






























import {Component, Vue} from 'vue-property-decorator';
import { DataStateChangeEventArgs } from "@syncfusion/ej2-vue-grids";
import { Predicate } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMDTOWorkOrder } from '@/scripts/services/ClientApiAuto'
import { L10n } from '@syncfusion/ej2-base';


@Component<Incident>({
   
})
export default class Incident extends CFMGridPage<CFMDTOWorkOrder> {
    entityType = "WorkOrder";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];

    entityState: DataStateChangeEventArgs = {where:[new Predicate('isActive', 'equal', true, false), new Predicate('OrderSourceId', 'equal', 3, false)]};


    async mounted(){
        this.dataStateChange(this.state);
    }
    async created() {
        this.filterSettings = {type: 'Menu'};
        L10n.load({
            "tr": {
                "grid": {
                    "FilterButton": "Filtrele",
                    "ClearButton": "Temizle"
                }
            }
        });
    }
    refreshGrid(){
        this.dataStateChange(this.state);
    }
    recordDoubleClick(args:any){
        this.edit(args.rowData.Id);
    }
    async edit(id: number){
        this.$router.push(
            {
                name:'AddWorkOrder', 
                query:{
                    workOrderId: String(id)
                }
        });
    }
    async delete(data: CFMDTOWorkOrder){
        const e = await swal.check(this, data.Id);
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.incident.incidentDelete({id: data.Id});
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }
    async add(){
        this.$router.push(
        {
            name:'AddWorkOrder', 
        });
    }
    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id);
            }
        }
        else if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
      this.toolbarClickBase(args);
    }
    commandTemplate() {
        return {
            template: Vue.component("columnTemplate", {
                template: `
                    <div>
                        <router-link
                            :to="{ name: 'MyWorkOrder', query: { workOrderId: data.Id }}"
                            v-slot="{ href, navigate }"
                            custom >
                            <a :href="href" @click="navigate" :title="translate('show')" class="e-lib e-btn e-control e-icon-btn">
                                <span class="e-btn-icon e-icons e-preview-icon"></span>
                            </a>
                        </router-link>
                    </div>
                    `
            }),
        };
    }
}
