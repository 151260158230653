import * as api from '../../services/api';
import chart from './chart';
import * as swal from '../../../helpers/swal';

export default {
  mixins: [chart],
  data() {
    return {
      result: 0,
      totalCapacityEquipment: 0,
    };
  },
  async mounted() {
    const self = this;
    await self.getData();
    // self.initChart();
  },
  methods: {
    async updateData() {
      const self = this;
      await self.getData();
      // self.redraw();
    },
    async getData() {
      const self = this;
      try {
        const deviceCountWithScpFormRelations = (await api.getDeviceCountWithScpFormCount()).body;
        self.result = deviceCountWithScpFormRelations.DeviceCountWhichHasNoScpForm;
        self.totalCapacityEquipment = deviceCountWithScpFormRelations.DeviceCount;
      } catch (e) {
        throw new Error(e);
      }
    },
    redraw() {

    },
    /*  redirect() {
              const self = this;
              self.$router.push({name: 'UserTrainingTracking', query: {usersNeedToBeGetTrainingLessThanDays: self.value}});
          } */
  },
};
