<template>
      <div>
          <div v-for="item in deviceHistory">
            <p><b>Kullanıcı: </b>{{item.User.NameSurname}}</p>
            <p><b> {{translate('change_date')}}: </b>{{ new Date(item.CreatedDate).toLocaleString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }) }}</p>
            <p><b>{{translate('device_installation_date')}}: </b>{{ new Date(item.DateOfInstallation).toLocaleDateString({}) }}</p>
            <hr/>
          </div>
      </div>
  </template>
  
  <script>
  import * as api from '../../../scripts/services/api';
  import * as blockui from '../../../helpers/block-ui';
  import { mixinz } from '../../../scripts/global/mixinz';
  
  export default {
    mixins: [mixinz],
    props: ['capacityEquipment', 'isUpdate'],
    data() {
      return {
        deviceHistory: [],
      };
    },
    async mounted() {
      await this.getDataDeviceHistory();
      this.isReady = true;
    },
    methods: {
      async getDataDeviceHistory() {
        const self = this;
        blockui.pageBlock(this);
        try {
          const response = await api.getDeviceHistoryByDeviceId(self.capacityEquipment.Id);
          this.deviceHistory = response.body;
        } catch (error) {
          console.error('Failed to fetch device history:', error);
        } finally {
          blockui.unBlockPage(this);
        }
      },
    },
  };
  </script>