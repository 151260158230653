import Vue from 'vue';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';
import * as datatable from '../global/datatables';

export default {
  data() {
    return {
      itemList: [],
      roles: {},
      roleItemList: {},
      itemTypeList: [],
      filterItemType: {
        Value: '',
      },
    };
  },
  mounted() {
    const self = this; let
      table;
    const datatableContainer = '.security_role_item_table';
    window.addEventListener('beforeunload', self.redirect);

    api.getSecurityItemTypes().then((res) => {
      self.itemTypeList = res.body;
    });

    Vue.getDatatables = (data) => {
      const columns = [{
        data: 'Name',
        title: this.translate('items'),
        sortable: 'asc',
        defaultContent: '',
      }, {
        data: 'ItemType.Name',
        title: this.translate('security_item_type'),
        sortable: 'asc',
        defaultContent: '',
      }];

      self.itemList = [];
      self.roles = {};
      self.roleItemList = {};
      blockui.blockElement(self, '.m-portlet');
      api.getSecurityItemList(data).then((res) => {
        self.itemList = res.body;
        api.getUserRoleList().then((response) => {
          self.roles = response.body.Data;
          self.itemList.forEach((item) => {
            self.roleItemList[item.Id] = {};
            self.roles.forEach((role) => {
              self.roleItemList[item.Id][role.Id] = {
                isActive: false,
                isReadOnly: false,
                isModified: false,
              };
            });
          });
          api.getSecurityRoleItem(data).then((res) => {
            res.body.forEach((securityRoleItem) => {
              self.roleItemList[securityRoleItem.ItemId][securityRoleItem.RoleId] = {
                isActive: true,
                isReadOnly: securityRoleItem.isActive,
                isModified: false,
              };
            });

            self.roles.forEach((role) => {
              columns.push({
                title: role.Name,
                orderable: false,
                render(data, type, row) {
                  return `<div data-toggle="m-tooltip" data-placement="left" data-original-title="${self.translate('authorize')}" style="text-align: center">
                    <input type="checkbox" class="role-item-is-active" data-roleId="${role.Id}" data-roleId="${row.Id}" ${self.roleItemList[row.Id][role.Id].isActive ? 'checked' : ''}/></div>`;
                },
              });
            });

            columns.push({
              title: '',
              orderable: false,
              render(data, type, row) {
                return `<button id="save_${row.Id}" class="btn btn-outline-primary save-button" disabled>${self.translate('save')}</button>`;
              },
            });
            columns.push({
              title: '',
              orderable: false,
              render(data, type, row) {
                return `<button id="select_all_${row.Id}" class="btn btn-outline-primary select-all-button">${self.translate('select_all')}</button>`;
              },
            });

            $(datatableContainer).on('init.dt', () => {
              self.$nextTick(() => {
                handlers();
              });
            });
            const isDataTable = $.fn.DataTable.isDataTable(datatableContainer);
            if (isDataTable) {
              const dt = $(datatableContainer).DataTable();
              dt.clear();
              dt.rows.add(self.itemList);
              dt.draw();
            } else {
              table = datatable.renderTable({
                exportFileName: self.translate('security_role_item'),
                container: datatableContainer,
                data: self.itemList,
                columns,
                scrollCollapse: true,
                scrollY: '800px',
                pagination: false,
                paging: false,
                scrollX: true,
                rowsGroup: [1],
              });
            }
            table.on('draw', handlers);

            function handlers() {
              $('.role-item-is-active').on('change keyup', function (e) {
                $(this).closest('tr').find('.save-button').removeAttr('disabled')
                  .addClass('active');
                self.roleItemList[$(this)[0].dataset.itemid][$(this)[0].dataset.roleid].isModified = true;
                self.roleItemList[$(this)[0].dataset.itemid][$(this)[0].dataset.roleid].isActive = $(this)[0].value;
              });
              window.$('#security_role_item_table').off('click');
              window.$('#security_role_item_table').on('click', '.save-button', function (e) {
                const button = $(this).closest('tr').find('.save-button');
                const row = window.$(this).closest('tr');
                const item = table.rows(row).data()[0];
                const formData = {
                  itemId: item.Id,
                  roles: [],
                };
                row.find('input').each(function () {
                  if ($(this).is(':checked')) {
                    const roleId = $(this)[0].dataset.roleid;
                    formData.roles.push(roleId);
                  }
                });
                blockui.pageBlock();
                api.saveSecurityRoleItem(formData).then((response) => {
                  if (response.body.IsSuccess) {
                    toastr.success(self.translate('save_successfully'));
                    button.attr('disabled', 'disabled').removeClass('active');
                    row.find('input').each(function () {
                      const roleId = $(this)[0].dataset.roleid;
                      self.roleItemList[item.Id][roleId].isModified = false;
                    });
                  } else {
                    toastr.error(response.body.Message);
                  }
                  blockui.unBlockPage();
                }, (error) => {
                  blockui.unBlockPage();
                });
              }).on('click', '.select-all-button', function (e) {
                const button = $(this).closest('tr').find('.save-button');
                const row = window.$(this).closest('tr');
                let allSelected = true;
                row.find('input').each(function () {
                  if (!$(this).is(':checked')) {
                    allSelected = false;
                  }
                });
                row.find('input').each(function () {
                  $(this).prop('checked', !allSelected);
                });
                button.removeAttr('disabled').addClass('active');
              });

              self.$nextTick(() => {
                $.fn.dataTable
                  .tables({ visible: true, api: true })
                  .columns.adjust()
                  .fixedColumns().relayout();
              });
            }

            blockui.unBlockElement('.m-portlet');
          });
        });
      });
    };
    Vue.getDatatables();
  },
  methods: {
    redirect(event) {
      const self = this; let
        table;
      const confirmationMessage = '\o/';
      if (self.isModifiedExist()) {
        window.removeEventListener('beforeunload', self.redirect);
        event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        return confirmationMessage; // Gecko, WebKit, Chrome <34
      }
    },
    isModifiedExist() {
      const self = this; let
        table;
      let isExist = false;
      Object.keys(self.roleItemList).forEach((key) => {
        Object.keys(self.roleItemList[key]).forEach((key2) => {
          if (self.roleItemList[key][key2].isModified) {
            isExist = true;
          }
        });
      });
      return isExist;
    },
    onFilter(e) {
      e.preventDefault();
      const form = $(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        Vue.getDatatables(formData);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const self = this; let
      table;
    const confirmationMessage = '\o/';
    if (self.isModifiedExist()) {
      window.removeEventListener('beforeunload', self.redirect);
      const answer = window.confirm(self.translate('you_have_unsaved_changes_do_you_want_to_leave'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
