import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { formatDataWithParams } from '@/scripts/global/util';
import { mapState, mapGetters } from 'vuex';
import * as api from '../services/api';
import DynamicFormElements from '../../components/partials/DynamicFormElements';
import Select2 from '../../components/shared/select2';
import * as blockui from '../../helpers/block-ui';
import { mixinz } from '../global/mixinz';
import Select2Group from '../../components/shared/select2Group';
import { AccordionComponent } from '@syncfusion/ej2-vue-navigations';

export default {
  mixins: [mixinz],
  props: ['selectedDevice', 'formElementParams', 'isUpdate', 'selectedTable'],
  components: {
    'dynamic-form-elements': DynamicFormElements,
    select2: Select2,
    'select2-group': Select2Group,
    'ejs-accordion': AccordionComponent,
  },
  data() {
    return {
      device: {
        Value: '',
      },
      deviceTypes: [],
      deviceHistory: [],
      dateOfInstallation: null,
      today: new Date(),
    };
  },
  validations: {
    selectedDevice: {
      DeviceTypeId: {
        required,
      },
      TotalEffectiveCapacityUnitId: {
        checked(value, vm) {
          if (vm.TotalEffectiveCapacity) {
            return !!vm.TotalEffectiveCapacityUnitId;
          }
          return true;
        },
      },
    },
  },
  computed: {
    ...mapState({
      modelList: (state) => state.brand.brandListWithModels,
    }),
    ...mapGetters({
      unitList: 'getUnitList',
    }),
    getBrandsWithModels() {
      return formatDataWithParams(this.modelList, ['DeviceModels']);
    },
    selectedDeviceType: {
      get() {
        const self = this;
        return self.selectGet('deviceTypes', 'selectedDevice.DeviceTypeId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedDevice.DeviceTypeId', 'Id', newValue);
      },
    },
  },
  created() {
    const self = this;
    api.getDeviceTypeList().then((response) => {
      self.deviceTypes = response.body.Data;
    });
    if(this.isUpdate){
      api.getDeviceHistoryByDeviceId(self.selectedDevice.Id).then((response) => {
        self.deviceHistory = response.body;
      });
    }
  },
  mounted() {
    const self = this;
    this.$store.dispatch('getUnitList');
    this.$store.dispatch('getBrandListWithModels', { isForDevice: true });
    $('#device_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
      if (self.selectedDevice && self.selectedDevice.DateOfInstallation) {
        self.dateOfInstallation = moment(self.selectedDevice.DateOfInstallation);
      }
    self.$nextTick(() => { 
      if (self.DateOfInstallation) {
        self.dateOfInstallation = moment(self.DateOfInstallation);
      }
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      this.$v.$touch();
      if (!form.valid() || this.$v.$invalid) {
        return;
      }
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        Object.assign(formData, { DeviceTypeId: self.selectedDevice.DeviceTypeId });
        if (self.dateOfInstallation) {
          formData.DateOfInstallation = moment(self.dateOfInstallation);
        }
        api.saveDevice(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            $('#device_modal').modal('toggle');
            if (self.$route.meta.xyz) {
              Vue.getDatatables('.device_list');
              if (!self.isUpdate) {
                self.$parent.slc.equipments.ChildCount = self.$parent.slc.equipments.ChildCount ? self.$parent.slc.equipments.ChildCount + 1 : 1;
              }
            } else if (!self.isUpdate) {
              if (!$(`table [data-id='${formData.parentId}']`).eq(0).closest('tr').hasClass('shown')) {
                $(`table [data-id='${formData.parentId}']`).eq(0).closest('tr').find('.details-control')
                  .removeClass('m--hide');
                $(`table [data-id='${formData.parentId}']`).eq(0).closest('tr').find('.details-control')
                  .eq(0)
                  .click();
              } else {
                Vue.getDatatables($(`table [data-id='${formData.parentId}']`).closest('tr').next().find('table'));
              }
            } else {
              Vue.getDatatables(self.selectedTable);
            }
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
  },
  watch: {
    'selectedDevice.DateOfInstallation'(newValue) {
      if (newValue) {
        this.dateOfInstallation = moment(newValue);
      }
    }
  }
};
