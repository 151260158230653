<template>
        <div class="column column-filter">
            <div class="column-header">
                <h3 class="column-header-text">
                    <span v-lang.filter></span>
                </h3>
                <button class="btn" @click.prevent="closeFilter()"><i class="la la-close"></i>
                </button>
            </div>
            <div class="column-content" style="overflow: visible!important;" data-scrollbar-shown="true"
                 data-scrollable="true" data-height="100%">
                <div class="column-scrollable">
                    <form ref="filterForm">
                        <div class="form-group column-input m-form__group d-flex align-items-center">
                            <label v-lang.show_only_has_feedback_ones></label>
                            <span class="m-switch m-switch--outline m-switch--primary ml-auto">
                                <label>
                                    <input type="checkbox" v-model="selectedParams.showOnlyHasFeedbackOnes">
                                    <span></span>
                                </label>
                            </span>
                        </div>
                        <div class="form-group column-input m-form__group d-flex align-items-center">
                            <label v-lang.show_only_has_invalid_answer_ones></label>
                            <span class="m-switch m-switch--outline m-switch--primary ml-auto">
                                <label>
                                    <input type="checkbox" v-model="selectedParams.showOnlyHasInvalidAnswerOnes">
                                    <span></span>
                                </label>
                            </span>
                        </div>
                        <div class="form-group column-input">
                            <label><span v-lang.start_date></span></label>
                            <date-picker name="startDate" v-model="startDate"
                                         :config="pickerDate"></date-picker>
                        </div>
                        <div class="form-group column-input">
                            <label><span v-lang.end_date></span></label>
                            <date-picker name="endDate" v-model="endDate"
                                         :config="pickerDate"></date-picker>
                        </div>
                        <div class="form-group column-input">
                            <label><span v-lang.form></span></label>
                            <multiselect v-model="form" track-by="Id" label="Name"
                                         :placeholder="translate('please_select')" :options="parentFormList"
                                         :searchable="true"
                                         :allow-empty="true" @input="getVersions" selectLabel="" deselectLabel=""
                                         selectedLabel=""></multiselect>
                        </div>
                        <div class="form-group column-input">
                            <label><span v-lang.version></span></label>
                            <multiselect v-model="version" track-by="Version" label="Version"
                                         :placeholder="translate('please_select')" :options="childFormList"
                                         :searchable="true"
                                         :allow-empty="true" selectLabel="" deselectLabel=""
                                         selectedLabel=""></multiselect>
                        </div>
                        <div class="form-group column-input">
                            <label><span v-lang.form_process_status></span></label>
                            <multiselect v-model="formProcessStatus" track-by="Id" label="Name"
                                         :placeholder="translate('please_select')" :options="formProcessStatuses"
                                         :searchable="true"
                                         :allow-empty="true" selectLabel="" deselectLabel=""
                                         selectedLabel=""></multiselect>
                        </div>
                        <div class="form-group column-input">
                            <label><span v-lang.procedure_type></span></label>
                            <multiselect v-model="procedureType" track-by="Id" label="Name"
                                         :placeholder="translate('please_select')" :options="procedureTypes"
                                         :searchable="true"
                                         :allow-empty="true" selectLabel="" deselectLabel=""
                                         selectedLabel=""></multiselect>
                        </div>

                        <div class="form-group column-input text-center">
                            <button @click.prevent="onFilter" type="submit" href="javascript:;" class="btn btn-primary">
                                <span v-lang.filter></span>
                            </button>
                        </div>
                        <div class="form-group column-input text-center">
                            <button @click.prevent="clear" type="submit" href="javascript:;" class="btn btn-danger">
                                <span v-lang.clear></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import { mixinz } from '../../../../scripts/global/mixinz';

export default {
  mixins: [mixinz],
  name: 'FormAnswersFilters',
  props: ['selectedParams'],
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch('getProcedureTypes');
    this.$store.dispatch('getFormProcessStatuses');
    this.$store.dispatch('getFilterCapacityEquipmentBreadCrumbList');
    this.$store.dispatch('getFilterBrandList');
    this.$store.dispatch('getFilterModelList');
    this.$store.dispatch('getFormListForFilter');
  },
  computed: {
    ...mapState({
      filterBrandList: (state) => state.brand.filterBrandList,
      filterModelList: (state) => state.model.filterModelList,
      formList: (state) => state.form.formListForFilter,
    }),
    ...mapGetters({
      procedureTypes: 'getProcedureTypes',
      filterCapacityEquipmentBreadCrumbList: 'getFilterCapacityEquipmentBreadCrumbList',
      formProcessStatuses: 'getFormProcessStatuses',
    }),
    parentFormList() {
      return this.formList.filter((form) => !(form.ParentId > 0));
    },
    childFormList() {
      const parentId = this.selectedParams.formId;
      if (parentId > 0) {
        return this.formList.filter((form) => form.ParentId == parentId || form.Id == parentId);
      }
      return [];
    },
    form: {
      get() {
        const self = this;
        return self.selectGet('parentFormList', 'selectedParams.formId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.formId', 'Id', newValue);
      },
    },
    version: {
      get() {
        const self = this;
        return self.selectGet('childFormList', 'selectedParams.version', 'Version', 'Version');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.version', 'Version', newValue);
      },
    },
    procedureType: {
      get() {
        const self = this;
        return self.selectGet('procedureTypes', 'selectedParams.procedureTypeId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.procedureTypeId', 'Id', newValue);
      },
    },
    formProcessStatus: {
      get() {
        const self = this;
        return self.selectGet('formProcessStatuses', 'selectedParams.formProcessStatusId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.formProcessStatusId', 'Id', newValue);
      },
    },
    startDate: {
      get() {
        if (this.selectedParams.startDate) {
          return moment(this.selectedParams.startDate).format(this.pickerDateFormat);
        }
        return null;
      },
      set(val) {
        if (val) {
          Vue.set(this.selectedParams, 'startDate', moment(val, this.pickerDateFormat).toDate());
        } else {
          Vue.set(this, 'startDate', val);
        }
        return val;
      },
    },
    endDate: {
      get() {
        if (this.selectedParams.endDate) {
          return moment(this.selectedParams.endDate).format(this.pickerDateFormat);
        }
        return null;
      },
      set(val) {
        if (val) {
          Vue.set(this.selectedParams, 'endDate', moment(val, this.pickerDateFormat).toDate());
        } else {
          Vue.set(this, 'endDate', val);
        }
        return val;
      },
    },
  },
  methods: {
    onFilter() {
      this.$emit('filtered', this.selectedParams);
    },
    closeFilter() {
      this.$emit('closed');
    },
    clear() {
      const self = this;
      // self.selectedParams = {};
      // self.$nextTick(() => {
      this.$emit('clearFilter');
      // });
    },
    getVersions() {
      this.version = null;
    },
  },
};
</script>
