import * as api from '../../services/api';
import chart from './chart';
import * as swal from '../../../helpers/swal';
import * as dateFormat from '../../../helpers/dateFormat';
import { groupBy } from '../../global/util';

export default {
  mixins: [chart],
  data() {
    return {
      chart: null,
      labels: [],
      datasets: [],
      shiftChartData: [],
      shiftTypes: [],
    };
  },
  async mounted() {
    const self = this;
    await self.getData();
    self.initChart(self.data);
  },
  methods: {
    async updateData() {
      const self = this;
      await self.getData();
      self.redraw();
    },
    getStaffShiftCountByDateRange(startDate, endDate) {
      return api.getStaffShiftCountByDateRange({
        startDate,
        endDate,
      }).then((res) => {
        this.shiftChartData = res.body;
      });
    },
    getShiftTypes() {
      return api.getShiftTypeList().then((res) => {
        this.shiftTypes = res.body;
      });
    },
    async getData() {
      const self = this;
      await Promise.all([
        this.getShiftTypes(),
        this.getStaffShiftCountByDateRange(
          dateFormat.postDateFormat(self.startDate),
          dateFormat.postDateFormat(self.endDate),
        ),
      ]);

      const start = moment(self.startDate);
      const end = moment(self.endDate);
      const dateArray = [];
      while (start.isBefore(end)) {
        dateArray.push(start.toDate());
        start.add(1, 'day');
      }

      self.datasets = this.shiftTypes.map((shiftType) => {
        const data = dateArray.map((date) => {
          const matchedShiftChartData = this.shiftChartData.find((shiftChartDataItem) => moment(shiftChartDataItem.ShiftDate).isSame(moment(date), 'day') && shiftChartDataItem.Name == shiftType.Name);
          if (!matchedShiftChartData) {
            return 0;
          }
          return matchedShiftChartData.StaffCount;
        });
        return {
          label: shiftType.Name,
          data,
          backgroundColor: shiftType.Color.Code,
          borderColor: shiftType.Color.Code,
          borderWidth: 1,
        };
      });
      self.labels = dateArray.map((date) => moment(date).format('DD MMM ddd'));
    },
    initChart() {
      const self = this;
      if ($(`#${self.chartId}`).length == 0) return;
      const config = {
        type: 'bar',
        data: {
          labels: self.labels,
          datasets: self.datasets,
        },
        options: {
          tooltips: {
            callbacks: {},
          },
          // responsive: true,
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              },
            }],
          },
          title: {
            display: true,
            text: `${self.translate('shift_count')}: ${self.datasets.reduce((total, current) => total + current.data.reduce((total_2, current_2) => total_2 + current_2, 0), 0)}`,
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          maintainAspectRatio: false,
        },
      };
      const ctx = $(`#${self.chartId}`);
      self.chart = new Chart(ctx, config);

      /* ctx.onclick = function(evt) {
              let activePoints = self.chart.getElementsAtEvent(evt);
              if (activePoints[0]) {
                let chartData = activePoints[0]['_chart'].config.data;
                let idx = activePoints[0]['_index'];

                let label = chartData.labels[idx];
                let value = chartData.datasets[0].data[idx];

                let url = "http://example.com/?label=" + label + "&value=" + value;
                alert(url);
              }
            }; */
    },
    redirect(orderStatusId) {
      this.$router.push({ name: 'WorkOrder', query: { workOrderStatusId: orderStatusId } });
    },
    redraw() {
      $(`#${this.chartId}`).removeAttr('width');
      $(`#${this.chartId}`).removeAttr('height');
      $(`#${this.chartId}`).html('');
      this.chart.destroy();
      this.initChart();
    },
  },
};
