import { mixinz } from '../../../../../scripts/global/mixinz';
import * as blockui from '../../../../../helpers/block-ui';
import * as api from '../../../../../scripts/services/api';

export default {
  mixins: [mixinz],
  props: ['isUpdate', 'selectedSlaId', 'selectedSectionId', 'selectedContractId'],
  data() {
    return {
      slaId: null,
      sla: {},
      SectionId: null,
      ContractId: null,
    };
  },
  async mounted() {
    window.$('form').validate();
    const self = this;
    $('#sla_modal').on('hidden.bs.modal', () => {
      self.$emit('close');
    });
    self.slaId = self.selectedSlaId;
    self.slaId && await self.getSla(self.slaId);
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);

      if (form.valid()) {
        blockui.blockModal(self);
        self.sla.SectionId = self.selectedSectionId;
        self.sla.ContractId = self.selectedContractId;

        api.saveContractSla(self.sla).then((response) => {
          if (response.body.IsSuccess) {
            self.$emit('reloadDatatable');
            toastr.success(self.translate('item_saved_successfully').formatString(self.sla.Condition));
            blockui.unBlockModal();
            $('#sla_modal').modal('toggle');
          } else {
            toastr.error(response.body.Message);
            blockui.unBlockModal();
          }
        }, (error) => {
          toastr.error(error.message);
          blockui.unBlockModal();
        });
      }
    },
    closeModal() {
      $('#sla_modal').modal('toggle');
      this.slaId = null;
    },
    getSla(id) {
      const self = this;

      blockui.blockModal(self);
      api.getContractSla(id).then((response) => {
        self.sla = response.body;
        blockui.unBlockModal();
      }, (error) => {
        toastr.error(error.message);
        blockui.unBlockModal();
      });
    },
  },
  watch: {
    selectedSlaId(e) {
      this.slaId = e;
    },
    selectedSectionId(e) {
      this.SectionId = e;
    },
    selectedContractId(e) {
      this.ContractId = e;
    },
  },
};
