import Vue from 'vue';
import * as swal from '../../helpers/swal';
import * as api from '../services/api';
import * as dateFormat from '../../helpers/dateFormat';
import * as blockui from '../../helpers/block-ui';
import scheduler from '../global/scheduler';
import grid from '../global/gridOld';

export default {
  data() {
    return {
      dateHelper: bryntum.scheduler.DateHelper,
      widgetHelper: bryntum.scheduler.WidgetHelper,
      currentDate: bryntum.scheduler.DateHelper.getFirstDateOfMonth(new Date()),
      eventTooltipFeature: { template: (data) => `${data.eventRecord.name}</br>#${data.eventRecord.id}</br>${bryntum.scheduler.DateHelper.format(data.endDate, 'LL')}` },
      selectedShift: null,
      schEngine: undefined,
      gridEngine: undefined,
      IsReady: false,
      IsGridReady: false,
      IsDisplayGrid: false,
      shiftTypes: [],
      gridColumns: [
        {
          type: 'rownumber',
          field: 'rownumber',
          locked: true,
          width: 50,
        }, {
          type: 'number',
          text: 'Personel',
          field: 'userName',
          width: 200,
        },
        // {
        //     type: 'widget',
        //     groupable: false,
        //     widgets: [{
        //         type: 'button',
        //         icon: 'b-fa b-fa-edit',
        //         cls: 'b-blue',
        //         style: 'width:100%',
        //         onAction: ({source}) => {
        //         }
        //     }],
        //     field: 'edit',
        //     text: 'Edit',
        //     width: 90,
        //     locked: true
        // },
        {
          type: 'widget',
          groupable: false,
          locked: true,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-trash',
            cls: 'b-red',
            style: 'width:100%',
            onAction: ({ source }) => {
              const self = this;
              const event = self.schEngine.eventStore.getById(source.cellInfo.record.id);
              const resourceRecord = self.schEngine.resourceStore.getById(source.cellInfo.record.resourceId);
              const swalTitle = `${resourceRecord.staff} 
            ${self.translate('shift')} ${event.name}
            ${dateFormat.viewDateFormat(event.startDate)}`;
              swal.check(self, swalTitle, self.translate('will_be_deleted_permanently')).then((e) => {
                if (e.value) self.deleteShift(event.id);
              });
            },
          }],
          field: 'delete',
          text: 'Delete',
          width: 100,
        },
        {
          type: 'number',
          field: 'id',
          text: 'Id',
          width: 90,
        },
        {
          text: 'Shift',
          field: 'name',
          htmlEncode: false,
          width: 80,
          align: 'center',
          renderer: ({ column, record }) => `<button class="b-raised b-${record.data.eventColor} b-widget b-button b-text b-icon"><i class="b-fa b-fa-calendar-alt"/> ${record.data.name}</button>`,
        },
        {
          type: 'date',
          text: 'Start Date',
          field: 'shiftStartDate',
          format: 'LLL',
          width: 200,
        },
        {
          type: 'date',
          text: 'End Date',
          field: 'shiftEndDate',
          format: 'LLL',
          width: 200,
        },
      ],
      resources: [],
      timeRanges: [],
      events: [],
      columns: [
        {
          type: 'rownumber',
          field: 'rownumber',
          groupable: false,
          draggable: false,
          hidden: true,
        },
        {
          field: 'role',
          text: 'Role',
          width: 220,
          hidden: true,
        },
        {
          field: 'orderNumber',
          text: 'OrderNumber',
          width: 220,
          hidden: true,
        },
        {
          text: 'Experience',
          field: 'experience',
          hidden: true,
          width: 90,
        },
        {
          text: 'Staff',
          field: 'staff',
          width: 220,
        },
      ],
      group: {
        field: 'orderNumber',
        renderer: (record) => {
          if (record.groupColumn.field == 'orderNumber') {
            return this.roleNameOrderListDistinct.find((x) => x.OrderNumber == record.groupRowFor).Name;
          }
          return record.groupRowFor;
        },
      },
      roleNameOrderListDistinct: [],
      deletableDayCount: 0
    };
  },
  components: {
    scheduler,
    grid,
  },
  async created() {
    const self = this;
    blockui.pageBlock(self);
    const promises = [
      await self.getShiftTypes(),
      await self.getAvailableShiftDeletableDayCount(),
      self.getResources(),
      self.getEvents(),
    ];
    Promise.all(promises);
    self.IsReady = true;
  },
  mounted() {
  },
  computed: {},
  methods: {
    initGrid() {
      const self = this;
      self.gridEngine = self.$refs.grid.gridEngine;
      self.gridEngine.hide();
    },
    initScheduler() {
      const self = this;
      self.schEngine = self.$refs.scheduler.schedulerEngine;
      self.IsGridReady = true;
      const schButtons = [{
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-arrow-left',
        tooltip: self.translate('previous_month'),
        onAction: function onAction() {
          blockui.pageBlock(self);
          self.currentDate = self.dateHelper.add(self.currentDate, -1, 'month');
          self.getEvents();
        },
      }, {
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-arrow-down',
        tooltip: self.translate('today'),
        onAction: function onAction() {
          blockui.pageBlock(self);
          self.currentDate = self.dateHelper.getFirstDateOfMonth(new Date());
          self.getEvents();
        },
      }, {
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-arrow-right',
        tooltip: self.translate('next_month'),
        onAction: function onAction() {
          blockui.pageBlock(self);
          self.currentDate = self.dateHelper.add(self.currentDate, 1, 'month');
          self.getEvents();
        },
      }, {
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-search-plus',
        tooltip: self.translate('zoom_in'),
        onAction: function onAction() {
          blockui.pageBlock(self);
          self.schEngine.zoomIn();
          blockui.unBlockPage();
        },
      }, {
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-search',
        tooltip: self.translate('zoom_reset'),
        onAction: function onAction() {
          blockui.pageBlock(self);
          self.schEngine.zoomTo({
            preset: 'dayAndWeek',
            startDate: self.currentDate,
            endDate: self.dateHelper.add(self.currentDate, 1, 'month'),
          });
          if (self.currentDate.getTime() == self.dateHelper.getFirstDateOfMonth(new Date()).getTime()) {
            self.$nextTick(() => {
              self.schEngine.scrollToNow({ block: 'start' });
            });
          }
          blockui.unBlockPage();
        },
      }, {
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-search-minus',
        tooltip: self.translate('zoom_out'),
        onAction: function onAction() {
          blockui.pageBlock(self);
          self.schEngine.zoomOut();
          blockui.unBlockPage();
        },
      }, {
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-toggle-on',
        tooltip: self.translate('toggle_view'),
        onAction: function onAction() {
          self.IsDisplayGrid = !self.IsDisplayGrid;
          self.IsDisplayGrid ? self.gridEngine.show() : self.gridEngine.hide();
          !self.IsDisplayGrid ? self.schEngine.show() : self.schEngine.hide();
          toolsContainer[0].items.forEach((item) => {
            if (item.icon.includes('b-fa-search')) {
              self.IsDisplayGrid ? item.hide() : item.show();
            }
          });
        },
      }];

      let toolsContainer = self.widgetHelper.append({
        type: 'container',
        widgets: schButtons,
        cls: 'pull-left',
      }, { insertFirst: document.getElementsByClassName('schContainer')[0] });

      const shiftButtons = self.shiftTypes.map((shift) => ({
        type: 'button',
        text: shift.Name,
        icon: 'b-fa b-fa-calendar-alt',
        tooltip: `${shift.StartTime} - ${shift.EndTime}`,
        cls: `b-raised b-${shift.Color.Name.toLowerCase()}`,
        margin: 5,
        onAction: function onAction() {
          $(this.currentElement).addClass('selected');
          $(this.currentElement).siblings().removeClass('selected');
          self.selectedShift = self.shiftTypes.filter((i) => i.Name === shift.Name)[0];
        },
      }));

      self.shiftContainer = self.widgetHelper.append({
        type: 'container',
        widgets: shiftButtons,
        cls: 'pull-right shift-type-selection-btn-wrapper',
      }, { insertFirst: document.getElementsByClassName('schContainer')[0] });

      self.schEngine.on('beforeeventdropfinalize', ({ context }) => {
        // context.async = true;
        // setTimeout(async ()=>{
        //   context.finalize(false);

        // },1000)

        context.draggedRecords.forEach((record) => {
          let done = false;
          const matchingShifts = [];
          const year = context.startDate.getFullYear();
          const month = context.startDate.getMonth();
          const day = context.startDate.getDate();
          self.shiftTypes.forEach((shift) => {
            const sTimes = shift.StartTime.split(':');
            const eTimes = shift.EndTime.split(':');
            const startDate = new Date(year, month, day, sTimes[0], sTimes[1], sTimes[2]);
            const endDate = new Date(year, month, day, eTimes[0], eTimes[1], eTimes[2]);
            if (shift.StartTime > shift.EndTime) {
              endDate.setDate(startDate.getDate() + 1);
            }
            if (!done && context.startDate >= startDate && context.startDate <= endDate) {
              matchingShifts.push(shift);
            }
          });
          if (matchingShifts.length === 1) {
            const sTimes = matchingShifts[0].StartTime.split(':');
            const eTimes = matchingShifts[0].EndTime.split(':');
            const startDate = new Date(year, month, day, sTimes[0], sTimes[1], sTimes[2]);
            const endDate = new Date(year, month, day, eTimes[0], eTimes[1], eTimes[2]);

            done = true;
            if (matchingShifts[0].StartTime > matchingShifts[0].EndTime) {
              endDate.setDate(startDate.getDate() + 1);
            }
            if (self.schEngine.eventStore.query((r) => r.startDate.getTime() == startDate.getTime() && r.endDate.getTime() == endDate.getTime() && r.data.resourceId == record.data.resourceId && r.data.shiftTypeId == matchingShifts[0].Id).length > 0) {
              toastr.error(self.translate('shift_exist_error_message'));
              context.finalize(false);
            } else {
              context.async = true;
              record.data.name = matchingShifts[0].Name;
              context.startDate = startDate;
              context.endDate = endDate;
              record.data.startDate = startDate;
              record.data.endDate = endDate;
              record.data.shift = matchingShifts[0].Name;
              record.data.shiftTypeId = matchingShifts[0].Id;
              record.data.eventColor = matchingShifts[0].Color.Name.toLowerCase();
              blockui.pageBlock(self);
              self.saveShift(record.data).then((id) => {
                if (id > 0) {
                  context.startDate = new Date(bryntum.scheduler.DateHelper.getEndOfPreviousDay(new Date(record.data.startDate)).getTime() + 1);
                  context.endDate = new Date(bryntum.scheduler.DateHelper.getStartOfNextDay(new Date(record.data.startDate)).getTime() - 1);
                  record.data.startDate = context.startDate;
                  record.data.endDate = context.endDate;
                }
                context.finalize(id > 0);
                blockui.unBlockPage();
              });
            }
          }
          if (matchingShifts.length > 1) {
            context.async = true;
            Swal.fire({
              title: '<strong>Select Exact Shift</strong>',
              type: 'question',
              html: `<div>${matchingShifts.map((i) => `<button style="background-color: ${i.Color.Code}" class='select_matching_shift'>${i.Name}</button>`)}</di>`,
              showCloseButton: true,
              showCancelButton: true,
              focusConfirm: false,
              confirmButtonText: 'Uygula',
              cancelButtonText: 'Iptal',
              cancelButtonAriaLabel: 'Cancel',
              onClose: () => {

              },
              preConfirm: () => {
                const shift = self.selectedMatchingShift;
                const sTimes = shift.StartTime.split(':');
                const eTimes = shift.EndTime.split(':');
                const startDate = new Date(year, month, day, sTimes[0], sTimes[1], sTimes[2]);
                const endDate = new Date(year, month, day, eTimes[0], eTimes[1], eTimes[2]);
                done = true;
                if (shift.StartTime > shift.EndTime) {
                  endDate.setDate(startDate.getDate() + 1);
                  startDate.setDate(startDate.getDate() - 1);
                }
                if (startDate.getTime() < new Date().getTime()) {
                  toastr.error(self.translate('shift_calendar_error'));
                  context.finalize(false);
                } else if (self.schEngine.eventStore.query((r) => r.startDate.getTime() == startDate.getTime() && r.endDate.getTime() == endDate.getTime() && r.data.resourceId == record.data.resourceId && r.data.shiftTypeId == shift.Id).length > 0) {
                  toastr.error(self.translate('shift_exist_error_message'));
                  context.finalize(false);
                } else {
                  context.async = true;
                  record.data.name = shift.Name;
                  context.startDate = startDate;
                  context.endDate = endDate;
                  record.data.startDate = startDate;
                  record.data.endDate = endDate;
                  record.data.shift = shift.Name;
                  record.data.shiftTypeId = shift.Id;
                  record.data.eventColor = shift.Color.Name.toLowerCase();
                  blockui.pageBlock(self);
                  self.saveShift(record.data).then((id) => {
                    if (id > 0) {
                      context.startDate = new Date(bryntum.scheduler.DateHelper.getEndOfPreviousDay(new Date(record.data.startDate)).getTime() + 1);
                      context.endDate = new Date(bryntum.scheduler.DateHelper.getStartOfNextDay(new Date(record.data.startDate)).getTime() - 1);
                      record.data.startDate = context.startDate;
                      record.data.endDate = context.endDate;
                    }
                    context.finalize(id > 0);
                    blockui.unBlockPage();
                  });
                }
                self.selectedMatchingShift = {};
                // self.initScheduler()
                return true;
              },
            }).then((res) => {
              if (res.dismiss === 'cancel') {
                context.finalize(false);
              }
            });
            Swal.disableConfirmButton();
          }
          $('.select_matching_shift').click(function () {
            $(this).addClass('active');
            $(this).siblings().removeClass('active');
            self.selectedMatchingShift = matchingShifts.filter((i) => i.Name === $(this).html())[0];
            Swal.enableConfirmButton();
          });
        });
      });

      self.schEngine.on('scheduleClick', (context) => {
        if (self.schEngine.readOnly || !Number.isInteger(context.resourceRecord.data.id)) return;
        if (self.selectedShift) {
          const shift = self.selectedShift;
          let done = false;
          const year = context.date.getFullYear();
          const month = context.date.getMonth();
          const day = context.date.getDate();

          const sTimes = shift.StartTime.split(':');
          const eTimes = shift.EndTime.split(':');
          const startDate = new Date(year, month, day, sTimes[0], sTimes[1], sTimes[2]);
          const endDate = new Date(year, month, day, eTimes[0], eTimes[1], eTimes[2]);
          if (shift.StartTime > shift.EndTime) {
            endDate.setDate(endDate.getDate() + 1);
          }
          // if (!done && context.date >= startDate && context.date <= endDate) {
          done = true;
          const todayStart = new Date(new Date().setHours(0,0,0,0));
          const availableStartDate = new Date(new Date(todayStart).setDate(new Date(todayStart).getDate() - self.deletableDayCount));
          if (startDate.getTime() < availableStartDate.getTime()) {
            toastr.error(self.translate('shift_calendar_error_earlier_X_days').formatString(self.deletableDayCount));
          } else if (self.schEngine.eventStore.query((r) =>
            new Date(r.shiftStartDate).getTime() == startDate.getTime() &&
            new Date(r.shiftEndDate).getTime() == endDate.getTime() && 
            r.data.resourceId == context.resourceRecord.data.id && 
            r.data.shiftTypeId == shift.Id
          ).length > 0) {
            toastr.error(self.translate('shift_exist_error_message'));
          } else {
            const event = {
              id: 0,
              resourceId: context.resourceRecord.data.id,
              name: shift.Name,
              startDate,
              endDate,
              eventColor: shift.Color.Name.toLowerCase(),
              shiftTypeId: shift.Id,
            };
            blockui.pageBlock(self);
            self.saveShift(event).then((id) => {
              event.id = id;
              if (id > 0) {
                event.startDate = new Date(bryntum.scheduler.DateHelper.getEndOfPreviousDay(new Date(event.startDate)).getTime() + 1);
                event.endDate = new Date(bryntum.scheduler.DateHelper.getStartOfNextDay(new Date(event.startDate)).getTime() - 1);
                self.schEngine.eventStore.add(event);
              }
              blockui.unBlockPage();
            });
          }

          // if (startDate.getTime() < new Date().getTime()) {
          //   toastr.error(self.translate('shift_calendar_error'));
          // } else if (self.schEngine.eventStore.query((r) => r.startDate.getTime() == startDate.getTime() && r.endDate.getTime() == endDate.getTime() && r.data.resourceId == context.resourceRecord.data.id && r.data.shiftTypeId == shift.Id).length > 0) {
          //   toastr.error(self.translate('shift_exist_error_message'));
          // } else {
          //   const event = {
          //     id: 0,
          //     resourceId: context.resourceRecord.data.id,
          //     name: shift.Name,
          //     startDate,
          //     endDate,
          //     eventColor: shift.Color.Name.toLowerCase(),
          //     shiftTypeId: shift.Id,
          //   };
          //   blockui.pageBlock(self);
          //   self.saveShift(event).then((id) => {
          //     event.id = id;
          //     if (id > 0) {
          //       event.startDate = new Date(bryntum.scheduler.DateHelper.getEndOfPreviousDay(new Date(event.startDate)).getTime() + 1);
          //       event.endDate = new Date(bryntum.scheduler.DateHelper.getStartOfNextDay(new Date(event.startDate)).getTime() - 1);
          //       self.schEngine.eventStore.add(event);
          //     }
          //     blockui.unBlockPage();
          //   });
          // }
          // // }
        } else {
          toastr.error(self.translate('shift_not_selected_error_message'));
        }
      });

      self.schEngine.on('eventClick', ({ eventRecord, resourceRecord }) => {
        if (self.schEngine.readOnly) return;
        const swalTitle = `${resourceRecord.staff} 
        ${self.translate('shift')} ${eventRecord.name}
        ${dateFormat.viewDateFormat(eventRecord.startDate)}`;
        swal.check(self, swalTitle, self.translate('will_be_deleted_permanently')).then((e) => {
          if (e.value) self.deleteShift(eventRecord.id);
        });
      });

      self.schEngine.scrollToNow({ block: 'start' });
    },

    async getShiftTypes() {
      const self = this;
      await self.$globalApiClient.shiftType.shiftTypeGetList().then((res) => {
        self.shiftTypes = res.data;
      });
    },

    async getAvailableShiftDeletableDayCount() {
      const self = this;
      await self.$globalApiClient.shift.getAvailableShiftDeletableDayCount().then((res) => {
        self.deletableDayCount = res.data.Data;
      });
    },

    getResources() {
      const self = this;
      self.$globalApiClient.user.userGetUsersForCalendar().then((res) => {
        const userList = res.data.Data;

        for (const user of userList) {
          if (user.Role && user.Role.Name && self.roleNameOrderListDistinct.findIndex((x) => x.Name == user.Role.Name) < 0) {
            self.roleNameOrderListDistinct.push({ Name: user.Role.Name, OrderNumber: user.Role.OrderNumber });
          }
        }

        const orderNumbers = self.roleNameOrderListDistinct.map((x) => x.OrderNumber).filter((x) => x || x === 0);
        let maximumOrderNumber = (orderNumbers && orderNumbers.length > 0) ? Math.max(...orderNumbers) : -1;

        for (const roleNameOrder of self.roleNameOrderListDistinct) {
          if (!roleNameOrder.OrderNumber || roleNameOrder.OrderNumber === 0) {
            maximumOrderNumber++;
            roleNameOrder.OrderNumber = maximumOrderNumber;
          }
        }

        self.resources = res.data.Data.map((user) => ({
          id: user.Id,
          staff: user.NameSurname,
          role: user.Role.Name,
          orderNumber: self.roleNameOrderListDistinct.find((x) => x.Name == user.Role.Name).OrderNumber,
          experience: user.UserExperience.Name,
        }));
      });
    },

    getEvents() {
      const self = this;
      if (self.schEngine) {
        self.schEngine.zoomTo({
          preset: 'dayAndWeek',
          startDate: self.currentDate,
          endDate: self.dateHelper.add(self.currentDate, 1, 'month'),
        });
        if (self.currentDate.getTime() == self.dateHelper.getFirstDateOfMonth(new Date()).getTime()) {
          self.$nextTick(() => {
            self.schEngine.scrollToNow({ block: 'start' });
          });
        }
      }
      const year = self.currentDate.getFullYear();
      const month = self.currentDate.getMonth();
      self.$globalApiClient.shift.shiftSearch({ month: month + 1, year }).then((res) => {
        self.events = res.data.Data.map((item) => ({
          id: item.Id,
          resourceId: item.UserId,
          userName: item.NameSurname,
          name: item.ShiftType,
          startDate: new Date(bryntum.scheduler.DateHelper.getEndOfPreviousDay(new Date(item.StartDate)).getTime() + 1),
          shiftStartDate: item.StartDate,
          endDate: new Date(bryntum.scheduler.DateHelper.getStartOfNextDay(new Date(item.StartDate)).getTime() - 1),
          shiftEndDate: item.EndDate,
          eventColor: item.ColorName.toLowerCase(),
          shiftTypeId: item.ShiftTypeId,
        }));
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
      // self.getTimeRanges(year, month);
    },

    getTimeRanges(year, month) {
      const self = this;
      self.timeRanges = [];
      const days = self.dateHelper.daysInMonth(self.currentDate);
      for (let i = 0; i <= days; i++) {
        self.shiftTypes.forEach((shift) => {
          const sTimes = shift.StartTime.split(':');
          const eTimes = shift.EndTime.split(':');
          let dayDiff = 0;
          if (shift.StartTime > shift.EndTime) dayDiff++;
          self.timeRanges.push({
            name: shift.Name,
            cls: `shift timerange-${shift.Color.Name.toLowerCase()}`,
            startDate: new Date(year, month, i, sTimes[0], sTimes[1], sTimes[2]),
            endDate: new Date(year, month, i + dayDiff, eTimes[0], eTimes[1], eTimes[2]),
          });
        });
      }
    },

    saveShift(event) {
      const self = this;
      let id = 0;
      const shift = {
        UserId: event.resourceId,
        Id: event.id,
        ShiftTypeId: event.shiftTypeId,
        ShiftDate: dateFormat.postDateFormat(event.startDate),
      };
      return new Promise((resolve) => {
        self.$globalApiClient.shift.shiftSave(shift).then((response) => {
          if (response.data.IsSuccess) {
            toastr.success(self.translate(`${shift.Id > 0 ? 'update' : 'save'}_successfully`));
            id = response.data.Data.Id;
          } else {
            toastr.error(response.data.Message);
          }
          resolve(id);
        }, (error) => {
          resolve(id);
        });
      });
    },

    deleteShift(id) {
      const self = this;
      const shiftDate = self.schEngine.eventStore.getById(id).startDate;
      const todayStart = new Date(new Date().setHours(0,0,0,0));
      const shiftDeleteLimitDate = new Date(new Date(todayStart).setDate(new Date(todayStart).getDate() - self.deletableDayCount)); //const shiftDeleteLimitDate = moment().startOf('day').toDate();

      if (shiftDate < shiftDeleteLimitDate) {
        toastr.error(self.translate('unable_to_delete_shift'));
        return;
      }
      blockui.pageBlock(self);
      self.$globalApiClient.shift.shiftDelete({id: Number.isInteger(id) ? id : 0}).then((response) => {
        if (response.data.IsSuccess) {
          toastr.success(self.translate('delete_successfully'));
          self.schEngine.eventStore.getById(id).remove();
        } else toastr.error(response.data.Message);
      }, (error) => {
      });
      blockui.unBlockPage();
    },
  },
};
