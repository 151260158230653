import Vue from 'vue';
import Vuex from 'vuex';
import location from './modules/location.js';
import procedure from './modules/procedure.js';
import room from './modules/room.js';
import capacity_equipment from './modules/capacity_equipment.js';
import building from './modules/building.js';
import work from './modules/work.js';
import qualification from './modules/qualification.js';
import infrastructure from './modules/infrastructure.js';
import brand from './modules/brand.js';
import floor from './modules/floor.js';
import product from './modules/product.js';
import device from './modules/device.js';
import training from './modules/training.js';
import user from './modules/user.js';
import unit from './modules/unit.js';
import suitable_status from './modules/suitable_status.js';
import maintenance from './modules/maintenance.js';
import vendor from './modules/vendor.js';
import job from './modules/job.js';
import languages from './modules/languages.js';
import enums from './modules/enums.js';
import model from './modules/model.js';
import reference_library from './modules/reference_library.js';
import status from './modules/status.js';
import description from './modules/description.js';
import work_order from './modules/work_order.js';
import minimum_staff from './modules/minimum_staff.js';
import form from './modules/form.js';
import common from './modules/common.js';
import tool from './modules/tool/tool.js';
import work_order_forms from './modules/work_order_forms.js';
import overtime from './modules/overtime.js';
import excuse from './modules/excuse.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    location,
    procedure,
    room,
    capacity_equipment,
    building,
    work,
    qualification,
    infrastructure,
    brand,
    floor,
    product,
    device,
    training,
    user,
    unit,
    suitable_status,
    maintenance,
    vendor,
    job,
    languages,
    enums,
    model,
    reference_library,
    status,
    description,
    work_order,
    minimum_staff,
    form,
    common,
    tool,
    work_order_forms,
    overtime,
    excuse
  },
});
