import Vue from 'vue';
import {
  minValue, required, minLength, maxLength,
} from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import * as api from '../../../../scripts/services/api';
import * as blockui from '../../../../helpers/block-ui';
import { mixinz } from '../../../../scripts/global/mixinz';
import PointSelections from '../PointSelections/PointSelections';

export default {
  components: {
    'point-selections': PointSelections,
  },
  mixins: [mixinz],
  props: ['selectedPoint', 'isUpdate', 'pointOrigin'],
  /* props: ['isUpdate'], */
  validations() {
    let selectionValidationRules = {};
    if (this.isPointTypeDigital) {
      selectionValidationRules = { minLength: minLength(2), maxLength: maxLength(2) };
    } else if (this.isPointTypeMultistate) {
      selectionValidationRules = { minLength: minLength(2) };
    }
    return {
      point: {
        DefinitionId: {
          required,
          minValue: minValue(1),
        },
        FormQuestionTypeId: {
          required,
          minValue: minValue(1),
        },
        Name: {
          required,
        },
        PointSelections: {
          ...selectionValidationRules,
          $each: {
            Name: {
              required,
            },
          },
        },
      },
    };
  },
  data() {
    return {
      point: {},
      setUpdateData: {},
      pointDefinitions: [],
      questionTypes: [],
    };
  },
  async created() {
    this.questionTypes = [
      {
        Id: this.enumFormQuestionType.number,
        Name: this.translate('number_input'),
      },
      {
        Id: this.enumFormQuestionType.decimal,
        Name: this.translate('decimal_input'),
      },
    ];
    this.pointDefinitions = (await api.getPointDefinitionList()).body;

    if (this.point && this.point.PointSelections && this.point.PointSelections.length > 0) {
      this.point.PointSelections = this.point.PointSelections.sort((x, y) => x.Order - y.Order);
    }
  },
  mounted() {
    const self = this;
    $('#point_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
    this.point = this.selectedPoint;
  },
  computed: {
    ...mapState({
      enumPointType: (state) => state.enums.pointType,
      enumFormQuestionType: (state) => state.enums.formQuestionType,
    }),
    selectedPointDefinition() {
      return this.pointDefinitions.find((pointDefinition) => pointDefinition.Id == this.point.DefinitionId);
    },
    isPointTypeAnalog() {
      return this.selectedPointDefinition && this.selectedPointDefinition.PointTypeId == this.enumPointType.analog;
    },
    isPointTypeDigital() {
      return this.selectedPointDefinition && this.selectedPointDefinition.PointTypeId == this.enumPointType.digital;
    },
    isPointTypeMultistate() {
      return this.selectedPointDefinition && this.selectedPointDefinition.PointTypeId == this.enumPointType.multistate;
    },
    pointDefinition: {
      get() {
        const self = this;
        return self.selectGet('pointDefinitions', 'point.DefinitionId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('point.DefinitionId', 'Id', newValue);
      },
    },
    questionType: {
      get() {
        const self = this;
        return self.selectGet('questionTypes', 'point.FormQuestionTypeId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('point.FormQuestionTypeId', 'Id', newValue);
      },
    },
    isEditable() {
      return !!this.point.IsEditable;
    },
  },
  methods: {
    onSubmit(e) {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      blockui.blockModal(self);
      api.savePoint(this.point).then((response) => {
        if (response.body.IsSuccess) {
          toastr.success(self.translate('item_saved_successfully').formatString(self.point.Name));
          $('#point_modal').modal('toggle');
          if (self.$route.meta.xyz) {
            Vue.getDatatables('.point_list');
            if (!self.isUpdate) {
              if (self.pointOrigin == 'rooms') {
                self.$parent.slc.rooms.PointCount = self.$parent.slc.rooms.PointCount ? self.$parent.slc.rooms.PointCount + 1 : 1;
              }
              if (self.pointOrigin == 'equipments') {
                self.$parent.slc.equipments.PointCount = self.$parent.slc.equipments.PointCount ? self.$parent.slc.equipments.PointCount + 1 : 1;
              }
              if (self.pointOrigin == 'devices') {
                self.$parent.slc.devices.PointCount = self.$parent.slc.devices.PointCount ? self.$parent.slc.devices.PointCount + 1 : 1;
              }
            }
          }
        } else {
          toastr.error(response.body.Message);
        }
        blockui.unBlockModal();
      }, (error) => {
        blockui.unBlockModal();
      });
    },
    addOption() {
      if (!this.point.PointSelections) {
        Vue.set(this.point, 'PointSelections', []);
      }
      const maxOrder = this.point.PointSelections.reduce((max, selection) => (max > selection.Order ? max : selection.Order), 0);
      this.point.PointSelections.push({ Name: '', Order: maxOrder + 1 });
    },
    async removeOption(removedItem, index) {
      const self = this;
      self.point.PointSelections.splice(index, 1);
      self.point.PointSelections.forEach((item, index) => {
        if (item.Order != index + 1) {
          item.Order = index + 1;
        }
      });
    },
    updateOrder(evt) {
      this.point.PointSelections.forEach((item, index) => {
        if (item.Order != index + 1) {
          item.Order = index + 1;
        }
      });
    },
    onPointDefinitionChanged(e) {
      if (e.PointTypeId == this.enumPointType.digital || e.PointTypeId == this.enumPointType.multistate) {
        Vue.set(this.point, 'FormQuestionTypeId', this.enumFormQuestionType.multipleChoice);
        const selectionCount = this.point ? this.point.PointSelections ? this.point.PointSelections.length : 0 : 0;
        // if selection count less then 2 add selection
        for (let i = selectionCount; i < 2; i++) {
          if (!this.point.PointSelections) {
            Vue.set(this.point, 'PointSelections', []);
          }
          this.addOption();
        }
        // if selection count more then 2 and point type is digital delete unnecessary ones
        if (e.PointTypeId == this.enumPointType.digital && this.point.PointSelections.length > 2) {
          this.point.PointSelections.splice(2);
        }
      } else {
        this.point.PointSelections = [];
        Vue.set(this.point, 'FormQuestionTypeId', null);
      }
    },
  },
  watch: {
    selectedPoint(newVal) {
      this.point = newVal;
    },
  },
};
