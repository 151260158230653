import * as api from '../../scripts/services/api';

const state = {
  userOvertimeList: [],
};

// getters
const getters = {};

// actions
const actions = {
  async getUserOvertimeList({ commit }, userId) {
    const userOvertimeList = (await api.getUserOvertimeList({ userId })).body;
    commit('setUserOvertimeList', userOvertimeList);
  }
};

// mutations
const mutations = {
  setUserOvertimeList(state, userOvertimes) {
    state.userOvertimeList = userOvertimes;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};