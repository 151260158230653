import * as api from '../../scripts/services/api';

// initial state
const state = {
  buildingList: [],
};

// getters
const getters = {};

// actions
const actions = {
  getBuildingList({ commit }) {
    api.getAllBuildings().then((res) => {
      commit('setBuildingList', res.body);
    });
  },
};

// mutations
const mutations = {
  setBuildingList(state, buildings) {
    state.buildingList = buildings;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
