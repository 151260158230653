

























































































































































































































import CFMDialog from "@/scripts/CFMDialog";
import { Component, Prop, Vue } from "vue-property-decorator";

import * as blockui from "@/helpers/block-ui";
import * as swal from "@/helpers/swal";
import * as dateFormat from '@/helpers/dateFormat';

import { CFMModelSuitableWorkUserSP } from "@/scripts/services/ClientApiAuto";
import { mixinz } from "@/scripts/global/mixinz";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns"; //import { MultiSelectComponent, CheckBoxSelection } from "@syncfusion/ej2-vue-dropdowns";
import Multiselect from 'vue-multiselect'
import MinutePicker from '@/components/shared/minutepicker.vue';
import BootstrapSwitchComponent from '@/components/shared/BootstrapSwitchComponent';
import { CFMDTOWorkOrder, CFMModelWorkWithBreadCrumbView, CFMCoreContractListComboSource, CFMCoreEntityComboSource, CFMDTOMaintenancePlan, CFMDTOMaintenancePlanUpdate, CFMDTOWorkForMaintenancePlan, CFMDTOContract } from '@/scripts/services/ClientApiAuto'

@Component<MaintenancePlanTransformToWorkOrderDialog>({
  data() {
    return {};
  },

  methods: {},

  components: {
    "ejs-multiselect": MultiSelectComponent,
    'vue-multiselect': Multiselect,
    'minutepicker': MinutePicker,
    'bootstrap-switch-component': BootstrapSwitchComponent,
  },

  /*
  provide: {
    'multiselect': [CheckBoxSelection]
  },
  */

  validations() {
    return {};
  },

  mixins: [mixinz],
})
export default class MaintenancePlanTransformToWorkOrderDialog extends CFMDialog {
  @Prop()
  selectedMaintenancePlans?: Array<CFMDTOMaintenancePlan>;

  activeTab = {Id:0};
  assignedStaffNames = "";
  assignedObserverNames = "";
  startDate: null | Date = null;
  maintenancePlan: CFMDTOWorkOrder = {Name: '', Duration: 0, DeviceId: null};
  maintenanceTypeList : CFMCoreEntityComboSource[] = [];
  updateGetResponse: any = {};
  contract: CFMCoreContractListComboSource | null = null;
  contractList : CFMCoreContractListComboSource[] = [];
  sendMail=false;
  vendorList : {Name:string, Id:number}[] = [];

  userData: CFMModelSuitableWorkUserSP[] = [];
  observerUserData: CFMModelSuitableWorkUserSP[] = [];

  userFields = { groupBy: "Role", text: "UserDisplayText", value: "Id" };
  observerUserFields = { groupBy: "Role", text: "ObserverUserDisplayText", value: "Id" };

  userDataPlaceHolder = "İşi Yapacak Personel Seçiniz";
  observerUserDataPlaceHolder = "Refakatçi Personel Seçiniz";
  /*
  multiMode = 'CheckBox';
  filterPlaceholder = 'Search vegetables';
  showSelectAll = true;
  showDropDownIcon = true;
  enableSelectionOrder = false;
  enableGroupCheck = true;
  popupHeight = "250px";
  */

  userDataForSelect = [];
  observerUserDataForSelect = [];

  selectedForMasterUser = [];
  selectedForObserverUser = [];

  loading = false;

  groupBy(list:any, keyGetter:any) {
    const map = new Map();
    list.forEach((item:any) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
  }

  changeActiveTab(workOrder: any) {
    this.activeTab = workOrder;
    //@ts-ignore
    this.maintenancePlan = this.updateGetResponse.WorkOrders.find((item:any) => item.WorkOder.Id === this.activeTab.Id).WorkOder;
    //@ts-ignore
    this.startDate = moment(this.maintenancePlan.ScheduledTime).toDate();
    //@ts-ignore
    this.contractList = this.updateGetResponse.WorkOrders.find((item:any) => item.WorkOder.Id === this.activeTab.Id).Contracts.map(x=>(
      {
          Text:x.Name + `\n(${dateFormat.viewDateFormat(x.StartDate)} - ${dateFormat.viewDateFormat(x.EndDate)})`, 
          Value: x.Id, 
          StartDate: x.StartDate,
          EndDate: x.EndDate
      }
    ))
    //@ts-ignore
    this.assignedStaffNames = this.maintenancePlan.AssignedStaffNames;
    //@ts-ignore
    this.assignedObserverNames =  this.maintenancePlan.AssignedObserverNames;
    this.vendorList = this.updateGetResponse.Vendors.map((x:any)=>({Name:<string>x.Text, Id:<number>x.Value}));
  }

  get StartDate(){
      if (this.startDate) {
        // @ts-ignore
        return moment(this.startDate).format(this.pickerDateTimeFormat);
      }
      return null;
  }
  set StartDate(val){
      if (val) {
        // @ts-ignore
        Vue.set(this, 'startDate', moment(val, this.pickerDateTimeFormat).toDate());
        this.maintenancePlan.ScheduledTime = dateFormat.postDateTimeFormat(<Date>this.startDate)
      } else {
        Vue.set(this, 'startDate', val);
        this.maintenancePlan.ScheduledTime = dateFormat.postDateTimeFormat(<Date>this.startDate)
      }
  }
  get selectedContract() {
      // @ts-ignore
      return this.selectGet('contractList', 'maintenancePlan.ContractId', 'Value', 'Text');
    }

    set selectedContract(newValue){
      this.contract = newValue;
      // @ts-ignore
      return this.selectSet('maintenancePlan.ContractId', 'Value', newValue);
    }


  async created() {
    const promises = [
      this.getMultipleMaintenancePlanUpdate()
    ];
    await Promise.all(promises);
    //@ts-ignore
    this.activeTab = this.selectedMaintenancePlans[0];
  }

  async getMultipleMaintenancePlanUpdate() {
    blockui.blockModal(this);
    this.loading = true;
    let idArray = this.selectedMaintenancePlans?.map((maintenancePlan) => maintenancePlan.Id);
    //@ts-ignore
    this.apiClient.maintenancePlan.getMultipleMaintenancePlanUpdate({workOrderIds:idArray?.toString()}).then((response) => {
        //@ts-ignore
        this.maintenanceTypeList = response.data.WorkOrderTypes;
        this.updateGetResponse = response.data;
        //@ts-ignore
        this.maintenancePlan = response.data.WorkOrders.find((item: any) => item.WorkOder.Id === this.activeTab.Id).WorkOder;
        //@ts-ignore
        this.assignedStaffNames = this.maintenancePlan.AssignedStaffNames;
         //@ts-ignore
        this.assignedObserverNames =  this.maintenancePlan.AssignedObserverNames;
        //@ts-ignore
        this.startDate = moment(this.maintenancePlan.ScheduledTime).toDate();
        //@ts-ignore
        this.contractList = this.updateGetResponse.WorkOrders.find((item:any) => item.WorkOder.Id === this.activeTab.Id).Contracts.map(x=>(
          {
            Text:x.Name + `\n(${dateFormat.viewDateFormat(x.StartDate)} - ${dateFormat.viewDateFormat(x.EndDate)})`, 
            Value: x.Id, 
            StartDate: x.StartDate,
            EndDate: x.EndDate
          }
        ))
        this.vendorList = this.updateGetResponse.Vendors.map((x:any)=>({Name:<string>x.Text, Id:<number>x.Value}));

        this.userData = [];
        this.observerUserData = [];

        //@ts-ignore
        response.data.SuitableWorkUsers.forEach((user) => {
          user.ShiftDescription = user.IsHaveShift ? "Shift: Yes" : "Shift: No";
          user.OverlappedDescription = user.IsHaveWorkOrder ? "Workload Availability: No" : "Workload Availability: Yes";

          if (user.IsAssignableToMaster) {
            user.UserTrainedDescription = user.IsTrainedForMaster ? "Trained: Yes" : "Trained: No";
            user.UserDisplayText = `${user.Name} - ${user.UserTrainedDescription} - ${user.ShiftDescription} - ${user.OverlappedDescription}`;
            this.userData.push(user);
          }
          
          if (user.IsAssignableToObserver) {
            user.ObserverUserTrainedDescription = user.IsTrainedForObserver ? "Trained: Yes" : "Trained: No";
            user.ObserverUserDisplayText = `${user.Name} - ${user.ObserverUserTrainedDescription} - ${user.ShiftDescription} - ${user.OverlappedDescription}`;
            this.observerUserData.push(user);
          }
        });
        //@ts-ignore
        this.userDataForSelect = Array.from(this.groupBy(this.userData, user => user.Role), ([role, value]) => ({ role, value }));
        //@ts-ignore
        this.observerUserDataForSelect = Array.from(this.groupBy(this.observerUserData, user => user.Role), ([role, value]) => ({ role, value }));
        blockui.unBlockModal();
        this.loading = false;
    })
  }

  async onSubmit() {
    blockui.blockModal(this);
    blockui.unBlockModal();
  }

  async handleSave() {
    let hasUserShiftWarning: boolean = false;
    let hasUserOverlapWarning: boolean = false;

    let hasObserverUserShiftWarning: boolean = false;
    let hasObserverOverlapWarning: boolean = false;

    this.selectedForMasterUser?.forEach(async (selectedUser) => {
      if (selectedUser !== undefined && selectedUser !== null) {
        //@ts-ignore
        hasUserShiftWarning = (selectedUser.IsHaveShift ?? false) == false;
        //@ts-ignore
        hasUserOverlapWarning = (selectedUser.IsHaveWorkOrder ?? false) == true;
      }
    });

    this.selectedForObserverUser?.forEach(async (selectedObserverUser) => {
      if (selectedObserverUser !== undefined && selectedObserverUser !== null) {
        //@ts-ignore
        hasObserverUserShiftWarning = (selectedObserverUser.IsHaveShift ?? false) == false;
        //@ts-ignore
        hasObserverOverlapWarning = (selectedObserverUser.IsHaveWorkOrder ?? false) == true;
      }
    })

    if(hasUserShiftWarning || hasUserOverlapWarning || hasObserverUserShiftWarning || hasObserverOverlapWarning) {
      const alertTitle: string = "Atanmak istenen iş emirleri !!!";
      const alertMessage: string = 
        (hasUserShiftWarning || hasUserOverlapWarning ? "İşi Yapacak Personelin(Personellerin) " : "") +
        (hasUserShiftWarning ? "vardiyası ile eşleşmemektedir, " : "") +
        (hasUserOverlapWarning ? "diğer bir iş emriyle çakışan işleri bulunmaktadır. " : "") + "<br>" +
        (hasObserverUserShiftWarning || hasObserverOverlapWarning ? "Refakatçi Personelin(Personellerin) " : "") +
        (hasObserverUserShiftWarning ? "vardiyası ile eşleşmemektedir, " : "") + 
        (hasObserverOverlapWarning ? "diğer bir iş emriyle çakışan işleri bulunmaktadır." : "");

        // @ts-ignore
        let swalResponse = await swal.checkHtml(this, alertTitle, alertMessage)
        swalResponse.dismiss
        if (swalResponse !== undefined && swalResponse.dismiss !== undefined && swalResponse.dismiss === "cancel") { // if (!swalResponse.value) {
          return;
        }
    }
 
    let AssigneeUsers = [];
    blockui.blockModal(this);
    //@ts-ignore
    for (let i = 0; i < this.selectedMaintenancePlans?.length; i++) {
      for (let j = 0; j < this.selectedForMasterUser.length; j++) {
        AssigneeUsers.push({
          // @ts-ignore
          WorkOrderId: this.selectedMaintenancePlans[i].Id,
          //@ts-ignore
          UserId: this.selectedForMasterUser[j].Id,
          UserPositionId: 1
        });
      }
    }
    // @ts-ignore
    for (let i = 0; i < this.selectedMaintenancePlans?.length; i++) {
      for (let j = 0; j < this.selectedForObserverUser.length; j++) {
        AssigneeUsers.push({
          // @ts-ignore
          WorkOrderId: this.selectedMaintenancePlans[i].Id,
          //@ts-ignore
          UserId: this.selectedForObserverUser[j].Id,
          UserPositionId: 2,
        });
      }
    }

    let MaintenancePlans:any = [];
    let dataList = this.updateGetResponse.WorkOrders.map((x:any) => (x.WorkOder))
    dataList.forEach((dataItem:any) => {
        MaintenancePlans.push({
            Id : dataItem.Id,
            Note : dataItem.Note,
            //@ts-ignore
            StartDate : dataItem.ScheduledTime,
            Duration : dataItem.Duration,
            MaintenanceTypeId : dataItem.OrderTypeId,
            VendorNotificationRemainingDay : dataItem.VendorNotificationRemainingDay,
            VendorId : dataItem.VendorId,
            ContractId: dataItem.ContractId
        })
    })

    console.log({AssigneeUsers,MaintenancePlans})


    try {
      let response = await this.apiClient.maintenancePlan.updateMultipleMaintenance({AssigneeUsers,MaintenancePlans});
      if(response.data.IsSuccess){
        swal.success("Kaydedildi","Değişiklikler başarılı bir şekilde kaydedildi")
      }else{
        swal.error("Hata","Bir hata oluştu")
      }
      blockui.unBlockModal();
      this.saveSucceeded();
      this.close();
    } 
    catch (error) {
      swal.error("Hata","Bir hata oluştu")
      blockui.unBlockModal();
      this.saveSucceeded();
      this.close();
    }
  }
} 
