








































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'
import {required} from "vuelidate/lib/validators";

import { CFMDTOWorkOrderType } from '@/scripts/services/ClientApiAuto'

@Component<WorkOrderTypeDialog>({
    components: {
    },
    validations() {
        return {
        workOrderType:{
            Name: {required}
        }
        }
    },
})
export default class WorkOrderTypeDialog extends CFMDialog {
    workOrderType: CFMDTOWorkOrderType = {Name: ''};

    selectedWorkOrderType :CFMDTOWorkOrderType = {Name: ''}

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.workOrderType.workOrderTypeGetById({id: <number>this.id});
        this.workOrderType = res.data;
      }
    
      if(this.isUpdate){
      if(this.workOrderType ){
          this.selectedWorkOrderType  = this.workOrderType;
      }
      }
      blockui.unBlockModal();
    }
    
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Id: this.workOrderType.Id,
        Name: this.workOrderType.Name
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.workOrderType.workOrderTypeSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
