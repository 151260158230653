import { AxiosError, AxiosResponse } from 'axios';
import { Api, ApiConfig, RequestParams } from './ClientApiAuto';

export class APIError {
  public message: string;
  public statusCode: number;
  constructor(message: string, statusCode: number) {
    this.message = message;
    this.statusCode = statusCode;
  }
}
export class OtherApi<SecurityDataType> extends Api<SecurityDataType> {
  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    super(apiConfig);

    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
      },
      (err: AxiosError) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        const status = err.response?.status || 500;
        // we can handle global errors here
        var message: string = err.response && err.response.data ? err.response.data : err.message;
        switch (status) {
          // authentication (token related issues)
          case 401: {
            toastr.warning("Token expired", message);
            window.location.href = '#/Logout';
            return Promise.reject(new APIError(message, 401));
          }
          // forbidden (permission related issues)
          case 403: {
            toastr.error("403 Permission Denied", message);
            return Promise.reject(new APIError(message, 403));
          }
          // bad request
          case 400: {
            return Promise.reject(new APIError(message, 400));
          }
          // not found
          case 404: {
            return Promise.reject(new APIError(message, 404));
          }
          // conflict
          case 409: {
            return Promise.reject(new APIError(message, 409));
          }
          // unprocessable
          case 422: {
            return Promise.reject(new APIError(message, 422));
          }
          // generic api error (server related) unexpected
          default: {
            return Promise.reject(new APIError(message, 500));
          }
        }
      }
    );
  }

  public getOdataEntityQuery<T>(entityType: string, query?: any, params: RequestParams = {}): Promise<AxiosResponse<ODataResult<T> | T[]>> {
    return this.request<ODataResult<T> | T[]>({
      path: `/odata/${entityType}`,
      method: 'GET',
      query,
      ...params,
    });
  }
}
export interface ODataResult<T> {
  '@odata.context': string,
  '@odata.count': number;
  value: T[];
}

export interface DataResult<T> {
  result: T[];
  count: number;
  aggregates?: object;
}