import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../../services/api';
import * as blockui from '../../../helpers/block-ui';

export default {
  props: ['descriptionItem', 'isUpdate'],
  data() {
    return {
    };
  },
  components: {},
  computed: {
    ...mapState({
      descriptionModalDeviceList: (state) => state.device.descriptionModalDeviceList,
    }),
  },
  mounted() {
    const self = this;
    window.$('form').validate();

    self.$store.dispatch('getDescriptionModalDeviceList');

    $('#description_item_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
  },
  methods: {
    async onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        /* api.saveDescription(formData).then(function (response) {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            self.$store.dispatch('getDescriptionList', {deviceId: self.descriptionItem.DeviceId});
            $('#description_item_modal').modal('toggle');
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        }); */
        const response = await api.saveDescription(formData);
        if (response.body.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
          await self.$store.dispatch('getDescriptionList', { deviceId: self.descriptionItem.DeviceId });
          $('#description_item_modal').modal('toggle');
        } else {
          toastr.error(response.body.Message);
        }
        blockui.unBlockModal();
      }
    },
    closeModal() {
      $('#description_item_modal').modal('toggle');
    },
  },
};
