/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable class-methods-use-this */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { OtherApi } from '@/scripts/services/OtherApis';

@Component({})
export default class CFMDialog extends Vue {
    apiClient: OtherApi<any>;

    @Prop()
    id?: number;

    constructor() {
      super();
      this.apiClient = this.$globalApiClient;
    }

    public created() {

    }

    public mounted() {
      $(this.$el).on('hidden.bs.modal', () => this.$emit('close'));
    }

    get isUpdate() {
      return !!this.id;
    }

    public saveSucceeded() {
      this.$emit('save-succeeded');
    }

    public close() {
      (<any>$(this.$el)).modal('toggle');
    }

    public showDialog() {
      (<any>$(this.$el)).modal({
        backdrop: 'static',
        show: true,
      });
    }
}
