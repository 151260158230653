<template>
    <div id="history_step">
        <div class="column-item" v-for="(item,index) in sparePartHistory" :id="sparePartId" :key="index">
            <div class="xxx">
                <div class="row">
                    <p class="col-12 col-md-2">
                        <label v-lang.spare_part_process_type></label>
                        <strong>{{item.SparePartProcessTypeName}}</strong>
                    </p>
                    <p class="col-12 col-md-2">
                        <label v-lang.user></label>
                        <strong><i class="fas fa-user"></i>{{item.UserName}}</strong>
                    </p>
                    <p class="col-12 col-md-2">
                        <label v-lang.created_date></label>
                        <strong>{{item.CreatedDate}}</strong>
                    </p>
                    <p class="col-12 col-md-2">
                        <label v-lang.sp_process></label>
                        <strong>{{item.Process}}</strong>
                    </p>
                    <p class="col-12 col-md-2">
                        <label v-lang.serial_number></label>
                        <strong>{{item.SerialNumber}}</strong>
                    </p>
                    <p class="col-12 col-md-2">
                        <label v-lang.description></label>
                        <strong>{{item.Description}}</strong>
                    </p>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    name:'HistoryStep2',
    data() {
        return {
            sparePartHistory:[],
            sparePartComment:{}
        };
    },
    props: [
        'sparePartId',
    ],
    async created(){
        //@ts-ignore
        let response = await this.$globalApiClient.sparePartHistory.sparePartGetHistories({sparePartId: this.sparePartId});
        //@ts-ignore
        this.sparePartHistory = response.data;
    },
}
</script>