import grid from './grid';
import { getDto } from './dto';

export default {
  created() {
    this._dto = this.dto;
    this.DTO = getDto(this._dto || '?', true);
    this.title = this._dto && this.translate(this._dto.split(/(?=[A-Z])/).join('_').toLowerCase());
    this.$parent && (this.$parent.title = this.title);
  },
  methods: {
    gridController() {
      new bryntum.scheduler.Combo({
        flex: 4,
        margin: 5,
        insertFirst: this.$refs.grid.gridController.element,
        items: getDto(),
        value: this._dto,
        onChange: ({ value }) => {
          this.display = null;
          this._dto = value;
          this.DTO = getDto(this._dto || '?', true);
          this.title = this._dto && this.translate(this._dto.split(/(?=[A-Z])/).join('_').toLowerCase());
          this.$parent && (this.$parent.title = this.title);
          this.$nextTick(() => this.display = true);
        },
      });

      this.field && this.value && this.$refs.grid.engine.store.filter({
        property: this.field,
        value: this.value,
        operator: '=',
      });
    },
  },
  data() {
    return {
      _dto: null,
      display: true,
      DTO: null,
      title: null,
    };
  },
  props: ['dto', 'field', 'value'],
  components: {
    grid,
  },
};
