import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as api from '@/scripts/services/api';
import * as blockui from '@/helpers/block-ui';
import { mixinz } from '@/scripts/global/mixinz';
import upload from '@/components/shared/upload';
import Questions from '@/components/partials/Questions';
import * as swal from '@/helpers/swal';
import PointTree from '@/components/views/Point/PointTree/PointTree.vue';

export default {
  mixins: [mixinz],
  props: {
    slcForm: {
      default() {
        return {};
      },
      type: Object,
    },
    slcWorkOrderId: {
      type: Number,
    },
    isUpdate: { default: false },
  },
  components: {
    upload,
    questions: Questions,
    'point-tree': PointTree,
  },
  data() {
    return {
      isUploadBusy: false,
      selectedForm: {},
      cloneForm: null,
      selectedDevices: [],
      formTemplate: null,
      questionGroups: [],
      // showQuestions: false,
      // TODO : Eren - Check is used or unnecessary
      showQuestionModal: false,
      formSources: [],
      fileStatuses: {
        uploading: 1,
        uploaded: 2,
        canceled: 3,
        error: 4,
        deleted: 5,
      },
      files: [],
      cloneFiles: [],
      selectedFormSectionId: null,
      selectedQuestionGroupId: null,
      formDeviceId: null,
      formRoomId: null,
      selectedPointId: null,
      draggedPointId: null,
      isPointDragged: false,
      isDragEnter: false,
      comparisonOperators: [],
      operandTypes: [],
      suitableFormStatuses: [],
      isUserActivity: true,
      isDirty: false
    };
  },
  async created() {
    const self = this;
    blockui.blockModal(this);
    self.formSources = [
      { Name: self.translate('capacity_equipment'), Id: 1 },
      { Name: self.translate('brand'), Id: 2 },
      { Name: self.translate('model'), Id: 3 },
    ];
  },
  async mounted() {
    blockui.blockModal(this);
    const self = this;

    $(document).off('hide.bs.modal', '#form_modal').on('hide.bs.modal', '#form_modal', () => {
      if (!self.isDirty) return true;
      const buttons = [
        {
          cls: 'btn btn-warning',
          id: 'close',
          text: self.translate('exit'),
          onClick: () => {
            self.isDirty = false;
            this.$emit('save-succeeded');
            $('#form_modal').modal('toggle');
          },
        }, {
          cls: 'btn',
          id: 'save_and_close',
          text: self.translate('save_and_exit'),
          onClick: () => {
            self.onSubmit({ target: self.$el.querySelector('form') }, () => {
              self.isUserActivity = false;
              this.$emit('save-succeeded');
              $('#form_modal').modal('toggle');
            });
          },
        }, {
          cls: 'btn btn-success',
          id: 'cancel',
          text: self.translate('cancel'),
        },
      ];
      swal.checkWithMultipleButtons(self, buttons, self.translate('are_you_sure_you_want_to_leave_the_page'), self.translate('you_have_unsaved_changes'));
      return false;
    });

    const promises = [
      this.$globalApiClient.comparisonOperator.comparisonOperatorGetList(),
      this.$globalApiClient.operandType.operandTypeGetList(),
      this.$store.dispatch('getCapacityEquipmentBreadCrumbList'),
      this.$store.dispatch('getRoomList'),
      this.$store.dispatch('getBrandList'),
      this.$store.dispatch('getModelList'),
      this.$store.dispatch('getProcedureTypes'),
    ];

    if (this.isUpdate) {
      promises.push(this.getForm());
      promises.push(this.getFormQuestionGroups());
    } else {
      this.selectedForm = {
        FormBrands: [],
        FormModels: [],
        FormDevices: [],
      };
    }
    this.$eventHub.$on('getFormQuestionGroups', this.getFormQuestionGroups);
    this.$eventHub.$on('getFormQuestions', this.getFormQuestionGroups);

    const responses = await Promise.all(promises);

    this.comparisonOperators = responses[0].data;
    this.operandTypes = responses[1].data;

    this.getFormSuitableStatuses();
    this.selectFiles();

    if (this.isUpdate) {
      this.fetchFormSections();
      self.cloneForm = self.deepClone(self.selectedForm);
      if (self.selectedForm.FormModels) {
        self.selectedForm.FormModels = self.selectedForm.FormModels.map((item) => ({
          ModelId: item.ModelId,
        }
        ));
        self.cloneForm.FormModels = self.cloneForm.FormModels.map((item) => ({
          ModelId: item.ModelId,
        }));
      }
      if (self.selectedForm.FormBrands) {
        self.selectedForm.FormBrands = self.selectedForm.FormBrands.map((item) => ({
          BrandId: item.BrandId,
        }
        ));
        self.cloneForm.FormBrands = self.cloneForm.FormBrands.map((item) => ({
          BrandId: item.BrandId,
        }));
      }
      self.isDirty = !self.deepEqual(self.selectedForm, self.cloneForm);
    }

    blockui.unBlockModal();
    window.$('form').validate();
    // this.getFormItems();
    mApp.initTooltips();
    self.$nextTick(() => {
      Vue.loadAsteriks();
    });
    this.onCloseModal('#form_modal');
  },
  computed: {
    ...mapState({
      capacityEquipmentList: (state) => state.capacity_equipment.capacityEquipmentBreadCrumbList,
      roomList: (state) => state.room.roomList,
      brandList: (state) => state.brand.brandList,
      modelList: (state) => state.model.modelList,
      formStatusEnums: (state) => state.enums.formStatus,
      enumProcedureType: (state) => state.enums.procedureType,
    }),
    ...mapGetters({
      procedureTypes: 'getProcedureTypes',
      formSections: 'getFormSections',
    }),
    currentSectionQuestionGroups: {
      get() {
        const self = this;
        return self.questionGroups
          .filter((questionGroup) => questionGroup.FormSectionId == self.selectedFormSectionId)
          .sort((x, y) => x.Order - y.Order);
      },
      set(newVal) {
        const self = this;
        newVal.forEach((questionGroup, index) => {
          questionGroup.Order = index + 1;
        });
        newVal.forEach((questionGroup, index) => {
          Vue.set(self.questionGroups, self.questionGroups
            .findIndex((x) => x.Id === questionGroup.Id), questionGroup);
        });
      },
    },
    sectionQuestionCounts() {
      const self = this;
      return self.questionGroups.reduce((p, c) => {
        const formSectionId = c.FormSectionId;
        if (!p.hasOwnProperty(formSectionId)) {
          p[formSectionId] = 0;
        }
        p[formSectionId] += c.Questions ? c.Questions.length : 0;
        return p;
      }, {});
    },
    selectedFormDevice: {
      get() {
        const self = this;
        return self.selectGet('capacityEquipmentList', 'formDeviceId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('formDeviceId', 'Value', newValue);
      },
    },
    selectedFormRoom: {
      get() {
        const self = this;
        return self.selectGet('roomList', 'formRoomId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('formRoomId', 'Value', newValue);
      },
    },
    selectedFormBrands: {
      get() {
        const self = this;
        return self.selectGet('brandList', 'selectedForm.FormBrands', 'Value', 'Text', true, 'BrandId');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedForm.FormBrands', 'Value', newValue, true, 'BrandId');
      },
    },
    selectedFormModels: {
      get() {
        const self = this;
        return self.selectGet('modelList', 'selectedForm.FormModels', 'Value', 'Text', true, 'ModelId');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedForm.FormModels', 'Value', newValue, true, 'ModelId');
      },
    },
    procedureType: {
      get() {
        return {
          Id: this.selectedForm.ProcedureTypeId,
          Name: this.selectedForm.ProcedureType ? this.selectedForm.ProcedureType.Name : '',
        };
      },
      set(newValue) {
        Vue.set(this.selectedForm, 'ProcedureTypeId', newValue.Id);
        if (!this.selectedForm.ProcedureType) {
          this.selectedForm.ProcedureType = {};
        }
        Vue.set(this.selectedForm.ProcedureType, 'Name', newValue.Name);
        Vue.set(this.selectedForm.ProcedureType, 'Id', newValue.Id);
        return newValue;
      },
    },
    formStatus: {
      get() {
        const self = this;
        return self.selectGet('suitableFormStatuses', 'selectedForm.FormStatusId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedForm.FormStatusId', 'Value', newValue);
      },
    },
    selectedProcedureTypeId() {
      return this.selectedForm ? this.selectedForm.ProcedureTypeId : null;
    },
    isPointSelectable() {
      return this.selectedForm.IsEditable && this.selectedProcedureTypeId === this.enumProcedureType.scp;
    },
  },
  methods: {
    getFormSuitableStatuses() {
      this.$globalApiClient.formStatus.formStatusGetFormSuitableStatuses({ formId: this.selectedForm && this.selectedForm.Id ? this.selectedForm.Id : null })
      .then((res) => {
        this.suitableFormStatuses = res.data;
      });
    },
    async getForm() {
      const self = this;
      const res = await this.$globalApiClient.form.formGetById({id: this.slcForm.Id});
      if (res.data && res.data.FormDevices && res.data.FormDevices.length > 0) {
        Vue.set(self, 'formDeviceId', res.data.FormDevices[0].DeviceId);
      }
      if (res.data && res.data.FormRooms && res.data.FormRooms.length > 0) {
        Vue.set(self, 'formRoomId', res.data.FormRooms[0].RoomId);
      }
      this.selectedForm = res.data;
    },
    removeTempPoint(question) {
      const self = this;
      blockui.blockModal(self);
      this.$globalApiClient.question.questionRemoveTempPoint({questionId: question.Id})
      .then((response) => {
        if (response.data.IsSuccess) {
          self.getFormQuestionGroups();
          toastr.success(self.translate('point_removed'));
        } else {
          toastr.error(response.data.Message);
        }
        blockui.unBlockModal();
      }, (error) => {
        blockui.unBlockModal();
      });
    },
    onTreeItemSelected(e) {
      this.selectedPointId = e.value;
    },
    onDragStart(e) {
      this.isPointDragged = true;
      this.draggedPointId = e.value;
    },
    onDragEnd(e) {
      this.isPointDragged = false;
    },
    setSelectedFormSectionId(id) {
      this.selectedFormSectionId = id;
      if (this.currentSectionQuestionGroups && this.currentSectionQuestionGroups.length > 0) {
        this.selectedQuestionGroupId = this.currentSectionQuestionGroups[0].Id;
      }
    },
    questionGroupDrop(event, questionGroup) {
      if (this.isPointDragged) {
        event.preventDefault();
        event.stopPropagation();
        this.addLinkedQuestion(questionGroup.Id, this.draggedPointId);
      }
    },
    questionDrop(event, question) {
      if (this.isPointDragged) {
        event.preventDefault();
        event.stopPropagation();
        this.changeQuestionPoint(question.Id, this.draggedPointId);
        /* if (question.TempPointId || !question.PointId) {
                    this.changeQuestionPoint(question.Id, this.draggedPointId);
                } else {
                    this.addLinkedQuestion(question.QuestionGroupId, this.draggedPointId)
                } */
      }
    },
    addLinkedQuestion(questionGroupId, pointId) {
      const questionGroup = this.questionGroups.find((questionGroup) => questionGroup.Id == questionGroupId);
      const maxOrder = questionGroup.Questions ? questionGroup.Questions.reduce((max, question) => (max > question.Order ? max : question.Order), 0) : 0;
      const slcQuestion = {
        IsRequired: true,
        QuestionGroupId: questionGroupId,
        PointId: pointId,
        Order: maxOrder + 1,
      };
      this.addItem({
        slcQuestion,
        questionGroups: this.questionGroups,
        procedureTypeId: this.selectedForm.ProcedureTypeId,
      }, 'questionModalProps', 'showQuestionModal', '#question_modal', 'isQuestionUpdate', 1);
      /* questionGroup.Questions.push({Name: "New One", Order: max + 1, Point: {Name: "pointId: " + pointId}}) */
    },
    async changeQuestionPoint(questionId, pointId) {
      const self = this;
      blockui.blockModal(this);
      try {
        const response = await this.$globalApiClient.question.questionChangePoint({ questionId: questionId, pointId: pointId });
        if (response.data.IsSuccess) {
          this.getFormQuestionGroups();
          toastr.success(self.translate('save_successfully'));
        } else {
          toastr.error(response.data.Message);
        }
      } catch (e) {
        throw new Error(e);
      } finally {
        blockui.unBlockModal();
      }
    },
    updateQuestionGroupOrder(evt) {
      const self = this;
      blockui.blockModal(this);
      this.$globalApiClient.questionGroup.questionGroupReOrder(
        self.currentSectionQuestionGroups.map((questionGroup) => ({
          Id: questionGroup.Id,
          Order: questionGroup.Order
        }))
      ).then((res) => {
        if (res.data.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
        }
        blockui.unBlockModal();
      }, (err) => {
        blockui.unBlockModal();
      });
    },

    updateQuestionOrder(questionGroup) {
      const self = this;
      blockui.blockModal(this);
      questionGroup.Questions.forEach((question, index) => {
        question.Order = index + 1;
      });
      this.$globalApiClient.question.questionReOrder(
        questionGroup.Questions.map((question) => ({
          Id: question.Id,
          Order: question.Order
        }))
      ).then((res) => {
        if (res.data.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
        }
        blockui.unBlockModal();
      }, (err) => {
        blockui.unBlockModal();
      });
    },
    selectFiles() {
      const self = this;
      if (!self.selectedForm.FormFiles) {
        self.selectedForm.FormFiles = [];
      }
      this.files = self.selectedForm.FormFiles.map((x) => ({
        fileId: x.FileId,
        title: x.File.Name,
        type: x.File.ContentType,
        isActive: true,
        status: self.fileStatuses.uploaded,
        isLocal: false,
      }));
      if (this.isUpdate) {
        self.cloneFiles = self.deepClone(this.files);
      }
    },
    async getFormQuestionGroups() {
      const self = this;
      this.$globalApiClient.questionGroup.questionGroupGetList({ formId: this.selectedForm.Id ? this.selectedForm.Id : this.slcForm.Id })
      .then((res) => {
        const questionGroups = self.sortQuestionGroups(res.data);
        Vue.set(this, 'questionGroups', questionGroups);
        self.$nextTick(() => {
          mApp.initTooltips();
        });
      });
    },
    sortQuestionGroups(questionGroups) {
      return questionGroups.sort((x, y) => x.Order - y.Order).map((qg) => {
        qg.Questions.sort((x, y) => x.Order - y.Order);
        return qg;
      });
    },
    addQuestion(questionGroup) {
      const maxOrder = questionGroup.Questions ? questionGroup.Questions.reduce((max, question) => (max > question.Order ? max : question.Order), 0) : 0;
      const slcQuestion = {
        IsRequired: true,
        QuestionTypeId: 1,
        Selections: [{ Name: '', Order: 1 }, { Name: '', Order: 2 }],
        QuestionGroupId: questionGroup.Id,
        Order: maxOrder + 1,
      };
      this.addItem({
        slcQuestion,
        questionGroups: this.questionGroups,
      }, 'questionModalProps', 'showQuestionModal', '#question_modal', 'isQuestionUpdate', 1);
    },
    addQuestionGroup() {
      const maxOrder = this.currentSectionQuestionGroups.reduce((max, questionGroup) => (max > questionGroup.Order ? max : questionGroup.Order), 0);
      const slcQuestionGroup = {
        FormId: this.selectedForm.Id,
        FormSectionId: this.selectedFormSectionId,
        Order: maxOrder + 1,
      };
      this.addItem(slcQuestionGroup, 'slcQuestionGroup', 'showQuestionGroupModal', '#question_group_modal', 'isQuestionGroupUpdate', 1);
    },
    async deleteQuestion(item) {
      const self = this;
      swal.check(self, item.Name).then((e) => {
        if (e.value) {
          this.$globalApiClient.question.questionDelete({id: item.Id}).then((response) => {
            if (response.data.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              const questionGroup = self.questionGroups.find((questionGroup) => (!!questionGroup.Questions.find((x) => x.Id == item.Id)));
              if (questionGroup) {
                questionGroup.Questions.splice(questionGroup.Questions.findIndex((x) => x.Id == item.Id), 1);
              }
            } else {
              toastr.error(response.data.Message);
            }
          });
        }
      });
    },
    async deleteQuestionGroup(item) {
      const self = this;
      swal.check(self, item.Name).then((e) => {
        if (e.value) {
          this.$globalApiClient.questionGroup.questionGroupDelete({id: item.Id}).then((response) => {
            if (response.data.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              self.questionGroups.splice(self.questionGroups.findIndex((x) => x.Id == item.Id), 1);
            } else {
              toastr.error(response.data.Message);
            }
          });
        }
      });
    },
    editQuestion(item) {
      this.editItem('getQuestion', {
        slcQuestion: item,
        questionGroups: this.questionGroups,
        procedureTypeId: this.selectedForm.ProcedureTypeId,
      }, 'questionModalProps', 'response.body', 'showQuestionModal', '#question_modal', 'isQuestionUpdate', 1);
    },
    editQuestionGroup(item) {
      this.editItem('getQuestionGroup', item, 'slcQuestionGroup', 'response.body', 'showQuestionGroupModal', '#question_group_modal', 'isQuestionGroupUpdate', 1);
    },
    async fetchFormSections() {
      await this.$store.dispatch('getFormSections', this.selectedForm.ProcedureTypeId);
      if (this.formSections && this.formSections.length > 0) {
        this.setSelectedFormSectionId(this.formSections[0].Id);
      }
      this.$nextTick(() => {
        mApp.initTooltips();
      });
    },
    closeModal() {
      this.$emit('save-succeeded');
      $(this.$el).modal('toggle');
    },
    async onSubmit(e, action) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        blockui.blockModal(self);
        const formData = { ...self.selectedForm };
        Vue.delete(formData, 'QuestionGroups');
        if (self.selectedProcedureTypeId == self.enumProcedureType.sop) {
          formData.FormDevices = [{ DeviceId: self.formDeviceId }];
          formData.FormBrands = [];
          formData.FormModels = [];
          formData.FormRooms = [];
        } else if (self.selectedProcedureTypeId == self.enumProcedureType.eop) {
          formData.FormDevices = [];
          formData.FormModels = [];
          formData.FormRooms = [];
        } else if (self.selectedProcedureTypeId == self.enumProcedureType.mop) {
          formData.FormDevices = [];
          formData.FormBrands = [];
          formData.FormRooms = [];
        } else if (self.selectedProcedureTypeId == self.enumProcedureType.scp) {
          formData.FormModels = [];
          formData.FormBrands = [];
          if (self.formDeviceId > 0) {
            formData.FormDevices = [{ DeviceId: self.formDeviceId }];
          } else {
            formData.FormDevices = [];
          }
          if (self.formRoomId > 0) {
            formData.FormRooms = [{ RoomId: self.formRoomId }];
          } else {
            formData.FormRooms = [];
          }
        }
        formData.FormFiles = self.files.map((x) => ({ fileId: x.fileId }));
        try {
          const questionGroups = await this.$globalApiClient.questionGroup.questionGroupGetList({ formId: this.selectedForm.Id ? this.selectedForm.Id : this.slcForm.Id });
          if (this.selectedForm.FormStatusId === 1) {
            if (questionGroups.data.some(questionGroup => questionGroup.Questions.some(question => question.TempPointView))) {
              toastr.error(this.translate("form_point_warning"));
               blockui.unBlockModal();
              return;
            }
          }           
          const response = await this.$globalApiClient.form.formSave(formData);
          if (response.data.IsSuccess) {
            self.cloneForm = self.deepClone(self.selectedForm);
            self.isDirty = !self.deepEqual(self.selectedForm, self.cloneForm);
            toastr.success(self.translate('save_successfully'));
            if (!self.isUpdate) {
              const res = await this.$globalApiClient.form.formGetById({id: response.data.Message});
              self.$parent.isUpdate = true;
              self.selectedForm = res.data;
              self.fetchFormSections();
              self.getFormQuestionGroups();
              self.getFormSuitableStatuses();
              self.$emit('save-succeeded');
            } else {
              self.$emit('save-succeeded');
              $('#form_modal').modal('toggle');
            }
            blockui.unBlockModal();
            self.cloneForm = self.deepClone(self.selectedForm);
            self.isDirty = !self.deepEqual(self.selectedForm, self.cloneForm);
            if (self.$route.meta.xyz) {
              if (self.isUpdate) {
                self.$eventHub.$emit('getFormItems');
              } else {
                self.$eventHub.$emit('pushForm', self.selectedForm);
              }
            } else {
              self.$emit('formChanged', self.isUpdate ? self.selectedForm.Id : null);
            }
            action && action();
          } else {
            toastr.error(response.data.Message);
            blockui.unBlockModal();
          }
        } catch(error){
          toastr.error(error);
          blockui.unBlockModal();
        }
      }
    },
    showDialog() {
      ($(this.$el)).modal({
        backdrop: 'static',
        show: true,
      });
    }
  },
  beforeDestroy() {
    this.$eventHub.$off('getFormQuestionGroups');
    this.$eventHub.$off('getFormQuestions');
  },
  watch: {
    selectedPointId(e) {
      this.$nextTick(() => {
        mApp.initTooltips();
      });
    },
    selectedForm: {
      handler(newValue) {
        if (this.cloneForm) {
          const initCloneFormDescription = this.cloneForm.Description ? this.cloneForm.Description : '';
          if (this.selectedForm.Description === '' && initCloneFormDescription === '') {
            this.cloneForm.Description = '';
          }
          this.isDirty = !this.deepEqual(this.selectedForm, this.cloneForm);
        }
      },
      deep: true,
    },
    formDeviceId: {
      handler(newValue) {
        this.isDirty = !!(this.isUpdate && this.selectedForm.FormDevices && newValue && (this.selectedForm.FormDevices[0].DeviceId != newValue));
      },
    },
    formRoomId: {
      handler(newValue) {
        this.isDirty = !!(this.isUpdate && this.selectedForm.FormRooms && newValue && (this.selectedForm.FormRooms[0].RoomId != newValue));
      },
    },
    files: {
      handler(newValue) {
        this.isDirty = !!(this.isUpdate && !this.deepEqual(this.files, this.cloneFiles));
      },
    },
  },
  updated() {
    const self = this;
    self.$nextTick(() => {
      Vue.loadAsteriks();
    });
  },
};
