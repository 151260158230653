function getFromLocalStorage(perm) {
  const clientSideItems = localStorage.getItem('clientSideItem');
  if (!clientSideItems || clientSideItems == 'undefined') {
    return false;
  }
  const clientSideItemsSplited = clientSideItems.split(",");
  if(!clientSideItemsSplited || clientSideItemsSplited.length<=0){
    return false;
  }

  let check = [];
  check = clientSideItemsSplited.map((item) => item === perm);

  return check.includes(true);
}

export function authIncidentSave() {
  return getFromLocalStorage('Incident.Save');
}

export function authWorkOrderSave() {
  return getFromLocalStorage('WorkOrder.Save');
}

export function authWorkOrderDelete() {
  return getFromLocalStorage('WorkOrder.Delete');
}

export function authIncidentDelete() {
  return getFromLocalStorage('Incident.Delete');
}
