import { render, staticRenderFns } from "./UsersNeedToBeGetTrainingChart.vue?vue&type=template&id=6d1c35d3&"
import script from "../../../scripts/partials/dashboard-charts/UsersNeedToBeGetTrainingChart.js?vue&type=script&lang=js&"
export * from "../../../scripts/partials/dashboard-charts/UsersNeedToBeGetTrainingChart.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports