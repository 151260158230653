































import { Component } from 'vue-property-decorator';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import ProcedureDialog from "@/components/NewLibrary/Procedure/ProcedureDialog.vue";
import WorkDialog from './WorkDialog.vue';

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMModelWorkView } from '@/scripts/services/ClientApiAuto'
import { L10n } from '@syncfusion/ej2-base';

@Component<Work>({
    components: {
        'work-dialog': WorkDialog,
        'procedure-dialog': ProcedureDialog
    }
})
export default class Work extends CFMGridPage<CFMModelWorkView> {
    procedure = {}
    isProcedureDialogActive = false;
    entityType = "Work";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];

    created(){
        this.filterSettings = {type: 'Menu'};

        L10n.load({
                "tr": {
                    "grid": {
                        "FilterButton": "Filtrele",
                        "ClearButton": "Temizle"
                    }
                }
            });
    }
    
    async mounted(){
        this.dataStateChange(this.state);
    }
    recordDoubleClick(args:any){
        this.edit(args.rowData.Id);
    }
    async delete(data: CFMModelWorkView){
        const e = await swal.check(this, data.Name);
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.work.workDelete({id: data.Id});
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }
    async edit(id: number){
        this.selectedId = id;
        this.isDialogActive = true;
        await this.$nextTick();
        (<WorkDialog>this.$refs.workDialog).showDialog();
    }
    async add(){
        this.selectedId = null;
        this.isDialogActive = true;
        await this.$nextTick();
        (<WorkDialog>this.$refs.workDialog).showDialog();
    }
    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id);
            }
        }
        else if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
        this.toolbarClickBase(args);
    }
    refreshProcedures(){
        (<WorkDialog>this.$refs.workDialog).getProcedures();
    }
    async openProcedureDialog(){
        this.isProcedureDialogActive = true;
        await this.$nextTick();
        (<ProcedureDialog>this.$refs.procedureDialog).showDialog();
    }
}
