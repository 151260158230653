


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import CFMDialog from '@/scripts/CFMDialog';
import SparePart from '../NewSparePart/SparePart.vue';
import SparePartGrid from '../NewSparePart/SparePartGrid.vue';
import * as swal from '@/helpers/swal';
import { CFMDTOWorkOrderSparePart } from '@/scripts/services/ClientApiAuto';
import * as blockui from '@/helpers/block-ui';
import { CFMDTOSparePart } from "@/scripts/services/ClientApiAuto";

@Component<SparePartTaskIdGridModal>( {
  components: {
    'spare-part': SparePart,
    'spare-part-grid': SparePartGrid
  }
})
export default class SparePartTaskIdGridModal extends CFMDialog {
  @Prop()
  dataList?: any;
  sparePartId: any = 0;
  gridData: any[] = [];
  uniqueIds: string[] = [];

  data(){
    return{
      gridData:[]
    };
  }
  
  async created() {
  const self = this;

  function duplicateData(data: any, count: number) {
    const duplicatedList = [];
    for (let i = 0; i < count; i++) {
      duplicatedList.push(...data);
    }
    return duplicatedList;
  }

  const duplicatedDataList = duplicateData(self.dataList, self.dataList[0].DeliveredCount);

const promises = duplicatedDataList.map((item: any, index: number) => {
  const returnedCount = item.ReturnedCount;
  const totalCount = item.ReservedCount - item.ReturnedCount;
  let uniqueId = null;

  if (index >= returnedCount) {
    uniqueId = `${item.SparePartId}.${item.WorkOrderId}.${index - returnedCount + 1}`;
  }

  const status = index < returnedCount ? self.translate('returned') : self.translate('used');
  return {
    Id: index, 
    Name: item.Name,
    Status: status,
    UniqueId: uniqueId,
  };
});

self.gridData = await Promise.all(promises);
}


showUniqueId(status: any): boolean {
        return status === this.translate('used');
      }
checkUniqueId(uniqueId: string, item: any) {
  if (this.gridData) {
    const index = this.gridData.findIndex(i => i.Id === item.Id);

    if (index !== -1) {
      Vue.set(this.gridData, index, { ...this.gridData[index], UniqueId: uniqueId });
    }
  } else {
    console.error("self.gridData is undefined");
  }
}

async confirmClose() {
  const e = await swal.qaqccheck(this, this.translate('you_have_unsaved_changes_do_you_want_to_leave'));
  if (e.value) {
    this.$emit('modalClosed');
    this.close();
  }
}

async confirmSave() {
  const self = this;

  const uniqueIds = self.gridData.map(item => item.UniqueId).filter(id => id !== null);
  const duplicates = uniqueIds.filter((id, index) => uniqueIds.indexOf(id) !== index);

  if (duplicates.length > 0) {
    toastr.error(self.translate("unique_id_must_be_unique"));
    return;
  }

  const confirm = await swal.check(self, self.translate('are_you_sure'), self.translate('spare_parts_return_notice'));

  if (confirm.value) {
    try {
      blockui.pageBlock(self);
    const formData = {
      SparePartId: self.dataList[0].SparePartId,
      WorkOrderId: Number(self.dataList[0].WorkOrderId),
      WarehouseId: self.dataList[0].WarehouseId,
      SerialNumber: self.dataList[0].SerialNumber,
      ReservedCount: self.dataList[0].ReservedCount,
      ReturnedCount: Number(self.dataList[0].ReturnedCount),
      Status: 3,
      UniqueCodes: uniqueIds
    };

    const response = await self.apiClient.workOrderSparePart.saveReturnedSparePart(formData);
      if (response.data.IsSuccess) {
        toastr.success(self.translate("save_successfully"));
        this.$emit('modalClosed');
        this.close();
      } else {
        const errorMessage = response.data.Message || "Error";
        toastr.error(errorMessage);
      }

    } catch (error) {
      console.error("Error sending form data to API:", error);
    } finally {
      blockui.unBlockPage();
    }
  }
}

}
