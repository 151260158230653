import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  props: ['userId', 'position'],
  data() {
    return {
      showCard: false,
      downloading: false,
    };
  },
  async mounted() {
    const self = this;
  },
  methods: {
    ...mapActions({
      addUserToUserCardList: 'addUserToUserCardList',
    }),
    async hoverCard(isHover) {
      const self = this;
      if (isHover) {
        if (!self.downloading) {
          self.downloading = true;
          await this.$store.dispatch('addUserToUserCardList', self.userId);
          self.downloading = false;
          self.showCard = true;
        }
      } else {
        self.showCard = false;
      }
    },
    redirectToUser() {

    },
    getElementTopOffset() {
      const scrollTop = $(window).scrollTop();
      return `${$(this.$el).offset().top - scrollTop + 25}px`;
    },
  },
  computed: {
    ...mapGetters({
      userCardList: 'userCardList',
    }),
    userData() {
      return this.userCardList.find((x) => x.Id == this.userId);
    },
  },
};
