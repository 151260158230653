






































































import CFMDialog from '@/scripts/CFMDialog'
import {Component, Prop} from 'vue-property-decorator';
import * as dateFormat from '../../helpers/dateFormat';
import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import {required} from "vuelidate/lib/validators";
import { CFMDTOUser, CFMDTOWorkOrderFormVendor } from '@/scripts/services/ClientApiAuto';
import { columnSelectionComplete } from '@syncfusion/ej2-vue-grids';


@Component<FormVendorDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect
    },

    validations() {
        return {
            selectedUser: {
                UserId: {required}
            }
        }
    }
})

export default class FormVendorDialog extends CFMDialog{
[x: string]: any;
    
    @Prop()
    formId? : number;

    @Prop()
    workOrderId?: number;
    
    @Prop()
    vendorSignatureList: CFMDTOWorkOrderFormVendor[] = [];

    userList: {Name: string, Id: number}[] = [];
    selectedUser: {Name?: string | null, Id?: number, UserId?: number, User?:CFMDTOUser} = {};
    selectedFileName: string | null = null;
    selectedAttachment: string | null = null;
    selectedAttachmentType: string | null = null;
    formVendor : CFMDTOWorkOrderFormVendor = {};
    signatureList : CFMDTOWorkOrderFormVendor[] = [];

    async created(){
        this.getVendors();
        this.getFormVendorInfo();
    }

    onVendorChanged(args:any){
        this.formVendor.UserId = args.UserId as number;
    }

    onFileInputChange(args:any) {
        const file = args.target.files[0];
        this.selectedFileName = file.name;        
        const reader = new FileReader();

        reader.onloadend = (event:any) => {
            const result = event.target?.result.split(',');
            this.formVendor.SignatureFileType = <string>result[0];
            this.formVendor.SignatureFileBase64 = <string>result[1];
            this.formVendor.SignatureFileName = file.name;
        }

        reader.readAsDataURL(file);
    }

    formatDate(date:any){
        return dateFormat.viewDateTimeFormat(date);
    }
    // Get Users By UserType = Vendor
    async getVendors() { 
        const response = await this.apiClient.workOrder.workOrderGetAssignedUsers({workOrderId: this.workOrderId});
        this.userList = response.data
            .filter(x=>x.UserTypeId == 3)
            .map(x=>({Name: <string>x.NameSurname, Id: <number>x.Id}));
    }

    async getFormVendorInfo() {
        const response = await this.apiClient.workOrderFormVendor.workOrderFormVendorGetList(
            {formId: this.formId, workOrderId: this.workOrderId});
        if(response.data.IsSuccess && response.data.Data != null){
            this.signatureList = response.data.Data;
        }
    }

    async deleteFormVendor(id?: number) {
        const response = await this.apiClient.workOrderFormVendor.workOrderFormVendorDeleteFormVendor({id: id});
        if(response.data.IsSuccess){
            toastr.success(this.translate('delete_successfully'));
            this.getFormVendorInfo();
        } else {
            const errorMessage = response.data.Message ? response.data.Message : "Error";
            toastr.error(errorMessage);
        }
    }
    async onSubmit() {
        this.$v.$touch();
        if(this.$v.$invalid) return;

        blockui.blockModal(this);

        try{
            
            if(!this.formVendor.Id || this.formVendor.Id <= 0){
                this.formVendor.FormId = this.formId;
                this.formVendor.WorkOrderId = this.workOrderId;
            }
            
            const any = this.signatureList.filter(x => 
                x.SignatureFileName == this.formVendor.SignatureFileName && 
                x.UserId == this.formVendor.UserId).length > 0 ? true : false;

            if(any) {
                toastr.error(this.translate('this_record_already_exists'));
            } else {
                const response = await this.apiClient.workOrderFormVendor.workOrderFormVendorSave(this.formVendor);

                if(response.data.IsSuccess){
                    toastr.success(this.translate('save_successfully'));
                    this.getFormVendorInfo();
                } else {
                    const errorMessage = response.data.Message ? response.data.Message : "Error";
                    toastr.error(errorMessage);
                }
            }
            
        } catch(e:any) {
            console.log("err", e);
            toastr.error(e);
        } finally{
            blockui.unBlockModal();
        }
    }

}
