<template>
    <div id="point_tree" class="point-tree">
        <div class="list" :style="{'max-height':maxHeight,'height':height, borderColor:invalid?'red':'black'}">
            <!--<v-jstree :data="nodes" ref="warehouseTree"></v-jstree>-->
            <div v-if="editingItem&&editingItem.value>0">
                <label><span>{{translate('selected_point')}}:</span><span>{{editingItem.text}}</span></label>
            </div>
            <v-jstree :data="nodes" @item-click="itemClick" ref="warehouseTree" show-checkbox draggable
                      @item-drag-start="onItemDragStart"
                      @item-drag-end="onItemDragEnd">
                <template slot-scope="_">
                    <div style="display: inherit;">
                        <template>
                            <i :class="_.vm.themeIconClasses" role="presentation"
                               v-if="!_.model.loading"></i>
                            {{_.model.text}}
                        </template>
                    </div>
                </template>
            </v-jstree>

        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VJstree from 'vue-jstree-promise';
import { minValue, required } from 'vuelidate/lib/validators';
import * as api from '../../../../scripts/services/api';
import * as blockui from '../../../../helpers/block-ui';

export default {
  props: {
    roomId: { default: null },
    capacityEquipmentId: { default: null },
    maxHeight: {
      type: String,
    },
    height: {
      default: '100%',
      type: String,
    },
    initNode: Object,
    invalid: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isUpdate: false,
      nodes: [],
      newNode: '',
      selectedNodeName: '',
      editingItem: {},
      editingNode: null,
      rootNodeText: 'Root',
    };
  },
  components: {
    'v-jstree': VJstree,
  },
  mounted() {
    this.initializeData();
  },
  computed: {
    selectedRoom() {
      return this.nodes.find((room) => room.selected);
    },
  },
  methods: {
    onItemDragStart(e, oriNode, event) {
      this.$emit('onDrag', oriNode);
    },
    onItemDragEnd(e, oriNode, event) {
      this.$emit('onDragEnd', oriNode);
    },
    initializeData() {
      const self = this;
      if (!this.roomId && !this.capacityEquipmentId) {
        return;
      }
      blockui.blockElementPromise(self, self.$el);
      api.getRoomWithPoints({
        roomId: this.roomId,
        capacityEquipmentId: this.capacityEquipmentId,
      }).then((response) => {
        const room = response.body.Data;
        self.nodes = [{
          text: room.Name,
          value: room.Id,
          icon: 'fa fa-warehouse',
          opened: true,
          selected: false,
          disabled: true,
          dragDisabled: true,
          loading: false,
          isLeaf: !(room.CapacityEquipments && room.CapacityEquipments.length > 0) && !(room.Points && room.Points.length > 0),
          children: [
            ...room.CapacityEquipments.map((capacityEquipment) => ({
              text: capacityEquipment.Name,
              value: capacityEquipment.Id,
              isLeaf: !(capacityEquipment.Devices && capacityEquipment.Devices.length > 0) && !(capacityEquipment.Points && capacityEquipment.Points.length > 0),
              icon: 'fa fa-bolt',
              opened: false,
              selected: false,
              disabled: true,
              dragDisabled: true,
              loading: false,
              children: [
                ...capacityEquipment.Devices.map((device) => ({
                  text: device.Name,
                  value: device.Id,
                  isLeaf: !(device.Points && device.Points.length > 0),
                  icon: 'fa fa-server',
                  opened: false,
                  selected: false,
                  disabled: true,
                  dragDisabled: true,
                  loading: false,
                  children: device.Points.map((point) => ({
                    text: point.Name,
                    value: point.Id,
                    isLeaf: true,
                    icon: 'fa fa-project-diagram',
                    opened: false,
                    selected: false,
                    disabled: false,
                    loading: false,
                  })),
                })),
                ...capacityEquipment.Points.map((point) => ({
                  text: point.Name,
                  value: point.Id,
                  isLeaf: true,
                  icon: 'fa fa-project-diagram',
                  opened: false,
                  selected: false,
                  disabled: false,
                  loading: false,
                })),
              ],
            })),
            ...room.Points.map((point) => ({
              text: point.Name,
              value: point.Id,
              isLeaf: true,
              icon: 'fa fa-project-diagram',
              opened: false,
              selected: false,
              disabled: false,
              loading: false,
            })),
          ],
        }];
        blockui.unBlockElement(self.$el);
      });
    },
    // on tree item clicked
    itemClick(node) {
      const self = this;
      self.editingNode = node;
      self.editingItem = node.model;
      self.$emit('treeItemSelected', node.model);
    },
  },
  watch: {
    editingNode(newVal) {
      this.selectedNodeName = newVal.data.text;
    },
  },
};
</script>

<style scoped>

</style>
