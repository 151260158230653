import Vue from 'vue';
import * as api from '../services/api';
import Select2 from '../../components/shared/select2';
import * as blockui from '../../helpers/block-ui';

export default {
  props: ['material', 'isUpdate'],
  data() {
    return {};
  },
  components: {
    select2: Select2,
  },
  mounted() {
    const self = this; let
      table;
    window.$('form').validate();
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        api.saveTrainingMaterial(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            $('#training_material_modal').modal('toggle');
            if (self.material !== undefined) {
              self.reloadDataTable();
            } else {
              self.$emit('saved');
            }
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    reloadDataTable() {
      const filterForm = $("form[name='filterForm']");
      const formData = {};
      $.map(filterForm.serializeArray(), (item) => {
        Object.assign(formData, { [item.name]: item.value });
      });
      Vue.getDatatables(formData);
    },
    closeModal() {
      $('#training_material_modal').modal('toggle');
    },
  },
};
