<template>
    <div class='gridContainer'></div>
</template>

<script>
/* eslint-disable */
    import Vue from "vue";
    import Crud from "./Crud.vue";
    import {baseUrl} from "../services/api";
    // Defines a Vue component that wraps Bryntum Grid
    export default {
        data() {
            return {
                actionController: undefined,
                gridController: undefined,
                engine: undefined,
                negativeId: -100000, //todo remove
            }
        },
        props: {
            name: {type: String, default: ''},
            // Configs
            ripple: {
                type: [Boolean, Object], default: () => {
                    return {radius : 65, delegate : '.b-grid-header'}
                }
            },

            width: {type: String, default: '100%'},
            height: {type: String, default: '78vh'},
            maxHeight: {type: String, default: '78vh'},
            minHeight: {type: String, default: '10vh'},
            autoHeight: {type: Boolean, default: false},
            columns: Array,
            //resizeToFitIncludesHeader: {type: Boolean, default: false},
            emptyText: String,
            partner: Object,
            hideHeaders: {type: Boolean, default: false},
            id: String,
            selectionMode: {
                type: Object, default: () => {
                    return {row: true, cell: true, multiSelect: true, checkbox: false}
                }
            },

            fillLastColumn: {type: Boolean, default: true},
            showDirty: {type: Boolean, default: false},
            readOnly: {type: Boolean, default: false},
            addNewAtEnd: {type: [Boolean, Object], default: true}, //todo set with readonly
            responsiveLevels: {type: Object, default: undefined},
            rowHeight: {type: Number, default: 33},
            showRemoveRowInContextMenu: {type: Boolean, default: true},

            // Stores
            store: Object,

            crudManager: Object,

            // Data
            data: Array,

            config: Object,

            // Features, only used for initialization
            columnPickerFeature: {type: [Boolean, Object], default: () => {
                    return {groupByRegion: false, groupByTag: false}
                }
            },
            columnDragToolbarFeature: {type: Boolean, default: true},
            excelExporterFeature: {
                type: [Boolean, Object], default: () => {
                    return {dateFormat: 'LLL'}
                }
            },
            summaryFeature: {type: [Boolean, Object], default: true},
            filterFeature: {type: [Boolean, Object], default: true},
            quickFindFeature: {type: Boolean, default: false},
            searchFeature: {type: Boolean, default: true},
            filterBarFeature: {type: Boolean, default: false},
            cellEditFeature: {type: [Boolean, Object], default: () => {
                    return {addNewAtEnd: true, autoEdit: true }
                }
            },
            cellTooltipFeature: {
                type: [Boolean, Function], default: () => {
                    return ({record, column}) => record[column.field]
                }
            },


            rowReorderFeature: {type: [Boolean], default: false}, //todo broken with undoRedo 2.0
            columnReorderFeature: {type: [Boolean], default: true},
            treeFeature: {type: [Boolean, Object, String], default: false},
            groupFeature: {type: [Boolean, Object, String], default: true},
            groupSummaryFeature: {type: [Boolean, Object], default: false},
            headerContextMenuFeature: {type: [Boolean, Object], default: true},
            contextMenuFeature: {type: [Boolean, Object], default: () => {
                    return {
                        processCellItems({record, column, items}) {
                            if(!record.firstStore.modelClass.isDeletable(record))
                                items.splice(items.findIndex(i => i.name === 'removeRow'), 1);

                            items.push({
                                text: 'Clone',
                                cls: 'b-separator',
                                icon: 'fa fa-clone',
                                onItem : ({source, record}) => {
                                    let clone = new source.store.modelClass();
                                    let fields = [...source.store.modelClass.fields, ...source.store.modelClass.interActiveFields]
                                    fields.forEach((field)=> clone[field] = record[field]);
                                    source.store.add(clone);
                                }
                            });

                            items.push({
                                text: 'Clone 10',
                                icon: 'fa fa-clone',
                                onItem : ({source, record}) => {
                                    for(var jj = 1; jj<11; jj++){
                                        let clone = new source.store.modelClass();
                                        let fields = [...source.store.modelClass.fields, ...source.store.modelClass.calculatedFields, ...source.store.modelClass.interActiveFields]
                                        fields.forEach((field)=>clone[field] = record[field]);
                                        source.store.add(clone);
                                    }
                                }
                            });

                            !record.hasGeneratedId && record.firstStore.modelClass.chf.forEach((x)=> {
                                items.push({
                                    text: x +' ('+ (Math.floor(Math.random() * 10) + 1)+')',
                                    icon: 'b-fa b-fa-forward',
                                    onItem : ({source, record}) => {
                                        let popup = new bryntum.scheduler.Popup({
                                            title      : x,
                                            closable   : true,
                                            width      : '80%',
                                            minWidth   : '60%',
                                            minHeight  : '92vh',
                                            autoClose  : false,
                                            autoShow   : false,
                                            trapFocus  : false,
                                            draggable  : true,
                                            floating   : true,
                                            x: 300,
                                            y: 50,
                                            style: 'background-color:white;resize:both;overflow:hidden;',
                                            tools:{
                                                done:{
                                                    cls: 'b-fa-minus-square',
                                                    style: 'margin-right:10px',
                                                    handler: (e)=> {
                                                        popup.toFront();
                                                        let contentElement = popup.element.querySelector('.m-portlet--mobile');
                                                        let condition = contentElement.style.display === 'none';
                                                        contentElement.style.display = condition ? 'block' : 'none';
                                                        popup.floating = condition;
                                                        popup.positioned = condition;
                                                        popup.draggable = condition;
                                                        popup.x = condition?300:0;
                                                        popup.y = condition?50:0;
                                                        popup.minHeight = condition?'92vh':0;
                                                        popup.height = condition ? 0 : 'auto';
                                                        popup.minWidth = condition?'60%':0;
                                                        popup.width=condition?'80%':200;
                                                        let header = condition ? document.querySelector('.b-float-root' ): document.querySelector('.m-grid__item.m-grid__item--fluid.m-wrapper');
                                                        header.insertBefore(popup.element, header.firstChild);
                                                        !condition && (popup.element.style.transform = null);
                                                        popup.element.style.display = condition ? 'flex' : 'inline-block';
                                                        popup.element.style.margin = condition ? null : '5px';
                                                        popup.element.style.resize = condition ? 'both' : 'none';
                                                    },
                                                    weight: -1000,
                                                }
                                            }
                                        });
                                        let a = Vue.extend(Crud);
                                        let b = new a({
                                            propsData: {
                                                dto: x,
                                                field: record.firstStore.config.modelClass.name+'Id',
                                                value: record.Id
                                        }});
                                        b.$parent = popup;
                                        popup.show();
                                        b.$mount(popup.contentElement);
                                    }
                                });
                            });
                        },
                        /*processHeaderItems({column, items }) {
                            let moveCol = items.find(i => i.name === 'moveColumn');
                            moveCol && items.splice(moveCol, 1);
                        },*/
                    }
                }
            },
            regionResizeFeature: {type: Boolean, default: true},
            sortFeature: {type: [Boolean, Object, String, Array], default: 'Id'},
            stripeFeature: {type: Boolean, default: true},
            summaryToolbarFeature: [Boolean, Object],

            enableDeleteKey: {type: Boolean, default: false},
            //enableTextSelection: {type: Boolean, default: true}
        },

        mounted: function () {

            var propKeys = Object.keys(this.$props),
                featureConfig = {};

            var config = {
                // Render grid to components element
                appendTo: this.$el,

                // Listeners, will relay events using $emit
                listeners: {
                    catchAll: function (event) {
                        // Uncomment this line to log events being emitted to console
                        //this.$emit(event.type, event);
                    },
                    thisObj: this
                },

                features: featureConfig
            };

            // Apply all props to grid config
            propKeys.forEach(function (prop) {
                if (prop.indexOf('Feature') > -1) {
                    var featureName = prop.substr(0, prop.length - 'Feature'.length);
                    // Prop is a feature config
                    featureConfig[featureName] = this[prop];
                } else if (prop === 'config') {
                    // Prop is a config object
                    Object.assign(config, this[prop]);
                } else if(prop === 'emptyText' && !this[prop]) {
                    // emptyText prop
                    config[prop] = this.translate('no_rows_to_display');
                } else {
                    // Prop is a config
                    if (this[prop] !== undefined) config[prop] = this[prop];
                }
            }, this);

            // Create a Bryntum Grid with props as configs
            let language = localStorage.getItem('vue-lang');
            if (!language || language === 'en-US') language = 'En';
            bryntum.scheduler.LocaleManager.applyLocale(language, false, true);

            if(config.store && config.store.modelClass) {
                let dtoName = config.store.modelClass.name;
                config.store.readUrl =  config.store.readUrl && config.store.readUrl.includes(baseUrl) ? config.store.readUrl : baseUrl + (config.store.readUrl || '/'+dtoName+'/GetList');
                config.store.createUrl = baseUrl+'/'+dtoName+'/SaveChanges?detectGeneratedValues=true';
                config.store.updateUrl = baseUrl+'/'+dtoName+'/SaveChanges?detectGeneratedValues=true';
                config.store.deleteUrl = baseUrl+'/'+dtoName+'/SaveChanges?detectGeneratedValues=true';
                config.store.autoLoad = false;
                //config.store.reapplyFilterOnAdd = true;
                //config.store.reapplyFilterOnUpdate = true;
                config.store.idField = 'Id';
                config.store.responseDataProperty = 'Data';
                config.store.sendAsFormData = false;
                config.store.headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.$cookie.get('tokenType') + ' ' + this.$cookie.get('token'),
                    'Culture': localStorage.getItem('vue-lang')
                };

                config.store.stm = new bryntum.scheduler.StateTrackingManager({
                    disabled : false,
                    autoRecord: true,
                    listeners: {
                        catchAll: this.updateUndoRedoButtons
                    }
                });

                config.store.onException = ({exception, response, action, request, exceptionType}) => {
                    if (action === 'commit') return;
                    exception && bryntum.scheduler.Toast.show(response.statusText  ? action +': '+ response.statusText : exceptionType + ' exception for ' + action + ' action');
                };
                config.store.onAfterRequest= ({exception, json, response, action, exceptionType})=> {
                    if(action === 'read' || exception) return;
                    let plural = json.length > 1 ? 's ' : ' ';
                    bryntum.scheduler.Toast.show(json.length + ' record' + plural + action + 'd');
                };
                config.store.onBeforeRequest= ({source, action, params, requestData})=> {
                    requestData && (requestData.entities = []);
                    let items = [];
                    if(action ==='create') items = source.added;
                    if(action ==='update') items = source.modified;
                    if(action ==='delete') items = source.removed;

                    items.forEach((i)=> {
                        let obj = {};
                        if(action ==='create') {
                            obj = JSON.parse(JSON.stringify(i));
                            Object.keys(obj).forEach((field)=> {

                                if(obj[field] === null || !source.modelClass.fields.includes(field)) delete obj[field]
                                else {
                                    let column = engine.columns.get(field);
                                    if(column && column.type === 'date' && column.editor && column.editor.type === 'time'){
                                        obj[field] = bryntum.scheduler.DateHelper.format(new Date(obj[field]), 'HH:mm');
                                    }
                                }
                            });
                        }
                        if(action ==='update') {
                            obj = JSON.parse(JSON.stringify(i)); //i.modifications changed Only
                            delete obj.id;
                        }

                        let oldValues = {};
                        action ==='update' && Object.keys(i.data).forEach((field)=> {  //i.modifications changed Only
                            if(!source.modelClass.fields.includes(field)) delete obj[field];
                            if(field !== 'id' && source.modelClass.fields.includes(field))
                            {
                                let modifiedFields = Object.keys(i.modifications);
                                let column = engine.columns.get(field);
                                if(column && column.type === 'date' && column.editor && column.editor.type === 'time'){
                                    obj[field] = bryntum.scheduler.DateHelper.format(new Date(obj[field]), 'HH:mm');
                                    modifiedFields.includes(field) && (oldValues[field] = bryntum.scheduler.DateHelper.format(new Date(i.originalData[field]), 'HH:mm'));
                                }
                                else {
                                    modifiedFields.includes(field) && (oldValues[field] = i.originalData[field] === undefined ? null : i.originalData[field]);
                                }
                            }
                        });
                        obj.Id = action ==='create' ? this.negativeId++ : i.Id;

                        obj.$t = {
                            t: 'Panoptiqon.MMS.DTO.' + source.config.modelClass.dtoName + ', Panoptiqon.MMS.DTO',
                            s: action ==='create' ? 'Added' : (action ==='update' ? 'Modified' : 'Deleted'),
                            o: oldValues,
                            i: 0,
                            f: false
                        };
                        requestData.entities.push(obj);
                    });
                    this.negativeId = -100000;
                };
                config.store.onChange = ({changes, action, records, record}) => {
                    this.$nextTick(()=>{
                        let props = changes && Object.keys(changes);
                        action ==='add' && records.forEach((r) => {
                            engine.scrollRowIntoView(r.id, {block:'bottom', highlight: true, animate: false});
                        });
                        action ==='update' && props.forEach((p) => {
                            engine.columns.query(x=> x.field === p).forEach((c)=>{
                                engine.columns.getById(c.id).show();
                                engine.scrollRowIntoView(record.id, {column:c.id, highlight: true, animate: false});
                            });
                        });
                    });
                };
                config.store.onBeforeRemove = ({records}) => {
                    records.forEach((r)=>{
                        engine.scrollRowIntoView(r.id, {highlight: true, animate: false});
                    });
                };
            }

            let theme = localStorage.getItem('themeInfo');
            theme && theme !== bryntum.scheduler.DomHelper.themeInfo.name && bryntum.scheduler.DomHelper.setTheme(theme);

            //set initial col width based on state
            let state = null;
            if (this.name) {
                state = JSON.parse(localStorage.getItem('grid-' + this.name + '-state'));
                state && state.columns.forEach(savedItem => config.columns.forEach((item) => {
                    item.field === savedItem.id.replace(/[0-9]/g, '') && savedItem.id.replace(/[0-9]/g, '') !== 'col' && (item.width = savedItem.width);
                }));
            }

            var engine = this.engine = new bryntum.scheduler.Grid(config);
            engine.rowManager.on('renderRow', this.styleRowCls);
            engine.rowManager.on('renderCell', this.styleCellCls);

            let scroll = engine.currentElement.querySelector(".b-virtual-scrollers");
            let header = engine.currentElement.querySelector(".b-grid-header-container");
            header.parentNode.insertBefore(scroll, header.nextSibling);

            let store = engine.store;
            if(config.store && config.store.modelClass) {
                engine.on('startcelledit', this.arrangeEditors);
                engine.on('finishcelledit',  this.arrangeEditors);

                this.actionController = new bryntum.scheduler.Container({
                    insertFirst: this.$el.previousSibling,
                    type:'container',
                    widgets: [{
                        type: 'button',
                        cls: 'b-raised b-blue',
                        margin: 5,
                        icon: 'b-fa b-fa-plus',
                        tooltip: 'Add New Row',
                        onAction: () => {
                            let model = new store.modelClass();
                            let genId = model.id;
                            model.fields.forEach((field)=>{
                                if(field.name === 'id' || field.name === 'parentId') return;
                                model[field.name] = null;
                            });
                            model.Id = genId;
                            model.idField = 'Id';
                            store.add(model);
                        }
                    },{
                        type: 'button',
                        cls: 'b-raised b-blue',
                        margin: 5,
                        disabled: true,
                        icon: 'b-fa b-fa-undo',
                        tooltip: 'Undo',
                        onAction: () => store.stm.canUndo && store.stm.undo()
                    }, {
                        type: 'button',
                        cls: 'b-raised b-blue',
                        margin: 5,
                        disabled: true,
                        icon: 'b-fa b-fa-redo',
                        tooltip: 'Redo',
                        onAction: () =>  store.stm.canRedo && store.stm.redo()
                    }, {
                        type: 'button',
                        cls: 'b-raised b-blue',
                        margin: 5,
                        disabled: true,
                        icon: 'b-fa b-fa-sync',
                        tooltip: 'Commit Changes',
                        onAction: async () => {
                            if (!store.changes || ![...store.changes.added, ...store.changes.modified, ...store.changes.removed].length) return;
                            bryntum.scheduler.Mask.mask('Updating');
                            await store.commit().then(()=>{},(error)=>{});
                            store.stm.disable();
                            store.stm.resetQueue();
                            this.updateUndoRedoButtons({type:' ', stm:store.stm});
                            store.sort(store.sorters); // todo remove -> fixes rownumber NaN new generated row
                            store.stm.enable();
                            bryntum.scheduler.Mask.unmask();
                        }
                    }]
                });
            }

            store.load && store.load().then(() => {
                if(state) {
                    state.columns.forEach((savedItem) => {
                        engine.state.columns.forEach((item) => {
                            if (item.id.replace(/[0-9]/g, '') === savedItem.id.replace(/[0-9]/g, '') && savedItem.id.replace(/[0-9]/g, '') !== 'col') {
                                savedItem.id = item.id;
                                savedItem.text = item.text;
                            }
                        });
                    });
                    delete state.readOnly; // should be stored ?
                    store.filters.values.length && delete state.store.filters;
                    engine.state = state;
                }
                else {
                    let resizedCols = engine.columns.query(x => x.region === 'normal');
                    resizedCols.forEach(col => {
                        col.resizeToFitContent();
                        col.isLastInSubGrid && col.element.nextSibling && col.element.nextSibling.remove();
                    });
                    store.first && engine.rowManager.renderFromRecord(store.first); //fix broken first row render
                }
            });

            let hideableCols = engine.columns.allRecords.filter(c=>c.hideable).map(c=>({field:c.field, text:c.text}));
            let groupableCols = engine.columns.allRecords.filter(c=>c.groupable).map(c=>({field:c.field, text:c.text, ascending:true}));
            let sortableCols = engine.columns.allRecords.filter(c=>c.sortable).map(c=>({field:c.field, text:c.text, ascending:true}));
            let hiddenCols = engine.columns.allRecords.filter(c=>c.hidden).map(c=>({field:c.field, text:c.text}));

            this.gridController = new bryntum.scheduler.Container({
                appendTo: this.$el.previousSibling,
                type:'container',
                widgets: [{
                    type: 'text',
                    clearable: true,
                    flex: 3,
                    label: '<i class="b-icon b-icon-search"></i>',
                    margin: 5,
                    onClear:() => engine.features.search.clear(),
                    onChange: ({value}) => engine.features.search.search(value)
                },{
                    type: 'button',
                    icon: 'b-fw-icon  b-icon-up',
                    tooltip: this.translate('find_previous'),
                    cls: 'b-raised b-blue',
                    margin: 5,
                    disabled: true,
                    onAction: () => engine.features.search.gotoPrevHit()
                },{
                    type: 'button',
                    icon: 'b-fw-icon b-icon-down',
                    tooltip: this.translate('find_next'),
                    cls: 'b-raised b-blue',
                    margin: 5,
                    disabled: true,
                    onAction: () => engine.features.search.gotoNextHit()
                },
                {
                    toggleable : true,
                    type: 'button',
                    icon: 'b-fw-icon b-fa-cogs',
                    cls: 'b-raised b-blue',
                    tooltip: 'Settings',
                    margin: 5,
                    onToggle: ({ pressed, source }) => source.popup[pressed ? 'show' : 'hide']()
                },
                {
                    type: 'button',
                    icon: 'b-fw-icon b-fa-sync',
                    cls: 'b-raised b-blue',
                    tooltip: 'Refresh',
                    margin: 5,
                    onAction: async () => {
                        if(!store.load) return;
                        await store.load();
                        store.stm.resetQueue();
                        this.updateUndoRedoButtons({type:' ', stm:store.stm});
                    }
                }]
            });

            store.on({
                filter: ({filters})=> {
                    this.gridController.widgets[3].popup.widgets[5].badge = filters.values.length || null;
                    this.gridController.widgets[3].popup.widgets[5].disabled = filters.values.length === 0;
                    this.gridController.widgets[3].badge = filters.values.length || null;
                },
                sort : ({ sorters }) => {
                    this.gridController.widgets[3].popup.widgets[6].selecting = true;
                    this.gridController.widgets[3].popup.widgets[6].value = sorters;
                    this.gridController.widgets[3].popup.widgets[6].selecting = false;
                },
                group: ({ groupers, isGrouped})=>{
                    if(!this.gridController.widgets) return;
                    this.gridController.widgets[3].popup.widgets[3].disabled = !isGrouped;
                    this.gridController.widgets[3].popup.widgets[4].disabled = !isGrouped;
                    this.gridController.widgets[3].popup.widgets[2].selecting = true;
                    this.gridController.widgets[3].popup.widgets[2].value = isGrouped ? groupers[0].field : null;
                    this.gridController.widgets[3].popup.widgets[2].selecting = false;
                }
            });

            engine.columns.on({
                catchAll: ({type, source})=> {
                    if(type !== 'showcolumn' && type !== 'hidecolumn') return;
                    let hiddenCols = source.allRecords.filter(c=>c.hidden).map(c=>({field:c.field, text:c.text}));
                    hiddenCols = hiddenCols.length ? hiddenCols : [{}];
                    this.gridController.widgets[3].popup.widgets[7].selecting = true;
                    this.gridController.widgets[3].popup.widgets[7].value = hiddenCols;
                    this.gridController.widgets[3].popup.widgets[7].selecting = false;
                }
            });

            this.gridController.widgets[3].popup = new bryntum.scheduler.Popup({
                forElement : this.gridController.widgets[3].element,
                align      : 't100-b100',
                width      : '35em',
                autoShow   : false,
                anchor     : true,
                trapFocus  : false,
                widgets    : [{
                    type: 'button',
                    tooltip: this.translate('read_only'),
                    icon: engine.readOnly ? 'b-fw-icon b-fa-edit' : 'b-fw-icon b-fa-ban',
                    cls: 'b-raised b-blue',
                    margin: 5,
                    onAction: ({source}) => {
                        engine.readOnly = !engine.readOnly;
                        source.icon = engine.readOnly ? 'b-fw-icon b-fa-edit' : 'b-fw-icon b-fa-ban';
                    }
                },{
                    type: 'button',
                    icon: 'b-fw-icon b-fa-file-excel',
                    tooltip: this.translate('export_excel'),
                    cls: 'b-raised b-blue',
                    margin: 5,
                    onAction: () => engine.features.excelExporter.export()
                },{
                    type        : 'combo',
                    editable    : false,
                    clearable   : true,
                    placeholder : 'No grouping',
                    valueField  : 'field',
                    displayField: 'text',
                    flex        : 3,
                    items       : groupableCols,
                    value       : store.groupers,
                    onSelect    : ({ source, record }) => {
                        if (source.selecting) return;
                        if (record) {
                            source.selecting = true;
                            store.group(record.field);
                            source.selecting = false;
                        }
                        else store.clearGroupers();
                    }
                }, {
                    type: 'button',
                    icon: 'b-fw-icon b-fa-minus-square',
                    tooltip: this.translate('collapse'),
                    cls: 'b-raised b-blue',
                    margin: 5,
                    disabled: true,
                    onAction: () => engine.collapseAll()
                }, {
                    type: 'button',
                    icon: 'b-fw-icon b-fa-plus-square',
                    tooltip: this.translate('expand'),
                    cls: 'b-raised b-blue',
                    margin: 5,
                    disabled: true,
                    onAction: () => engine.expandAll()
                },{
                    type: 'button',
                    icon: 'b-fw-icon b-fa-filter',
                    tooltip: 'Clear Filters',
                    cls: 'b-raised b-blue',
                    margin: 5,
                    badge: store.filters.values.length || null,
                    disabled: store.filters.values.length === 0,
                    onAction: ({source}) => {source.parent.widgets[0].focus(); store.clearFilters();}
                },{
                    type: 'combo',
                    clearable: true,
                    editable : false,
                    multiSelect: true,
                    valueField : 'field',
                    displayField: 'text',
                    label: 'Sort',
                    //hidePickerOnSelect: true,
                    value: store.sorters,
                    items: sortableCols,
                    chipView : {
                        itemTpl : record => record.text,
                        iconTpl : record => `<div class="b-sort-icon b-icon ${record.ascending ? 'b-fa-arrow-up' : 'b-fa-arrow-down'}" data-noselect></div>`,
                        onItem({source : chipView, record, event}) {
                            if (event.target.classList.contains('b-sort-icon')) {
                                record.ascending = !record.ascending;
                                chipView.store.records.forEach((s) => {
                                    let sortable = engine.columns.allRecords.find(c => c.field === s.field).sortable;
                                    if(typeof sortable === "function") s.fn = sortable;
                                });
                                store.sort(chipView.store.records);
                            }
                        }
                    },
                    onChange: ({source}) => {
                        if (source.selecting) return;
                        source.selecting = true;
                        source.valueCollection.values.forEach((s) => {
                            let sortable = engine.columns.allRecords.find(c => c.field === s.field).sortable;
                            if(typeof sortable === "function") s.fn = sortable;
                        });
                        store.sort(source.valueCollection.values);
                        source.selecting = false;
                    }
                },{
                    type: 'combo',
                    clearable: true,
                    editable : false,
                    multiSelect: true,
                    valueField : 'field',
                    displayField: 'text',
                    label: 'Hide',
                    //hidePickerOnSelect: true,
                    value: hiddenCols,
                    items: hideableCols,
                    onChange: ({source}) => {
                        if (source.selecting) return;
                        source.selecting = true;
                        engine.columns.forEach((col)=>{
                            let hide = false;
                            source.valueCollection.values.forEach((x)=> {
                                if(x.field === col.field) {
                                    hide = true;
                                    !col.hidden && col.hide();
                                }
                            });
                            !hide && col.hidden && col.show();
                        });
                        source.selecting = false;
                    }
                },{
                    type: 'combo',
                    clearable: false,
                    editable : false,
                    label: 'Theme',
                    hidePickerOnSelect: true,
                    value: localStorage.getItem('themeInfo') || bryntum.scheduler.DomHelper.themeInfo.name,
                    items: ['Material', 'Stockholm', 'Default', 'Light', 'Dark'],
                    onChange: ({value}) => {
                        bryntum.scheduler.DomHelper.setTheme(value);
                        localStorage.setItem('themeInfo', value);
                    }
                }],
                onHide : () => this.gridController.widgets && (this.gridController.widgets[3].pressed = false)
            });

            engine.on({
                search: ({ find, found }) => {
                    let search = this.gridController.widgets[0];
                    if (!bryntum.scheduler.ObjectHelper.isEqual(search.value, find)) {
                        search.suspendEvents();
                        search.value = find instanceof Date ? bryntum.scheduler.DateHelper.format(find, 'LL') : find;
                        search.resumeEvents();
                    }//todo search by column type
                    search.badge = found.length;
                    this.gridController.widgets[1].enable();
                    this.gridController.widgets[2].enable();
                },
                clearsearch: () => {
                    this.gridController.widgets[0].value = '';
                    this.gridController.widgets[0].badge = null;
                    this.gridController.widgets[1].disable();
                    this.gridController.widgets[2].disable();
                }
            });
            this.$emit('gridController');
        },
        beforeDestroy: function () {
            this.name && localStorage.setItem('grid-' + this.name + '-state', JSON.stringify(this.engine.state));
            this.actionController && this.actionController.destroy();
            this.gridController && this.gridController.destroy();
            this.engine.destroy();
        },
        methods: {
            arrangeEditors({grid, editorContext, type}) {
                let record = editorContext.record;
                let currentField = editorContext.column.field;
                record.beginBatch();
                grid.store.modelClass.fields.forEach((field)=> {
                    let fieldName = field.name || field;
                    let editable = grid.store.modelClass.isEditable(record, fieldName);
                    let editor = grid.columns.get(fieldName).data.editor;
                    if(editor && !editable && type ==='finishcelledit' && record[fieldName]) record[fieldName] = editor.multiSelect ? [] : null;
                    if(editor && !editable && type ==='startcelledit' && currentField === field) editorContext.editor.cancelEdit();
                });
                grid.store.modelClass.calculatedFields.forEach((field)=> currentField === field && editorContext.editor.cancelEdit());
                type ==='finishcelledit' && grid.deselectAll(); //todo focus out cell
                record.endBatch();
            },
            updateUndoRedoButtons({type, stm}){
                if(type.endsWith('start')) return;
                let store = this.engine.store;
                let widgets = this.actionController.widgets;
                widgets[1].disabled = (widgets[1].badge = stm.position || null) == null;
                widgets[2].disabled = (widgets[2].badge = (stm.length - stm.position) || null) == null;

                //todo check
                //let modifications = store.changes.modified.filter((m)=> Object.keys(m.modifications).some(r=> store.modelClass.fields.includes(r)));

                widgets[3].disabled = (widgets[3].badge = store.changes && [...store.changes.added, ...store.changes.modified, ...store.changes.removed].length || null) == null;
                widgets[0].badge = store.changes && store.changes.added.length || null;
                this.gridController.widgets[3].popup.widgets[1].disabled = !widgets[3].disabled;
            },
            styleRowCls({source, row}) {
                //new row
                let added = source.store.changes && source.store.changes.added.find(x => x.id === row.id);
                row.elements.left[added ? 'setAttribute': 'removeAttribute']('data-badge', '*') ;
            },
            styleCellCls({cellElement, source, row, column, record}) {
                //Deletable && Calculated && Interactive
                let deletable = source.store.modelClass.isDeletable(record);
                let calculated = source.store.modelClass.calculatedFields.includes(column.field);
                let interActive = source.store.modelClass.interActiveFields.includes(column.field);
                cellElement[(!deletable && column.field === 'Delete') ? 'setAttribute': 'removeAttribute']('data-usable', '*');
                cellElement[ calculated ? 'setAttribute': 'removeAttribute']('data-calculated', '*');
                cellElement[ interActive ? 'setAttribute': 'removeAttribute']('data-interActive', '*');
                cellElement.removeAttribute('data-usable', '*'); // todo fix grouped editable ??
                cellElement.removeAttribute('data-badge', '*'); // todo fix grouped required ??
                if(!source.store.modelClass.fields.includes(column.field) || column.field ==='Id' || record.isParent) return; //todo record.isParent works grouped what on tree ?

                //Dirty
                let changesData = source.store.changes;
                let changed = changesData && [...changesData.modified, ...changesData.added].find(x => x.Id === row.id);
                let dirty = changed && changed.modifications && (changed.modifications[column.field] || changed.modifications[column.field] === false && column.type === 'check');
                dirty = dirty && ((!Array.isArray(dirty) && dirty) || (Array.isArray(dirty) && dirty.length));
                cellElement[dirty ? 'setAttribute' : 'removeAttribute']('data-dirty', '*');

                if(record.groupChildren) return;

                //Editable && Required
                let must = (source.grid.columns.get(column.field).editor && source.grid.columns.get(column.field).editor.required) || (source.grid.columns.get(column.field).type === 'widget' && source.grid.columns.get(column.field).data.required);
                let editable = source.store.modelClass.isEditable(record, column.field);
                let required = must && editable && (!record[column.field] || Array.isArray(record[column.field]) && record[column.field].length === 0);
                cellElement[required ? 'setAttribute' : 'removeAttribute']('data-badge', '*');
                cellElement[!required && !editable ? 'setAttribute' : 'removeAttribute']('data-usable', '*');
            },
        }
    }
</script>
