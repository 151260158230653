<template>
    <div class="m-dropdown__wrapper" style="z-index: 101;">
        <span class="m-dropdown__arrow m-dropdown__arrow--center"></span>
        <div class="m-dropdown__inner">
            <div class="m-dropdown__header m--align-center"
                 :style="{'background': 'url('+require('@/assets/images/notification_bg.jpg')+')'}"
                 style="background-size: cover;">
                <span class="m-dropdown__header-title" v-lang.nine_new></span>
                <span class="m-dropdown__header-subtitle" v-lang.user_notification></span>
            </div>
            <div class="m-dropdown__body">
                <div class="m-dropdown__content">
                    <ul class="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand" role="tablist">
                        <li class="nav-item m-tabs__item">
                            <a class="nav-link m-tabs__link active show" data-toggle="tab"
                               href="#topbar_notifications_notifications" role="tab" aria-selected="true"
                               v-lang.alerts></a>
                        </li>
                        <li class="nav-item m-tabs__item">
                            <a class="nav-link m-tabs__link" data-toggle="tab" href="#topbar_notifications_events"
                               role="tab" aria-selected="false" v-lang.events></a>
                        </li>
                        <li class="nav-item m-tabs__item">
                            <a class="nav-link m-tabs__link" data-toggle="tab" href="#topbar_notifications_logs"
                               role="tab" aria-selected="false" v-lang.logs></a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane active show" id="topbar_notifications_notifications" role="tabpanel">
                            <div class="m-scrollable m-scroller ps ps--active-y" data-scrollable="true"
                                 data-height="250" data-mobile-height="200" style="height: 250px; overflow: hidden;">
                                <div class="m-list-timeline m-list-timeline--skin-light">
                                    <div class="m-list-timeline__items">
                                        <div class="m-list-timeline__item">
                                      <span
                                              class="m-list-timeline__badge -m-list-timeline__badge--state-success"></span>
                                            <span class="m-list-timeline__text">{{translate('notifications_1')}}</span>
                                            <span class="m-list-timeline__time">{{translate('notifications_1_time')}}</span>
                                        </div>
                                        <div class="m-list-timeline__item">
                                            <span class="m-list-timeline__badge"></span>
                                            <span class="m-list-timeline__text">{{translate('notifications_2')}}</span>
                                            <span class="m-list-timeline__time">{{translate('notifications_2_time')}}</span>
                                        </div>
                                        <div class="m-list-timeline__item">
                                            <span class="m-list-timeline__badge"></span>
                                            <span class="m-list-timeline__text">{{translate('notifications_3')}}</span>
                                            <span class="m-list-timeline__time">{{translate('notifications_3_time')}}</span>
                                        </div>
                                        <div class="m-list-timeline__item">
                                            <span class="m-list-timeline__badge"></span>
                                            <span class="m-list-timeline__text">{{translate('notifications_4')}}<span
                                                    class="m-badge m-badge--danger m-badge--wide">{{translate('notifications_4_status')}}</span></span>
                                            <span class="m-list-timeline__time">{{translate('notifications_4_time')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                                    <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                                </div>
                                <div class="ps__rail-y" style="top: 0px; right: 4px; height: 250px;">
                                    <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 223px;"></div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="topbar_notifications_events" role="tabpanel">
                            <div class="m-scrollable m-scroller ps" data-scrollable="true" data-height="250"
                                 data-mobile-height="200" style="height: 250px; overflow: hidden;">
                                <div class="m-list-timeline m-list-timeline--skin-light">
                                    <div class="m-list-timeline__items">
                                        <div class="m-list-timeline__item">
                                      <span
                                              class="m-list-timeline__badge m-list-timeline__badge--state1-success"></span>
                                            <a href=""
                                               class="m-list-timeline__text">{{translate('notifications_5')}}</a>
                                            <span class="m-list-timeline__time">{{translate('notifications_5_time')}}</span>
                                        </div>
                                        <div class="m-list-timeline__item">
                                            <span class="m-list-timeline__badge m-list-timeline__badge--state1-danger"></span>
                                            <a href=""
                                               class="m-list-timeline__text">{{translate('notifications_6')}}</a>
                                            <span class="m-list-timeline__time">{{translate('notifications_6_time')}}</span>
                                        </div>
                                        <div class="m-list-timeline__item">
                                      <span
                                              class="m-list-timeline__badge m-list-timeline__badge--state1-success"></span>
                                            <a href=""
                                               class="m-list-timeline__text">{{translate('notifications_7')}}</a>
                                            <span class="m-list-timeline__time">{{translate('notifications_7_time')}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                                    <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                                </div>
                                <div class="ps__rail-y" style="top: 0px; right: 4px;">
                                    <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="topbar_notifications_logs" role="tabpanel">
                            <div class="m-stack m-stack--ver m-stack--general" style="min-height: 180px;">
                                <div class="m-stack__item m-stack__item--center m-stack__item--middle">
                                    <span class="">{{translate('all_caught_up')}}<br>{{translate('no_new_logs')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>
