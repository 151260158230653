




















































































































import CFMDialog from "@/scripts/CFMDialog";
import { Component } from "vue-property-decorator";

import * as blockui from "@/helpers/block-ui";

import { required } from "vuelidate/lib/validators";

import { CFMDTOSparePartSubCategory } from "@/scripts/services/ClientApiAuto";
import CFMMultiselect from "@/components/partials/CFMMultiselect.vue";

@Component<SparePartSubCategoryDialog>({
  components: {
    "cfm-multiselect": CFMMultiselect
  },
  validations() {
    return {
      sparePartSubCategory: {
        Name: { required }
      },
      selectedSparePartCategory: {
        CategoryId: { required }
      }
    };
  }
})
export default class SparePartSubCategoryDialog extends CFMDialog {
  sparePartSubCategory: CFMDTOSparePartSubCategory = { Name: "" };
  sparePartCategoryList: { Name: String; Id: Number }[] = [];
  selectedSparePartCategory: CFMDTOSparePartSubCategory = { Name: "" };

  async created() {
    blockui.blockModal(this);
    if (this.isUpdate) {
      const res = await this.apiClient.sparePartSubCategory.sparePartSubCategoryGetById(
        {
          id: <number>this.id
        }
      );
      this.sparePartSubCategory = res.data;
    }
    const promises = [this.getSparePartCategories()];
    if (this.isUpdate) {
      if (
        this.sparePartSubCategory &&
        this.sparePartSubCategory.Category
      ) {
        this.selectedSparePartCategory = this.sparePartSubCategory;
      }
    }
    await Promise.all(promises);
    blockui.unBlockModal();
  }

  async getSparePartCategories() {
    const response = await this.apiClient.sparePartCategory.sparePartCategoryGetCategories();
    this.sparePartCategoryList = response.data.map(x => ({
      Name: <string>x.Text,
      Id: <number>x.Value
    }));
  }

  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    let formData = {
      Id: this.sparePartSubCategory.Id,
      Name: this.sparePartSubCategory.Name,
      CategoryId: this.selectedSparePartCategory.CategoryId
    };
    blockui.blockModal(this);
    try {
      const response = await this.apiClient.sparePartSubCategory.sparePartSubCategorySave(
        formData
      );
      if (response.data.IsSuccess) {
        toastr.success(this.translate("save_successfully"));
        this.saveSucceeded();
        this.close();
      } else {
        const errorMessage = response.data.Message
          ? response.data.Message
          : "Error";
        toastr.error(errorMessage);
      }
    } catch (e) {
      console.log("err", e);
      toastr.error(e);
    } finally {
      blockui.unBlockModal();
    }
  }
}
