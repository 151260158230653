import * as api from '../../scripts/services/api';

// initial state
const state = {
  deviceList: [],
  descriptionModalDeviceList: [],
};

// getters
const getters = {};

// actions
const actions = {
  getDeviceList({ commit }) {
    api.getCapacityEquipments().then((res) => {
      commit('setDeviceList', res.body);
    });
  },
  getDescriptionModalDeviceList({ commit }) {
    api.getAllDevices().then((res) => {
      commit('setDescriptionModalDeviceList', res.body.Data);
    });
  },
};

// mutations
const mutations = {
  setDeviceList(state, devices) {
    state.deviceList = devices;
  },
  setDescriptionModalDeviceList(state, devices) {
    state.descriptionModalDeviceList = devices;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
