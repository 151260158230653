import Vue from 'vue';
import { mapState } from 'vuex';
import MaskedInput from 'vue-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as api from '../../services/api';
import Select2Group from '../../../components/shared/select2Group';
import * as dateFormat from '../../../helpers/dateFormat';
import * as blockui from '../../../helpers/block-ui';

export default {
  props: {
    user: {
      default() {
        return {};
      },
      type: Object,
    },
    isUpdate: {
      default: false,
      type: Boolean,
    },
    isContratVendor: {
      default: false,
      type: Boolean
    },
    vendorIdFromContract: {
      default: null,
      type: Number
    }
  },
  components: {
    'select2-group': Select2Group,
    'masked-input': MaskedInput,
  },
  data() {
    return {
      selectedUser: {},
      isVendor: false,
      sendMail: false, //true,
      isDomainUser: false,
      lastFoundDomainUsername: null,
    };
  },
  computed: {
    ...mapState({
      jobList: (state) => state.job.jobList,
      userTypeList: (state) => state.user.userTypeList,
      vendorList: (state) => state.vendor.vendorList,
      roleList: (state) => state.user.roleList,
      qualificationList: (state) => state.qualification.qualificationList,
      experienceList: (state) => state.user.experienceList,
      userTypes: (state) => state.enums.userTypes,
    }),
    selectedQualifications() {
      if (undefined !== this.user.UserQualifications && this.user.UserQualifications.length) {
        const values = [];
        for (let i = 0; i < this.user.UserQualifications.length; i++) {
          values.push(this.user.UserQualifications[i].QualificationId);
        }
        return values;
      }
      return [];
    },
    selectedOrganizations() {
      if (undefined !== this.user.OrganizationUsers && this.user.OrganizationUsers.length) {
        const values = [];
        for (let i = 0; i < this.user.OrganizationUsers.length; i++) {
          values.push(this.user.OrganizationUsers[i].OrganizationId);
        }
        return values;
      }
      return [];
    },
  },
  mounted() {
    const self = this;
    window.$('form').validate();

    this.$store.dispatch('getJobList');
    this.$store.dispatch('getUserTypeList');
    this.$store.dispatch('getVendorList');
    this.$store.dispatch('getUserRoleList');
    this.$store.dispatch('getQualificationList');
    this.$store.dispatch('getUserExperienceList');

    window.$('#date_of_employment').datepicker({
      autoclose: true,
      language: localStorage.getItem('vue-lang'),
    });

    self.$nextTick(() => {
      self.selectedUser = self.user;
      self.$nextTick(() => {
        if (self.user.DateOfEmployment) {
          window.$('#date_of_employment').datepicker('update', dateFormat.viewDateFormat(self.user.DateOfEmployment));
        } else {
          window.$('#date_of_employment').datepicker('update', '');
        }
      });

      self.isDomainUser = !!self.selectedUser.Username;
      const isDomainUserSwitch = window.$('#is_domain_user');
      isDomainUserSwitch.bootstrapSwitch({
        onText: self.translate('yes'),
        offText: self.translate('no'),
      });
      isDomainUserSwitch.bootstrapSwitch('state', self.isDomainUser, self.isDomainUser);
      isDomainUserSwitch.on('switchChange.bootstrapSwitch', (e, state) => {
        self.isDomainUser = state;
        self.$nextTick(() => {
          Vue.loadAsteriks();
        });
      });

      //self.sendMail = self.selectedUser.sendMail != undefined ? self.selectedUser.sendMail : true;
      const sendActivationMail = window.$('#send_activation_mail');
      sendActivationMail.bootstrapSwitch({
        onText: self.translate('yes'),
        offText: self.translate('no'),
      });
      sendActivationMail.bootstrapSwitch('state', self.sendMail, self.sendMail);
      sendActivationMail.on('switchChange.bootstrapSwitch', (e, state) => {
        self.sendMail = state;
        self.$nextTick(() => {
          Vue.loadAsteriks();
        });
      });
    });

    $("[name='Phone']").on('change', (e) => {
      self.selectedUser.Phone = e.target.value.replace(/-/g, '');
    });

    $("[name='userTagForm'] [name='UserTypeId']").on('change', (e) => {
      self.setIsVendor(e.target.value);
      self.$nextTick(() => { // TODO : Eren staff type vendor seçilince role id zorunlu olmuyor ama asteriks kalkmıyor
        Vue.loadAsteriks();
      });
    });

    self.$nextTick(() => {
      Vue.loadAsteriks();
      self.setIsVendor(self.user.UserTypeId);
    });
  },
  methods: {
    setIsVendor(userTypeId) {
      if (userTypeId == this.userTypes.vendor) {
        this.isVendor = true;
      } else {
        this.isVendor = false;
      }
    },
    getSuggestedRunHourString() {
      if (this.capacityEquipment.SuggestedRunHour) {
        return this.capacityEquipment.SuggestedRunHour.toString();
      }
      return '';
    },
    serializeArrayIncludingDisabledField(form) {
      const fields = form.find('[disabled]');
      fields.prop('disabled', false);
      const json = form.serializeArray();
      fields.prop('disabled', true);
      return json;
    },
    async onSubmit(e) {
      const self = this;
      const form = $("form[name='userTagForm']");

      if (!form.valid()) {
        toastr.error(self.translate('please_fill_all_required_fields'));
        return;
      }
      const formData = {};
      formData.IsSendPasswordResetNotification = undefined;

      $.map(self.serializeArrayIncludingDisabledField(form), (item) => {
        if (item.name == 'DateOfEmployment') {
          Object.assign(formData, { [item.name]: item.value ? dateFormat.getDate(item.value) : null });
        } else if (item.name == 'sendMail') {
          Object.assign(formData, { IsSendPasswordResetNotification: true });
        } else {
          Object.assign(formData, { [item.name]: item.value });
        }
      });

      if (!self.isUpdate && formData.IsSendPasswordResetNotification === undefined) {
        Object.assign(formData, { IsSendPasswordResetNotification: false });
      }

      Object.assign(formData, { IsActive: true });

      blockui.blockModal(self);
      formData.DateOfEmployment = formData.DateOfEmployment ? dateFormat.postDateFormat(formData.DateOfEmployment) : null;

      const result = (await api.saveUser(formData)).body;
      if (result.IsSuccess) {
        toastr.success(self.translate('save_successfully'));
        if (self.$route.meta.contract) {
          const savedUserIdKeyValue = result.Messages.find((x) => x.Key == 'Id');
          self.$eventHub.$emit('contractAddedNewUser', { VendorId: self.selectedUser.VendorId, UserId: savedUserIdKeyValue.Value });

          blockui.unBlockModal();
        } else {
          Vue.getDatatables();
        }
        $('#user_overlay_modal').modal('toggle');
      } else {
        toastr.error(result.Message);
      }
      blockui.unBlockModal();
    },
    unmask(val) {
      if (val) {
        return val.toString().replace(/\D+/g, '');
      }
      return '';
    },
    suitableForPhoneNumber(e) {
      const code = (e.which) ? e.which : e.keyCode;
      if (code !== 32 && code !== 40 && code !== 41 && code !== 43 && (code < 48 || code > 57)) {
        e.preventDefault();
      }
    },
    async findDomainUser() {
      const self = this;
      blockui.blockModal(self);
      try {
        const result = (await api.getLdapUser(self.selectedUser.Username)).body;
        self.lastFoundDomainUsername = self.selectedUser.Username;
        Vue.set(self.selectedUser, 'Name', result.Name);
        Vue.set(self.selectedUser, 'Surname', result.Surname);
        Vue.set(self.selectedUser, 'Email', result.Email);
      } catch (e) {
        blockui.unBlockModal();
      }
      blockui.unBlockModal();
    },
  },
  watch: {
    capacityEquipment(e) {
      const self = this;
      if (this.isUpdate) {
        if (self.capacityEquipment.DateOfInstallation) {
          self.dateOfInstallation = new Date(self.capacityEquipment.DateOfInstallation);
        }
        if (self.capacityEquipment.DateOfWarrantyStart) {
          self.dateOfWarrantyStart = new Date(self.capacityEquipment.DateOfWarrantyStart);
        }
        if (self.capacityEquipment.ExpectedLifeEndDate) {
          self.expectedLifeEndDate = new Date(self.capacityEquipment.ExpectedLifeEndDate);
        }
      }
    },
    user(e) {
      this.selectedUser = e;
    },
  },
};