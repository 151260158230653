import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';
import * as swal from '../../helpers/swal';
export default {
  props: ['workOrder', 'from'],
  data() {
    return {
      selectedWorkOrder: {},
      subStatusList: [],
      workOrderStatuses: {},
      workOrderSubStatuses: {},
      modalId: 'update_work_order_status_modal',
    };
  },
  async mounted() {
    const self = this;
    window.$('form').validate();
    blockui.blockElementPromise(self, self.$el);

    const promises = [
      api.getWorkOrderSubStatusList().then((res) => {
        res.body.forEach((workOrderSubStatus) => {
          self.workOrderSubStatuses[workOrderSubStatus.Id] = workOrderSubStatus;
        });
      }),
      api.getWorkOrder(this.workOrder.Id).then((res) => {
        self.selectedWorkOrder = res.body;
      }),
    ];
    await Promise.all(promises);
    await api.getWorkOrderStatusList(self.selectedWorkOrder.OrderSourceId).then((res) => {
      res.body.Data.forEach((workOrderStatus) => {
        self.workOrderStatuses[workOrderStatus.Id] = workOrderStatus;
      });
    });
    await self.$store.dispatch('getSuitableStatusList', self.selectedWorkOrder.Id);
    blockui.unBlockElement(self.$el);

    $(`#${self.modalId} select[name='OrderStatusId']`).on('change', (e) => {
      blockui.blockModal(self);
      self.subStatusList = [];
      if (e.target.value) {
        api.getWorkOrderSubStatuses({ statusId: e.target.value }).then((res) => {
          self.subStatusList = res.body;
          blockui.unBlockModal();
        });
      } else {
        blockui.unBlockModal();
      }
    });

    $(`#${self.modalId}`).on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
  },
  methods: {
    async onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      form.validate();
      if (form.valid()) {
        const formData = {};

        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        const handleStatusChange = async () => {
          try {
            blockui.blockModal(self);  
            const response = await self.$globalApiClient.workOrder.workOrderUpdateStatus(formData);
            if (response.data.IsSuccess) {
              toastr.success(self.translate('save_successfully'));
              self.closeModal();
              if (self.$route.meta.xyz) {
                Vue.getDatatables('.work_order');
              } else if (self.from === 'WorkOrder') {
                self.reloadDataTable();
              } else if (self.from === 'MyWorkOrder') {
                self.reloadMyWorkOrder();
              }
            
            } else {
              toastr.error(response.data.Message);
            }
          } catch (error) {
            console.error(error);
          } finally {
            blockui.unBlockModal();
          }
        };
        const assignedUsers = this.workOrder.AssignedUsers.some(item => item.UserTypeId === 3);
        const vendor_runtime_empty = !this.workOrder.VendorWorkTime || !this.workOrder.VendorWorkTime.VendorStartDate || !this.workOrder.VendorWorkTime.VendorEndDate
        if (Number(formData.OrderStatusId) === 4) {
          const response = await this.$globalApiClient.workOrderSparePart.anyNotReturnedSparePart({ workOrderId: self.selectedWorkOrder.Id });          
          if (response.data.Data === true) {
            if (assignedUsers) {
              if (vendor_runtime_empty) {
                toastr.error(this.translate('vendor_runtime_empty'));
                return;
              }
            }
            const confirm = await swal.check(self, self.translate('are_you_sure'), self.translate('spare_part_sent_for_approval'));
            if (confirm.value) {
              await handleStatusChange();
            }
          }
            else {
              if (assignedUsers) {
                  if (vendor_runtime_empty) {
                    toastr.error(this.translate('vendor_runtime_empty'));
                    return;
                  }
              }
              await handleStatusChange();               
            }
        }
        else {
        if (Number(formData.OrderStatusId) === 11 || Number(formData.OrderStatusId) === 12 || Number(formData.OrderStatusId) === 6) {
          const response = await this.$globalApiClient.workOrderSparePart.anyNotReturnedSparePart({ workOrderId: self.selectedWorkOrder.Id });          
          if (response.data.Data === true) {
            if (assignedUsers) {
              if (vendor_runtime_empty) {
                toastr.error(this.translate('vendor_runtime_empty'));
                return;
              }
            }
            toastr.error(this.translate('spare_part_sent_for_confirm'));
            return;
          }
            else {

              if (assignedUsers) {
                  if (vendor_runtime_empty) {
                    toastr.error(this.translate('vendor_runtime_empty'));
                    return;
                  }
              }
              await handleStatusChange();               
            }
        }
        else {
          await handleStatusChange(); 
        }          
        }
      }
    },
    reloadMyWorkOrder() {
      Vue.getMyWorkOrder();
    },
    reloadDataTable() {
      Vue.getDatatables();
    },
    closeModal() {
      $(`#${this.modalId}`).modal('toggle');
    },
  },
  computed: {
    isSubStatusListEmpty() {
      const self = this;
      return jQuery.isEmptyObject(self.subStatusList);
    },
    ...mapState({
      suitableStatusList: (state) => state.suitable_status.suitableStatusList,
      workOrderSourceEnums: (state) => state.enums.workOrderSource,
    }),
  },
};
