import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import { mixinz } from '../../../../../scripts/global/mixinz';

export default {
  props: ['action', 'isUpdate'],
  mixins: [mixinz],
  data() {
    return {
      selectedAction: {},
      actionGroupList: [],
    };
  },
  validations() {
    return {
      selectedAction: {
        ActionGroupId: {
          required,
        },
        Name: {
          required,
        },
      },
    };
  },
  mounted() {
    window.$('form').validate();
    const self = this;
    self.selectedAction = self.action;

    $('#action_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });

    api.getSecurityActionGroupList().then((response) => {
      this.actionGroupList = response.body;
    });
  },
  computed: {

    actionGroup: {
      get() {
        const self = this;
        return self.selectGet('actionGroupList', 'selectedAction.ActionGroupId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedAction.ActionGroupId', 'Id', newValue);
      },
    },
  },
  methods: {
    onSubmit(e) {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      blockui.blockModal(self);
      api.saveSecurityAction(this.selectedAction).then((response) => {
        if (response.body.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
          self.closeModal();
          self.reloadDataTable();
        } else {
          toastr.error(response.body.Message);
        }
        blockui.unBlockModal();
      }, (error) => {
        blockui.unBlockModal();
      });
    },
    reloadDataTable() {
      Vue.getDatatables();
    },
    closeModal() {
      $('#action_modal').modal('toggle');
    },
  },
  watch: {
    action(e) {
      this.selectedAction = e;
    },
  },
};
