import Vue from 'vue';
import { mapState } from 'vuex';
import _ from 'lodash';
import * as api from '../../services/api';
import Select2Group from '../../../components/shared/select2Group';

export default {
  props: {
    capacityEquipment: {
      default() {
        return {};
      },
      type: Object,
    },
    isUpdate: {
      default: false,
      type: Boolean,
    },
  },
  components: {
  },
  computed: {
  },
  data() {
    return {
    };
  },
  mounted() {
    const self = this;
    window.$('form').validate();
    if (!self.capacityEquipment) {
      self.capacityEquipment = {};
    }

    /* $('.date-picker').datepicker({
      autoclose: true,
      language: localStorage.getItem('vue-lang')
    });

    if (self.capacityEquipment.DateOfInstallation) {
      window["$"]("#dateOfInstallation").datepicker("update", new Date(self.capacityEquipment.DateOfInstallation));
    }

    self.$nextTick(() => {
      $('.date-picker').datepicker({
        autoclose: true,
        language: localStorage.getItem('vue-lang')
      });

      if (self.capacityEquipment.DateOfInstallation) {
        window["$"]("#dateOfInstallation").datepicker("update", new Date(self.capacityEquipment.DateOfInstallation));
      }
    }); */

    self.$nextTick(() => {
      Vue.loadAsteriks();
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        self.$emit('next', formData);
      }
    },
  },
  watch: {
    /* capacityEquipment: function (e) {
      if (this.isUpdate) {
        window["$"]("#dateOfInstallation").datepicker("update", new Date(this.capacityEquipment.DateOfInstallation));
      }
    }, */
  },
};
