import moment from 'moment';

export const getDate = (date) => {
  moment.locale(localStorage.getItem('vue-lang'));
  date = moment(date, moment().locale(localStorage.getItem('vue-lang')).creationData().locale.longDateFormat('LL')).toDate();
  return date;
};

export const viewDateTimeFormatTZ = (dateString) => {
  return new Date(
    Date.UTC(
      new Date(dateString).getFullYear(), 
      new Date(dateString).getMonth(), 
      new Date(dateString).getDate(), 
      new Date(dateString).getHours(), 
      new Date(dateString).getMinutes(), 
      new Date(dateString).getSeconds()));
}

export const viewDateFormatTZ = (dateString) => {
  return new Date(
    new Date(dateString).getFullYear(), 
    new Date(dateString).getMonth(), 
    new Date(dateString).getDate());
}

export const viewDateFormat = (date) => moment(date).locale(localStorage.getItem('vue-lang')).format('LL');

export const viewDateTimeFormat = (date) => moment(date).locale(localStorage.getItem('vue-lang')).format('LLL');

export const viewTimeFormat = (date) => moment(date).locale(localStorage.getItem('vue-lang')).format('LTS');

export const viewRelativeDateTimeFormat = (date, fromDate) => moment(date).locale(localStorage.getItem('vue-lang')).from(moment(fromDate));

export const postDateFormat = (date) => {
  moment.locale(localStorage.getItem('vue-lang'));
  date = moment(date, moment().locale(localStorage.getItem('vue-lang')).creationData().locale.longDateFormat('LL')).toDate();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`;
};

export const postDateTimeFormat = (date) => {
  moment.locale(localStorage.getItem('vue-lang'));
  date = moment(date, moment().locale(localStorage.getItem('vue-lang')).creationData().locale.longDateFormat('LLL')).toDate();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const postDateTimeFormatFromDateObject = (date) => {
  if (!date) {
    return null;
  }

  date = moment(date).toDate();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const postDateFormatToDate = (postDate) => {
  const splitted = postDate.split('-');

  const day = splitted[2];
  const month = splitted[1] - 1;
  const year = splitted[0];

  return new Date(year, month, day);
};

export const formatMinute = (min, self) => {
  let tempValue = min;

  const day = Math.floor(tempValue / (60 * 24));
  tempValue %= (60 * 24);
  const hour = Math.floor(tempValue / 60);
  tempValue %= 60;
  const minute = Math.floor(tempValue);

  let result = '';
  if (day > 0) {
    result += `${day} ${self.translate('day')} `;
  }
  if (hour > 0) {
    result += `${hour} ${self.translate('hour')} `;
  }
  result += `${minute > 0 ? minute : 0} ${self.translate('minute')} `;

  return result;
};

const isInt = (value) => !isNaN(value)
    && parseInt(Number(value)) == value
    && !isNaN(parseInt(value, 10));

export const dataMinute = (string, self) => {
  let min = 0;
  const indexOfDayString = string.indexOf(self.translate('day'));
  if (indexOfDayString >= 0 && (string[indexOfDayString - 1] != ' ' || !isInt(string.substring(0, indexOfDayString - 1)))) {
    return null;
  } if (indexOfDayString >= 0) {
    min += parseInt(string.substring(0, indexOfDayString - 1), 10) * 24 * 60;
  }

  const indexOfHourString = string.indexOf(self.translate('hour'));
  const hour = string.substring(indexOfDayString >= 0 ? indexOfDayString + self.translate('day').length + 1 : 0, indexOfHourString - 1);
  if (indexOfHourString >= 0 && (string[indexOfHourString - 1] != ' ' || !isInt(hour))) {
    return null;
  } if (indexOfHourString >= 0) {
    min += parseInt(hour, 10) * 60;
  }

  const indexOfMinuteString = string.indexOf(self.translate('minute'));
  const minute = string.substring(indexOfHourString >= 0 ? indexOfHourString + self.translate('hour').length + 1 : (indexOfDayString >= 0 ? indexOfDayString + self.translate('day').length + 1 : 0), indexOfMinuteString - 1);
  if (indexOfMinuteString >= 0 && (string[indexOfMinuteString - 1] != ' ' || !isInt(minute))) {
    return null;
  } if (indexOfMinuteString >= 0) {
    min += parseInt(minute, 10);
  } else {
    return null;
  }
  return min;
};
