<template>
    <div>
        <h5 class="m--padding-top-15 mb-0" v-lang.procedures></h5>
        <div class="row m--padding-top-15 m--padding-bottom-15 border-bottom">
            <div class="col-md-4"
                 v-for="workOrderProcedure in workOrderProcedures">
                <div>{{workOrderProcedure.ProcedureType}}</div>
                <strong class="d-block">{{workOrderProcedure.ProcedureName}}</strong>
                <a href="javascript:;"
                   @click="downloadFile(workOrderProcedure.FileId)">
                    <span class="m-nav-grid__text">{{workOrderProcedure.FileName}}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import { downloadBlob, base64toBlob } from '../../../../../scripts/global/util';

export default {
  name: 'WorkOrderProcedures',
  props: {
    workOrderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      workOrderProcedures: [],
    };
  },
  async mounted() {
    blockui.blockElementPromise(this, this.$el);
    try {
      const response = await this.$globalApiClient.workOrder.workOrderGetProcedures({workOrderId: this.workOrderId})
      if ( !response.data ) {
        throw new Error('Error');
      }
      this.workOrderProcedures = response.data;
    } catch (e) {
      toastr.error(e.message);
    } finally {
      blockui.unBlockElement(this.$el);
    }
  },
  methods: {
    async downloadFile(id) {
      const self = this;
      blockui.blockElementPromise(this, this.$el);
      try {
        const response = await api.getFile({ id, isContainsData: true });
        if (!response.body || !response.body.Data) {
          throw new Error('Error');
        }
        downloadBlob(
          base64toBlob(
            response.body.Data.Base64,
            response.body.Data.ContentType,
          ),
          response.body.Data.Name,
        );
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.$el);
      }
    },
  },
};
</script>

<style scoped>

</style>
