


















































































import CFMDialog from "@/scripts/CFMDialog";
import { Component } from "vue-property-decorator";

import * as blockui from "@/helpers/block-ui";
import { required } from "vuelidate/lib/validators";

import { CFMDTOSparePartCategory } from "@/scripts/services/ClientApiAuto";

@Component<DiscardProductDialog>({
  components: {},
  validations() {
    return {
      sparePartCategory: {
        Name: { required }
      }
    };
  }
})
export default class DiscardProductDialog extends CFMDialog {
  sparePartCategory: CFMDTOSparePartCategory = { Name: "" };

  selectedSparePartCategory: CFMDTOSparePartCategory = { Name: "" };

  async created() {
    blockui.blockModal(this);
    if (this.isUpdate) {
      const res = await this.apiClient.sparePartCategory.sparePartCategoryGetById({
        id: <number>this.id
      });
      this.sparePartCategory = res.data;
    }

    if (this.isUpdate) {
      if (this.sparePartCategory) {
        this.selectedSparePartCategory = this.sparePartCategory;
      }
    }
    blockui.unBlockModal();
  }

  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    let formData = {
      Id: this.sparePartCategory.Id,
      Name: this.sparePartCategory.Name
    };
    blockui.blockModal(this);
    try {
      const response = await this.apiClient.sparePartCategory.sparePartCategorySave(formData);
      if (response.data.IsSuccess) {
        toastr.success(this.translate("save_successfully"));
        this.saveSucceeded();
        this.close();
      } else {
        const errorMessage = response.data.Message
          ? response.data.Message
          : "Error";
        toastr.error(errorMessage);
      }
    } catch (e) {
      console.log("err", e);
      toastr.error(e);
    } finally {
      blockui.unBlockModal();
    }
  }
}
