import { mapState } from 'vuex';
import * as api from '../../services/api';
import chart from './chart';
import * as swal from '../../../helpers/swal';

export default {
  mixins: [chart],
  data() {
    return {
      WorkOrderCountsByStatus: {},
      data: undefined,
      polarArea: undefined,
    };
  },
  computed: {
    ...mapState({
      workOrderSourceEnums: (state) => state.enums.workOrderSource,
    }),
  },
  mounted() {
    const self = this;
    api.getWorkOrderStatusList(self.workOrderSourceEnums.planned).then((res) => {
      api.getWorkOrderList({
        /* fromDate: moment().startOf('month').format(),
        endDate: moment().endOf('month').format(), */
      }).then((response) => {
        response.body.Data.forEach((workOrder) => {
          if (workOrder.IsShowOnCalendar) {
            self.WorkOrderCountsByStatus[workOrder.OrderStatusId] = self.WorkOrderCountsByStatus[workOrder.OrderStatusId] + 1 || 1;
          }
        });
        self.data = res.body.Data.map((workOrderStatus) => ({
          label: workOrderStatus.Name,
          Id: workOrderStatus.Id,
          value: self.WorkOrderCountsByStatus[workOrderStatus.Id] ? self.WorkOrderCountsByStatus[workOrderStatus.Id] : 0,
          color: workOrderStatus.Color.Code,
        }));
        self.initChart(self.data);
      });
    });
  },
  methods: {
    initChart(data) {
      const self = this;
      if ($(`#${self.chartId}`).length == 0) return;
      const config = {
        type: 'polarArea',
        data: {
          datasets: [{
            data: [],
            backgroundColor: [],
          }],
          labels: [],
        },
        options: {
          tooltips: {
            callbacks: {},
          },
          // responsive: true,
          legend: {
            position: 'right',
            labels: {
              usePointStyle: true,
            },
          },

          title: {
            display: true,
            text: `${self.translate('work_order_count')}: ${data.reduce((a, b) => a + b.value, 0)}`,
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          maintainAspectRatio: false,
        },
      };

      data.forEach((dataItem) => {
        config.data.datasets[0].data.push(dataItem.value);
        config.data.datasets[0].backgroundColor.push(dataItem.color);
        config.data.labels.push(`${dataItem.label} (${dataItem.value})`);
      });

      const onClick = (isClickedLegend, e, item) => {
        let index;
        if ((!Array.isArray(item) && item) || (Array.isArray(item) && item.length > 0)) {
          if (isClickedLegend) {
            index = item.index;
          } else {
            index = item[0]._index;
          }
          if (data[index].value > 0) {
            swal.check(self, self.translate('are_you_sure'), self.translate('you_will_be_redirected_to_page').formatString(self.translate('work_orders'))).then((e) => {
              if (e.value) {
                self.$router.push({ name: 'WorkOrder', query: { workOrderStatusId: data[index].Id } });
              }
            });
          } else {
            toastr.warning(self.translate('there_is_no_work_order_in_this_status'));
          }
        }
      };

      config.options.onClick = (e, item) => {
        onClick(false, e, item);
      };

      config.options.tooltips.callbacks.label = (tooltipItem, data) => {
        let label = data.labels[tooltipItem.index] || '';
        if (label) label += ': ';
        const total = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b) => a + b, 0);
        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return `${label}${((value / total) * 100).toFixed(2)}%`;
      };

      const ctx = $(`#${self.chartId}`);
      self.polarArea = new Chart(ctx, config);
    },
    redirect(orderStatusId) {
      this.$router.push({ name: 'WorkOrder', query: { workOrderStatusId: orderStatusId } });
    },
    redraw() {
      $(`#${this.chartId}`).removeAttr('width');
      $(`#${this.chartId}`).removeAttr('height');
      $(`#${this.chartId}`).html('');
      this.polarArea.destroy();
      this.initChart(this.data);
    },
  },
};
