<template>
  <input
    v-if="edit"
    v-model="scopeValue"
    @blur="edit = false; $emit('update')"
    @keyup.enter="edit=false; $emit('update')"
    @input="validateValue"
    @keydown="validateValue"
    @keyup="validateValue"
    @mousedown="validateValue"
    @mouseup="validateValue"
    @select="validateValue"
    @contextmenu="validateValue"
    @drop="validateValue"
    :size="size"
    :maxlength="maxlength"
    v-focus
  >
  <label v-else @click="edit = true"> {{scopeValue}} </label>
</template>
<script>
export default {
  props: {
    value: '',
    textClasses: {
      type: String | Number,
      default: '',
    },
    inputClasses: {
      type: String,
      default: '',
    },
    textStyles: {
      type: String,
      default: '',
    },
    inputStyles: {
      type: String,
      default: '',
    },
    size: {
      type: Number | Boolean,
      default: false,
    },
    maxlength: {
      type: Number | Boolean,
      default: false,
    },
    regEx: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      edit: false,
      scopeValue: '',
      oldValue: '',
    };
  },
  computed: {},
  created() {
    this.oldValue = this.value;
    this.scopeValue = this.value;
  },
  mounted() {
  },
  methods: {
    validateValue(event) {
      const regex = new RegExp(this.regEx);
      if ((this.scopeValue == '' || this.regEx == '' || this.validateRegExp(regex, this.scopeValue))) {
        if (this.oldValue != this.scopeValue && this.scopeValue != '') {
          this.oldValue = this.scopeValue;
          this.$emit('update:value', this.scopeValue);
        }
      } else {
        event.preventDefault();
        this.scopeValue = this.oldValue;
      }
    },
    validateRegExp(regExp, string) {
      return regExp.test(String(string).toLowerCase());
    },
    inputFilter() {

    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  watch: {
    value(val) {
      this.oldValue = val;
      this.scopeValue = val;
    },
  },
};
</script>
