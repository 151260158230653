<template>
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text" v-lang.tools></h3>
                </div>
            </div>
            <a href="javascript:;" id="add_tool_btn" @click="openAddToolModal" class="btn-act btn-add">
                <span v-lang.add_tool></span>
            </a>
        </div>
        <div class="m-portlet__body">
            <tool-calendar-component></tool-calendar-component>
        </div>
        <tool-modal v-if="showToolModal" @closed="showToolModal=false;getResources()" @updated="getResources"/>
    </div>
</template>
<script>
import ToolModal from '../../../partials/modals/Tool/AddTool/AddTool.vue';
import ToolCalendarComponent from './ToolCalendarComponent.vue';

export default {
  data() {
    return {
      showToolModal: false,
    };
  },
  components: {
    'tool-modal': ToolModal,
    'tool-calendar-component': ToolCalendarComponent,
  },
  methods: {
    openAddToolModal() {
      const self = this;
      self.showToolModal = true;
      self.$nextTick(() => {
        const modalContainer = window.$('#tool_modal');
        modalContainer.modal({
          backdrop: 'static',
          show: true,
        });
        modalContainer.find('form').data('type', '');
      });
    },
    getResources() {
      this.$eventHub.$emit('RefreshToolResources');
    },
  },
};
</script>
