
























































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import { CFMDTORoomSubType } from '@/scripts/services/ClientApiAuto'
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'


@Component<RoomSubTypeDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
    },
    validations() {
        return {
        roomSubType:{
            Name: {required},
        },
        selectedType: {
            RoomTypeId: {required}
        },
        }
    },
})
export default class RoomSubTypeDialog extends CFMDialog {
    roomSubType: CFMDTORoomSubType = {Name: ''};
    typeList : {Name:String, Id:Number}[] = [];

    selectedType :CFMDTORoomSubType = {Name: ''}

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.roomSubType.roomSubTypeGetById({id: <number>this.id});
        this.roomSubType = res.data;
      }
      const promises = [
      this.getTypes()
      ];
      if(this.isUpdate){
        if(this.roomSubType && this.roomSubType.RoomTypeId){
          this.selectedType = this.roomSubType;
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    async getTypes(){
      const response = await this.apiClient.roomType.roomTypeGetRoomTypes();
      this.typeList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Id: this.roomSubType.Id,
        Name: this.roomSubType.Name,
        RoomTypeId: this.selectedType.RoomTypeId,
        IsActive: true
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.roomSubType.roomSubTypeSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
