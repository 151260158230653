import { mapState } from 'vuex';
import * as api from '../../services/api';
import chart from './chart';
import * as dateFormat from '../../../helpers/dateFormat';

export default {
  mixins: [chart],
  data() {
    return {
      WorkOrderCountsByStatus: {},
      data: [],
      donut: undefined,
    };
  },
  computed: {
    ...mapState({
      workOrderSourceEnums: (state) => state.enums.workOrderSource,
    }),
  },
  async mounted() {
    const self = this;
    await self.getData();
    self.initChart(self.data);
  },
  methods: {
    async updateData() {
      const self = this;
      await self.getData();
      self.redraw();
    },
    async getData() {
      const self = this;
      try {
        self.WorkOrderCountsByStatus = {};
        self.data = [];
        const workOrderStatusList = (await api.getWorkOrderStatusList(self.workOrderSourceEnums.planned)).body.Data;
        const workOrderList = (await api.getWorkOrderCountByStatus({
          fromDate: dateFormat.postDateFormat(self.startDate),
          endDate: dateFormat.postDateFormat(self.endDate),
          isIncludeMaintenance: true,
        })).body;
        /* workOrderList.forEach(function (workOrder) {
                    if (workOrder.IsShowOnCalendar) {
                        self.WorkOrderCountsByStatus[workOrder.OrderStatusId] = self.WorkOrderCountsByStatus[workOrder.OrderStatusId] + 1 || 1
                    }
                }); */
        self.data = workOrderStatusList.map((workOrderStatus) => {
          const count = workOrderList.find((x) => x.Text == workOrderStatus.Name);
          return {
            label: workOrderStatus.Name,
            Id: workOrderStatus.Id,
            value: count ? count.Value : 0,
            color: workOrderStatus.Color.Code,
          };
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    initChart(data) {
      const self = this;
      if ($(`#${self.chartId}`).length == 0) return;
      const config = {
        type: 'doughnut',
        data: {
          datasets: [{
            data: [],
            backgroundColor: [],
          }],
          labels: [],
        },
        options: {
          tooltips: {
            callbacks: {},
          },
          // responsive: true,
          legend: {
            position: 'left',
            labels: {
              usePointStyle: true,
            },
          },

          title: {
            display: true,
            text: `${self.translate('work_order_count')}: ${self.data.reduce((a, b) => a + b.value, 0)}`,
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          maintainAspectRatio: false,
        },
      };

      self.data.forEach((dataItem) => {
        config.data.datasets[0].data.push(dataItem.value);
        config.data.datasets[0].backgroundColor.push(dataItem.color);
        config.data.labels.push(`${dataItem.label} (${dataItem.value})`);
      });

      const onClick = (isClickedLegend, e, item) => {
        let index;
        if ((!Array.isArray(item) && item) || (Array.isArray(item) && item.length > 0)) {
          if (isClickedLegend) {
            index = item.index;
          } else {
            index = item[0]._index;
          }
          if (data[index].value > 0) {
            self.$router.push({
              name: 'WorkOrder',
              query: {
                workOrderStatusId: data[index].Id,
                startDate: self.startDate.getTime(),
                endDate: self.endDate.getTime(),
              },
            });
          } else {
            toastr.warning(self.translate('there_is_no_work_order_in_this_status'));
          }
        }
      };

      config.options.onClick = (e, item) => {
        onClick(false, e, item);
      };

      config.options.tooltips.callbacks.label = (tooltipItem, data) => {
        let label = data.labels[tooltipItem.index] || '';
        if (label) label += ': ';
        const total = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b) => a + b, 0);
        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return `${label}${((value / total) * 100).toFixed(2)}%`;
      };

      const ctx = $(`#${self.chartId}`);
      self.donut = new Chart(ctx, config);
    },
    redirect(orderStatusId) {
      this.$router.push({ name: 'WorkOrder', query: { workOrderStatusId: orderStatusId } });
    },
    redraw() {
      $(`#${this.chartId}`).removeAttr('width');
      $(`#${this.chartId}`).removeAttr('height');
      $(`#${this.chartId}`).html('');
      this.donut.destroy();
      this.initChart(this.data);
    },
  },
};
