import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';
import { mixinz } from '../global/mixinz';

export default {
  mixins: [mixinz],
  props: {
    slcQuestionGroup: {
      default() {
        return {};
      },
      type: Object,
    },
    isUpdate: { default: false },
  },
  data() {
    return {
      selectedQuestionGroup: {},
    };
  },
  mounted() {
    const self = this;
    window.$('form').validate();
    this.onCloseModal('#question_group_modal');
    self.$nextTick(() => {
      Vue.loadAsteriks();
    });
  },
  async created() {
    blockui.blockModal(this);
    const promises = [
      this.$store.dispatch('getCapacityEquipmentBreadCrumbList'),
    ];
    await Promise.all(promises);
    blockui.unBlockModal();
    if (this.isUpdate) {
      const res = await api.getQuestionGroup(this.slcQuestionGroup.Id);
      this.selectedQuestionGroup = res.body;
    } else {
      this.selectedQuestionGroup = this.slcQuestionGroup;
    }
  },
  computed: {
    ...mapState({
      formQuestionType: (state) => state.enums.formQuestionType,
    }),
    ...mapGetters({
      answerTypes: 'getFormQuestionTypes',
    }),
    getCurrentDevices() {
      const self = this;
      if (self.selectedQuestionGroup.DeviceIds && self.selectedQuestionGroup.DeviceIds.length > 0) {
        const currentDevices = [];
        self.selectedQuestionGroup.DeviceIds.forEach((device) => {
          currentDevices.push(device);
        });
        return currentDevices;
      }
      return [];
    },
    answerType: {
      get() {
        const self = this;
        const selectedObj = self.answerTypes.find((answerType) => self.selectedQuestionGroup.QuestionTypeId === answerType.Id);
        if (selectedObj) {
          return { Id: self.selectedQuestionGroup.QuestionTypeId, Name: selectedObj.Name };
        }
        return { Id: self.selectedQuestionGroup.QuestionTypeId, Name: 'loading' };
      },
      set(newValue) {
        Vue.set(this.selectedQuestionGroup, 'QuestionTypeId', newValue.Id);
        return newValue;
      },
    },
    questionStatus: {
      get() {
        return { Id: this.selectedQuestionGroup.StatusId, Name: this.selectedQuestionGroup.StatusName };
      },
      set(newValue) {
        Vue.set(this.selectedQuestionGroup, 'StatusId', newValue.Id);
        Vue.set(this.selectedQuestionGroup, 'StatusName', newValue.Name);
        return newValue;
      },
    },
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      form.validate();
      if (form.valid()) {
        blockui.blockModal(self);
        this.$globalApiClient.questionGroup.questionGroupSave(self.selectedQuestionGroup).then((response) => {
          if (response.data.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            self.$eventHub.$emit('getFormQuestions');
            $('#question_group_modal').modal('toggle');
          } else {
            toastr.error(response.data.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
  },
  updated() {
    const self = this;
    self.$nextTick(() => {
      Vue.loadAsteriks();
    });
  },
};
