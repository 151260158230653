import Vue from 'vue';
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators/';
import Select2Group from '../../../components/shared/select2Group';
import { formatDataWithParams } from '../../global/util';

export default {
  props: {
    capacityEquipment: {
      default() {
        return { DryBulbTemperatureUnitId: null, WetBulbTemperatureUnitId: null };
      },
      type: Object,
    },
    isUpdate: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    'select2-group': Select2Group,
  },
  computed: {
    ...mapState({
      capacityEquipmentModelRoomList: (state) => state.room.addCapacityEquipmentModalRoomList,
      roomCodeList: (state) => state.room.roomCodeList,
      campusList: (state) => state.location.campusList,
      temperatureUnitList: (state) => state.unit.temperatureUnitList,
    }),
    getCapacityEquipmentModelRoomList() {
      return formatDataWithParams(this.capacityEquipmentModelRoomList, ['Buildings', 'Floors', 'Rooms']);
    },
  },
  data() {
    return {
      campus: {},
      isDryTempUnitChanged: false,
      isWetTempUnitChanged: false,
    };
  },
  validations: {
    capacityEquipment: {
      DryBulbTemperatureUnitId: {
        required,
        checked(value, vm) {
          const self = this;
          return !self.capacityEquipment.DryBulbTemperatureUnitId && (vm.DryBulbTemperatureMin || vm.DryBulbTemperatureMax) ? !!((vm.DryBulbTemperatureMin != null || vm.DryBulbTemperatureMax != null)) : false;
        },

      },
      WetBulbTemperatureUnitId: {
        required,
        checked(value, vm) {
          const self = this;
          return !self.capacityEquipment.WetBulbTemperatureUnitId && (vm.WetBulbTemperatureMin || vm.WetBulbTemperatureMax) ? !!((vm.WetBulbTemperatureMin != null || vm.WetBulbTemperatureMax != null)) : false;
        },

      },
    },
  },
  created() {
    if (!this.capacityEquipment.DryBulbTemperatureUnitId) {
      Vue.set(this.capacityEquipment, 'DryBulbTemperatureUnitId', null);
    }
    if (!this.capacityEquipment.WetBulbTemperatureUnitId) {
      Vue.set(this.capacityEquipment, 'WetBulbTemperatureUnitId', null);
    }
  },
  mounted() {
    const self = this;
    window.$('form').validate();

    if (!self.capacityEquipment) {
      self.capacityEquipment = {};
    }

    this.$store.dispatch('getCapacityEquipmentModalRoomList').then((res) => {
      self.roomChanged(self.capacityEquipment.RoomId);
    });
    this.$store.dispatch('getRoomCodeList');
    this.$store.dispatch('getTemperatureUnitList');

    $('.date-picker').datepicker({
      autoclose: true,
      language: localStorage.getItem('vue-lang'),
    });

    if (self.capacityEquipment.DateOfInstallation) {
      window.$('#dateOfInstallation').datepicker('update', new Date(self.capacityEquipment.DateOfInstallation));
    }

    self.$nextTick(() => {
      Vue.loadAsteriks();
    });

    $('.room-modal').on('click', () => {
      $('#room_modal').modal({
        show: true,
        backdrop: 'static',
      });
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        self.$emit('next', formData);
      }
    },
    temperatureChanged() {
      Vue.loadAsteriks();
    },
    tempDryUnitChanged(e) {
      const self = this;
      self.capacityEquipment.DryBulbTemperatureUnitId = e;
      self.isDryTempUnitChanged = !!e;
    },
    tempWetUnitChanged(e) {
      const self = this;
      self.capacityEquipment.WetBulbTemperatureUnitId = e;
      self.isWetTempUnitChanged = !!e;
    },
    roomChanged(roomId, isRoom) {
      const self = this;
      self.capacityEquipment.RoomId = roomId;
      self.capacityEquipmentModelRoomList.forEach((campus) => {
        if (campus.Buildings) {
          campus.Buildings.forEach((building) => {
            if (building.Floors) {
              building.Floors.forEach((floor) => {
                if (floor.Rooms) {
                  floor.Rooms.forEach((room) => {
                    if (room) {
                      if (room.Id == roomId) {
                        self.campus = campus;
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    /*    formatData: function (data) {
          const self = this;
          if (!data || !(data.length > 0)) {
            return [];
          }
          let b = [];
          data.forEach(item => {
            let a = [];
            let containsArray = false;
            let restrictedKeys = ['Rooms','Floors','Buildings']
            Object.keys(item).forEach(key => {
              if(typeof item[key] === 'object' &&  restrictedKeys.includes(key)  ){
                containsArray = true;
                a.push(self.formatData(item[key]))
              }
            });
            if (containsArray) {
              b.push({text: item.Name, children: a[0]})
            }
            else {
              b.push({text: item.Name, id: item.Id})
            }
          });
          return b;
        }, */
  },
};
