<template>
    <div class="workorder_info_wrapper row">
        <div class="col-md-4">
            <div class="workorder_info_item">
                <label v-lang.room_capacity_equipment></label>
                <p>{{workOrder.BreadCrumb}}</p>
            </div>
            <div class="workorder_info_item">
                <label>{{translate('inventory')}} No</label>
                <p>{{workOrder.DeviceId}}</p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="workorder_info_item">
                <label v-lang.capacity_type></label>
                <p>{{workOrder.CapacityTypeName}}</p>
            </div>
            <div class="workorder_info_item">
                <label v-lang.infrastructure_type></label>
                <p>{{workOrder.InfrastructureTypeName}}</p>
            </div>
        </div>
        <div class="col-md-4">
            <div class="workorder_info_item">
                <label v-lang.infrastructure_group></label>
                <p>{{workOrder.InfrastructureGroupName}}</p>
            </div>
            <div class="workorder_info_item">
                <label v-lang.infrastructure></label>
                <p>{{workOrder.InfrastructureName}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'WorkOrderInventoryCard',
  props: ['workOrder'],
};
</script>

<style scoped>

</style>
