import { mapState } from 'vuex';
import * as swal from '../../../helpers/swal';
import * as api from '../../services/api';

export default {
  data() {
    return {};
  },
  props: [
    'capacityEquipment',
    'isUpdate',
  ],
  computed: {
    ...mapState({
      financialItemList: (state) => state.capacity_equipment.financialItemList,
    }),
  },
  mounted() {
    const self = this; let
      table;
    self.$store.dispatch('getFinancialItemList', self.capacityEquipment.Id);
    mApp.initScrollers();
  },
  methods: {
    deleteItem(index) {
      const self = this;
      const id = self.financialItemList[index].Id;
      const name = self.financialItemList[index].Text;
      swal.check(self, name).then((e) => {
        if (e.value) {
          api.deleteFinancialItem(id).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              self.$store.dispatch('getDescriptionList', { deviceId: self.capacityEquipment.Id });
            } else {
              toastr.error(response.body.Message);
            }
          });
        }
      });
    },
    editItem(index) {
      this.$parent.$parent.editItem('financialItems', { deviceId: this.capacityEquipment.Id, financialItemId: this.financialItemList[index].Id });
    },
  },
  watch: {
    financialItemList(e) {
      if (e) {
        this.$parent.selectedCapacityEquipment.FinancialCount = e.length;
      }
    },
  },
};
