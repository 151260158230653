<template>
    <div tabindex="-1" class="modal modal-side fade" :id="modalId" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header column-header">
                    <div>
                        <h5 class="modal-title">
                            {{isUpdate ? this.translate('edit_section') : this.translate('add_section')}}
                        </h5>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form autocomplete="off" action="" method="post" v-on:submit.prevent="onSubmit" novalidate="novalidate">
                    <div class="modal-body">
                        <div class="form-group m-form__group" :class="{ 'form-group--error': $v.selectedContractSection.Name.$error,'has-danger': $v.selectedContractSection.Name.$error}">
                            <label><span v-lang.section_name></span><span class="red">*</span></label>
                            <input v-model="selectedContractSection.Name" type="text" name="Name" class="form-control"
                                   required>
                            <template v-if="$v.selectedContractSection.Name.$error">
                                <div v-if="$v.selectedContractSection.Name.$error"
                                     class="form-control-feedback error"
                                     v-lang.this_field_is_required></div>
                            </template>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" v-lang.cancel></button>
                        <button type="submit" class="btn btn-primary" v-lang.save></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import * as api from '../../../scripts/services/api';
import * as blockui from '../../../helpers/block-ui';
import { mixinz } from '../../../scripts/global/mixinz';

export default {
  mixins: [mixinz],
  props: {
    contractSection: {
      default() {
        return {};
      },
      type: Object,
    },
    isUpdate: { default: false },
  },
  data() {
    return {
      modalId: 'contract_section_modal',
      selectedContractSection: {},
    };
  },
  validations() {
    return {
      selectedContractSection: {
        Name: { required },
      },
    };
  },
  created() {
    this.selectedContractSection = this.contractSection;
  },
  mounted() {
    $(this.modalQuery).modal({
      backdrop: 'static',
      show: true,
    });

    $(this.modalQuery).on('hidden.bs.modal', (e) => {
      this.$emit('close');
    });
  },
  computed: {
    modalQuery() {
      return `#${this.modalId}`;
    },
    modalBodyQuery() {
      return `${this.modalQuery} .modal-body`;
    },
  },
  methods: {
    async onSubmit(e) {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      blockui.blockElementPromise(this, this.modalBodyQuery);
      try {
        const response = await api.saveContractSection(self.selectedContractSection);
        if (!response.body.IsSuccess) {
          toastr.error(response.body.Message);
          return;
        }
        toastr.success(self.translate('item_saved_successfully').formatString(self.selectedContractSection.Name));
        // self.$eventHub.$emit('getFormQuestions');
        this.$emit('changed');
        $(this.modalQuery).modal('toggle');
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.modalBodyQuery);
      }
    },
  },
  watch: {
    contractSection(newValue) {
      this.selectedContractSection = newValue;
    },
  },
};
</script>
