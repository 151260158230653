import AnswerCondition from '../AnswerCondition/AnswerCondition.vue';
import deepClone from '../utilities.js';

export default {
  name: 'vue-query-builder',
  components: {
    'answer-condition': AnswerCondition,
  },
  props: {
    styled: {
      type: Boolean,
      default: true,
    },
    maxDepth: {
      type: Number,
      default: 3,
      validator(value) {
        return value >= 1;
      },
    },
    comparisonOperators: Array,
    operandTypes: {
      type: Array,
    },
    formQuestionTypeComparisonOperators: Array,
    questionTypeId: Number,
    enumFormQuestionType: Object,
    value: Object,
    showErrors: Boolean,
  },
  data() {
    return {
      depth: 1,
      query: {
        OperandTypeId: 0,
        AnswerConditionRules: [],
        Children: [],
      },
    };
  },
  computed: {
    currentComparisonOperators() {
      const filteredFormQuestionTypeComparisonOperators = this.formQuestionTypeComparisonOperators.filter((formQuestionTypeComparisonOperator) => formQuestionTypeComparisonOperator.FormQuestionTypeId == this.questionTypeId);
      return this.comparisonOperators.filter((comparisonOperator) => filteredFormQuestionTypeComparisonOperators.find((formQuestionTypeComparisonOperator) => formQuestionTypeComparisonOperator.ComparisonOperatorId == comparisonOperator.Id));
    },
  },
  mounted() {
    this.query = {
      OperandTypeId: this.operandTypes[0].Id,
      AnswerConditionRules: [],
      Children: [],
    };
    this.$watch(
      'query',
      (newQuery) => {
        if (JSON.stringify(newQuery) !== JSON.stringify(this.value)) {
          this.$emit('input', deepClone(newQuery));
        }
      }, {
        deep: true,
      },
    );
    this.$watch(
      'value',
      (newValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(this.query)) {
          this.query = deepClone(newValue);
        }
      }, {
        deep: true,
      },
    );
    if (typeof this.$options.propsData.value !== 'undefined') {
      this.query = Object.assign(this.query, this.$options.propsData.value);
    }
  },
};
