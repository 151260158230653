import * as api from '../../scripts/services/api';

// initial state
const state = {
  descriptionList: [],
};

// getters
const getters = {};

// actions
const actions = {
  async getDescriptionList({ commit }, data) {
    await api.getDescriptionList(data).then((res) => {
      commit('setDescriptionList', res.body.Data);
    });
  },
};

// mutations
const mutations = {
  setDescriptionList(state, description) {
    state.descriptionList = description;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
