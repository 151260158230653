import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import Select2 from '../../components/shared/select2';
import * as blockui from '../../helpers/block-ui';
import * as dateFormat from '../../helpers/dateFormat';
import { mixinz } from '../global/mixinz';

export default {
  props: ['userOvertime', 'isUpdate'],
  mixins: [mixinz],
  data() {
    return {
      selectedUserOvertime: {
        OvertimeDate: ''
      }
    };
  },
  components: {
    select2: Select2,
  },
  computed: {
    ...mapState({
    }),
    selectedDate: {
      get() {
        const self = this;
        if (self.selectedUserOvertime.OvertimeDate) {
          return moment(self.selectedUserOvertime.OvertimeDate).format('DD.MM.YYYY');
        }
        return '';
      },
      set(newVal) {
        const self = this;
        Vue.set(self.selectedUserOvertime, 'OvertimeDate', moment(newVal, 'DD.MM.YYYY').toDate());
      },
    }
  },
  created() {
    const self = this;
    self.selectedUserOvertime = self.userOvertime;
  },
  async mounted() {
    const self = this;
    window.$('form').validate();
    self.$nextTick(() => {
      Vue.loadAsteriks();
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        formData.OvertimeDate = dateFormat.postDateTimeFormatFromDateObject(self.selectedUserOvertime.OvertimeDate);
        api.saveUserOvertime(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            self.$store.dispatch('getUserOvertimeList', self.userOvertime.UserId);
            self.closeModal();
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    reloadDataTable() {
      Vue.getDatatables();
    },
    closeModal() {
      $('#user_overtime_modal').modal('toggle');
      this.$emit('close');
    }
  },
};
