// @ts-ignore
import { getCookieByName } from '@/scripts/global/util';

// @ts-ignore
import { baseUrl } from './api.js';
import { ApiConfig } from './ClientApiAuto';

const apiConfig: ApiConfig = {
  secure: true,
  securityWorker: (securityData) => {
    return {
      headers: {
        common: {
          Authorization: `${getCookieByName('tokenType')} ${getCookieByName('token')}`,
        },
      },
    }
  },
  baseURL: baseUrl,
  headers: {
    post: {
      Accept: 'application/json',
      Culture: localStorage.getItem('vue-lang'),
    },
    transformResponse: [],
  },
};



export default apiConfig;
