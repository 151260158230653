import * as api from '../../scripts/services/api';

// initial state
const state = {
  colors: [],
};

// getters
const getters = {};

// actions
const actions = {
  getColors({ commit }) {
    api.getColors().then((res) => {
      commit('setColors', res.body.Data);
    });
  },
};

// mutations
const mutations = {
  setColors(state, colors) {
    state.colors = colors;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
