import * as api from '../../../../scripts/services/api';
import * as swal from '../../../../helpers/swal';
import * as blockui from '../../../../helpers/block-ui';
import grid from '../../../../scripts/global/gridOld';
import ToolModal from '../../../partials/modals/Tool/AddTool/AddTool.vue';
import ToolDetail from '../ToolDetail/ToolDetail';

export default {
  data() {
    return {
      IsDisplayTree: true,
      IsGridReady: false,
      gridEngine: undefined,
      treeGridEngine: undefined,
      widgetHelper: bryntum.scheduler.WidgetHelper,
      gridData: [],
      treeData: [],
      columns: [
        {
          locked: true,
          type: 'number',
          field: 'id',
          text: 'Id',
          width: 75,
          renderer: ({ value }) => (Number.isInteger(value) ? value : ''),
          tooltipRenderer: false,
        },
        {
          locked: true,
          type: 'tree',
          field: 'toolTree',
          text: 'Tool',
          leafIconCls: 'b-icon b-fa-wrench',
          collapsedFolderIconCls: 'b-icon b-fa-box',
          expandedFolderIconCls: 'b-icon b-fa-box-open',
          width: 200,
          renderer({ record, value }) {
            function getChildCounts(r) {
              let total = 0;
              if (r.children) r.children.forEach((child) => total += getChildCounts(child));
              else total++;
              return total;
            }
            return record.children ? `${value} (${getChildCounts(record)})` : value;
          },
        },
        {
          type: 'widget',
          groupable: false,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-edit',
            cls: 'b-blue',
            style: 'width:100%',
            onAction: ({ source }) => {
              if (source.cellInfo.record.id.toString().startsWith('_generated')) return;
              this.$router.push({ name: 'ToolDetail', query: { toolId: source.cellInfo.record.id } });
            },
          }],
          field: 'edit',
          text: 'Edit',
          width: 75,
          locked: true,
        },
        {
          type: 'widget',
          groupable: false,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-trash',
            cls: 'b-red',
            style: 'width:100%',
            onAction: ({ source }) => {
              if (source.cellInfo.record.id.toString().startsWith('_generated')) return;
              const self = this;
              swal.check(self, source.cellInfo.record.toolTree).then((e) => {
                if (e.value) {
                  api.deleteTool(source.cellInfo.record.id).then((response) => {
                    if (response.body.IsSuccess) {
                      toastr.success(self.translate('delete_successfully'));
                      self.getResources();
                    } else {
                      toastr.error(response.body.Message);
                    }
                  });
                }
              });
            },
          }],
          field: 'delete',
          text: 'Delete',
          width: 75,
          locked: true,
        },
        {
          field: 'warehouse',
          text: 'Warehouse',
          width: 180,
        },
        {
          field: 'isNeedCalibration',
          text: 'Calibration',
          width: 100,
          htmlEncode: false,
          tooltipRenderer: false,
          renderer: ({ value }) => (value == null ? '' : `<div class="b-widget b-checkbox b-green"><input disabled type="checkbox" ${value ? 'checked' : ''}><label/></div>`),
        },
        {
          type: 'date',
          field: 'nextCalibrationDate',
          text: 'Next Calibration',
          format: 'LLL',
          width: 120,
        },
        {
          field: 'isNeedPeriodicMaintenance',
          text: 'Maintenance',
          width: 100,
          htmlEncode: false,
          tooltipRenderer: false,
          renderer: ({ value }) => (value == null ? '' : `<div class="b-widget b-checkbox blue"><input disabled type="checkbox" ${value ? 'checked' : ''}><label/></div>`),
        },
        {
          type: 'date',
          field: 'nextMaintenanceDate',
          text: 'Next Maintenance',
          format: 'LLL',
          width: 120,
        },
        {
          field: 'brand',
          text: 'Brand',
          width: 120,
        },
        {
          field: 'model',
          text: 'Model',
          width: 120,
        },
        {
          field: 'toolStatus',
          text: 'Tool Status',
          width: 120,
        },
        {
          field: 'serialNumber',
          text: 'Serial Number',
          width: 120,
        }],
      /* selectedTool: {},
                   isUpdate: false, */
      showToolModal: false,
    };
  },
  components: {
    grid,
    'tool-modal': ToolModal,
  },
  async created() {
    const self = this;
    blockui.pageBlock(self);
    const promises = [
      self.getResources(),
    ];
    Promise.all(promises);
  },
  methods: {
    initTreeGrid() {
      const self = this;
      self.treeGridEngine = self.$refs.treeGrid.gridEngine;
      const toolTypeCol = self.columns.find((x) => x.field === 'toolTree');
      toolTypeCol.type = null;
      toolTypeCol.renderer = null;
      toolTypeCol.width = 150;
      toolTypeCol.text = 'Tool Type';
      toolTypeCol.field = 'toolType';
      self.columns.splice(2, 0, {
        locked: true,
        field: 'toolSubType',
        text: 'Subtype',
        width: 150,
      });
      self.IsGridReady = true;

      const gridButtons = [{
        type: 'button',
        cls: 'b-raised b-blue',
        margin: 5,
        icon: 'b-fa b-fa-toggle-on',
        tooltip: self.translate('toggle_view'),
        onAction: function onAction() {
          self.IsDisplayTree = !self.IsDisplayTree;
          self.IsDisplayTree ? self.treeGridEngine.show() : self.treeGridEngine.hide();
          !self.IsDisplayTree ? self.gridEngine.show() : self.gridEngine.hide();
        },
      }];
      self.widgetHelper.append({ type: 'container', widgets: gridButtons, cls: 'pull-left' }, { insertFirst: document.getElementsByClassName('gridContainer')[0] });
    },
    initGrid() {
      const self = this;
      self.gridEngine = self.$refs.grid.gridEngine;
      self.gridEngine.hide();
    },
    async getResources() {
      const self = this;
      await api.getToolTypeList({ isIncludeSubData: true }).then((res) => {
        self.treeData = [];
        self.gridData = [];
        if (res.body.IsSuccess) {
          res.body.Data.forEach((toolType) => {
            const type = {
              expanded: true,
              toolTree: toolType.Name,
              toolType: null,
              toolSubType: null,
              isNeedCalibration: null,
              nextCalibrationDate: null,
              isNeedPeriodicMaintenance: null,
              nextMaintenanceDate: null,
              brand: null,
              model: null,
              description: null,
              warehouse: null,
              toolStatus: null,
              serialNumber: null,
              children: toolType.Tools ? toolType.Tools.map((tool) => ({
                id: tool.Id,
                isNeedCalibration: tool.IsNeedCalibration,
                nextCalibrationDate: tool.NextCalibrationDate,
                isNeedPeriodicMaintenance: tool.IsNeedPeriodicMaintenance,
                nextMaintenanceDate: tool.NextMaintenanceDate,
                toolTree: toolType.Name,
                toolType: toolType.Name,
                toolSubType: null,
                brand: tool.Brand ? tool.Brand.Name : null,
                model: tool.Model ? tool.Model.Name : null,
                description: tool.Description,
                warehouse: tool.Warehouse && self.getParentRecursive(tool.Warehouse), // WarehouseName
                toolStatus: tool.ToolStatus.Name,
                serialNumber: tool.SerialNumber,
              })) : [],
            };

            self.gridData.push(...type.children);

            toolType.ToolSubTypes.forEach((toolSubType) => {
              const subType = {
                expanded: true,
                toolTree: toolSubType.Name,
                children: toolSubType.Tools ? toolSubType.Tools.map((tool) => ({
                  id: tool.Id,
                  isNeedCalibration: tool.IsNeedCalibration,
                  nextCalibrationDate: tool.NextCalibrationDate,
                  isNeedPeriodicMaintenance: tool.IsNeedPeriodicMaintenance,
                  nextMaintenanceDate: tool.NextMaintenanceDate,
                  toolTree: toolSubType.Name,
                  toolType: toolType.Name,
                  toolSubType: toolSubType.Name,
                  brand: tool.Brand ? tool.Brand.Name : null,
                  model: tool.Model ? tool.Model.Name : null,
                  description: tool.Description,
                  warehouse: tool.Warehouse && self.getParentRecursive(tool.Warehouse), // WarehouseName
                  toolStatus: tool.ToolStatus.Name,
                  serialNumber: tool.SerialNumber,
                })) : [],
              };

              self.gridData.push(...subType.children);

              if (subType.children.length > 0) type.children.push(subType);
            });

            if (type.children.length > 0) self.treeData.push(type);
          });
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    getParentRecursive(obj) {
      return (obj.Parent ? this.getParentRecursive(obj.Parent) : '').concat((obj.Parent ? ' > ' : '') + obj.Name);
    },
    async updateGrid(updatedRecord) {
      const self = this;
      if (updatedRecord.Id) await self.getResources();
      else {
        const ids = self.gridData.map((record) => record.id);
        await self.getResources();
        updatedRecord.Id = self.gridData.filter((record) => !ids.some((id) => id === record.id))[0].id;
      }
      self.gridEngine.scrollRowIntoView(updatedRecord.Id, { block: 'center', highlight: true, animate: true });
    },
    openAddToolModal() {
      const self = this;
      self.showToolModal = true;
      self.$nextTick(() => {
        const modalContainer = window.$('#tool_modal');
        modalContainer.modal({
          backdrop: 'static',
          show: true,
        });
        modalContainer.find('form').data('type', '');
      });
    },
  },
  mounted() {
  },
};
