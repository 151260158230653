<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <point-tree :capacityEquipmentId="2368" @onDrag="onDragStart" @treeItemSelected="onTreeItemSelected"></point-tree>
            </div>
            <div class="col-md-6">
                <div class="sections">
                    <div v-for="section in sectionList">
                        <span>{{section.Name}}</span>
                        <div v-for="questionGroup in questionGroupList.filter(questionGroup=>questionGroup.SectionId==section.Id)"
                             @dragover.stop.prevent="isDragEnter = true"
                             @drop.stop.prevent="drop(questionGroup)"
                             style="padding-left: 20px;">
                            <span>-- {{questionGroup.Name}}</span>
                            <div v-for="question in questionGroup.Questions" style="height: 100px;padding-left: 20px;">
                                <span>-- -- {{question.Name}}</span>
                                <div v-if="question.Point" style="padding-left: 20px;">
                                    <span> {{question.Point.Name}} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import PointTree from './PointTree';

export default {
  props: {},
  data() {
    return {
      isDragEnter: false,
      sectionList: [
        { Name: 'Preparation', Order: 1, Id: 1 },
        { Name: 'Tools', Order: 2, Id: 2 },
        { Name: 'Parts', Order: 3, Id: 3 },
        { Name: 'Activity', Order: 4, Id: 4 },
        { Name: 'Feedback', Order: 5, Id: 5 },
      ],
      questionGroupList: [
        {
          Id: 1,
          Name: 'QG-1',
          Order: 1,
          SectionId: 1,
          Questions: [
            { Id: 1, Name: 'Q-1', Order: 1 },
            { Id: 2, Name: 'Q-2', Order: 2 },
            { Id: 3, Name: 'Q-3', Order: 3 },
            { Id: 4, Name: 'Q-4', Order: 4 },
            { Id: 5, Name: 'Q-5', Order: 5 },
          ],
        },
        {
          Id: 2,
          Name: 'QG-2',
          Order: 2,
          SectionId: 2,
          Questions: [
            { Id: 6, Name: 'Q-6', Order: 1 },
            { Id: 7, Name: 'Q-7', Order: 2 },
            { Id: 8, Name: 'Q-8', Order: 3 },
            { Id: 9, Name: 'Q-9', Order: 4 },
            { Id: 10, Name: 'Q-10', Order: 5 },
          ],
        },
      ],
      selectedPoint: null,
      draggedPointId: null,
    };
  },
  components: { 'point-tree': PointTree },
  mounted() {

  },
  computed: {},
  methods: {
    onTreeItemSelected(e) {
      this.selectedPoint = e.value;
    },
    onDragStart(e) {
      this.draggedPointId = e.value;
    },
    drop(questionGroup) {
      this.addLinkedQuestion(questionGroup.Id, this.draggedPointId);
    },
    addLinkedQuestion(questionGroupId, pointId) {
      const questionGroup = this.questionGroupList.find((questionGroup) => questionGroup.Id == questionGroupId);
      const max = questionGroup.Questions.reduce((max, question) => (max > question.Order ? max : question.Order), 0);
      questionGroup.Questions.push({ Name: 'New One', Order: max + 1, Point: { Name: `pointId: ${pointId}` } });
    },
  },
};
</script>

<style scoped>

</style>
