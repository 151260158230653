<template>
    <div>
        <div class="input-group" :class="addButton && !isChild ? 'select-add-button' : ''">
            <input type="text" ref="select" v-model="selected" :name="name" :disabled="disabled" :required="required"/>
            <div v-if="addButton && !isChild" class="input-group-append">
                <button @click="onAddButtonClick" class="btn btn-metal" :class="addButton" type="button"
                        data-select2-open="single-append-text">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';

export default {
  props: ['items', 'selected', 'required', 'name', 'id', 'classNames', 'multiple', 'allowClear', 'addButton', 'isChild', 'selectAll', 'disabled'],
  data() {
    return {
      isUpdate: false,
      isClicked: false,
      value: null,
    };
  },
  mounted() {
    const vm = this;
    $(this.$refs.select)
      .select2({
        data: vm.items,
        placeholder: this.translate('please_select'),
        matcher: this.modelMatcher,
        dropdownParent: $(this.$refs.select).closest('div'),
        allowClear: true,
      })
      .val(this.selected)
    // .on('select2:open', function () {
    //   setTimeout(()=>{
    //     $( ".select2-results__option" ).on( "click", function(e) {
    //       e.stopPropagation();
    //       vm.$emit('valueChanged', $(this).attr('data-select2-id'));
    //     });
    //   },100)
    //   // vm.$emit('selectClosed', $(vm.$refs.select).val());
    // })
    // .on('select2:close', function () {
    //   $('.select2-results__option').off('click');
    //   vm.$emit('selectClosed', $(vm.$refs.select).val());
    // })
      .on('change', (e) => {
        const newVal = e.target.value;
        const id = e.target.value;

        if (vm.value !== newVal) {
          vm.value = newVal;
          $(vm.$refs.select).val(newVal);
          vm.$emit('valueChanged', $(vm.$refs.select).val() !== null ? $(vm.$refs.select).val() : vm.selected);
        }
        vm.setBreadCrumb(id);
      });
  },
  watch: {
    selected(value) {
      $(this.$refs.select)
        .val(value).trigger('change');
    },
    items(items) {
      if (this.selected) {
        this.setBreadCrumb(this.selected);
      }
      $(this.$refs.select).select2({
        data: this.items,
        placeholder: this.translate('please_select'),
        matcher: this.modelMatcher,
        dropdownParent: $(this.$refs.select).closest('div'),
        allowClear: true,
      });
    },
  },
  methods: {
    onAddButtonClick() {
      this.$emit('add-button-click', this.addButton);
    },
    // update options
    searchSelect2(params, data) {
      if ($.trim(params.term) === '') {
        return data;
      }
      const original = data.text.toUpperCase();
      const term = params.term.toUpperCase();
      if (data.children && data.children.length > 0) {
        if (original.indexOf(term) > -1) return data;

        for (let c = data.children.length - 1; c >= 0; c--) {
          const matches = this.searchSelect2(params, data.children[c]);
          if (matches == null) {
            data.children.splice(c, 1);
          }
        }
        if (data.children && data.children.length > 0) {
          return data;
        }
        return null;
      } if (original.indexOf(term) > -1) {
        return data;
      }
      return null;
    },
    modelMatcher(params, data) {
      const temp = $.extend(true, {}, data);
      return this.searchSelect2(params, temp);
    },
    reloadSelect2() {
      this.$emit('reloadSelect2');
    },
    setBreadCrumb(id) {
      function getBreadCrumb(id, currentNode) {
        let i;
        let currentChild;
        let result;

        if (id == currentNode.id) {
          return currentNode;
        }
        if (currentNode.children) {
          for (i = 0; i < currentNode.children.length; i += 1) {
            currentChild = currentNode.children[i];
            result = getBreadCrumb(id, currentChild);
            if (result !== false && result) {
              result.text = `${currentNode.text} > ${result.text}`;
              return result;
            }
          }
        }
        return false;
      }

      const vm = this;
      let a = null;
      vm.items.forEach((item) => {
        const temp = $.extend(true, {}, item);
        const b = getBreadCrumb(id, temp);
        if (b) {
          a = b;
        }
      });

      setTimeout(() => {
        if (a != null) {
          $(vm.$refs.select).next('.select2').find('.select2-selection__rendered').html(a.text);
        } else {
          $(vm.$refs.select).next('.select2').find('.select2-selection__rendered').html('');
        }
      });
    },
  },
  destroyed() {
    $(this.$refs.select).off().select2('destroy');
  },
};
</script>
