import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';

export default {
  data() {
    return {};
  },
  mounted() {
    const self = this;
    window.$('form').validate();
  },
  methods: {
    onSubmit(e) {
      const self = this;
      e.preventDefault();
      const form = $(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        api.changePassword(formData).then((res) => {
          if (res.body.IsSuccess) {
            $('.modal').modal('hide');
            toastr.success(this.translate('password_changed'));
            // window.location = '#/Logout';
          } else {
            toastr.warning(res.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    closeModal() {
      $('#change_password_modal').modal('toggle');
    },
  },
};
