/**
 * format nested array
 * @returns Array data of id, name and if key exist nested children
 */
export function formatDataWithParams(data, keys) {
  if (!data || !(data.length > 0)) {
    return [];
  }
  const result = [];
  for (const dataItem of data) {
    let resultItem = {};
    if (keys && keys.length > 0) {
      let children = [];
      if (dataItem.hasOwnProperty(keys[0])) {
        children = formatDataWithParams(dataItem[keys[0]], [...keys.slice(1, keys.length)]);
      }
      resultItem.children = children;
    } else {
      resultItem = { ...resultItem, id: dataItem.Id };
    }
    resultItem = { ...resultItem, text: dataItem.Name };
    result.push(resultItem);
  }
  return result;
}

export function compareArrayResult(arr1, arr2) {
  arr1 = [...arr1];
  arr2 = [...arr2];
  if (!arr1 || !arr2) return false;
  if (arr1.length != arr2.length) return false;
  let result = true;

  arr1.forEach((e1, i) => {
    const indexOfSecondArray = arr2.findIndex((e2) => e2 == e1);
    if (indexOfSecondArray < 0) {
      result = false;
    } else {
      arr2.splice(indexOfSecondArray, 1);
    }
  });
  return result;
}

export function getCookieByName(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length == 2) return parts.pop().split(';').shift();
}

export function deepCloneWithPrefixIgnore(obj, ignoredPrefixes) {
  if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj) {
    return obj;
  }
  let temp;
  if (obj instanceof Date) {
    temp = new obj.constructor(); // or new Date(obj);
  } else {
    temp = obj.constructor();
  }
  for (const key in obj) {
    if (!ignoredPrefixes.some((x) => key.startsWith(x)) && Object.prototype.hasOwnProperty.call(obj, key)) {
      obj.isActiveClone = null;
      temp[key] = deepCloneWithPrefixIgnore(obj[key], ignoredPrefixes);
      delete obj.isActiveClone;
    }
  }
  return temp;
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function base64toBlob(base64Data, type) {
  type = type || '';
  // Convert the Base64 string back to text.
  const byteString = atob(base64Data);

  // Convert that text into a byte array.
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type });
}

export function downloadBlob(blob, fileName) {
  const a = $("<a style='display: none;'/>");
  const url = window.URL.createObjectURL(blob);
  a.attr('href', url);
  a.attr('download', fileName);
  $('body').append(a);
  a[0].click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

export function compareDates(a, b) {
  const dateA = moment(a).toDate();
  const dateB = moment(b).toDate();
  if (dateA < dateB) {
    return -1;
  } if (dateA > dateB) {
    return 1;
  }
  return 0;
}

export function isMobileBrowser() {
  return !!(navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i));
}
