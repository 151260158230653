import * as api from '../../scripts/services/api';

// initial state
const state = {
  // List
  roomList: [],
  addCapacityEquipmentModalRoomList: [],
  roomTypeList: [],
  roomSubTypeList: [],
  roomCodeList: [],
  // Selected
  selectedRoom: null,
  // Related Stores
  roomRelated: {
    WorkOrder: ['getRoomList', 'getCapacityEquipmentModalRoomList'],
    RoomCapacityEquipment: ['getRoomList', 'getCapacityEquipmentModalRoomList'],
    InventoryList: ['getRoomList', 'getCapacityEquipmentModalRoomList'],
    xyz: ['getRoomList', 'getCapacityEquipmentModalRoomList'],
  },
};

// getters
const getters = {
  getRoomList: (state) => state.roomList.map((x) => ({ Id: x.Value, Name: x.Text })),
};

// actions
const actions = {
  getRelatedRoomList({ commit, dispatch }, pathName) {
    const relatedList = state.roomRelated[pathName];
    relatedList.forEach((item) => {
      dispatch(item);
    });
  },
  getRoomList({ commit }) {
    api.getAllRooms().then((res) => {
      commit('setRoomList', res.body);
    });
  },
  async getCapacityEquipmentModalRoomList({ commit }) {
    await api.getCampusListWithRooms().then((res) => {
      commit('setAddCapacityEquipmentModalRoomList', res.body.Data);
    });
  },
  async getRoomTypeList({ commit }) {
    await api.getRoomTypes().then((res) => {
      commit('setRoomTypeList', res.body);
    });
  },
  getRoomSubTypeList({ commit }, data) {
    api.getRoomSubTypes(data).then((res) => {
      commit('setRoomSubTypeList', res.body);
    });
  },
  getRoomCodeList({ commit }, data) {
    api.getRoomCodes().then((res) => {
      commit('setRoomCodeList', res.body);
    });
  },
};

// mutations
const mutations = {
  // List
  setRoomList(state, rooms) {
    state.roomList = rooms;
  },
  setAddCapacityEquipmentModalRoomList(state, rooms) {
    state.addCapacityEquipmentModalRoomList = rooms;
  },
  setRoomTypeList(state, roomTypes) {
    state.roomTypeList = roomTypes;
  },
  setRoomSubTypeList(state, roomSubTypes) {
    state.roomSubTypeList = roomSubTypes;
  },
  setRoomCodeList(state, roomCodes) {
    state.roomCodeList = roomCodes;
  },
  // Selected
  setSelectedRoom(state, continent) {
    state.selectedRoom = continent;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
