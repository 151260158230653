import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import * as api from '../../../../scripts/services/api';
import * as swal from '../../../../helpers/swal';
import * as blockui from '../../../../helpers/block-ui';

import Tabs from '../../../wrappers/TabView/Tabs/Tabs.vue';
import Tab from '../../../wrappers/TabView/Tab/Tab.vue';
import ToolHistory from './History/ToolHistory.vue';
import EditTool from './EditTool/EditTool.vue';
import ReserveTool from './ReserveTool/ReserveTool.vue';
import bField from '../../../shared/Field.vue';

export default {
  props: ['toolId'],
  data() {
    return {
      dateConfig: {
        badge: '1',
        label: 'Date',
        editable: false,
        min: new Date(2018, 4, 20),
        value: new Date(2018, 4, 19),
      },
      timeConfig: {
        label: 'Time',
        editable: false,
        min: new Date(2018, 4, 20),
        value: new Date(2018, 4, 19),
      },
      textConfig: {
        label: 'Text',
        clearable: true,
      },
      numberConfig: {
        label: 'Number',
        min: 200,
        value: 100,
        clearable: true,
      },
      checkboxConfig: {
        color: 'b-blue',
        text: 'Checkbox',
        checked: true,
      },
      silderConfig: {
        text: 'Slider',
        showTooltip: true,
      },
      textAreaConfig: {
        placeholder: 'Text Area',
        clearable: true,
      },
      buttonConfig: {
        badge: 1,
        cls: 'b-raised',
        text: 'Show menu',
        onClick: ({ source: btn }) => {
          btn.badge++;
          const menu = btn.menu || (btn.menu = new bryntum.scheduler.Menu({
            forElement: btn.element,
            items: [{
              icon: 'b-icon b-icon-add',
              text: 'Add',
            }, {
              icon: 'b-icon b-icon-trash',
              text: 'Remove',
            }, {
              text: 'Sub menu',
              menu: [{
                icon: 'b-icon fa-play',
                text: 'Play',
              }],
            }],
          }));
          menu.show();
        },
      },
      comboConfig: {
        multiSelect: true,
        label: 'Combo',
        items: [
          'BASIC',
          'COBOL',
          'FORTRAN',
          'DBL',
          'SQL',
          'C/C++',
          'Java',
          'Javascript',
        ],
      },
      toolBarConfig: {
        items: [
          { text: 'Add' },
          { text: 'Delete' },
        ],
      },
      tabPanelConfig: {
        widgets: [
          {
            text: 'Tab 1',
            widgets: [
              { type: 'text', label: 'First name' },
              { type: 'text', label: 'Last name' },
            ],

          },
          {
            text: 'Tab 2',
            widgets: [
              { type: 'widget', html: 'Second' },
            ],
          },
        ],
      },
      panelConfig: {
        title: 'Panel',
        widgets: [
          { type: 'text', placeholder: 'Text' },
        ],
      },
      containerConfig: {
        widgets: [
          { type: 'text', label: 'Name', style: 'margin-right: .5em' },
          { type: 'number', label: 'Score', style: 'margin-right: .5em' },
          {
            type: 'button',
            text: 'Save',
            cls: 'b-raised',
            onClick(e) {
              const btn = e.source;
              const popup = btn.popup || (btn.popup = new bryntum.scheduler.Popup({
                header: 'A Popup containing Widgets',
                autoShow: false,
                centered: true,
                style: 'width: 20em',
                widgets: [
                  // a text field
                  {
                    ref: 'nameField',
                    type: 'text',
                    label: 'Enter your name',
                  },
                  // and a button
                  {
                    type: 'button',
                    cls: 'b-raised',
                    text: 'Greet',
                    onClick: () => {
                      bryntum.scheduler.Toast.show(`Hello ${popup.widgetMap.nameField.value}`);
                    },
                  },
                ],
              }));
              popup.show();
            },
          },
        ],
      },
      listConfig: {
        itemTpl: (item) => `<i>${item.text}</i>`,
        items: [{
          text: 'Add',
        }, {
          text: 'Remove',
        }],
      },
    };
  },
  components: {
    'b-field': bField,
    tabs: Tabs,
    tab: Tab,
    'edit-tool': EditTool,
    'tool-history': ToolHistory,
    'reserve-tool': ReserveTool,
  },
  created() {
    this.initTool(this.toolId);
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      tool: 'tool/tool',
    }),
  },
  methods: {
    ...mapActions({
      getTool: 'tool/getTool',
    }),
    getParentRecursive(obj) {
      return (obj.Parent ? this.getParentRecursive(obj.Parent) : '').concat((obj.Parent ? ' > ' : '') + obj.Name);
    },
    async initTool(toolId) {
      const self = this;
      try {
        blockui.blockElementPromise(self, self.$el);
        await self.getTool(toolId);
      } catch (err) {
        throw new Error(err);
      } finally {
        blockui.unBlockElement(self.$el);
      }
    },
    getArgs(args) {
      const self = this;
      if (self.isArray(args) || self.isObject(args)) {
        return [...args];
      } if (self.isFunction(args)) {
        return self[method.name](...args());
      }
      return getArgs;
    },
    isArray(obj) {
      return Array.isArray(obj) || toString.call(obj) == '[object Array]';
    },
    isObject(obj) {
      return obj === Object(obj);
    },
    isFunction(obj) {
      return toString.call(obj) == '[object Function]';
    },
    updated() {
      this.initTool(this.toolId);
    },
  },
};
