
























































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'

import { CFMDTOCountry } from '@/scripts/services/ClientApiAuto'

@Component<CountryDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
    },
    validations() {
        return {
        country:{
            Name: {required}
        },
        selectedContinent: {
            ContinentId: {required}
        },
        }
    },
})
export default class CountryDialog extends CFMDialog {
    country: CFMDTOCountry = {Name: ''};
    continentList : {Name:String, Id:Number}[] = [];
    
    selectedContinent :CFMDTOCountry = {Name: ''}

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.country.countryGetById({id: <number>this.id});
        this.country = res.data;
      }
      const promises = [
      this.getContinents()
      ];
      if(this.isUpdate){
        if(this.country && this.country.Continent){
          this.selectedContinent = this.country;
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }
// TODO continentGetContinents
    async getContinents(){
      const response = await this.apiClient.continent.continentGetContinents();
      this.continentList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Id: this.country.Id,
        Name: this.country.Name,
        ContinentId : this.selectedContinent.ContinentId,
        IsActive : true
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.country.countrySave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
