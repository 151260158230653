









































































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import { Component, Vue } from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import Summernote from '@/scripts/global/summernote'
import BootstrapSwitchComponent from "@/components/shared/BootstrapSwitchComponent";

import {required, url, minValue} from "vuelidate/lib/validators";

import { CFMDTOTraining, CFMDTOFileType, CFMDTOTrainingMaterial } from '@/scripts/services/ClientApiAuto'
import axios, { CancelTokenSource } from "axios";

interface TrainingMaterialWithFileProcesses {
  TrainingMaterial:(CFMDTOTrainingMaterial & { FileName?: string | null });
  CancelTokenSource: CancelTokenSource | null;
  FileUploadPercent: number | null;
}

@Component<TrainingDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
        'summernote' : Summernote,
        'bootstrap-switch-component': BootstrapSwitchComponent,
    },
    validations() {
        return {
          TrainingMaterialWithFileProcessesList:{
            $each: {
              TrainingMaterial: {
                Url: {
                  url,
                },
                Name:{
                  required
                }
              }
            }
          },
          training:{
              Name: {required},
              TrainingMethodId: {required},
              TrainingTypeId: {required},
              Purpose: {required},
              Duration: {required, minValue: minValue(0)},
              Code: {required},
              Content: {required}
          },
        }
    },
})
export default class TrainingDialog extends CFMDialog {
    training: CFMDTOTraining = {Name: '', Code: "", Purpose: "", Content: ""};

    trainingTypeList : {Name:string, Id:number}[] = [];
    roleList : {Name:string, Id:number}[] = [];
    trainingMethodList : {Name:String, Id:Number}[] = [];
    assessmentList : {Name:String, Id:Number}[] = [];

    fileTypeList: CFMDTOFileType[] = [];
    acceptedExtensions: string[] = [];

    TrainingMaterialWithFileProcessesList: TrainingMaterialWithFileProcesses[] = []

    maxFileSize: number = 20;

    multiSelectProps= {
        "close-on-select": false,
        "clear-on-select": false,
        "preserve-search": true,
    }

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.training.trainingGetById({id: <number>this.id});
        this.training = res.data;
      }
      const promises = [
        this.getTrainingTypes(),
        this.getRoles(),
        this.getTrainingMethods(),
        this.getAssessments(),
        this.getFileTypes()
      ];
      await Promise.all(promises);
      if (this.training.TrainingMaterials) {
        Vue.set(this, "TrainingMaterialWithFileProcessesList", 
          this.training.TrainingMaterials.map((trainingMaterial) => {
              return {
                TrainingMaterial:{
                  Id: trainingMaterial.Id,
                  FileId: trainingMaterial.FileId,
                  Url: trainingMaterial.Url,
                  Description: trainingMaterial.Description,
                  Name: trainingMaterial.Name,
                  FileName: trainingMaterial.File ? trainingMaterial.File.Name : '',
                },
                CancelTokenSource:null,
                FileUploadPercent:null
              }
            })
        );
      }
      blockui.unBlockModal();
    }

    get isUpdate() {
        return !!this.id;
    }
    get isAllUploadFinished() {
        return this.TrainingMaterialWithFileProcessesList.every(trainingMaterialWithFileProcesses => !trainingMaterialWithFileProcesses.FileUploadPercent);
    }

    async getTrainingTypes(){
      const response = await this.apiClient.trainingType.trainingTypeGetTrainingTypes();
      this.trainingTypeList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getRoles(){
      const response = await this.apiClient.role.roleGetRoles();
      this.roleList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getTrainingMethods(){
      const response = await this.apiClient.trainingMethod.trainingMethodGetTrainingMethods();
      this.trainingMethodList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getAssessments(){
      const response = await this.apiClient.assessment.assessmentGetAssessments();
      this.assessmentList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getFileTypes(){
      const response = await this.apiClient.fileType.fileTypeGetList();
      this.fileTypeList = response.data;
      this.fileTypeList.forEach((fileType) => {
        this.acceptedExtensions.push(fileType.Extension);
      });
    }
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      if (!this.isAllUploadFinished) {
          toastr.error(this.translate("please_wait_for_uploads"));
          return;
      }
      let formData:CFMDTOTraining = {
        Id: this.training.Id,
        Name: this.training.Name,
        Code: this.training.Code,
        Purpose: this.training.Purpose,
        Duration: this.training.Duration,
        IsCertificatedTraining: this.training.IsCertificatedTraining,
        Content: this.training.Content,
        RoleId: this.training.RoleId,
        TrainingTypeId: this.training.TrainingTypeId,
        TrainingMethodId: this.training.TrainingMethodId,
        TrainingAssessments: this.training.TrainingAssessments ? this.training.TrainingAssessments.map(x=>({
          AssessmentId: x.AssessmentId,
          Id: x.Id,
          TrainingId: x.TrainingId
        })) : [],
        TrainingMaterials: this.TrainingMaterialWithFileProcessesList.map(x=>({
            Id: x.TrainingMaterial.Id,
            Name: x.TrainingMaterial.Name,
            Url: x.TrainingMaterial.Url,
            Description: x.TrainingMaterial.Description,
            FileId: x.TrainingMaterial.FileId
          }))
      };
      console.log(this.training.TrainingAssessments);
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.training.trainingSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
    onFileChange (e:Event, trainingMaterial:TrainingMaterialWithFileProcesses) {
      const target = (e.target as HTMLInputElement)
      if(!target || !target.files || Array(target.files).length == 0 )
      {
        toastr.error(this.translate("file_is_not_suitable"));
        target.value = '';
        return;
      }
      const file : File = target.files[0]; 
      const fileSize = Number(((file.size / 1024) / 1024).toFixed(4));
      if(!file.name)
      { 
        toastr.error(this.translate("file_is_not_suitable"));
        target.value = '';
        return;
      }
      let extension = file.name.split('.').pop();
      extension = extension ? extension.toLowerCase(): '';
      if (typeof file.name != "undefined" && fileSize <= this.maxFileSize && this.acceptedExtensions.includes(extension)) {
        let formData = new FormData();
        formData.append("file", file);
        trainingMaterial.TrainingMaterial.FileId = null;
        let cancelTokenSource = axios.CancelToken.source();
        this.apiClient.file.fileSaveFile(<any>formData,
        {id:0},
        {
          
          onUploadProgress:(progressEvent) => {
            trainingMaterial.FileUploadPercent = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(1));
          },
          cancelToken : cancelTokenSource.token
        }).then(res => {
          if (res.data.IsSuccess) {
            console.log("file saved", res.data.Message, Number(res.data.Message), file.name);
            trainingMaterial.TrainingMaterial.FileId = Number(res.data.Message);
            trainingMaterial.TrainingMaterial.FileName = file.name;
          } else {
            toastr.warning(res.data.Message ? res.data.Message : res.statusText);
          }
          trainingMaterial.FileUploadPercent = null;
          trainingMaterial.CancelTokenSource = null;
        }).catch(reason => {
            trainingMaterial.FileUploadPercent = null;
            trainingMaterial.CancelTokenSource = null;
            toastr.warning(reason.data.Message ? reason.data.Message : reason.statusText);
        })
        trainingMaterial.CancelTokenSource = cancelTokenSource;
      } else {
        toastr.error(this.translate("file_is_not_suitable"));
        target.value = '';
      }
    }
    async downloadFile (fileId: number) {
      blockui.blockModal(this);
      const response = await this.apiClient.file.fileGetFile({id: fileId, isContainsData: true});
      if(response.data.IsSuccess && response.data.Data && response.data.Data.Base64 && response.data.Data.ContentType){
        this.downloadBlob(this.base64toBlob(response.data.Data.Base64, response.data.Data.ContentType), response.data.Data.Name);
      }
      blockui.unBlockModal();
    }
    base64toBlob (base64Data:string, type:string): Blob {
      type = type || '';
      // Convert the Base64 string back to text.
      const byteString = atob(base64Data);

      // Convert that text into a byte array.
      const ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {
        type: type
      });
    }
    downloadBlob (blob: Blob, fileName:string) {
      let a = $("<a style='display: none;'/>");
      const url = window.URL.createObjectURL(blob);
      a.attr("href", url);
      a.attr("download", fileName);
      $("body").append(a);
      a[0].click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
    addMaterial () {
      this.TrainingMaterialWithFileProcessesList.push({
        TrainingMaterial:{
          FileId: null,
          Url: "",
          Description: "",
          Name: "",
          FileName: ""
        },
        CancelTokenSource:null,
        FileUploadPercent:null,
      });
    }
    deleteMaterial (trainingMaterialWithFileProcesses: TrainingMaterialWithFileProcesses, index: number) {
      if (trainingMaterialWithFileProcesses.CancelTokenSource) {
        trainingMaterialWithFileProcesses.CancelTokenSource.cancel();
      }
      trainingMaterialWithFileProcesses.CancelTokenSource = null; 
      trainingMaterialWithFileProcesses.FileUploadPercent = null;

      this.TrainingMaterialWithFileProcessesList.splice(index, 1);
    }
}
