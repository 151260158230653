import Vue from 'vue';
import * as api from '../services/api';
import * as swal from '../../helpers/swal';
import * as blockui from '../../helpers/block-ui';
import * as datatable from '../global/datatables';
import AddSecurityItemModal from '../../components/partials/modals/SecurityItemModal';

export default {
  data() {
    return {
      selectedSecurityItem: {},
      isUpdate: false,
      itemTypeList: [],
      filterItemType: {
        Value: '',
      },
    };
  },
  components: {
    'security-item-modal': AddSecurityItemModal,
  },
  mounted() {
    const self = this; let
      table;

    api.getSecurityItemTypes().then((res) => {
      self.itemTypeList = res.body;
    });

    const columns = [{
      data: 'Id',
      title: 'ID',
      sortable: true,
      width: 40,
      defaultContent: '',
    }, {
      data: 'Name',
      title: this.translate('security_item'),
      width: 200,
      defaultContent: '',
    }, {
      data: 'Description',
      title: this.translate('description'),
      width: 200,
      defaultContent: '',
    }, {
      data: 'ItemType.Name',
      title: this.translate('security_item_type'),
      width: 200,
      defaultContent: '',
    }];

    const datatableContainer = '.security_item_list';

    Vue.getDatatables = (data) => {
      blockui.blockElement(self, '.m-portlet');
      const isDataTable = $.fn.DataTable.isDataTable(datatableContainer);
      api.getSecurityItemList(data).then((response) => {
        if (isDataTable) {
          const dt = $(datatableContainer).DataTable();
          dt.clear();
          dt.rows.add(response.body);
          dt.draw();
        } else {
          table = datatable.renderTable({
            exportFileName: self.translate('security_item'),
            container: datatableContainer,
            data: response.body,
            columns,
            paging: true,
            sortable: true,
            editButton: true,
            deleteButton: true,
          });
        }
        blockui.unBlockElement('.m-portlet');
      }, (error) => {
        blockui.unBlockElement('.m-portlet');
      });
    };

    Vue.getDatatables();

    window.$(datatableContainer).on('click', '.edit-button', function () {
      blockui.blockModal(self);
      const dt = $(datatableContainer).DataTable();
      self.selectedSecurityItem = {};
      const modalContainer = window.$('#security_item_modal');
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const id = row.data().Id;
      api.getSecurityItem(id).then((res) => {
        self.selectedSecurityItem = res.body;
        blockui.unBlockModal();
      });
      modalContainer.modal({
        backdrop: 'static',
        show: true,
      });
      modalContainer.find('form').data('type', 'update');
      self.isUpdate = true;
    });

    window.$('#add_security_item_btn').on('click', () => {
      const modalContainer = window.$('#security_item_modal');
      modalContainer.modal({
        backdrop: 'static',
        show: true,
      });
      self.selectedSecurityItem = {};
      modalContainer.find('form').data('type', '');
      self.isUpdate = false;
    });

    window.$(datatableContainer).on('click', '.delete-button', function () {
      const dt = $(datatableContainer).DataTable();
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const data = row.data();

      swal.check(self, data.Name).then((e) => {
        if (e.value) {
          api.deleteSecurityItem(data.Id).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              row.remove().draw();
            } else {
              toastr.error(response.body.Message);
            }
          });
        }
      });
    });
  },
  methods: {
    onFilter(e) {
      e.preventDefault();
      const form = $(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        $('#security_role_item_table').DataTable().destroy();
        $('#security_role_item_table').empty();
        Vue.getDatatables(formData);
      }
    },
  },
};
