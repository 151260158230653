import Vue from 'vue';
import { minValue, required } from 'vuelidate/lib/validators';
import * as api from '../../../../scripts/services/api';
import * as blockui from '../../../../helpers/block-ui';
import { mixinz } from '../../../../scripts/global/mixinz';
import Select2 from '../../../shared/select2';

export default {
  mixins: [mixinz],
  props: ['point', 'isUpdate'],
  validations() {
    return {
      point: {
        DefinitionId: {
          required,
          minValue: minValue(1),
        },
        Name: {
          required,
        },
        CloneType: {
          required,
        },
      },
    };
  },
  data() {
    return {
      rooms: [],
      devices: [],
      capacityEquipments: [],
      setUpdateData: {},
      cloneTypes: [{
        Value: 1,
        Text: 'Capacity Equipments',
      }, {
        Value: 2,
        Text: 'Room',
      }, {
        Value: 3,
        Text: 'Device',
      }],
    };
  },

  async mounted() {
    const self = this;
    const Rooms = await api.getRoomList();
    Vue.set(this, 'rooms', Rooms.body);

    const Devices = await api.getDevicesBreadCrumb({ isOnlyDevices: true });
    Vue.set(this, 'devices', Devices.body);

    const CapacityEquipmentList = await api.getCapacityEquipmentsBreadCrumb();

    Vue.set(this, 'capacityEquipments', CapacityEquipmentList.body);
    $('#point_clone_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
  },
  computed: {
    cloneToolType: {
      get() {
        const self = this;
        return self.selectGet('cloneTypes', 'point.CloneType', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('point.CloneType', 'Value', newValue);
      },
    },
    roomToolType: {
      get() {
        const self = this;
        return self.selectGet('rooms', 'point.roomId', 'Id', 'BreadCrumb');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('point.roomId', 'Id', newValue);
      },
    },
    deviceToolType: {
      get() {
        const self = this;
        return self.selectGet('devices', 'point.deviceId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('point.deviceId', 'Value', newValue);
      },
    },
    capacityEquipmentsToolType: {
      get() {
        const self = this;
        return self.selectGet('capacityEquipments', 'point.deviceId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('point.deviceId', 'Value', newValue);
      },
    },
  },
  components: { select2: Select2 },
  methods: {
    cloneTypeChanged(value) {
      // let self = this;
      // self.point.roomId = null;
      // self.point.deviceId = null;
    },
    onSubmit(e) {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const point = {
        name: this.point.Name,
        pointId: this.point.Id,
      };
      if (this.point.CloneType === 1 || this.point.CloneType === 3) {
        point.DeviceId = this.point.deviceId;
      } else if (this.point.CloneType === 2) {
        point.RoomId = this.point.roomId;
      } else {
        return;
      }

      blockui.blockModal(self);
      this.$globalApiClient.point.pointClone(point).then((response) => {
        if (response.data.IsSuccess) {
          toastr.success(self.translate('item_saved_successfully').formatString(self.point.Name));
          $('#point_clone_modal').modal('toggle');
          if (self.$route.meta.xyz) {
            // Vue.getDatatables('.point_list');
            self.$emit('pointsAdded', [point]);
            /* if (!self.isUpdate) {
                            self.$parent.slc.equipments.ChildCount = self.$parent.slc.equipments.ChildCount ? self.$parent.slc.equipments.ChildCount + 1 : 1;
                        } */
          }
        } else {
          toastr.error(response.data.Message);
        }
        blockui.unBlockModal();
      }, (error) => {
        blockui.unBlockModal();
      });
    },
  },
};
