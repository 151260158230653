










































































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import {required} from "vuelidate/lib/validators";

import {  CFMDTOCountry, CFMDTOCity } from '@/scripts/services/ClientApiAuto'

@Component<DistrictDialog>({

    components: {
        'cfm-multiselect': CFMMultiselect,
    },

    validations() {
        return {
        city:{
            Name: {required}
        },
        selectedContinent: {
            ContinentId: {required}
        },
        selectedCountry: {
            CountryId: {required}
        },
        }
    },
})
export default class DistrictDialog extends CFMDialog {
    city: CFMDTOCity = {Name: ''};
    continentList : {Name:string, Id:number}[] = [];
    countryList : {Name:string, Id:number}[] = [];

    selectedContinent :CFMDTOCountry = {Name: ''}
    selectedCountry: CFMDTOCity = {Name: ''};
    

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.city.cityGetById({id: <number>this.id});
        this.city = res.data;
      }
      const promises = [
      this.getContinents()
      ];
      if(this.isUpdate){
      if(this.city && this.city.CountryId){
         this.selectedCountry = this.city;
         if (this.city.Country && this.city.Country.ContinentId) {
           this.selectedContinent = this.city.Country;
            promises.push(this.getCountries( <number>this.selectedCountry.CountryId ));
         }
      }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }

   async getContinents(){
      const response = await this.apiClient.continent.continentGetContinents();
      this.continentList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getCountries(continentId: number){
      const response = await this.apiClient.country.countryGetCountries({continentId: continentId});
      this.countryList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    
    onContinentChanged(args: any){
      this.getCountries(args.ContinentId);
      this.selectedCountry = {Name:''};
    }
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }

      let formData = {
        Id: this.city.Id,
        Name: this.city.Name,
        CountryId: this.selectedCountry.CountryId,
        IsActive : true
      };

      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.city.citySave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
