import Vue from 'vue';

export default {
  props: {
    id: { required: true },
    name: { required: true },
    prefix: {},
    suffix: {},
    isDisabled: { default: false },
    left: { default: false },
    deletable: { default: false, type: [Boolean, Function] },
    editable: { default: false, type: [Boolean, Function] },
    keepAlive: { default: false, type: [Boolean, Function] },
  },

  data: () => ({
    isActive: false,
    isShowed: false,
    isVisible: true,
  }),

  computed: {
    header() {
      return (!this.isNullOrUndefined(this.prefix) ? (`${this.prefix} `) : '') + this.name + (!this.isNullOrUndefined(this.suffix) ? ` (${this.suffix})` : '');
    },
    computedId() {
      return this.id ? this.id : this.name.toLowerCase().replace(/ /g, '-');
    },
  },

  methods: {
    isNullOrUndefined(val) {
      return typeof (val) === 'undefined' || val === null;
    },
  },
};
