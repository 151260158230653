import * as api from './services/api';
import * as blockui from '../helpers/block-ui';

export default {
  props: ['token'],
  data() {
    return {};
  },
  mounted() {
    const self = this;
    window.$('form').validate();
  },
  methods: {
    onSubmit(e) {
      const self = this;
      e.preventDefault();
      const form = $(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.pageBlock(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        Object.assign(formData, { token: self.token });
        api.resetPassword(formData).then((res) => {
          blockui.unBlockPage();
          if (res.body.IsSuccess) {
            toastr.success(this.translate('password_changed'));
            window.location = '#/Login';
          } else {
            toastr.warning(res.body.Message);
          }
        }, (error) => {
          blockui.unBlockPage();
        });
      }
    },
  },
};
