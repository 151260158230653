import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { minValue, required, requiredIf } from 'vuelidate/lib/validators';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';
import MinutePicker from '../../components/shared/minutepicker';
import { mixinz } from '../global/mixinz';
import BootstrapSwitchComponent from '../../components/shared/BootstrapSwitchComponent';

export default {
  mixins: [mixinz],
  props: {
    workId: {
      type: [Number, String],
    },
    deviceId: {
      default: null,
      type: [Number, String],
    },
  },
  data() {
    return {
      selectedWork: { RoomId: null, DeviceId: null, Duration: null },
      formList: [],
      isDeviceBased: true,
      isShowOnCalendar: true,
      selectedDefermentCondition: false,
    };
  },
  validations() {
    return {
      selectedWork: {
        CategoryId: {
          required,
        },
        WorkGroupId: {
          required,
        },
        RoomId: {
          required: requiredIf(() => !this.isRoundSelected && !this.isDeviceBased),
        },
        DeviceId: {
          required: requiredIf(() => !this.isRoundSelected && this.isDeviceBased),
        },
        Duration: {
          required,
          minValue: minValue(1),
        },
        Name: {
          required,
        },
        PeriodId: {
          required: requiredIf(() => this.isShowPeriods),
        },
      },
    };
  },
  components: {
    minutepicker: MinutePicker,
    'bootstrap-switch-component': BootstrapSwitchComponent,
  },

  async mounted() {
    const self = this;
    await self.init(self.workId);
    blockui.blockElement(this, '#work_modal');
    if (self.isUpdate) {
      try {
        const response = await api.getWorkWithBreadCrumb(self.workId);
        if (!response.body.IsSuccess) {
          throw (new Error(response.body.Message));
        }
        Vue.set(self, 'selectedWork', response.body.Data);
        self.isDeviceBased = !!self.selectedWork.DeviceId || !!self.deviceId;
        self.isShowOnCalendar = self.selectedWork.IsShowOnCalendar;
        self.selectedDefermentCondition = !!self.selectedWork.DefermentCondition;
      } catch (e) {
        toastr.error(e.message);
      }
    } else if (self.deviceId) {
      Vue.set(self.selectedWork, 'DeviceId', self.deviceId);
      Vue.set(self.selectedWork, 'CategoryId', self.workCategory.Maintenance);
    }
    blockui.unBlockElement('#work_modal');

    // Null controls
    if (!this.selectedWork.WorkTrainings) {
      Vue.set(this.selectedWork, 'WorkTrainings', []);
    }
    if (!this.selectedWork.WorkProcedures) {
      Vue.set(this.selectedWork, 'WorkProcedures', []);
    }
    if (!this.selectedWork.WorkForms) {
      Vue.set(this.selectedWork, 'WorkForms', []);
    }
    this.selectedWork.WorkForms = this.selectedWork.WorkForms.sort((x, y) => x.Order - y.Order);

    $('#work_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
  },
  methods: {
    async init(workId) {
      blockui.blockElement(this, '#work_modal');
      const promises = [
        this.getAvailableFormList(workId),
        this.$store.dispatch('getWorkModalProcedureList'),
        this.$store.dispatch('getRoomList'),
        this.$store.dispatch('getCapacityEquipmentBreadCrumbList'),
        this.$store.dispatch('getWorkCategoryList'),
        this.$store.dispatch('getWorkGroupList'),
        this.$store.dispatch('getTrainingList'),
        this.$store.dispatch('getUserPositionList'),
        this.$store.dispatch('getMaintenancePeriodList')];
      await Promise.all(promises);
      blockui.unBlockElement('#work_modal');
    },
    async getAvailableFormList(workId) {
      try {
        const response = await api.getAvailableFormList(workId);
        this.formList = response.body;
      } catch (e) {
        toastr.error(e.message);
      }
    },
    onIsShowOnCalendarChange() {
      this.$nextTick(() => {
        Vue.loadAsteriks();
      });
    },
    onIsDeviceBasedChange() {
      this.selectedWork.DeviceId = null;
      this.selectedWork.RoomId = null;
      this.$nextTick(() => {
        Vue.loadAsteriks();
      });
    },
    onSubmit(e) {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      blockui.blockElementPromise(self, self.$el);
      const formData = { ...self.selectedWork };
      formData.WorkForms = formData.WorkForms && formData.WorkForms.map((workForm) => ({
        FormId: workForm.BaseFormId,
        Order: workForm.Order,
      }));
      formData.WorkTrainings = formData.WorkTrainings.map((workTraining) => ({
        TrainingId: workTraining.TrainingId,
        UserPositionId: workTraining.UserPositionId,
      }));
      formData.WorkProcedures = formData.WorkProcedures && formData.WorkProcedures.map((workProcedure) => ({
        ProcedureId: workProcedure.ProcedureId,
      }));

      formData.IsShowOnCalendar = self.isShowOnCalendar;

      if (!self.selectedDefermentCondition) {
        delete formData.DefermentCondition;
      }

      if (self.isDeviceBased) {
        formData.RoomId = null;
      } else {
        formData.DeviceId = null;
      }

      api.saveWork(formData).then((response) => {
        if (response.body.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
          if (self.$route.meta.contract) {
            const savedWorkIdKeyValue = response.body.Messages.find((x) => x.Key == 'Id');
            if (savedWorkIdKeyValue) {
              self.$eventHub.$emit('newWorkAddedToDevice', {
                DeviceId: self.selectedWork.DeviceId,
                WorkId: savedWorkIdKeyValue.Value,
              });
            }
          } else if (self.deviceId) {
            self.$emit('saved', self.deviceId);
            Vue.getDatatables('.work_list');
          } else {
            self.reloadDataTable();
          }
          $('#work_modal').modal('toggle');
        } else {
          toastr.error(response.body.Message);
        }
        blockui.unBlockElement(self.$el);
      }, (error) => {
        blockui.unBlockPage(self.$el);
      });
    },
    reloadDataTable() {
      if (this.$route.meta.xyz) {
        const self = this;
        self.$store.dispatch('getCapacityEquipmentOverlayWorkList');
        self.$parent.loadEquipmentWorksWithLastSelectedParams();
        if (!self.isUpdate) {
          self.$parent.slc.equipments.WorkCount = self.$parent.slc.equipments.WorkCount ? self.$parent.slc.equipments.WorkCount + 1 : 1;
        }
      } else {
        const filterForm = $("form[name='filterForm']");
        if (filterForm.valid()) {
          const formData = {};
          $.map(filterForm.serializeArray(), (item) => {
            Object.assign(formData, { [item.name]: item.value });
          });
          Vue.getDatatables(formData);
        }
      }
    },
    addProcedure() {
      this.$emit('openProcedure');
    },
    selectedWorkCategoryChanged(workCategory) {
      if (this.isRoundSelected) {
        this.selectedWork.DeviceId = null;
        this.selectedWork.RoomId = null;
      }
    },
    selectForm(form) {
      if (!this.selectedWork.WorkForms) {
        Vue.set(this.selectedWork, 'WorkForms', []);
      }
      const maxOrder = this.selectedWork.WorkForms.reduce((max, current) => (max > current.Order ? max : current.Order), 0);
      this.selectedWork.WorkForms.push({
        ...form,
        Order: maxOrder + 1,
      });
    },
    removeForm(form) {
      const formIndex = this.selectedWork.WorkForms.findIndex((workForm) => workForm.BaseFormId == form.BaseFormId);
      this.selectedWork.WorkForms.splice(formIndex, 1);
      let index = 0;
      for (index = formIndex; index < this.selectedWork.WorkForms.length; index++) {
        this.selectedWork.WorkForms[index].Order--;
      }
    },
    updateOrder({ oldIndex, newIndex }) {
      this.selectedWork.WorkForms.forEach((WorkForm, index) => {
        WorkForm.Order = index + 1;
      });
    },
    selectWorkTraining(workTraining, userPositionId) {
      if (!this.selectedWork.WorkTrainings) {
        Vue.set(this.selectedWork, 'WorkTrainings', []);
      }
      this.selectedWork.WorkTrainings.push({
        UserPositionId: userPositionId,
        Name: workTraining.Text,
        TrainingId: workTraining.Value,
      });
    },
    removeWorkTraining(removedWorkTraining, userPositionId) {
      const formIndex = this.selectedWork.WorkTrainings.findIndex((workTraining) => workTraining.UserPositionId == userPositionId && workTraining.TrainingId == removedWorkTraining.Value);
      this.selectedWork.WorkTrainings.splice(formIndex, 1);
    },

  },
  computed: {
    ...mapState({
      workCategoryList: (state) => state.work.workCategoryList,
      workGroupList: (state) => state.work.workGroupList,
      trainingList: (state) => state.training.trainingList,
      positionList: (state) => state.user.positionList,
      workCategory: (state) => state.enums.workCategory,
      userPosition: (state) => state.enums.userPosition,
      maintenancePeriodList: (state) => state.maintenance.maintenancePeriodList,
    }),
    ...mapGetters({
      capacityEquipmentList: 'getCapacityEquipmentBreadCrumbList',
      roomList: 'getRoomList',
      workModalProcedureList: 'getWorkModalProcedureList',
    }),
    isUpdate() {
      return this.workId > 0;
    },
    isShowPeriods() {
      return this.isDeviceBased && this.selectedWork && (this.selectedWork.CategoryId == this.workCategory.Maintenance);
    },
    isRoundSelected() {
      return this.selectedWork && (this.selectedWork.CategoryId == this.workCategory.Round);
    },
    selectedRoom: {
      get() {
        return this.selectGet('roomList', 'selectedWork.RoomId', 'Id', 'Name');
      },
      set(newValue) {
        return this.selectSet('selectedWork.RoomId', 'Id', newValue);
      },
    },
    selectedDevice: {
      get() {
        return this.selectGet('capacityEquipmentList', 'selectedWork.DeviceId', 'Id', 'Name');
      },
      set(newValue) {
        return this.selectSet('selectedWork.DeviceId', 'Id', newValue);
      },
    },
    selectedWorkGroup: {
      get() {
        return this.selectGet('workGroupList', 'selectedWork.WorkGroupId', 'Value', 'Text');
      },
      set(newValue) {
        return this.selectSet('selectedWork.WorkGroupId', 'Value', newValue);
      },
    },
    selectedWorkCategory: {
      get() {
        return this.selectGet('workCategoryList', 'selectedWork.CategoryId', 'Value', 'Text');
      },
      set(newValue) {
        return this.selectSet('selectedWork.CategoryId', 'Value', newValue);
      },
    },
    selectedMaintenancePeriod: {
      get() {
        return this.selectGet('maintenancePeriodList', 'selectedWork.PeriodId', 'Value', 'Text');
      },
      set(newValue) {
        return this.selectSet('selectedWork.PeriodId', 'Value', newValue);
      },
    },
    selectedWorkProcedures: {
      get() {
        return this.selectMultipleGet('workModalProcedureList', 'selectedWork.WorkProcedures', 'ProcedureId', 'ProcedureId');
      },
      set(newValue) {
        return this.selectMultipleSet('selectedWork.WorkProcedures', 'ProcedureId', newValue, 'ProcedureId');
      },
    },
    selectedWorkForm: {
      get() {
        return this.selectMultipleGet('formList', 'selectedWork.WorkForms', 'BaseFormId', 'BaseFormId');
      },
    },
    selectedTrainings: {
      get() {
        return this.positionList.map((userPosition) => ({
          userPositionId: userPosition.Value,
          userPositionName: userPosition.Text,
          trainings: this.selectedWork && this.selectedWork.WorkTrainings ? this.selectedWork.WorkTrainings.filter((workTraining) => workTraining.UserPositionId == userPosition.Value).map((workTraining) => ({
            Value: workTraining.TrainingId,
            Text: workTraining.Name,
          })) : [],
        }));
      },
    },
  },
  // TODO: Check it
  watch: {
    work(e) {
      this.selectedWork = e;
    },
  },
};
