import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import * as datatable from '../global/datatables';
import * as blockui from '../../helpers/block-ui';
import * as swal from '../../helpers/swal';

export default {
  data() {
    return {
      yearList: [],
      thisYear: new Date().getFullYear(),
      // netAvailableWorkHours: [],
      averageTurnOvers: {},
    };
  },
  mounted() {
    const self = this; let
      table;
    // TODO:Eren Türkçeleri yanlış çevirilmiş.
    const columns = [{
      data: 'Id',
      title: 'ID',
      sortable: 'desc',
      width: 30,
      defaultContent: '',
    }, {
      data: 'Year',
      title: self.translate('year'),
      sortable: 'desc',
      defaultContent: '',
    }, {
      data: 'HighestEmployeeCount',
      title: self.translate('second_section_first_element'),
      sortable: 'desc',
      defaultContent: '',
    }, {
      data: 'FailedToCompleteProbationaryPeriodCount',
      title: self.translate('second_section_second_element'),
      sortable: 'desc',
      defaultContent: '',
    }, {
      data: 'LeftExperiencedEmployeeCount',
      title: self.translate('second_section_third_element'),
      sortable: 'desc',
      defaultContent: '',
    }, {
      data: 'TurnOverRate',
      title: self.translate('turnover_rate'),
      sortable: 'desc',
      defaultContent: '',
    }, {
      data: 'RetentionRate',
      title: self.translate('retention_rate'),
      sortable: 'desc',
      defaultContent: '',
    }];

    const datatableContainer = '.turnover_rate_table';

    Vue.getDatatables = async (data) => {
      blockui.blockElement(self, '.m-portlet');
      const isDataTable = $.fn.DataTable.isDataTable(datatableContainer);
      await self.$store.dispatch('getTurnOvers', data);
      if (isDataTable) {
        const dt = $(datatableContainer).DataTable();
        dt.clear();
        dt.rows.add(self.turnOvers);
        dt.draw();
      } else {
        table = datatable.renderTable({
          exportFileName: self.translate('turnover_rate'),
          container: datatableContainer,
          data: self.turnOvers,
          columns,
          paging: true,
          sortable: true,
          deleteButton: true,
          footerCallback(tfoot, data, start, end, display) {
            const api = this.api(); let total; let i; let
              count;
            for (i = 2; i <= 6; i++) {
              total = api
                .column(i)
                .data()
                .reduce((a, b) => a + b, 0);
              count = api
                .column(i)
                .data().length;
              $(api.column(i).footer()).html((total / count).toFixed(2));
            }
          },
        });
      }
      blockui.unBlockElement('.m-portlet');
    };

    self.$store.dispatch('getNetAvailableWorkHours');
    self.$store.dispatch('getAverageTurnOver');
    self.$store.dispatch('getCoveragePosition');
    self.$store.dispatch('getHourlyProcessingCapability');
    self.$store.dispatch('getVolumeInfluencedPosition');

    Vue.getDatatables();

    for (let i = this.thisYear; i > this.thisYear - 15; i--) {
      this.yearList.push({ Value: i, Text: i });
    }

    $('.turnover_rate_table').on('click', '.delete-button', function () {
      const dt = $(this).closest('table').DataTable();
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const data = row.data();
      swal.check(self, data.Year).then((e) => {
        if (e.value) {
          api.deleteTurnOver(data.Id).then((response) => {
            if (response.ok) {
              toastr.success(self.translate('delete_successfully'));
              self.$store.dispatch('getAverageTurnOver');
              row.remove().draw();
            } else {
              toastr.error(response.statusText);
            }
          });
        }
      });
    });

    /* $(document).on('keyup mouseup', turnover_rate_form, function () {
      let inputA = $(turnover_rate_form).find(".in-a").val();
      let inputB = $(turnover_rate_form).find(".in-b").val();
      let inputC = $(turnover_rate_form).find(".in-c").val();
      inputA = parseInt(inputA);
      inputB = parseInt(inputB);
      inputC = parseInt(inputC);

      const turnoverRate = (inputB + inputC) / inputA;
      const retentionRate = (1 - turnoverRate);

      const turnoverResult = parseFloat(turnoverRate).toFixed(2) * 100;
      const retentionResult = parseFloat(retentionRate).toFixed(2) * 100;

      if (inputA && inputB && inputC) {
        $("#turnover_rate span").html(turnoverResult);
        $("#retention_rate span").html(retentionResult);
      }
    }); */

    /* const work_hours_form_inputs = '#work_hours input';
    $(document).on('keyup', work_hours_form_inputs, function () {
      let totalHours = 0, unavailableTotal = 0;
      $.each($(work_hours_form_inputs), function (index, input) {
        const value = parseInt($(input).val());
        if (index === 0) {
          totalHours = value;
        } else if (index > 0) {
          unavailableTotal += value;
        }
      });
      $(this).closest("form").find("#total_unavailable_hours").html(unavailableTotal);
      $(this).closest("form").find("#total_work_hours").html(totalHours - unavailableTotal);
    }); */

    /* const coverage_form_inputs = '#coverage_position input';
     $(document).on('keyup', coverage_form_inputs, function () {
       let total = 1;
       $.each($(coverage_form_inputs), function (index, input) {
         const value = parseInt($(input).val());
         if (value) {
           total *= value;
         }
       });
       $(this).closest("form").find("#total_hours").html(total);
     });

     const average_form_inputs = '#processing_capability input';
     $(document).on('keyup', average_form_inputs, function () {
       let total = 1;
       $.each($(average_form_inputs), function (index, input) {
         const value = parseInt($(input).val());
         if (value) {
           total *= value;
         }
       });
       $(this).closest("form").find("#total_average_time").html(total);
       $(this).closest("form").find("#average_hourly").html(60 / total);
     }); */

    $('.nav-link').on('shown.bs.tab', (e) => {
      $($.fn.dataTable.tables(true)).DataTable()
        .columns.adjust()
        .responsive.recalc();
    });
  },
  methods: {
    workHours(e) {
      e.preventDefault();
      const self = this;
      const form = window.$(e.target);

      form.validate();
      $(this.$refs.totalHoursForOneFullTimeEmployee).rules('add', {
        min: this.minTotalHoursForOneFullTimeEmployee,
      });
      // form.validate().destroy();
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        blockui.pageBlock();
        api.saveNetAvailableWorkHours(formData).then((res) => {
          if (res.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            self.$store.dispatch('getNetAvailableWorkHours');
          } else {
            toastr.error(res.body.Message);
          }
          blockui.unBlockPage();
        }, (error) => {
          blockui.unBlockPage();
        });
      }
    },
    turnoverRate(e) {
      e.preventDefault();
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        blockui.pageBlock();
        api.saveTurnOverRate(formData).then((res) => {
          if (res.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            Vue.getDatatables();
            self.$store.dispatch('getAverageTurnOver');
          } else {
            toastr.error(res.body.Message);
          }
          blockui.unBlockPage();
        }, (error) => {
          blockui.unBlockPage();
        });
      }
    },
    coveragePositions(e) {
      e.preventDefault();
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        blockui.pageBlock();
        api.saveCoveragePosition(formData).then((res) => {
          if (res.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
          } else {
            toastr.error(res.body.Message);
          }
          blockui.unBlockPage();
        }, (error) => {
          blockui.unBlockPage();
        });
      }
    },
    processingCapability(e) {
      e.preventDefault();
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        blockui.pageBlock();
        api.saveHourlyProcessingCapability(formData).then((res) => {
          if (res.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
          } else {
            toastr.error(res.body.Message);
          }
          blockui.unBlockPage();
        }, (error) => {
          blockui.unBlockPage();
        });
      }
    },
    volumeInfluenced(e) {
      e.preventDefault();
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        blockui.pageBlock();
        api.saveVolumeInfluencedPosition(formData).then((res) => {
          if (res.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
          } else {
            toastr.error(res.body.Message);
          }
          blockui.unBlockPage();
        }, (error) => {
          blockui.unBlockPage();
        });
      }
    },
    getNumeric(number) {
      const parsed = Number(number);
      if (Number.isNaN(parsed)) {
        return 0;
      }
      return parsed;
    },
  },
  computed: {
    ...mapState({
      netAvailableWorkHours: (state) => state.minimum_staff.netAvailableWorkHours,
      turnOvers: (state) => state.minimum_staff.turnOvers,
      averageTurnOver: (state) => state.minimum_staff.averageTurnOver,
      coveragePosition: (state) => state.minimum_staff.coveragePosition,
      hourlyProcessingCapability: (state) => state.minimum_staff.hourlyProcessingCapability,
      volumeInfluencedPosition: (state) => state.minimum_staff.volumeInfluencedPosition,
    }),
    calculatedTotalUnavailableTime() {
      return this.getNumeric(this.netAvailableWorkHours.AverageVacaionAndHolidayLeave)
        + this.getNumeric(this.netAvailableWorkHours.AverageSickLeave)
        + this.getNumeric(this.netAvailableWorkHours.AveragePersonelLeave)
        + this.getNumeric(this.netAvailableWorkHours.AverageTrainingLeave)
        + this.getNumeric(this.netAvailableWorkHours.AverageMilitaryLeave)
        + this.getNumeric(this.netAvailableWorkHours.AverageLunchAndBreak)
        + this.getNumeric(this.netAvailableWorkHours.AverageOther);
    },
    calculatedNetAvailableWorkHours() {
      return this.getNumeric(this.netAvailableWorkHours.TotalHoursForOneFullTimeEmployee)
        - this.calculatedTotalUnavailableTime;
    },
    turnOverRate() {
      const self = this;
      return (((self.getNumeric(self.averageTurnOvers.FailedToCompleteProbationaryPeriodCount)
        + self.getNumeric(self.averageTurnOvers.LeftExperiencedEmployeeCount))
        / self.getNumeric(self.averageTurnOvers.HighestEmployeeCount)) * 100);
    },
    retentionRate() {
      const self = this;
      return ((1 - ((self.getNumeric(self.averageTurnOvers.FailedToCompleteProbationaryPeriodCount)
        + self.getNumeric(self.averageTurnOvers.LeftExperiencedEmployeeCount))
        / self.getNumeric(self.averageTurnOvers.HighestEmployeeCount))) * 100);
    },
    totalHoursNeedingCoverage() {
      const self = this;
      return self.getNumeric(self.coveragePosition.ConsoleCount)
        * self.getNumeric(self.coveragePosition.NumberOfHoursPerDay)
        * self.getNumeric(self.coveragePosition.NumberOfDaysPerWeek)
        * self.getNumeric(self.coveragePosition.NumberOfWeeksPerYear);
    },
    fullTimeEquivalent() {
      const self = this;
      return self.totalHoursNeedingCoverage
        / self.netAvailableWorkHours.NetAvailableWorkHours;
    },
    fullTimeEquivalentPlus() {
      const self = this;
      return self.fullTimeEquivalent * (1 + self.averageTurnOver);
    },
    averageProcessingTime() {
      const self = this;
      return self.getNumeric(self.hourlyProcessingCapability.AverageMaintenanceDuration)
        + self.getNumeric(self.hourlyProcessingCapability.AverageMaintenanceCompletionTime);
    },
    averageHourlyProcessingCapability() {
      const self = this;
      return (60 / self.averageProcessingTime);
    },
    volumeInfluencedPositionHourlyProcessingCapability() {
      const self = this;
      return (60 / self.getNumeric(self.volumeInfluencedPosition.AverageProcessingTime));
    },
    workloadInHours() {
      const self = this;
      return self.getNumeric(self.volumeInfluencedPosition.TotalMaintenanceCount)
        / self.volumeInfluencedPositionHourlyProcessingCapability;
    },
    agentOccupancyRate() {
      const self = this;
      return self.getNumeric(self.netAvailableWorkHours.NetAvailableWorkHours) / 24;
    },
    availabilityPerPerson() {
      const self = this;
      return self.getNumeric(self.netAvailableWorkHours.NetAvailableWorkHours)
        * self.agentOccupancyRate / 100;
    },
    volumeInfluencedFullTimeEquivalent() {
      const self = this;
      return self.workloadInHours
        / self.availabilityPerPerson;
    },
    volumeInfluencedFullTimeEquivalentPlus() {
      const self = this;
      return self.volumeInfluencedFullTimeEquivalent * (1 + self.averageTurnOver);
    },
    minTotalHoursForOneFullTimeEmployee() {
      return this.getNumeric(this.netAvailableWorkHours.AverageVacaionAndHolidayLeave)
        + this.getNumeric(this.netAvailableWorkHours.AverageSickLeave)
        + this.getNumeric(this.netAvailableWorkHours.AveragePersonelLeave)
        + this.getNumeric(this.netAvailableWorkHours.AverageTrainingLeave)
        + this.getNumeric(this.netAvailableWorkHours.AverageMilitaryLeave)
        + this.getNumeric(this.netAvailableWorkHours.AverageLunchAndBreak)
        + this.getNumeric(this.netAvailableWorkHours.AverageOther);
    },
  },
};
