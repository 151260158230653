<template>
    <div class="helper-footer-bar">
        <div class="helper-header" style="width: 100%;">
            <date-range-picker
                    v-if="filterType==1"
                    :opens="opens"
                    :startDate="startDate"
                    :endDate="endDate"
                    :presetId="presetId"
                    @update="updateValues"
                    :ranges="ranges"
                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY', applyLabel: translate('apply'), cancelLabel: translate('cancel'), customRangeLabel: translate('custom_range'), daysOfWeek: daysOfWeek, monthNames: monthNames }">
                <div slot="input" slot-scope="picker">
                    {{ startDate | date }} - {{ endDate | date }}
                </div>
            </date-range-picker>
            <!--<input v-else-if="filterType==2" v-model="value" @change="updateValues({value:$event.target.value})" type="number" step="1">-->
            <div v-else-if="filterType==2">
                <number-input v-model="value" inline controls @change="updateValues({value:$event})" :center="true"
                              :min="0" :max="100" rounded
                              size="small"></number-input>
            </div>
            <div v-else-if="filterType==3" style="display: flex;width: 100%">
                <span class="sq pull-left" @click="addWeek(-1)">
                    <i class="fa fa-chevron-left"></i>
                </span>
                <div style="width: 100%;text-align: center;">{{ endDate| year}} {{translate('week')}}: #
                    <click-and-edit :size="2" :maxlength="2" :value.sync="weekOfYear"
                                    :regEx="onlyNumberRegEx"></click-and-edit>
                </div>
                <span class="sq pull-right" @click="addWeek(1)">
                    <i class="fa fa-chevron-right"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-relative-daterange-picker';
import moment from 'moment';
import * as dateFormat from '../../../helpers/dateFormat';
import clickAndEdit from '../../shared/clickAndEdit';

export default {
  props: ['filterType', 'filterParameters', 'ranges'],
  components: { DateRangePicker, clickAndEdit },
  filters: {
    date(value) {
      return dateFormat.viewDateFormat(value);
    },
    year(value) {
      return moment(value).year();
    },
  },
  data() {
    moment.locale(localStorage.getItem('vue-lang'));
    const today = new Date();
    return {
      opens: 'center',
      startDate: moment().startOf('week').toDate(),
      endDate: moment().endOf('week').toDate(),
      presetId: null,
      minDate: today,
      maxDate: today,
      value: '',
      onlyNumberRegEx: '[+-]?([0-9]*[.])?[0-9]+',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
    };
  },
  methods: {
    updateValues(values) {
      this.presetId = values.presetId;
      this.startDate = values.startDate;
      this.endDate = values.endDate;
      this.value = values.value;
      this.$emit('filterParametersUpdated', values);
    },
    addWeek(n) {
      this.updateValues({
        startDate: moment(this.startDate).add(n, 'week').toDate(),
        endDate: moment(this.endDate).add(n, 'week').toDate(),
      });
    },
  },
  computed: {
    weekOfYear: {
      get() {
        return moment(this.startDate).week();
      },
      set(newVal) {
        const weekDay = moment().year(moment(this.startDate).year()).startOf('year').week(newVal);
        this.updateValues({
          startDate: weekDay.startOf('week').toDate(),
          endDate: weekDay.endOf('week').toDate(),
        });
        return moment(this.startDate).week();
      },
    },
  },
  created() {
    moment.locale(localStorage.getItem('vue-lang'));
    const self = this;
    self.startDate = self.filterParameters.startDate;
    self.endDate = self.filterParameters.endDate;
    self.presetId = self.filterParameters.presetId;
    self.value = self.filterParameters.value;
  },
  mounted() {
  },
  watch: {
    filterParameters(newVal) {
      const self = this;
      self.startDate = newVal.startDate;
      self.endDate = newVal.endDate;
      self.presetId = newVal.presetId;
      self.value = newVal.value;
    },
  },
};
</script>

<style lang="scss">
    .helper-footer-bar {
        .active-range {
            border: 1px solid !important;
            background-color: #357ebd !important;
            color: #ffffff !important;

            &:hover {
                color: #000000 !important;
                border: 1px solid transparent !important;
                background-color: #ffffff !important;
            }
        }

        .daterangepicker {
            position: absolute;
            color: inherit;
            background-color: #fff;
            border-radius: 4px;
            border: 1px solid #ddd;
            width: 278px;
            max-width: none;
            padding: 0;
            margin-top: 7px;
            top: 100px;
            left: 20px;
            z-index: 3001;
            display: none;
            font-size: 15px;
            line-height: 1em;
        }

        .daterangepicker:before, .daterangepicker:after {
            position: absolute;
            display: inline-block;
            border-bottom-color: rgba(0, 0, 0, 0.2);
            content: '';
        }

        .daterangepicker:before {
            top: -7px;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            border-bottom: 7px solid #ccc;
        }

        .daterangepicker:after {
            top: -6px;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #fff;
            border-left: 6px solid transparent;
        }

        .daterangepicker.opensleft:before {
            right: 9px;
        }

        .daterangepicker.opensleft:after {
            right: 10px;
        }

        .daterangepicker.openscenter:before {
            left: 0;
            right: 0;
            width: 0;
            margin-left: auto;
            margin-right: auto;
        }

        .daterangepicker.openscenter:after {
            left: 0;
            right: 0;
            width: 0;
            margin-left: auto;
            margin-right: auto;
        }

        .daterangepicker.opensright:before {
            left: 9px;
        }

        .daterangepicker.opensright:after {
            left: 10px;
        }

        .daterangepicker.drop-up {
            margin-top: -7px;
        }

        .daterangepicker.drop-up:before {
            top: initial;
            bottom: -7px;
            border-bottom: initial;
            border-top: 7px solid #ccc;
        }

        .daterangepicker.drop-up:after {
            top: initial;
            bottom: -6px;
            border-bottom: initial;
            border-top: 6px solid #fff;
        }

        .daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
            float: none;
        }

        .daterangepicker.single .drp-selected {
            display: none;
        }

        .daterangepicker.show-calendar .drp-calendar {
            display: block;
        }

        .daterangepicker.show-calendar .drp-buttons {
            display: block;
        }

        .daterangepicker.auto-apply .drp-buttons {
            display: none;
        }

        .daterangepicker .drp-calendar {
            display: none;
            max-width: 270px;
        }

        .daterangepicker .drp-calendar.left {
            padding: 8px 0 8px 8px;
        }

        .daterangepicker .drp-calendar.right {
            padding: 8px;
        }

        .daterangepicker .drp-calendar.single .calendar-table {
            border: none;
        }

        .daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
            color: #fff;
            border: solid black;
            border-width: 0 2px 2px 0;
            border-radius: 0;
            display: inline-block;
            padding: 3px;
        }

        .daterangepicker .calendar-table .next span {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        .daterangepicker .calendar-table .prev span {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }

        .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
            white-space: nowrap;
            text-align: center;
            vertical-align: middle;
            min-width: 32px;
            width: 32px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            border-radius: 4px;
            border: 1px solid transparent;
            white-space: nowrap;
            cursor: pointer;
        }

        .daterangepicker .calendar-table {
            border: 1px solid #fff;
            border-radius: 4px;
            background-color: #fff;
        }

        .daterangepicker .calendar-table table {
            width: 100%;
            margin: 0;
            border-spacing: 0;
            border-collapse: collapse;
        }

        .daterangepicker td.available:hover, .daterangepicker th.available:hover {
            background-color: #eee;
            border-color: transparent;
            color: inherit;
        }

        .daterangepicker td.week, .daterangepicker th.week {
            font-size: 80%;
            color: #ccc;
        }

        .daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
            background-color: #fff;
            border-color: transparent;
            color: #999;
        }

        .daterangepicker td.in-range {
            background-color: #ebf4f8;
            border-color: transparent;
            color: #000;
            border-radius: 0;
        }

        .daterangepicker td.start-date {
            border-radius: 4px 0 0 4px;
        }

        .daterangepicker td.end-date {
            border-radius: 0 4px 4px 0;
        }

        .daterangepicker td.start-date.end-date {
            border-radius: 4px;
        }

        .daterangepicker td.active, .daterangepicker td.active:hover {
            background-color: #357ebd;
            border-color: transparent;
            color: #fff;
        }

        .daterangepicker th.month {
            width: auto;
        }

        .daterangepicker td.disabled, .daterangepicker option.disabled {
            color: #999;
            cursor: not-allowed;
            text-decoration: line-through;
        }

        .daterangepicker select.monthselect, .daterangepicker select.yearselect {
            font-size: 12px;
            padding: 1px;
            height: auto;
            margin: 0;
            cursor: default;
        }

        .daterangepicker select.monthselect {
            margin-right: 2%;
            width: 56%;
        }

        .daterangepicker select.yearselect {
            width: 40%;
        }

        .daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
            width: 50px;
            margin: 0 auto;
            background: #eee;
            border: 1px solid #eee;
            padding: 2px;
            outline: 0;
            font-size: 12px;
        }

        .daterangepicker .calendar-time {
            text-align: center;
            margin: 4px auto 0 auto;
            line-height: 30px;
            position: relative;
        }

        .daterangepicker .calendar-time select.disabled {
            color: #ccc;
            cursor: not-allowed;
        }

        .daterangepicker .drp-buttons {
            clear: both;
            text-align: right;
            padding: 8px;
            border-top: 1px solid #ddd;
            display: none;
            line-height: 12px;
            vertical-align: middle;
        }

        .daterangepicker .drp-selected {
            display: inline-block;
            font-size: 12px;
            padding-right: 8px;
        }

        .daterangepicker .drp-buttons .btn {
            margin-left: 8px;
            font-size: 12px;
            font-weight: bold;
            padding: 4px 8px;
        }

        .daterangepicker.show-ranges .drp-calendar.left {
            border-left: 1px solid #ddd;
        }

        .daterangepicker .ranges {
            float: none;
            text-align: left;
            margin: 0;
        }

        .daterangepicker.show-calendar .ranges {
            margin-top: 8px;
        }

        .daterangepicker .ranges ul {
            list-style: none;
            margin: 0 auto;
            padding: 0;
            width: 100%;
        }

        .daterangepicker .ranges li {
            font-size: 12px;
            padding: 8px 12px;
            cursor: pointer;
        }

        .daterangepicker .ranges li:hover {
            background-color: #eee;
        }

        .daterangepicker .ranges li.active {
            background-color: #08c;
            color: #fff;
        }

        /*  Larger Screen Styling */
        @media (min-width: 564px) {
            .daterangepicker {
                width: auto;
            }
            .daterangepicker .ranges ul {
                width: 140px;
            }
            .daterangepicker.single .ranges ul {
                width: 100%;
            }
            .daterangepicker.single .drp-calendar.left {
                clear: none;
            }
            .daterangepicker.single.ltr .ranges, .daterangepicker.single.ltr .drp-calendar {
                float: left;
            }
            .daterangepicker.single.rtl .ranges, .daterangepicker.single.rtl .drp-calendar {
                float: right;
            }
            .daterangepicker.ltr {
                direction: ltr;
                text-align: left;
            }
            .daterangepicker.ltr .drp-calendar.left {
                clear: left;
                margin-right: 0;
            }
            .daterangepicker.ltr .drp-calendar.left .calendar-table {
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            .daterangepicker.ltr .drp-calendar.right {
                margin-left: 0;
            }
            .daterangepicker.ltr .drp-calendar.right .calendar-table {
                border-left: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            .daterangepicker.ltr .drp-calendar.left .calendar-table {
                padding-right: 8px;
            }
            .daterangepicker.ltr .ranges, .daterangepicker.ltr .drp-calendar {
                float: left;
            }
            .daterangepicker.rtl {
                direction: rtl;
                text-align: right;
            }
            .daterangepicker.rtl .drp-calendar.left {
                clear: right;
                margin-left: 0;
            }
            .daterangepicker.rtl .drp-calendar.left .calendar-table {
                border-left: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            .daterangepicker.rtl .drp-calendar.right {
                margin-right: 0;
            }
            .daterangepicker.rtl .drp-calendar.right .calendar-table {
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            .daterangepicker.rtl .drp-calendar.left .calendar-table {
                padding-left: 12px;
            }
            .daterangepicker.rtl .ranges, .daterangepicker.rtl .drp-calendar {
                text-align: right;
                float: right;
            }
        }

        @media (min-width: 730px) {
            .daterangepicker .ranges {
                width: auto;
            }
            .daterangepicker.ltr .ranges {
                float: left;
            }
            .daterangepicker.rtl .ranges {
                float: right;
            }
            .daterangepicker .drp-calendar.left {
                clear: none !important;
            }
        }

        .reportrange-text {
            background: #fff;
            cursor: pointer;
            padding: 5px 10px;
            border: 1px solid #ccc;
            width: 100%;
        }

        .daterangepicker {
            flex-direction: column;
            display: flex;
            width: auto;

            &.show-calendar {
                display: block;
            }
        }

        .calendars {
            display: flex;
        }

        div.daterangepicker.opensleft {
            top: 35px;
            right: 10px;
            left: auto;
        }

        div.daterangepicker.openscenter {
            top: 35px;
            right: auto;
            left: 50%;
            transform: translate(-50%, 0);
        }

        div.daterangepicker.opensright {
            top: 35px;
            left: 10px;
            right: auto;
        }

        /* Enter and leave animations can use different */
        /* durations and timing functions.              */
        .slide-fade-enter-active {
            transition: all .2s ease;
        }

        .slide-fade-leave-active {
            transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        }

        .slide-fade-enter, .slide-fade-leave-to
            /* .slide-fade-leave-active for <2.1.8 */
        {
            transform: translateX(10px);
            opacity: 0;
        }

        td.today {
            font-weight: bold;
        }

        td.disabled {
            pointer-events: none;
            background-color: #eee;
            border-radius: 0;
            opacity: 0.6;
        }

        @function str-replace($string, $search, $replace: "") {
            $index: str-index($string, $search);

            @if $index {
                @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
            }

            @return $string;
        }

        $carousel-control-color: #ccc !default;
        $viewbox: '-2 -2 10 10';
        $carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='#{$viewbox}'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
        $carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='#{$viewbox}'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

        .fa {
            display: inline-block;
            width: 100%;
            height: 100%;
            background: transparent no-repeat center center;
            background-size: 100% 100%;
            fill: $carousel-control-color;
        }

        .prev, .next {
            &:hover {
                background-color: transparent !important;
            }

            .fa:hover {
                opacity: 0.6;
            }
        }

        .chevron-right:before {
            content: '\f054';
            font-family: 'Font Awesome\ 5 Free';
        }

        .chevron-left:before {
            content: "\f053";
            font-family: 'Font Awesome\ 5 Free';
        }
    }

</style>
