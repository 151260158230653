import Vue from 'vue';
import { required, minValue } from 'vuelidate/lib/validators';
import * as api from '../../../../scripts/services/api';
import * as blockui from '../../../../helpers/block-ui';
import { mixinz } from '../../../../scripts/global/mixinz';
import MinutePicker from '../../../shared/minutepicker';
import * as dateFormat from '../../../../helpers/dateFormat';

export default {
  mixins: [mixinz],
  props: ['capacityEquipmentList', 'isUpdate', 'preventiveMaintenance'],
  data() {
    return {
      capacityEquipments: [],
      checkedMaintenanceRadio: false,
      works: [],
      maintenancePeriods: [],
      selectedPreventiveMaintenance: {
      },
    };
  },
  validations() {
    return {
      selectedPreventiveMaintenance: {
        Name: {
          required,
        },
        DeviceId: {
          required,
        },
        PeriodId: {
          required,
        },
        ReferenceTypeId: {
          required,
        },
        ReferenceDate: {
          required,
        },
        RemainedCount: {
          required,
          minValue: minValue(0),
        },
        TotalCount: {
          required,
          minValue: minValue(0),
        },
        Tolerance: {
          required,
          minValue: minValue(0),
        },
      },
    };
  },
  components: {
    minutepicker: MinutePicker,
  },
  computed: {
    selectedPeriod: {
      get() {
        const self = this;
        return self.selectGet('maintenancePeriods', 'selectedPreventiveMaintenance.PeriodId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedPreventiveMaintenance.PeriodId', 'Id', newValue);
      },
    },
    selectedCapacity: {
      get() {
        const self = this;
        return self.selectGet('capacityEquipments', 'selectedPreventiveMaintenance.DeviceId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedPreventiveMaintenance.DeviceId', 'Value', newValue);
      },
    },
    selectedWork: {
      get() {
        const self = this;
        return self.selectGet('works', 'selectedPreventiveMaintenance.WorkId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedPreventiveMaintenance.WorkId', 'Id', newValue);
      },
    },
  },
  created() {
    const self = this;
    blockui.blockElementPromise(self, '#maintenance_periods');

    api.getMaintenancePeriodList().then((response) => {
      this.maintenancePeriods = response.body.Data;
      blockui.unBlockElement('#maintenance_periods');
    });
  },
  async mounted() {
    const self = this;
    window.$('form').validate();
    $('#preventive_maintenance_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
    self.selectedPreventiveMaintenance = this.preventiveMaintenance;
    self.capacityEquipments = this.capacityEquipmentList;
    if (self.selectedPreventiveMaintenance && self.selectedPreventiveMaintenance.DeviceId > 0) {
      this.fetchWork(this.selectedPreventiveMaintenance.DeviceId);
    }
    if (self.selectedPreventiveMaintenance && self.selectedPreventiveMaintenance.WorkId > 0) {
      self.selectedPreventiveMaintenance.Duration = self.selectedPreventiveMaintenance.Duration;
    }
    self.$eventHub.$on('newWorkAddedToDevice', async (data) => {
      if (data && data.DeviceId && data.WorkId) {
        await self.fetchWork(data.DeviceId);
        self.selectedPreventiveMaintenance.WorkId = parseInt(data.WorkId);
        const selectedWork = self.works.find((x) => x.Id == data.WorkId);
        self.selectedPreventiveMaintenance.Duration = selectedWork ? selectedWork.Duration : 0;
        self.selectedPreventiveMaintenance.PeriodId = selectedWork ? selectedWork.PeriodId : 0;
      }
    });
  },
  methods: {
    onSubmit() {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        toastr.error(self.translate('please_fill_all_required_fields'));
        return;
      }
      if (self.selectedPreventiveMaintenance.RemainedCount > self.selectedPreventiveMaintenance.TotalCount) {
        toastr.error(self.translate('remained_count_cannot_be_greater_than_total_count'));
      } else {
        blockui.blockModal(self);
        self.selectedPreventiveMaintenance.ReferenceDate = dateFormat.postDateTimeFormatFromDateObject(self.selectedPreventiveMaintenance.ReferenceDate);
        delete self.selectedPreventiveMaintenance.$id;
        delete self.selectedPreventiveMaintenance.$type;
        api.saveContractMaintenancePlan(self.selectedPreventiveMaintenance).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('pm_save_successfully'));
            self.reloadDataTable();
            self.closeModal();
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    addWork() {
      this.$emit('openWork', this.selectedPreventiveMaintenance.DeviceId);
    },
    setMaintenanceDateProperty(propertyKey, value) {
      Vue.set(this.selectedPreventiveMaintenance, propertyKey, value);
    },
    reloadDataTable() {
      this.$emit('reloadDataTable');
    },
    closeModal() {
      $('#preventive_maintenance_modal').modal('toggle');
    },
    onSelectedWorkChanged(selectedCapacity) {
      this.selectedPreventiveMaintenance.WorkId = null;
      this.fetchWork(selectedCapacity.Value);
    },
    onSelectWork(selectedWork) {
      this.selectedPreventiveMaintenance.Duration = selectedWork.Duration;
      this.selectedPreventiveMaintenance.PeriodId = selectedWork.PeriodId;
      this.selectedPreventiveMaintenance.Tolerance = selectedWork.DefermentCondition;
    },
    async fetchWork(selectedDeviceId) {
      blockui.blockElementPromise(this, '#work_select');
      await api.getWorkList({ DeviceId: selectedDeviceId }).then((response) => {
        this.works = response.body.Data;
        blockui.unBlockElement('#work_select');
      });
    },
    allowNumbersOnly(e) {
      const code = (e.which) ? e.which : e.keyCode;
      if (code > 31 && (code < 48 || code > 57)) {
        e.preventDefault();
      }
    },
  },
  watch: {
    capacityEquipmentList(e) {
      this.capacityEquipments = e;
    },
    preventiveMaintenance(e) {
      this.selectedPreventiveMaintenance = e;
    },
  },
  beforeDestroy() {
    this.$eventHub.$off('newWorkAddedToDevice');
  },
};
