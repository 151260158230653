import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import Select2 from '../../components/shared/select2';
import * as dateFormat from '../../helpers/dateFormat';
import * as blockui from '../../helpers/block-ui';
import MinutePicker from '../../components/shared/minutepicker';
import { mixinz } from '../global/mixinz';

export default {
  mixins: [mixinz],
  props: ['maintenancePlan', 'isUpdate'],
  data() {
    return {
      selectedMaintenancePlan: {
        room: '',
        capacityEquipment: '',
        work: '',
        maintenanceType: '',
        maintenancePeriod: '',
        maintenanceCount: 1,
        VendorNotificationRemainingDay: null,
        VendorId: null,
      },
      recommendedWorkDuration: 0,
      workList: [],
      currentDeviceList: null,
      fullWorkList: {},
      startDate: null,
      sendMail: null,
      vendorList: [],
    };
  },
  components: {
    minutepicker: MinutePicker,
  },
  computed: {
    ...mapState({
      maintenancePeriods: (state) => state.maintenance.maintenancePeriods,
      maintenanceTypeList: (state) => state.maintenance.maintenanceTypeList,
      capacityEquipmentList: (state) => state.capacity_equipment.capacityEquipmentBreadCrumbList,
      // workList: state => state.work.workList,
    }),
    maintenancePeriodList() {
      return this.maintenancePeriods.map((x) => ({
        Text: x.Name,
        Value: x.Id,
      }));
    },
    maintenancePeriodListFromIds() {
      const o = {};
      this.maintenancePeriods.forEach((maintenancePeriod) => {
        o[maintenancePeriod.Id] = maintenancePeriod;
      });
      return o;
    },
    StartDate: {
      get() {
        if (this.startDate) {
          return moment(this.startDate).format(this.pickerDateTimeFormat);
        }
        return null;
      },
      set(val) {
        if (val) {
          Vue.set(this, 'startDate', moment(val, this.pickerDateTimeFormat).toDate());
        } else {
          Vue.set(this, 'startDate', val);
        }
        return val;
      },
    },
    selectedVendor: {
      get() {
        const self = this;
        return self.selectGet('vendorList', 'selectedMaintenancePlan.VendorId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedMaintenancePlan.VendorId', 'Value', newValue);
      },
    },
  },
  mounted() {
    const self = this;
    $('#maintenance_plan_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });

    api.getWorksWithBreadCrumb().then((response) => {
      response.body.Data.forEach((item) => {
        self.fullWorkList[item.Id] = item;
      });

      self.workList = response.body.Data.map((x) => {
        if (!x.DeviceId && !x.RoundId) {
          return { Text: `${x.WorkGroupName} | ${x.CategoryName} | ${x.Name}`, Value: x.Id };
        }
        let type = 0;
        if (x.DeviceId && x.DeviceId > 0) {
          type = 1;
        } else if (x.RoomId && x.RoomId > 0) {
          type = 2;
        }
        return { Text: `${x.BreadCrumb} | ${x.Name}`, Value: x.Id, Type: type };
      });
    });

    api.getVendors().then((response) => {
      self.vendorList = response.body;
    });

    this.$store.dispatch('getCapacityEquipmentBreadCrumbList');
    this.$store.dispatch('getMaintenancePeriods');
    this.$store.dispatch('getMaintenanceTypeList');

    const maintenancePlanModal = $('#maintenance_plan_modal');

    self.$nextTick(() => {
      Vue.loadAsteriks();
    });

    self.selectedMaintenancePlan = self.maintenancePlan;

    if (self.selectedMaintenancePlan.ScheduledTime) {
      self.startDate = moment(self.selectedMaintenancePlan.ScheduledTime).toDate();
    }
    if (self.selectedMaintenancePlan.Duration) {
      self.recommendedWorkDuration = self.selectedMaintenancePlan.Duration;
    } else {
      self.recommendedWorkDuration = 0;
    }

    self.sendMail = self.selectedMaintenancePlan.VendorNotificationRemainingDay != undefined || self.selectedMaintenancePlan.VendorNotificationRemainingDay != null ? self.selectedMaintenancePlan.VendorNotificationRemainingDay : false;
    const sendActivationMail = window.$('#send_activation_mail');
    sendActivationMail.bootstrapSwitch({
      onText: self.translate('yes'),
      offText: self.translate('no'),
    });
    sendActivationMail.bootstrapSwitch('state', self.sendMail, self.sendMail);
    sendActivationMail.on('switchChange.bootstrapSwitch', (e, state) => {
      self.sendMail = state;
      self.$nextTick(() => {
        Vue.loadAsteriks();
      });
    });

    maintenancePlanModal.on('hidden.bs.modal', () => {
      self.selectedMaintenancePlan = {};
    });
  },
  methods: {
    onSubmit(e) {
      const self = this; let
        table;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        formData.StartDate = dateFormat.postDateTimeFormat(self.startDate);

        if (!self.sendMail) {
          formData.VendorId = null;
          formData.VendorNotificationRemainingDay = null;
        }

        if (self.isUpdate) {
          api.updateMaintenancePlan(formData).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('update_successfully'));
              $('#maintenance_plan_modal').modal('toggle');
              if (self.$route.meta.xyz) {
                Vue.getDatatables('.maintenance_plan');
              } else {
                self.reloadDataTable();
              }
            } else {
              toastr.error(response.body.Message);
            }
            blockui.unBlockModal();
          }, (error) => {
            blockui.unBlockModal();
          });
        } else {
          api.saveMaintenancePlan(formData).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('save_successfully'));
              $('#maintenance_plan_modal').modal('toggle');
              if (self.$route.meta.xyz) {
                Vue.getDatatables('.maintenance_plan');
                if (!self.isUpdate) {
                  self.$parent.slc.works.MaintenancePlanCount = self.$parent.slc.works.MaintenancePlanCount ? self.$parent.slc.works.MaintenancePlanCount + parseInt(self.selectedMaintenancePlan.maintenanceCount) : parseInt(self.selectedMaintenancePlan.maintenanceCount);
                }
              } else {
                self.reloadDataTable();
              }
            } else {
              toastr.error(response.body.Message);
            }
            blockui.unBlockModal();
          }, (error) => {
            blockui.unBlockModal();
          });
        }
      }
    },
    setWork(workId) {
      const self = this;
      self.selectedMaintenancePlan.workId = workId;

      if (self.selectedMaintenancePlan.workId > 0 && self.fullWorkList[self.selectedMaintenancePlan.workId] && self.fullWorkList[self.selectedMaintenancePlan.workId].ModelId) {
        blockui.pageBlock();
        api.getDevicesFromModelId({ modelId: self.fullWorkList[self.selectedMaintenancePlan.workId].ModelId }).then((res) => {
          self.currentDeviceList = res.body;
          blockui.unBlockPage();
          self.$nextTick(() => {
            Vue.loadAsteriks();
          });
        }, (err) => {
          blockui.unBlockPage();
        });
      } else if (self.selectedMaintenancePlan && self.selectedMaintenancePlan.workId > 0) {
        self.currentDeviceList = null;
      } else {
        self.currentDeviceList = null;
      }
      if (workId > 0 && self.fullWorkList[workId]) {
        self.recommendedWorkDuration = self.fullWorkList[workId].Duration;
      }
      // self.selectedMaintenancePlan.WorkId
    },
    reloadDataTable() {
      const filterForm = $("form[name='filterForm']");
      if (filterForm.valid()) {
        const formData = {};
        $.map(filterForm.serializeArray(), (item) => {
          if (item.value !== '') {
            if (item.name === 'DateRange') {
              Object.assign(formData, { fromDate: dateFormat.postDateFormat(item.value.split(' - ')[0]) });
              Object.assign(formData, { endDate: dateFormat.postDateFormat(item.value.split(' - ')[1]) });
            } else {
              Object.assign(formData, { [item.name]: item.value });
            }
          }
        });
        Vue.getDatatables(formData);
      }
    },
    arrangeMaintenanceCount(maintenancePeriodId) {
      /* if (this.maintenancePeriodListFromIds[maintenancePeriodId] && !(this.selectedMaintenancePlan.maintenanceCount > 0)) {
                    Vue.set(this.selectedMaintenancePlan, 'maintenanceCount', (Math.floor(365 / this.maintenancePeriodListFromIds[maintenancePeriodId].Period)))
            } */
    },
  },
};
