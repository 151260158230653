import * as api from '../../scripts/services/api';

// initial state
const state = {
  documentStatuses: [],
  documentValidityStatuses: [],
};

// getters
const getters = {
  getDocumentStatusText: (state) => (id) => {
    if (state.documentStatuses.find((documentStatus) => documentStatus.Value === id)) {
      return state.documentStatuses.find((documentStatus) => documentStatus.Value === id).Text;
    }
    return '';
  },
  getDocumentValidityStatusText: (state) => (id) => {
    if (state.documentValidityStatuses.find((documentValidityStatus) => documentValidityStatus.Value === id)) {
      return state.documentValidityStatuses.find((documentValidityStatus) => documentValidityStatus.Value === id).Text;
    }
    return '';
  },
};

// actions
const actions = {
  getDocumentStatuses({ commit }) {
    api.getDocumentStatuses().then((res) => {
      commit('setDocumentStatuses', res.body);
    });
  },
  getDocumentValidityStatuses({ commit }) {
    api.getDocumentValidityStatuses().then((res) => {
      commit('setDocumentValidityStatuses', res.body);
    });
  },
};

// mutations
const mutations = {
  setDocumentStatuses(state, documentStatuses) {
    state.documentStatuses = documentStatuses;
  },
  setDocumentValidityStatuses(state, documentValidityStatuses) {
    state.documentValidityStatuses = documentValidityStatuses;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
