







































import CFMDialog from '@/scripts/CFMDialog'
import { Component, Vue } from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import { CFMDTOJob } from '@/scripts/services/ClientApiAuto'

@Component<JobDialog>({
    validations() {
        return {
          job:{
              Name: {required},
          },
        }
    },
})
export default class JobDialog extends CFMDialog {
    job: CFMDTOJob = {Name: ''};

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.job.jobGetById({id: <number>this.id});
        this.job = res.data;
      }
      blockui.unBlockModal();
    }

    get isUpdate() {
        return !!this.id;
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      
      let formData:CFMDTOJob = {
        Id: this.job.Id,
        Name: this.job.Name,
      };

      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.job.jobSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
