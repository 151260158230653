import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as blockui from '../../../../helpers/block-ui';
import * as datatable from '../../../../scripts/global/datatables';
import * as dateFormat from '../../../../helpers/dateFormat';
import WorkOrderFormModal from '../../../partials/modals/WorkOrderFormModal.vue';
import Filters from './FormAnswersFilters';
import { mixinz } from '../../../../scripts/global/mixinz';

export default {
  mixins: [mixinz],
  data() {
    return {
      params: {},
      showWorkOrderFormModal: false,
      showFilter: true,
      filterData: {},
      initialFilterValues: {},
    };
  },
  components: {
    'work-order-form-modal': WorkOrderFormModal,
    filters: Filters,
  },
  computed: {
    ...mapState({
      formProcessList: (state) => state.form.formProcessList,
    }),
  },
  async mounted() {
    const self = this;
    this.setColumns();
    const datatableContainer = '.form_answer_list';
    await this.clearFilter();
    this.$eventHub.$emit('loadFilterData', 'formAnswers');

    // region show
    window.$(datatableContainer).on('click', 'tbody > tr > td > .show', function () {
      const table = $(this).closest('table');
      const dt = table.DataTable();
      const tr = $(this).closest('tr');
      const data = dt.row(tr).data();
      self.showModal(
        'params',
        {
          formId: data.FormId,
          procedureTypeId: data.ProcedureTypeId,
          workOrderId: data.WorkOrderId,
          isEditable: false,
          isCurrentFormChangeable: false,
        },
        'showWorkOrderFormModal',
        'form_modal',
      );
    });
  },
  methods: {
    isAllPropertiesEmpty(obj) {
      const self = this;
      if (obj) {
        let isEmpty = true;
        Object.keys(obj).forEach((key) => {
          if (!(obj[key] == null || obj[key] === undefined || obj[key] == '')) {
            if (typeof obj[key] === 'object') {
              isEmpty = self.isAllPropertiesEmpty(obj[key]);
            }
            isEmpty = false;
          }
        });
        return isEmpty;
      }
      return true;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    clearFilter() {
      this.loadFormAnswers({
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate(),
      });
    },
    fixDataTable() {
      $($.fn.dataTable.tables(true)).DataTable()
        .columns.adjust()
        .responsive.recalc();
    },
    loadFormAnswers(formData) {
      Vue.set(this, 'filterData', formData);
      this.getFormAnswersTable();
    },
    async getFormAnswersTable() {
      const self = this;
      const data = {};
      Object.keys(this.filterData).forEach((key) => {
        if (key.startsWith('startDate') || key.startsWith('endDate')) {
          if (this.filterData[key]) {
            data[key] = dateFormat.postDateTimeFormat(this.filterData[key]);
          }
        } else {
          data[key] = this.filterData[key];
        }
      });
      blockui.blockElement(self, '.m-portlet');
      const promises = [
        this.$store.dispatch('getFormProcessList', data),
      ];
      await Promise.all(promises);
      // datatable.getDatatables(this, 'getFormList', 'body.Data', '.form_list', this.columns, null, {expandable: true});
      datatable.getDatatablez(this, 'getFormProcessList', this.formProcessList, '.form_answer_list', this.columns, null, {
        extraButtons: [{
          class: 'show',
          tooltip: self.translate('show'),
          icon: 'fa-eye',
        }],
        editButton: false,
        deleteButton: false,
      });
      blockui.unBlockElement('.m-portlet');
    },
    setColumns() {
      this.columns = [{
        data: 'Id',
        title: 'ID',
        sortable: true,
        width: 40,
      }, {
        data: 'FormName',
        title: this.translate('form_name'),
        sortable: 'asc',
        width: 200,
      }, {
        data: 'WorkName',
        title: this.translate('work'),
        sortable: 'asc',
        width: 100,
      }, {
        data: 'WorkOrderId',
        title: this.translate('work_order_number'),
        sortable: 'asc',
        width: 100,
        render: (data, type, rowData) => {
          const routeData = this.$router.resolve({ name: 'MyWorkOrder', query: { workOrderId: rowData.WorkOrderId, from: 'FormAnswers' } }).href;
          return `<a href="${routeData}" class="work-order-link">${rowData.WorkOrderId}</a>`;
        },
      }, {
        data: 'WorkOrderStatus',
        title: this.translate('work_order_status'),
        sortable: 'asc',
        width: 100,
        render: (data, type, rowData) => `<span class="badge" style="color: #fff;background-color: ${rowData.WorkOrderStatusColorCode}">${rowData.WorkOrderStatus}</span>`,
      }, {
        data: 'Date',
        title: this.translate('last_modified_date'),
        sortable: 'asc',
        width: 100,
        render: (data, type, rowData) => dateFormat.viewDateTimeFormat(rowData.ModifiedDate ? rowData.ModifiedDate : rowData.CreatedDate),
      }, {
        data: 'Staff',
        title: this.translate('last_modified_user'),
        sortable: 'asc',
        width: 100,
        render: (data, type, rowData) => (rowData.ModifiedUser && rowData.ModifiedUser.trim() != '' ? rowData.ModifiedUser : rowData.CreatedUser),
      }, {
        data: 'InvalidAnswerCount',
        title: this.translate('invalid_answer_count'),
        sortable: 'asc',
        width: 100,
      }, {
        data: 'FeedbackCount',
        title: this.translate('feedback'),
        sortable: 'asc',
        width: 100,
        defaultContent: '',
      }, {
        data: 'Version',
        title: this.translate('version'),
        sortable: 'asc',
        width: 50,
      }, {
        data: 'StatusName',
        title: this.translate('status'),
        sortable: 'asc',
        width: 100,
        render: (data, type, rowData) => `<span class="badge" style="color: #fff;background-color: ${rowData.StatusColor}">${rowData.StatusName}</span>`,
      }];
    },
  },
};
