import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import MaskedInput from 'vue-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as api from '../../services/api';
import Select2Group from '../../../components/shared/select2Group';
import { formatDataWithParams } from '../../global/util';
import {requiredIf} from "vuelidate/lib/validators";

export default {
  props: {
    capacityEquipment: {
      default() {
        return {};
      },
      type: Object,
    },
    isUpdate: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    'select2-group': Select2Group,
    'masked-input': MaskedInput,
  },
  data() {
    return {
      step: 1,
      allDeviceList: [],
      infrastructureListWithCapacityTypes: [],
      productFamilyList: [],
      sources: [],
      outputs: [],
      sourceArray: [],
      outputsArray: [],
      showTotalEffectiveCapacity: false,
      infrastructureId: null,
      numberMask: [],
      dateOfInstallation: null,
      dateOfWarrantyStart: null,
      expectedLifeEndDate: null,
    };
  },
  validations() {
    return {
      capacityEquipment: {
        TotalEffectiveCapacityUnitId: {
          checked(value, vm) {
            if (vm.TotalEffectiveCapacity) {
              return !!vm.TotalEffectiveCapacityUnitId;
            }
            return true;
          },
        },
        TotalEffectiveCapacity: {
          requiredIf: requiredIf(() => this.infrastructureId == this.enumInfrastructure.Electric)
        }
      },
    }
  },
  computed: {
    ...mapState({
      enumInfrastructure: (state) => state.enums.infrastructure,
      modelList: (state) => state.brand.brandListWithModels,
      capacityEquipmentModelRoomList: (state) => state.room.addCapacityEquipmentModalRoomList,
    }),
    ...mapGetters({
      unitList: 'getUnitList',
    }),
    getInfrastructuresWithCapacityTypes() {
      return formatDataWithParams(this.infrastructureListWithCapacityTypes, ['InfrastructureGroups', 'InfrastructureTypes', 'CapacityTypes']);
    },
    getCapacityEquipmentModelRoomList() {
      return formatDataWithParams(this.capacityEquipmentModelRoomList, ['Buildings', 'Floors', 'Rooms']);
    },
    getBrandsWithModels() {
      return formatDataWithParams(this.modelList, ['DeviceModels']);
    },
    suggestedRunHourString: {
      // getter
      get() {
        return this.getSuggestedRunHourString();
      },
      // setter
      set(newValue) {
        this.capacityEquipment.SuggestedRunHour = newValue;
      },
    },
    getMaxProductionYear() {
      if (!(this.dateOfInstallation) && !(moment(this.dateOfInstallation).isValid())) {
        return moment().year();
      }
      return moment(this.dateOfInstallation).year();
    },
    dateOfInstallationStartDate() {
      if (this.capacityEquipment.ProductionYear) {
        return moment().year(this.capacityEquipment.ProductionYear).startOf('year').toDate();
      }
      return moment().startOf('year').subtract(20, 'years').toDate();
    },
    dateOfWarrantyStartStartDate() {
      if (this.capacityEquipment.ProductionYear) {
        return moment().year(this.capacityEquipment.ProductionYear).startOf('year').toDate();
      }
      return moment().startOf('year').subtract(20, 'years').toDate();
    },
    expectedLifeEndDateStartDate() {
      if (this.dateOfInstallation) {
        return new Date(this.dateOfInstallation);
      } if (this.capacityEquipment.ProductionYear) {
        return moment().year(this.capacityEquipment.ProductionYear).startOf('year').toDate();
      }
      return moment().startOf('year').subtract(20, 'years').toDate();
    },
  },
  mounted() {
    const self = this;

    self.numberMask = createNumberMask({
      prefix: '',
      suffix: '',
    });

    window.$('form').validate();
    if (!self.capacityEquipment) {
      self.capacityEquipment = {};
    }

    this.$store.dispatch('getCapacityEquipmentModalRoomList');

    this.$store.dispatch('getUnitList');

    api.getInfrastructuresWithCapacityTypes().then((response) => {
      self.infrastructureListWithCapacityTypes = response.body.Data;
    });

    api.getProductFamilies().then((response) => {
      this.productFamilyList = response.body;
    });

    this.$store.dispatch('getBrandListWithModels', { isForCapacityEquipment: true });

    self.$nextTick(() => {
      // Vue.modalDatePickerPlace();

      self.showTotalEffectiveCapacity = !!(self.capacityEquipment && self.capacityEquipment.InfrastructureId == self.enumInfrastructure.Electric);
      self.infrastructureId = self.capacityEquipment ? self.capacityEquipment.InfrastructureId : '';

      $("#capacity_equipment_modal form [name='CapacityTypeId']").on('change', (e) => {
        const capacityTypeId = e.target.value;
        let infrastructureId = null;
        self.infrastructureListWithCapacityTypes.forEach((item) => {
          const infrastructureGroups = item.InfrastructureGroups;
          infrastructureGroups.forEach((infrastructureGroup) => {
            if (infrastructureGroup.InfrastructureTypes.length > 0) {
              infrastructureGroup.InfrastructureTypes.forEach((CapacityType) => {
                const capacityTypes = CapacityType.CapacityTypes;
                capacityTypes.forEach((type) => {
                  if (type.Id == capacityTypeId) {
                    infrastructureId = infrastructureGroup.InfrastructureId;
                  }
                });
              });
            }
          });
        });
        self.infrastructureId = infrastructureId;
        // e.target.value dan infrastructureid bulunacak
        self.showTotalEffectiveCapacity = !!(infrastructureId && infrastructureId == self.enumInfrastructure.Electric);
        self.$nextTick(() => {
          Vue.loadAsteriks();
        });
      });

      self.$nextTick(() => {
        Vue.loadAsteriks();
      });

      /* $('.date-picker').datepicker({
              autoclose: true,
              language: localStorage.getItem('vue-lang')
            }); */

      /* $('#dateOfInstallation').datepicker({
              autoclose: true,
              language: localStorage.getItem('vue-lang'),
              endDate: new Date(),
              startDate: moment().startOf('year').subtract(20, 'years').toDate(),
            }).on('changeDate', function (e) {
            }); */
      /* $('#dateOfWarrantyStart').datepicker({
               autoclose: true,
               language: localStorage.getItem('vue-lang'),
             });
             $('#expectedLifeEndDate').datepicker({
               autoclose: true,
               language: localStorage.getItem('vue-lang'),
             }); */

      if (self.capacityEquipment.DateOfInstallation) {
        self.dateOfInstallation = new Date(self.capacityEquipment.DateOfInstallation);
      }
      if (self.capacityEquipment.DateOfWarrantyStart) {
        self.dateOfWarrantyStart = new Date(self.capacityEquipment.DateOfWarrantyStart);
      }
      if (self.capacityEquipment.ExpectedLifeEndDate) {
        self.expectedLifeEndDate = new Date(self.capacityEquipment.ExpectedLifeEndDate);
      }
    });

    self.$nextTick(() => {
      Vue.loadAsteriks();
    });
  },
  methods: {
    openRoomModal() {
      this.$emit('open-room-modal');
    },
    getSuggestedRunHourString() {
      if (this.capacityEquipment.SuggestedRunHour) {
        return this.capacityEquipment.SuggestedRunHour.toString();
      }
      return '';
    },
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      self.$v.$touch();
      if (!self.$v.$invalid && form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        self.$emit('next', formData);
      }
    },
    /* formatDataWithParams(data, keys) {
            const self = this;
            if (!data || !(data.length > 0)) {
                return [];
            }
            let result = [];
            for (const dataItem of data) {
                let resultItem = {};
                if (keys && keys.length > 0) {
                    let children = [];
                    if (dataItem.hasOwnProperty(keys[0])) {
                        children = self.formatDataWithParams(dataItem[keys[0]], [...keys.slice(1, keys.length)])
                    }
                    resultItem.children = children;
                }
                resultItem = {...resultItem, text: dataItem.Name, id: dataItem.Id};
                result.push(resultItem);
            }
            return result;
        }, */
    unmask(val) {
      if (val) {
        return val.toString().replace(/\D+/g, '');
      }
      return '';
    },
  },
  watch: {
    capacityEquipment(e) {
      const self = this;
      if (this.isUpdate) {
        if (self.capacityEquipment.DateOfInstallation) {
          self.dateOfInstallation = new Date(self.capacityEquipment.DateOfInstallation);
        }
        if (self.capacityEquipment.DateOfWarrantyStart) {
          self.dateOfWarrantyStart = new Date(self.capacityEquipment.DateOfWarrantyStart);
        }
        if (self.capacityEquipment.ExpectedLifeEndDate) {
          self.expectedLifeEndDate = new Date(self.capacityEquipment.ExpectedLifeEndDate);
        }
      }
    },
  },
};
