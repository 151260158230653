import Vue from 'vue';
import * as swal from '../../helpers/swal';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';

export const mixinz = {
  data() {
    return {
      picker: {
        // https://momentjs.com/docs/#/displaying/
        locale: localStorage.getItem('vue-lang'),
        // format: 'YYYY.MM.DD HH:mm:ss',
        useCurrent: false,
        showClear: true,
        showClose: true,
        // inline: true,
        ignoreReadonly: true,
        icons: {
          time: 'far fa-clock',
          date: 'far fa-calendar',
          up: 'fas fa-arrow-up',
          down: 'fas fa-arrow-down',
          previous: 'fas fa-chevron-left',
          next: 'fas fa-chevron-right',
          today: 'fas fa-calendar-check',
          clear: 'far fa-trash-alt',
          close: 'far fa-times-circle',
        },
      },
      pickerDateFormat: 'DD.MM.YYYY',
      pickerDateTimeFormat: 'DD.MM.YYYY HH:mm',
      pickerNewDateTimeFormat: 'YYYY-MM-DD HH:mm',
      pickerTimeFormat: 'HH:mm',
    };
  },
  computed: {
    pickerDate() {
      return { format: this.pickerDateFormat, ...this.picker };
    },
    pickerNewDateTime(){
      return { format: this.pickerNewDateTimeFormat, ...this.picker };
    },
    pickerDateTime() {
      return { format: this.pickerDateTimeFormat, ...this.picker };
    },
    pickerTime() {
      return { format: this.pickerTimeFormat, ...this.picker };
    },
    selectOptions() {
      const self = this;
      return {
        searchable: true,
        placeholder: self.translate('please_select'),
        'select-label': self.translate('select_label'),
        'deselect-label': self.translate('deselect_label'),
        'selected-label': self.translate('selected_label'),
        'show-no-results': false,
      };
    },
    selectOptionsRequired() {
      const self = this;
      return {
        ...self.selectOptions,
        'allow-empty': false,
        'deselect-label': '',
      };
    },
  },
  methods: {
    async showModal(target, data, show, modalId) {
      Vue.set(this, target, data);
      Vue.set(this, show, true);
      await this.$nextTick(() => {
        const modalContainer = window.$(`#${modalId}`);
        modalContainer.modal({
          show: true,
          backdrop: 'static',
        });
      });
    },
    async openModal(show, modal, update, up, prnt) {
      if (prnt == 1) {
        Vue.set(this.$parent, show, true);
        Vue.set(this.$parent, up, update);
      } else {
        Vue.set(this, show, true);
        Vue.set(this, up, update);
      }
      let formUpdate = '';
      if (update) {
        formUpdate = 'update';
      }
      await this.$nextTick(() => {
        const modalContainer = window.$(modal);
        modalContainer.modal({
          show: true,
          backdrop: 'static',
        });
        modalContainer.find('form').data('type', formUpdate);
      });
    },
    onCloseModal(modal, selected, update) {
      const self = this;
      $(modal).on('hidden.bs.modal', (e) => {
        self.$emit('close');
      });
    },
    nestedResolve(path, obj) {
      return path.split('.').reduce((prev, curr) => {
        if (!prev[curr]) Vue.set(prev, curr, null);
        return prev[curr];
      }, obj || this);
    },
    editItem(endpoint, item, obj, res, show, modal, up, prnt) {
      // Vue.set( this, obj, {} );
      /* api[endpoint](item.Id).then(response => {
              if (prnt == 1) {
                Vue.set( this.$parent, obj, this.nestedResolve(res, response) );
              } else {
                Vue.set( this, obj, this.nestedResolve(res, response) );
              }
            }); */
      if (prnt == 1) {
        console.log(this.$parent, obj, item);
        Vue.set(this.$parent, obj, item);
      } else {
        Vue.set(this, obj, item);
      }

      this.openModal(show, modal, true, up, prnt);
    },
    async addItem(item, obj, show, modal, up, prnt) {
      if (prnt == 1) {
        Vue.set(this.$parent, obj, item);
      } else {
        Vue.set(this, obj, item);
      }
      await this.openModal(show, modal, false, up, prnt);
    },
    deleteItem(endpoint, item, index, list) {
      return swal.check(this, item.Name).then((e) => {
        if (e.value) {
          // api[endpoint](item.Id).then(response => {
          // if (response.body.IsSuccess) {
          toastr.success(this.translate('delete_successfully'));
          list.splice(index, 1);
          return true;
          // } else {
          // toastr.error(response.body.Message);
          // }
          // });
        }
      });
    },
    /* async printItem(element, autoPrint, beforeCallback, afterCallback) {
          let beforeCallbackResult = await beforeCallback();
          if (!beforeCallbackResult) {
            return false;
          }
          this.$nextTick(() => {
            var _link = document.createElement('a');
            var _relToAbs = function (href) {
              // Assign to a link on the original page so the browser will do all the
              // hard work of figuring out where the file actually is
              _link.href = href;
              var linkHost = _link.host;
              // IE doesn't have a trailing slash on the host
              // Chrome has it on the pathname
              if (linkHost.indexOf('/') === -1 && _link.pathname.indexOf('/') !== 0) {
                linkHost += '/';
              }
              return _link.protocol + "//" + linkHost + _link.pathname + _link.search;
            };
            var _styleToAbs = function (el) {
              var url;
              var clone = $(el).clone()[0];
              var linkHost;
              if (clone.nodeName.toLowerCase() === 'link') {
                clone.href = _relToAbs(clone.href);
              }
              return clone.outerHTML;
            };
            var html = $(element).html();
            var win = window.open('', '');
            win.document.close();
            var head = '<title>' + this.selectedForm.Name + '</title>';
            $('style, link').each(function () {
              head += _styleToAbs(this);
            });
            head += '<style>* { font-family: Arial, Helvetica, sans serif !important; }</style>';
            try {
              win.document.head.innerHTML = head; // Work around for Edge
            } catch (e) {
              $(win.document.head).html(head); // Old IE
            }
            win.document.body.innerHTML = html;
            // win.document.body.innerHTML =
            //  '<h1>'+exportInfo.title+'</h1>'+
            //  '<div>'+(exportInfo.messageTop || '')+'</div>'+
            //  html+
            //  '<div>'+(exportInfo.messageBottom || '')+'</div>';
            // $(win.document.body).addClass('ddd');
            $('img', win.document.body).each(function (i, img) {
              img.setAttribute('src', _relToAbs(img.getAttribute('src')));
            });
            // Allow stylesheets time to load
            win.setTimeout(function () {
              if (autoPrint) {
                win.print(); // blocking - so close will not
                win.close(); // execute until this is done
                afterCallback();
              }
            }, 1000);
          });
        }, */
    selectSet(destination, trackBy, newValue, isMultiple, destinationTrackBy) {
      const self = this;
      if (isMultiple) {
        const newArr = [];
        newValue.forEach((item) => {
          newArr.push({ [destinationTrackBy]: item[trackBy] });
        });
        const lastDotIndex = destination.lastIndexOf('.');
        const a = destination.substr(0, lastDotIndex);
        const b = destination.substr(lastDotIndex + 1);
        if (a) {
          Vue.set(self.nestedResolve(a, self), b, newArr);
        } else {
          Vue.set(self, b, newArr);
        }
        return newValue[trackBy];
      }
      const lastDotIndex = destination.lastIndexOf('.');
      const a = destination.substr(0, lastDotIndex);
      const b = destination.substr(lastDotIndex + 1);
      if (newValue) {
        if (a) {
          Vue.set(self.nestedResolve(a, self), b, newValue[trackBy]);
        } else {
          Vue.set(self, b, newValue[trackBy]);
        }
        return newValue[trackBy];
      }
      if (a) {
        Vue.set(self.nestedResolve(a, self), b, null);
      } else {
        Vue.set(self, b, null);
      }
      return null;
    },
    selectGet(repository, destination, trackBy, label, isMultiple, destinationTrackBy) {
      const self = this;
      if (isMultiple) {
        const a = self.nestedResolve(destination, self);
        /* if (!a) {
                  a = [];
                } */
        if (!a) {
          return null;
        }
        return a.map((x) => {
          const selectedObj = self.nestedResolve(repository, self).find((y) => x[destinationTrackBy] === y[trackBy]);
          if (selectedObj) {
            return { Value: x[destinationTrackBy], Text: selectedObj.Text };
          }
          return { Value: x[destinationTrackBy], Text: self.translate('loading') };
        });
      }
      if (!self.nestedResolve(destination, self)) {
        return null;
      }
      const selectedObj = self.nestedResolve(repository, self).find((x) => self.nestedResolve(destination, self) === x[trackBy]);
      if (selectedObj) {
        return { ...selectedObj };
      }
      return {};
    },
    selectMultipleGet(repository, destination, trackBy, destinationTrackBy) {
      const self = this;
      const a = self.nestedResolve(destination, self);
      if (!a) {
        return null;
      }
      return a.map((x) => {
        const selectedObj = self.nestedResolve(repository, self).find((y) => x[destinationTrackBy] === y[trackBy]);
        if (selectedObj) {
          return selectedObj;
        }
        return {};
      });
    },
    selectMultipleSet(destination, trackBy, newValue, destinationTrackBy) {
      const self = this;
      const newArr = [];
      newValue.forEach((item) => {
        newArr.push({ [destinationTrackBy]: item[trackBy] });
      });
      const lastDotIndex = destination.lastIndexOf('.');
      const a = destination.substr(0, lastDotIndex);
      const b = destination.substr(lastDotIndex + 1);
      if (a) {
        Vue.set(self.nestedResolve(a, self), b, newArr);
      } else {
        Vue.set(self, b, newArr);
      }
      return newValue[trackBy];
    },
    deepClone(obj) {
      if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj) return obj;

      if (obj instanceof Date) var temp = new obj.constructor(); // or new Date(obj);
      else var temp = obj.constructor();

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj.isActiveClone = null;
          temp[key] = this.deepClone(obj[key]);
          delete obj.isActiveClone;
        }
      }
      return temp;
    },
    deepEqual(obj1, obj2) {
      if (obj1 === obj2) return true;
      if ((typeof obj1 === 'object' && obj1 != null) && (typeof obj2 === 'object' && obj2 != null)) {
        if (Array.isArray(obj1) || Array.isArray(obj2)) {
          if (Array.isArray(obj1) && Array.isArray(obj2)) {
            if (obj1.length !== obj2.length) return false;
            return obj1.filter((x) => obj2.find((y) => this.deepEqual(x, y))).length === obj1.length;
          }
          return false;
        }

        const props1 = Object.keys(obj1);
        const props2 = Object.keys(obj2);
        if (props1.length !== props2.length) return false;
        const result = props1.find((prop) => !props2.includes(prop) || !this.deepEqual(obj1[prop], obj2[prop]));
        return result === undefined;
      }
      return false;
    },
  },
  mounted() {
  },
  created() {
    const self = this;
  },
};
