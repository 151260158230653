<template>
    <div class="m-grid m-grid--hor m-grid--root m-page">
        <router-view/>
    </div>
</template>

<style lang="scss">
    @import "assets/scss/otomatica";
    @import "assets/scss/material";
    //@import "assets/scss/custom";
</style>

<style>
  div.e-ddl.e-popup.e-popup-flmenu.e-lib.e-control.e-popup-open[id^="strui-grid-column"][id$="_popup"] {
    display: none;
  }
</style>

<script>
import Vue from 'vue';
import * as toastr from 'toastr';
import { L10n, setCulture, loadCldr } from '@syncfusion/ej2-base';
import languages from './languages/index';
  
import numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import gregorian from "cldr-data/main/tr/ca-gregorian.json";
import numbers from "cldr-data/main/tr/numbers.json";
import timeZoneNames from "cldr-data/main/tr/timeZoneNames.json";
import weekData from "cldr-data/supplemental/weekData.json";

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
const currentLanguage = localStorage.getItem('vue-lang') || 'tr-TR';

L10n.load(languages);
setCulture('tr');

export default {
  name: 'App',
  data() {
    return {};
  },
  created() {
  },
  mounted() {
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      window.addEventListener('hashchange', this.hashChangeHandler);
    }

    Vue.modalDatePickerPlace = () => {
      $('.modal-content').scroll(() => {
        $('.date-picker').datepicker('place');
        $('.datetime-picker').datetimepicker('place');
        $('.m_daterangepicker').daterangepicker('place');
      });
    };

    Vue.justNumber = () => {
      $('.just-number').on('keypress', (evt) => {
        const charCode = (evt.which) ? evt.which : event.keyCode;
        return !(charCode > 31 && (charCode < 48 || charCode > 57));
      });
    };

    Vue.renderMenuItems = () => {
      const menuList = $('#m_aside_left .m-menu__nav li');

      const checkParentItem = (item) => {
        $(item).find('ul').each(function () {
          const li = $(this).find('li');
          if (li.length === 0) {
            $(this).closest('li').remove();
          }
          checkParentItem(li);
        });
      };

      $.each(menuList, function () {
        if (localStorage.getItem('menuItem')) {
          const self = $(this);
          const id = self.attr('id');
          if (!id.includes('parent_menu')) {
            const menuItem = localStorage.getItem('menuItem');
            let check = [];
            if (menuItem && menuItem != 'undefined') {
                check = menuItem.split(',').map(item => {
                    return item + '_menu' === id;
                });
            }
            !check.includes(true) && self.remove();
          }
        } else {
          const self = $(this);
          self.remove();
        }
      });
      checkParentItem('#m_ver_menu');
    };
    Vue.renderMenuItems();

    Vue.loadSidebarSearch = () => {
      /* let asideLeftActive = localStorage.getItem('aside_left_active');
                if (asideLeftActive || !asideLeftActive && asideLeftActive != true) {
                  let asideLeftToggle = new mToggle('m_aside_left_minimize_toggle', {
                    target: 'body',
                    targetState: 'm-brand--minimize m-aside-left--minimize',
                    togglerState: 'm-brand__toggler--active'
                  });
                  setTimeout(() => {
                    asideLeftToggle.toggle()
                  }, 2000)

                } */

      $('#m_aside_left_minimize_toggle').on('click', function () {
        const isActive = $(this).hasClass('m-brand__toggler--active');
        localStorage.setItem('aside_left_active', !isActive);
        if (isActive) {
          $('.sidebar-search').hide();
        } else {
          $('.sidebar-search').show();
        }
      });
      $('.sidebar-search .remove-button').on('click', function () {
        $(this).hide();
        $('.sidebar-search input').val('');
        $('#search_result').html('');
      });
    };

    Vue.loadValidationLanguage = () => {
      const currentLanguage = localStorage.getItem('vue-lang') || 'tr-TR';
      import(`@/assets/static/validation-localization/${currentLanguage}`);
    };

    Vue.loadDataTableLanguage = () => {
      $.extend(true, $.fn.dataTable.defaults, {
        language: require(`@/assets/static/DataTables/i18n/${localStorage.getItem('vue-lang')}.json`),
      });
    };

    Vue.setMomentDefaultLanguage = () => {
      moment.locale(localStorage.getItem('vue-lang'));
    };

    Vue.loadAsteriks = () => {
      $.each($('[required]'), (i, element) => {
        if ($(element).closest('.form-group').find('label span.red').length === 0 && !($(element).closest('.form-group').find('label').hasClass('m-radio')) && !($(element).closest('.form-group').find('label').hasClass('m-checkbox'))) {
          $(element).closest('.form-group').find('label').append("<span class='red'>*</span>");
        }
      });
    };
    Vue.metronLink = (to, from) => {
      if (from) {
        const fromPathMenuItem = $(`li.m-menu__item a[href="#${from.path}"]`);
        fromPathMenuItem.parent('li.m-menu__item').removeClass('m-menu__item--active');
        fromPathMenuItem.parents('li.m-menu__item--submenu').removeClass('m-menu__item--open');
      }
      const currentPathMenuItem = $(`li.m-menu__item a[href="#${this.$route.path}"]`);
      currentPathMenuItem.parent('li.m-menu__item').addClass('m-menu__item--active');
      currentPathMenuItem.parents('li.m-menu__item--submenu').addClass('m-menu__item--open');
    };

    Vue.checkVersion = () => {
      const currentVersion = process.env.VUE_APP_VERSION;
      const version = localStorage.getItem('version');
      const ignoreRemoveList = ['dashboard', 'vue-lang'];

      if (!version || (version !== currentVersion)) {
        Object.keys(localStorage).forEach((key) => {
          if (!ignoreRemoveList.some((ignoreRemoveKey) => key.startsWith(ignoreRemoveKey))) {
            localStorage.removeItem(key);
          }
        });
        localStorage.setItem('version', currentVersion);
        if (this.$route.name.toLowerCase() !== 'login') {
          this.$router.push({
            path: '/Login',
            query: { redirect: this.$route.fullPath.replace('/', '') },
          });
        }
      }
    };

    mLayout.init();

    /* Vue.metronic = () => {
                Dashboard.init();
            }; */

    $('.modal-side').on('hidden.bs.modal', (e) => {
      const form = $(e.target).find('form');
      if (form) {
        const validator = form.validate();
        if (validator) {
          validator.resetForm();
        }
      }
    });

    $.validator.setDefaults({
      errorPlacement(error, element) {
        if (element.parent('.input-group').length) {
          error.insertAfter(element.parent()); // radio/checkbox?
        } else if (element.parent('.grouped-input').length) {
          error.insertAfter(element.parents('.grouped-inputs').find('.grouped-input').last()); // grouped
        } else if (element.hasClass('select2-hidden-accessible')) {
          error.insertAfter(element.next('span')); // select2
        } else {
          error.insertAfter(element); // default
        }
      },
      ignore: ['.ignore-validate'],
    });

    $(document).on('show.bs.modal', '.modal', function () {
      const zIndex = 1040 + (10 * $('.modal:visible').length);
      $(this).css('z-index', zIndex);
      setTimeout(() => {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
      }, 0);
    });

    toastr.options = {
      closeButton: true,
      debug: false,
      positionClass: 'toast-bottom-right',
      onclick: null,
      showDuration: '1000',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    Vue.loadValidationLanguage();
    Vue.loadDataTableLanguage();
    Vue.setMomentDefaultLanguage();
    Vue.loadAsteriks();
    // Vue.metronic();
    Vue.metronLink();
    Vue.justNumber();
    Vue.loadSidebarSearch();
    Vue.modalDatePickerPlace();
    Vue.checkVersion();
  },
  methods: {
    hashChangeHandler() {
      const target = window.location.hash;
      this.$router.push(target.substring(1, target.length));
    },
  },
  watch: {
    $route(to, from) {
      // close all opened select2
      $('.select2-hidden-accessible').select2('close');
      if ($('.modal-content').is(':visible')) $('.modal').modal('toggle');
      window.$(document).ready(() => {
        Vue.loadValidationLanguage();
        Vue.loadDataTableLanguage();
        Vue.setMomentDefaultLanguage();
        Vue.loadAsteriks();
        // Vue.metronic();
        Vue.metronLink(to, from);
        Vue.renderMenuItems();
        Vue.justNumber();
        Vue.loadSidebarSearch();
        Vue.modalDatePickerPlace();
        Vue.checkVersion();
        $('.popover, .m-popover, .m-tooltip, .tooltip, .datetimepicker.dropdown-menu,  .datepicker.dropdown-menu, .timepicker.dropdown-menu').remove();
        $('.modal-backdrop').remove();
        swal.close();
      });
    },
  },
  destroyed() {
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      window.removeEventListener('hashchange', this.hashChangeHandler);
    }
  },
  async created() {
    if (!String.prototype.formatString) {
      String.prototype.formatString = function () {
        const args = arguments;
        return this.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined'
          ? args[number]
          : match));
      };
    }

    if (!String.prototype.replaceAll) {
      String.prototype.replaceAll = function replaceAll(search, replace) { return this.split(search).join(replace); };
    }
  },
};
</script>
