import Vue from 'vue';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';

export default {
  props: ['securityItem', 'isUpdate', 'isChild'],
  data() {
    return {
      selectedSecurityItem: {},
      itemTypeList: [],
    };
  },
  mounted() {
    const self = this;
    window.$('form').validate();
    api.getSecurityItemTypes().then((res) => {
      self.itemTypeList = res.body;
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        api.saveSecurityItem(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            $('#security_item_modal').modal('toggle');
            self.reloadDataTable();
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    reloadDataTable() {
      blockui.blockElement(this, '.m-portlet');
      api.getSecurityItemList().then((response) => {
        const dt = $('.security_item_list').DataTable();
        dt.clear();
        dt.rows.add(response.body.Data);
        dt.draw();
        blockui.unBlockElement('.m-portlet');
      });
    },
    closeModal() {
      $('#security_item_modal').modal('toggle');
    },
  },
  watch: {
    securityItem(e) {
      this.selectedSecurityItem = e;
    },
  },
};
