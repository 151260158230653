import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import Select2 from '../../components/shared/select2';
import * as blockui from '../../helpers/block-ui';
import * as dateFormat from '../../helpers/dateFormat';
import { mixinz } from '../global/mixinz';

export default {
  props: ['userTraining', 'isUpdate'],
  mixins: [mixinz],
  data() {
    return {
      continent: {
        Value: '',
      },
      country: {
        Value: '',
      },
      selectedUserTraining: {
        IssueDate: '',
      },
      /* pickerDate: {
                format: 'DD.MM.YYYY',
            }, */
      instructorList: [],
      userListByRoles: {},
      isSpecificUser: false,
    };
  },
  components: {
    select2: Select2,
  },
  computed: {
    ...mapState({
      userList: (state) => state.user.userList,
      trainingList: (state) => state.training.trainings,
    }),
    selectedDate: {
      get() {
        const self = this;
        if (self.selectedUserTraining.IssueDate) {
          return moment(self.selectedUserTraining.IssueDate).format('DD.MM.YYYY');
        }
        return '';
      },
      set(newVal) {
        const self = this;
        Vue.set(self.selectedUserTraining, 'IssueDate', moment(newVal, 'DD.MM.YYYY').toDate());
      },
    },
    trainings() {
      return this.trainingList.map((training) => ({ Text: training.Name, Value: training.Id }));
    },
  },
  created() {
    const self = this;
    self.isSpecificUser = !!self.userTraining.UserId;
    if (self.userTraining.TrainingId) {
      self.getInstructorList(self.userTraining.TrainingId);
    }
    self.selectedUserTraining = self.userTraining;
  },
  async mounted() {
    const self = this;
    window.$('form').validate();
    self.$nextTick(() => {
      Vue.loadAsteriks();
    });

    const userList = (await this.$globalApiClient.user.userGetUserListWithoutIncludes()).data;
    userList.forEach((user) => {
      if (!self.userListByRoles[user.RoleId]) {
        self.userListByRoles[user.RoleId] = [];
      }
      self.userListByRoles[user.RoleId].push({ Text: user.NameSurname, Value: user.Id });
    });

    this.$store.dispatch('getUserList');
    this.$store.dispatch('getTrainings');
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        formData.IssueDate = dateFormat.postDateTimeFormatFromDateObject(self.selectedUserTraining.IssueDate);
        api.saveUserTraining(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            // self.reloadDataTable();
            self.$store.dispatch('getUserTrainingList', self.userTraining.UserId);
            self.closeModal();
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    reloadDataTable() {
      Vue.getDatatables();
    },
    closeModal() {
      $('#user_training_modal').modal('toggle');
      this.$emit('close');
    },
    getInstructorList(selectedTrainingId) {
      const self = this;
      Vue.set(self, 'instructorList', []);
      self.$nextTick(() => {
        const selectedTraining = self.trainingList.find((training) => training.Id == selectedTrainingId);
        if (selectedTraining) {
          Vue.set(self, 'instructorList', self.userListByRoles[selectedTraining.RoleId]);
        }
      });
    },
  },
};
