import * as api from '../../scripts/services/api';

const state = {
  userExcuseList: [],
  excuseTypes: []
};

// getters
const getters = {};

// actions
const actions = {
  async getUserExcuseList({ commit }, userId) {
    const userExcuseList = (await api.getUserExcuseList({ userId })).body;
    debugger;
    commit('setUserExcuseList', userExcuseList);
  },
  async getExcuseTypeList({ commit }) {
    return await api.getExcuseTypeList().then((res) => {
        commit('setExcuseTypeList', res.body.Data);
    });
  },
};

// mutations
const mutations = {
  setUserExcuseList(state, userExcuses) {
    state.userExcuseList = userExcuses;
  },
  setExcuseTypeList(state, excuseTypes) {
    state.excuseTypes = excuseTypes;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};