import * as api from '../../scripts/services/api';

// initial state
const state = {
  referenceLibraryList: [],
  financialModalReferenceLibraryList: [],
};

// getters
const getters = {};

// actions
const actions = {
  getReferenceLibraryList({ commit }, data) {
    api.getReferenceLibraryList(data).then((res) => {
      commit('setReferenceLibraryList', res.body.Data);
    });
  },
  getFinancialModalReferenceLibraryList({ commit }, data) {
    api.getReferenceLibraries(data).then((res) => {
      commit('setFinancialModalReferenceLibraryList', res.body);
    });
  },
};

// mutations
const mutations = {
  setReferenceLibraryList(state, referenceLibrary) {
    state.referenceLibraryList = referenceLibrary;
  },
  setFinancialModalReferenceLibraryList(state, referenceLibrary) {
    state.financialModalReferenceLibraryList = referenceLibrary;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
