








































import { Component } from 'vue-property-decorator';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage';

import * as swal from '@/helpers/swal';
import * as blockui from '@/helpers/block-ui';
import * as dateFormat from '@/helpers/dateFormat';

import { CFMModelPendingIncidentApprovalsNewView } from '@/scripts/services/ClientApiAuto';

@Component<PendingApprovalIncident>({
    components: {

    }
})

export default class PendingApprovalIncident extends CFMGridPage<CFMModelPendingIncidentApprovalsNewView> {
    entityType = "PendingIncidentApproval";
    sorts : { field:string, direction:string }[] = [{ field:"Id", direction:"Descending" }];
    headerText = "";
    orderStatusId?:number;
    isAction?:boolean;
    statusId?: number;
    commandPreview = [{ buttonOption : {iconCss : "e-icons e-preview-icon" }, title : 'Ön İzleme'}];
    commandClosed = [{ buttonOption : {iconCss : "e-icons e-approve-icon" }, title : 'Kapalı'}];
    commandClosedIncompleted = [{ buttonOption : {iconCss : "e-icons e-circle-check" }, title : 'Kapalı Tamamlanmadı'}];

    created() {
        this.toolbar = [
            { text: 'Excel Export', tooltipText: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport' }, 
            { text: 'CSV Export', tooltipText: 'CSV Export', prefixIcon: 'e-csvexport', id: 'CSVExport' }, 
            { text: 'PDF Export', tooltipText: 'PDF Export', prefixIcon: 'e-pdfexport', id: 'PDFExport'}
        ];
    };

    async mounted() {
        this.dataStateChange(this.state);
    };

    refreshGrid() {
        this.dataStateChange(this.state);
    };

    recordDoubleClick(args:any) {
        this.$router.push({
            name:'MyWorkOrder', 
            query:{
                from:'PendingApprovalWorkOrder',
                workOrderId: String(args.rowData.Id)
            }
        });
    };

    convertToDay(field:any,data:any,column:any) {
        return dateFormat.formatMinute(data[field],this);  
    };

    async commandClick(args: any) {
        const self = this;
        if (args.target.children[0].classList.contains("e-preview-icon")) { // Gözat
            const router = { name: 'MyWorkOrder', query: { from: 'PendingApprovalIncident', 'workOrderId': args.rowData.Id } };
            const routeData = this.$router.resolve(router);
            window.open(routeData.href, '_blank');
        }
        else if (args.target.children[0].classList.contains("e-approve-icon")) { // Kapali (11)
            blockui.pageBlock();
            await this.updateIncidentStatus(Number(args.rowData.Id), 11);
        }
        else { // Kapalı Tamamlanmadı (12)
            blockui.pageBlock();
            await this.updateIncidentStatus(Number(args.rowData.Id), 12);
        }
    };

    async toolbarClick(args: ClickEventArgs){
        this.toolbarClickBase(args);
    };

    async changeStatusWorkOrderControl(myWorkOrderId: number): Promise<boolean> {
        //@ts-ignore
        const response = await this.$globalApiClient.workOrderSparePart.anyNotReturnedSparePart({ workOrderId: myWorkOrderId });
        //@ts-ignore
        if (response.data.Data == true) {
            toastr.error(this.translate('spare_part_sent_for_confirm'));
            return false;
        }
        return true;
    }

    async updateIncidentStatus(workOrderId: number, workOrderStatusId: number) {
        const self = this;
        const isSparePartReturnedCheck = await this.changeStatusWorkOrderControl(workOrderId);
        if (isSparePartReturnedCheck) {
            this.$globalApiClient.workOrder.workOrderUpdateStatus({
                Id: workOrderId,
                OrderStatusId: workOrderStatusId,
            }).then((response) => {
                if (response.data.IsSuccess) {
                    toastr.success(self.translate('save_successfully'));
                    self.refreshGrid();
                } 
                else {
                    const errorMessage = response.data.Message ? response.data.Message : "Error";
                    toastr.error(errorMessage);
                }
            });
        }

        blockui.unBlockPage();
    }
}
