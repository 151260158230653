import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import Select2 from '../../components/shared/select2';
import * as blockui from '../../helpers/block-ui';
import * as dateFormat from '../../helpers/dateFormat';
import { mixinz } from '../global/mixinz';

export default {
  props: ['userExcuse', 'isUpdate'],
  mixins: [mixinz],
  data() {
    return {
      selectedUserExcuse: {
        StartDate: '',
        EndDate: ''
      }
    };
  },
  components: {
    select2: Select2,
  },
  computed: {
    ...mapState({
        excuseTypeList: (state) => state.excuse.excuseTypes
    }),
    selectedStartDate: {
      get() {
        const self = this;
        if (self.selectedUserExcuse.StartDate) {
          return moment(self.selectedUserExcuse.StartDate).format('DD.MM.YYYY');
        }
        return '';
      },
      set(newVal) {
        const self = this;
        Vue.set(self.selectedUserExcuse, 'StartDate', moment(newVal, 'DD.MM.YYYY').toDate());
      },
    },
    selectedEndDate: {
        get() {
          const self = this;
          if (self.selectedUserExcuse.EndDate) {
            return moment(self.selectedUserExcuse.EndDate).format('DD.MM.YYYY');
          }
          return '';
        },
        set(newVal) {
          const self = this;
          Vue.set(self.selectedUserExcuse, 'EndDate', moment(newVal, 'DD.MM.YYYY').toDate());
        },
    },
    excuseTypes() {
        return this.excuseTypeList.map((excuseType) => ({ Text: excuseType.Name, Value: excuseType.Id }));
    },
  },
  async created() {
    const self = this;
    await this.$store.dispatch('getExcuseTypeList');
    self.selectedUserExcuse = self.userExcuse;
    debugger;
  },
  async mounted() {
    const self = this;
    window.$('form').validate();
    self.$nextTick(() => {
      Vue.loadAsteriks();
    });
    debugger;
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        formData.StartDate = dateFormat.postDateTimeFormatFromDateObject(self.selectedUserExcuse.StartDate);
        formData.EndDate = dateFormat.postDateTimeFormatFromDateObject(self.selectedUserExcuse.EndDate);
        debugger;
        if(self.selectedUserExcuse.StartDate > self.selectedUserExcuse.EndDate) {
            toastr.error(this.translate('end_date_cannot_be_before_start_date'));
            blockui.unBlockModal();
        }
        else {
            api.saveUserExcuse(formData).then((response) => {
                if (response.body.IsSuccess) {
                  toastr.success(self.translate('save_successfully'));
                  self.$store.dispatch('getUserExcuseList', self.userExcuse.UserId);
                  self.closeModal();
                } else {
                  toastr.error(response.body.Message);
                }
                blockui.unBlockModal();
              }, (error) => {
                blockui.unBlockModal();
              });
        }
      }
    },
    reloadDataTable() {
      Vue.getDatatables();
    },
    closeModal() {
      $('#user_excuse_modal').modal('toggle');
      this.$emit('close');
    }
  },
};
