<template>
  <input type="text" :name="name" :id="id" class="date-picker" :class="classNames" ref="datepicker">
</template>
<script>
export default {
  props: {
    value: {},
    format: {
      default: null,
    },
    autoclose: {
      type: Boolean,
      default: true,
    },
    pickerPosition: {
      type: String,
      default: 'top-right',
    },
    startDate: {},
    endDate: {},
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    classNames: {
      type: String,
    },
  },
  data() {
    return {};
  },
  mounted() {
    const self = this;
    const constructorParameters = { ...(self.format === null ? null : { format: self.format }) };
    const datepickerInstance = $(this.$refs.datepicker).datepicker({
      ...constructorParameters,
      autoclose: self.autoclose,
      language: localStorage.getItem('vue-lang'),
      pickerPosition: self.pickerPosition,
      endDate: self.endDate,
      startDate: self.startDate,
    }).on('changeDate', (e) => {
      self.$emit('update:value', self.isValidDate(e.date) ? e.date : undefined);
    });
    if (self.value) {
      datepickerInstance.datepicker('update', self.value);
    }
  },
  methods: {
    isValidDate(d) {
      return !isNaN(new Date(d));
    },
  },
  watch: {
    value(e) {
      $(this.$refs.datepicker).datepicker('update', new Date(e));
    },
    startDate(e) {
      if (e) {
        $(this.$refs.datepicker).datepicker('setStartDate', new Date(e));
      } else {
        $(this.$refs.datepicker).datepicker('setStartDate', false);
      }
    },
    endDate(e) {
      if (e) {
        $(this.$refs.datepicker).datepicker('setEndDate', new Date(e));
      } else {
        $(this.$refs.datepicker).datepicker('setEndDate', false);
      }
    },
  },
};
</script>
