import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import * as swal from '../../helpers/swal';
import * as blockui from '../../helpers/block-ui';
import * as datatable from '../global/datatables';
import ProcedureModal from '../../components/partials/modals/ProcedureModal';

export default {
  data() {
    return {
      filterProcedureType: {
        Value: '',
      },
      selectedProcedure: {},
      isUpdate: false,
      showProcedureModal: false,
    };
  },
  components: {
    'procedure-modal': ProcedureModal,
  },
  computed: {
    ...mapState({
      procedureTypeList: (state) => state.procedure.procedureTypeList,
    }),
  },
  mounted() {
    const self = this; let
      table;

    this.$store.dispatch('getProcedureTypeList');

    const columns = [{
      data: 'Id',
      title: 'ID',
      sortable: true,
      width: 40,
      defaultContent: '',
    }, {
      data: 'Name',
      title: this.translate('procedure'),
      sortable: 'asc',
      width: 200,
      defaultContent: '',
    }, {
      data: 'ProcedureType.Name',
      title: this.translate('procedure_type'),
      sortable: 'asc',
      width: 150,
      defaultContent: '',
    }, {
      data: '',
      title: this.translate('file'),
      width: 300,
      defaultContent: '',
      render: (data, type, row) => {
        if (row.File) {
          return ` <a href="javascript:;" id="download_button" class="btn btn-outline-info">
                        <i class="m-nav-grid__icon flaticon-download"></i>
                        <span class="m-nav-grid__text">${row.File.Name}</span>
                      </a>`;
        }
        return '';
      },
    }];
    const datatableContainer = '.procedure_list';

    Vue.getDatatables = (data) => {
      blockui.blockElement(self, '.m-portlet');
      const isDataTable = $.fn.DataTable.isDataTable(datatableContainer);
      api.getProcedureList(data).then((response) => {
        if (isDataTable) {
          const dt = $(datatableContainer).DataTable();
          dt.clear();
          dt.rows.add(response.body.Data);
          dt.draw();
        } else {
          table = datatable.renderTable({
            exportFileName: self.translate('procedure'),
            container: datatableContainer,
            data: response.body.Data,
            columns,
            paging: true,
            sortable: true,
            editButton: true,
            deleteButton: true,
          });
        }
        blockui.unBlockElement('.m-portlet');
      }, (error) => {
        blockui.unBlockElement('.m-portlet');
      });
    };

    Vue.getDatatables();

    window.$(datatableContainer).on('click', '.edit-button', function () {
      blockui.blockModal(self);
      const dt = $(datatableContainer).DataTable();
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const id = row.data().Id;
      api.getProcedure(id).then((res) => {
        self.selectedProcedure = res.body;

        blockui.unBlockModal();
      });
      self.isUpdate = true;
      self.selectedProcedure = {};
      self.showProcedureModal = true;
      self.$nextTick(() => {
        const modalContainer = window.$('#procedure_modal');
        modalContainer.modal({
          backdrop: 'static',
          show: true,
        });
        modalContainer.find('form').data('type', 'update');
      });
    });

    window.$('#add_procedure_btn').on('click', () => {
      self.showProcedureModal = true;
      self.selectedProcedure = {};
      self.isUpdate = false;
      self.$nextTick(() => {
        const modalContainer = window.$('#procedure_modal');
        modalContainer.modal({
          backdrop: 'static',
          show: true,
        });
        modalContainer.find('form').data('type', '');
      });
    });

    window.$(datatableContainer).on('click', '.delete-button', function () {
      const dt = $(datatableContainer).DataTable();
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const data = row.data();
      swal.check(self, data.Name).then((e) => {
        if (e.value) {
          blockui.blockElement(self, '.m-portlet');
          api.deleteProcedure(data.Id).then((response) => {
            if (response.body.IsSuccess) {
              row.remove().draw('full-hold');
            } else {
              toastr.error(response.body.Message);
            }
            blockui.unBlockElement('.m-portlet');
          }, (err) => {
            blockui.unBlockElement('.m-portlet');
          });
        }
      });
    });

    window.$(datatableContainer).on('click', '#download_button', function () {
      const id = $(datatableContainer).DataTable().row(window.$(this).closest('tr')).data().FileId;
      self.downloadFile(id);
    });
  },
  methods: {
    downloadFile(id) {
      const self = this; let
        table;
      blockui.unBlockPage();
      api.getFile({ id, isContainsData: true }).then((response) => {
        self.downloadBlob(self.base64toBlob(response.body.Data.Base64, response.body.Data.ContentType), response.body.Data.Name);
        blockui.unBlockPage();
      });
    },
    base64toBlob(base64Data, type) {
      type = type || '';
      // Convert the Base64 string back to text.
      const byteString = atob(base64Data);

      // Convert that text into a byte array.
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type });
    },
    downloadBlob(blob, fileName) {
      const a = $("<a style='display: none;'/>");
      const url = window.URL.createObjectURL(blob);
      a.attr('href', url);
      a.attr('download', fileName);
      $('body').append(a);
      a[0].click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    onFilter(e) {
      e.preventDefault();
      const form = $(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        Vue.getDatatables(formData);
      }
    },
  },
};
