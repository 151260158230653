import * as api from '../../scripts/services/api';

// initial state
const state = {
  languages: [],
};

// getters
const getters = {};

// actions
const actions = {
  async getLanguagesList({ commit }) {
    await api.getLanguages().then((res) => {
      commit('setLanguagesList', res.body);
    });
  },
};

// mutations
const mutations = {
  setLanguagesList(state, languages) {
    state.languages = languages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
