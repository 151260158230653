
























import { Component } from 'vue-property-decorator';
import { DataStateChangeEventArgs } from "@syncfusion/ej2-vue-grids";
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMDTOQualification } from '@/scripts/services/ClientApiAuto'
import QualificationDialog from './QualificationDialog.vue'


@Component<Qualification>({
   
    components: {
        'qualification-dialog': QualificationDialog,
    }
})
export default class Qualification extends CFMGridPage<CFMDTOQualification> {
    entityType = "Qualification";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];
    expandProperties = ["QualificationType/Name"];

    async mounted(){
        await this.dataStateChange(this.state);
    }
    refreshGrid(){
        this.dataStateChange(this.state);
    }
    recordDoubleClick(args:any){
        this.edit(args.rowData.Id);
    }
    async delete(data: CFMDTOQualification){
        const e = await swal.check(this, data.Name);
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.qualification.qualificationDelete({id: data.Id});
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }
    async edit(id: number){
        this.selectedId = id;
        this.isDialogActive = true;
        await this.$nextTick();
        (<QualificationDialog>this.$refs.qualificationDialog).showDialog();
    }
    async add(){
        this.selectedId = null;
        this.isDialogActive = true;
        await this.$nextTick();
        (<QualificationDialog>this.$refs.qualificationDialog).showDialog();
    }
    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id);
            }
        }
        else if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
      this.toolbarClickBase(args);
    }
}
