import Vue from 'vue';
import * as api from '../../scripts/services/api';

const state = {
  workOrderForms: [],
};

// getters
const getters = {
  getWorkOrderForms: (state) => state.workOrderForms,
};

const actions = {
  async getWorkOrderFormsByWorkOrderIdAndFormId({ commit }, { workOrderId, formId }) {
    await api.getFormStatusSummary(workOrderId, formId).then((response) => {
      if (response.body) {
        commit('setWorkOrderForm', { workOrderId, form: response.body });
      }
    });
  },
  async getWorkOrderFormsByWorkOrderId({ commit, state }, { workOrderId }) {
    // if (!state.workOrderForms.find(x => x.workOrderId == workOrderId)) {
    
    
    await this._vm.$globalApiClient.workOrder.workOrderGetFormStatusSummaryList({workOrderId}).then((response) => {
      if (response.data) {
        commit('setWorkOrderForms', { workOrderId, forms: response.data });
      }
    });
    // }
  },
};

const mutations = {
  setWorkOrderForms(state, { workOrderId, forms }) {
    const workOrderIndex = state.workOrderForms.findIndex((x) => x.workOrderId == workOrderId);
    if (workOrderIndex > -1) {
      Vue.set(state.workOrderForms, workOrderIndex, {
        workOrderId,
        forms,
      });
    } else {
      state.workOrderForms.push({ workOrderId, forms });
    }
  },
  setWorkOrderForm(state, { workOrderId, form }) {
    const workOrderForms = state.workOrderForms.find((x) => x.workOrderId == workOrderId);
    if (workOrderForms) {
      const formIndex = workOrderForms.forms.findIndex((formItem) => formItem.Id == form.Id);
      if (formIndex > -1) {
        Vue.set(workOrderForms.forms, formIndex, form);
        const currentForms = workOrderForms.forms.filter((formItem) => formItem.IsCurrentForm == true);
        if (currentForms && currentForms.length > 1) {
          const previousCurrentForm = currentForms.find((formItem) => formItem.Id != form.Id);
          Vue.set(previousCurrentForm, 'IsCurrentForm', false);
        }
      } else {
        workOrderForms.forms.push(form);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
