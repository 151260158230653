import Vue from 'vue';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';

export default {
  props: ['action_group', 'isUpdate', 'isChild'],
  data() {
    return {
      selectedActionGroup: {},
    };
  },
  mounted() {
    window.$('form').validate();
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        api.saveSecurityActionGroup(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            $('#action_group_modal').modal('toggle');
            self.reloadDataTable();
            // self.$store.dispatch('getRelatedContinentList', self.$router.history.current.name);
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    reloadDataTable() {
      Vue.getDatatables();
    },
    closeModal() {
      $('#action_group_modal').modal('toggle');
    },
  },
  watch: {
    action_group(e) {
      this.selectedActionGroup = e;
    },
  },
};
