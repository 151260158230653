import Vue from 'vue';
import VJstree from 'vue-jstree-promise';
import VueRightClick from 'vue-right-click-scroll-pinsoft';
import { minValue, required } from 'vuelidate/lib/validators';
import * as api from '../../../../../scripts/services/api';
import * as swal from '../../../../../helpers/swal';
import * as blockui from '../../../../../helpers/block-ui';

export default {
  props: {
    maxHeight: {
      type: String,
    },
    height: {
      default: '100%',
      type: String,
    },
    initNode: Object,
    invalid: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isUpdate: false,
      nodes: [],
      newNode: '',
      selectedNodeName: '',
      editingItem: {},
      editingNode: null,
      rootNodeText: 'Root',
    };
  },
  components: {
    'v-jstree': VJstree,
    'vue-right-click': VueRightClick,
  },
  mounted() {
  },
  methods: {
    getRightClickItems(value) {
      if (value > 0) {
        return [{
          id: 1,
          name: this.translate('add_child_to_item'),
          action: 'add',
        }, {
          id: 2,
          name: this.translate('edit_item'),
          action: 'edit',
        }, {
          id: 3,
          name: this.translate('delete_item'),
          action: 'delete',
        }];
      }
      return [{
        id: 1,
        name: this.translate('add_child_to_item'),
        action: 'add',
      }];
    },
    // loads async data when expand tree items
    loadData(oriNode, resolve) {
      const self = this;
      let nodes = [];

      if (oriNode.data.value === undefined) {
        this.$nextTick(() => {
          nodes = [{
            text: self.rootNodeText,
            value: null,
            isLeaf: false,
            opened: true,
            disabled: true,
            Children: [],
            icon: 'fa fa-warehouse',
          }];
          resolve(nodes);
        });
      } else {
        api.getWarehouseList({ parentId: oriNode.data.value }).then((response) => {
          nodes = response.body.Data.map((warehouse) => ({
            text: warehouse.Name,
            value: warehouse.Id,
            isLeaf: !(warehouse.Children.length > 0),
            icon: 'fa fa-warehouse',
            opened: false,
            selected: self.initNode && self.initNode.Id === warehouse.Id,
          }));

          if (oriNode.data.value === null && oriNode.data.text === self.rootNodeText && self.initNode) {
            let tempObj = self.initNode;
            while (tempObj.ParentId) tempObj = tempObj.Parent;
            const parentNode = nodes.find((x) => x.value === tempObj.Id);
            if (parentNode) {
              parentNode.opened = self.initNode.Id !== parentNode.value;
              self.traverseTree(parentNode, tempObj);
            }
          }
          resolve(nodes);
        });
      }
    },
    traverseTree(node, obj) {
      const self = this;
      obj.Children.forEach((child) => {
        let temp = child;
        const ids = [];
        if (!child.Id) {
          temp = self.initNode;
          while (temp.ParentId && temp.$id !== child.$ref) {
            temp = temp.Parent;
            ids.push(temp.Id);
          }
        }
        node.children = node.children || [];
        node.children.push({
          text: temp.Name,
          value: temp.Id,
          isLeaf: !(temp.Children.length > 0),
          icon: 'fa fa-warehouse',
          children: [],
          opened: ids.includes(temp.Id),
          selected: self.initNode.Id === temp.Id,
        });
        self.traverseTree(node.children[node.children.length - 1], temp);
      });
    },
    // on tree item clicked
    itemClick(node) {
      const self = this;
      self.editingNode = node;
      self.editingItem = node.model;
      self.$emit('treeItemSelected', { Id: node.model.value, Name: node.model.text });
    },
    // on tree item delete button clicked
    deleteButtonClick(node, item, e) {
      this.deleteItem(item, node);
    },
    // item drop before
    itemDropBefore(node, item, draggedItem, e, resolve) {
      const self = this;
      let newItem = {};
      if (!draggedItem) {
        newItem = {
          Id: 0,
          Name: self.newNode,
          ParentId: node.data.value,
        };
      } else {
        newItem = {
          Id: draggedItem.value,
          Name: draggedItem.text,
          ParentId: node.data.value,
        };
      }
      blockui.blockElement(self, '.m-portlet');
      api.saveWarehouse(newItem).then((res) => {
        if (res.body.IsSuccess) {
          resolve(true);
          if (!draggedItem) {
            item.addChild({
              text: res.body.Data.Name,
              value: res.body.Data.Id,
              isLeaf: true,
              icon: 'fa fa-warehouse',
            });
          }
        } else {
          if (draggedItem) {
            resolve(false);
          }
          toastr.error(res.body.Message);
        }
      }).finally(() => {
        blockui.unBlockElement('.m-portlet');
      });
    },
    // item drop
    itemDrop(node, item) {
      const sortBy = function (attr, rev) {
        if (rev == undefined) {
          rev = 1;
        } else {
          rev = (rev) ? 1 : -1;
        }
        return function (a, b) {
          a = a[attr];
          b = b[attr];
          if (a < b) {
            return rev * -1;
          }
          if (a > b) {
            return rev * 1;
          }
          return 0;
        };
      };
      item.children.sort(sortBy('text', true));
    },
    // handle right click menu item click
    handleContexMenuItemClick(action, item) {
      if (action === 'delete') {
        this.deleteItem(item.model, item.vm);
      } else if (action === 'add') {
        this.addItem(item.model);
      } else if (action === 'edit') {
        this.editItem(item.model, item.vm);
      }
    },
    // add item
    async addItem(item) {
      const self = this;
      try {
        const name = await this.askName('', self.translate('add_child_to_item'));
        if (item.id !== undefined) {
          blockui.blockElement(self, '.m-portlet');
          const newItem = { Id: 0, Name: name, ParentId: item.value };
          api.saveWarehouse(newItem).then((res) => {
            if (res.body.IsSuccess) {
              item.addChild({
                text: res.body.Data.Name,
                value: res.body.Data.Id,
                isLeaf: true,
                icon: 'fa fa-warehouse',
              });
            } else {
              toastr.error(res.body.Message);
            }
          }).finally(() => {
            blockui.unBlockElement('.m-portlet');
          });
        }
      } catch (e) {

      }
    },
    // edit item
    async editItem(item, node) {
      const self = this;
      try {
        const name = await this.askName(item.text, self.translate('edit_item'));
        if (item.id !== undefined && item.value > 0) {
          blockui.blockElement(self, '.m-portlet');
          const newItem = {
            Id: item.value,
            Name: name,
            ParentId: node.$parent.data.value,
          };
          api.saveWarehouse(newItem).then((res) => {
            if (res.body.IsSuccess) {
              item.text = name;
            } else {
              toastr.error(res.body.Message);
            }
          }).finally(() => {
            blockui.unBlockElement('.m-portlet');
          });
        }
      } catch (e) {

      }
    },
    // delete item
    deleteItem(item, node) {
      const self = this;
      if (item.id !== undefined && item.value > 0) {
        swal.check(self, node.data.text).then((e) => {
          if (e.value) {
            blockui.blockElement(self, '.m-portlet');
            api.deleteWarehouse(node.data.value).then((response) => {
              if (response.body.IsSuccess) {
                toastr.success(self.translate('delete_successfully'));
                const index = node.parentItem.indexOf(item);
                node.parentItem.splice(index, 1);
                /* if (node.parentItem.length === 0) {

                                } */
              } else {
                toastr.error(response.body.Message);
              }
            }).finally(() => {
              blockui.unBlockElement('.m-portlet');
            });
          }
        });
      }
    },
    // ask name modal returns promise that give name and takes initial name (optional)
    askName(name, title) {
      const self = this;
      return new Promise((resolve, reject) => {
        const AskNameModal = Vue.extend({
          template: `<div tabindex="-1" class="modal modal-side fade" id="name_modal" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
               <div class="modal-header">
                    <h5 class="modal-title">{{title}}</h5>
                    <button type="button" class="close" @click="closeModal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form action="" method="post" @submit.prevent="onSubmit" novalidate="novalidate">
                    <div class="modal-body">
                        <div class="form-group m-form__group" :class="{ 'form-group--error': $v.selectedName.$error,'has-danger': $v.selectedName.$error}">
                            <label><span v-lang.name></span><span class="red">*</span></label>
                            <input v-model="selectedName" type="text" name="Name" class="form-control m-input" required="required" autocomplete="off" />
                            <template v-if="$v.selectedName.$error">
                                <div v-if="!$v.selectedName.required"
                                     class="form-control-feedback error"
                                     v-lang.this_field_is_required></div>
                            </template>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal" v-lang.cancel></button>
                        <button type="submit" class="btn btn-primary" v-lang.save></button>
                    </div>
                </form>
            </div>
        </div>
    </div>`,
          data() {
            return {
              selectedName: '',
            };
          },
          props: ['name', 'title'],
          validations() {
            return {
              selectedName: {
                required,
              },
            };
          },
          methods: {
            onSubmit() {
              const self = this;
              this.$v.$touch();
              if (this.$v.$invalid) {
                return;
              }
              this.$emit('name', this.selectedName);
            },
            closeModal() {
              const self = this;
              $(self.$el).modal('toggle');
            },
          },
          mounted() {
            const self = this;
            this.selectedName = this.name;
            $(self.$el).on('hidden.bs.modal', () => {
              self.$nextTick(() => {
                this.$emit('modalClosed');
              });
            });
          },
          watch: {
            name(newVal) {
              this.selectedName = newVal;
            },
          },
        });
        const askNameModal = new AskNameModal({
          propsData: {
            name,
            title,
          },
        }).$mount();
        document.body.appendChild(askNameModal.$el);
        // document.getElementById('warehouse').appendChild(askNameModal.$el);
        self.$nextTick(() => {
          $('#name_modal').modal({
            show: true,
            backdrop: 'static',
          });
          askNameModal.$once('name', (name) => {
            $(askNameModal.$el).off('hidden.bs.modal');
            $(askNameModal.$el).on('hidden.bs.modal', () => {
              self.$nextTick(() => {
                askNameModal.$destroy();
                $(askNameModal.$el).remove();
                resolve(name);
              });
            });
            $(askNameModal.$el).modal('toggle');
          });
          askNameModal.$once('modalClosed', () => {
            askNameModal.$destroy();
            $(askNameModal.$el).remove();
            reject();
          });
        });
      });
    },
  },
  watch: {
    editingNode(newVal) {
      this.selectedNodeName = newVal.data.text;
    },
  },
};
