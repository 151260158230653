


































































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import colorpicker from '@/components/shared/colorpicker.vue';
import timepicker from '@/scripts/global/timepicker';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import {CFMDTOShiftType, CFMDTOColor } from '@/scripts/services/ClientApiAuto'

@Component<ShiftTypeDialog>({
    components: {
        'colorpicker': colorpicker,
        'timepicker': timepicker,
    },
    validations() {
        return {
          shiftType:{
              Name: {required},
              StartTime:{required},
              EndTime:{required},
              ColorId: {required}
          }
        }
    },
})
export default class ShiftTypeDialog extends CFMDialog {
    shiftType: CFMDTOShiftType = {Name: ''};
    colorList: CFMDTOColor[] = [];

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.shiftType.shiftTypeGetById({id: <number>this.id});
        this.shiftType = res.data;
      }
      const promises = [
        this.getColorList()
      ];
      blockui.unBlockModal();
    }

    onColorChanged(color: CFMDTOColor) {
      this.shiftType.ColorId = color && color.Id ? color.Id : undefined;
    }

    async getColorList(){
      const response = await this.apiClient.common.commonGetColorList();
      if(!response.data.IsSuccess || !response.data.Data){
        throw new Error(response.data.Message? response.data.Message: response.statusText);
      }
      this.colorList = response.data.Data;
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData:CFMDTOShiftType = {
        Id: this.shiftType.Id,
        Name: this.shiftType.Name,
        ColorId: this.shiftType.ColorId,
        StartTime: this.shiftType.StartTime,
        EndTime: this.shiftType.EndTime
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.shiftType.shiftTypeSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
