import * as api from '../../services/api';
import * as blockui from '../../../helpers/block-ui';
import chart from './chart';
import * as swal from '../../../helpers/swal';

export default {
  mixins: [chart],
  data() {
    return {
      result: 0,
    };
  },
  async mounted() {
  //   let self = this;
  //   await self.getData();
  //   //self.initChart();
  },
  methods: {
    async updateData() {
      const self = this;
      await self.getData();
      // self.redraw();
    },
    async getData() {
      const self = this;
      try {
        const trainingFalseDemands = (await api.getTrainingDemands({
          daysToCheck: self.value,
          isUser: false,
        })).body.Data;
        self.result = trainingFalseDemands.filter((item) => item.Total > 0).length;
      } catch (e) {
        throw new Error(e);
      }
    },
    redraw() {

    },
    redirect() {
      const self = this;
      self.$router.push({ name: 'UserTrainingTracking', query: { trainingNeedToBeEnrolledLessThanDays: self.value } });
    },
  },
};
