import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import * as datatable from '../global/datatables';
import * as dateFormat from '../../helpers/dateFormat';
import * as swal from '../../helpers/swal';
import * as blockui from '../../helpers/block-ui';

export default {
  data() {
    return {
      selectedCapacityEquipment: {},
    };
  },
  components: {},
  computed: {},
  mounted() {
    const self = this; let
      table;

    const columns = [{
      data: 'Id',
      title: 'ID',
      sortable: 'desc',
      width: 20,
      defaultContent: '',
    }, {
      data: 'Description',
      title: this.translate('description'),
      width: 150,
      defaultContent: '',
    }, {
      data: 'Date',
      title: this.translate('time'),
      width: 150,
      defaultContent: '',
      render: (data, type, row) => dateFormat.viewDateFormat(row.Date),
    }, {
      data: 'TotalCount',
      title: this.translate('total_plan'),
      width: 150,
      defaultContent: '',
    }, {
      data: 'TotalDuration',
      title: this.translate('total_duration'),
      width: 150,
      defaultContent: '',
      render: (data, type, row) => dateFormat.formatMinute(row.TotalDuration, self),
    }];

    const datatableContainer = '.mass_work_order';

    Vue.getDatatables = (data) => {
      blockui.blockElement(self, '.m-portlet');
      const isDataTable = $.fn.DataTable.isDataTable(datatableContainer);
      if (!data) {
        const dateRange = $("input[name='DateRange']").val();
        data = {
          startDate: dateFormat.postDateFormat(dateRange.split(' - ')[0].trim()),
          endDate: dateFormat.postDateFormat(dateRange.split(' - ')[1].trim()),
        };
      }
      api.getMassMaintenancePlanList({ ...data, ...{ isIncludeWork: true } }).then((response) => {
        if (isDataTable) {
          const dt = $(datatableContainer).DataTable();
          dt.clear();
          dt.rows.add(response.body.Data);
          dt.draw();
        } else {
          table = datatable.renderTable({
            exportFileName: self.translate('mass_work_order'),
            container: datatableContainer,
            data: response.body.Data,
            columns,
            paging: true,
            sortable: true,
            expandable: true,
            editButton: true,
            deleteButton: true,
            childAddButton: true,
            childAddButtonIcon: 'fa-exchange-alt',
            childAddButtonTooltip: self.translate('transform_to_work_order'),
            // extraButtons: [{
            //   display: () => true,
            //   class: 'preview-button',
            //   buttonClass: '',
            //   tooltip: self.translate('show'),
            //   icon: 'fa-fw fa-eye',
            // },],
          });
        }
      });
      blockui.unBlockElement('.m-portlet');
    };

    moment.locale(localStorage.getItem('vue-lang'));
    const ranges = {};
    ranges[self.translate('today')] = [moment(), moment()];
    ranges[self.translate('tomorrow')] = [moment().add(1, 'days'), moment().add(1, 'days')];
    ranges[self.translate('this_week')] = [moment().startOf('week'), moment().endOf('week')];
    ranges[self.translate('two_week')] = [moment().startOf('week'), moment().add(7, 'days').endOf('week')];
    ranges[self.translate('this_month')] = [moment().startOf('month'), moment().endOf('month')];
    ranges[self.translate('this_year')] = [moment().startOf('year'), moment().endOf('year')];

    let start = {};
    let end = {};
    if (self.$route.query.startDate) {
      start = moment(new Date(parseInt(self.$route.query.startDate)));
    } else {
      start = moment();
    }
    if (self.$route.query.endDate) {
      end = moment(new Date(parseInt(self.$route.query.endDate)));
    } else {
      end = moment().add(7, 'days').endOf('week');
    }

    function cb(start, end) {
      $('#m_daterangepicker .form-control').val(`${dateFormat.viewDateFormat(start.format('MM/DD/YYYY'))} - ${dateFormat.viewDateFormat(end.format('MM/DD/YYYY'))}`);
    }

    $('#m_daterangepicker').daterangepicker({
      locale: {
        applyLabel: self.translate('apply'),
        cancelLabel: self.translate('cancel'),
        customRangeLabel: self.translate('custom_range'),
      },
      autoApply: true,
      buttonClasses: 'm-btn btn',
      applyClass: 'btn-primary',
      cancelClass: 'btn-secondary',
      startDate: start,
      endDate: end,
      maxDate: moment().add(1, 'year'),
      ranges,
    }, cb);

    cb(start, end);

    const filterForm = $("form[name='filterForm']");
    if (filterForm.valid()) {
      const formData = {};
      $.map(filterForm.serializeArray(), (item) => {
        if (item.value != '') {
          if (item.name == 'DateRange') {
            Object.assign(formData, { startDate: dateFormat.postDateFormat(item.value.split(' - ')[0].trim()) });
            Object.assign(formData, { endDate: dateFormat.postDateFormat(item.value.split(' - ')[1].trim()) });
          } else {
            Object.assign(formData, { [item.name]: item.value });
          }
        }
      });
      Vue.getDatatables(formData);
    }

    // Work Order show detail
    window.$(datatableContainer).on('click', 'tbody > tr > td > .preview-button', function () {
      const dt = $(this).closest('table').DataTable();
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const data = row.data();
      self.$router.push({ name: 'MyWorkOrder', query: { from: 'WorkOrderList', workOrderId: data.Id } });
    });

    let tableId = 0;
    // Mass Work Order details
    window.$(datatableContainer).on('click', 'tbody > tr > td > .details-control', function () {
      const dt = $(datatableContainer).DataTable();
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const massMaintenancePlanData = row.data();

      if (row.child.isShown()) {
        row.child.remove();
        tr.removeClass('shown');
      } else {
        tableId += 1;
        row.child(`<h5>${self.translate('work_orders')}</h5><table class='work_order_list${tableId} table table-bordered table-hover'></table>`).show();
        tr.addClass('shown');
        blockui.blockElement(self, '.m-portlet');
        api.getWorkOrderListByMaintenanceGroupId({ maintenanceGroupId: massMaintenancePlanData.Id }).then((res) => {
          const columns = [{
            data: 'Id',
            title: 'ID',
            sortable: 'desc',
            width: 40,
            defaultContent: '',
          }, {
            data: 'BreadCrumb',
            title: `${self.translate('room')} > ${self.translate('capacity_equipment')}`,
            width: 150,
            defaultContent: '',
          }, {
            data: 'WorkName',
            title: self.translate('work'),
            width: 150,
            defaultContent: '',
          }, {
            data: 'OrderStatusName',
            title: self.translate('order_status'),
            width: 150,
            defaultContent: '',
            render: (data, type, row) => {
              let icon;
              icon = `<span class="m-badge m-badge--wide" style="background-color: ${row.ColorCode};color: white;">${row.OrderStatusName}</span>`;
              return `<div style="width: 110px">${icon}</div>`;
            },
          }, {
            data: '',
            title: self.translate('time'),
            width: 150,
            defaultContent: '',
            render: (data, type, row) => dateFormat.viewDateTimeFormat(row.ScheduledTime),
          }];
          if (res.body.Data) {
            datatable.renderTable({
              exportFileName: self.translate('work_order'),
              container: tr.next('tr').find('table'),
              data: res.body.Data,
              columns,
              pagination: false,
              sortable: true,
              expandable: false,
              editButton: false,
              // deleteButton: true,
              /* childAddButton: true,
                            childAddButtonTooltip: self.translate('change_status'),
                            childAddButtonIcon: 'fa-tasks', */
              extraButtons: [{
                display: () => true,
                class: 'preview-button',
                buttonClass: '',
                tooltip: self.translate('show'),
                icon: 'fa-fw fa-eye',
              }],
            });
          }
          blockui.unBlockElement('.m-portlet');
        });
      }
    });

    // Mass Work Order add clicked
    window.$('#add_mass_work_order_btn').on('click', () => {
      self.$router.push({ name: 'MassMaintenancePlanOperations', query: { from: 'MassMaintenancePlan' } });
    });

    // Mass Work Order edit clicked
    window.$(datatableContainer).on('click', 'tbody > tr > td > .edit-button', function () {
      const dt = $(datatableContainer).DataTable();
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const id = row.data().Id;
      self.$router.push({
        name: 'MassMaintenancePlanOperations',
        query: { from: 'MassMaintenancePlan', massWorkOrderId: id },
      });
    });

    // Mass Work Order delete clicked
    window.$(datatableContainer).on('click', 'tbody > tr > td > .delete-button', function () {
      const dt = $(datatableContainer).DataTable();
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const data = row.data();
      swal.checkWithInput(
        self,
        [{
          title: `${self.translate('are_you_sure_delete')}\n${data.Description} - ${dateFormat.viewDateFormat(data.Date)}`,
          text: self.translate('please_enter_a_cancellation_description'),
        }],
        (value) => !value && self.translate('please_fill_all_required_fields'),
      ).then((e) => {
        if (e.value) {
          blockui.blockElement(self, '.m-portlet');
          // TODO AHMET Bug 810
          api.deleteMaintenanceGroup({ Id: data.Id, Description: e.value[0] }).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              row.remove().draw('full-hold');
            } else {
              toastr.error(response.body.Message);
            }
            blockui.unBlockElement('.m-portlet');
          }, (err) => {
            blockui.unBlockElement('.m-portlet');
          });
        }
      });
    });

    // Transform to Work Order clicked
    window.$(datatableContainer).on('click', '.add-new-child-button', function () {
      const id = window.$(this).data('id');
      self.$router.push({ name: 'AddWorkOrder', query: { maintenanceGroupId: id } });
    });
  },
  methods: {
    onFilter(e) {
      e.preventDefault();
      const form = $(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          if (item.value != '') {
            if (item.name == 'DateRange') {
              Object.assign(formData, { startDate: dateFormat.postDateFormat(item.value.split(' - ')[0].trim()) });
              Object.assign(formData, { endDate: dateFormat.postDateFormat(item.value.split(' - ')[1].trim()) });
            } else {
              Object.assign(formData, { [item.name]: item.value });
            }
          }
        });
        Vue.getDatatables(formData);
      }
    },
  },
};
