import { render, staticRenderFns } from "./FormModal.vue?vue&type=template&id=0a22dc54&scoped=true&"
import script from "./FormModal.js?vue&type=script&lang=js&"
export * from "./FormModal.js?vue&type=script&lang=js&"
import style0 from "./FormModal.vue?vue&type=style&index=0&id=0a22dc54&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a22dc54",
  null
  
)

export default component.exports