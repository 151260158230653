import Vue from 'vue';
import { minValue, required } from 'vuelidate/lib/validators';
import * as api from '../../../../scripts/services/api';
import * as blockui from '../../../../helpers/block-ui';
import { mixinz } from '../../../../scripts/global/mixinz';
import TreeSelect from '../TreeSelect/TreeSelect.vue';
import { Editor } from '../../../../scripts/global/editors.js';
import * as swal from '../../../../helpers/swal';

export default {
  mixins: [mixinz],
  props: ['parent'],
  data() {
    return {
      points: [],
      clonePoints: [],
      selectedPoints: [],

      targetEditor: null,
      selectedClonePoint: null,

      modalId: 'mass_point_clone_modal',
      wrapperObj: {
        TargetModel: [],
      },
    };
  },
  async mounted() {
    const self = this;

    this.initializeData();
    $(`#${this.modalId}`).on('shown.bs.modal', () => {
      self.$nextTick(() => {
        mApp.initTooltips();
      });
      // Added for bootstrap modal's input block issue in nested modals.
      $(document).off('focusin.modal');
    });
    $(`#${this.modalId}`).on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
  },
  computed: {
    isChangedAny() {
      return this.clonePoints.some((x) => x.IsSuccess !== true);
    },
    processLabel() {
      const total = this.clonePoints.length;
      return total > 0 ? this.translate('n_of_n_saved').formatString(this.clonePoints.filter((x) => x.IsSuccess === true).length, total) : '';
    },
  },
  methods: {
    async initializeData() {
      const self = this;
      blockui.blockElementPromise(self, self.$el);
      await Promise.all([
        api.getPointList(self.parent).then((res) => {
          self.points = res.body;
        }),
      ]);

      self.targetEditor = Editor({
        type: 'popupVue',
        value: '',
        vueEvents: [
          {
            name: 'input',
            callback: self.selectTarget,
          }, {
            name: 'hide',
            callback: self.hideTargetEditor,
          }],
        vueProps: {
          maxHeight: '300px',
        },
        vueComponent: TreeSelect,
      }, self.targetEditor);

      blockui.unBlockElement(self.$el);
    },
    openTargetEditor(e, item, multiple) {
      if (item.IsSuccess === true) {
        return;
      }
      if (this.selectedClonePoint !== item) {
        this.targetEditor.hide();
      }
      this.selectedClonePoint = item;
      this.targetEditor.forElement = e.target;
      this.targetEditor.changeProp({ value: item.TargetModel, multiple });
      this.targetEditor.show();
    },
    selectTarget(value) {
      Vue.set(this.selectedClonePoint, 'TargetModel', value);
      this.targetEditor.changeProp({ value });
    },
    hideTargetEditor() {
      this.targetEditor.hide();
    },
    addClonePoint() {
      if (this.selectedPoints && this.selectedPoints.length > 0) {
        let newClonePoints = [];
        if (this.wrapperObj.TargetModel && this.wrapperObj.TargetModel.length > 0) {
          for (const selectedTarget of this.wrapperObj.TargetModel) {
            newClonePoints = newClonePoints.concat(
              this.selectedPoints.map((point) => ({
                Id: point.Id,
                Name: point.Name,
                Description: point.Description,
                Definition: point.Definition,
                TargetModel: [selectedTarget],
              })),
            );
          }
        } else {
          newClonePoints = newClonePoints.concat(
            this.selectedPoints.map((point) => ({
              Id: point.Id,
              Name: point.Name,
              Description: point.Description,
              Definition: point.Definition,
              TargetModel: null,
            })),
          );
        }
        this.clonePoints = this.clonePoints.concat(newClonePoints);
        this.wrapperObj.TargetModel = [];
      }
    },
    removeClonePoint(index) {
      this.targetEditor.hide();
      this.clonePoints.splice(index, 1);
    },
    removeSucceededClonePoints() {
      this.targetEditor.hide();
      this.clonePoints = this.clonePoints.filter((x) => x.IsSuccess !== true);
    },
    async saveClonePoint() {
      const self = this;
      blockui.blockElementPromise(self, self.$el);
      const unsavedClonePoints = this.clonePoints.filter((clonePoint) => clonePoint.IsSuccess !== true && clonePoint.TargetModel && clonePoint.TargetModel[0]);
      const data = unsavedClonePoints.map((clonePoint) => ({
        Id: clonePoint.Id,
        Name: clonePoint.Name,
        Description: clonePoint.Description ? clonePoint.Description : '',
        ...clonePoint.TargetModel[0].Target,
        Status: '',
        IsSuccess: null,
      }));
      if (data.length > 0) {
        data.forEach(d => {
          if(!d.hasOwnProperty("DeviceId")) {
            d.DeviceId = null;
          }
          if(!d.hasOwnProperty("RoomId")) {
            d.RoomId = null;
          }
        });

        api.cloneMassPoint(data).then((responses) => { //api.cloneMassPoint({ massPointCloneList: data }).then((responses) => {
          const resultMessages = responses.body;
          const addedPoints = [];
          for (let i = 0; i < resultMessages.length; i++) {
            const resultMessage = resultMessages[i];
            Vue.set(unsavedClonePoints[i], 'Status', resultMessage.Message);
            Vue.set(unsavedClonePoints[i], 'IsSuccess', resultMessage.IsSuccess);
            if (resultMessage.IsSuccess) {
              addedPoints.push(unsavedClonePoints[i]);
            }
          }
          if (addedPoints.length > 0) {
            self.$emit('pointsAdded', addedPoints.map((addedPoint) => addedPoint.TargetModel[0].Target));
          }
          blockui.unBlockElement(self.$el);
        }, (err) => {
          blockui.unBlockElement(self.$el);
        });
      } else {
        blockui.unBlockElement(self.$el);
      }
    },
  },
  beforeDestroy() {
    this.targetEditor.destroy();
    const modal = $(`#${this.modalId}`);
    if (modal) {
      modal.data('bs.modal', null);
    }
  },
};
