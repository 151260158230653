import Vue from 'vue';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';
import * as datatable from '../global/datatables';
import * as dateFormat from '../../helpers/dateFormat';

export default {
  data() {
    return {
      roleList: [],
      trainings: {},
      trainingRoleList: {},
    };
  },
  mounted() {
    const self = this; let
      table;
    const datatableContainer = '#role_training_table';
    window.addEventListener('beforeunload', self.redirect);

    const columns = [{
      data: 'TrainingType.Name',
      title: this.translate('training_type'),
      sortable: 'asc',
      defaultContent: '',
    }, {
      data: 'Name',
      title: this.translate('training'),
      sortable: 'asc',
      defaultContent: '',
    }];
    blockui.blockElement(self, '.m-portlet');
    api.getUserRoleList().then((res) => {
      self.roleList = res.body;
      api.getTrainingList().then((response) => {
        self.trainings = response.body.Data;
        self.roleList.forEach((role) => {
          self.trainingRoleList[role.Id] = {};
          self.trainings.forEach((training) => {
            self.trainingRoleList[role.Id][training.Id] = {
              duration: '',
              id: '',
              isModified: false,
            };
          });
        });
        api.getRoleTrainingList().then((res) => {
          res.body.Data.forEach((roleTraining) => {
            self.trainingRoleList[roleTraining.RoleId][roleTraining.TrainingId] = {
              duration: roleTraining.Duration,
              id: roleTraining.Id,
              isModified: false,
            };
          });

          self.roleList.forEach((role) => {
            columns.push({
              title: role.Name,
              orderable: false,
              render(data, type, row) {
                return `<div data-toggle="m-tooltip" data-placement="left" data-original-title="${self.translate('repetition_frequency')} (${self.translate('month')})"><input type="text" class="training-role-duration just-number" min="0" max="999" style="width: 100%" data-roleId="${role.Id}" data-trainingId="${row.Id}" value="${self.trainingRoleList[role.Id][row.Id].duration}"/></div>`;
              },
            });
          });
          columns.push({
            title: '',
            orderable: false,
            class: 'not-export',
            render(data, type, row) {
              return `<button id="save_${row.Id}" data-toggle="m-tooltip" data-placement="left" data-original-title="${self.translate('save')}" class="btn btn-outline-primary save-button not-export" disabled><i class="fa fa-fw fa-save"></i></button>`;
            },
          });

          $(datatableContainer).on('init.dt', () => {
            self.$nextTick(() => {
              handlers();
              Vue.justNumber();
            });
          });

          table = datatable.renderTable({
            exportFileName: self.translate('role_training'),
            container: datatableContainer,
            data: self.trainings,
            columns,
            paging: true,
            sortable: true,
            colReOrder: false,
            rowsGroup: [
              0,
            ],
            buttons: [
              {
                extend: 'excelHtml5',
                title() {
                  return `${self.translate('role_trainings')}-${dateFormat.viewDateTimeFormat(new Date())}`;
                },
                className: 'excelButton',
                exportOptions: {
                  orthogonal: 'export',
                  columns: 'th:not(.not-export)',
                  format: {
                    body: self.customExport,
                  },
                },
              }, {
                extend: 'pdfHtml5',
                title() {
                  return `${self.translate('role_trainings')}-${dateFormat.viewDateTimeFormat(new Date())}`;
                },
                className: 'pdfButton',
                exportOptions: {
                  orthogonal: 'export',
                  columns: 'th:not(.not-export)',
                  format: {
                    body: self.customExport,
                  },
                },
              }, {
                extend: 'print',
                title() {
                  return `${self.translate('role_trainings')}-${dateFormat.viewDateTimeFormat(new Date())}`;
                },
                className: 'printButton',
                exportOptions: {
                  orthogonal: 'export',
                  columns: 'th:not(.not-export)',
                  format: {
                    body: self.customExport,
                  },
                },
              }],
          });

          table.on('draw', handlers);

          function handlers() {
            $($.fn.dataTable.tables(true)).DataTable()
              .columns.adjust()
              .responsive.recalc();
            $('.training-role-duration').on('change keyup', function (e) {
              $(this).closest('tr').find('button').removeAttr('disabled')
                .addClass('active');
              self.trainingRoleList[$(this)[0].dataset.roleid][$(this)[0].dataset.trainingid].isModified = true;
              self.trainingRoleList[$(this)[0].dataset.roleid][$(this)[0].dataset.trainingid].duration = $(this)[0].value;
            });
            window.$('#role_training_table').off('click');
            window.$('#role_training_table').on('click', '.save-button', function (e) {
              const button = $(this).closest('tr').find('button');
              const row = window.$(this).closest('tr');
              const dtrow = table.row(row);
              const data = dtrow.data();
              const training = data;

              const formData = [];
              row.find('input').each(function () {
                const roleId = $(this)[0].dataset.roleid;
                const duration = $(this)[0].value ? parseInt($(this)[0].value) : 0;
                const id = self.trainingRoleList[roleId][training.Id].id ? parseInt(self.trainingRoleList[roleId][training.Id].id) : 0;
                formData.push({
                  TrainingId: parseInt(training.Id),
                  RoleId: parseInt(roleId),
                  Duration: duration,
                  Id: id,
                });
                if ($(this).val() == 0) {
                  $(this).val('');
                }
              });
              blockui.pageBlock();
              api.saveRoleTraining(formData).then((response) => {
                if (response.body.IsSuccess) {
                  toastr.success(self.translate('save_successfully'));
                  button.attr('disabled', 'disabled').removeClass('active');
                  row.find('input').each(function () {
                    const roleId = $(this)[0].dataset.roleid;
                    self.trainingRoleList[roleId][training.Id].isModified = false;
                  });
                } else {
                  toastr.error(response.body.Message);
                }
                blockui.unBlockPage();
              }, (error) => {
                blockui.unBlockPage();
              });
            });
          }

          blockui.unBlockElement('.m-portlet');
        });
      });
    });
  },
  methods: {
    redirect(event) {
      const self = this; let
        table;
      const confirmationMessage = '\o/';
      if (self.isModifiedExist()) {
        window.removeEventListener('beforeunload', self.redirect);
        event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        return confirmationMessage; // Gecko, WebKit, Chrome <34
      }
    },
    isModifiedExist() {
      const self = this; let
        table;
      let isExist = false;
      Object.keys(self.trainingRoleList).forEach((key) => {
        Object.keys(self.trainingRoleList[key]).forEach((key2) => {
          if (self.trainingRoleList[key][key2].isModified) {
            isExist = true;
          }
        });
      });
      return isExist;
    },
    customExport(data, row, column, node) {
      const input = $(node).find('input');
      if (input.length > 0) {
        return input.val();
      }
      return data;
    },
  },
  beforeRouteLeave(to, from, next) {
    const self = this; let
      table;
    const confirmationMessage = '\o/';
    if (self.isModifiedExist()) {
      window.removeEventListener('beforeunload', self.redirect);
      const answer = window.confirm(self.translate('you_have_unsaved_changes_do_you_want_to_leave'));
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
