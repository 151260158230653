<template>
    <div class="row question">
        <div class="col-md">
            <div v-if="answerProcess&&answerProcess.CreatedUser">
                <user-card v-if="!isPrint" :userId="answerProcess.CreatedUser.Id">
                    {{translate('answered_by')}}: <strong>{{answerProcess.CreatedUser.Name}}
                    {{answerProcess.CreatedUser.Surname}}</strong>
                </user-card>
                <p v-else><strong>{{answerProcess.CreatedUser.Name}} {{answerProcess.CreatedUser.Surname}}</strong></p>
                {{translate('answered_at')}}: {{answerProcess.CreatedDate|formatDateTimeLocal}}
            </div>
            <h6 :class="{'m--font-brand': !question.Answer || !question.Answer.length}">
                {{index+1}}. {{question.Name}} <span class="text-danger" v-if="question.IsRequired">*</span>
                <i v-if="questionFeedBacks" v-for="(feedback, index) in questionFeedBacks"
                   style="color:#006c95; margin-left:10px;"
                   class="b-fa b-fa-flag i-tooltip custom-feedback-button"
                   :id="'feedback_'+feedback.Id"
                ></i>
                <i v-if="questionProcess" style="margin-left:10px;" class="fa"
                   :class="{'fa-check':questionProcess.IsValid===true,'fa-times':questionProcess.IsValid===false,'btn-outline-success':questionProcess.IsValid===true,'btn-outline-danger':questionProcess.IsValid===false}"></i>
            </h6>
            <p>
                {{question.Description}}
            </p>
            <div class="form-group m--regular-font-size-lg1 m--font-bold"
                 v-if="question.QuestionTypeId == formQuestionType.multipleChoice">
                <div class="py-2" v-for='(opt, index) in question.Selections' :key="index">
                    <label class="m-radio m-radio--bold m-radio--state-brand text-inherit">
                        <input type="radio" :name="'radio'+question.Id" :id="'radio'+question.Id+index"
                               v-model="currentSelect"
                               :value="opt.Id" :required="question.IsRequired" :disabled="disabled">
                        <div :style="{color:opt.IsExpectedValue?'blue':''}">{{opt.Name}}</div>
                        <span class="opaque" :class="{checked:opt.Id==currentSelect}"></span>
                    </label>
                </div>
            </div>
            <div class="form-group m--regular-font-size-lg1 m--font-bold"
                 v-else-if="question.QuestionTypeId == formQuestionType.checkBox">
                <div class="py-2" v-for='(opt, index) in question.Selections'>
                    <label class="m-checkbox m-checkbox--bold m-checkbox--state-brand text-inherit" :key="index">
                        <input type="checkbox" :name="'checkbox'+question.Id" :id="'checkbox'+question.Id+index"
                               v-model="currentSelect" :value="opt.Id" :required="question.IsRequired"
                               :disabled="disabled">
                        <div :style="{color:opt.IsExpectedValue?'blue':''}">{{opt.Name}}</div>
                        <span class="opaque" :class="{checked:currentSelect.findIndex(x=>x==opt.Id)>=0}"></span>
                    </label>
                </div>
            </div>
            <div class="form-group m--regular-font-size-lg1 m--font-bold"
                 v-else-if="question.QuestionTypeId == formQuestionType.imageUpload">
                <!-- <img :src="currentSelect">-->
                <upload :value="currentSelect" :disabled="true" :displayUpload="false" @isDownloadBusy="isDownloadBusy"
                        :multiple="false"/>
            </div>
            <div class="form-group m--regular-font-size-lg1 m--font-bold"
                 v-else-if="question.QuestionTypeId == formQuestionType.fileUpload">
                <!--<a :href="question.Answer" target="_blank">{{question.Answer}}</a>-->
                <upload :value="currentSelect" :disabled="true" :displayUpload="false" @isDownloadBusy="isDownloadBusy"
                        :multiple="false"/>
            </div>
            <div class="form-group m--regular-font-size-lg1 m--font-bold" v-else>
                {{currentSelect}}
            </div>
            <div>
                <pre>{{answerConditionString}}</pre>
            </div>
        </div>
        <div class="image-container">
            <img :src="image" v-if="image" class="col--sm ml-auto mt-6 m--margin-right-25 m--margin-top-35"
                 :class="{'thumb': toggleImage}" @click="toggleImage = !toggleImage">
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import * as api from '../../scripts/services/api';
import upload from '../shared/upload';
import UserCard from './cards/UserCard';

export default {
  props: ['questionFeedBacks', 'question', 'index', 'disabled', 'devices', 'selection', 'answerProcess', 'isPrint', 'comparisonOperators', 'operandTypes'],
  components: {
    upload,
    'user-card': UserCard,
  },
  data() {
    return {
      toggleImage: true,
      questionDevices: [],
      image: null,
    };
  },
  computed: {
    ...mapState({
      formQuestionType: (state) => state.enums.formQuestionType,
    }),
    imageId() {
      return this.question.ImageId;
    },
    questionProcess() {
      const self = this;
      if (self.question && self.question.QuestionProcess && self.question.QuestionProcess && self.question.QuestionProcess.length > 0) {
        return self.question.QuestionProcess[0];
      }
      return null;
    },
    currentSelect: {
      get() {
        const self = this;
        return self.selection;
        /* if (self.questionProcess && self.questionProcess.AnswerProcesses) {
                      let a = self.questionProcess.AnswerProcesses.find(x => x.IsSelected == true);
                      return a ? a.SelectionId : null;
                    }
                    return null; */
      },
    },
    answerConditionString() {
      if (!this.question.AnswerCondition) {
        return '';
      }
      return this.answerConditionRecursive(this.question.AnswerCondition, 0);
    },
  },
  async created() {
    const self = this;
    self.initImage(self.imageId);

    /* for (var item of this.question.DeviceIds) {
                      var getDevice = await api.getCapacityEquipment(item);
                      var device = getDevice.body.Data;
                      //this.questionDevices.push({Value: item, Text: device.Name});
                  } */
    /* if (this.$route.name == 'MyWorkOrder') {
                      this.questionDevices = this.$parent.formDevices.filter(f => this.question.DeviceIds.includes(f.Value));
                  } else {
                      this.questionDevices = this.$parent.$parent.formDevices.filter(f => this.question.DeviceIds.includes(f.Value));
                  } */
  },
  methods: {
    answerConditionRecursive(answerCondition, index) {
      const operandType = this.operandTypes.find((operandType) => operandType.Id === answerCondition.OperandTypeId);
      const array = [];
      if (answerCondition && answerCondition.AnswerConditionRules && answerCondition.AnswerConditionRules.length > 0) {
        for (const answerConditionRule of answerCondition.AnswerConditionRules) {
          if (answerConditionRule.ComparisonOperator.IsSecondValueNeeded) {
            array.push(`${answerConditionRule.ComparisonOperator.Name} ${answerConditionRule.FirstValue}, ${answerConditionRule.SecondValue}`);
          } else {
            array.push(`${answerConditionRule.ComparisonOperator.Name} ${answerConditionRule.FirstValue}`);
          }
        }
      }
      if (answerCondition && answerCondition.Children && answerCondition.Children.length > 0) {
        for (const child of answerCondition.Children) {
          array.push(this.answerConditionRecursive(child, index + 1));
        }
      }

      let str = `\n${' '.repeat(index)}(`;
      str += array.join(` ${operandType.Name} `);
      return index - 1 > -1 ? `${str})` + `\n${' '.repeat(index - 1)}` : `${str})`;
    },
    async downloadFile(fileId) {
      const self = this;
      const response = await api.getFile({ id: fileId, isContainsData: true });
      // self.downloadBlob(self.base64toBlob(response.body.Data.Base64, response.body.Data.ContentType), response.body.Data.Name);
      return response.body.Data;
    },
    async initImage(e) {
      const self = this;
      if (e > 0) {
        const response = await self.downloadFile(e);
        if (response.ContentType.match('image.*')) {
          self.image = `data:${response.ContentType};base64,${response.Base64}`;
        }
      } else {
        self.image = null;
      }
    },
    isDownloadBusy(value) {
      this.$emit('isDownloadBusy', value);
    },
  },
  watch: {
    async imageId(e) {
      this.initImage(e);
    },
  },
};
</script>
