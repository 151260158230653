import Vue from 'vue';
import * as api from '../services/api';
import * as swal from '../../helpers/swal';
import * as blockui from '../../helpers/block-ui';
import { mixinz } from '../global/mixinz';

import Filters from '../../components/partials/Filters';

import RoomModal from '../../components/partials/modals/RoomModal';
import CapacityEquipmentModal from '../../components/partials/modals/CapacityEquipmentModal';
import CapacityEquipmentOverlay from '../../components/partials/xyz/CapacityEquipmentOverlay';
import DeviceModal from '../../components/partials/modals/DeviceModal';
import WorkModal from '../../components/partials/modals/WorkModal';
import ProcedureModal from '../../components/partials/modals/ProcedureModal';
import MaintenancePlanModal from '../../components/partials/modals/MaintenancePlanModal';
import WorkOrderStatusModal from '../../components/partials/modals/WorkOrderStatusModal';
import ReferenceLibraryItemModal
  from '../../components/partials/add-capacity-equipment-steps/ReferenceLibraryItemModal';
import FinancialModal from '../../components/partials/add-capacity-equipment-steps/FinancialModal';
import ServiceProviderModal from '../../components/partials/add-capacity-equipment-steps/ServiceProviderModal';
import DescriptionItemModal from '../../components/partials/add-capacity-equipment-steps/DescriptionItemModal';
import QuestionModal from '../../components/partials/modals/QuestionModal';
import PointModal from '../../components/views/Point/PointModal/PointModal.vue';
import PointCloneModal from '../../components/views/Point/PointCloneModal/PointCloneModal.vue';
import MassPointCloneModal from '../../components/views/Point/MassPointCloneModal/MassPointCloneModal.vue';

export default {
  mixins: [mixinz],
  data() {
    return {
      showFilter: null,
      isUpdate: false,
      collapseCount: 0,
      columnCount: 1,
      pointOrigin: null,
      window: {
        width: 0,
        height: 0,
      },

      searchRooms: '',
      searchEquipments: '',
      searchWorks: '',
      searchDevices: '',
      searchPoints: '',

      rooms: null,
      equipments: null,
      devices: null,
      points: null,
      works: null,
      plans: null,
      orders: null,

      filter: {
        rooms: false,
        equipments: false,
        devices: false,
        points: false,
        works: false,
        plans: false,
        orders: false,
      },
      collapse: {
        rooms: false,
        equipments: false,
        devices: false,
        points: false,
        works: false,
        plans: false,
        orders: false,
      },
      slc: {
        rooms: {},
        equipments: {},
        devices: {},
        points: {},
        works: {},
        plans: {},
        orders: {},
      },

      showRoomModal: false,
      showCapacityEquipmentModal: false,
      showCapacityEquipmentOverlay: false,
      showCapacityEquipmentOverlayRoomModal: false,
      showDeviceModal: false,
      showPointModal: false,
      showClonePointModal: false,
      showWorkModal: false,
      showReferenceLibraryItemModal: false,
      showDescriptionItemModal: false,
      showFinancialModal: false,
      showServiceProviderModal: false,
      showMaintenancePlanModal: false,
      showOrderStatusModal: false,
      showCreateOrderModal: false,
      showMassPointCloneModal: false,

      selectedRoom: {},
      selectedCapacityEquipment: {},
      selectedDevice: {},
      selectedPoint: {},
      // selectedWork: {},
      deviceId: null,
      selectedReferenceLibraryItem: {},
      selectedDescriptionItem: {},
      selectedFinancialItem: {},
      selectedServiceProviderItem: {},
      selectedMaintenancePlan: {},
      selectedWorkOrder: {},
      selectedWorkOrderId: null,
      selectedWorkParams: {},
      massPointCloneParent: {},

      capacityEquipmentId: '',

      capacityEquipmentModalIsUpdate: false,
      capacityEquipmentOverlayIsUpdate: false,
      deviceModalIsUpdate: false,
      roomModalIsUpdate: false,
      pointModalIsUpdate: false,
      capacityEquipmentOverlayRoomModalIsUpdate: false,
      // workModalIsUpdate: false,
      referenceLibraryItemModalIsUpdate: false,
      descriptionItemModalIsUpdate: false,
      financialModalIsUpdate: false,
      serviceProviderModalIsUpdate: false,
      maintenancePlanModalIsUpdate: false,

      lastSelectedEquipment: null,
      lastSelectedEquipmentFilterData: null,

      isQuestionUpdate: false,
      slcQuestion: {},
      showQuestionModal: false,
    };
  },
  props: ['by'],
  components: {
    filters: Filters,
    'room-modal': RoomModal,
    'capacity-equipment-modal': CapacityEquipmentModal,
    'capacity-equipment-overlay': CapacityEquipmentOverlay,
    'device-modal': DeviceModal,
    'work-modal': WorkModal,
    'reference-library-item-modal': ReferenceLibraryItemModal,
    'description-item-modal': DescriptionItemModal,
    'financial-modal': FinancialModal,
    'service-provider-modal': ServiceProviderModal,
    'procedure-modal': ProcedureModal,
    'maintenance-plan-modal': MaintenancePlanModal,
    'work-order-status-modal': WorkOrderStatusModal,
    'question-modal': QuestionModal,
    'point-modal': PointModal,
    'point-clone-modal': PointCloneModal,
    'mass-point-clone-modal': MassPointCloneModal,
  },
  computed: {
    roomsSearched() {
      let { rooms } = this;
      if (this.searchRooms) {
        this.cleanAfter('rooms', 'equipments', 'devices', 'works', 'plans', 'orders', 'points');
        rooms = rooms.filter((item) => item.Name.toLocaleLowerCase(localStorage.getItem('vue-lang')).includes(this.searchRooms.toLocaleLowerCase(localStorage.getItem('vue-lang'))));
      }
      return this.$refs.roomFilters.orderBy(rooms);
    },
    equipmentsSearched() {
      let { equipments } = this;
      if (this.searchEquipments) {
        this.cleanAfter('equipments', 'devices', 'works', 'plans', 'orders', 'points');
        equipments = equipments.filter((item) => {
          if(item.Name.toLocaleLowerCase(localStorage.getItem('vue-lang')).includes(this.searchEquipments.toLocaleLowerCase(localStorage.getItem('vue-lang')))){
            return item;
          }
          if(item.DeviceFieldCode){
            if(item.DeviceFieldCode.toLocaleLowerCase(localStorage.getItem('vue-lang')).includes(this.searchEquipments.toLocaleLowerCase(localStorage.getItem('vue-lang')))){
              return item;
            }
          }
          
        });
      }
      return this.$refs.equipmentFilters.orderBy(equipments);
    },
    worksSearched() {
      let { works } = this;
      if (this.searchWorks) {
        this.cleanAfter('works', 'plans', 'orders', 'points');
        works = works.filter((item) => item.Name.toLocaleLowerCase(localStorage.getItem('vue-lang')).includes(this.searchWorks.toLocaleLowerCase(localStorage.getItem('vue-lang'))));
      }
      return this.$refs.workFilters.orderBy(works);
    },
    plansSearched() {
      return this.$refs.planFilters.orderBy(this.plans);
    },
    pointsSearched() {
      let { points } = this;
      if (this.searchPoints) {
        points = points.filter((item) => item.Name.toLocaleLowerCase(localStorage.getItem('vue-lang')).includes(this.searchPoints.toLocaleLowerCase(localStorage.getItem('vue-lang'))));
      }
      return this.$refs.pointFilters.orderBy(points);
    },
    DevicesSearched() {
      let { devices } = this;
      if (this.searchDevices) {
        devices = devices.filter((item) => item.Name.toLocaleLowerCase(localStorage.getItem('vue-lang')).includes(this.searchDevices.toLocaleLowerCase(localStorage.getItem('vue-lang'))));
      }
      return this.$refs.deviceFilters.orderBy(devices);
    },
    isCollapsible() {
      let counterCollapse = 0;
      let counterColumns = 0;
      Object.keys(this.collapse).forEach((key) => {
        if (this[key] && !this.collapse[key]) {
          counterCollapse++;
        }
        if (this[key]) {
          counterColumns++;
        }
      });
      this.collapseCount = counterCollapse;
      this.columnCount = counterColumns;
      return counterCollapse > 1;
    },
  },
  methods: {
    redirectFromModal(e) {
      $(`#${e.modalId}`).modal('toggle');
      this.$router.push(e.redirectParams);
    },
    routeToMyWorkOrder(workOrderId) {
      this.$router.push({ name: 'MyWorkOrder', query: { from: 'WorkOrder', workOrderId } });
    },
    routeToEditWorkOrder(workOrderId) {
      this.$router.push({ name: 'AddWorkOrder', query: { from: 'AddWorkOrder', workOrderId } });
    },
    routeToAddWorkOrder() {
      this.$router.push({ name: 'AddWorkOrder', query: { from: 'AddWorkOrder' } });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (window.innerWidth < 1750 && this.columnCount > 2) {
        Vue.set(this.collapse, 'rooms', true);
      }
      if (window.innerWidth > 1750 && this.columnCount <= 3) {
        Vue.set(this.collapse, 'rooms', false);
      }
      //
      if (window.innerWidth < 1500 && this.columnCount >= 3) {
        Vue.set(this.collapse, 'equipments', true);
      }
      if (window.innerWidth > 1500 && this.columnCount <= 4) {
        Vue.set(this.collapse, 'equipments', false);
      }
      //
      if (window.innerWidth < 1250 && this.columnCount >= 4) {
        Vue.set(this.collapse, 'devices', true);
        Vue.set(this.collapse, 'works', true);
      }
      if (window.innerWidth > 1250 && this.columnCount <= 5) {
        Vue.set(this.collapse, 'devices', false);
        Vue.set(this.collapse, 'works', false);
      }
    },
    collapseColumn(iz, item) {
      if (!this.isCollapsible && iz) {
        return;
      }
      Vue.set(this.collapse, item, iz);
      if (iz) {
        this.filterItems(item, iz);
      }
      if (item == 'rooms' && this.columnCount > 3) {
        Vue.set(this.collapse, 'works', !iz);
      }
    },
    filterItems(type, close) {
      if (close) {
        this.showFilter = null;
        Vue.set(this.filter, type, false);
      } else {
        this.showFilter = type;
        Vue.set(this.filter, type, true);
        this.$eventHub.$emit('loadFilterData', type);
        this.$nextTick(() => {
          mApp.initScrollers();
          mApp.initTooltips();
        });
      }
    },
    openModal(show, modal, update) {
      Vue.set(this, show, true);
      this.$nextTick(() => {
        const modalContainer = window.$(modal);
        modalContainer.modal({
          show: true,
          backdrop: 'static',
        });
        modalContainer.find('form').data('type', update);
      });
    },
    updateWorkOrderStatus(itemId) {
      this.selectedWorkOrderId = itemId;
      this.openModal('showOrderStatusModal', '#update_work_order_status_modal', '');
    },
    addItem(type, itemId) {
      switch (type) {
        case 'rooms':
          this.roomModalIsUpdate = false;
          this.selectedRoom = {};
          // if (itemId) {
          this.selectedRoom = {
            AreaSize: 50,
          };
          // }
          this.openModal('showRoomModal', '#room_modal', '');
          break;
        case 'equipments':
          // Vue.set(this.slc, 'rooms', {Id: itemId});
          this.capacityEquipmentModalIsUpdate = false;
          this.selectedCapacityEquipment = {};
          if (itemId && this.rooms) {
            this.selectedCapacityEquipment = {
              RoomId: itemId,
            };
          }
          this.openModal('showCapacityEquipmentModal', '#capacity_equipment_modal', '');
          this.showRoomModal = true;
          break;
        case 'devices':
          this.$store.commit('setSelectedCapacityEquipmentId', itemId.Id);
          Vue.set(this.slc, 'equipments', itemId);
          this.deviceModalIsUpdate = false;
          if (itemId.Id) {
            this.selectedDevice = {
              CapacityEquipmentId: itemId.Id,
            };
            this.slc.equipments.Id = itemId.Id;
          } else {
            this.selectedDevice = {
              CapacityEquipmentId: this.slc.equipments.Id,
            };
          }
          this.openModal('showDeviceModal', '#device_modal', '');
          break;
        case 'works':
          this.$store.commit('setSelectedCapacityEquipmentId', itemId.Id);
          Vue.set(this.slc, 'equipments', itemId);
          this.showModal(
            'selectedWorkParams',
            {
              deviceId: itemId.Id ? itemId.Id : this.slc.equipments.Id,
            },
            'showWorkModal',
            'work_modal',
          );
          break;
        case 'points':
          this.pointModalIsUpdate = false;
          this.selectedPoint = {};
          if (this.pointOrigin === 'rooms') {
            this.selectedPoint = {
              RoomId: this.slc.rooms.Id,
            };
          } else if (this.pointOrigin === 'equipments') {
            this.selectedPoint = {
              DeviceId: this.slc.equipments.Id,
            };
          } else if (this.pointOrigin === 'devices') {
            this.selectedPoint = {
              DeviceId: this.slc.devices.Id,
            };
          }
          this.openModal('showPointModal', '#point_modal', '');
          break;
        case 'cloneMassPoint':
          this.pointModalIsUpdate = false;
          this.selectedPoint = {};
          if (this.pointOrigin === 'rooms') {
            this.selectedPoint = {
              RoomId: this.slc.rooms.Id,
            };
          } else if (this.pointOrigin === 'equipments') {
            this.selectedPoint = {
              DeviceId: this.slc.equipments.Id,
            };
          } else if (this.pointOrigin === 'devices') {
            this.selectedPoint = {
              DeviceId: this.slc.devices.Id,
            };
          }
          this.openModal('showPointModal', '#point_modal', '');
          break;
        case 'referenceLibraryItems':
          this.referenceLibraryItemModalIsUpdate = false;
          this.selectedReferenceLibraryItem = {};
          if (itemId) {
            this.selectedReferenceLibraryItem = {
              DeviceId: itemId,
            };
          }
          this.openModal('showReferenceLibraryItemModal', '#reference_library_item_modal', '');
          break;
        case 'descriptionItems':
          this.descriptionItemModalIsUpdate = false;
          this.selectedDescriptionItem = {};
          if (itemId) {
            this.selectedDescriptionItem = {
              DeviceId: itemId,
            };
          }
          this.openModal('showDescriptionItemModal', '#description_item_modal', '');
          break;
        case 'serviceProviderItems':
          this.serviceProviderModalIsUpdate = false;
          this.selectedServiceProviderItem = {};
          if (itemId) {
            this.selectedServiceProviderItem = {
              DeviceId: itemId,
            };
          }
          this.openModal('showServiceProviderModal', '#service_provider_item_modal', '');
          break;
        case 'financialItems':
          this.financialModalIsUpdate = false;
          this.selectedFinancialItem = {};
          if (itemId) {
            this.selectedFinancialItem = {
              DeviceId: itemId.deviceId,
            };
          }
          this.openModal('showFinancialModal', '#financial_modal', '');
          break;
        case 'plans':
          this.maintenancePlanModalIsUpdate = false;
          this.selectedMaintenancePlan = {};
          if (itemId) {
            this.selectedMaintenancePlan = {
              DeviceId: this.slc.equipments.Id,
              WorkId: itemId,
            };
          } else {
            this.selectedMaintenancePlan = {
              DeviceId: this.slc.equipments.Id,
              WorkId: this.slc.works.Id,
            };
          }
          this.openModal('showMaintenancePlanModal', '#maintenance_plan_modal', '');
          break;
        case 'workOrder':
          this.$router.push({ name: 'AddWorkOrder', query: { from: 'AddWorkOrder' } });
          break;
      }
    },
    cloneItem(type, itemId) {
      const self = this;
      if (type == 'points') {
        this.pointModalIsUpdate = true;
        this.selectedPoint = {};
        blockui.pageBlock(self);
        api.getPoint(itemId).then((response) => {
          this.selectedPoint = response.body;
          this.openModal('showClonePointModal', '#point_clone_modal', '');
          blockui.unBlockPage(self);
        });
      } else if ('massPoint') {
        this.massPointCloneParent = {};
        if (this.pointOrigin === 'rooms') {
          this.massPointCloneParent = {
            RoomId: this.slc.rooms.Id,
          };
        } else if (this.pointOrigin === 'equipments') {
          this.massPointCloneParent = {
            DeviceId: this.slc.equipments.Id,
          };
        } else if (this.pointOrigin === 'devices') {
          this.massPointCloneParent = {
            DeviceId: this.slc.devices.Id,
          };
        }
        this.openModal('showMassPointCloneModal', '#mass_point_clone_modal', '');
      }
    },
    editItem(type, itemId) {
      const self = this;
      if (type == 'rooms') {
        this.roomModalIsUpdate = true;
        this.selectedRoom = {};
        blockui.pageBlock(self);
        api.getRoom(itemId).then((response) => {
          this.selectedRoom = response.body;
          this.openModal('showRoomModal', '#room_modal', 'update');
          blockui.unBlockPage(self);
        });
      } else if (type == 'equipments') {
        this.capacityEquipmentOverlayIsUpdate = true;
        this.selectedCapacityEquipment = {};
        blockui.pageBlock(self);
        api.getCapacityEquipment(itemId.Id).then((response) => {
          this.selectedCapacityEquipment = response.body.Data;
          // this.loadEquipmentWorks(itemId);
          this.openModal('showCapacityEquipmentOverlay', '#capacity_equipment_overlay', 'update');
          blockui.unBlockPage(self);
        });
      } else if (type == 'devices') {
        this.deviceModalIsUpdate = true;
        this.selectedDevice = {};
        blockui.pageBlock(self);
        api.getDevice(itemId).then((response) => {
          this.selectedDevice = response.body;
          this.openModal('showDeviceModal', '#device_modal', 'update');
          blockui.unBlockPage(self);
        });
      } else if (type == 'works') {
        this.showModal(
          'selectedWorkParams',
          {
            workId: itemId,
          },
          'showWorkModal',
          'work_modal',
        );
      } else if (type == 'points') {
        this.pointModalIsUpdate = true;
        this.selectedPoint = {};
        blockui.pageBlock(self);
        api.getPoint(itemId).then((response) => {
          this.selectedPoint = response.body;
          this.openModal('showPointModal', '#point_modal', 'update');
          blockui.unBlockPage(self);
        });
      } else if (type == 'referenceLibraryItems') {
        this.referenceLibraryItemModalIsUpdate = true;
        this.selectedReferenceLibraryItem = {};
        blockui.pageBlock(self);
        api.getReferenceLibraryItem(itemId).then((response) => {
          this.selectedReferenceLibraryItem = response.body;
          this.openModal('showReferenceLibraryItemModal', '#reference_library_item_modal', 'update');
          blockui.unBlockPage(self);
        });
      } else if (type == 'descriptionItems') {
        this.descriptionItemModalIsUpdate = true;
        this.selectedDescriptionItem = {};
        blockui.pageBlock(self);
        api.getDescriptionItem(itemId).then((response) => {
          this.selectedDescriptionItem = response.body;
          this.openModal('showDescriptionItemModal', '#description_item_modal', 'update');
          blockui.unBlockPage(self);
        });
      } else if (type == 'financialItems') {
        this.financialModalIsUpdate = true;
        this.selectedFinancialItem = {};
        blockui.pageBlock(self);
        api.getFinancialItem(itemId.financialItemId).then((response) => {
          this.selectedFinancialItem = response.body;
          this.selectedFinancialItem.DeviceId = itemId.deviceId;
          this.openModal('showFinancialModal', '#financial_modal', 'update');
          blockui.unBlockPage(self);
        });
      } else if (type == 'plans') {
        this.maintenancePlanModalIsUpdate = true;
        this.selectedMaintenancePlan = {};
        blockui.pageBlock(self);
        api.getMaintenancePlan(itemId).then((response) => {
          this.selectedMaintenancePlan = response.body;
          this.openModal('showMaintenancePlanModal', '#maintenance_plan_modal', 'update');
          blockui.unBlockPage(self);
        });
      }
    },

    deleteItem(type, item) {
      const self = this;
      swal.check(self, item.Name).then((e) => {
        if (e.value) {
          blockui.pageBlock(self);
          if (type == 'rooms') {
            api.deleteRoom(item.Id).then((response) => {
              if (response.body.IsSuccess) {
                toastr.success(self.translate('delete_successfully'));
                Vue.getDatatables('.room_list');
              } else {
                toastr.error(response.body.Message);
              }
              blockui.unBlockPage();
            });
          } else if (type == 'equipments') {
            api.deleteCapacityEquipment(item.Id).then((response) => {
              if (response.body.IsSuccess) {
                toastr.success(self.translate('delete_successfully'));
                Vue.getDatatables('.capacity_equipment_list');
                self.slc.rooms.ChildCount = self.slc.rooms.ChildCount ? self.slc.rooms.ChildCount - 1 : 1;
              } else {
                toastr.error(response.body.Message);
              }
              blockui.unBlockPage();
            });
          } else if (type == 'devices') {
            api.deleteDevice(item.Id).then((response) => {
              if (response.body.IsSuccess) {
                toastr.success(self.translate('delete_successfully'));
                Vue.getDatatables('.device_list');
                self.slc.equipments.ChildCount = self.slc.equipments.ChildCount ? self.slc.equipments.ChildCount - 1 : 1;
              } else {
                toastr.error(response.body.Message);
              }
              blockui.unBlockPage();
            });
          } else if (type == 'points') {
            api.deletePoint(item.Id).then((response) => {
              if (response.body.IsSuccess) {
                toastr.success(self.translate('item_deleted_successfully').formatString(item.Name));
                Vue.getDatatables('.point_list');
                if (self.pointOrigin == 'rooms') {
                  self.slc.rooms.PointCount = self.slc.rooms.PointCount ? self.slc.rooms.PointCount - 1 : 1;
                }
                if (self.pointOrigin == 'equipments') {
                  self.slc.equipments.PointCount = self.slc.equipments.PointCount ? self.slc.equipments.PointCount - 1 : 1;
                }
                if (self.pointOrigin == 'devices') {
                  self.slc.devices.PointCount = self.slc.devices.PointCount ? self.slc.devices.PointCount - 1 : 1;
                }
              } else {
                toastr.error(response.body.Message);
              }
              blockui.unBlockPage();
            });
          } else if (type == 'works') {
            api.deleteWork(item.Id).then((response) => {
              if (response.body.IsSuccess) {
                toastr.success(self.translate('delete_successfully'));
                Vue.getDatatables('.work_list');
                self.slc.equipments.WorkCount = self.slc.equipments.WorkCount ? self.slc.equipments.WorkCount - 1 : 1;
              } else {
                toastr.error(response.body.Message);
              }
              blockui.unBlockPage();
            });
          } else if (type == 'plans') {
            api.deleteMaintenancePlan(item.Id).then((response) => {
              if (response.body.IsSuccess) {
                toastr.success(self.translate('delete_successfully'));
                Vue.getDatatables('.maintenance_plan');
                self.slc.works.MaintenancePlanCount = self.slc.works.MaintenancePlanCount ? self.slc.works.MaintenancePlanCount - 1 : 1;
              } else {
                toastr.error(response.body.Message);
              }
              blockui.unBlockPage();
            });
          } else if (type == 'orders') {
            api.deleteWorkOrder(item.Id).then((response) => {
              if (response.body.IsSuccess) {
                toastr.success(self.translate('delete_successfully'));
                Vue.getDatatables('.work_order');
                self.slc.works.WorkOrderCount = self.slc.works.WorkOrderCount ? self.slc.works.WorkOrderCount - 1 : 1;
              } else {
                toastr.error(response.body.Message);
              }
              blockui.unBlockPage();
            });
          }
        }
      });
    },
    closeItems(type) {
      if (type == 'rooms') {
        this.cleanAfter('rooms', 'equipments', 'devices', 'works', 'plans', 'orders', 'points', 'searchRooms');
      } else if (type == 'equipments') {
        this.cleanAfter('rooms', 'equipments', 'devices', 'works', 'plans', 'orders', 'points', 'searchEquipments');
      } else if (type == 'devices') {
        this.cleanAfter('equipments', 'devices', 'works', 'plans', 'orders', 'points', 'searchDevices');
      } else if (type == 'works') {
        this.cleanAfter('equipments', 'devices', 'works', 'plans', 'orders', 'points', 'searchWorks');
      } else if (type == 'plans') {
        this.cleanAfter('works', 'plans', 'orders', 'points');
      } else if (type == 'orders') {
        this.cleanAfter('works', 'plans', 'orders', 'points');
      } else if (type == 'points') {
        this.cleanAfter('equipments', 'devices', 'works', 'plans', 'orders', 'points', 'searchPoints');
      }
    },
    cleanAfter(main, ...args) {
      Vue.set(this.slc, main, {});
      Vue.set(this.collapse, main, false);
      args.forEach((item) => {
        Vue.set(this.slc, item, {});
        Vue.set(this.filter, item, false);
        Vue.set(this.collapse, item, false);
        Vue.set(this, item, null);
      });
    },
    loadAfter() {
      this.$nextTick(() => {
        mApp.initScrollers();
        mApp.initTooltips();
      });
    },
    selectAfter(id, type) {
      this.$nextTick(() => {
        this.loadAfter();
        const inp = $(`.${type}`).find(`.column-item#${id}`);
        if (inp && inp.position()) {
          inp.closest('.column-content').animate({ scrollTop: inp.position().top + inp.closest('.column-content').scrollTop() }, 'slow', () => {
          });
        }
      });
    },
    async loadRooms(formData) {
      const self = this;
      blockui.pageBlock(self);
      const Rooms = await api.getRoomList(formData);
      Vue.set(this, 'rooms', Rooms.body);
      this.cleanAfter('rooms', 'equipments', 'devices', 'works', 'plans', 'orders', 'points');
      blockui.unBlockPage(self);
      this.loadAfter();
    },
    async loadEquipments(formData) {
      const self = this;
      blockui.pageBlock(self);
      const CapacityEquipments = await api.getCapacityEquipmentSearch(formData);
      Vue.set(this, 'equipments', CapacityEquipments.body.Data);
      this.cleanAfter('equipments', 'devices', 'works', 'plans', 'orders', 'points');
      blockui.unBlockPage(self);
      this.loadAfter();
    },
    async loadRoomEquipments(room, formData) {
      const self = this;
      blockui.pageBlock(self);
      Vue.set(this.slc, 'rooms', room);
      let obj = { roomId: room.Id ? room.Id : self.selectedCapacityEquipment.RoomId };
      if (formData) {
        obj = Object.assign(obj, formData);
      }
      // var CapacityEquipments = await api.getCapacityEquipmentList(obj);
      const CapacityEquipments = await api.getCapacityEquipmentSearch(obj);
      Vue.set(this, 'equipments', CapacityEquipments.body.Data);
      this.cleanAfter('equipments', 'devices', 'works', 'plans', 'orders', 'points');
      blockui.unBlockPage(self);
      this.selectAfter(room.Id ? room.Id : self.selectedCapacityEquipment.RoomId, 'rooms');
      this.loadAfter();
    },
    async loadPoints(selectedData, origin, formData) {
      const self = this; const
        cleanAfterArray = ['rooms', 'equipments', 'devices', 'works', 'plans', 'orders'];
      let obj = {};
      blockui.pageBlock(self);
      Vue.set(this.slc, origin, selectedData);
      this.pointOrigin = origin;
      if (origin === 'rooms') {
        obj = { roomId: selectedData.Id };
      } else if (origin === 'equipments') {
        obj = { deviceId: selectedData.Id };
      } else if (origin === 'devices') {
        obj = { deviceId: selectedData.Id };
      }
      if (formData) {
        obj = Object.assign(obj, formData);
      }
      // const CapacityEquipments = await api.getCapacityEquipmentList(obj);
      const CapacityEquipments = await api.getPointList(obj);
      Vue.set(this, 'points', CapacityEquipments.body);
      const cleanAfterIndex = cleanAfterArray.findIndex((x) => x === origin);
      this.cleanAfter('points', ...cleanAfterArray.slice(cleanAfterIndex + 1));
      blockui.unBlockPage(self);
      this.selectAfter(selectedData.Id, origin);
    },
    async loadEquipmentDevices(equip) {
      const self = this;
      self.$store.commit('setSelectedCapacityEquipmentId', equip.Id);
      blockui.pageBlock(self);
      Vue.set(this.slc, 'equipments', equip);
      const Devices = await api.getDeviceList({ capacityEquipmentId: equip.Id });
      Vue.set(this, 'devices', Devices.body.Data);
      Vue.set(equip, 'ChildCount', Devices.body.Data.length);
      this.cleanAfter('devices', 'works', 'plans', 'orders', 'points');
      blockui.unBlockPage(self);
      this.selectAfter(equip.Id, 'equipments');
    },
    async loadEquipmentWorks(equip, formData) {
      const self = this;

      self.lastSelectedEquipment = equip;
      self.lastSelectedEquipmentFilterData = formData;

      self.$store.commit('setSelectedCapacityEquipmentId', equip.Id);
      blockui.pageBlock(self);
      Vue.set(this.slc, 'equipments', equip);
      let obj = { deviceId: equip.Id };
      if (formData) {
        obj = Object.assign(obj, formData);
      }
      const Works = await api.getWorkSearch(obj);
      Vue.set(this, 'works', Works.body.Data);
      Vue.set(equip, 'WorkCount', Works.body.Data.length);
      this.cleanAfter('works', 'devices', 'plans', 'orders', 'points');
      blockui.unBlockPage(self);
      this.selectAfter(equip.Id, 'equipments');
    },
    async loadEquipmentWorksWithLastSelectedParams() {
      const self = this;
      if (self.lastSelectedEquipment && self.lastSelectedEquipment.Id > 0) {
        self.$store.commit('setSelectedCapacityEquipmentId', self.lastSelectedEquipment.Id);
        blockui.pageBlock(self);
        Vue.set(this.slc, 'equipments', self.lastSelectedEquipment);
        let obj = { deviceId: self.lastSelectedEquipment.Id };
        if (self.lastSelectedEquipmentFilterData) {
          obj = Object.assign(obj, self.lastSelectedEquipmentFilterData);
        }
        const Works = await api.getWorkSearch(obj);
        Vue.set(this, 'works', Works.body.Data);
        this.cleanAfter('works', 'devices', 'plans', 'orders', 'points');
        blockui.unBlockPage(self);
        this.selectAfter(self.lastSelectedEquipment.Id, 'equipments');
      }
    },
    async loadEquipmentWorkPlans(work, formData) {
      const self = this;
      blockui.pageBlock(self);
      Vue.set(this.slc, 'works', work);
      const obj = { WorkId: work.Id.toString() };
      /* var dates = {fromDate: moment().format('YYYY-MM-DD'), endDate: moment().add(1, "years").format('YYYY-MM-DD')};
            if (formData) {
              dates = formData;
            }
            obj = Object.assign(obj, dates); */
      const Works = await api.getMaintenancePlanList(obj);
      Vue.set(this, 'plans', Works.body.Data);
      this.cleanAfter('plans', 'devices', 'orders', 'points');
      Vue.set(this.collapse, 'rooms', true);
      // if (this.$parent.by == 'equipments') {
      if (this.window.width < 1900 && !this.$parent.by) {
        Vue.set(this.collapse, 'equipments', true);
      }
      blockui.unBlockPage(self);
      this.selectAfter(work.Id, 'works');
    },
    async loadEquipmentWorkOrders(work, formData) {
      const self = this;
      blockui.pageBlock(self);
      Vue.set(this.slc, 'works', work);
      const obj = { WorkId: work.Id.toString() };
      /* var dates = {fromDate: moment().format('YYYY-MM-DD'), endDate: moment().add(1, "years").format('YYYY-MM-DD')};
            if (formData) {
              dates = formData;
            }
            obj = Object.assign(obj, dates); */
      const Orders = await api.getWorkOrderList(obj);
      Vue.set(this, 'orders', Orders.body.Data);
      this.cleanAfter('orders', 'devices', 'plans', 'points');
      Vue.set(this.collapse, 'rooms', true);
      // if (this.$parent.by == 'equipments') {
      if (this.window.width < 1900 && !this.$parent.by) {
        Vue.set(this.collapse, 'equipments', true);
      }
      blockui.unBlockPage(self);
      this.selectAfter(work.Id, 'works');
    },
    async loadWorkOrderChildren(order) {
      if (order.ChildCount > 0) {
        if (order.isShowChildWorkOrders == true) {
          Vue.set(order, 'isShowChildWorkOrders', false);
        } else {
          if (!order.ChildWorkOrders) {
            const ChildWorkOrders = await api.getWorkOrder(order.Id);
            Vue.set(order, 'ChildWorkOrders', ChildWorkOrders.body.ChildWorkOrders);
          }
          Vue.set(order, 'isShowChildWorkOrders', true);
          this.orders.forEach((item, index) => {
            if (item.Id != order.Id) {
              Vue.set(item, 'isShowChildWorkOrders', false);
            }
          });
        }
      }
    },
    async loadCapacityEquipmentWorks() {
      const Works = await api.getWorkSearch({ deviceId: this.slc.equipments.Id });
      Vue.set(this, 'works', Works.body.Data);
    },
    async loadCapacityEquipmentWorkMaintenancePlans() {
      // var MaintenancePlans = await api.getMaintenancePlanList({WorkId: this.slc.works.Id});
      const MaintenancePlans = await api.getMaintenancePlanList({
        fromDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, 'years').format('YYYY-MM-DD'),
        WorkId: this.slc.works.Id.toString(),
      });
      Vue.set(this, 'plans', Works.body.Data);
    },
    openCapacityEquipmentOverlayRoomModal() {
      this.openModal('showCapacityEquipmentOverlayRoomModal', '#room_modal', '');
    },
    onPointsAdded(addedPoints) {
      Vue.getDatatables('.point_list');
      for (const addedPoint of addedPoints) {
        if (addedPoint.RoomId > 0 && this.rooms) {
          const room = this.rooms.find((x) => x.Id == addedPoint.RoomId);
          if (room) {
            room.PointCount = room.PointCount ? room.PointCount + 1 : 1;
          }
        } else if (addedPoint.DeviceId > 0) {
          if (this.equipments) {
            const equipment = this.equipments.find((x) => x.Id == addedPoint.DeviceId);
            if (equipment) {
              Vue.set(equipment, 'PointCount', equipment.PointCount ? equipment.PointCount + 1 : 1);
            }
          }
          if (this.devices) {
            const device = this.devices.find((x) => x.Id == addedPoint.DeviceId);
            if (device) {
              Vue.set(device, 'PointCount', device.PointCount ? device.PointCount + 1 : 1);
            }
          }
        }
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  async mounted() {
    Vue.getDatatables = (table) => {
      if (table === '.room_list') {
        this.loadRooms();
      } else if (table === '.capacity_equipment_list') {
        if (this.by == 'equipments') {
          this.loadEquipments();
        } else {
          this.loadRoomEquipments(this.slc.rooms);
        }
      } else if (table === '.device_list') {
        this.loadEquipmentDevices(this.slc.equipments);
      } else if (table === '.work_list') {
        this.loadEquipmentWorks(this.slc.equipments);
      } else if (table === '.maintenance_plan') {
        this.loadEquipmentWorkPlans(this.slc.works);
      } else if (table === '.work_order') {
        this.loadEquipmentWorkOrders(this.slc.works);
      } else if (table === '.point_list') {
        this.loadPoints(this.slc[this.pointOrigin], this.pointOrigin);
      }
    };
    if (this.by == 'equipments') {
      Vue.getDatatables('.capacity_equipment_list');
    } else {
      Vue.getDatatables('.room_list');
    }
  },
};
