export const check = (self, title = null, text = null, type = 'warning', confirmButtonText = null) => swal({
  title: title ? ((text ? title : self.translate('are_you_sure_delete'))) : self.translate('are_you_sure'),
  text: title && text ? text : title,
  type,
  showCancelButton: !0,
  confirmButtonText: confirmButtonText || self.translate('yes'),
  cancelButtonText: self.translate('no'),
});

export const checkHtml = (self, title = null, text = null, type = 'warning', confirmButtonText = null) => swal({
  title: title ? ((text ? title : self.translate('are_you_sure_delete'))) : self.translate('are_you_sure'),
  html: title && text ? text : title,
  type,
  showCancelButton: !0,
  confirmButtonText: confirmButtonText || self.translate('yes'),
  cancelButtonText: self.translate('no'),
});

export const qaqccheck = (self, title = null, text = null, type = 'warning', confirmButtonText = null) => swal({
  title: self.translate('are_you_sure'),
  text: title && text ? text : title,
  type,
  showCancelButton: !0,
  confirmButtonText: confirmButtonText || self.translate('yes'),
  cancelButtonText: self.translate('no'),
});
export const checkWithMultipleButtons = (self, buttons, title = self.translate('are_you_sure'), text, type = 'warning') => {
  let htmlContent = text ? (`${text}<br/><br/>`) : '';
  for (const button of buttons) {
    htmlContent += `<button id="${button.id}" class="${button.cls}">${button.text}</button>`;
  }

  return swal({
    title,
    html: htmlContent,
    showConfirmButton: false,
    type,
    onBeforeOpen: () => {
      const content = Swal.getContent();
      const qs = content.querySelector.bind(content);

      for (const button of buttons) {
        const buttonNode = qs(`#${button.id}`);
        buttonNode.addEventListener('click', (e) => {
          if (button.onClick) {
            button.onClick(e);
          }
          swal.close();
        });
      }
    },
  });
};

export const checkWithHtmlContent = (self, title = null, html = null, type = 'warning', confirmButtonText = null) => swal({
  title: title ? ((html ? title : self.translate('are_you_sure_delete'))) : self.translate('are_you_sure'),
  html: title && html ? html : title,
  type,
  showCancelButton: !0,
  confirmButtonText: confirmButtonText || self.translate('yes'),
  cancelButtonText: self.translate('no'),
});

export const checkWithInput = (self, inputParams = [], inputValidator = null, type = 'warning', inputPlaceholder = '', confirmButtonText = self.translate('yes'), cancelButtonText = self.translate('no')) => {
  /*
    inputParams
      [{
        title: 'Question 1',
        text: 'Chaining swal2 modals is easy'
      },
        {
          title: 'Question 1',
          text: 'Chaining swal2 modals is easy'
        }]
    inputValidator
    (value) => {
      return !value && 'You need to write something!'
    }
    */
  const options = {
    input: 'text',
    type,
    confirmButtonText,
    inputPlaceholder,
    cancelButtonText,
    showCancelButton: true,
  };
  Object.assign(options, { inputValidator });
  if (inputParams.length > 1) {
    const progressSteps = [...Array(inputParams.length).keys()].map((x) => x + 1);
    Object.assign(options, { progressSteps });
  }
  return swal.mixin(options).queue(inputParams).then((result) => {
    if (result.value) {
      return result;
    }
    return false;
  });
};

export const checkWithInputMultipleInputMultipleType = async (self, title, inputs, type, confirmButtonText, cancelButtonText) => {
  let html = "<form id='swal-form'>";
  const inputGroup = inputs.map((x, index) => {
    $(document).on('click', `#swal-input-${index}`, function () {
      const isCheckedExist = $('#swal-form input[type="checkbox"]:checked').length > 0;
      const button = $(this).closest('.swal2-container').find('.swal2-confirm');
      if (!isCheckedExist) {
        button.attr('disabled', 'disabled');
      } else {
        button.removeAttr('disabled');
      }
    });
    return `<div><input class="${x.cls}" type="${x.input}" ${x.required ? 'required' : ''} id="swal-input-${index}" text="${x.text}" ${x.checked ? 'checked' : ''} value="${x.id}"/><span>&nbsp</span><label for="swal-input-${index}">${x.text}</label></div>`;
  }).join('');
  html += inputGroup;
  html += '</form>';
  const res = await swal({
    title,
    html,
    type,
    focusConfirm: false,
    showCancelButton: true,
    cancelButtonText,
    confirmButtonText,
    preConfirm: (e) => {
      const form = $('#swal-form');
      form.validate();
      if (form.valid()) {
        const result = [];
        for (let i = 0; i < inputs.length; i++) {
          const input = document.getElementById(`swal-input-${i}`);
          if (input.checked) {
            result.push(input.value);
          }
        }
        return result;
      }
    },
  });
  inputs.map((x, index) => {
    $(document).off('click', `#swal-input-${index}`);
  });
  return res;
};
export const checkWithInputMultipleInput = async (title, inputs) => {
  let html = "<form id='swal-form'>";
  const inputGroup = inputs.map((x, index) => `<input type="text" ${x.required ? 'required' : ''} placeholder="${x.placeholder}" id="swal-input-${index}"/>`).join('');
  html += inputGroup;
  html += '</form>';

  return await swal({
    title,
    html,
    focusConfirm: false,
    preConfirm: (e) => {
      const form = $('#swal-form');
      form.validate();
      if (form.valid()) {
        const result = [];
        for (let i = 0; i < inputs.length; i++) {
          result.push(document.getElementById(`swal-input-${i}`).value);
        }
        return result;
      }
    },
  });
};

export const error = (title,text) => {
  return swal({
    title: title,
    text: text,
    type: "error",
    confirmButtonText: "Tamam",
  })
}
export const success = (title,text) => {
  return swal({
    title: title,
    text: text,
    type: "success",
    confirmButtonText: "Tamam",
  })
}

export const warning = (title,text) => {
  return swal({
    title: title,
    text: text,
    type: "warning",
    confirmButtonText: "Tamam",
  })
}