import Vue from 'vue';
import * as api from '../services/api';
import * as datatable from '../global/datatables';
import TrainingMaterialModal from '../../components/partials/modals/TrainingMaterialModal';
import * as swal from '../../helpers/swal';
import * as blockui from '../../helpers/block-ui';
import Select2 from '../../components/shared/select2';

export default {
  data() {
    return {
      filterContinent: {
        Value: '',
      },
      filterCountry: {
        Value: '',
      },
      selectedMaterial: {},
      continentList: [],
      countryList: [],
      isUpdate: false,
    };
  },
  components: {
    select2: Select2,
    'training-material-modal': TrainingMaterialModal,
  },
  mounted() {
    const self = this; let
      table;
    const columns = [{
      data: 'Id',
      title: 'ID',
      sortable: true,
      width: 40,
      defaultContent: '',
    }, {
      data: 'Name',
      title: this.translate('training_material'),
      sortable: 'asc',
      width: 700,
      defaultContent: '',
    }];

    const datatableContainer = '.training_material_list';

    Vue.getDatatables = (data) => {
      blockui.blockElement(self, '.m-portlet');
      const isDataTable = $.fn.DataTable.isDataTable(datatableContainer);
      api.getTrainingMaterialList(data).then((response) => {
        if (isDataTable) {
          const dt = $(datatableContainer).DataTable();
          dt.clear();
          dt.rows.add(response.body.Data);
          dt.draw();
        } else {
          table = datatable.renderTable({
            exportFileName: self.translate('training_material'),
            container: datatableContainer,
            data: response.body.Data,
            columns,
            paging: true,
            sortable: true,
            editButton: true,
            deleteButton: true,
          });
        }
      });
      blockui.unBlockElement('.m-portlet');
    };

    Vue.getDatatables();

    window.$(datatableContainer).on('click', '.edit-button', function () {
      blockui.blockModal(self);
      const dt = $(datatableContainer).DataTable();
      self.selectedMaterial = {};
      const modalContainer = window.$('#training_material_modal');
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const id = row.data().Id;
      api.getTrainingMaterial(id).then((res) => {
        self.selectedMaterial = res.body;
        blockui.unBlockModal();
      });
      modalContainer.modal({
        backdrop: 'static',
        show: true,
      });
      modalContainer.find('form').data('type', 'update');
      self.isUpdate = true;
    });

    window.$('#add_training_material_btn').on('click', () => {
      const modalContainer = window.$('#training_material_modal');
      modalContainer.modal({
        backdrop: 'static',
        show: true,
      });
      modalContainer.find('form').data('type', '');
      self.isUpdate = false;
      self.selectedMaterial = {};
    });

    window.$(datatableContainer).on('click', '.delete-button', function () {
      const dt = $(datatableContainer).DataTable();
      const tr = $(this).closest('tr');
      const row = dt.row(tr);
      const data = row.data();
      swal.check(self, data.Name).then((e) => {
        if (e.value) {
          api.deleteTrainingMaterial(data.Id).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              const filterForm = $("form[name='filterForm']");
              const formData = {};
              $.map(filterForm.serializeArray(), (item) => {
                Object.assign(formData, { [item.name]: item.value });
              });
              row.remove().draw();
              Vue.getDatatables(formData);
            } else {
              toastr.error(response.body.Message);
            }
          });
        }
      });
    });
  },
  methods: {
    onFilter(e) {
      e.preventDefault();
      const form = $(e.target);
      if (form.valid()) {
        const formData = {};
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        Vue.getDatatables(formData);
      }
    },
  },
};
