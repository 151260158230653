import grid from '../../../../scripts/global/gridOld';
import * as api from '../../../../scripts/services/api';
import * as blockui from '../../../../helpers/block-ui';

export default {
  data() {
    return {
      isReady: false,
      actors: [],
      actionGroupList: [],
      // MOCK DATA
      // roleGroups: [
      //     {
      //         "Id": 3,
      //         "Name": "Work Order",
      //         "Actions": [
      //             {
      //                 "Id": 1,
      //                 "Name": "Add"
      //             },
      //             {
      //                 "Id": 3,
      //                 "Name": "Delete"
      //             },
      //             {
      //                 "Id": 4,
      //                 "Name": "Assign"
      //             },
      //             {
      //                 "Id": 5,
      //                 "Name": "Plan"
      //             }
      //         ]
      //     },
      //     {
      //         "Id": 4,
      //         "Name": "Work",
      //         "Actions": [
      //             {
      //                 "Id": 3,
      //                 "Name": "Assign"
      //             },
      //             {
      //                 "Id": 4,
      //                 "Name": "Update"
      //             }
      //         ]
      //     }, {
      //         "Id": 1,
      //         "Name": "Vardiya Takvimi",
      //         "Actions": [
      //             {
      //                 "Id": 5,
      //                 "Name": "Create"
      //             },
      //             {
      //                 "Id": 6,
      //                 "Name": "Update"
      //             },
      //             {
      //                 "Id": 6,
      //                 "Name": "Delete"
      //             },
      //             {
      //                 "Id": 6,
      //                 "Name": "View"
      //             },
      //         ]
      //     },
      // ],
      selectedActions: [],
      rows: [],
      columns: [{
        field: 'ActionName',
        text: 'Actions',
        width: 200,
        locked: true,
      }, {
        field: 'ActionGroupName',
        text: 'ActionGroup',
        width: 200,
        locked: true,
        hidden: true,
        tooltipRenderer: false,
      }],
      group: 'ActionGroupName',
    };
  },
  components: {
    grid,
  },
  methods: {
    isChecked(e) {
      if (!e.record.hasGeneratedId) return;
      const self = this;
      const ActorId = e.source.data.actorId;
      const { ActionId } = e.record;
      blockui.pageBlock(self);
      const index = this.selectedActions.findIndex((x) => x.ActionId == ActionId && x.ActorId == ActorId);
      api.saveAction({
        ActorId,
        ActionId,
        isActive: e.checked,
      }).then((response) => {
        if (response.body.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
        } else {
          toastr.error(response.body.Message);
        }
        blockui.unBlockPage(self);
      });
      if (e.checked) {
        if (index < 0) {
          this.selectedActions.push({
            ActorId,
            ActionId,
          });
        }
      } else if (index >= 0) {
        this.selectedActions.splice(index, 1);
      }
    },
  },
  async created() {
    const self = this;
    await api.getActorList().then((response) => {
      this.actors = response.body;
    });
    await api.getActionGroupList().then((response) => {
      this.actionGroupList = response.body;
    });
    await api.getActionActorsList().then((response) => {
      this.selectedActions = response.body;
    });
    this.actors.forEach((actor) => {
      self.columns.push({
        field: `Actor_${actor.Id}`,
        actorId: actor.Id,
        text: actor.Name,
        width: 100,
        htmlEncode: false,
        tooltipRenderer: false,
        flex: 1,
        type: 'check',
        listeners: {
          toggle: this.isChecked,
        },
        headerRenderer: ({ column }) => `${column.text}&nbsp;<span data-checked="${column.data.actorId}" class="routeToActor"><i class="fa fa-plus"></i></span>`,
        // renderer: ({value}) => value == null ? '' : `<div class="b-widget b-checkbox b-green"><input class="checkedActions" type="checkbox" ${value ? 'checked' : ''}><label/></div>`

      });
    });
    this.actionGroupList.forEach((actionGroup) => {
      actionGroup.Actions.forEach((action) => {
        const row = {
          ActionName: action.Name,
          ActionId: action.Id,
          ActionGroupName: actionGroup.Name,
          ActionGroupId: actionGroup.Id,
        };
        this.actors.forEach((actor) => {
          row[`Actor_${actor.Id}`] = self.selectedActions.findIndex((selectedAction) => selectedAction.ActorId == actor.Id && selectedAction.ActionId == action.Id) >= 0;
        });
        self.rows.push(row);
      });
    });

    console.log('a', this.rows, this.columns);
    this.isReady = true;
  },
  mounted() {
    const self = this;
    $(document).off('click', '.routeToActor');

    $(document).on('click', '.routeToActor', (e) => {
      const actorId = $(e.currentTarget).data('checked');
      self.$router.push({ name: 'Actors', query: { from: 'Actors', id: actorId } });
    });
  },

};
