import * as api from '../../services/api';
import chart from './chart';
import * as swal from '../../../helpers/swal';

export default {
  mixins: [chart],
  data() {
    return {
      chart: null,
      labels: [],
      datasets: [],

      shiftTypeObjectList: {},
    };
  },
  async mounted() {
    const self = this;
    await self.getData();
    self.initChart();
  },
  methods: {
    async updateData() {
      const self = this;
      await self.getData();
      self.redraw();
    },
    async getData() {
      const self = this;
      // try {
      const promises = [
        await api.getInventoryList(),
        await api.getProductFamilies(),
      ];
      const results = await Promise.all(promises);
      const inventoryList = results[0].body.Data;
      const productFamilies = results[1].body;
      /*shiftTypeList.forEach(function (shiftType) {
        self.shiftTypeObjectList[shiftType.Id] = shiftType;
      }); */
      /* let start = moment(self.startDate);
      const end = moment(self.endDate);
      const dateArray = [];
      while (start.isBefore(end)) {
        dateArray.push(start.toDate());
        start.add(1, 'day')
      } */
      const d = [];
      d.push(inventoryList.filter((x) => x.ParentId));
      d.push(inventoryList.filter((x) => !x.ParentId));
      self.datasets = d.map((devicesAndCapacityEquipments, index) => {
        const data = productFamilies.map((productFamily) => devicesAndCapacityEquipments.filter((deviceOrCapacitiEquipment) => deviceOrCapacitiEquipment.ProductFamilyId == productFamily.Value).length);
        const colors = data.map((x, i) => self.getRandomColor(data.length, i));
        const labels = productFamilies.map((productFamily) => ((index == 0 ? 'device' : 'capacity equipment ') + productFamily.Text));
        return {
          label: (index == 0 ? 'device' : 'capacity equipment'),
          labels,
          data,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1,
        };
      });

      /* self.labels = capacityTypes.map(capacityType => {
        return capacityType.Text
      }); */
      // } catch (e) {
      //  throw new Error(e);
      // }
    },
    initChart() {
      const self = this;
      if ($(`#${self.chartId}`).length == 0) return;
      const config = {
        type: 'doughnut',
        data: {
          // labels: self.labels,
          datasets: self.datasets,
        },
        options: {
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const { index } = tooltipItem;
                return `${dataset.labels[index]}: ${dataset.data[index]}`;
              },
            },
          },
          // responsive: true,
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
            },
          },
          /* legendCallback: function (chart) {
             var text = [];
             var legs = [];
             for (var j = 0; j < chart.data.datasets.length; j++) {
               for (var i = 0; i < chart.data.datasets[j].data.length; i++) {
                 var newd = {label: chart.data.datasets[j].labels[i], color: chart.data.datasets[j].backgroundColor[i]};

                 if (!containsObject(newd, legs)) {
                   legs.push(newd);
                 }
               }
             }

             text.push('<ul class="Mylegend ' + chart.id + '-legend">');
             for (var k = 0; k < legs.length; k++) {
               text.push('<li><span style="background-color:' + legs[k].color + '"></span>');
               text.push(legs[k].label);
               text.push('</li>');
             }
             text.push('</ul>');
             return text.join("");
           }, */
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
              },
            }],
          },
          title: {
            display: true,
            text: `${self.translate('shift_count')}: ${self.datasets.reduce((total, current) => total + current.data.reduce((total_2, current_2) => total_2 + current_2, 0), 0)}`,
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          maintainAspectRatio: false,
        },
      };
      const ctx = $(`#${self.chartId}`);
      self.chart = new Chart(ctx, config);

      /* ctx.onclick = function(evt) {
        let activePoints = self.chart.getElementsAtEvent(evt);
        if (activePoints[0]) {
          let chartData = activePoints[0]['_chart'].config.data;
          let idx = activePoints[0]['_index'];

          let label = chartData.labels[idx];
          let value = chartData.datasets[0].data[idx];

          let url = "http://example.com/?label=" + label + "&value=" + value;
          alert(url);
        }
      }; */
    },
    getRandomColor(numOfSteps, step) {
      let r; let g; let
        b;
      const h = step / numOfSteps;
      const i = ~~(h * 6);
      const f = h * 6 - i;
      const q = 1 - f;
      switch (i % 6) {
        case 0:
          r = 1;
          g = f;
          b = 0;
          break;
        case 1:
          r = q;
          g = 1;
          b = 0;
          break;
        case 2:
          r = 0;
          g = 1;
          b = f;
          break;
        case 3:
          r = 0;
          g = q;
          b = 1;
          break;
        case 4:
          r = f;
          g = 0;
          b = 1;
          break;
        case 5:
          r = 1;
          g = 0;
          b = q;
          break;
      }
      const c = `#${(`00${(~~(r * 255)).toString(16)}`).slice(-2)}${(`00${(~~(g * 255)).toString(16)}`).slice(-2)}${(`00${(~~(b * 255)).toString(16)}`).slice(-2)}`;
      return (c);
    },
    redirect(orderStatusId) {
      this.$router.push({ name: 'WorkOrder', query: { workOrderStatusId: orderStatusId } });
    },
    redraw() {
      $(`#${this.chartId}`).removeAttr('width');
      $(`#${this.chartId}`).removeAttr('height');
      $(`#${this.chartId}`).html('');
      this.chart.destroy();
      this.initChart();
    },
  },
};
