import * as api from '../services/api';
import * as dateFormat from '../../helpers/dateFormat';
import * as blockui from '../../helpers/block-ui';
import scheduler from '../global/scheduler';
import grid from '../global/gridOld';
import FastAssignWorkOrderUser from '../../components/wrappers/FastAssignWorkOrderUser';

export default {
  data() {
    return {
      workOrder: {
        workOrderStartDate: null,
        workOrderEndDate: null,
        workOrderId: null,
        workOrderName: null,
        capacityEquipment: null,
        workId: null,
      },
      showFastAssign: false,
      dateHelper: bryntum.scheduler.DateHelper,
      widgetHelper: bryntum.scheduler.WidgetHelper,
      currentDate: bryntum.scheduler.DateHelper.getFirstDateOfMonth(new Date()),
      schEngine: undefined,
      gridEngine: undefined,
      IsReady: false,
      IsGridReady: false,
      IsDisplayGrid: false,
      maintenanceTypeList: [],
      gridColumns: [{
        type: 'rownumber',
        field: 'rownumber',
        locked: true,
      },
      {
        type: 'widget',
        groupable: false,
        widgets: [{
          type: 'button',
          icon: 'b-fa b-fa-edit',
          cls: 'b-blue',
          style: 'width:100%',
          // todo renderer not working
          onPaint: ({ source }) => {
            this.$nextTick(() => {
              source.icon = source.cellInfo.record.resizable ? source.icon : 'b-fa b-fa-angle-double-right';
            });
          },
          onAction: ({ source }) => {
            const event = this.schEngine.eventStore.getById(source.cellInfo.record.id);
            const resource = this.schEngine.resourceStore.getById(source.cellInfo.record.resourceId);
            this.schEngine.editEvent(event, resource, source.currentElement);
          },
        }],
        field: 'edit',
        text: 'Edit',
        width: 90,
        locked: true,
      },
      {
        type: 'widget',
        groupable: false,
        widgets: [{
          type: 'button',
          icon: 'b-fa b-fa-trash',
          cls: 'b-red',
          style: 'width:100%',
          // todo renderer not working
          onPaint: ({ source }) => {
            this.$nextTick(() => {
              source.icon = source.cellInfo.record.resizable ? source.icon : 'b-fa b-fa-ban';
            });
          },
          onAction: ({ source }) => {
            if (!source.cellInfo.record.resizable) return;
            this.deletePlan(source.cellInfo.record.id);
          },
        }],
        field: 'delete',
        text: 'Delete',
        width: 90,
        locked: true,
      },
      {
        type: 'number',
        field: 'id',
        text: 'Id',
        width: 90,
      },
      {
        text: 'Work Order',
        field: 'name',
        width: 300,
      },
      {
        type: 'date',
        text: 'Start Date',
        field: 'startDate',
        format: 'LLL',
        width: 200,
      },
      {
        type: 'date',
        text: 'End Date',
        field: 'endDate',
        format: 'LLL',
        width: 200,
      },
      {
        type: 'number',
        text: 'Device',
        field: 'resourceId',
        width: 85,
      },
      {
        text: 'Note',
        field: 'note',
        width: 200,
      }],
      eventContextMenu: {
        items: [{
          cls: 'transform_to_work_order',
          text: this.$parent.translate('transform_to_work_order'),
          icon: 'b-fa b-fa-exchange',
        }],
      },
      resources: [],
      timeRanges: [],
      events: [],
      columns: [
        {
          type: 'rownumber',
          field: 'rownumber',
          groupable: false,
          draggable: false,
          hidden: true,
        },
        {
          field: 'capacityEquipment',
          text: 'Capacity Equipment',
          width: 525,
        },
        {
          field: 'brand',
          text: 'Brand',
          width: 120,
          hidden: true,
        },
        {
          field: 'model',
          text: 'Model',
          width: 280,
          hidden: true,
        },
        {
          field: 'room',
          text: 'Room',
          width: 280,
          hidden: true,
        },
        {
          field: 'capacityType',
          text: 'Capacity Type',
          width: 525,
          hidden: true,
        }],
      group: { field: 'capacityType' },
    };
  },
  components: {
    scheduler,
    grid,
    'fast-assign-work-order-user': FastAssignWorkOrderUser,
  },
  async created() {
    const self = this;
    blockui.pageBlock(self);
    const promises = [
      await self.getMaintenanceTypes(),
      self.getResources(),
      self.getEvents(),
    ];
    Promise.all(promises);
    self.IsReady = true;
  },
  mounted() {

  },
  computed: {},
  methods: {
    initGrid() {
      const self = this;
      self.gridEngine = self.$refs.grid.gridEngine;
      self.gridEngine.hide();
    },
    initScheduler() {
      const self = this;
      self.schEngine = self.$refs.scheduler.schedulerEngine;
      self.IsGridReady = true;
      const schButtons = [
        {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-arrow-left',
          tooltip: self.translate('previous_month'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.currentDate = self.dateHelper.add(self.currentDate, -1, 'month');
            self.getEvents();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-arrow-down',
          tooltip: self.translate('today'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.currentDate = self.dateHelper.getFirstDateOfMonth(new Date());
            self.getEvents();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-arrow-right',
          tooltip: self.translate('next_month'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.currentDate = self.dateHelper.add(self.currentDate, 1, 'month');
            self.getEvents();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-search-plus',
          tooltip: self.translate('zoom_in'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.schEngine.zoomIn();
            blockui.unBlockPage();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-search',
          tooltip: self.translate('zoom_reset'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.schEngine.zoomTo({
              preset: 'dayAndWeek',
              startDate: self.currentDate,
              endDate: self.dateHelper.add(self.currentDate, 1, 'month'),
            });
            if (self.currentDate.getTime() == self.dateHelper.getFirstDateOfMonth(new Date()).getTime()) {
              self.$nextTick(() => {
                self.schEngine.scrollToNow({ block: 'start' });
              });
            }
            blockui.unBlockPage();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-search-minus',
          tooltip: self.translate('zoom_out'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.schEngine.zoomOut();
            blockui.unBlockPage();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-toggle-on',
          tooltip: self.translate('toggle_view'),
          onAction: function onAction() {
            self.IsDisplayGrid = !self.IsDisplayGrid;
            self.IsDisplayGrid ? self.gridEngine.show() : self.gridEngine.hide();
            !self.IsDisplayGrid ? self.schEngine.show() : self.schEngine.hide();

            toolsContainer[0].items.forEach((item) => {
              if (item.icon.includes('b-fa-search')) {
                self.IsDisplayGrid ? item.hide() : item.show();
              }
            });
          },
        }];

      let toolsContainer = self.widgetHelper.append({
        type: 'container',
        widgets: schButtons,
        cls: 'pull-left',
      }, { insertFirst: document.getElementsByClassName('schContainer')[0] });

      const schFilterButtons = [{
        title: 'colored',
        type: 'button',
        icon: 'b-fa b-fa-tasks',
        tooltip: self.translate('work_order'),
        cls: 'b-raised b-green',
        margin: 5,
        onAction: function onAction() {
          self.schEngine.eventStore.clearFilters();
          if (!this.currentElement.classList.contains('filtered')) {
            filterContainer[0].items.forEach((btn) => {
              btn.currentElement.classList.remove('filtered');
              btn.icon = btn.title === 'colored' ? 'b-fa b-fa-tasks' : 'b-fa b-fa-wrench';
            });
            this.currentElement.classList.add('filtered');
            this.icon = 'b-fa b-fa-filter';
            self.schEngine.eventStore.filter((x) => x.eventStyle === this.title);
          } else {
            this.currentElement.classList.remove('filtered');
            this.icon = 'b-fa b-fa-tasks';
          }
        },
      }, {
        title: 'plain',
        type: 'button',
        icon: 'b-fa b-fa-wrench',
        tooltip: self.translate('maintenance_plan'),
        cls: 'b-raised b-gray',
        margin: 5,
        onAction: function onAction() {
          self.schEngine.eventStore.clearFilters();
          if (!this.currentElement.classList.contains('filtered')) {
            filterContainer[0].items.forEach((btn) => {
              btn.currentElement.classList.remove('filtered');
              btn.icon = btn.title === 'colored' ? 'b-fa b-fa-tasks' : 'b-fa b-fa-wrench';
            });
            this.currentElement.classList.add('filtered');
            this.icon = 'b-fa b-fa-filter';
            self.schEngine.eventStore.filter((x) => x.eventStyle === this.title);
          } else {
            this.currentElement.classList.remove('filtered');
            this.icon = 'b-fa b-fa-wrench';
          }
        },
      }];

      let filterContainer = self.widgetHelper.append({
        type: 'container',
        widgets: schFilterButtons,
        cls: 'pull-right',
      }, { insertFirst: document.getElementsByClassName('schContainer')[0] });

      self.schEngine.eventEdit.editorConfig = {
        title: '',
        bbar: { widgets: [] },
        width: 400,
        triggerEvent: '',
        widgets: [
          {
            type: 'combo',
            label: self.translate('work'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'workId',
            required: true,
            value: '',
            filterOperator: '*',
            items: [{ duration: 0, text: '', value: 0 }],
            onAction: function onAction(e) {
              if (e.record) self.schEngine.eventEdit.editor.widgets.find((x) => x.name == 'durationMinute').value = e.record.duration;
            },
          },
          {
            type: 'number',
            label: `${self.translate('duration')} (${self.translate('minute')})`,
            labelWidth: 400,
            name: 'durationMinute',
            value: 0,
            min: 1,
          },
          {
            type: 'combo',
            label: self.translate('maintenance_type'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'maintenanceTypeId',
            value: '',
            filterOperator: '*',
            items: [{ text: '', value: 0 }],
          },
          {
            type: 'date',
            label: self.translate('start_date'),
            labelWidth: 400,
            name: 'startDate',
            editable: false,
          },
          {
            type: 'time',
            label: self.translate('start_time'),
            cls: 'startTime',
            labelWidth: 400,
            name: 'startDate',
            editable: false,
          },
          {
            type: 'text',
            labelWidth: 550,
            label: self.translate('note'),
            name: 'note',
            clearable: true,
          },
          {
            type: 'button',
            cls: 'b-green b-raised',
            text: self.translate('save'),
            name: 'save',
            onAction: async function onAction(e) {
              e.source.owner.mask(bryntum.scheduler.LocaleManager.locale.Grid.loadMask);
              const { values } = self.schEngine.eventEdit;
              const plan = {
                Id: values.id,
                WorkId: values.workId,
                Duration: values.durationMinute,
                MaintenanceTypeId: values.maintenanceTypeId,
                StartDate: dateFormat.postDateTimeFormat(values.startDate),
                Note: values.note,
              };
              blockui.pageBlock(self);
              plan.Id = Number.isInteger(plan.Id) ? plan.Id : 0;
              if (plan.Id) {
                await self.updatePlan(plan, true);
              } else {
                await self.savePlan(plan);
              }
              e.source.owner.unmask();
            },
          },
          {
            type: 'button',
            cls: 'b-red b-raised',
            text: self.translate('delete'),
            name: 'delete',
            onAction: function onAction(e) {
              blockui.pageBlock(self);
              const { id } = self.schEngine.eventEdit.values;
              self.deletePlan(id);
            },
          },
          {
            type: 'button',
            cls: 'b-gray b-raised',
            text: self.translate('cancel'),
            name: 'cancel',
            onAction: function onAction(e) {
              self.schEngine.eventEdit.editor.hide();
            },
          },
          {
            type: 'text',
            cls: 'b-hidden',
            editable: 'false',
            text: 'Id',
            name: 'id',
          },
          {
            type: 'text',
            cls: 'b-hidden',
            editable: 'false',
            text: 'ResourceId',
            name: 'resourceId',
          }],
      };

      self.schEngine.on('beforeEventEdit', ({ eventRecord, resourceRecord, eventElement }) => {
        // TODO - Eren
        if (eventRecord.eventStyle == 'colored') {
          self.fastAssign(eventRecord.id, eventRecord.name, eventRecord.resource.capacityEquipment, eventRecord.data.startDate, eventRecord.data.endDate, eventRecord.workId);
          // self.$router.push({name: 'AddWorkOrder', query: {workOrderId: eventRecord.id}});
          return false;
        }
        const { widgets } = self.schEngine.eventEdit.editor;
        api.getWorkListWithBreadCrumb({ deviceId: resourceRecord.id }).then((res) => {
          widgets.find((x) => x.name == 'workId').items = res.body.map((item) => ({
            text: item.Name,
            value: item.Id,
            duration: item.Duration,
          }));
          widgets.find((x) => x.name == 'maintenanceTypeId').items = self.maintenanceTypeList;
          widgets.find((x) => x.name == 'id').value = Number.isInteger(eventRecord.id) ? eventRecord.id : 0;
          widgets.find((x) => x.name == 'resourceId').value = resourceRecord.id;
          widgets.find((x) => x.name == 'maintenanceTypeId').value = eventRecord.data.maintenanceTypeId ? eventRecord.data.maintenanceTypeId : null;
          widgets.find((x) => x.name == 'workId').value = eventRecord.data.workId ? eventRecord.data.workId : null;
          widgets.find((x) => x.name == 'note').value = eventRecord.data.note ? eventRecord.data.note : null;
          widgets.find((x) => x.name == 'durationMinute').value = self.dateHelper.diff(eventRecord.startDate, eventRecord.endDate, 'minute');
        });
        if (Number.isInteger(eventRecord.id)) {
          widgets.find((x) => x.name == 'delete').show();
          widgets.find((x) => x.name == 'workId').hide();
        } else {
          widgets.find((x) => x.name == 'delete').hide();
          widgets.find((x) => x.name == 'workId').show();
        }
      });

      self.schEngine.on('beforeeventdropfinalize', ({ context }) => {
        blockui.pageBlock(self);
        context.async = true;
        context.draggedRecords.forEach(async (record) => {
          const plan = {
            Id: record.id,
            WorkId: record.data.workId,
            StartDate: dateFormat.postDateTimeFormat(context.startDate),
            Duration: self.dateHelper.diff(context.startDate, context.endDate, 'm'),
            MaintenanceTypeId: record.data.maintenanceTypeId,
            Note: record.data.note ? record.data.note : null,
          };
          const success = await self.updatePlan(plan, false);
          context.finalize(success);
        });
      });

      self.schEngine.on('scheduleDblClick', (context) => {
        if (!Number.isInteger(context.resourceRecord.id)) self.schEngine.eventEdit.editor.hide();
      });

      self.schEngine.on('beforeeventresizefinalize', async ({ context }) => {
        blockui.pageBlock(self);
        context.async = true;
        const plan = {
          Id: context.eventRecord.id,
          WorkId: context.eventRecord.data.workId,
          StartDate: dateFormat.postDateTimeFormat(context.startDate),
          Duration: self.dateHelper.diff(context.startDate, context.endDate, 'm'),
          MaintenanceTypeId: context.eventRecord.data.maintenanceTypeId,
          Note: context.eventRecord.data.note ? context.eventRecord.data.note : null,
        };
        const success = await self.updatePlan(plan, false);
        context.finalize(success);
        self.schEngine.refreshWithTransition();
      });

      self.schEngine.on({
        eventcontextmenu(context) {
          if (context.eventRecord.eventStyle === 'colored') {
            context.event.preventDefault();
            context.event.stopPropagation();
          }
        },
        contextMenuItem({ record, item }) {

        },
        async eventcontextmenuitem({ source, item, eventRecord }) {
          if (item.cls === 'transform_to_work_order') {
            blockui.pageBlock(self);
            api.transformToWorkOrder(eventRecord.id).then((response) => {
              if (response.body.IsSuccess) {
                blockui.unBlockPage();
                self.fastAssign(eventRecord.id, eventRecord.name, eventRecord.resource.capacityEquipment, eventRecord.data.startDate, eventRecord.data.endDate, eventRecord.workId);
                // TODO - Erdi take the color from response
                self.schEngine.eventStore.getById(eventRecord.id).eventColor = 'lime';
                self.schEngine.eventStore.getById(eventRecord.id).eventStyle = 'colored';
                self.schEngine.eventStore.getById(eventRecord.id).draggable = false;
                self.schEngine.renderContents();
                // self.$router.push({name: 'AddWorkOrder', query: {workOrderId: eventRecord.id}});
              } else {
                toastr.error(response.body.Message);
                blockui.unBlockPage();
              }
            }, (error) => {
              blockui.unBlockPage();
            });
          } else if (item.icon == 'b-icon b-icon-trash') {
            blockui.pageBlock(self);
            const success = await self.deletePlan(eventRecord.id);
            if (!success) {
              self.$nextTick(() => {
                self.schEngine.eventStore.add(eventRecord);
              });
            }
          }
        },
      });

      self.schEngine.scrollToNow({ block: 'start' });
    },

    getResources() {
      const self = this;
      api.getCapacityEquipmentList().then((res) => {
        self.resources = res.body.Data.map((item) => ({
          id: item.Id,
          capacityEquipment: item.Name,
          roomId: item.RoomId,
          room: item.Location,
          modelId: item.ModelId,
          model: item.ModelName,
          brandId: item.BrandId,
          brand: item.BrandName,
          capacityTypeId: item.CapacityTypeId,
          capacityType: item.CapacityTypeBreadCrumb,
        }));
      });
    },

    getTimeRanges() {
      const self = this;
      const formData = {
        beginDate: dateFormat.postDateTimeFormat(self.dateHelper.getFirstDateOfMonth(self.currentDate)),
        endDate: dateFormat.postDateTimeFormat(self.dateHelper.add(self.dateHelper.getFirstDateOfMonth(self.currentDate), 1, 'month')),
      };
      self.timeRanges = [];
      // todo merge validations
      // todo include single resource validations
      api.validateWorkSchedule(formData).then((res) => {
        if (res.body.IsSuccess) {
          res.body.Data.map((item) => {
            self.timeRanges.push({
              name: item.Name,
              cls: `shift timerange-${item.IsSuitable ? 'gray' : 'orange'}`,
              startDate: new Date(item.ScheduledBeginDate),
              endDate: new Date(item.ScheduledEndDate),
            });
          });
        } else {
          toastr.error(res.body.Message);
        }
      }, (error) => {
      });
      api.validateDeviceSchedule(formData).then((res) => {
        if (res.body.IsSuccess) {
          res.body.Data.map((item) => {
            self.timeRanges.push({
              name: item.Name,
              cls: `shift timerange-${item.IsSuitable ? 'cyan' : 'violet'}`,
              startDate: new Date(item.ScheduledBeginDate),
              endDate: new Date(item.ScheduledEndDate),
            });
          });
        } else {
          toastr.error(res.body.Message);
        }
      }, (error) => {
      });
      api.validateRoomSchedule(formData).then((res) => {
        if (res.body.IsSuccess) {
          res.body.Data.map((item) => {
            self.timeRanges.push({
              name: item.Name,
              cls: `shift timerange-${item.IsSuitable ? 'lime' : 'red'}`,
              startDate: new Date(item.ScheduledBeginDate),
              endDate: new Date(item.ScheduledEndDate),
            });
          });
        } else {
          toastr.error(res.body.Message);
        }
      }, (error) => {
      });
    },

    getEvents() {
      const self = this;
      if (self.schEngine) {
        self.schEngine.zoomTo({
          preset: 'dayAndWeek',
          startDate: self.currentDate,
          endDate: self.dateHelper.add(self.currentDate, 1, 'month'),
        });
        if (self.currentDate.getTime() == self.dateHelper.getFirstDateOfMonth(new Date()).getTime()) {
          self.$nextTick(() => {
            self.schEngine.scrollToNow({ block: 'start' });
          });
        }
      }
      const year = self.currentDate.getFullYear();
      const month = self.currentDate.getMonth();

      api.getMaintenancePlanCalendarList({ month: month + 1, year }).then((res) => {
        self.events = res.body.Data.map((item) => ({
          id: item.Id,
          resourceId: item.DeviceId,
          name: item.WorkName + (item.MaintenanceGroupId ? ` (${item.MaintenanceGroupName})` : ''),
          startDate: new Date(item.StartDate),
          endDate: new Date(item.EndDate),
          maintenanceGroupId: item.MaintenanceGroupId,
          maintenanceGroupName: item.MaintenanceGroupName,
          maintenanceTypeId: item.OrderTypeId,
          workId: item.WorkId,
          note: item.Note ? item.Note : null,
          eventColor: item.ColorName.toLowerCase(),
          eventStyle: 'plain',
          durationUnit: 'minute',
        }));

        api.getWorkOrderList({
          fromDate: dateFormat.postDateTimeFormat(new Date(year, month, 1)),
          endDate: dateFormat.postDateTimeFormat(new Date(year, month + 1, 0)),
        }).then((res) => {
          const workOrderEvents = res.body.Data.map((item) => ({
            id: item.Id,
            resourceId: item.DeviceId,
            name: item.WorkName + (item.MaintenanceGroupId ? ` (${item.MaintenanceGroupName})` : ''),
            startDate: new Date(item.StartDate),
            endDate: new Date(item.EndDate),
            maintenanceGroupId: item.MaintenanceGroupId,
            maintenanceGroupName: item.MaintenanceGroupName,
            maintenanceTypeId: item.OrderTypeId,
            workId: item.WorkId,
            note: item.Note ? item.Note : null,
            eventColor: item.ColorName.toLowerCase(),
            durationUnit: 'minute',
            draggable: false,
            resizable: false,
            eventStyle: 'colored',
          }));
          self.events = self.events.concat(workOrderEvents);
          blockui.unBlockPage();
        });
      });

      self.getTimeRanges();
    },

    async deletePlan(id) {
      const self = this;
      let success = false;
      await api.deleteMaintenancePlan(id).then((response) => {
        if (response.body.IsSuccess) {
          success = true;
          if (self.schEngine.eventEdit.editor) self.schEngine.eventEdit.editor.hide();
          toastr.success(self.translate('delete_successfully'));
          const record = self.schEngine.eventStore.getById(id);
          if (record) record.remove();
        } else {
          toastr.error(response.body.Message);
        }
      });
      blockui.unBlockPage();
      return success;
    },

    async savePlan(plan) {
      const self = this;
      const { values } = self.schEngine.eventEdit;
      await api.saveMaintenancePlan(plan).then((response) => {
        if (response.body.IsSuccess) {
          self.schEngine.eventEdit.editor.hide();
          toastr.success(self.translate('save_successfully'));
          self.schEngine.eventStore.add({
            id: parseInt(response.body.Messages.find((x) => x.Key == 'Id').Value),
            resourceId: values.resourceId,
            name: self.schEngine.eventEdit.editor.widgets.find((x) => x.name == 'workId').inputValue,
            startDate: values.startDate,
            endDate: self.dateHelper.add(values.startDate, values.durationMinute, 'minute'),
            maintenanceTypeId: values.maintenanceTypeId,
            workId: values.workId,
            note: values.note,
            eventColor: response.body.Messages.find((x) => x.Key == 'OrderStatus.Color').Value.toLowerCase(),
            durationUnit: 'minute',
            eventStyle: 'plain',
          });
          self.schEngine.refreshWithTransition();
        } else {
          toastr.error(response.body.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },

    async updatePlan(plan, updateDom) {
      const self = this;
      let success = false;
      await api.updateMaintenancePlan(plan).then((response) => {
        if (response.body.IsSuccess) {
          self.schEngine.eventEdit.editor.hide();
          toastr.success(self.translate('maintenance_plan_calendar_updated'));
          success = true;
          if (updateDom) {
            self.schEngine.eventStore.getById(plan.Id).set({
              note: plan.Note,
              maintenanceTypeId: plan.MaintenanceTypeId,
              startDate: new Date(plan.StartDate),
              duration: plan.Duration,
            });
          }
        } else {
          toastr.error(response.body.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
      return success;
    },

    async getMaintenanceTypes() {
      const self = this;
      await api.getWorkOrderTypes().then((res) => {
        self.maintenanceTypeList = res.body.map((item) => ({
          text: item.Text,
          value: item.Value,
        }));
      });
    },
    fastAssign(workOrderId, workOrderName, capacityEquipment, startDate, endDate, workId) {
      const self = this;
      self.workOrder.workOrderId = workOrderId;
      self.workOrder.workOrderName = workOrderName;
      self.workOrder.capacityEquipment = capacityEquipment;
      self.workOrder.workOrderStartDate = startDate;
      self.workOrder.workOrderEndDate = endDate;
      self.workOrder.workId = workId;
      self.showFastAssign = true;
    },
  },
};
