import Tabs from '../../../wrappers/TabView/Tabs/Tabs.vue';
import Tab from '../../../wrappers/TabView/Tab/Tab.vue';
import * as api from '../../../../scripts/services/api';
import * as blockui from '../../../../helpers/block-ui';
import { mixinz } from '../../../../scripts/global/mixinz';
import CFMMultiselect from '../../../partials/CFMMultiselect';
import UserOverlay from '../../../partials/user/UserOverlay';

export default {
  mixins: [mixinz],
  props: ['actorId', 'from'],
  data() {
    return {
      actors: [],
      roles: [],
      users: [],
      selectedRoles: [],
      selectedUsers: [],
      selectedActorId: null,
      multiSelectProps: {
        'close-on-select': false,
        'clear-on-select': false,
        'preserve-search': true,
      },
    };
  },
  components: {
    'cfm-multiselect': CFMMultiselect,
    tab: Tab,
    tabs: Tabs,
  },
  methods: {
    selectedActor(actor) {
      blockui.pageBlock(this);
      this.selectedActorId = actor.id;
      api.getActorRolesAndUsers(
        {
          actorId: this.selectedActorId,
        },
      ).then((response) => {
        this.selectedUsers = response.body.ActorUsers;
        this.selectedRoles = response.body.ActorRoles;
        blockui.unBlockPage(this);
      });
    },
    selectedUser(selectedUser) {
      this.selectedUsers.push({
        ActorId: this.selectedActorId,
        UserId: selectedUser.Value,
      });
    },
    selectedRole(selectedRole) {
      this.selectedRoles.push({
        ActorId: this.selectedActorId,
        RoleId: selectedRole.Value,
      });
    },
    removedUser(selectedUser) {
      const index = this.selectedUsers.findIndex((x) => x.ActorId == this.selectedActorId && x.UserId == selectedUser.Value);
      this.selectedUsers.splice(index, 1);
    },
    removedRole(selectedRole) {
      const index = this.selectedRoles.findIndex((x) => x.ActorId == this.selectedActorId && x.RoleId == selectedRole.Value);
      this.selectedRoles.splice(index, 1);
    },
    onSubmit() {
      const self = this;
      blockui.pageBlock(this);
      api.saveActorRolesAndUsers({
        ActorId: this.selectedActorId,
        ActorRoles: this.selectedRoles,
        ActorUsers: this.selectedUsers
      }).then((response) => {
        if (response.body.IsSuccess) {
          toastr.success(self.translate('changes_applied_successfully'));
        } else {
          toastr.error(response.body.Message);
        }
        blockui.unBlockPage(this);
      });
    },
    back() {
      const self = this;
      self.$router.push({ name: 'Authorization' });
    },
  },
  computed: {
    computedSelectedUsers: {
      get() {
        return this.selectedUsers;
      },
      set(newVal) {
        console.log('newval', newVal);
        console.log('selectedUsers', this.selectedUsers);
        if (newVal) {
          this.selectedUsers = newVal.map((x) => ({
            ...x,
            ActorId: this.selectedActorId,
            Actor: null,
            IsActive: true,
            User: this.users.find((m) => m.Id == x.User.Id)
          }));
        } else {
          this.selectedUsers = [];
        }
      },
    },
    selectedUserObjects() {
      return this.selectedUsers.map((selectedUser) => {
        const usr = this.users.find((user) => user.Value == selectedUser.UserId);
        return {
          Text: usr ? usr.Text : 'undefined',
          Value: selectedUser.UserId,
        };
      });
    },
    selectedRoleObjects() {
      return this.selectedRoles.map((selectedRole) => {
        const rl = this.roles.find((role) => role.Value == selectedRole.RoleId);
        return {
          Text: rl ? rl.Text : 'undefined',
          Value: selectedRole.RoleId,
        };
      });
    },
  },
  async mounted() {
    blockui.pageBlock(this);
    const promises = [
      api.getActorList().then((response) => {
        this.actors = response.body;
      }),
      api.getUserRoles().then((response) => {
        this.roles = response.body;
      }),
      api.getUserListWithoutIncludes().then((response) => { // api.getUsers().then((response) => {
        this.users = response.body;
      }),
    ];

    await Promise.all(promises);
    blockui.unBlockPage(this);
  },
};
