<template>
    <div>
        <tool-calendar-component :toolId="toolId" @updated="$emit('updated')"></tool-calendar-component>
    </div>
</template>

<script>
import ToolCalendarComponent from '../../ToolCalendar/ToolCalendarComponent.vue';

export default {
  components: { 'tool-calendar-component': ToolCalendarComponent },
  props: ['toolId'],
};
</script>
