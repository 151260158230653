import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as api from '../../services/api';
import Select2 from '../../../components/shared/select2';
import * as blockui from '../../../helpers/block-ui';
import * as swal from '../../../helpers/swal';

export default {
  props: {
    capacityEquipment: {
      default() {
        return {};
      },
      type: Object,
    },
    isUpdate: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  components: {
    select2: Select2,
  },
  computed: {
    ...mapState({
      referenceLibraryList: (state) => state.reference_library.referenceLibraryList,
      timeUnitList: (state) => state.unit.timeUnitList,
    }),
    ...mapGetters({
      getDocumentStatusText: 'getDocumentStatusText',
      getDocumentValidityStatusText: 'getDocumentValidityStatusText',
    }),
  },
  mounted() {
    const self = this;
    self.$store.dispatch('getReferenceLibraryList', { deviceId: self.capacityEquipment.Id });
    self.$store.dispatch('getTimeUnitList');
    self.$store.dispatch('getDocumentStatuses');
    self.$store.dispatch('getDocumentValidityStatuses');
    mApp.initScrollers();
  },
  methods: {
    getUnitListObjectById(id) {
      return this.timeUnitList.find((x) => x.Value == id);
    },
    downloadFile(id) {
      const self = this; let
        table;
      blockui.blockModal(self);
      api.getFile({ id, isContainsData: true }).then((response) => {
        self.downloadBlob(self.base64toBlob(response.body.Data.Base64, response.body.Data.ContentType), response.body.Data.Name);
        blockui.unBlockModal();
      });
    },
    base64toBlob(base64Data, type) {
      type = type || '';
      // Convert the Base64 string back to text.
      const byteString = atob(base64Data);

      // Convert that text into a byte array.
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type });
    },
    downloadBlob(blob, fileName) {
      const a = $("<a style='display: none;'/>");
      const url = window.URL.createObjectURL(blob);
      a.attr('href', url);
      a.attr('download', fileName);
      $('body').append(a);
      a[0].click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    deleteItem(index) {
      const self = this;
      const id = self.referenceLibraryList[index].Id;
      const name = self.referenceLibraryList[index].Name;
      swal.check(self, name).then((e) => {
        if (e.value) {
          api.deleteReferenceLibraryItem(id).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              self.$store.dispatch('getReferenceLibraryList', { deviceId: self.capacityEquipment.Id });
            } else {
              toastr.error(response.body.Message);
            }
          });
        }
      });
    },
    editItem(index) {
      this.$parent.$parent.editItem('referenceLibraryItems', this.referenceLibraryList[index].Id);
    },
  },
  watch: {
    referenceLibraryList(e) {
      if (e) {
        this.$parent.selectedCapacityEquipment.ReferenceLibraryCount = e.length;
      }
    },
  },
};
