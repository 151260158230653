import Vue from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import * as swal from '../../../../../helpers/swal';
import { mixinz } from '../../../../../scripts/global/mixinz';
import WarehouseTree from '../Warehouse/Warehouse.vue';
import AddToolDetailList from './AddToolDetailList.vue';

export default {
  mixins: [mixinz],
  props: [],
  components: {
    'warehouse-tree': WarehouseTree,
    'add-tool-detail-list': AddToolDetailList,
  },
  data() {
    return {
      quickAddModel: {
        TypeId: null,
        SubTypeId: null,
        BrandId: null,
        ModelId: null,
        WarehouseId: null,
      },
      selectedWarehouse: null,
      quantity: 1,
      showEntriesDetail: false,
      entries: [],
    };
  },
  validations() {
    return {
      quickAddModel: {
        TypeId: {
          required,
          minValue: minValue(1),
        },
        WarehouseId: {
          required,
          minValue: minValue(1),
        },
      },
    };
  },
  async created() {
    this.initSelects([{ name: 'getToolTypes' }, { name: 'getBrands' }]);
  },
  mounted() {
    const self = this;
    $('#tool_modal').on('hidden.bs.modal', () => {
      self.$emit('closed');
    });
  },
  computed: {
    ...mapGetters({
      toolTypes: 'tool/toolTypes',
      toolSubTypes: 'tool/toolSubTypes',
      toolBrands: 'tool/toolBrands',
      toolModels: 'tool/toolModels',
    }),
    // #region computed select
    toolType: {
      get() {
        const self = this;
        return self.selectGet('toolTypes', 'quickAddModel.TypeId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('quickAddModel.TypeId', 'Id', newValue);
      },
    },
    toolSubType: {
      get() {
        const self = this;
        return self.selectGet('toolSubTypes', 'quickAddModel.SubTypeId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('quickAddModel.SubTypeId', 'Id', newValue);
      },
    },
    toolBrand: {
      get() {
        const self = this;
        return self.selectGet('toolBrands', 'quickAddModel.BrandId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('quickAddModel.BrandId', 'Id', newValue);
      },
    },
    toolModel: {
      get() {
        const self = this;
        return self.selectGet('toolModels', 'quickAddModel.ModelId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('quickAddModel.ModelId', 'Id', newValue);
      },
    },
    // #endregion select
  },
  methods: {
    ...mapActions({
      getToolTypes: 'tool/getToolTypes',
      getToolSubTypes: 'tool/getToolSubTypes',
      getBrands: 'tool/getBrands',
      getModels: 'tool/getModels',
    }),
    ...mapMutations({
      setToolModels: 'tool/setToolModels',
      setToolSubTypes: 'tool/setToolSubTypes',
    }),
    async initSelects(methods) {
      const self = this;
      const promises = methods.map((method) => {
        const args = method.args ? method.args : [];
        return self[method.name](...args);
      });
      try {
        // await blockui.blockElementPromise(self,"#tool_modal");
        blockui.blockModalPromise(self, '#tool_modal');
        await Promise.all(promises);
      } catch (err) {
        throw new Error(err);
      } finally {
        blockui.unBlockModal();
      }
    },
    async onSubmit(e) {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        blockui.blockModalPromise(self, '#tool_modal');
        const result = await api.saveTool({ tool: self.quickAddModel, quantity: self.quantity });
        if (!result.body.IsSuccess) {
          throw result.body.Message;
        }
        const savedEntryIds = result.body.Messages.map((keyValuePair) => keyValuePair.Value);
        self.reloadDataTable();
        if ((await swal.check(self, self.translate('save_successfully'), self.translate('would_you_like_to_detail_entries'), 'success')).value) {
          self.entries = savedEntryIds.map((id) => ({ Id: id, ...self.quickAddModel }));
          self.showEntriesDetail = true;
        } else {
          self.closeModal();
        }
      } catch (err) {
        toastr.error(err);
        throw new Error(err);
      } finally {
        blockui.unBlockModal();
      }

      /* const form = window["$"](e.target);
            if (form.valid()) {
                let formData = {};
                blockui.blockModal(self);
                $.map(form.serializeArray(), item => {
                    Object.assign(formData, {[item.name]: item.value});
                });
                //api.saveContinent(formData).then(function (response) {
                api.saveToolType(formData).then(function (response) {
                    if (response.body.IsSuccess) {
                        toastr.success(self.translate('save_successfully'));
                        self.closeModal();
                        //self.$store.dispatch('getRelatedContinentList', self.$router.history.current.name);
                    } else {
                        toastr.error(response.body.Message);
                    }
                    blockui.unBlockModal();
                }, (error) => {
                    blockui.unBlockModal();
                });
            } */
    },
    setSelectedLocation(e) {
      if (e && e.Id) {
        this.quickAddModel.WarehouseId = e.Id;
        this.selectedWarehouse = e;
      } else {
        this.quickAddModel.WarehouseId = null;
        this.selectedWarehouse = null;
      }
    },
    toolTypeChanged(value) {
      this.quickAddModel.SubTypeId = null;
      if (value && value.Id > 0) {
        this.initSelects([{ name: 'getToolSubTypes', args: [value.Id] }]);
      } else {
        this.setToolSubTypes([]);
      }
    },
    toolBrandChanged(value) {
      this.quickAddModel.ModelId = null;
      if (value && value.Id > 0) {
        this.initSelects([{ name: 'getModels', args: [value.Id] }]);
      } else {
        this.setToolModels([]);
      }
    },
    reloadDataTable() {
      // this.$emit('updated');
    },
    closeModal() {
      $('#tool_modal').modal('toggle');
    },
  },
  watch: {},
};
