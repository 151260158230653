import { mapState } from 'vuex';
import * as swal from '../../../helpers/swal';
import * as api from '../../services/api';

export default {
  data() {
    return {};
  },
  props: [
    'capacityEquipment',
    'isUpdate',
  ],
  computed: {
    ...mapState({
      descriptionList: (state) => state.description.descriptionList,
    }),
  },
  mounted() {
    const self = this; let
      table;
    self.$store.dispatch('getDescriptionList', { deviceId: self.capacityEquipment.Id });
    // mApp.initScrollers();
  },
  methods: {
    deleteItem(index) {
      const self = this;
      const id = self.descriptionList[index].Id;
      const name = self.descriptionList[index].Text;
      swal.check(self, name).then((e) => {
        if (e.value) {
          api.deleteDescription(id).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              self.$store.dispatch('getDescriptionList', { deviceId: self.capacityEquipment.Id });
            } else {
              toastr.error(response.body.Message);
            }
          });
        }
      });
    },
    editItem(index) {
      this.$parent.$parent.editItem('descriptionItems', this.descriptionList[index].Id);
    },
  },
  watch: {
    descriptionList(e) {
      if (e) {
        this.$parent.selectedCapacityEquipment.DescriptionCount = e.length;
      }
    },
  },
};
