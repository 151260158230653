

































































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import {CFMDTOWorkPeriod, CFMDTOUnit } from '@/scripts/services/ClientApiAuto'
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'


@Component<MaintenancePeriodDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
    },
    validations() {
        return {
        workPeriod:{
            Name: {required},
        },
        selectedUnit: {
            UnitId: {required}
        },
        }
    },
})
export default class MaintenancePeriodDialog extends CFMDialog {
    workPeriod: CFMDTOWorkPeriod = {Name: ''};
    unitList : {Name:String, Id:Number}[] = [];

    selectedUnit :CFMDTOWorkPeriod = {Name: ''}

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.workPeriod.workPeriodGetById({id: <number>this.id});
        console.log(res);
        this.workPeriod = res.data;
      }
      const promises = [
      this.getUnits()
      ];
      if(this.isUpdate){
        if(this.workPeriod && this.workPeriod.UnitId){
          this.selectedUnit = this.workPeriod;
          console.log(this.selectedUnit);
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    async getUnits(){
      const response = await this.apiClient.unit.unitGetUnits();
      this.unitList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Id: this.workPeriod.Id,
        Name: this.workPeriod.Name,
        Period: this.workPeriod.Period,
        UnitId: this.selectedUnit.UnitId
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.workPeriod.workPeriodSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
