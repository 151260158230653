import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import * as api from '../../services/api';
import * as blockui from '../../../helpers/block-ui';
import * as dateFormat from '../../../helpers/dateFormat';

export default {
  props: ['financialItem', 'isUpdate'],
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState({
      currencyUnitList: (state) => state.unit.currencyUnitList,
      financialModalReferenceLibraryList: (state) => state.reference_library.financialModalReferenceLibraryList,
    }),
    ...mapGetters({
      getFinancialModalServiceProviderComboBox: 'getFinancialModalServiceProviderComboBox',
    }),
  },
  mounted() {
    const self = this;
    window.$('form').validate();

    self.$nextTick(() => {
      Vue.loadAsteriks();
    });

    self.$store.dispatch('getCurrencyUnitList');
    self.$store.dispatch('getFinancialModalReferenceLibraryList', { deviceId: self.financialItem.DeviceId });
    self.$store.dispatch('getFinancialModalServiceProviderList', { deviceId: self.financialItem.DeviceId });

    $('#financial_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });

    const datetimePicker = $('#financial_modal .date-picker').datepicker({
      autoclose: true,
      language: localStorage.getItem('vue-lang'),
    });
    if (self.financialItem.ContractStartDate) {
      datetimePicker.datepicker('update', new Date(self.financialItem.ContractStartDate));
    }
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          if (item.name.startsWith('ContractStartDate')) {
            Object.assign(formData, { [item.name]: dateFormat.postDateFormat(item.value) });
          } else {
            Object.assign(formData, { [item.name]: item.value });
          }
        });
        api.saveFinancialItem(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            self.$store.dispatch('getFinancialItemList', self.financialItem.DeviceId);
            $('#financial_modal').modal('toggle');
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    closeModal() {
      $('#financial_modal').modal('toggle');
    },
  },
};
