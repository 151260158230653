import * as api from '../../scripts/services/api';

// initial state
const state = {
  infrastructureList: [],
  infrastructureGroupList: [],
  infrastructureTypeList: [],
  capacityTypeList: [],
  infrastructureListWithCapacityTypes: [],
};

// getters
const getters = {};

// actions
const actions = {
  getInfrastructureList({ commit }) {
    api.getInfrastructures().then((res) => {
      commit('setInfrastructureList', res.body);
    });
  },
  getInfrastructureGroupList({ commit }, data) {
    api.getInfrastructureGroups(data).then((res) => {
      commit('setInfrastructureGroupList', res.body);
    });
  },
  getInfrastructureTypeList({ commit }, data) {
    api.getInfrastructureTypes(data).then((res) => {
      commit('setInfrastructureTypeList', res.body);
    });
  },
  getCapacityTypeList({ commit }, data) {
    api.getCapacityTypes(data).then((res) => {
      commit('setCapacityTypeList', res.body);
    });
  },
  getInfrastructuresWithCapacityTypeList({ commit }) {
    api.getInfrastructuresWithCapacityTypes().then((res) => {
      commit('setInfrastructuresWithCapacityTypeList', res.body.Data);
    });
  },
};

// mutations
const mutations = {
  setInfrastructureList(state, infrastructures) {
    state.infrastructureList = infrastructures;
  },
  setInfrastructureGroupList(state, infrastructureGroups) {
    state.infrastructureGroupList = infrastructureGroups;
  },
  setInfrastructureTypeList(state, infrastructureTypes) {
    state.infrastructureTypeList = infrastructureTypes;
  },
  setCapacityTypeList(state, capacityTypes) {
    state.capacityTypeList = capacityTypes;
  },
  setInfrastructuresWithCapacityTypeList(state, getInfrastructuresWithCapacityTypes) {
    state.infrastructureListWithCapacityTypes = getInfrastructuresWithCapacityTypes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
