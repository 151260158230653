




























































































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import {required} from "vuelidate/lib/validators";

import { CFMDTOCapacityType, CFMDTOInfrastructureType, CFMDTOInfrastructureGroup } from '@/scripts/services/ClientApiAuto'

@Component<CapacityTypeDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
    },
    validations() {
        return {
        capacityType:{
            Name: {required}
        },
        selectedInfrastructure: {
            InfrastructureId: {required}
        },
        selectedInfrastructureGroup: {
            InfrastructureGroupId: {required}
        },
        selectedInfrastructureType: {
            InfrastructureTypeId: {required}
        },
        }
    },
})
export default class CapacityTypeDialog extends CFMDialog {
    capacityType: CFMDTOCapacityType = {Name: ''};
    infrastructureTypeList : {Name:string, Id:number}[] = [];
    infrastructureGroupList : {Name:string, Id:number}[] = [];
    infrastructureList : {Name:String, Id:Number}[] = [];

    selectedInfrastructure :CFMDTOInfrastructureGroup = {Name: ''}
    selectedInfrastructureGroup: CFMDTOInfrastructureType = {Name: ''};
    selectedInfrastructureType: CFMDTOCapacityType = {Name: ''};
    
    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.capacityType.capacityTypeGetById({id: <number>this.id});
        this.capacityType = res.data;
      }
      const promises = [
      this.getInfrastructures()
      ];
      if(this.isUpdate){
      if(this.capacityType && this.capacityType.InfrastructureTypeId){
          this.selectedInfrastructureType  = this.capacityType;
          if(this.capacityType.InfrastructureType && this.capacityType.InfrastructureType.InfrastructureGroupId){
            this.selectedInfrastructureGroup  = this.capacityType.InfrastructureType;
            promises.push(this.getInfrastructureTypes( <number>this.selectedInfrastructureGroup.InfrastructureGroupId ))
            if(this.capacityType.InfrastructureType.InfrastructureGroup && this.capacityType.InfrastructureType.InfrastructureGroup.InfrastructureId){
              this.selectedInfrastructure = this.capacityType.InfrastructureType.InfrastructureGroup;
              promises.push(this.getInfrastructureGroups( <number>this.selectedInfrastructure.InfrastructureId ));
            }
          }
      }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    async getInfrastructures(){
      const response = await this.apiClient.infrastructure.infrastructureGetInfrastructures();
      this.infrastructureList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getInfrastructureGroups(infrastructureId: number){
      const response = await this.apiClient.infrastructureGroup.infrastructureGroupGetInfrastructureGroups({infrastructureId: infrastructureId});
      this.infrastructureGroupList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getInfrastructureTypes(infrastructureGroupId: number){
      const response = await  this.apiClient.infrastructureType.infrastructureTypeGetInfrastructureTypes({infrastructureGroupId: infrastructureGroupId})
      this.infrastructureTypeList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    onInfrastructureChanged(args: any){
      this.getInfrastructureGroups(args.InfrastructureId);
      this.selectedInfrastructureGroup = {Name:''};
      this.selectedInfrastructureType = {Name:''};
    }
    onInfrastructureGroupChanged(args: any){
      this.getInfrastructureTypes(args.InfrastructureGroupId);
      this.selectedInfrastructureType = {Name:''};
    }
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Id: this.capacityType.Id,
        Name: this.capacityType.Name,
        InfrastructureTypeId : this.selectedInfrastructureType.InfrastructureTypeId,
        IsActive: true
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.capacityType.capacityTypeSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
