import * as api from '../../services/api';
import chart from './chart';
import * as swal from '../../../helpers/swal';

export default {
  mixins: [chart],
  data() {
    return {
      result: 0,
    };
  },
  async mounted() {
    // let self = this;
    // await self.getData();
    // self.initChart();
  },
  methods: {
    async updateData() {
      const self = this;
      await self.getData();
      // self.redraw();
    },
    async getData() {
      const self = this;
      try {
        const trainingDemands = (await api.getTrainingDemands({
          daysToCheck: self.value,
          isUser: true,
        })).body.Data;
        self.result = trainingDemands.filter((item) => item.Total > 0).length;
      } catch (e) {
        throw new Error(e);
      }
    },
    redraw() {

    },
    redirect() {
      const self = this;
      self.$router.push({ name: 'UserTrainingTracking', query: { usersNeedToBeGetTrainingLessThanDays: self.value } });
    },
  },
};
