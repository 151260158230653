import * as api from '../../scripts/services/api';

// initial state
const state = {
  // Continents
  continentList: [],
  countryModalContinentList: [],
  cityModalContinentList: [],
  districtModalContinentList: [],
  campusModalContinentList: [],
  // Countries
  countryList: [],
  campusModalCountryList: [],
  cityModalCountryList: [],
  districtModalCountryList: [],
  // Cities
  cityList: [],
  districtModalCityList: [],
  campusModalCityList: [],
  // Districts
  districtList: [],
  campusModalDistrictList: [],
  // Campuses
  campusList: [],
  // Selected
  selectedContinent: null,
  selectedCountry: null,
  selectedCity: null,
  selectedDistrict: null,
  countryModalSelectedContinent: null,
  cityModalSelectedContinent: null,
  cityModalSelectedCountry: null,
  districtModalSelectedContinent: null,
  districtModalSelectedCountry: null,
  districtModalSelectedCity: null,
  campusModalSelectedContinent: null,
  campusModalSelectedCountry: null,
  campusModalSelectedCity: null,
  campusModalSelectedDistrict: null,
  // Related Stores
  continentRelated: {
    Country: [
      'getContinentList',
      'getCountryModalContinentList',
    ],
    City: [
      'getContinentList',
      'getCountryModalContinentList',
      'getCityModalContinentList',
    ],
    District: [
      'getContinentList',
      'getCountryModalContinentList',
      'getCityModalContinentList',
      'getDistrictModalContinentList',
    ],
  },
  countryRelated: {
    City: [
      'getCountryList',
      'getCityModalCountryList',
    ],
    District: [
      'getCountryList',
      'getCityModalCountryList',
      'getDistrictModalCountryList',
    ],
  },
  cityRelated: {
    District: [
      'getCityList',
      'getDistrictModalCityList',
    ],
  },
  districtRelated: {
    Campus: [
      'getDistrictList',
      'getCampusModalDistrictList',
    ],
  },
};

// getters
const getters = {};

// actions
const actions = {
  // Continent
  getRelatedContinentList({ commit, dispatch }, pathName) {
    const relatedList = state.continentRelated[pathName];
    if (relatedList) {
      relatedList.forEach((item) => {
        dispatch(item);
      });
    }
  },
  getContinentList({ commit }) {
    commit('setContinentList', []);
    api.getContinents().then((res) => {
      commit('setContinentList', res.body);
    });
  },
  getCountryModalContinentList({ commit }) {
    commit('setCountryModalContinentList', []);
    api.getContinents().then((res) => {
      commit('setCountryModalContinentList', res.body);
    });
  },
  getCityModalContinentList({ commit }) {
    commit('setCityModalContinentList', []);
    api.getContinents().then((res) => {
      commit('setCityModalContinentList', res.body);
    });
  },
  getDistrictModalContinentList({ commit }) {
    commit('setDistrictModalContinentList', []);
    api.getContinents().then((res) => {
      commit('setDistrictModalContinentList', res.body);
    });
  },
  getCampusModalContinentList({ commit }) {
    commit('setCampusModalContinentList', []);
    api.getContinents().then((res) => {
      commit('setCampusModalContinentList', res.body);
    });
  },
  // Country
  getRelatedCountryList({ commit, dispatch }, pathName) {
    const relatedList = state.countryRelated[pathName];
    if (relatedList) {
      relatedList.forEach((item) => {
        dispatch(item);
      });
    }
  },
  getCountryList({ commit }) {
    commit('setCountryList', []);
    if (state.selectedContinent) {
      api.getCountries({ continentId: state.selectedContinent }).then((res) => {
        commit('setCountryList', res.body);
      });
    }
  },
  getCityModalCountryList({ commit }) {
    commit('setCityModalCountryList', []);
    if (state.cityModalSelectedContinent) {
      api.getCountries({ continentId: state.cityModalSelectedContinent }).then((res) => {
        commit('setCityModalCountryList', res.body);
      });
    }
  },
  getDistrictModalCountryList({ commit }) {
    commit('setDistrictModalCountryList', []);
    if (state.districtModalSelectedContinent) {
      api.getCountries({ continentId: state.districtModalSelectedContinent }).then((res) => {
        commit('setDistrictModalCountryList', res.body);
      });
    }
  },
  // City
  getRelatedCityList({ commit, dispatch }, pathName) {
    const relatedList = state.cityRelated[pathName];
    if (relatedList) {
      relatedList.forEach((item) => {
        dispatch(item);
      });
    }
  },
  getCityList({ commit }) {
    commit('setCityList', []);
    if (state.selectedCountry) {
      api.getCities({ countryId: state.selectedCountry }).then((res) => {
        commit('setCityList', res.body);
      });
    }
  },
  getDistrictModalCityList({ commit }) {
    commit('setDistrictModalCityList', []);
    if (state.districtModalSelectedCountry) {
      api.getCities({ countryId: state.districtModalSelectedCountry }).then((res) => {
        commit('setDistrictModalCityList', res.body);
      });
    }
  },
  // District
  getRelatedDistrictList({ commit, dispatch }, pathName) {
    const relatedList = state.districtRelated[pathName];
    if (relatedList) {
      relatedList.forEach((item) => {
        dispatch(item);
      });
    }
  },
  getDistrictList({ commit }) {
    commit('setDistrictList', []);
    if (state.selectedCity) {
      api.getDistricts({ cityId: state.selectedCity }).then((res) => {
        commit('setDistrictList', res.body);
      });
    }
  },
  getCampusModalDistrictList({ commit }) {
    commit('setCampusModalDistrictList', []);
    if (state.campusModalSelectedCity) {
      api.getDistricts({ cityId: state.campusModalSelectedCity }).then((res) => {
        commit('setCampusModalDistrictList', res.body);
      });
    }
  },
  // Campus
  getCampusList({ commit }) {
    commit('setCampusList', []);
    api.getAllCampuses().then((res) => {
      commit('setCampusList', res.body);
    });
  },
  getCampusModalCountryList({ commit }) {
    commit('setCampusModalCountryList', []);
    if (state.campusModalSelectedContinent) {
      api.getCountries({ continentId: state.campusModalSelectedContinent }).then((res) => {
        commit('setCampusModalCountryList', res.body);
      });
    }
  },
  getCampusModalCityList({ commit }) {
    commit('setCampusModalCityList', []);
    if (state.campusModalSelectedCountry) {
      api.getCities({ countryId: state.campusModalSelectedCountry }).then((res) => {
        commit('setCampusModalCityList', res.body);
      });
    }
  },
};

// mutations
const mutations = {
  // List Continent
  setContinentList(state, data) {
    state.continentList = data;
  },
  setCountryList(state, data) {
    state.countryList = data;
  },
  setCityList(state, data) {
    state.cityList = data;
  },
  setDistrictList(state, data) {
    state.districtList = data;
  },
  setCampusList(state, data) {
    state.campusList = data;
  },
  setCountryModalContinentList(state, data) {
    state.countryModalContinentList = data;
  },
  setCityModalContinentList(state, data) {
    state.cityModalContinentList = data;
  },
  setDistrictModalContinentList(state, data) {
    state.districtModalContinentList = data;
  },
  setCityModalCountryList(state, data) {
    state.cityModalCountryList = data;
  },
  setDistrictModalCountryList(state, data) {
    state.districtModalCountryList = data;
  },
  setDistrictModalCityList(state, data) {
    state.districtModalCityList = data;
  },
  setCampusModalContinentList(state, data) {
    state.campusModalContinentList = data;
  },
  setCampusModalCountryList(state, data) {
    state.campusModalCountryList = data;
  },
  setCampusModalCityList(state, data) {
    state.campusModalCityList = data;
  },
  setCampusModalDistrictList(state, data) {
    state.campusModalDistrictList = data;
  },
  // Selected
  setSelectedContinent(state, data) {
    state.selectedContinent = data;
  },
  setSelectedCountry(state, data) {
    state.selectedCountry = data;
  },
  setSelectedCity(state, data) {
    state.selectedCity = data;
  },
  setCityModalSelectedContinent(state, data) {
    state.cityModalSelectedContinent = data;
  },
  setDistrictModalSelectedContinent(state, data) {
    state.districtModalSelectedContinent = data;
  },
  setDistrictModalSelectedCountry(state, data) {
    state.districtModalSelectedCountry = data;
  },
  setCampusModalSelectedContinent(state, data) {
    state.campusModalSelectedContinent = data;
  },
  setCampusModalSelectedCountry(state, data) {
    state.campusModalSelectedCountry = data;
  },
  setCampusModalSelectedCity(state, data) {
    state.campusModalSelectedCity = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
