






















import { Component } from 'vue-property-decorator';
import { DataStateChangeEventArgs, recordDoubleClick, toolbarClick } from "@syncfusion/ej2-vue-grids";
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMDTOQAQC } from '@/scripts/services/ClientApiAuto'
import QAQCDialog from './QAQCDialog.vue'
@Component<QAQC>({
    components: {
        'qaqc-dialog': QAQCDialog
    }
})

export default class QAQC extends CFMGridPage<CFMDTOQAQC> {
    entityType = 'QAQC';
    sorts: { field: string, direction: string }[] = [{ field:"Id", direction: "Descending" }];
    toolbar: {text:string, tooltipText: string, prefixIcon: string, id: string}[] = [];
    async mounted() {
        this.dataStateChange(this.state);
        this.toolbar = [{
            text: 'Excel Export', tooltipText: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport'
        }, {
            text: 'CSV Export', tooltipText: 'CSV Export', prefixIcon: 'e-csvexport', id: 'CSVExport'
        }, {
            text: 'PDF Export', tooltipText: 'PDF Export', prefixIcon: 'e-pdfexport', id: 'PDFExport'
        }, {
            text: this.translate('add'), tooltipText: this.translate('add'), prefixIcon: 'e-add', id: 'Add',
        }, {
            text: this.translate('delete'), tooltipText: this.translate('delete'), prefixIcon: 'e-delete', id: 'Delete',
        }];
    }

    refreshGrid() {
        this.dataStateChange(this.state);
    }

    async delete(data: CFMDTOQAQC) {
        const e = await swal.check(this, data.Name);

        if(e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.QAQC.qaqcDelete({id: data.Id});

            if(response.data.IsSuccess){
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }

    async add() {
        this.selectedId = null;
        this.isDialogActive = true;
        await this.$nextTick();

        (<QAQCDialog>this.$refs.qaqcDialog).showDialog();
    }

    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
        this.toolbarClickBase(args);
    }
}
