/* eslint-disable no-undef */
import Vue from 'vue';
import { required, minValue, helpers } from 'vuelidate/lib/validators';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';
import FinancialInformation from '../../components/partials/contract/FinancialInformation';
import VendorInformation from '../../components/partials/contract/VendorInformation';
import ContractInformation from '../../components/partials/contract/ContractInformation';
import ContractSection from '../../components/partials/contract/ContractSection';
import AddAttachments from '../../components/partials/contract/AddAttachments';
import * as dateFormat from '../../helpers/dateFormat';
import * as swal from '../../helpers/swal';
import { deepCloneWithPrefixIgnore } from '../global/util';
import axios from 'axios';


export const baseUrl = process.env.VUE_APP_API_BASE_URL;
export const checkDay = (value) => (value.IsSelected ? (helpers.req(value.StartTime) && helpers.req(value.EndTime)) : true);
export const time = (value) => {
  if (!value || !value.IsSelected || !value.StartTime || !value.EndTime) {
    return true;
  }
  const splittedStartTime = value.StartTime.split(':');
  const splittedEndTime = value.EndTime.split(':');
  const startTime = new Date();
  startTime.setHours(splittedStartTime[0], splittedStartTime[1]);
  const endTime = new Date();
  endTime.setHours(splittedEndTime[0], splittedEndTime[1]);
  return (endTime > startTime);
};
/* export const price=(value)=>{
    if (!value) {
        return true;
    }else{
        const contractPrice=value.toString();
        if(contractPrice.includes('.')){
            const beforePoint=contractPrice.split('.')[0].length;
            const afterPoint=contractPrice.split('.')[1].length;
            return beforePoint>15||afterPoint>3;
        }else{
            return contractPrice.length>15;
        }
    }
} */

export default {
  props: {
    Id: {},
    IsEditable: { default: true },
  },
  components: {
    AddAttachments, ContractInformation, ContractSection, VendorInformation, FinancialInformation,
  },
  data() {
    return {
      Contract: {
        ContractPersonels: [],
      },
      validation: false,
      isReady: false,
    };
  },
  validations() {
    return {
      Contract: {
        Name: {
          required,
        },
        ContractNumber: {
          required,
        },
        StartDate: {
          required,
        },
        EndDate: {
          required,
        },
        VendorId: {
          required,
        },
        ContractDevices: {
          required,
        },
        ContractPrice: {
          /*    price */
        },
        ContractWorkDays: {
          $each: {
            checkDay,
            time,

          },
        },
      },
    };
  },
  mounted() {
    const self = this;
    if (self.Id) {
      api.getContract(self.Id).then((res) => {
        self.Contract = res.body;
        self.isReady = true;
      });
    } else {
      self.isReady = true;
    }
  },
  methods: {
    async onSubmit(e) {
      const self = this;
      self.validation = true;
      self.$v.$touch();
      if (self.$v.$invalid) {
        toastr.error(self.translate('please_fill_all_required_fields'));
        return;
      }
      if(self.Contract.Id !== undefined && self.Contract.Id !== null && self.Contract.Id > 0){
        const event = await swal.qaqccheck(this, this.translate('contract_are_you_sure') );
        if(!event.value){
          return;
        }
      }
      blockui.pageBlock(self);
      const startDateObj = new Date(self.Contract.StartDate);
      const endDateObj = new Date(self.Contract.EndDate);

      if (startDateObj > endDateObj) {
        toastr.error(self.translate('end_date_cannot_be_before_start_date'));
        blockui.unBlockPage();
      } else if (startDateObj.getTime() == endDateObj.getTime()) {
        toastr.error(self.translate('start_date_and_end_date_cannot_be_same'));
        blockui.unBlockPage();
      } else if (this.Contract.ContractPrice < 0) {
        toastr.error(self.translate('contract_price_can_not_be_less_than_zero'));
        blockui.unBlockPage();
      } else {
        const postData = {
          ...deepCloneWithPrefixIgnore(self.Contract, ['$']),
          StartDate: dateFormat.postDateTimeFormatFromDateObject(self.Contract.StartDate),
          EndDate: dateFormat.postDateTimeFormatFromDateObject(self.Contract.EndDate),
        };
        postData.ContractWorkDays = postData.ContractWorkDays.filter((x) => x.IsSelected);
        this.$globalApiClient.contract.contractSave(postData).then((response)=> {
          if (response.data.IsSuccess) {
            toastr.success(self.translate('item_saved_successfully').formatString(self.Contract.Name));
            if (!self.Id) {
              const IdKeyValue = response.data.Messages.find((x) => x.Key == 'Id');
              if (IdKeyValue) {
                self.$router.push({ name: 'Contract', query: { Id: IdKeyValue.Value } });
              }
            } else {
              self.$eventHub.$emit('updatedContract', self.Id);
            }
            blockui.unBlockPage();
          } else {
            toastr.error(response.data.Message);
            blockui.unBlockPage();
          }
        }, (error) => {
          blockui.unBlockPage();
        });
      }
    },
    close() {
      const self = this;
      self.$router.push({ name: 'ContractList' });
    },

  },

};
