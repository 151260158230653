





































































































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import {required, maxLength} from "vuelidate/lib/validators";

import { CFMDTODistrict, CFMDTOCountry, CFMDTOCity } from '@/scripts/services/ClientApiAuto'

@Component<DistrictDialog>({

    components: {
        'cfm-multiselect': CFMMultiselect,
    },

    validations() {
        return {
        district:{
            Name: {required},
            Code: 
                {
                  required,
                  maxLength :maxLength(5),
                }
        },
        selectedContinent: {
            ContinentId: {required}
        },
        selectedCountry: {
            CountryId: {required}
        },
        selectedCity: {
            CityId: {required}
        },
        }
    },
})
export default class DistrictDialog extends CFMDialog {
    district: CFMDTODistrict = {Name: '', Code:''};
    continentList : {Name:string, Id:number}[] = [];
    countryList : {Name:string, Id:number}[] = [];
    cityList : {Name:String, Id:Number}[] = [];

    selectedContinent :CFMDTOCountry = {Name: ''}
    selectedCountry: CFMDTOCity = {Name: ''};
    selectedCity: CFMDTODistrict = {Name: '', Code: ''};
    

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.district.districtGetById({id: <number>this.id});
        this.district = res.data;
      }
      const promises = [
        this.getContinents()
      ];
      if(this.isUpdate){
        if(this.district && this.district.CityId){
          this.selectedCity = this.district;
          if (this.district.City && this.district.City.CountryId) {
            this.selectedCountry = this.district.City;
              promises.push(this.getCities( <number>this.selectedCountry.CountryId ));
              if (this.district.City.Country && this.district.City.Country.ContinentId) {
                this.selectedContinent = this.district.City.Country;
                promises.push(this.getCountries( <number>this.selectedContinent.ContinentId ));
              }
          }
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    async getContinents(){
      const response = await this.apiClient.continent.continentGetContinents();
      this.continentList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getCountries(continentId: number){
      const response = await this.apiClient.country.countryGetCountries({continentId: continentId});
      this.countryList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getCities(countryId: number){
      const response = await this.apiClient.city.cityGetCities({countryId: countryId});
      this.cityList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    onContinentChanged(args: any){
      this.getCountries(args.ContinentId);
      this.selectedCountry = {Name:''};
      this.selectedCity = {Name:'',Code:''};
    }
    onCountryChanged(args: any){
      console.log(args);
      this.getCities(args.CountryId);
      this.selectedCity = {Name:'',Code:''};
    }
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }

      let formData = {
        Id: this.district.Id,
        Name: this.district.Name,
        Code: this.district.Code,
        CityId: this.selectedCity.CityId,
        IsActive : true
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.district.districtSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
