import { BaseModel, createColumn } from './columns';

export const getDto = (dto, load) => {
  !allDto && generateDto();
  if (dto) {
    load && allDto[dto] && allDto[dto].modelClass.columns.forEach((x) => { x.editor && x.editor.type === 'combo' && x.editor.store.load(); });
    return allDto[dto];
  }
  return Object.keys(allDto).sort().map((x) => ({ value: x, text: x }));
};

let allDto;
let generateDto = () => {
  allDto = {
    Continent: {
      modelClass: class Continent extends BaseModel {
                static name = 'Continent';

                static dtoName = 'Continent';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field:'Delete'}), */
                  createColumn({ field: 'Name', text: 'Continent' }),
                ];
      },
    },
    Country: {
      modelClass: class Country extends BaseModel {
                static name = 'Country';

                static dtoName = 'Country';

                static fields = ['Name', 'ContinentId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field:'Delete'}), */
                  createColumn({ field: 'Name', text: 'Country' }),
                  createColumn({ field: 'ContinentId', type: 'combo' }),
                ];
      },
    },
    City: {
      modelClass: class City extends BaseModel {
                static name = 'City';

                static dtoName = 'City';

                static fields = ['Name', 'CountryId'];

                static calculatedFields = ['BreadCrumb'];

                static interActiveFields = ['ContinentId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field:'Delete'}), */
                  createColumn({ field: 'Name', text: 'City' }),
                  createColumn({ field: 'BreadCrumb' }),
                  createColumn({ field: 'ContinentId', type: 'combo', required: false }),
                  createColumn({ field: 'CountryId', type: 'combo', parentField: 'ContinentId' }),
                ];
      },
      readUrl: '/City/Search',
    },
    District: {
      modelClass: class District extends BaseModel {
                static name = 'District';

                static dtoName = 'District';

                static fields = ['Name', 'CityId', 'Code'];

                static calculatedFields = ['BreadCrumb'];

                static interActiveFields = ['ContinentId', 'CountryId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'District' }),
                  createColumn({ field: 'Code' }),
                  createColumn({ field: 'BreadCrumb' }),
                  createColumn({ field: 'ContinentId', type: 'combo', required: false }),
                  createColumn({
                    field: 'CountryId', type: 'combo', parentField: 'ContinentId', required: false,
                  }),
                  createColumn({ field: 'CityId', type: 'combo', parentField: 'CountryId' }),
                ];
      },
      readUrl: '/District/Search',
    },
    Campus: {
      modelClass: class Campus extends BaseModel {
                static name = 'Campus';

                static dtoName = 'Campus';

                static fields = ['Name', 'DistrictId', 'Altitude', 'RelativeHumidity'];

                static calculatedFields = ['BreadCrumb'];

                static interActiveFields = ['ContinentId', 'CountryId', 'CityId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'Campus' }),
                  createColumn({ field: 'BreadCrumb' }),
                  createColumn({ field: 'ContinentId', type: 'combo', required: false }),
                  createColumn({
                    field: 'CountryId', type: 'combo', parentField: 'ContinentId', required: false,
                  }),
                  createColumn({
                    field: 'CityId', type: 'combo', parentField: 'CountryId', required: false,
                  }),
                  createColumn({ field: 'DistrictId', type: 'combo', parentField: 'CityId' }),
                  createColumn({ field: 'Altitude', type: 'number', required: false }),
                  createColumn({ field: 'RelativeHumidity', type: 'number', required: false }),
                ];
      },
      readUrl: '/Campus/Search',
    },
    Vendor: {
      modelClass: class Vendor extends BaseModel {
                static name = 'Vendor';

                static dtoName = 'Vendor';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'Vendor' }),
                ];
      },
    },
    User: {
      modelClass: class User extends BaseModel {
                static name = 'User';

                static dtoName = 'User';

                static fields = ['Name', 'Surname', 'Email', 'DateOfEmployment', 'Phone', 'RoleId', 'JobId', 'UserTypeId', 'UserExperienceId', 'DateOfEmployment', 'VendorId'];

                static calculatedFields = ['NameSurname'];

                static isEditable = (record, field) => field !== 'VendorId' || (record.UserTypeId === 3 && field === 'VendorId');

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name' }),
                  createColumn({ field: 'Surname' }),
                  createColumn({ field: 'NameSurname' }),
                  createColumn({ field: 'Email' }),
                  createColumn({ field: 'DateOfEmployment', type: 'date', required: false }),
                  createColumn({ field: 'Phone', required: false }),
                  createColumn({ field: 'RoleId', type: 'combo', url: '/Role/GetRoles' }),
                  createColumn({ field: 'JobId', type: 'combo' }),
                  createColumn({ field: 'UserExperienceId', type: 'combo' }),
                  createColumn({ field: 'UserTypeId', type: 'combo' }),
                  createColumn({ field: 'VendorId', type: 'combo', url: '/Vendor/GetVendors' }),

                ];
      },
      onBeforeParse: ({ json, action }) => {
        json.forEach((x) => {
          x.DateOfEmployment = x.DateOfEmployment && new Date(x.DateOfEmployment);
        });
      },
    },
    Schedule: {
      modelClass: class Schedule extends BaseModel {
                static name = 'Schedule';

                static dtoName = 'Schedule';

                static fields = ['Name', 'Interval', 'Duration', 'ScheduleTypeId', 'ScheduleDayOfMonths', 'ScheduleDayOfWeeks', 'ScheduleMonths', 'ScheduleWeeks'];

                static isDeletable = (record) => record.Id !== 1;

                static isEditable = (record, field) => {
                  const select = record.ScheduleTypeId;
                  return (field === 'Name' || field === 'ScheduleTypeId')
                        || (select && select !== 1 && field === 'Duration')
                        || (select && select !== 1 && select !== 4 && select !== 5 && field === 'Interval')
                        || ((select === 3 || select === 5) && field === 'ScheduleDayOfWeeks')
                        || ((select === 4 || select === 5) && field === 'ScheduleMonths')
                        || (select === 4 && field === 'ScheduleDayOfMonths')
                        || (select === 5 && field === 'ScheduleWeeks');
                };

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'Schedule' }),
                  createColumn({ field: 'ScheduleTypeId', type: 'combo' }),
                  createColumn({ field: 'ScheduleDayOfWeeks', type: 'comboMulti', url: '/Common/GetDayOfWeeks' }),
                  createColumn({ field: 'ScheduleDayOfMonths', type: 'comboMulti', url: '/Common/GetDayOfMonths' }),
                  createColumn({ field: 'ScheduleWeeks', type: 'comboMulti', url: '/Common/GetWeeks' }),
                  createColumn({ field: 'ScheduleMonths', type: 'comboMulti', url: '/Common/GetMonths' }),
                  createColumn({ field: 'Duration', type: 'number' }),
                  createColumn({ field: 'Interval', type: 'number' }),
                ];
      },
      onBeforeParse: ({ json, action }) => {
        json.forEach((x) => {
          x.ScheduleDayOfMonths = x.ScheduleDayOfMonths.map((y) => y.DayOfMonthId);
          x.ScheduleDayOfWeeks = x.ScheduleDayOfWeeks.map((y) => y.DayOfWeekId);
          x.ScheduleMonths = x.ScheduleMonths.map((y) => y.MonthId);
          x.ScheduleWeeks = x.ScheduleWeeks.map((y) => y.WeekId);
        });
      },
    },
    RoomType: {
      modelClass: class RoomType extends BaseModel {
                static name = 'RoomType';

                static dtoName = 'RoomType';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'RoomType' }),
                ];
      },
    },
    RoomSubType: {
      modelClass: class RoomSubType extends BaseModel {
                static name = 'RoomSubType';

                static dtoName = 'RoomSubType';

                static fields = ['Name', 'RoomTypeId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'RoomSubType' }),
                  createColumn({ field: 'RoomTypeId', type: 'combo' }),
                ];
      },
    },
    Brand: {
      modelClass: class Brand extends BaseModel {
                static name = 'Brand';

                static dtoName = 'Brand';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'Brand' }),
                ];
      },
    },
    DeviceModel: {
      modelClass: class Model extends BaseModel {
                static name = 'DeviceModel';

                static dtoName = 'DeviceModel';

                static fields = ['Name', 'BrandId', 'ModelMaintenancePeriods', 'IsForTool', 'IsForCapacityEquipment', 'IsForDevice', 'IsForSparePart'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'Model' }),
                  createColumn({ field: 'BrandId', type: 'combo', url: '/Brand/GetBrands' }),
                  createColumn({
                    field: 'ModelMaintenancePeriods', type: 'comboMulti', url: '/WorkPeriod/GetPeriods', required: false,
                  }),

                  createColumn({ field: 'IsForTool', type: 'check' }),
                  createColumn({ field: 'IsForCapacityEquipment', type: 'check' }),
                  createColumn({ field: 'IsForDevice', type: 'check' }),
                  createColumn({ field: 'IsForSparePart', type: 'check' }),
                ];
      },
      onBeforeParse: ({ json, action }) => {
        json.forEach((x) => {
          x.ModelMaintenancePeriods = x.ModelMaintenancePeriods.map((y) => y.PeriodId);
        });
      },
    },
    ProductFamily: {
      modelClass: class ProductFamily extends BaseModel {
                static name = 'ProductFamily';

                static dtoName = 'ProductFamily';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'ProductFamily' }),
                ];
      },
    },
    Infrastructure: {
      modelClass: class Infrastructure extends BaseModel {
                static name = 'Infrastructure';

                static dtoName = 'Infrastructure';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'Infrastructure' }),
                ];
      },
    },
    InfrastructureGroup: {
      modelClass: class InfrastructureGroup extends BaseModel {
                static name = 'InfrastructureGroup';

                static dtoName = 'InfrastructureGroup';

                static fields = ['Name', 'InfrastructureId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'InfrastructureGroup' }),
                  createColumn({ field: 'InfrastructureId', type: 'combo', url: '/Infrastructure/GetInfrastructures' }),
                ];
      },
    },
    InfrastructureType: {
      modelClass: class InfrastructureType extends BaseModel {
                static name = 'InfrastructureType';

                static dtoName = 'InfrastructureType';

                static fields = ['Name', 'InfrastructureGroupId'];

                static interActiveFields = ['InfrastructureId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'InfrastructureType' }),
                  createColumn({
                    field: 'InfrastructureId', type: 'combo', url: '/Infrastructure/GetInfrastructures', required: false,
                  }),
                  createColumn({
                    field: 'InfrastructureGroupId', type: 'combo', url: '/InfrastructureGroup/GetList', parentField: 'InfrastructureId',
                  }),
                ];
      },
    },
    CapacityType: {
      modelClass: class CapacityType extends BaseModel {
                static name = 'CapacityType';

                static dtoName = 'CapacityType';

                static fields = ['Name', 'InfrastructureTypeId'];

                static interActiveFields = ['InfrastructureId', 'InfrastructureGroupId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'CapacityType' }),
                  createColumn({
                    field: 'InfrastructureId', type: 'combo', url: '/Infrastructure/GetInfrastructures', required: false,
                  }),
                  createColumn({
                    field: 'InfrastructureGroupId', type: 'combo', url: '/InfrastructureGroup/GetList', parentField: 'InfrastructureId', required: false,
                  }),
                  createColumn({
                    field: 'InfrastructureTypeId', type: 'combo', url: '/InfrastructureType/GetList', parentField: 'InfrastructureGroupId',
                  }),
                ];
      },
      readUrl: '/CapacityType/Search',
      onBeforeParse: ({ json, action }) => {
        json.forEach((x) => {
          x.InfrastructureType && (x.InfrastructureId = x.InfrastructureType.InfrastructureGroup.Infrastructure.Id);
          x.InfrastructureType && (x.InfrastructureGroupId = x.InfrastructureType.InfrastructureGroup.Id);
        });
      },
    },
    ShiftType: {
      modelClass: class ShiftType extends BaseModel {
                static name = 'ShiftType';

                static dtoName = 'ShiftType';

                static fields = ['Name', 'StartTime', 'EndTime', 'ColorId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'ShiftType' }),
                  createColumn({ field: 'StartTime', type: 'time' }),
                  createColumn({ field: 'EndTime', type: 'time' }),
                  createColumn({ field: 'ColorId', type: 'combo', url: '/Common/GetColorList' }),
                ];
      },
      onBeforeParse: ({ json, action }) => {
        json.forEach((x) => {
          x.StartTime = bryntum.scheduler.DateHelper.parse(x.StartTime, 'HH:mm:ss');
          x.EndTime = bryntum.scheduler.DateHelper.parse(x.EndTime, 'HH:mm:ss');
        });
      },
    },
    ToolType: {
      modelClass: class ToolType extends BaseModel {
                static name = 'ToolType';

                static dtoName = 'ToolType';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'ToolType' }),
                ];
      },
    },
    ToolSubType: {
      modelClass: class ToolSubType extends BaseModel {
                static name = 'ToolSubType';

                static dtoName = 'ToolSubType';

                static fields = ['Name', 'TypeId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'ToolSubType' }),
                  createColumn({ field: 'TypeId', type: 'combo', url: '/ToolType/GetToolTypes' }),
                ];
      },
    },
    ToolBrand: {
      modelClass: class ToolBrand extends BaseModel {
                static name = 'ToolBrand';

                static dtoName = 'ToolBrand';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'ToolBrand' }),
                ];
      },
    },
    ToolModel: {
      modelClass: class ToolModel extends BaseModel {
                static name = 'ToolModel';

                static dtoName = 'ToolModel';

                static fields = ['Name', 'BrandId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'ToolModel' }),
                  createColumn({ field: 'BrandId', type: 'combo', url: '/ToolBrand/GetBrands' }),
                ];
      },
    },
    ScheduleDevice: {
      modelClass: class ScheduleDevice extends BaseModel {
                static name = 'ScheduleDevice';

                static dtoName = 'ScheduleDevice';

                static fields = ['Name', 'ScheduleId', 'BeginDate', { name: 'BeginTime', dataSource: 'BeginDate' }, 'ExpireDate', { name: 'ExpireTime', dataSource: 'ExpireDate' }, 'IsSuitable', 'DeviceId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'ScheduleDevice' }),
                  createColumn({
                    field: 'DeviceId', type: 'combo', url: '/CapacityEquipment/GetCapacityEquipments', required: false,
                  }),
                  createColumn({ field: 'BeginDate', type: 'date' }),
                  createColumn({ field: 'BeginTime', type: 'time' }),
                  createColumn({ field: 'ExpireDate', type: 'date' }),
                  createColumn({ field: 'ExpireTime', type: 'time' }),
                  createColumn({ field: 'IsSuitable', type: 'check' }),
                  createColumn({ field: 'ScheduleId', type: 'combo', url: '/Schedule/GetSchedules' }),
                ];
      },
      onBeforeParse: ({ json, action }) => {
        json.forEach((x) => {
          x.BeginDate = new Date(x.BeginDate);
          x.ExpireDate = new Date(x.ExpireDate);
        });
      },
    },
    ScheduleWork: {
      modelClass: class ScheduleWork extends BaseModel {
                static name = 'ScheduleWork';

                static dtoName = 'ScheduleWork';

                static fields = ['Name', 'ScheduleId', 'BeginDate', { name: 'BeginTime', dataSource: 'BeginDate' }, 'ExpireDate', { name: 'ExpireTime', dataSource: 'ExpireDate' }, 'IsSuitable', 'WorkId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'ScheduleWork' }),
                  createColumn({
                    field: 'WorkId', type: 'combo', url: '/Work/GetWorksWithBreadCrumb', displayField: 'BreadCrumb', required: false,
                  }),
                  createColumn({ field: 'BeginDate', type: 'date' }),
                  createColumn({ field: 'BeginTime', type: 'time' }),
                  createColumn({ field: 'ExpireDate', type: 'date' }),
                  createColumn({ field: 'ExpireTime', type: 'time' }),
                  createColumn({ field: 'IsSuitable', type: 'check' }),
                  createColumn({ field: 'ScheduleId', type: 'combo', url: '/Schedule/GetSchedules' }),
                ];
      },
      onBeforeParse: ({ json, action }) => {
        json.forEach((x) => {
          x.BeginDate = new Date(x.BeginDate);
          x.ExpireDate = new Date(x.ExpireDate);
        });
      },
    },
    ScheduleRoom: {
      modelClass: class ScheduleRoom extends BaseModel {
                static name = 'ScheduleRoom';

                static dtoName = 'ScheduleRoom';

                static fields = ['Name', 'ScheduleId', 'BeginDate', { name: 'BeginTime', dataSource: 'BeginDate' }, 'ExpireDate', { name: 'ExpireTime', dataSource: 'ExpireDate' }, 'IsSuitable', 'RoomId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'ScheduleRoom' }),
                  createColumn({
                    field: 'RoomId', type: 'combo', url: '/Room/GetAllRooms', required: false,
                  }),
                  createColumn({ field: 'BeginDate', type: 'date' }),
                  createColumn({ field: 'BeginTime', type: 'time' }),
                  createColumn({ field: 'ExpireDate', type: 'date' }),
                  createColumn({ field: 'ExpireTime', type: 'time' }),
                  createColumn({ field: 'IsSuitable', type: 'check' }),
                  createColumn({ field: 'ScheduleId', type: 'combo', url: '/Schedule/GetSchedules' }),
                ];
      },
      onBeforeParse: ({ json, action }) => {
        json.forEach((x) => {
          x.BeginDate = new Date(x.BeginDate);
          x.ExpireDate = new Date(x.ExpireDate);
        });
      },
    },
    WorkOrderType: {
      modelClass: class WorkOrderType extends BaseModel {
                static name = 'WorkOrderType';

                static dtoName = 'WorkOrderType';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'WorkOrderType' }),
                ];
      },
    },
    WorkOrderStatusDescription: {
      modelClass: class WorkOrderStatusDescription extends BaseModel {
                static name = 'WorkOrderStatusDescription';

                static dtoName = 'WorkOrderStatusDescription';

                static fields = ['Description', 'OrderStatusId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Description' }),
                  createColumn({ field: 'OrderStatusId', type: 'combo', url: '/WorkOrderStatus/GetList' }),
                ];
      },
    },
    WorkGroup: {
      modelClass: class WorkGroup extends BaseModel {
                static name = 'WorkGroup';

                static dtoName = 'WorkGroup';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'WorkGroup' }),
                ];
      },
    },
    Job: {
      modelClass: class Job extends BaseModel {
                static name = 'Job';

                static dtoName = 'Job';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'Job' }),
                ];
      },
    },
    Role: {
      modelClass: class UserRole extends BaseModel {
                static name = 'Role';

                static dtoName = 'Role';

                static fields = ['Name', 'IsShowOnCalendar', 'IsShowOnOrganizationChart', 'ParentId', 'JobDescription', 'Predecessors', 'Successors', 'RoleQualifications'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field:'Delete'}), */
                  createColumn({ field: 'Name', text: 'Role' }),
                  createColumn({ field: 'IsShowOnCalendar', type: 'check' }),
                  createColumn({ field: 'IsShowOnOrganizationChart', type: 'check' }),
                  createColumn({
                    field: 'ParentId', type: 'combo', url: '/Role/GetRoles', required: false,
                  }),
                  createColumn({
                    field: 'Predecessors', type: 'comboMulti', url: '/Role/GetRoles', required: false,
                  }),
                  createColumn({
                    field: 'Successors', type: 'comboMulti', url: '/Role/GetRoles', required: false,
                  }),
                  createColumn({
                    field: 'RoleQualifications', type: 'comboMulti', url: '/Qualification/GetQualifications', required: false,
                  }),
                  createColumn({ field: 'JobDescription', required: false, type: 'html' }),
                ];
      },
      onBeforeParse: ({ json, action }) => {
        json.forEach((x) => {
          x.Predecessors = x.Predecessors.map((y) => y.PredecessorId);
          x.Successors = x.Successors.map((y) => y.SuccessorId);
          x.RoleQualifications = x.RoleQualifications.map((y) => y.QualificationId);
        });
      },
    },
    QualificationType: {
      modelClass: class QualificationType extends BaseModel {
                static name = 'QualificationType';

                static dtoName = 'QualificationType';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'QualificationType' }),
                ];
      },
    },
    Qualification: {
      modelClass: class Qualification extends BaseModel {
                static name = 'Qualification';

                static dtoName = 'Qualification';

                static fields = ['Name', 'QualificationTypeId'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'Qualification' }),
                  createColumn({
                    field: 'QualificationTypeId', type: 'combo', url: '/QualificationType/GetQualificationTypes', required: false,
                  }),
                ];
      },
    },
    TrainingType: {
      modelClass: class TrainingType extends BaseModel {
                static name = 'TrainingType';

                static dtoName = 'TrainingType';

                static fields = ['Name'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'TrainingType' }),
                ];
      },
    },
    Training: {
      modelClass: class Training extends BaseModel { // todo TrainingAssessments, TrainingMaterials ?
                static name = 'Training';

                static dtoName = 'Training';

                static fields = ['Name', 'Code', 'Duration', 'IsCertificatedTraining', 'Purpose', 'TrainingMethodId', 'TrainingTypeId', 'Content'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'Training' }),
                  createColumn({ field: 'Code' }),
                  createColumn({ field: 'Duration', type: 'number' }),
                  createColumn({ field: 'TrainingMethodId', type: 'combo' }),
                  createColumn({ field: 'TrainingTypeId', type: 'combo' }),
                  // createColumn({field:'TrainingAssessments', type: 'comboMulti', required:false, url:'/Assessment/Query'}), //todo include & Parse
                  // createColumn({field:'TrainingMaterials', type: 'comboMulti'required:false}),
                  createColumn({ field: 'Content', type: 'html' }),
                  createColumn({ field: 'Purpose' }),
                  createColumn({ field: 'IsCertificatedTraining', type: 'check' }),
                ];
      },
    },
    Tool: {
      modelClass: class Tool extends BaseModel { // todo warehouse component + tool tree grid
                static name = 'Tool';

                static dtoName = 'Tool';

                static fields = ['ToolStatusId', 'WarehouseId', 'ModelId', 'BrandId', 'TypeId', 'SubTypeId', 'IsNeedCalibration', 'NextCalibrationDate', 'IsNeedPeriodicMaintenance', 'NextMaintenanceDate', 'Description', 'SerialNumber'];

                static columns = [// , 'PictureId',
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'WarehouseId', type: 'warehouse' }), // todo create generic tree select type
                  createColumn({ field: 'TypeId', type: 'combo', url: '/ToolType/GetToolTypes' }),
                  createColumn({
                    field: 'SubTypeId', type: 'combo', required: false, url: '/ToolSubType/GetToolSubTypes',
                  }),
                  createColumn({
                    field: 'ModelId', type: 'combo', required: false, url: '/ToolModel/GetModels',
                  }),
                  createColumn({
                    field: 'BrandId', type: 'combo', required: false, url: '/ToolBrand/GetBrands',
                  }),
                  createColumn({ field: 'ToolStatusId', type: 'combo', url: '/ToolStatus/GetList' }),
                  createColumn({ field: 'IsNeedCalibration', type: 'check' }),
                  createColumn({ field: 'NextCalibrationDate', type: 'date', required: false }),
                  createColumn({ field: 'IsNeedPeriodicMaintenance', type: 'check' }),
                  createColumn({ field: 'NextMaintenanceDate', type: 'date', required: false }),
                  createColumn({ field: 'Description', required: false }),
                  // createColumn({field:'PictureId', type: 'picture', required:false}),
                  createColumn({ field: 'SerialNumber', required: false }),
                ];
      },
      onBeforeParse: ({ json, action }) => {
        json.forEach((x) => {
          x.NextCalibrationDate = x.NextCalibrationDate && new Date(x.NextCalibrationDate);
          x.NextMaintenanceDate = x.NextMaintenanceDate && new Date(x.NextMaintenanceDate);
        });
      },
    },
    Procedure: {
      modelClass: class Procedure extends BaseModel {
                static name = 'Procedure';

                static dtoName = 'Procedure';

                static fields = ['Name', 'ProcedureTypeId', 'FileId'];

                static calculatedFields = ['FileName'];

                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'Procedure' }),
                  createColumn({ field: 'FileName' }),
                  createColumn({ field: 'FileId', type: 'file' }),
                  createColumn({ field: 'ProcedureTypeId', type: 'combo', url: '/ProcedureType/GetProcedureTypes' }),
                ];
      },
      onBeforeParse: ({ json, action }) => {
        action === 'read' && json.forEach((x) => {
          x.FileName = x.File && x.File.Name;
        });
      },
    },
    CapacityEquipment: {
      modelClass: class CapacityEquipment extends BaseModel {
                static name = 'CapacityEquipment';

                static dtoName = 'Device';

                static fields = ['Name', 'CapacityTypeId', 'Explanation', 'RoomId', 'ProductFamilyId', 'TotalEffectiveCapacity'];

                static calculatedFields = ['ChildCount', 'PointCount', 'WorkCount', 'ModelName', 'BrandName', 'DeviceType'];// 'BreadCrumb',

                // static interActiveFields = [ 'BuildingId' , 'FloorId'];//,'InfrastructureTypeId'
                static columns = [
                  createColumn({ field: 'RowNumber' }),
                  createColumn({ field: 'Id' }),
                  /* createColumn({field: 'Delete'}), */
                  createColumn({ field: 'Name', text: 'CapacityEquipment' }),
                  // createColumn({field: 'InfrastructureTypeId', type: 'combo'}),
                  createColumn({ field: 'CapacityTypeId', type: 'combo', url: '/CapacityType/GetCapacityTypes' }), // , parentField:'InfrastructureTypeId'
                  createColumn({ field: 'Explanation', required: false }),
                  // createColumn({field: 'BuildingId', type: 'combo', url:'/Location/GetAllBuildings'}),
                  // createColumn({field: 'FloorId', type: 'combo', parentField:'BuildingId', url:'/Floor/GetFloors'}),
                  createColumn({ field: 'RoomId', type: 'combo', url: '/Room/GetAllRooms' }), // parentField:'FloorId'
                  createColumn({
                    field: 'ProductFamilyId', type: 'combo', required: false, url: '/ProductFamily/GetProductFamilies',
                  }),
                  createColumn({ field: 'TotalEffectiveCapacity', type: 'number', required: false }),
                  // createColumn({field: 'BreadCrumb', editor:false}),
                  createColumn({ field: 'ChildCount', type: 'number' }),
                  createColumn({ field: 'PointCount', type: 'number' }),
                  createColumn({ field: 'WorkCount', type: 'number' }),
                  createColumn({ field: 'ModelName' }),
                  createColumn({ field: 'BrandName' }),
                  createColumn({ field: 'DeviceType' }),
                ];
      },
      readUrl: '/CapacityEquipment/Search',
    },
  };

  Object.keys(allDto).forEach((x) => {
    allDto[x].modelClass.chf = allDto[x].modelClass.chf || [];
    Object.keys(allDto).forEach((y) => {
      if (y !== x) {
        const find = allDto[y].modelClass.fields.find((y) => `${x}Id` === y);
        find && allDto[x].modelClass.chf.push(y);
      }
    });
  });
};
