module.exports = {
    template: '<input :name="name" class="form-control time-picker" :required="required" autocomplete="off"/>',
    props: {
      value: {
        required: true,
      },
      name: {
        type: String
      },
      required: {
        type: Boolean,
        default: false
      },
    },
    mounted() {
        $(this.$el).timepicker({
            autoclose: true,
            language: localStorage.getItem('vue-lang'),
            showMeridian: false,
        });
        $(this.$el).timepicker('setTime', this.value);
        $(this.$el).on('change', () => {
            this.$emit('input', $(this.$el).val());
        });
    },
    watch: {
      value() {
        $(this.$el).timepicker('setTime', this.value);
      },
    },
  
  };
  