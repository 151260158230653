<template>
    <div>
        <div class="workorder_staff_wrapper"
             v-for="userPosition in userPositionsWithAssignedUsers">
            <label class="role_title">
                {{userPosition.userPositionId==enumUserPositions.master?translate('master'):userPosition.userPositionId==enumUserPositions.observer?translate('observer'):translate('assigned')}} {{translate('user')}}
                ({{userPosition.users.length}})
                <a v-if="isEditable" href="javascript:;"><i @click="showAddUser(user)" class="fa fa-plus"></i></a>
            </label>
            <div class="staff_item"
                 v-tooltip="!user.IsActiveUser?translate('deleted_entry'):false"
                 :class="{'removed-item':!user.IsActiveUser?true:false}"
                 v-for="user in userPosition.users">
                <a v-if="isEditable" class="pull-right" href="javascript:;"><i @click="removeUser(user)" class="fa fa-fw fa-times"></i></a>
                <user-card2 class="workorder_staff_card"
                            :user="user">
                    {{user.NameSurname}}
                </user-card2>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import UserCard2 from '../../../../partials/cards/UserCard2';
import { groupBy } from '../../../../../scripts/global/util';

export default {
  name: 'WorkOrderAssignedUsers',
  components: {
    'user-card2': UserCard2,
  },
  props: {
    workOrderId: {
      type: [Number, String],
      required: true,
    },
    isEditable: {
      type: [Boolean, String],
      required: false,
    },
  },
  data() {
    return {
      userPositionsWithAssignedUsers: [],
    };
  },
  async mounted() {
    blockui.blockElementPromise(this, this.$el);
    try {
      await this.initializeData();
    } catch (e) {
      toastr.error(e.message);
    } finally {
      blockui.unBlockElement(this.$el);
    }
  },
  methods: {
    async initializeData() {
      const response = await this.$globalApiClient.workOrder.workOrderGetAssignedUsers({workOrderId: this.workOrderId});
      if ( !response.data ) {
        throw new Error('Error');
      }
      this.userPositionsWithAssignedUsers = this.formatWorkOrderUserPositionData(response.data);
    },
    formatWorkOrderUserPositionData(assignedUsers) {
      const userPositionsWithAssignedUsers = [];
      const assignedUsersGroupedByUserPosition = groupBy(assignedUsers, (x) => x.UserPositionId);
      for (const key of assignedUsersGroupedByUserPosition.keys()) {
        const positionsUsers = assignedUsersGroupedByUserPosition.get(key);
        userPositionsWithAssignedUsers.push({
          userPositionId: key,
          userPositionName: positionsUsers[0].UserPosition,
          users: positionsUsers,
        });
      }
      return userPositionsWithAssignedUsers;
    },
    showAddUser() {

    },
    async removeUser(user) {
      const self = this;
      const formData = {
        workOrderId: user.WorkOrderId,
        userId: user.Id,
      };
      await api.deleteWorkOrderUsers(formData).then((response) => {
        if (response.body.IsSuccess) {
          toastr.success(self.translate('delete_successfully'));
          self.initializeData();
        } else toastr.error(response.body.Message);
      }, (error) => {
      });
    },
  },
  computed: {
    ...mapState({
      enumUserPositions: (state) => state.enums.userPositions,
    }),
  },
};
</script>
