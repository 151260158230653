import * as api from '../../scripts/services/api';

// initial state
const state = {
  unitList: [],
  temperatureUnitList: [],
  timeUnitList: [],
  currencyUnitList: [],
  continentRelated: {
    Unit: [
      'getUnitList',
    ],

  },
};

// getters
const getters = {
  getUnitList: (state) => state.unitList.map((x) => ({ Value: x.Id, Text: x.Name })),
};

// actions
const actions = {
  getRelatedUnitList({ commit, dispatch }, pathName) {
    const relatedList = state.continentRelated[pathName];
    if (relatedList) {
      relatedList.forEach((item) => {
        dispatch(item);
      });
    }
  },
  getUnitList({ commit }, data) {
    api.getUnitList(data).then((res) => {
      commit('setUnitList', res.body.Data);
    });
  },
  getTemperatureUnitList({ commit }) {
    api.getUnits({ unitTypeId: this.state.enums.unitType.Temperature }).then((res) => {
      commit('setTemperatureUnitList', res.body);
    });
  },
  getTimeUnitList({ commit }) {
    api.getUnits({ unitTypeId: this.state.enums.unitType.Time }).then((res) => {
      commit('setTimeUnitList', res.body);
    });
  },
  getCurrencyUnitList({ commit }) {
    api.getUnits({ unitTypeId: this.state.enums.unitType.Currency }).then((res) => {
      commit('setCurrencyUnitList', res.body);
    });
  },
};

// mutations
const mutations = {
  setUnitList(state, units) {
    state.unitList = units;
  },
  setTemperatureUnitList(state, temperatureUnits) {
    state.temperatureUnitList = temperatureUnits;
  },
  setTimeUnitList(state, timeUnits) {
    state.timeUnitList = timeUnits;
  },
  setCurrencyUnitList(state, currencyUnits) {
    state.currencyUnitList = currencyUnits;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
