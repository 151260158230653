import moment from 'moment';
import * as api from '../../services/api';
import chart from './chart';
import * as dateFormat from '../../../helpers/dateFormat';

export default {
  mixins: [chart],
  data() {
    return {
      userList: [],
      currentSort: 'RoundCount',
      currentSortDir: 'desc',
      pageSize: 6,
      currentPage: 1,
      currentMonth: moment().locale(localStorage.getItem('vue-lang')).format('MMMM'),
    };
  },
  mounted() {
    api.getStaffRoundCountsReport(
      dateFormat.postDateTimeFormatFromDateObject(moment().startOf('month').toDate()),
      dateFormat.postDateTimeFormatFromDateObject(moment().endOf('month').toDate()),
    ).then((res) => {
      this.userList = res.body;
    });
  },
  methods: {
    redraw() {},
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    nextPage() {
      if (this.currentPage * this.pageSize < this.userList.length) this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
  },
  computed: {
    sortedUserList() {
      return this.userList.sort((a, b) => {
        let modifier = 1;
        if (isNaN(a[this.currentSort]) || isNaN(b[this.currentSort])) {
          const compare = a[this.currentSort].localeCompare(b[this.currentSort]);
          if (this.currentSortDir === 'asc') modifier = -1;
          if (compare > 0) return -1 * modifier;
          if (compare < 0) return 1 * modifier;
        } else {
          if (this.currentSortDir === 'asc') modifier = -1;
          if (a[this.currentSort] > b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] < b[this.currentSort]) return 1 * modifier;
        }
        return 0;
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize;
        const end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      });
    },
    isLastPage() {
      return this.currentPage * this.pageSize >= this.userList.length;
    },
    isFirstPage() {
      return this.currentPage == 1;
    },
  },
};
