import * as api from '../services/api';
import * as dateFormat from '../../helpers/dateFormat';

import grid from '../../components/partials/dashboard-charts/Grid';

export default {
  data() {
    return {
      workOrderCounts: {},
    };
  },
  components: {
    grid,
  },
  mounted() {
    const self = this; let
      table;
  },
};
