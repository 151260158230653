import * as api from '@/scripts/services/api';
import toolEdit from './tool_edit.js';

export default {
  namespaced: true,
  modules: {
    toolEdit,
  },

  // initial state
  state: {
    tool: {},
    toolTypes: [],
    toolSubTypes: [],
    toolBrands: [],
    toolModels: [],
    workOrderListForReservation: [],
  },

  // getters
  getters: {
    tool(state) {
      return state.tool;
    },
    toolTypes(state) {
      return state.toolTypes.map((x) => ({ Id: x.Value, Name: x.Text }));
    },
    toolSubTypes(state) {
      return state.toolSubTypes.map((x) => ({ Id: x.Value, Name: x.Text }));
    },
    toolBrands(state) {
      return state.toolBrands.map((x) => ({ Id: x.Value, Name: x.Text }));
    },
    toolModels(state) {
      return state.toolModels.map((x) => ({ Id: x.Value, Name: x.Text }));
    },
    workOrderListForReservation(state) {
      return state.workOrderListForReservation;
    },
  },

  // actions
  actions: {
    getTool({ commit }, id) {
      return new Promise((resolve, reject) => {
        api.getTool(id).then((res) => {
          if (!res.ok && res.status !== 200) {
            return reject(res.status);
          }
          const data = res.body;
          commit('setTool', data);
          resolve(data);
        }, (err) => reject(err.message));
      });
    },
    getToolTypes({ commit }) {
      return new Promise((resolve, reject) => {
        api.getToolTypes().then((res) => {
          if (!res.ok && res.status !== 200) {
            return reject(res.status);
          }
          const data = res.body;
          commit('setToolTypes', data);
          resolve(data);
        }, (err) => reject(err.message));
      });
    },
    getToolSubTypes({ commit }, toolTypeId) {
      return new Promise((resolve, reject) => {
        api.getToolSubTypes({ toolTypeId }).then((res) => {
          if (!res.ok && res.status !== 200) {
            return reject(res.status);
          }
          const data = res.body;
          commit('setToolSubTypes', data);
          resolve(data);
        }, (err) => reject(err.message));
      });
    },
    getBrands({ commit }) {
      return new Promise((resolve, reject) => {
        api.getBrands().then((res) => {
          if (!res.ok && res.status !== 200) {
            return reject(res.status);
          }
          const data = res.body;
          commit('setToolBrands', data);
          resolve(data);
        }, (err) => reject(err.message));
      });
    },
    getModels({ commit }, brandId) {
      return new Promise((resolve, reject) => {
        api.getModels({ brandId }).then((res) => {
          if (!res.ok && res.status !== 200) {
            return reject(res.status);
          }
          const data = res.body;
          commit('setToolModels', data);
          resolve(data);
        }, (err) => reject(err.message));
      });
    },
    getWorkOrderListForReservation({ commit }, { fromDate, endDate }) {
      return new Promise((resolve, reject) => {
        api.getWorkOrderList({ fromDate, endDate }).then((res) => {
          if (!res.ok && res.status !== 200) {
            return reject(res.status);
          }
          const data = res.body.Data;
          commit('setWorkOrderListForReservation', data);
          resolve(data);
        }, (err) => reject(err.message));
      });
    },
  },

  // mutations
  mutations: {
    setTool(state, data) {
      state.tool = data;
    },
    setToolTypes(state, data) {
      state.toolTypes = data;
    },
    setToolSubTypes(state, data) {
      state.toolSubTypes = data;
    },
    setToolBrands(state, data) {
      state.toolBrands = data;
    },
    setToolModels(state, data) {
      state.toolModels = data;
    },
    setWorkOrderListForReservation(state, data) {
      state.workOrderListForReservation = data;
    },
  },
};
