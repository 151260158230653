import Vue from 'vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { minValue, required } from 'vuelidate/lib/validators';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import * as swal from '../../../../../helpers/swal';
import { mixinz } from '../../../../../scripts/global/mixinz';
import WarehouseTree from '../../../../partials/modals/Tool/Warehouse/Warehouse.vue';

export default {
  mixins: [mixinz],
  props: [
    'toolId',
  ],
  components: {
    'warehouse-tree': WarehouseTree,
  },
  validations() {
    return {
      tool: {
        TypeId: {
          required,
          minValue: minValue(1),
        },
        WarehouseId: {
          required,
          minValue: minValue(1),
        },
      },
    };
  },
  data() {
    return {
      toolCopy: null,
      selectedWarehouse: null,
      toolInitialized: false,
    };
  },
  async created() {
    const self = this;
    this.initSelects([{ name: 'getToolTypes' }, { name: 'getBrands' }]);
    this.initSelects([{ name: 'getToolSubTypes', args: [this.tool.TypeId] }, {
      name: 'getModels',
      args: [this.tool.BrandId],
    }]);
    await this.initTool(this.toolId);
    self.selectedWarehouse = self.tool.Warehouse;
    self.toolInitialized = true;
    self.toolCopy = self.deepClone(self.tool);
  },
  mounted() {
    window.$('form').validate();
  },
  computed: {
    ...mapGetters({
      tool: 'tool/toolEdit/tool',
      toolTypes: 'tool/toolTypes',
      toolSubTypes: 'tool/toolSubTypes',
      toolBrands: 'tool/toolBrands',
      toolModels: 'tool/toolModels',
    }),
    // #region computed select
    toolType: {
      get() {
        const self = this;
        return self.selectGet('toolTypes', 'tool.TypeId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('tool.TypeId', 'Id', newValue);
      },
    },
    toolSubType: {
      get() {
        const self = this;
        return self.selectGet('toolSubTypes', 'tool.SubTypeId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('tool.SubTypeId', 'Id', newValue);
      },
    },
    toolBrand: {
      get() {
        const self = this;
        return self.selectGet('toolBrands', 'tool.BrandId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('tool.BrandId', 'Id', newValue);
      },
    },
    toolModel: {
      get() {
        const self = this;
        return self.selectGet('toolModels', 'tool.ModelId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('tool.ModelId', 'Id', newValue);
      },
    },
    // #endregion select
    nextCalibrationDate: {
      get() {
        if (this.tool.NextCalibrationDate) {
          return moment(this.tool.NextCalibrationDate).format('DD.MM.YYYY');
        }
        return null;
      },
      set(val) {
        if (val) {
          Vue.set(this.tool, 'NextCalibrationDate', moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD'));
        } else {
          Vue.set(this.tool, 'NextCalibrationDate', val);
        }
        return val;
      },
    },
    nextMaintenanceDate: {
      get() {
        if (this.tool.NextMaintenanceDate) {
          return moment(this.tool.NextMaintenanceDate).format('DD.MM.YYYY');
        }
        return null;
      },
      set(val) {
        if (val) {
          Vue.set(this.tool, 'NextMaintenanceDate', moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD'));
        } else {
          Vue.set(this.tool, 'NextMaintenanceDate', val);
        }
        return val;
      },
    },
  },
  methods: {
    ...mapActions({
      getTool: 'tool/toolEdit/getTool',
      getToolTypes: 'tool/getToolTypes',
      getToolSubTypes: 'tool/getToolSubTypes',
      getBrands: 'tool/getBrands',
      getModels: 'tool/getModels',
    }),
    ...mapMutations({
      setToolModels: 'tool/setToolModels',
      setToolSubTypes: 'tool/setToolSubTypes',
    }),
    async initTool(toolId) {
      const self = this;
      try {
        blockui.blockElementPromise(self, self.$el);
        await self.getTool(toolId);
      } catch (err) {
        throw new Error(err);
      } finally {
        blockui.unBlockElement(self.$el);
      }
    },
    async initSelects(methods) {
      const self = this;
      const promises = methods.map((method) => {
        const args = method.args ? method.args : [];
        return self[method.name](...args);
      });
      try {
        blockui.blockElementPromise(self, self.$el);
        await Promise.all(promises);
      } catch (err) {
        throw new Error(err);
      } finally {
        blockui.unBlockElement(self.$el);
      }
    },
    async onSubmit(e) {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        blockui.blockElementPromise(self, self.$el);
        const postData = {
          tool: {
            Id: self.tool.Id,
            TypeId: self.tool.TypeId,
            SubTypeId: self.tool.SubTypeId,
            BrandId: self.tool.BrandId,
            IsNeedCalibration: self.tool.IsNeedCalibration,
            IsNeedPeriodicMaintenance: self.tool.IsNeedPeriodicMaintenance,
            ToolStatusId: self.tool.ToolStatusId,
            WarehouseId: self.tool.WarehouseId,
            ModelId: self.tool.ModelId,
            SerialNumber: self.tool.SerialNumber ? self.tool.SerialNumber : null,
            Description: self.tool.Description ? self.tool.Description : null,
            NextMaintenanceDate: self.tool.NextMaintenanceDate,
            NextCalibrationDate: self.tool.NextCalibrationDate,
          },
        };
        const result = await api.saveTool(postData);
        if (!result.body.IsSuccess) {
          throw result.body.Message;
        } else {
          self.toolCopy = self.deepClone(self.tool);
          self.$emit('updated');
        }
        toastr.success(self.translate('update_successfully'));
      } catch (err) {
        toastr.error(err);
        throw new Error(err);
      } finally {
        blockui.unBlockElement(self.$el);
      }
    },
    setSelectedLocation(e) {
      if (e && e.Id) {
        this.tool.WarehouseId = e.Id;
        this.selectedWarehouse = e;
      } else {
        this.tool.WarehouseId = null;
        this.selectedWarehouse = null;
      }
    },
    toolTypeChanged(value) {
      this.tool.SubTypeId = null;
      if (value && value.Id > 0) {
        this.initSelects([{ name: 'getToolSubTypes', args: [value.Id] }]);
      } else {
        this.setToolSubTypes([]);
      }
    },
    toolBrandChanged(value) {
      this.tool.ModelId = null;
      if (value && value.Id > 0) {
        this.initSelects([{ name: 'getModels', args: [value.Id] }]);
      } else {
        this.setToolModels([]);
      }
    },
    isNeedCalibrationChanged() {
      if (!this.tool.IsNeedCalibration) {
        this.nextCalibrationDate = null;
      }
    },
    isNeedPeriodicMaintenanceChanged() {
      if (!this.tool.IsNeedPeriodicMaintenance) {
        this.nextMaintenanceDate = null;
      }
    },
  },
  watch: {},
};
