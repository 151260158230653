


































import { Component, Prop } from 'vue-property-decorator';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'
import * as api from '../../../scripts/services/api';

import { CFMModelWorkOrderView, CFMDTOMaintenancePlan } from '@/scripts/services/ClientApiAuto'
import MaintenancePlanDialog from './MaintenancePlanDialog.vue'

import { mixinz } from '@/scripts/global/mixinz';
import moment from 'moment';
import { L10n } from '@syncfusion/ej2-base';


@Component<ContractWorkOrders>({
    mixins: [mixinz],
})
export default class ContractWorkOrders extends CFMGridPage<CFMModelWorkOrderView> {

    @Prop()
    contractId?:number;

    entityType = "WorkOrder";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];
    // @ts-ignore
    filterSettings = { 
        type: 'Menu',
        // @ts-ignore
        columns: [
            // @ts-ignore
            { field: 'ContractId', matchCase: false, operator: 'equal', predicate: 'and', value: parseInt(this.contractId) }
        ]
    };
    
    async created(){
      this.toolbar = [] 
      L10n.load({
            "tr": {
                "grid": {
                    "FilterButton": "Filtrele",
                    "ClearButton": "Temizle"
                }
            }
        });
        this.filterSettings = { 
        type: 'Menu',
        // @ts-ignore
        columns: [
            //@ts-ignore
            { field: 'ContractId', matchCase: false, operator: 'equal', predicate: 'and', value: parseInt(this.contractId)}
        ]
        };
    }

    async mounted(){
        this.dataStateChange(this.state);
    }

    refreshGrid(){
        this.dataStateChange(this.state);
    }

    customiseCell(args: any) { // Added status column
        if(args.column.field === 'OrderStatusName') {
            args.cell.classList.add('bg-color');
        }
    }

    toolbarClick(args:any){}
    recordDoubleClick(args:any){}
    
    queryCellInfo(args: any) { // Added status column
        if(args.column) {
            if(args.column.field === 'OrderStatusName') {
                let value = args.data["ColorCode"];
                args.cell.style.backgroundColor = value;
                return;
            }
        }
    }
}
