import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import Select2 from '../../components/shared/select2';
import * as blockui from '../../helpers/block-ui';

export default {
  data() {
    return {
      filterCampus: {
        Value: '',
      },
    };
  },
  components: {
    select2: Select2,
  },
  computed: {
    ...mapState({
      campusList: (state) => state.location.campusList,
    }),
  },
  mounted() {
    const self = this;

    this.$store.dispatch('getCampusList');

    api.getUserRoleList({ isShowOnOrganizationChart: true }).then((response) => {
      response.body.forEach((item) => {
        if (!item.ParentId) chartSource.children.push(pushData(item));
      });
      Vue.getChart(1);
    });

    const companyString = localStorage.getItem('company');
    let companyName = '';
    if (companyString && companyString != 'undefined') {
      const company = JSON.parse(companyString);
      if (company && company.Name) {
        companyName = company.Name;
      }
    }

    const chartSource = { id: 1, name: companyName, children: [] };

    function pushData(item) {
      const obj = new Object();
      obj.name = item.Name;
      if (item.Children && item.Children.length) {
        obj.children = [];
        item.Children.forEach((childItem) => {
          obj.children.push(pushData(childItem));
        });
      }
      if (item.Users && item.Users.length) {
        obj.content = '';
        item.Users.forEach((userItem) => {
          obj.content += `<div>${userItem.NameSurname}</div>`;
        });
      }
      return obj;
    }

    Vue.getChart = (id) => {
      $('#chart_container').orgchart({
        data: chartSource,
        nodeContent: 'content',
        draggable: false,
        parentNodeSymbol: 'fa fa-users',
        createNode: ($node, data) => {
          $node.attr('data-toggle', 'm-tooltip').attr('data-original-title', data.name);
          mApp.initTooltip($node);
        },
      });
      blockui.unBlockElement('.m-portlet');
    };

    blockui.blockElement(self, '.m-portlet');

    $("select[name='campusId']").on('change', (e) => {
      const id = e.target.value;
      if (id) {
        $('#chart_container').empty();
        Vue.getChart(id);
      }
    });

    const loopChart = ($chart) => {
      const $tr = $chart.find('tr:first');
      const subObj = {
        id: $tr.find('attr', 'id'),
        title: $tr.find('.content').text(),
        name: $tr.find('.title').text(),
      };
      $tr.data('toggle', 'm-tooltip');
      $tr.data('original-title', subObj.title);
      $tr.siblings(':last').children().each(function () {
        if (!subObj.children) {
          subObj.children = [];
        }
        subObj.children.push(loopChart($(this)));
      });
      return subObj;
    };

    Vue.getHierarchy = ($chart) => loopChart($chart);
  },
};
