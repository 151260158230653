


























































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import {required} from "vuelidate/lib/validators";

import { CFMDTOWorkOrderStatusDescription, CFMDTOWorkOrderStatus } from '@/scripts/services/ClientApiAuto'

@Component<WorkOrderStatusDescriptionDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
    },
    validations() {
        return {
        workOrderStatusDescription :{
            Description: {required}
        },
        selectedWorkOrderStatusDescription: {
            OrderStatusId : {required}
        }
        }
    },
})
export default class WorkOrderStatusDescriptionDialog extends CFMDialog {
    workOrderStatusDescription: CFMDTOWorkOrderStatusDescription = {Description: ''};
    orderStatusList : {Name:string, Id:number}[] = [];

    selectedWorkOrderStatusDescription : CFMDTOWorkOrderStatusDescription = {Description: ''};
    selectedWorkOrderStatus : CFMDTOWorkOrderStatus = {Name: ''}

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.workOrderStatusDescription.workOrderStatusDescriptionGetById({id: <number>this.id});
        this.workOrderStatusDescription = res.data;
      }
      const promises = [
      this.getWorkOrderStatuses()
      ];
      if(this.isUpdate){
      if(this.workOrderStatusDescription && this.workOrderStatusDescription.OrderStatus){
          this.selectedWorkOrderStatusDescription  = this.workOrderStatusDescription;
          this.selectedWorkOrderStatus = this.workOrderStatusDescription.OrderStatus;
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    async getWorkOrderStatuses(){
      const response = await this.apiClient.common.commonGetWorkOrderStatuses();
      this.orderStatusList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          alert("invalid");
          return;
      }
      let formData = {
        Id: this.workOrderStatusDescription.Id,
        Description: this.workOrderStatusDescription.Description,
        OrderStatusId : this.selectedWorkOrderStatusDescription.OrderStatusId,
        CompanyId: this.workOrderStatusDescription.CompanyId,
        IsActive: true
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.workOrderStatusDescription.workOrderStatusDescriptionSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
