import * as api from '../../scripts/services/api';

// initial state
const state = {
  workCategoryList: [],
  workOrderCategories: [],
  workGroupList: [],
  workList: [],
  worksWithBreadCrumbList: [],
  capacityEquipmentOverlayWorkList: [],
  roomCapacityEquipmentWorkList: null,

  selectedCapacityEquipmentId: null,
};

// getters
const getters = {};

// actions
const actions = {
  async getWorkOrderCategories({ commit }) {
    await api.getWorkOrderCategories().then((res) => {
      commit('setWorkOrderCategories', res.body);
    });
  },
  async getWorkCategoryList({ commit }) {
    await api.getWorkCategories().then((res) => {
      commit('setWorkCategoryList', res.body);
    });
  },
  async getWorkGroupList({ commit }) {
    await api.getWorkGroups().then((res) => {
      commit('setWorkGroupList', res.body);
    });
  },
  getWorkList({ commit }, data) {
    api.getWorks(data).then((res) => {
      commit('setWorkList', res.body);
    });
  },
  getWorksWithBreadCrumb({ commit }) {
    api.getWorksWithBreadCrumb().then((res) => {
      commit('setWorksWithBreadCrumbList', res.body);
    });
  },
  getCapacityEquipmentOverlayWorkList({ commit }, data) {
    api.getWorkSearch({ ...data, ...{ deviceId: state.selectedCapacityEquipmentId } }).then((res) => {
      commit('setCapacityEquipmentOverlayWorkList', res.body.Data);
    });
  },
  getRoomCapacityEquipmentWorkList({ commit }, data) {
    api.getWorkSearch({ ...data, ...{ deviceId: state.selectedCapacityEquipmentId } }).then((res) => {
      commit('setRoomCapacityEquipmentWorkList', res.body.Data);
    });
  },
};

// mutations
const mutations = {
  setWorkOrderCategories(state, workOrderCategories) {
    state.workOrderCategories = workOrderCategories;
  },
  setWorkCategoryList(state, workCategories) {
    state.workCategoryList = workCategories;
  },
  setWorkGroupList(state, workGroups) {
    state.workGroupList = workGroups;
  },
  setWorkList(state, works) {
    state.workList = works;
  },
  setWorksWithBreadCrumbList(state, works) {
    state.worksWithBreadCrumbList = works;
  },
  setCapacityEquipmentOverlayWorkList(state, works) {
    state.capacityEquipmentOverlayWorkList = works;
  },
  setSelectedCapacityEquipmentId(state, selectedCapacityEquipmentId) {
    state.selectedCapacityEquipmentId = selectedCapacityEquipmentId;
  },
  setRoomCapacityEquipmentWorkList(state, works) {
    state.roomCapacityEquipmentWorkList = works;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
