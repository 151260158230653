




















































































import CFMDialog from '@/scripts/CFMDialog'
import { Component, Vue } from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import { required } from "vuelidate/lib/validators";
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'

import { CFMDTOProcedure, CFMDTOFileType } from '@/scripts/services/ClientApiAuto'

@Component<ProcedureDialog>({
  components: {
      'cfm-multiselect': CFMMultiselect
  },
  validations() {
      return {
        procedure:{
            Name: {required},
            ProcedureTypeId: {required},
        },
        file: {required}
      }
  }
})
export default class ProcedureDialog extends CFMDialog {
    procedure : CFMDTOProcedure = {Name: ''};
    maxFileSize = 20;

    procedureTypeList : {Name:String, Id:Number}[] = [];
    fileTypeList : CFMDTOFileType[] = [];

    file: File | null = null;

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.procedure.procedureGetById({id: <number>this.id});
        this.procedure = res.data;
      }
      const promises = [
        this.getProcedureTypes(),
        this.getFileTypes()
      ];
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    get isUpdate() {
        return !!this.id;
    }

    get acceptedExtensionsString(){
      return this.fileTypeList && this.fileTypeList.length > 0 ? this.fileTypeList.map(x=>x.Extension).join(', ') : '';
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }

      if(!this.file) return;
      
      let queryData  = {
        id: this.procedure.Id,
        name: this.procedure.Name,
        procedureTypeId: this.procedure.ProcedureTypeId,
      };

      let formData = new FormData();
      formData.append("file", this.file);
 
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.procedure.procedureSave(<any>formData, queryData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }

    async getProcedureTypes(){
      const response = await this.apiClient.procedureType.procedureTypeGetProcedureTypes();
      this.procedureTypeList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    
    async getFileTypes(){
      const response = await this.apiClient.fileType.fileTypeGetList();
      this.fileTypeList = response.data;
    }

    onFileChange (e:Event) {
      const target = (e.target as HTMLInputElement)
      if(!target || !target.files || Array(target.files).length == 0 )
      {
        toastr.error(this.translate("file_is_not_suitable"));
        target.value = '';
        return;
      }
      const file : File = target.files[0]; 
      const fileSize = Number(((file.size / 1024) / 1024).toFixed(4));

      if(!file.name && fileSize > this.maxFileSize)
      { 
        toastr.error(this.translate("file_is_not_suitable"));
        target.value = '';
        return;
      }
      let extension = file.name.split('.').pop();
      extension = extension ? extension.toLowerCase(): '';
      if (!this.fileTypeList.map(x=>x.Extension).includes(extension)) {
        toastr.error(this.translate("file_is_not_suitable"));
        target.value = '';
        return;
      } 
      this.file = file;    
    }

    async downloadFile () {
      blockui.blockModal(this);
      if(!this.procedure.FileId) return;
      const response = await this.apiClient.file.fileGetFile({id: this.procedure.FileId, isContainsData: true});
      if(response.data.IsSuccess && response.data.Data && response.data.Data.Base64 && response.data.Data.ContentType){
        this.downloadBlob(this.base64toBlob(response.data.Data.Base64, response.data.Data.ContentType), response.data.Data.Name);
      }
      blockui.unBlockModal();
    }
    base64toBlob (base64Data:string, type:string): Blob {
      type = type || '';
      // Convert the Base64 string back to text.
      const byteString = atob(base64Data);

      // Convert that text into a byte array.
      const ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {
        type: type
      });
    }
    downloadBlob (blob: Blob, fileName:string) {
      let a = $("<a style='display: none;'/>");
      const url = window.URL.createObjectURL(blob);
      a.attr("href", url);
      a.attr("download", fileName);
      $("body").append(a);
      a[0].click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }








}
