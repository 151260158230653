import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';

import AddToolDetailItem from './AddToolDetailItem.vue';

export default {
  mixins: [],
  props: ['tools'],
  components: {
    'add-tool-detail-item': AddToolDetailItem,
  },
  data() {
    return {
      toolEntries: [],
    };
  },
  created() {
  },
  mounted() {
    this.toolEntries = this.tools;
  },
  computed: {},
  methods: {
    toolUpdated(tool, newTool) {
      tool = newTool;
      // this.$emit('updated')
    },
    /* isChanged(tool, index) {
            if (!(this.tools && this.tools.length > index)) {
                return true;
            }
            return !_.isEqual(this.tools[index], tool)
        }, */
    async onSubmit() {
      const self = this;
      // TODO validation
      if (true) {
        /* try {
                    blockui.blockModalPromise(self, "#tool_modal");
                    const result = await api.saveAllTools({toolList: self.toolEntries});
                    if (!result.body.IsSuccess) {
                        throw result.body.Message;
                    }
                    self.$emit('updated');
                    toastr.success(self.translate('save_successfully'));
                } catch (err) {
                    toastr.error(err);
                    throw new Error(err);
                } finally {
                    blockui.unBlockModal();
                } */
        const result = await self.saveTools(self.toolEntries);
        if (result) {
          self.$emit('updated');
        }
      }
    },
    async saveTools(toolList) {
      // TODO: EREN - Sadece değişmiş olanlar yollanacak.
      const self = this;
      if (!(toolList && toolList.length > 0)) {
        return false;
      }
      let result = false;
      try {
        blockui.blockModalPromise(self, '#tool_modal');
        const res = await api.saveAllTools({ toolList });
        if (!res.body.IsSuccess) {
          self.handleErrors(res.body.Message);
          return false;
        }
        result = true;
        toastr.success(self.translate('save_successfully'));
      } catch (err) {
        self.handleErrors(new Error(err));
      } finally {
        blockui.unBlockModal();
        return result;
      }
    },
    handleErrors(err) {
      let errorMessage = '';
      if (err instanceof Error) {
        errorMessage = err.message ? err.message : '';
      } else {
        errorMessage = err || '';
      }
      if (errorMessage === '') {
        errorMessage = this.translate('undefined_error');
      }
      toastr.error(err);
    },
  },
  watch: {
    tools(newVal) {
      this.toolEntries = newVal;
    },
  },
};
