
















































































































































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import { required, requiredIf } from 'vuelidate/lib/validators';

import { mixinz } from '@/scripts/global/mixinz';

import {CFMCoreEntityComboSource, CFMDTOForm, CFMDTOFormClone } from '@/scripts/services/ClientApiAuto'
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'

import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'

const enumsModule = namespace('enums')

// @ts-ignore
const mustBeFilledArrayIf = (param) => (value) => !param() || (value && value.length > 0);

@Component<FormCloneDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
    },
    validations() {
        return {
          form: {
            Name: {
              required,
            },
            Code: {
              required,
            },
            FormBrands: {
              // @ts-ignore
              mustBeFilledArray: mustBeFilledArrayIf(() => this.selectedProcedureTypeId == this.enumProcedureType.eop && !(this.form.FormModels && this.form.FormModels.length > 0)),
            },
            FormModels: {
              // @ts-ignore
              mustBeFilledArray: mustBeFilledArrayIf(() => this.selectedProcedureTypeId == this.enumProcedureType.mop && !(this.form.FormBrands && this.form.FormBrands.length > 0)),
            },
          },
          formRoomId: {
            // @ts-ignore
            requiredIf: requiredIf(() => this.selectedProcedureTypeId == this.enumProcedureType.scp && !(this.formDeviceId > 0)),
          },
          formDeviceId: {
            // @ts-ignore
            requiredIf: requiredIf(() => (this.selectedProcedureTypeId == this.enumProcedureType.scp || this.selectedProcedureTypeId == this.enumProcedureType.sop) && !(this.formRoomId > 0)),
          },
        }
    },
    mixins: [mixinz]
})
export default class FormCloneDialog extends CFMDialog {
    @enumsModule.State('procedureType')
    enumProcedureType!: { [key: string]: number; };
    
    form: CFMDTOForm = {Name: '', Code: '', Description: ''};

    capacityEquipmentList : CFMCoreEntityComboSource[] = [];
    roomList : CFMCoreEntityComboSource[] = [];
    brandList : CFMCoreEntityComboSource[] = [];
    modelList : CFMCoreEntityComboSource[] = [];
    procedureTypeList : CFMCoreEntityComboSource[] = [];
    
    formDeviceId:number = 0;
    formRoomId:number = 0;

    async created(){
      blockui.blockModal(this);
      const res = await this.apiClient.form.formGetById({id: <number>this.id});
      this.form = res.data;

      const promises = [
        this.getCapacityEquipmentBreadCrumbList(),
        this.getRoomList(),
        this.getBrandList(),
        this.getModelList(),
        this.getProcedureTypes()
      ];
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    async getCapacityEquipmentBreadCrumbList(){
      const response = await this.apiClient.capacityEquipment.capacityEquipmentGetCapacityEquipmentsBreadCrumb();
      this.capacityEquipmentList = response.data;
    }
    async getRoomList(){
      const response = await this.apiClient.room.roomGetAllRooms();
      this.roomList = response.data;
    }
    async getBrandList(){
      const response = await this.apiClient.brand.brandGetBrands();
      this.brandList = response.data;
    }
    async getModelList(){
      const response = await this.apiClient.model.modelGetModels();
      this.modelList = response.data;
    }
    async getProcedureTypes(){
      const response = await this.apiClient.procedureType.procedureTypeGetProcedureTypes();
      this.procedureTypeList = response.data;
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }

      const formData: CFMDTOFormClone = {
        Id: this.form.Id,
        Name: this.form.Name,
        Code: this.form.Code,
        FormDevices: this.form.FormDevices,
        FormBrands: this.form.FormBrands,
        FormModels: this.form.FormModels,
        FormRooms: this.form.FormRooms,
      };
      if (this.selectedProcedureTypeId == this.enumProcedureType.sop) {
        formData.FormDevices = [{ DeviceId: this.formDeviceId }];
        formData.FormBrands = [];
        formData.FormModels = [];
        formData.FormRooms = [];
      } else if (this.selectedProcedureTypeId == this.enumProcedureType.eop) {
        formData.FormDevices = [];
        formData.FormModels = [];
        formData.FormRooms = [];
      } else if (this.selectedProcedureTypeId == this.enumProcedureType.mop) {
        formData.FormDevices = [];
        formData.FormBrands = [];
        formData.FormRooms = [];
      } else if (this.selectedProcedureTypeId == this.enumProcedureType.scp) {
        formData.FormModels = [];
        formData.FormBrands = [];
        if (this.formDeviceId > 0) {
          formData.FormDevices = [{ DeviceId: this.formDeviceId }];
        } else {
          formData.FormDevices = [];
        }
        if (this.formRoomId > 0) {
          formData.FormRooms = [{ RoomId: this.formRoomId }];
        } else {
          formData.FormRooms = [];
        }
      }

      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.form.formClone(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('item_saved_successfully').formatString(formData.Name));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }

    get selectedProcedureTypeId() {
      return this.form ? this.form.ProcedureTypeId : null;
    }

    get selectedFormDevice() {
      // @ts-ignore
      return this.selectGet('capacityEquipmentList', 'formDeviceId', 'Value', 'Text');
    }
    set selectedFormDevice(newValue) {
      // @ts-ignore
      return this.selectSet('formDeviceId', 'Value', newValue);
    }

    get selectedFormRoom() {
      // @ts-ignore
      return this.selectGet('roomList', 'formRoomId', 'Value', 'Text');
    }

    set selectedFormRoom(newValue) {
      // @ts-ignore
      return this.selectSet('formRoomId', 'Value', newValue);
    }

    get selectedFormBrands() {
      // @ts-ignore
      return this.selectGet('brandList', 'form.FormBrands', 'Value', 'Text', true, 'BrandId');
    }
    set selectedFormBrands(newValue) {
      // @ts-ignore
      return this.selectSet('form.FormBrands', 'Value', newValue, true, 'BrandId');
    }

    get selectedFormModels() {
      // @ts-ignore
      return this.selectGet('modelList', 'form.FormModels', 'Value', 'Text', true, 'ModelId');
    }

    set selectedFormModels(newValue) {
      // @ts-ignore
      return this.selectSet('form.FormModels', 'Value', newValue, true, 'ModelId');
    }
}
