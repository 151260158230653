<template>
  <div class="row">
    <div class="col-md-6" v-for="(item,index) in formParameters" v-bind:key="index">
      <div  v-if="item.ParameterType.Name === 'Numeric'">
        <div class="form-group m-form__group">
          <label><span>{{item.Name}}</span></label>
          <input type="text" name="floor_count" class="form-control m-input touch-spin" value=""
                 :required="item.Required" :data-decimals="item.ParameterNumeric.ParameterFormat.Pattern"
                :data-min="item.Minimum" :data-max="item.Maximum" :data-step="item.Increment"/>
        </div>
      </div>
      <div v-if="item.ParameterType.Name === 'String'">
        <div class="form-group m-form__group">
          <label><span>{{item.Name}}</span></label>
          <div v-if="item.ParameterString ? item.ParameterString.MaxLength > 100 : false">
                      <textarea :name="item.Id" class="form-control"
                                :data-rule-minlength="item.ParameterString ? item.ParameterString.MinLength : 'false'"
                                :data-rule-maxlength="item.ParameterString ? item.ParameterString.MaxLength : 'false'"
                                :required="item.Required" rows="4"></textarea>
          </div>
          <div v-if="item.ParameterString ? item.ParameterString.MaxLength <= 100 : true">
            <input type="text" :name="item.Id" class="form-control"
                   :data-rule-minlength="item.ParameterString ? item.ParameterString.MinLength : 'false'"
                   :data-rule-maxlength="item.ParameterString ? item.ParameterString.MaxLength : 'false'" :required="item.Required"/>
          </div>
        </div>
      </div>
      <div v-if="item.ParameterType.Name === 'Boolean'">
        <div class="row">
          <label class="col-8 col-form-label"><span>{{item.Name}}</span></label>
          <div class="col-4 text-right">
                      <span class="m-switch m-switch--icon">
                        <label>
                          <input type="checkbox" checked="checked" :name="item.Id">
                          <span></span>
                        </label>
                      </span>
          </div>
        </div>
      </div>
      <div v-if="item.ParameterType.Name === 'Datetime'">
        <div class="form-group m-form__group">
          <label><span>{{item.Name}}</span></label>
          <div v-if="item.ParameterDatetime.ParameterFormat.Name === 'Date'">
            <input type="text" class="form-control date-picker" :required="item.Required"
                   :data-date-format="item.ParameterDatetime.ParameterFormat.Pattern"
                   :data-date-start-date="item.ParameterDatetime.MinDate"
                   :data-date-end-date="item.ParameterDatetime.MaxDate"
            />
          </div>
          <div v-if="item.ParameterDatetime.ParameterFormat.Name === 'Datetime'">
            <input type="text" class="form-control date-time-picker" :required="item.Required"
                   :data-date-format="item.ParameterDatetime.ParameterFormat.Pattern"
                   :data-date-start-date="item.ParameterDatetime.MinDate"
                   :data-date-end-date="item.ParameterDatetime.MaxDate"
            />
          </div>
          <div v-if="item.ParameterDatetime.ParameterFormat.Name === 'Time'">
            <input type="text" class="form-control time-picker" :required="item.Required"
                   :data-date-format="item.ParameterDatetime.ParameterFormat.Pattern"
                   :data-show-meridian="item.ParameterDatetime.ParameterFormat.Pattern === 'HH:mm:ss' ? 'true' : 'false' || item.ParameterDatetime.ParameterFormat.Pattern === 'HH:mm' ? 'true' : 'false'"
                   :data-min-time="item.ParameterDatetime.MinTime"
                   :data-max-time="item.ParameterDatetime.MaxTime"
            />
          </div>
        </div>
      </div>
      <div v-if="item.ParameterType.Name === 'List'">
        <div class="form-group m-form__group">
          <label><span>{{item.Name}}</span></label>
          <select2 :items="item.ParameterList.Items" :selected="item.Value" :required="item.Required" :name="item.Id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from '../shared/select2';
// ss
export default {
  props: ['formParameters', 'updateData'],
  components: {
    select2: Select2,
  },
  mounted() {
    $(document).ready(() => {
      this.$nextTick(() => {
        $('#add_device').on('shown.bs.modal', () => {
          window.$('.touch-spin').TouchSpin();
          window.$('form').validate();
          window.$('.date-picker').datepicker({
            autoclose: true,
            language: localStorage.getItem('vue-lang'),
          });
          window.$('.date-time-picker').datetimepicker({
            autoclose: true,
            language: localStorage.getItem('vue-lang'),

          });
          window.$('.time-picker').timepicker({
            autoclose: true,
            language: localStorage.getItem('vue-lang'),
            showMeridian: true,
          });
        });
      });
    });
  },
};
</script>
