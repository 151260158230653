import Vue from 'vue';
import * as api from '../services/api';
import grid from '../global/gridOld';
import { Editor } from '../global/editors';

export default {
  props: ['workOrderId'],
  data() {
    return {
      gridEngine: undefined,
      feedBackEditor: undefined,
      tooltipEditor: undefined,
      data: [],
      columns: [
        {
          field: 'CreatedDate',
          text: 'Date',
          type: 'date',
          format: 'LLL',
          width: 125,
        },
        {
          field: 'CreatedUserName',
          text: 'Staff',
          width: 125,
        },
        {
          field: 'FormName',
          text: 'Form',
          width: 125,
        },
        {
          field: 'QuestionGroupName',
          text: 'Question Group',
          width: 125,
        },
        {
          field: 'QuestionName',
          text: 'Question',
          width: 125,
        },
        {
          field: 'QuestionOrder',
          text: 'Question Order',
          type: 'number',
          width: 100,
        },
        {
          field: 'Preview',
          text: 'Preview',
          flex: 1,
          tooltipRenderer: this.asyncTooltipRenderer,
        },
        {
          text: '',
          type: 'widget',
          width: 90,
          groupable: false,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-eye',
            cls: 'b-blue',
            style: 'width:100%',
            onAction: ({ source }) => this.openTooltip(source.cellInfo.record.Id),
          }],
        }],
    };
  },
  components: {
    grid,
  },
  async created() {
    this.$eventHub.$on('flagCount', this.getResources);
    await this.getResources();
  },
  beforeDestroy() {
    Editor(null, this.feedBackEditor);
    Editor(null, this.tooltipEditor);
    this.$eventHub.$off('flagCount');
  },
  methods: {
    initGrid() {
      this.gridEngine = this.$refs.grid.gridEngine;
    },
    async getResources() {
      this.gridEngine && this.gridEngine.store.clear();
      await this.$globalApiClient.workOrderHtmlContent.workOrderHtmlContentGetView({ workOrderId: this.workOrderId }).then((res) => {
        this.data = res.data.map((item) => ({
          Id: item.Id,
          CreatedDate: new Date(item.CreatedDate),
          CreatedUserName: item.CreatedUserName,
          FormName: item.FormName,
          FormId: item.FormId,
          QuestionGroupName: item.QuestionGroupName,
          QuestionName: item.QuestionName,
          QuestionOrder: item.QuestionOrder || null,
          Preview: item.Preview,
        }));
      });
    },
    asyncTooltipRenderer({ record, column, tip }) {
      tip.hideOnDelegateChange = true;
      tip.hideDelay = 2000;
      tip.maxHeight = 350;
      tip.maxWidth = 500;
      tip.scrollable = true;
      tip.allowOver = true;
      tip.htmlEncode = true;
      this.$globalApiClient.workOrderHtmlContent.workOrderHtmlContentGetById({ id: record.Id }).then((response) => {
        if (response.data) {
          const html = response.data.HtmlContent && response.data.HtmlContent.Content || '';
          tip.html = html;
        }
      });
      return false;
    },
    openEditor({ target }) {
      const self = this;
      self.feedBackEditor = Editor({
        type: 'popupHtml',
        forElement: target,
        value: '',
        callback: async (value) => {
          if (!value.length) return;
          await self.$globalApiClient.workOrderHtmlContent.workOrderHtmlContentSave({ 
            workOrderId: self.workOrderId,
          }, {
            content: value,
          }).then((res) => {
            if (res.data.Data) {
              const feedback = {
                Id: res.data.Data.Id,
                CreatedDate: new Date(),
                CreatedUserName: localStorage.getItem('userName'),
                FormName: null,
                FormId: null,
                QuestionGroupName: null,
                QuestionName: null,
                QuestionOrder: null,
                Preview: res.data.Data.HtmlContent && res.data.Data.HtmlContent.Content,
              };
              self.gridEngine.store.add(feedback);
              toastr.success(self.translate('feedback_saved_successfully'));
              self.$eventHub.$emit('flagCount', { workOrderId: self.workOrderId });
              self.feedBackEditor.close();
            } else {
              toastr.error(res.data.Message);
            }
          });
        },
      }, self.feedBackEditor);
    },
    openTooltip(id) {
      const self = this;
      self.tooltipEditor = Editor({
        type: 'toolTip',
        onPaint: async (source) => {
          await api.getWorkOrderHtmlContent(id).then((response) => {
            if (response.ok && response.body) {
              if (localStorage.getItem('userId') == (response.body.CreatedUser && response.body.CreatedUser.Id)) source.tools.delete.element.style.display = 'block';
              const html = response.body.HtmlContent && response.body.HtmlContent.Content || '';
              const date = response.body.CreatedDate || '';
              const user = response.body.CreatedUser && response.body.CreatedUser.NameSurname || '';
              source.html = html;
              source.title = `${user} ${moment(date).format('DD.MM.YYYY HH:mm')}`;
            }
          });
        },
        callback: async (source) => {
          await source.element && api.deleteWorkOrderHtmlContent(id).then((res) => {
            if (res.body.IsSuccess) {
              const record = self.gridEngine.store.find((x) => x.Id === id);
              const formId = record && record.FormId;
              toastr.success(self.translate('feedback_deleted_successfully'));
              source.close();
              record && record.remove();
              formId && self.$emit('onFormFeedbackDeleted', {
                workOrderId: self.workOrderId,
                formId,
              });
              self.$eventHub.$emit('flagCount', { workOrderId: self.workOrderId });
              self.$eventHub.$emit('decrementFlagCount', { workOrderId: self.workOrderId, formId });
            } else {
              toastr.error(res.body.Message);
            }
          });
        },
      }, self.tooltipEditor);
    },
  },
};
