import * as api from '../../scripts/services/api';

// initial state
const state = {
  brandList: [],
  filterBrandList: [],
  brandListWithModels: [],
  brands: [],
};

// getters
const getters = {};

// actions
const actions = {
  getBrandList({ commit }) {
    api.getBrands().then((res) => {
      commit('setBrandList', res.body);
    });
  },
  getFilterBrandList({ commit }) {
    api.getBrands().then((res) => {
      commit('setFilterBrandList', res.body);
    });
  },
  getBrandListWithModels({ commit }, data) {
    let postData = data || {};
    postData = { isIncludeModel: true, ...postData };
    api.getBrandList(postData).then((res) => {
      commit('setBrandListWithModels', res.body.Data);
    });
  },
  async getBrands({ commit }) {
    await api.getBrandList().then((res) => {
      commit('setBrands', res.body.Data);
    });
  },
};

// mutations
const mutations = {
  setBrandList(state, brands) {
    state.brandList = brands;
  },
  setFilterBrandList(state, brands) {
    state.filterBrandList = brands;
  },
  setBrandListWithModels(state, brandListWithModels) {
    state.brandListWithModels = brandListWithModels;
  },
  setBrands(state, brands) {
    state.brands = brands;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
