<template>
    <div>
        <multiselect v-model="computedValue"
                     :options="list"
                     :multiple="multiple"
                     :label="label"
                     :track-by="trackBy"
                     v-bind="computedMultiSelectProps"
                     :disabled="disabled">
            <template slot="tag" slot-scope="{ option, remove }">
                <span class="multiselect__tag"
                      :class="{'removed-item': !option['isActiveProperty']}"
                      v-tooltip="!option['isActiveProperty']?translate('deleted_entry'):false">
                    <span>{{option[label]}}</span>
                    <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
                </span>
            </template>
            <template slot="singleLabel" slot-scope="{ option, remove }">
                <span v-if="option"
                      v-tooltip="!option['isActiveProperty']?translate('deleted_entry'):false">
                    <span>{{option[label]}}</span>
                </span>
                <div v-if="!option['isActiveProperty']"
                     class="deleted-entry-exist-warning">
                    <i class="fa fa-exclamation-circle"></i>
                    {{translate("deleted_entry")}}
                </div>
            </template>
        </multiselect>
        <slot name="postfix"></slot>
    </div>
</template>

<script>
import Vue from 'vue';
import { mixinz } from '../../scripts/global/mixinz';

export default {
  props: {
    // region common
    value: {}, // e.g. selectedContract.ContractPersonels or selectedContract
    valueLabel: {}, // e.g. NameSurname or Name
    valueTrackByObjectName: {}, // Name of object like "User" or "Vendor" for value.map(x=>x.User.NameSurname) or value.Vendor.Name
    valueTrackBy: {}, // e.g. UserId or VendorId for creating a new selected for [{UserId:2,...},{UserId:45,..}] or VendorId: 3
    // endregion
    // region list
    list: {
      type: Array,
      required: true,
    }, // e.g. vendorUserList
    trackBy: {
      type: String,
      default: 'Id',
    }, // e.g. Value or Id
    label: {
      type: String,
      default: 'Name',
    }, // e.g. Text or Name
    // endregion
    // region options
    multiple: {
      default: false,
    },
    disabled: {
      default: false,
    },
    required: {
      default: false,
    },
    isActiveProperty: {
      type: String,
      default: 'IsActive',
    },
    multiSelectProps: {
      type: [Object, Array],
    },
    idProperty: {
      type: String,
      default: 'Id',
    },
    // endregion
  },
  mixins: [mixinz],
  computed: {
    computedMultiSelectProps() {
      if (this.multiSelectProps && typeof this.multiSelectProps === 'object') {
        if (Array.isArray(this.multiSelectProps)) {
          const result = {};
          for (const prop of this.multiSelectProps) {
            result[prop.name] = prop.value;
          }
          return { ...this.computedSelectOptions, ...result };
        }
        return { ...this.computedSelectOptions, ...this.multiSelectProps };
      }
      return this.computedSelectOptions;
    },
    computedSelectOptions() {
      return this.required ? this.selectOptionsRequired : this.selectOptions;
    },
    computedValue: {
      get() {
        if (this.multiple && this.valueTrackByObjectName) {
          if (this.value !== null && this.value !== undefined && this.value !== '' && Array.isArray(this.value)) {
            return this.value.map((valueItem) => ({
              isActiveProperty: valueItem[this.valueTrackByObjectName][this.isActiveProperty],
              [this.label]: valueItem[this.valueTrackByObjectName][this.valueLabel],
              [this.trackBy]: valueItem[this.valueTrackBy],
              idProperty: valueItem.Id,
            }));
          }
          return [];
        }
        if (!this.multiple && !this.valueTrackByObjectName) {
          return {
            [this.isActiveProperty]: true,
            [this.label]: this.value[this.valueLabel],
            [this.trackBy]: this.value[this.valueTrackBy],
          };
        }
        if (this.multiple && !this.valueTrackByObjectName) {
          if (this.value !== null && this.value !== undefined && this.value !== '' && Array.isArray(this.value)) {
            return this.value.map((valueItem) => ({
              isActiveProperty: true,
              [this.label]: valueItem[this.valueLabel],
              [this.trackBy]: valueItem[this.valueTrackBy],
              idProperty: valueItem.Id,
            }));
          }
          return [];
        }

        if (this.value && this.value[this.valueTrackByObjectName]) {
          return {
            isActiveProperty: this.value[this.valueTrackByObjectName][this.isActiveProperty],
            [this.label]: this.value[this.valueTrackByObjectName][this.valueLabel],
            [this.trackBy]: this.value[this.valueTrackBy],
          };
        }
        return null;
      },
      set(newValue) {
        let computedResult = null;
        if (this.multiple && this.valueTrackByObjectName) {
          computedResult = [];
          if (newValue !== null && newValue !== undefined && newValue !== '' && Array.isArray(newValue)) {
            for (const valueItem of newValue) {
              computedResult.push({
                [this.idProperty]: valueItem.idProperty ? valueItem.idProperty : 0,
                [this.valueTrackBy]: valueItem[this.trackBy],
                [this.valueTrackByObjectName]: {
                  [this.idProperty]: valueItem[this.trackBy],
                  [this.valueLabel]: valueItem[this.label],
                  [this.isActiveProperty]: valueItem.isActiveProperty === undefined ? true : valueItem.isActiveProperty,
                },
              });
            }
          }
        } else if (!this.multiple && this.valueTrackByObjectName) {
          computedResult = this.value;
          if (newValue) {
            Vue.set(computedResult, this.valueTrackBy, newValue[this.trackBy]);
            Vue.set(computedResult, this.valueTrackByObjectName, {
              [this.idProperty]: newValue[this.trackBy],
              [this.valueLabel]: newValue[this.label],
              [this.isActiveProperty]: newValue.isActiveProperty === undefined ? true : newValue.isActiveProperty,
            });
          } else {
            Vue.set(computedResult, this.valueTrackBy, null);
            Vue.set(computedResult, this.valueTrackByObjectName, null);
          }
        } else if (!this.multiple && !this.valueTrackByObjectName) {
          computedResult = this.value;
          if (newValue) {
            Vue.set(computedResult, this.valueTrackBy, null);
            Vue.set(computedResult, this.valueTrackBy, newValue[this.trackBy]);
          } else {
            Vue.set(computedResult, this.valueTrackBy, null);
          }
        }
        this.$emit('input', computedResult);
        return computedResult;
      },
    },
  },
};
</script>

<style scoped>
    .label-content {
        overflow: hidden;
    }
</style>
