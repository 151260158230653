import * as api from '../../services/api';
import chart from './chart';

export default {
  mixins: [chart],
  data() {
    return {
      workOrderCounts: {},
      workOrderCount: 0,
      currentDomain: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      range: 4,
      caloptions: {},
    };
  },
  mounted() {
    const self = this;

    self.initCalendar();

    $(window).on('resize', function () {
      if (this.resizeTO) clearTimeout(this.resizeTO);
      this.resizeTO = setTimeout(function () {
        $(this).trigger('resizeEnd');
      }, 500);
    });

    $(window).bind('resizeEnd', () => {
      if ($(`#${self.chartId}`).length) {
        self.redraw();
      }
    });
    $(document).on('click', '#chart-work-order-count-calendar-chart .cal-heatmap-container .graph-label', (e) => {
      const classList = $(e.target).closest('svg').attr('class').split(/\s+/);
      classList.forEach((className) => {
        if (className.startsWith('m_')) {
          const monthIndex = parseInt(className.replace('m_', '')) - 1;
          self.$router.push({
            name: 'WorkOrder',
            query: {
              startDate: moment(self.currentYear).months(monthIndex).startOf('month').toDate()
                .getTime(),
              endDate: moment(self.currentYear).months(monthIndex).endOf('month').toDate()
                .getTime(),
            },
          });
        }
      });
    });
  },
  methods: {
    nextYear() {
      const self = this;
      self.currentYear++;
      self.currentDomain = 0;
      self.initCalendar();
    },
    prevYear() {
      const self = this;
      self.currentYear--;
      self.currentDomain = 11;
      self.initCalendar();
    },
    initCalendar() {
      const self = this;
      self.workOrderCounts = {};
      self.workOrderCount = 0;
      const currentDate = new Date(self.currentYear, 0, 1);
      api.getWorkOrderList({
        endDate: moment(currentDate).endOf('year').format(),
        fromDate: moment(currentDate).startOf('year').format(),
        isShowOnCalendar: false,
      }).then((response) => {
        let date;
        response.body.Data.forEach((workOrder) => {
          date = moment(workOrder.ScheduledTime).format('YYYY-MM-DD');
          if (self.workOrderCounts[date]) {
            self.workOrderCounts[date]++;
          } else {
            self.workOrderCounts[date] = 1;
          }
          self.workOrderCount++;
        });

        const data = {};
        let max = 0;
        Object.keys(self.workOrderCounts).forEach((key) => {
          data[new Date(key).getTime() / 1000] = self.workOrderCounts[key];
          if (self.workOrderCounts[key] > max) {
            max = self.workOrderCounts[key];
          }
        });

        self.caloptions = {
          itemSelector: `#${self.chartId}-inner`,
          dataType: 'json',
          range: self.range,
          considerMissingDataAsZero: false,
          domainDynamicDimension: true,
          previousSelector: '#cal-heatmap-PreviousDomain-selector',
          nextSelector: '#cal-heatmap-NextDomain-selector',
          afterLoadNextDomain(date) {
            self.currentDomain = Math.floor(((new Date(date).getMonth() * 2) - self.range) / 2);
          },
          afterLoadPreviousDomain(date) {
            self.currentDomain = Math.floor(((new Date(date).getMonth() * 2) + self.range) / 2);
          },
          legend: [0, 2, 4, 6, 8, 10],
          domain: 'month',
          subDomain: 'x_day',
          subDomainTextFormat: '%d',
          verticalOrientation: false,
          displayLegend: true,
          minDate: moment(currentDate).startOf('year').toDate(),
          maxDate: moment(currentDate).endOf('year').toDate(),
          data,
          cellPadding: 5,
          domainGutter: 20,
          tooltip: true,
          legendMargin: [35, 0, 0, 0],
          // cellSize: 35,
          // legend: [...Array(max).keys(), [...Array(max).keys()].length],
          legendCellSize: 20,
          legendColors: ['#dae289', '#3b6427'],
          highlight: new Date(),
          itemName: [self.translate('work_order'), self.translate('work_orders')],
          label: {
            position: 'top',
          },
          subDomainTitleFormat: {
            empty: self.translate('there_is_no_work_order'),
            filled: self.translate('there_is_n_work_order'),
          },
          legendTitleFormat: {
            lower: self.translate('lower_than_n_work_order'),
            inner: self.translate('inner_than_n_work_order'),
            upper: self.translate('upper_than_n_work_order'),
          },
          subDomainDateFormat(date) {
            moment.locale(localStorage.getItem('vue-lang'));
            return moment(date).format('LL');
          },
          domainLabelFormat(date) {
            moment.locale(localStorage.getItem('vue-lang'));
            return moment(date).format('MMMM');
          },
          onClick(date) {
            self.$router.push({
              name: 'WorkOrder',
              query: {
                startDate: moment(date).startOf('day').toDate().getTime(),
                endDate: moment(date).endOf('day').toDate().getTime(),
              },
            });
          },
        };
        self.responsiveCal(self.caloptions);
      });
    },
    responsiveCal(options) {
      const self = this;
      let month = self.currentDomain;
      if ($(`#${self.outerId}`).closest('.grid-stack-item').width() <= 420) {
        self.range = 1;
        options.range = 1;
        options.cellSize = 23;
      } else if ($(`#${self.outerId}`).closest('.grid-stack-item').width() <= 600) {
        if (month === 11) month = 10;
        self.range = 2;
        options.range = 2;
        options.cellSize = 20;
      } else if ($(`#${self.outerId}`).closest('.grid-stack-item').width() <= 780) {
        if (month === 11) month = 10;
        month--;
        self.range = 3;
        options.range = 3;
        options.cellSize = 20;
      } else if ($(`#${self.outerId}`).closest('.grid-stack-item').width() <= 980) {
        if (month > 8) month = 9;
        month--;
        self.range = 4;
        options.range = 4;
        options.cellSize = 20;
      } else if ($(`#${self.outerId}`).closest('.grid-stack-item').width() <= 1100) {
        if (month > 8) month = 9;
        month -= 2;
        self.range = 5;
        options.range = 5;
        options.cellSize = 20;
      } else if ($(`#${self.outerId}`).closest('.grid-stack-item').width() <= 1300) {
        if (month > 8) month = 9;
        month -= 2;
        self.range = 5;
        options.range = 5;
        options.cellSize = 23;
      } else if ($(`#${self.outerId}`).closest('.grid-stack-item').width() <= 1500) {
        if (month > 7) month = 8;
        month -= 2;
        self.range = 6;
        options.range = 6;
        options.cellSize = 23;
      } else {
        if (month > 7) month = 8;
        month -= 3;
        self.range = 7;
        options.range = 7;
        options.cellSize = 23;
      }

      if ($(`#${self.outerId}`).closest('.grid-stack-item').height() <= 300) {
        options.cellSize = 14;
        options.legendCellSize = 14;
        options.legendMargin = [20, 0, 0, 0];
      } else {
        options.legendCellSize = 20;
        options.legendMargin = [35, 0, 0, 0];
      }

      month = month < 0 ? 0 : month;
      options.start = new Date(self.currentYear, month, 1);

      $(`#${self.chartId}-inner`).empty();
      $(`#${self.chartId}-inner`).removeAttr('style');
      const cal = new CalHeatMap();
      if ($(`#${self.chartId}-inner`).length > 0) {
        cal.init(options);
      }
    },
    redraw() {
      const self = this;
      if ($(`#${self.chartId}-inner`).length > 0) {
        self.responsiveCal(self.caloptions);
      }
    },
  },
  beforeDestroyed() {
    $(window).off('resize');
    if (this.resizeTO) clearTimeout(this.resizeTO);
  },
};
