<template>
    <div class='schContainer'></div>
</template>

<script>
/* eslint-disable */

    // Defines a Vue component that wraps Bryntum Scheduler
    export default {
        props: {
            name: {type: String, default: ''},
            ripple: {
                type: [Boolean, Object], default: () => {
                    return {radius : 65, delegate : '.b-grid-header'}
                }
            },
            // Configs
            //todo fix snap = true
            snap: {type: Boolean, default: false},
            width: {type: String, default: '100%'},
            height: {type: String, default: '75vh'},
            maxHeight: {type: String, default: '75vh'},
            minHeight: {type: String, default: '10vh'},
            autoHeight: {type: Boolean, default: false},
            barMargin: {type: Number, default: 0},
            columns: Array,
            emptyText: String,
            eventBodyTemplate: Function,
            eventColor: {type: String, default: 'green'},
            eventLayout: {type: String, default: 'none'},
            eventRenderer: Function,
            eventStyle: {default: 'plain', type: String},
            endDate: {
                type: Date, default: () => {
                    return bryntum.scheduler.DateHelper.add(
                        bryntum.scheduler.DateHelper.getFirstDateOfMonth(new Date()),
                        1,
                        'month')
                }
            },
            //timeResolution: {type: Number, default: 1},
            partner: Object,
            fillTicks: {type: Boolean, default: false},
            forceFit: {type: Boolean, default: false},
            hideHeaders: {type: Boolean, default: false},
            id: String,
            milestoneCharWidth: Number,
            maxZoomLevel: Number,
            minZoomLevel: Number,
            milestoneLayoutMode: String,
            multiEventSelect: {type: Boolean, default: false},
            readOnly: {type: Boolean, default: false},
            responsiveLevels: {type: Object, default: undefined},
            rowHeight: {type: Number, default: 33},
            showRemoveEventInContextMenu: {type: Boolean, default: true},
            showRemoveRowInContextMenu: {type: Boolean, default: false},
            showUnassignEventInContextMenu: {type: Boolean, default: true},
            startDate: {
                type: Date, default: () => {
                    return bryntum.scheduler.DateHelper.getFirstDateOfMonth(new Date())
                }
            },
            viewPreset: {type: [String, Object], default: 'dayAndWeek'},
            // Stores
            assignmentStore: Object,
            dependencyStore: Object,
            eventStore: Object,
            resourceStore: Object,

            crudManager: Object,

            // Data
            assignments: Array,
            dependencies: Array,
            events: Array,
            resources: Array,
            timeRanges: Array,

            config: Object,

            // Features, only used for initialization
            treeFeature: {type: Boolean, default: false},
            columnDragToolbarFeature: {type: Boolean, default: true},
            excelExporterFeature: {
                type: [Boolean, Object], default: () => {
                    return {dateFormat: 'LLL'}
                }
            },
            summaryFeature: {type: [Boolean, Object], default: false},
            filterFeature: {type: [Boolean, Object], default: true},
            quickFindFeature: {type: Boolean, default: true},
            searchFeature: {type: Boolean, default: true},
            filterBarFeature: {type: Boolean, default: false},
            cellEditFeature: {type: [Boolean, Object], default: false},
            columnLinesFeature: {type: Boolean, default: true},
            dependenciesFeature: {
                type: [Boolean, Object], default: () => {
                    return {terminalSides: []}
                }
            },
            eventContextMenuFeature: {type: [Boolean, Object], default: false},
            eventDragCreateFeature: {type: [Boolean, Object], default: false},
            eventDragFeature: {
                type: [Boolean, Object], default: () => {
                    return {constrainDragToResource: true}
                }
            },
            eventEditFeature: {type: [Boolean, Object], default: false},
            eventFilterFeature: {type: [Boolean, Object], default: true},
            eventResizeFeature: {type: [Boolean, Object], default: false},
            eventTooltipFeature: {type: [Boolean, Object], default: true},
            groupFeature: [Boolean, Object, String],
            groupSummaryFeature: {type: [Boolean, Object], default: false},
            headerContextMenuFeature: {type: [Boolean, Object], default: true},
            labelsFeature: {
                type: [Boolean, Object], default: () => {
                    return {bottom: {field: 'id'}}
                }
            },
            nonWorkingTimeFeature: {type: [Boolean, Object], default: false},
            regionResizeFeature: {type: Boolean, default: true},
            scheduleTooltipFeature: {type: [Boolean, Object], default: true},
            sortFeature: {type: [Boolean, Object, String, Array], default: 'name'},
            stripeFeature: {type: Boolean, default: true},
            summaryToolbarFeature: [Boolean, Object],
            timeRangesFeature: {
                type: [Boolean, Object], default: () => {
                    return {
                        showHeaderElements: false,
                        enableResizing: false,
                        showCurrentTimeLine: true,
                        showToolTip: true,
                        currentDateFormat: 'HH:mm'
                    }
                }
            },
            scheduleContextMenuFeature: {type: [Boolean, Object], default: () => {
                return { processItems:({date, resourceRecord, items}) => items.splice(0,1) }
            }},

            enableEventAnimations: {type: Boolean, default: true},
            zoomOnTimeAxisDoubleClick: {type: Boolean, default: true},
            zoomOnMouseWheel: {type: Boolean, default: true},
            enableDeleteKey: {type: Boolean, default: false},
            weekStartDay: {type: Number, default: 1},
            createEventOnDblClick: {type: Boolean, default: true},
            zoomKeepsOriginalTimespan: {type: Boolean, default: false},
            /*zoomLevels: {type: Array, default: ()=>{return [
              { width: 100,   increment: 1,   resolution: 5, preset: 'dayAndWeek', resolutionUnit: 'minute' },
              { width: 250,   increment: 1,   resolution: 5, preset: 'dayAndWeek', resolutionUnit: 'minute' },
              { width: 400,   increment: 1,   resolution: 5, preset: 'dayAndWeek', resolutionUnit: 'minute' },
              { width: 550,   increment: 1,   resolution: 5, preset: 'dayAndWeek', resolutionUnit: 'minute' },
              { width: 700,   increment: 1,   resolution: 5, preset: 'dayAndWeek', resolutionUnit: 'minute' },
              { width: 60,    increment: 1,   resolution: 5, preset: 'hourAndDay', resolutionUnit: 'minute' }
            ]}}*/
        },
        mounted: function () {
            var propKeys = Object.keys(this.$props),
                featureConfig = {};

            var config = {
                // Render grid to components element
                appendTo: this.$el,

                // Listeners, will relay events using $emit
                listeners: {
                    catchAll: function (event) {
                        // Uncomment this line to log events being emitted to console
                        //console.log(event.type);
                        this.$emit(event.type, event);
                    },

                    thisObj: this
                },

                features: featureConfig
            };

            // Apply all props to grid config
            propKeys.forEach(function (prop) {
                if (prop.indexOf('Feature') > -1) {
                    var featureName = prop.substr(0, prop.length - 'Feature'.length);
                    // Prop is a feature config
                    featureConfig[featureName] = this[prop];
                } else if (prop === 'config') {
                    // Prop is a config object
                    Object.assign(config, this[prop]);
                } else if(prop === 'emptyText' && !this[prop]) {
                    // emptyText prop
                    config[prop] = this.translate('no_rows_to_display');
                } else {
                    // Prop is a config
                    if (this[prop] !== undefined) config[prop] = this[prop];
                }
            }, this);


            // Create a Bryntum Grid with props as configs
            let language = localStorage.getItem('vue-lang');
            if (!language || language === 'en-US') language = 'En';
            bryntum.scheduler.LocaleManager.applyLocale(language, false, true);

            var engine = this.schedulerEngine = new bryntum.scheduler.Scheduler(config);
            engine.eventStore && engine.eventStore.relayAll(engine, 'eventStore');
            engine.resourceStore && engine.resourceStore.relayAll(engine, 'resourceStore');
            engine.assignmentStore && engine.assignmentStore.relayAll(engine, 'assignmentStore');
            engine.dependencyStore && engine.dependencyStore.relayAll(engine, 'dependencyStore');

            //engine.eventStore.reapplyFilterOnAdd = true;
            //engine.eventStore.reapplyFilterOnUpdate = true;


            //engine.timeAxisColumn.showColumnPicker = true;
            //engine.snapRelativeToEventStartDate = true;
            //engine.rowHeightChanged = true;
            //engine.timeAxisColumn.enableCellContextMenu = true;

            this.$emit('scheduler');

            let scroll = engine.currentElement.getElementsByClassName("b-virtual-scrollers")[0];
            let header = engine.currentElement.getElementsByClassName("b-grid-header-container")[0];
            $(scroll).insertAfter($(header));

            let timeAxisItems = [];
            engine.timeAxisColumn.headerMenuItems && engine.timeAxisColumn.headerMenuItems.forEach((item) => timeAxisItems.push(item));

            timeAxisItems.push({
                cls: 'b-separator',
                icon: 'b-fw-icon b-fa-file-excel',
                text: this.translate('export_excel'),
                onItem: () => {
                    engine.expandAll();
                    engine.features.excelExporter.export({
                        filename : this.name || 'scheduler'
                    });
                }
            });

            timeAxisItems.push({
                type: 'menuitem',
                cls: 'b-separator',
                checked: false,
                text: this.translate('read_only'),
                onItem: (e) => {
                    engine.timeAxisColumn.headerMenuItems.find(x => x.text === e.item.text).checked = e.item.checked;
                    engine.readOnly = e.item.checked;
                }
            });

            timeAxisItems.push({
                type: 'menuitem',
                cls: 'b-separator',
                checked: false,
                text: this.translate('events_only'),
                onItem: (e) => {
                    engine.timeAxisColumn.headerMenuItems.find(x => x.text === e.item.text).checked = e.item.checked;
                    if (e.item.checked) engine.resourceStore.filter(x => engine.eventStore.query(y => y.resourceId === x.id).length)
                    else engine.resourceStore.clearFilters();
                }
            });

            engine.features.timeRanges && timeAxisItems.push({
                type: 'menuitem',
                checked: false,
                text: this.translate('hide_time_ranges'),
                onItem: (e) => {
                    engine.timeAxisColumn.headerMenuItems.find(x => x.text === e.item.text).checked = e.item.checked;
                    if (e.item.checked) engine.features.timeRanges.store.filter(x => x.id === 0);
                    else {
                        let ranges = [];
                        engine.features.timeRanges.store.clearFilters();
                        engine.features.timeRanges.store.allRecords.forEach((item) => ranges.push(item.data));
                        engine.timeRanges = ranges;
                    }
                }
            });

            engine.timeAxisColumn.headerMenuItems = timeAxisItems;

            let columnItems = [];

            columnItems.push({
                cls: 'b-separator',
                icon: 'b-fw-icon b-fa-search',
                text: this.translate('search'),
                menu: [{
                    type: 'container',
                    width: '15em',
                    widgets: [
                        {
                            type: 'textfield',
                            clearable: true,
                            icon: 'b-fw-icon b-fa-search',
                            cls: 'menu_item_search',
                            label: this.translate('search'),
                            onInput: (e) => {
                                engine.features.search.search(e.value);
                                engine.columns.forEach((col) => {
                                    if (col.region === 'locked') col.headerMenuItems.find(x => x.menu && x.menu[0].widgets[0].cls === e.source.cls).menu[0].widgets[0].value = e.value
                                });
                            },
                            onChange: (e) => {
                                engine.features.search.search(e.value);
                                engine.columns.forEach((col) => {
                                    if (col.region === 'locked') col.headerMenuItems.find(x => x.menu && x.menu[0].widgets[0].cls === e.source.cls).menu[0].widgets[0].value = e.value
                                });
                            }
                        },
                        {
                            type: 'button',
                            icon: 'b-fw-icon b-icon-prev',
                            tooltip: this.translate('find_previous'),
                            cls: 'b-raised b-blue',
                            width: '20%',
                            closeParent: false,
                            flex: 1,
                            onAction: () => engine.features.search.gotoPrevHit()
                        },
                        {
                            type: 'button',
                            icon: 'b-fw-icon b-icon-next',
                            tooltip: this.translate('find_next'),
                            cls: 'b-raised b-blue',
                            width: '20%',
                            style: 'margin-left: 65%',
                            closeParent: false,
                            flex: 1,
                            onAction: () => engine.features.search.gotoNextHit()
                        }
                    ]
                }]
            });

            columnItems.push({
                cls: 'b-separator',
                icon: 'b-fw-icon b-fa-minus-square',
                text: this.translate('collapse'),
                onItem: () => {
                    engine.scrollToTop();
                    engine.collapseAll();
                }
            });

            columnItems.push({
                icon: 'b-fw-icon b-fa-plus-square',
                text: this.translate('expand'),
                onItem: () => engine.expandAll()
            });

            engine.columns.forEach((col) => {
                if (col.region === 'locked') col.headerMenuItems = col.headerMenuItems || [].concat(columnItems)
            });

            //todo engine.eventEdit.editor not defined on first time in engine.on('beforeEventEdit' ...
            if (engine.editEvent) {
                engine.eventEdit.getEditor();
                engine.eventEdit.saveAndCloseOnEnter = false;
            }

            if (this.name) {
                // assigning to grid.state applies a state to the grid
                const state = JSON.parse(localStorage.getItem('scheduler-' + this.name + '-state'));
                if (state) {
                    state.columns.forEach((savedItem) => {
                        engine.state.columns.forEach((item) => {
                            // todo fix id and text fields not override the state
                            if (item.id.replace(/[0-9]/g, '') == savedItem.id.replace(/[0-9]/g, '') && savedItem.id.replace(/[0-9]/g, '') != 'col') {
                                savedItem.id = item.id;
                                savedItem.text = item.text;
                            }
                        });
                    });
                    //to prevent changing zoomLevel and tickWidth
                    state.tickWidth = engine.state.tickWidth;
                    state.zoomLevel = engine.state.zoomLevel;
                    engine.timeAxisColumn.headerMenuItems.find(x => x.text === this.translate('read_only')).checked = state.readOnly;

                    state.hideTimeRanges && engine.features.timeRanges && engine.features.timeRanges.store.filter(x => x.id === 0);

                    if (engine.features.timeRanges) engine.timeAxisColumn.headerMenuItems.find(x => x.text === this.translate('hide_time_ranges')).checked = state.hideTimeRanges;
                    engine.state = state;
                }
            }
        },
        beforeDestroy: function () {

            if (this.name) {
                let state = this.schedulerEngine.state;
                //todo preserve selectedCell prevent circular structure to JSON.stringify
                delete state.selectedCell;
                state.hideTimeRanges = this.schedulerEngine.features.timeRanges && this.schedulerEngine.timeAxisColumn.headerMenuItems.find(x => x.text === this.translate('hide_time_ranges')).checked;
                localStorage.setItem('scheduler-' + this.name + '-state', JSON.stringify(state));
            } else { //todo destroy!
                // Make sure Bryntum Grid is destroyed when vue component is
                //todo destroy with state when coloumn removed from state --> hideColumnFilterField "TypeError: Cannot read property 'classList' of null"
                this.schedulerEngine.destroy();
            }
        },
        watch: {
            barMargin: function (newValue) {
                this.schedulerEngine.barMargin = newValue;
            },

            assignments: function (newValue) {
                this.schedulerEngine.assignments = newValue.slice();
            },

            dependencies: function (newValue) {
                this.schedulerEngine.dependencies = newValue.slice();
            },

            events: function (newValue) {
                this.schedulerEngine.events = newValue.slice();
            },

            resources: function (newValue) {
                this.schedulerEngine.resources = newValue.slice();
            },

            timeRanges: function (newValue) {
                this.schedulerEngine.timeRanges = newValue.slice();
            },
            maxHeight: function (newValue) {
                this.schedulerEngine.maxHeight = newValue;
            },
            rowHeight: function (newValue) {
                this.schedulerEngine.rowHeight = newValue;
            }
        },

        methods: {
            removeEvent: function () {

            },
            addEvent: function () {

            }
        }
    }
</script>
