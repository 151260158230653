<template>
  <div class="accordion mb-space table-bordered mt-3" id="accordion">
    <div class="card">
      <div class="card-header" id="ContractHeading">
        <div style="display: inline-block">
          <button
            class="b-raised b-blue b-widget b-button b-icon"
            @click="addSection"
            v-if="IsEditable"
          >
            <i class="fa fa-plus"></i></button
          >&nbsp
        </div>
        <div
          class="card-title"
          style="display: inline-block"
          data-toggle="collapse"
          data-target="#collapseContract"
          aria-expanded="true"
          aria-controls="collapseOne1"
          v-lang.contract_section
        ></div>
      </div>
      <div
        id="collapseContract"
        class="collapse show"
        aria-labelledby="ContractHeading"
        data-parent="#contractSection"
        style=""
      >
        <div class="card-body">
          <tabs
            @changed="changeCurrentContractSection"
            @update="reorderContractSections"
            @edit="updateSection"
            @delete="deleteSection"
            :draggable="true"
          >
            <tab
              v-for="contractSection in contractSections"
              :key="contractSection.Id"
              :name="contractSection.Name"
              :editable="isEditable(contractSection)"
              :deletable="isEditable(contractSection)"
              :id="contractSection.Id"
            >
              <br />
              <preventive-maintenance
                v-if="contractSection.Id"
                :contractId="contractId"
                :sectionId="contractSection.Id"
                :IsEditable="IsEditable"
              ></preventive-maintenance>
              <br />
              <sla
                v-if="contractSection.Id"
                :sectionId="contractSection.Id"
                :IsEditable="IsEditable"
                :contractId="contractId"
              ></sla>
            </tab>
          </tabs>
          <contract-section-modal
            :contractSection="contractSection"
            :isUpdate="isContractSectionModalUpdate"
            v-if="showContractSectionModal"
            @changed="getContractSectionList"
            @close="
              contractSection = {};
              showContractSectionModal = false;
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tabs from "../../wrappers/TabView/Tabs/Tabs.vue";
import Tab from "../../wrappers/TabView/Tab/Tab.vue";
import * as api from "../../../scripts/services/api";
import * as blockui from "../../../helpers/block-ui";
import * as swal from "../../../helpers/swal";
import ContractSectionModal from "./ContractSectionModal";
import Sla from "./Sla";
import PreventiveMaintenance from "../../views/PreventiveMaintenance/PreventiveMaintenance.vue";

export default {
  components: {
    Sla,
    tab: Tab,
    tabs: Tabs,
    "contract-section-modal": ContractSectionModal,
    "preventive-maintenance": PreventiveMaintenance,
  },
  props: ["contractId", "IsEditable"],
  data() {
    return {
      contractSections: [],

      isContractSectionModalUpdate: false,
      contractSection: {},
      showContractSectionModal: false,
    };
  },
  created() {},
  async mounted() {
    await this.getContractSectionList();
  },
  methods: {
    isEditable(contractSection) {
      return this.IsEditable;
    },
    addSection() {
      blockui.pageBlock();
      $("#collapseContract").collapse("show");
      this.isContractSectionModalUpdate = false;
      this.contractSection = { ContractId: this.contractId };
      this.showContractSectionModal = true;
      blockui.unBlockPage();
    },
    async updateSection({ id }) {
      this.isContractSectionModalUpdate = true;
      blockui.blockElementPromise(this, this.$el);
      try {
        const response = await api.getContractSection(id);
        if (!response.body) {
          toastr.error(response.body.Message);
          return;
        }
        this.contractSection = response.body;
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.$el);
      }
      this.showContractSectionModal = true;
    },
    async deleteSection({ id, name }) {
      const answer = await swal.check(this, name);
      if (!answer.value) {
        return;
      }
      blockui.blockElementPromise(this, this.$el);
      try {
        const response = await api.deleteContractSection(id);
        if (!response.body.IsSuccess) {
          toastr.error(response.body.Message);
          return;
        }
        toastr.success(
          this.translate("item_deleted_successfully").formatString(name)
        );
        this.getContractSectionList();
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.$el);
      }
    },
    async changeCurrentContractSection() {},
    async getContractSectionList() {
      blockui.blockElementPromise(this, this.$el);
      try {
        const response = await api.getContractSectionList(this.contractId);
        if (!response.body.IsSuccess) {
          toastr.error(response.body.Message);
          return;
        }
        this.contractSections = response.body.Data;
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.$el);
      }
    },
    async reorderContractSections(e) {
      blockui.blockElementPromise(this, this.$el);
      try {
        this.contractSections[e.oldIndex].Order = e.newIndex + 1;
        if (e.newIndex > e.oldIndex) {
          for (let i = e.newIndex; i > e.oldIndex; i--) {
            this.contractSections[i].Order--;
          }
        } else if (e.newIndex < e.oldIndex) {
          for (let i = e.newIndex; i < e.oldIndex; i++) {
            this.contractSections[i].Order++;
          }
        }
        const response = await api.reorderContractSections({
          contractSections: this.contractSections.map((contractSection) => ({
            Id: contractSection.Id,
            Order: contractSection.Order,
          })),
        });
        if (!response.body.IsSuccess) {
          toastr.error(response.body.Message);
          return;
        }
        // this.getContractSectionList();
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.$el);
      }
    },
  },
};
</script>
