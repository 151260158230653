import * as api from '../../scripts/services/api';

// initial state
const state = {
  unitType: {
    Others: 1,
    Length: 2,
    Mass: 3,
    Time: 4,
    Temperature: 5,
    Quantity: 6,
    Area: 7,
    Volume: 8,
    Package: 9,
    VolumeFlowRate: 10,
    TimeDependentQuantity: 11,
    Energy: 12,
    Currency: 13,
  },
  infrastructure: {
    Electric: 1,
    Mechanic: 2,
    Control: 3,
  },
  workCategory: {
    Maintenance: 1,
    Operational: 2,
    Managerial: 3,
    Training: 4,
    Round: 5,
  },
  userPosition: {
    Master: 1,
    Observer: 2,
  },
  // TODO: Use instead all status enums
  workOrderStatus: {
    planned: 1,
    assigned: 2,
    onProgress: 3,
    pendingApproval: 4,
    approved: 5,
    cancelled: 6,
    onHold: 7,
    denied: 8,
    new: 9,
    resolved: 10,
    closed: 11,
    unresolved: 12,
  },
  formQuestionType: {
    multipleChoice: 1,
    checkBox: 2,
    shortText: 3,
    longText: 4,
    number: 5,
    decimal: 6,
    longDate: 7,
    shortDate: 8,
    hour: 9,
    imageUpload: 10,
    fileUpload: 11,
  },
  formStatus: {
    inUse: 1,
    toBeRevised: 2,
    revising: 3,
    preparing: 4,
    outOfService: 5,
    deleted: 6,
  },
  formProcessStatus: {
    started: 1,
    continues: 2,
    completed: 3,
    cancelled: 4,
    notStarted: 5,
  },
  pointType: {
    analog: 1,
    digital: 2,
    multistate: 3,
  },
  procedureType: {
    sop: 1,
    mop: 2,
    eop: 3,
    scp: 4,
  },
  userPositions: {
    master: 1,
    observer: 2,
  },
  userTypes: {
    staff: 1,
    outsource: 2,
    vendor: 3,
  },
  workOrderSource: {
    planned: 1,
    case: 2,
    incident: 3,
  },
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
