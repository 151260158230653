import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../../../scripts/services/api';
import * as blockui from '../../../helpers/block-ui';
import * as dateFormat from '../../../helpers/dateFormat';
import Dropdown from '../../shared/MyWorkOrderDropdown';
import * as swal from '../../../helpers/swal';
import { DateRangePickerComponent } from '@syncfusion/ej2-vue-calendars';
import { L10n } from '@syncfusion/ej2-base';

L10n.load({
  'tr': {
    'daterangepicker': {
      applyText: 'Tamam',
      cancelText: 'İptal',
      selectedDays: 'Tarih aralığı seçiniz',
      days: 'gün seçildi'
    }
  }
});

export default {
  components: {
    dropdown: Dropdown,
    "ejs-daterangepicker": DateRangePickerComponent,
  },
  data() {
    return {
      overdueStartDate: moment().add(-7, 'days').toDate(),
      overdueEndDate: moment().add(-1, 'days').toDate(),

      upcomingStartDate: moment().add(1, 'days').toDate(),
      upcomingEndDate: moment().add(7, 'days').toDate(),

      // overdueStartDateDaily: moment().add(-7, 'days').toDate(),
      // overdueEndDateDaily: moment().add(-1, 'days').toDate(),

      // upcomingStartDateDaily: moment().add(1, 'days').toDate(),
      // upcomingEndDateDaily: moment().add(7, 'days').toDate(),

      overdueWorkOrders: [],
      todayWorkOrders: [],
      upcomingWorkOrders: [],

      overdueWorkOrdersDaily: [],
      todayWorkOrdersDaily: [],
      upcomingWorkOrdersDaily: [],

      statuses: [],
      min: 1,
      max: 14,

      overdueWaterMark: 'Tarih seçiniz...',
      upcomingWaterMark: 'Tarih seçiniz...',

      dateFormatDD_MMM_YYYY: 'dd MMMM yyyy',
      seperator:' - ',

      isActivePaneDaliyWorkOrders: false
    };
  },
  mounted() {
    const self = this;
    self.init();
    self.getWorkOrderStatusList();
    self.$eventHub.$on('changeWOStatus', self.init);
    document.querySelector('.m-content').style.padding = '0px 0px';
  },
  computed: {
    ...mapState({
      workOrderStatuses: (state) => state.enums.workOrderStatus,
      workOrderSourceEnums: (state) => state.enums.workOrderSource,
    }),
    yesterday() {
      return moment().add(-1, 'days').toDate();
    },
    today() {
      return moment().toDate();
    },
    tomorrow() {
      return moment().add(1, 'days').toDate();
    },
  },
  methods: {
    getWorkOrderStatusList() {
      const self = this;
      api.getWorkOrderStatusList(null).then((res) => {
        self.statuses = res.body.Data;
      });
    },
    async init() {
      const self = this;
      blockui.pageBlock();
      const promises = [
        self.getWorkOrders(self.passDateParameter(self.overdueStartDate), self.passDateParameter(self.overdueEndDate), true),
        self.getTodayWorkOrders(),
        self.getWorkOrders(self.passDateParameter(self.upcomingStartDate), self.passDateParameter(self.upcomingEndDate), false)
      ];

      await Promise.all(promises);
      blockui.unBlockPage();
    },
    getWorkOrders(startDate, endDate, isOverdueWorkOrders) {
      const self = this;
      api.getWorkOrdersBetweenDates(startDate, endDate).then((response) => {
        if (response.body.IsSuccess) {
          const workOrders = response.body.Data;
          if(isOverdueWorkOrders) {
            Vue.set(self.overdueWorkOrders, 0, workOrders.filter((wo) => !wo.IsDailyWorkOrderCategory).sort(self.compareDatesDESC));
            Vue.set(self.overdueWorkOrdersDaily, 0, workOrders.filter((wo) => wo.IsDailyWorkOrderCategory).sort(self.compareDatesDESC));
          }
          else{
            Vue.set(self.upcomingWorkOrders, 0, workOrders.filter((wo) => !wo.IsDailyWorkOrderCategory).sort(self.compareDatesASC));
            Vue.set(self.upcomingWorkOrdersDaily, 0, workOrders.filter((wo) => wo.IsDailyWorkOrderCategory).sort(self.compareDatesASC));
          }
        }
      }, (error) => {
      });
    },
    getTodayWorkOrders() {
      const self = this;
      api.getTodayWorkOrders().then((response) => {
        if (response.body.IsSuccess) {
          const workOrders = response.body.Data;
          Vue.set(self.todayWorkOrders, 0, workOrders.filter((wo) => !wo.IsDailyWorkOrderCategory).sort(self.compareDatesDESC));
          Vue.set(self.todayWorkOrdersDaily, 0, workOrders.filter((wo) => wo.IsDailyWorkOrderCategory).sort(self.compareDatesDESC));
        }
      });
    },
    compareDatesDESC(a, b) {
      if (new Date(a.ScheduledTime) < new Date(b.ScheduledTime)) {
        return 1;
      } if (new Date(a.ScheduledTime) > new Date(b.ScheduledTime)) {
        return -1;
      }
      return 0;
    },
    compareDatesASC(a, b) {
      if (new Date(a.ScheduledTime) < new Date(b.ScheduledTime)) {
        return -1;
      } if (new Date(a.ScheduledTime) > new Date(b.ScheduledTime)) {
        return 1;
      }
      return 0;
    },
    viewDateTimeFormat(date) {
      return dateFormat.viewDateTimeFormat(date);
    },
    viewDateFormat(date) {
      return dateFormat.viewDateFormat(date);
    },
    viewRelativeDateTimeFormat(date) {
      return dateFormat.viewRelativeDateTimeFormat(date);
    },
    passDateParameter(date) {
      return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    },
    routeToMyWorkOrder(workOrderId) {
      this.$router.push({ name: 'MyWorkOrder', query: { from: 'MyWorkOrders', workOrderId } });
    },
    changeStatusWorkOrderControl(myWorkOrder, newStatusId) {
      if (myWorkOrder.OrderSourceId != newStatusId) {
        const self = this;

        const previousOrderStatus = self.statuses.find((status) => status.Id == myWorkOrder.OrderStatusId);
        const currentOrderStatus = self.statuses.find((status) => status.Id == newStatusId);
        if (currentOrderStatus.Id != self.workOrderStatuses.onHold) {
          swal.check(self, self.translate('are_you_sure'), self.translate('ask_status_update_from_to').formatString(myWorkOrder.WorkName, previousOrderStatus.Name, currentOrderStatus.Name)).then((e) => {
            if (e.value) {
              self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
            }
          });
        } else {
          self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
        }
      }
    },
    changeStatusWorkOrder(selectedWorkOrder, currentOrderStatus) {
      const self = this;
      blockui.pageBlock();
      self.$globalApiClient.workOrder.workOrderUpdateStatus({
        Id: selectedWorkOrder.WorkOrderId,
        OrderStatusId: currentOrderStatus.Id,
      }).then((response) => {
        if (response.data.IsSuccess) {
          toastr.success(self.translate('status_update_work_order_successfully').formatString(selectedWorkOrder.WorkName, selectedWorkOrder.OrderStatusName, currentOrderStatus.Name));
          self.init();
        } else {
          toastr.error(response.data.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    getWorkOrdersBetweenDates(event, isOverdueWorkOrders) {
      const self = this;
      const startDate = moment(event.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      const endDate = moment(event.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD');

      if(!(event.startDate === null || event.endDate === null)) {
        blockui.pageBlock();
        if(isOverdueWorkOrders) {
          self.overdueStartDate = event.startDate;
          self.overdueEndDate = event.endDate;
        }
        else {
          self.upcomingStartDate = event.startDate;
          self.upcomingEndDate = event.endDate;
        }

        self.getWorkOrders(startDate, endDate, isOverdueWorkOrders);
        blockui.unBlockPage();
      }
    }
  },
  beforeDestroy() {
    this.$eventHub.$off('changeWOStatus');
  },
};