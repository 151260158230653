import { mixinz } from '../../../../../scripts/global/mixinz';

export default {
  props: ['value', 'questionTypeId', 'styled', 'enumFormQuestionType'],
  mixins: [mixinz],
  data() {
    return {
      val: '',
    };
  },
  methods: {
    numberInputKeypressHandler(e, allowDot) {
      e = (e) || window.event;
      if (e.key === 'e' || e.key === '+' || ((e.key === '.' || e.key === ',') && !allowDot)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.val = this.value;
  },
  watch: {
    value(newVal) {
      this.val = newVal;
    },
    val(newVal) {
      this.$emit('input', newVal);
    },
  },
};
