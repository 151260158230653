export default {
  props: {
    isFullScreen: {},
    events: {
      default: () => [],
      type: Array,
    },
    title: {
      default: null,
      type: String,
    },
  },
  components: {},
  filters: {},
  data() {
    return {};
  },
  methods: {
    clickHandler(eventName) {
      this.$emit(eventName);
    },
    isShown(eventName) {
      return this.events.find((x) => x === eventName) != null;
    },
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  watch: {},
};
