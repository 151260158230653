<template>
    <div>
        <div class="dropdown">
            <button class="btn btn-default" data-toggle="dropdown" type="button"><i class="fa fa-play"></i></button>
            <ul class="dropdown-menu" :class="styleClass" :style="{opacity:dropdownOpacity}">
                <li v-if="workOrderForms.length==0">
                    <a>
                    <span style="color:#576472;" v-lang.no_forms_to_display></span>
                </a></li>
                <li v-for="form in workOrderForms" :class="form.IsCurrentForm ? 'suitable-form' : '' ">
                    <a href="javascript:void(0);"
                       @click="openForm(selectedWorkOrder, nextStatusId, form.Id, form.ProcedureTypeId, form.FormProcessStatusId)">
                        <p>{{form.Name}}
                            <span class="badge pull-right"
                                  :style="{backgroundColor:form.ColorCode,color:'white'}">
                        {{form.FormProcess}}
                    </span>
                        </p>
                    </a>
                </li>

            </ul>

        </div>
        <work-order-form-modal v-bind="params"
                               v-if="showFormModal"
                               @close="fetchWorkOrderForm(currentFormId); showFormModal = false; params = {}"
                               @changeForm="changeForm"
                               @changeWorkOrderStatus="closeFormAndChangeFormStatus"/>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as api from '../../scripts/services/api';
import WorkOrderFormModal from '../partials/modals/WorkOrderFormModal';
import * as blockui from '../../helpers/block-ui';
import { mixinz } from '../../scripts/global/mixinz';

export default {
  mixins: [mixinz],
  components: {
    'work-order-form-modal': WorkOrderFormModal,
  },
  computed: {
    ...mapState({
      workOrderStatuses: (state) => state.enums.workOrderStatus,
      formProcessStatus: (state) => state.enums.formProcessStatus,
      workOrderSourceEnums: (state) => state.enums.workOrderSource,
    }),
    ...mapGetters({
      getWorkOrderForms: 'getWorkOrderForms',
    }),
    workOrderForms() {
      const workOrderForms = this.getWorkOrderForms.find((x) => x.workOrderId == this.workOrderId);
      return workOrderForms ? workOrderForms.forms : [];
    },
  },
  props: {
    workOrderId: {
      type: [Number, String],
      required: true,
    },
    nextStatusId: {
      type: [Number],
      required: false,
    },
    currentFormModal: {
      type: [String],
      required: false,
    },
    styleClass: {
      type: [String],
      required: false,
    },
  },
  data() {
    return {
      //  workOrderForms: [],
      params: {},
      showFormModal: false,
      formStatusContinues: [],
      formStatusNotStarted: [],
      currentFormId: null,
      notShow: false,
      showDrop: false,
      selectedWorkOrder: {},
      statuses: [],
      dropdownOpacity: 1,
    };
  },
  async created() {
    const self = this;
    await self.$store.dispatch('getWorkOrderFormsByWorkOrderId', { workOrderId: self.workOrderId });
  },
  async mounted() {
    const self = this;
    mApp.initTooltips();
    const dropdown = $(this.$el).find('.dropdown');
    dropdown.on('show.bs.dropdown', this.onDropdownShown);
  },
  methods: {
    async onDropdownShown(e) {
      const self = this;
      this.dropdownOpacity = 0;
      self.currentFormModal ? blockui.blockModalPromise(self) : blockui.pageBlock(self);

      await self.getWorkOrderStatus();
      await self.getWorkOrder(self.workOrderId);
      if (self.selectedWorkOrder.OrderStatusId != self.workOrderStatuses.onProgress) {
        self.nextStatusId && await self.changeWorkOrderStatus(self.selectedWorkOrder, self.nextStatusId);
      }
      this.dropdownOpacity = 1;
      self.currentFormModal ? blockui.unBlockModal(self) : blockui.unBlockPage(self);
    },
    async openForm(workOrder, newStatusId, formId, procedureTypeId, formProcessStatusId) {
      const self = this;
      self.currentFormModal ? blockui.blockModalPromise(self) : blockui.pageBlock(self);
      // await self.getWorkOrder(self.workOrderId);
      self.$nextTick(() => {
        const isEditable = self.selectedWorkOrder.IsFormsAnswerable && self.selectedWorkOrder.IsEditable && (!formProcessStatusId || formProcessStatusId == self.formProcessStatus.started || formProcessStatusId == self.formProcessStatus.continues || formProcessStatusId == self.formProcessStatus.notStarted);
        if (self.selectedWorkOrder && self.selectedWorkOrder.OrderStatusId) {
          if (!self.currentFormModal) {
            self.currentFormId = formId;
            this.showModal(
              'params',
              {
                selectedWorkOrderFormsCount: self.workOrderForms && self.workOrderForms.length ? self.workOrderForms.length : 0,
                formId,
                procedureTypeId,
                workOrderId: self.workOrderId,
                isEditable,
                continueButton: false,
              },
              'showFormModal',
              'form_modal',
            );
            /* Vue.set(self, "params", {
                                selectedWorkOrderFormsCount: self.workOrderForms.length && self.workOrderForms.length ? self.workOrderForms.length : 0,
                                formId: self.currentFormId,
                                procedureTypeId: procedureTypeId,
                                workOrderId: self.workOrderId,
                                isEditable: isEditable,
                                continueButton: false,
                            }); */
            // self.changeWorkOrderStatus(self.selectedWorkOrder, newStatusId);
          } else {
            self.$emit('changeForm', {
              selectedWorkOrderFormsCount: self.workOrderForms.length && self.workOrderForms.length ? self.workOrderForms.length : 0,
              currentModal: self.currentFormModal,
              formId,
              procedureTypeId,
              formProcessStatusId,
              isEditable,
              continueButton: false,
            });
          }
        }
      });
      self.currentFormModal ? blockui.unBlockModal(self) : blockui.unBlockPage(self);
    },
    async fetchWorkOrderForm(formId) {
      blockui.blockElementPromise(this, this.$el);
      try {
        await this.$store.dispatch('getWorkOrderFormsByWorkOrderIdAndFormId', {
          workOrderId: this.workOrderId,
          formId,
        });
        /*      const response = await api.getFormStatusSummary(this.workOrderId, formId);
                          if (!response.body || !response.ok) {
                              throw new Error("Error");
                          }
                          const formIndex = this.workOrderForms.findIndex(form => form.Id == formId);
                          if (formIndex >= 0) {
                              Vue.set(this.workOrderForms, formIndex, response.body);
      */
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.$el);
      }
    },
    async getWorkOrder(workOrderId) {
      const self = this;
      try {
        const response = await api.getWorkOrderSummary(workOrderId);
        Vue.set(self, 'selectedWorkOrder', response.body);
      } catch (e) {
      }
    },
    async getWorkOrderStatus() {
      const self = this;
      await api.getWorkOrderStatusList(null).then((res) => {
        self.statuses = res.body.Data;
      });
    },
    async changeWorkOrderStatus(workOrder, newStatusId) {
      const self = this;
      const currentOrderStatus = self.statuses.find((status) => status.Id == newStatusId);
      if (workOrder.OrderStatusId != self.workOrderStatuses.onProgress) {
        await self.$globalApiClient.workOrder.workOrderUpdateStatus({
          Id: workOrder.Id,
          OrderStatusId: currentOrderStatus.Id
        }).then((response) => {
          if (response.data.IsSuccess) {
            toastr.success(self.translate('status_update_work_order_successfully').formatString(workOrder.WorkName, workOrder.OrderStatusName, currentOrderStatus.Name));
            self.$eventHub.$emit('reloadTabCounts', { workOrderId: workOrder.Id });
            self.$eventHub.$emit('changeWOStatus', {
              workOrderId: workOrder.Id,
              workOrderStatusId: currentOrderStatus.Id,
              workOrderStatusColorNo: currentOrderStatus.Color.Code,
              workOrderStatusName: currentOrderStatus.Name,
              isFormsAnswerable: currentOrderStatus.IsFormsAnswerable,
            });
            self.$eventHub.$emit('changeWOStatusButtons', {
              workOrderId: workOrder.Id,
            });
          } else {
            toastr.error(response.data.Message);
          }
        });
      }
    },
    closeFormAndChangeFormStatus(e) {
      const self = this;
      self.showFormModal = false;
      self.params = {};
      self.$emit('closeFormAndChangeFormStatus', e);
    },
    changeForm(e) {
      const self = this;
      // TODO : Eren - check blocks is necessary
      blockui.pageBlock(self);
      if (self.currentFormId != e.formId) {
        self.$nextTick(() => {
          self.currentFormId = e.formId;
          Vue.set(self, 'params', {
            selectedWorkOrderFormsCount: self.workOrderForms && self.workOrderForms.length ? self.workOrderForms.length : 0,
            formId: e.formId,
            procedureTypeId: e.procedureTypeId,
            workOrderId: self.workOrderId,
            isEditable: e.isEditable,
            continueButton: false,
          });
        });
        blockui.unBlockPage();
      } else {
        blockui.unBlockPage();
      }
    },
  },
};
</script>

<style scoped>
    ul.dropdown-menu {
        width: 320px;
        height: auto;
        max-height: 250px;
        overflow-x: hidden;
        overflow-y: scroll;
        z-index: 3 !important;
        -webkit-overflow-scrolling: auto; /*Safari on İos */
        -webkit-user-drag: auto  /*Chrome on Android*/

    }

    ul.dropdown-menu::-webkit-scrollbar {
        width:4px;
    }

    ul.dropdown-menu::-webkit-scrollbar-thumb {
        background: #666;
        border-radius: 20px;
    }

    @media (min-width: 327px) and (max-width: 1199px) {
        .dropdown-menu {
            left: auto !important;
            transform: translate3d(-200px, 40px, 0px) !important;
        }
    }

    /*-----Media Query For Ipad - Iphone -------*/

    @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
        .dropdown-menu li {
            word-wrap: break-word;
            white-space: normal;
            height: 9vh !important;
        }

        .dropdown-menu > li > a:hover {
            background-color: #00CC88;
            color: #ffffff;
            height: 9vh !important;
        }

    }

    @media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
        .dropdown-menu li {
            word-wrap: break-word;
            white-space: normal;
            height: 9vh !important;
        }

        .dropdown-menu > li > a:hover {
            background-color: #00CC88;
            color: #ffffff;
            height: 9vh !important;
        }

    }

    /*-----Media Query For Ipad - Iphone -------*/
    .dropdown-menu li {
        word-wrap: break-word;
        white-space: normal;
        height: 7vh;
    }
    .todo-tasklist-item li {
        color: #577688;
        font-size: 13px;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        padding: 0 !important;
    }

    .todo-tasklist-item ul {
        margin: 0;
    }

    .dropdown-menu > li > a:hover {
        background-color: #00CC88;
        color: #ffffff;
        height: 7vh;
    }

    .suitable-form {
        background-color: #0088BB;

    }

    .suitable-form > a {
        color: #ffffff;
    }

</style>
