import * as api from '../../scripts/services/api';
import * as blockui from '../../helpers/block-ui';

// initial state
const state = {
  floorList: [],
};

// getters
const getters = {};

// actions
const actions = {
  getFloorList({ commit }, data) {
    blockui.blockSelect2('floor');
    api.getFloors(data).then((res) => {
      commit('setFloorList', res.body);
      blockui.unBlockSelect2('floor');
    });
  },
};

// mutations
const mutations = {
  setFloorList(state, floors) {
    state.floorList = floors;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
