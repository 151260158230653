<template>
    <form>
        <h6 v-lang.financial_information></h6>
        <br>
        <div class="row">
            <div class="col-md-3 col-lg-3 col-xl-2">
                <div class="form-group"
                     :class="{ 'form-group--error': $v.selectedContract.ContractPrice.$error&&validation,'has-danger': $v.selectedContract.ContractPrice.$error&&validation}">
                    <label><span v-lang.contract_price></span></label>
                    <input type="text" class="form-control" min="0" step="1" autocomplete="off" @keypress="allowNumbersOnlyForDecimal" maxlength="19"
                           v-model="selectedContract.ContractPrice" name="Price"
                           :disabled="!IsEditable">
                    <div v-if="$v.selectedContract.ContractPrice.$error&&validation" style="margin-top:25%">
                        <div v-if="$v.selectedContract.ContractPrice.$error&&validation"
                             class="form-control-feedback error"
                             v-lang.only_decimal_and_integer_must_be_entered></div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-lg-3 col-xl-2">
                <div class="form-group">
                    <label><span v-lang.unit></span></label>
                    <multiselect
                            v-model="selectedUnit"
                            :options="CurrencyUnitList"
                            label="Sign"
                            track-by="Id"
                            v-bind="selectOptions"
                            :disabled="!IsEditable">
                    </multiselect>
                </div>
            </div>
            <div class="col-md-3 col-lg-3 col-xl-2">
                <div class="form-group m-form__group">
                    <label><span v-lang.annual_contract_price></span></label>
                    <label type="text" name="AnnualContractPrice" class="form-control m-input" style="width: fit-content;min-width:100%;"
                           :disabled="!IsEditable"><span style="text-overflow: clip; white-space: pre;"
                            v-if="selectedContract.StartDate&&selectedContract.EndDate&&!(selectedContract.StartDate==selectedContract.EndDate)&&selectedContract.ContractPrice&&selectedUnit"
                    >{{calculateDayCount}}</span></label>
                </div>
            </div>
        </div>
    </form>

</template>

<script>
import { decimal, maxLength } from 'vuelidate/lib/validators';
import * as api from '../../../scripts/services/api';
import { mixinz } from '../../../scripts/global/mixinz';
import * as dateFormat from '../../../helpers/dateFormat';
/* import {price} from '../../../scripts/pages/Contract' */

export default {
  mixins: [mixinz],
  props: ['value', 'validation', 'IsEditable'],
  data() {
    return {
      CurrencyUnitList: [],
      selectedContract: {},
      ContractForYearPrice: null,
    };
  },
  validations() {
    return {
      selectedContract: {
        ContractPrice: {
          decimal,
          maxLength: maxLength(19),
          /* price */
        },
      },
    };
  },
  methods: {
    viewDateTimeFormat(date) {
      return dateFormat.viewDateTimeFormat(date);
    },

    allowNumbersOnlyForDecimal(e) {
      const self = this;
      const code = (e.which) ? e.which : e.keyCode;
      if (code > 31 && code != 46 && (code < 48 || code > 57)) {
        e.preventDefault();
      }
      if (code == 46 && (self.selectedContract.ContractPrice == '' || self.selectedContract.ContractPrice.lastIndexOf('.') > -1)) {
        e.preventDefault();
      }
    },
  },
  computed: {
    calculateDayCount() {
      const startDate = new Date(this.selectedContract.StartDate);
      const endDate = new Date(this.selectedContract.EndDate);
      if (startDate && endDate) {
        if ((startDate - endDate) == 0 || this.selectedContract.ContractPrice < 0 || (startDate > endDate)) {
          return 0;
        }

        const days = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
        const oneDayPrice = this.selectedContract.ContractPrice / days;
        return `${(oneDayPrice * 365).toFixed(3)} ${this.selectedUnit.Sign}`;
      }
      return 0;
    },
    selectedUnit: {
      get() {
        const self = this;
        return self.selectGet('CurrencyUnitList', 'selectedContract.CurrencyUnitId', 'Id', 'Sign');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedContract.CurrencyUnitId', 'Id', newValue);
      },
    },
  },
  mounted() {
    this.$v.$touch();
    this.selectedContract = this.value;
    api.getCurrencyUnit('Currency').then((res) => {
      this.CurrencyUnitList = res.body.Data;
    });
  },
  watch: {
    selectedContract(val) {
      this.$emit('input', val);
    },
    value(newValue) {
      this.selectedContract = newValue;
      // this.calculateDayCount();
    },
  },
};
</script>

<style scoped>

</style>
