<template>
  <div>
    <div class="input-group">
      <input type="number" min="0" step="1"  class="form-control just-number" v-model="hour" :disabled="disabled"
             :required="required" @change="changedHandler">
      <span class="input-group-text" v-lang.hour></span>
      <input type="number" min="0" max="59" step="1" class="form-control just-number" v-model="minute" :required="required"
              :disabled="disabled" @change="changedHandler">
      <span class="input-group-text" v-lang.minute></span>
    </div>
    <input type="hidden" :value="processedValue" :required="required" :name="name" :disabled="disabled">
  </div>
</template>
<script>
import Vue from 'vue';

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      hour: 0,
      minute: 0,
    };
  },
  mounted() {
    const self = this;
    self.setTime(self.value);
    Vue.justNumber;
  },
  computed: {
    processedValue() {
      const self = this;
      const minute = Number.isInteger(parseInt(self.minute)) ? parseInt(self.minute) : 0;
      const hour = Number.isInteger(parseInt(self.hour)) ? parseInt(self.hour) : 0;
      return minute + (hour * 60);
    },
  },
  watch: {
    value(e) {
      this.setTime(e);
    },
  },
  methods: {
    setTime(minute) {
      const self = this;
      let tempValue = minute;
      self.hour = Math.floor(tempValue / 60);
      tempValue %= 60;
      self.minute = Math.floor(tempValue);
    },
    changedHandler() {
      const self = this;

      const minute = parseInt(self.minute);
      const hour = parseInt(self.hour);

      if (!Number.isInteger(minute)) {
        self.minute = 0;
      }
      if (!Number.isInteger(hour)) {
        self.hour = 0;
      }
      if (minute < 0) {
        self.minute = 0;
      }
      if (hour < 0) {
        self.hour = 0;
      }
      if (minute > 59) {
        self.minute = 59;
      }

      self.$emit('input', self.processedValue);
    },
  },
};
</script>
