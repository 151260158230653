
























































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'

import { CFMDTOInfrastructureGroup } from '@/scripts/services/ClientApiAuto'

@Component<InfrastructureGroupDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
    },
    validations() {
        return {
        infrastructureGroup:{
            Name: {required}
        },
        selectedInfrastructure: {
            InfrastructureId: {required}
        },
        }
    },
})
export default class InfrastructureGroupDialog extends CFMDialog {
    infrastructureGroup: CFMDTOInfrastructureGroup = {Name: ''};
    infrastructureList : {Name:String, Id:Number}[] = [];
    
    selectedInfrastructure :CFMDTOInfrastructureGroup = {Name: ''}

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.infrastructureGroup.infrastructureGroupGetById({id: <number>this.id});
        this.infrastructureGroup = res.data;
      }
      const promises = [
      this.getInfrastructures()
      ];
      if(this.isUpdate){
        if(this.infrastructureGroup && this.infrastructureGroup.Infrastructure){
          this.selectedInfrastructure = this.infrastructureGroup;
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    async getInfrastructures(){
      const response = await this.apiClient.infrastructure.infrastructureGetInfrastructures();
      this.infrastructureList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Id: this.infrastructureGroup.Id,
        Name: this.infrastructureGroup.Name,
        IsActive : this.infrastructureGroup.IsActive,
        InfrastructureId : this.selectedInfrastructure.InfrastructureId
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.infrastructureGroup.infrastructureGroupSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
