<template>
  <div class="workorder_info_wrapper row">
      <div class="col-md-4">
          <div class="workorder_info_item">
              <div class="row">
                  <div class="col-md-6">
                      <label>{{translate('id_no')}}</label>
                      <p>{{workOrder.Id}}</p>
                  </div>
                  <div class="col-md-6">
                      <label>{{translate('priority')}}</label>
                      <p>{{workOrder.PriorityType}}</p>
                  </div>
              </div>
          </div>
          <div class="workorder_info_item">
              <label v-lang.task_category></label>
              <p v-if="workOrder.OrderSourceId == workOrderSourceEnums.planned">{{workOrder.WorkCategory}}</p>
              <p v-else>{{workOrder.OrderCategoryName}}</p>
          </div>
          <div class="workorder_info_item">
              <label>
                  {{translate('status')}}
                  <button v-if="workOrder.IsEditable&&workOrderStatusChangeable"
                          type="button"
                          class="pull-right edit-btn"
                          @click="updateStatus(workOrder.Id)">
                      <i class="fa fa-edit"></i>
                  </button>
              </label>
              <div>
                  <div class="btn-group pull-right m-section__content " role="group" v-if="workOrder.IsEditable">
                      <button @click="changeStatusWorkOrderControl(workOrder, statusEnums.pendingApproval)"
                              data-container="body" data-toggle="m-tooltip" data-placement="top"
                              data-original-title="Onaya Gönderildi"
                              type="button"
                              v-if="workOrder.IsConvertibleToApprove"
                              class="btn btn-secondary" value="4"><i class="fa fa-share-square"></i></button>
                      <button @click="changeStatusWorkOrderControl(workOrder, statusEnums.cancelled)" type="button"
                              data-container="body" data-toggle="m-tooltip" data-placement="top" data-html="true"
                              data-original-title="İptal Edildi"
                              v-if="workOrder.IsConvertibleToCancelled"
                              class="btn btn-secondary" value="6"><i class="fa fa-times"></i></button>
                      <button @click="changeStatusWorkOrderControl(workOrder, statusEnums.onHold)" type="button"
                              data-toggle="m-tooltip" data-placement="top" data-original-title="Beklemede"
                              v-if="workOrder.IsConvertibleToPending"
                              class="btn btn-secondary" value="7"><i class="fa fa-pause"></i></button>
                      <dropdown :nextStatusId="statusEnums.onProgress"
                                :workOrderId="workOrder.Id"
                                v-if="workOrder.IsConvertibleToStarted"
                                @closeFormAndChangeFormStatus="changeStatusButton"></dropdown>
                  </div>
                  <p class="mt-3">
                      <span class="mt-5"
                            style="color: white;"
                            :style="{backgroundColor:workOrder.ColorCode}">
                          {{workOrder.OrderStatusName}}
                          <template v-if="workOrder.OrderSubStatusName">
                              - {{workOrder.OrderSubStatusName}}
                          </template>
                      </span>
                  </p>
              </div>
              <p v-if="workOrder.OrderStatusDescription">
                  <i class="fas fa-info-circle"></i>
                  {{workOrder.OrderStatusDescription}}
              </p>
          </div>
      </div>
      <div class="col-md-4">
          <div class="workorder_info_item">
              <label>
                  <i class="fa fa-calendar"></i>
                  {{translate('scheduled_date')}}
              </label>
              <p>{{viewDateFormat(workOrder.ScheduledTime)}}</p>
          </div>

          <div class="workorder_info_item">
              <label>
                  <i class="fa fa-clock"></i>
                  {{translate('scheduled_hour')}}
              </label>
              <p>{{viewTimeFormat(workOrder.ScheduledTime)}}</p>
          </div>
          <div class="workorder_info_item">
              <label>
                  <i class="fa fa-hourglass-half"></i>
                  {{translate('estimated_duration')}}
              </label>
              <p>{{workOrder.Duration}} {{translate('abbreviation_minute')}}</p>
          </div>
      </div>
      <div class="col-md-4">
          <div class="workorder_info_item">
              <label>
                  <i class="fa fa-calendar"></i>
                  {{translate('actual_date')}}
              </label>
              <p>{{workOrder.StartedDate ? viewDateFormat(workOrder.StartedDate) : '-'}}</p>
          </div>
          <div class="workorder_info_item">
              <label>
                  <i class="fa fa-clock"></i>
                  {{translate('actual_time')}}
              </label>
              <p>{{workOrder.StartedDate ? viewTimeFormat(workOrder.StartedDate) : '-' }}</p>
          </div>
          <div class="workorder_info_item">
              <label>
                  <i class="fa fa-hourglass-half"></i>
                  {{translate('actual_duration')}}

                  <button v-if="shouldShowButton"
                          type="button"
                          class="pull-right edit-btn"
                          @click="toggleSpentMinutesEdit(workOrder.Id)">
                      <i class="fa fa-edit"></i>
                  </button>
              </label>

              <div v-if="workOrder.isSpentTimeEdit" class="form-group m-form__group">
                  <!-- <label><span v-lang.notify_before_days></span></label> -->
                  <div class="input-group">
                      <input type="number" class="form-control just-number" name="spentTimeMinutes" v-model="workOrder.tempSpentTimeMinutes" autocomplete="off" min="1" step="1" required/>
                      <span class="input-group-text" v-lang.minute></span>
                      <button type="submit" class="btn btn-primary" :disabled="workOrder.tempSpentTimeMinutes == workOrder.SpentTimeMinutes" @click="saveWorkOrderSpentMinutes" v-lang.save></button>
                  </div>
              </div>
              <p v-else>{{workOrder.SpentTimeMinutes}} {{(workOrder.SpentTimeMinutes||workOrder.SpentTimeMinutes==0)? translate('abbreviation_minute') : '-'}}</p>
          </div>
      </div>
      <div v-if="workOrder.OrderStatusNote" class="col-md-4">
          <div class="workorder_info_item">
              <label>
                  <i class="fas fa-file-alt"></i>
                  {{translate('status_note')}}
              </label>
              <p>{{workOrder.OrderStatusNote}}</p>
          </div>
      </div>
      <div v-if="workOrder.OrderSourceId == workOrderSourceEnums.planned" class="col-md-4">
          <div class="workorder_info_item">
              <label>
                  <i class="fas fa-file-alt"></i>
                  {{translate('work_description')}}
              </label>
              <p>{{workOrder.WorkDescription}}</p>
          </div>
      </div>
      <div v-if="workOrder.OrderSourceId != workOrderSourceEnums.planned" class="col-md-4">
          <div class="workorder_info_item">
              <label>
                  <i class="fas fa-file-alt"></i>
                  {{translate('description')}}
              </label>
              <p>{{workOrder.Description}}</p>
          </div>
      </div>
      <div class="col-md-4">
          <div class="workorder_info_item">
               <label>
                  <i class="fas fa-file-alt"></i>
                  {{translate('note')}}
              </label>
              <p>{{workOrder.Note}}</p>
          </div>
      </div>

        <div class="col-md-4" v-show="VendorShow">
            <div class="workorder_info_item">
                <label>
                    <i class="fa fa-clock"></i>
                    {{translate('vendor_runtime')}}
                </label>
                <div class="input-vendor">
                  <div class="vendor-left">
                    <span v-lang.start_time></span>
                    <date-picker v-model="VendorStartDate"
                                    :config="pickerDateTime"
                                    name="VendorStartDate"
                                    :disabled="vendorDisabled"
                                    :required="true" autocomplete="off"/>
                  </div>
                  <div class="vendor-right">
                    <span  v-lang.end_time></span>
                    <date-picker v-model="VendorEndDate"
                                    :config="pickerDateTime"
                                    name="VendorEndDate"
                                    :disabled="vendorDisabled"
                                    :required="true" autocomplete="off"/>
                  </div>
                  <button type="submit" class="btn btn-primary" :disabled="vendorDisabled" v-lang.save @click="saveData"></button>
                </div>  
            </div>
        </div>
      <work-order-status-modal v-if="showWorkOrderStatusModal"
                               v-bind="params"
                               @close="showWorkOrderStatusModal = false; params = {}"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import * as dateFormat from '../../../../../helpers/dateFormat';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import { mixinz } from '../../../../../scripts/global/mixinz';
import WorkOrderStatusModal from '../../../../partials/modals/WorkOrderStatusModal';
import * as swal from '../../../../../helpers/swal';

import MyWorkOrderDropdown from '../../../../shared/MyWorkOrderDropdown';

export default {
name: 'WorkOrderCard',
mixins: [mixinz],
props: ['workOrder', 'workOrderStatusChangeable'],
components: {
  'work-order-status-modal': WorkOrderStatusModal,
  'dropdown': MyWorkOrderDropdown
},
permissions: [],
data() {
  return {
    showWorkOrderStatusModal: false,
    params: {},
    statuses: [],
    VendorShow:false,
    vendorDisabled:false,
    vendorStartDate: null,
    vendorEndDate: null,
  };
},
mounted() {
  const self = this;
  api.getWorkOrderStatusList(null).then((res) => {
    self.statuses = res.body.Data;
  });
  this.$eventHub.$on('changeWOStatusButtons', ({ workOrderId }) => {
    if (self.workOrder.Id == workOrderId) {
      self.changeStatusButton(self.workOrder.Id);
      // todo: schupy
    }
  });
  mApp.initTooltips();
  const OrderStatusId = this.workOrder.OrderStatusId;
  if(OrderStatusId == 4 || OrderStatusId == 5 || OrderStatusId == 6){
    this.vendorDisabled = true;
  }
  this.workOrder.AssignedUsers.some(item => {
  if(item.UserTypeId == 3){
     this.VendorShow = true;
  }
  if (this.workOrder.VendorWorkTime) {
    if(this.workOrder.VendorWorkTime.VendorStartDate){
      self.vendorStartDate = moment(this.workOrder.VendorWorkTime.VendorStartDate).toDate();
    }
    if(this.workOrder.VendorWorkTime.VendorEndDate){
      self.vendorEndDate = moment(this.workOrder.VendorWorkTime.VendorEndDate).toDate();
    }
  }
  

});
},
computed: {
  ...mapState({
    statusEnums: (state) => state.enums.workOrderStatus,
    workOrderSourceEnums: (state) => state.enums.workOrderSource,
  }),
  VendorStartDate: {
    get() {
      if (this.vendorStartDate) {
        return moment(this.vendorStartDate).format(this.pickerDateTimeFormat);
      }
      return null;
    },
    set(val) {
      if (val) {
        Vue.set(this, 'vendorStartDate', moment(val, this.pickerDateTimeFormat).toDate());
      } else {
        Vue.set(this, 'vendorStartDate', val);
      }
      return val;
    },
  },
  VendorEndDate: {
    get() {
      if (this.vendorEndDate) {
        return moment(this.vendorEndDate).format(this.pickerDateTimeFormat);
      }
      return null;
    },
    set(val) {
      if (val) {
        Vue.set(this, 'vendorEndDate', moment(val, this.pickerDateTimeFormat).toDate());
      } else {
        Vue.set(this, 'vendorEndDate', val);
      }
      return val;
    },
  },
  shouldShowButton() {

    const localStorageData =  localStorage.getItem("clientSideItem");
    
    if (localStorageData) {
      this.permissions = localStorageData.split(',');
    }else {
      this.permissions = [];
    }

    return ((this.workOrder.OrderSourceId == 3) || this.permissions.includes('WorkOrder.SpentMinuteUpdate')) && 
        ((this.workOrder.OrderSourceId == 3) || 
        (this.workOrder.OrderSourceId == 1 && 
          (this.workOrder.OrderStatusId == 4 || this.workOrder.OrderStatusId == 5)));
    }
},
methods: {
  async saveWorkOrderSpentMinutes() {
    if (!(this.workOrder.tempSpentTimeMinutes > 0)) {
      return;
    }
    const result = await this.setWorkOrderSpentMinutes(this.workOrder.Id, this.workOrder.tempSpentTimeMinutes);
    if (result) {
      Vue.set(this.workOrder, 'isSpentTimeEdit', false);
      Vue.set(this.workOrder, 'SpentTimeMinutes', this.workOrder.tempSpentTimeMinutes);
    }
  },
  async saveData() {
      const self = this;
      if(self.workOrder.OrderStatusId == 4 || self.workOrder.OrderStatusId == 5 || self.workOrder.OrderStatusId == 6){
        toastr.error(self.translate('vendor_disabled'));
        return;
      }
      if(!self.vendorStartDate && !self.vendorEndDate){
        toastr.error(self.translate('vendor_runtime_empty'));
        return;
      }
      if (!self.vendorStartDate) {
        toastr.error(self.translate('start_date_is_required'));
        return;
      }
      if(self.vendorStartDate && self.vendorEndDate){
        if (moment(new Date(self.vendorEndDate)) <= moment(new Date(self.vendorStartDate))) {
        toastr.error(self.translate('vendor_end_date'));
        return;
        }
      }

      const formData = {
        Id: self.workOrder.VendorWorkTime ? self.workOrder.VendorWorkTime.Id : 0,
        WorkOrderId: self.workOrder.Id,
        VendorStartDate: dateFormat.postDateTimeFormat(self.vendorStartDate),
        VendorEndDate: self.vendorEndDate ? dateFormat.postDateTimeFormat(self.vendorEndDate) : null,
        IsActive: true,
      };

      self.$globalApiClient.workOrder.workOrderSaveVendorWorkTime(formData).then((response) => {
        if (response) {
          toastr.success(self.translate('vendor_recorded'));
          this.workOrder.VendorWorkTime = response.data.Data
        } else {
          toastr.error("Hata oluştu");
        }
      });
    },
  async setWorkOrderSpentMinutes(workOrderId, spentMinutes) {
    const self = this;
    blockui.pageBlock(self);
    try {
      const response = await api.setWorkOrderSpentMinutes(workOrderId, spentMinutes);
      blockui.unBlockPage();
      if (response.body.IsSuccess) {
        toastr.success(self.translate('save_successfully'));
        return true;
      }
      toastr.error(response.body.Message);
      return true;
    } catch (e) {
      blockui.unBlockPage();
      toastr.error(e.message);
      return false;
    }
  },
  toggleSpentMinutesEdit() {
    Vue.set(this.workOrder, 'isSpentTimeEdit', !this.workOrder.isSpentTimeEdit);
    Vue.set(this.workOrder, 'tempSpentTimeMinutes', this.workOrder.SpentTimeMinutes);
  },
  viewDateTimeFormat(date) {
    return dateFormat.viewDateTimeFormat(date);
  },
  viewDateFormat(date) {
    return dateFormat.viewDateFormat(date);
  },
  viewRelativeDateTimeFormat(date) {
    return dateFormat.viewRelativeDateTimeFormat(date, this.now);
  },
  viewTimeFormat(date) {
    return dateFormat.viewTimeFormat(date);
  },
  updateStatus() {
    const self = this;
    blockui.blockElementPromise(self, self.$el);
    self.showModal(
      'params',
      {
        workOrder: self.workOrder,
        from: 'MyWorkOrder',
      },
      'showWorkOrderStatusModal',
      'update_work_order_status_modal',
    );
    blockui.unBlockElement(self.$el);
  },
  async changeStatusWorkOrderControl(myWorkOrder, newStatusId) {
    const self = this;
    const response = await this.$globalApiClient.workOrderSparePart.anyNotReturnedSparePart({ workOrderId: this.workOrder.Id });
    const assignedUsers = this.workOrder.AssignedUsers.some(item => item.UserTypeId === 3);
    const vendor_runtime_empty = !this.workOrder.VendorWorkTime || !this.workOrder.VendorWorkTime.VendorStartDate || !this.workOrder.VendorWorkTime.VendorEndDate
    const previousOrderStatus = self.statuses.find(status => status.Id == myWorkOrder.OrderStatusId);
    const currentOrderStatus = self.statuses.find(status => status.Id == newStatusId);
    
    const handleStatusChange = async () => {
      if (myWorkOrder.OrderStatusId != newStatusId) {
        if (currentOrderStatus.Id != self.statusEnums.onHold) {
          const confirm = await swal.check(self, self.translate('are_you_sure'), self.translate('ask_status_update_from_to').formatString(myWorkOrder.WorkName, previousOrderStatus.Name, currentOrderStatus.Name));
          if (confirm.value) {
            self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
          }
        } else {
          self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
        }
      }
    };
    if (newStatusId === 4) {
      let workOrderFormStatuses = null;
      let isShowNotCompletedFormsWarning = false;
      await api.getFormStatusSummaryList(this.workOrder.Id).then((res) => {
        workOrderFormStatuses = res.body;
      });

      if (workOrderFormStatuses.filter(x => x.FormProcessStatusId === 1 || x.FormProcessStatusId === 2 || x.FormProcessStatusId === 5).length > 0) { // 1	Başladı, 2	Devam Ediyor, 5	Başlamadı
        isShowNotCompletedFormsWarning = true;
      } 

      if (response.data.Data === true) {
        if (assignedUsers) {
          if (vendor_runtime_empty) {
            toastr.error(this.translate('vendor_approval'));
            return;
          }
        }

        const confirm = await swal.check(self, self.translate('are_you_sure'), 
          isShowNotCompletedFormsWarning ? self.translate('spare_part_sent_for_approval_and_incompleted_forms') : self.translate('spare_part_sent_for_approval'));
        if (confirm.value) {
          self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
        }
        // const confirm = await swal.check(self, self.translate('are_you_sure'), self.translate('spare_part_sent_for_approval'));
        // if (confirm.value) {
        //   self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
        // }
      }
      else {
        if (assignedUsers) {
            if (vendor_runtime_empty) {
              toastr.error(this.translate('vendor_approval'));
              return;
            }
        }

        if (isShowNotCompletedFormsWarning) {
          const confirm = await swal.check(self, self.translate('are_you_sure'), self.translate('incompleted_forms_warning'));
          if (confirm.value) {
            self.changeStatusWorkOrder(myWorkOrder, currentOrderStatus);
          }
        }
        else {
          await handleStatusChange();
        }
      }
    }
    else {
        if (newStatusId === 6) {  
          if (response.data.Data === true) {
            if (assignedUsers) {
              if (vendor_runtime_empty) {
                toastr.error(this.translate('vendor_runtime_empty'));
                return;
              }
            }
            toastr.error(this.translate('spare_part_sent_for_confirm'));
            return;
          }
            else {

              if (assignedUsers) {
                  if (vendor_runtime_empty) {
                    toastr.error(this.translate('vendor_runtime_empty'));
                    return;
                  }
              }
              await handleStatusChange();               
          }
        }
        else {
          await handleStatusChange(); 
      }
    }
  },

  changeStatusWorkOrder(selectedWorkOrder, currentOrderStatus) {
    const self = this;
    blockui.pageBlock(self);
    this.$globalApiClient.workOrder.workOrderUpdateStatus({
      Id: selectedWorkOrder.Id,
      OrderStatusId: currentOrderStatus.Id,
    }).then((response) => {
      if (response.data.IsSuccess) {
        toastr.success(self.translate('status_update_work_order_successfully').formatString(selectedWorkOrder.WorkName, selectedWorkOrder.OrderStatusName, currentOrderStatus.Name));
        Vue.getMyWorkOrder();
      } else {
        toastr.error(response.data.Message);
      }
      blockui.unBlockPage();
    }, (error) => {
      blockui.unBlockPage();
    });
  },
  changeStatusButton(workOrderId) {
    api.getWorkOrderConvertibleStatusesByWorkOrderId(workOrderId).then((response) => {
      if (response.ok) {
        this.workOrder.IsConvertibleToApprove = response.body.IsConvertibleToApprove;
        this.workOrder.IsConvertibleToPending = response.body.IsConvertibleToPending;
        this.workOrder.IsConvertibleToCancelled = response.body.IsConvertibleToCancelled;
        this.workOrder.IsConvertibleToStarted = response.body.IsConvertibleToStarted;
      }
    });
  },
},
beforeDestroy() {
  this.$eventHub.$off('changeWOStatusButtons');
},
};
</script>

<style scoped>
.input-vendor{
width: 100%; 
}
.vendor-left{
width: 50%;
float: left;
margin-bottom: 5px;
}
.vendor-right{
width: 50%;
float: left;
margin-bottom: 5px;
}
</style>
