import Vue from 'vue';

export default {
  data() {
    return {
    };
  },
  components: {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  },
};
