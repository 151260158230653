<template>
    <div class="m-portlet m-portlet--mobile">
            <div class="card">
                <div class="card-header" id="collapseSLAHeader">
                    <div class="sla-grid-header"></div>&nbsp
                    <div class="card-title" data-toggle="collapse" data-target="#collapseSLA" aria-expanded="true"
                         aria-controls="collapseOne1" v-lang.service_level_agreement>
                    </div>
                </div>
                <div id="collapseSLA" class="collapse show" aria-labelledby="collapseSLAHeader"
                     data-parent="#contractSection" style="">

                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 sla-grid">
                            <grid v-if="isReady"
                                  ref="grid"
                                  sort="Condition"
                                  :data="rows"
                                  @grid="initGrid"
                                  :columns="columns"
                                  :height="null"
                                  :name="translate('service_level_agreement')"
                                  :autoHeight="true">
                            </grid>
                            <sla_modal v-if="isModalShown"
                                    @close="isModalShown=false"
                                    @reloadDatatable="reloadDatatable"
                                    :selectedSlaId="selectedSlaId"
                                    :isUpdate="isUpdate"
                                    :selectedSectionId="selectedSectionId"
                                    :selectedContractId="selectedContractId"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import grid from '../../../scripts/global/gridOld.vue';
import * as api from '../../../scripts/services/api';
import * as blockui from '../../../helpers/block-ui';
import * as swal from '../../../helpers/swal';
import slaModal from './Sla/SlaModal/SlaModal.vue';

export default {
  props: ['sectionId', 'contractId', 'IsEditable'],
  components: {
    grid,
    sla_modal: slaModal,
  },
  data() {
    return {
      selectedSlaId: null,
      isUpdate: false,
      isModalShown: false,
      isReady: false,
      widgetHelper: bryntum.scheduler.WidgetHelper,
      slaList: [],
      rows: [],
      selectedSectionId: null,
      selectedContractId: null,
      columns: [
        {
          text: 'Id',
          field: 'Id',
          editor: false,
          locked: this.IsEditable,
        }, {
          text: 'Condition',
          field: 'Condition',
          flex: 1,
          editor: false,
          locked: this.IsEditable,
        }, {
          text: 'Criticality Level',
          field: 'CriticalityLevel',
          flex: 1,
          editor: false,
          locked: this.IsEditable,
        }, {
          text: 'Reaction Time (Hours)',
          field: 'ReactionTime',
          width: 135,
          editor: false,
          locked: this.IsEditable,
        }, {
          text: 'Work Around Time (Hours)',
          field: 'WorkAroundTime',
          editor: false,
          width: 150,
          locked: this.IsEditable,
        }, {
          text: 'Permanent Solution Time (Hours)',
          field: 'PermanentSolutionTime',
          editor: false,
          width: 150,
          locked: this.IsEditable,
        }, {
          text: 'Contact Person',
          field: 'ContactPerson',
          editor: false,
          flex: 1,
          width: 250,
          locked: this.IsEditable,
        }, {
          text: 'Contact Person Tel',
          field: 'ContactPersonTel',
          editor: false,
          flex: 1,
          locked: this.IsEditable,
        }, {
          text: 'Contact Person Mail',
          field: 'ContactPersonMail',
          editor: false,
          flex: 1,
          locked: this.IsEditable,
        }, {
          type: 'widget',
          groupable: false,
          locked: false,
          hidden: !this.IsEditable,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-edit',
            cls: 'b-blue',
            style: 'width:100%',
            onAction: ({ source }) => {
              // route
              const self = this;
              blockui.pageBlock(self);
              self.selectedSlaId = source.cellInfo.record.Id;
              self.isUpdate = true;
              self.isModalShown = true;
              self.$nextTick(() => {
                const modalContainer = window.$('#sla_modal');
                modalContainer.modal({
                  backdrop: 'static',
                  show: true,
                });
                modalContainer.find('form').data('type', 'update', 'selectedSlaId');
                blockui.unBlockPage(self);
              });
            },
          }],
          field: 'edit',
          text: 'Edit',
          flex: 1,
        }, {
          type: 'widget',
          groupable: false,
          locked: false,
          hidden: !this.IsEditable,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-trash',
            cls: 'b-red',
            style: 'width:100%',
            onAction: ({ source }) => {
              const self = this;
              blockui.pageBlock(self);
              swal.check(self, source.cellInfo.record.Condition).then((e) => {
                if (e.value) {
                  api.deleteContractSla(source.cellInfo.record.Id).then((response) => {
                    if (response.body.IsSuccess) {
                      self.$refs.grid.gridEngine.store.remove(source.cellInfo.record.data.id);
                      toastr.success(self.translate('item_deleted_successfully').formatString(source.cellInfo.record.Condition));
                    } else {
                      toastr.error(response.body.Message);
                    }
                    blockui.unBlockPage();
                  });
                } else {
                  blockui.unBlockPage();
                }
              });
            },
          }],
          field: 'delete',
          text: 'Delete',
          flex: 1,
        },
      ],
    };
  },
  methods: {
    async reloadDatatable() {
      const self = this;
      blockui.blockElementPromise(self, '.sla-grid');
      self.selectedSectionId = self.sectionId;
      self.selectedContractId = self.contractId;
      await api.getContractSlaList(self.selectedSectionId).then((response) => {
        self.slaList = response.body.Data;
      });

      self.rows = self.slaList.map((item) => ({
        Id: item.Id,
        Condition: item.Condition,
        CriticalityLevel: item.CriticalityLevel,
        ReactionTime: item.ReactionTime,
        WorkAroundTime: item.WorkAroundTime,
        PermanentSolutionTime: item.PermanentSolutionTime,
        ContactPerson: item.ContactPerson,
        ContactPersonTel: item.ContactPersonTel,
        ContactPersonMail: item.ContactPersonMail,
        ContractId: item.ContractId,
        SectionId: item.SectionId,
      }));

      blockui.unBlockElement('.sla-grid');
      self.isReady = true;
    },
    initGrid() {
      const self = this;

      const oldAddRowButton = self.widgetHelper.getById('addSlaRow');
      if (oldAddRowButton) {
        oldAddRowButton.destroy();
      }
      const gridButtons = [{
        id: 'addSlaRow',
        type: 'button',
        cls: 'b-raised b-blue',
        tooltip: self.translate('add_sla'),
        margin: 10,
        icon: 'b-fa b-fa-plus',
        badge: null,
        tooltip: self.translate('add_row'),
        onAction: () => {
          const self = this;
          blockui.blockModal(self);
          $('#collapseSLA').collapse('show');

          self.selectedSlaId = null;
          self.isUpdate = false;
          self.isModalShown = true;
          self.$nextTick(() => {
            const modalContainer = window.$('#sla_modal');
            modalContainer.modal({
              backdrop: 'static',
              show: true,
            });
            blockui.unBlockModal(self);
          });
        },
      }];
      self.widgetHelper.append({
        type: 'container',
        widgets: gridButtons,
        cls: `pull-left${self.IsEditable ? '' : ' d-none'}`,
      }, { insertFirst: document.querySelector('.sla-grid-header') });
    },
  },
  async created() {
    const self = this;
    self.reloadDatatable();
  },
};
</script>
