import Vue from 'vue';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import * as datatable from '../../../../../scripts/global/datatables';
import ActionGroupModal from '../ActionGroupModal/ActionGroupModal.vue';

export default {
  data() {
    return {
      selectedActionGroup: {},
      isUpdate: false,
    };
  },
  components: {
    'action-group-modal': ActionGroupModal,
  },
  mounted() {
    const self = this; let
      table;
    const columns = [{
      data: 'Id',
      title: 'ID',
      sortable: true,
      width: 40,
      defaultContent: '',
    }, {
      data: 'Name',
      title: this.translate('action_group'),
      sortable: 'asc',
      width: 550,
      defaultContent: '',
    }];

    const datatableContainer = '.action_group_list';

    Vue.getDatatables = () => {
      blockui.blockElement(self, '.m-portlet');
      const isDataTable = $.fn.DataTable.isDataTable(datatableContainer);
      api.getSecurityActionGroupList().then((response) => {
        if (isDataTable) {
          const dt = $(datatableContainer).DataTable();
          dt.clear();
          dt.rows.add(response.body);
          dt.draw();
        } else {
          table = datatable.renderTable({
            exportFileName: self.translate('action'),
            container: datatableContainer,
            data: response.body,
            columns,
            paging: true,
            sortable: true,
          });
        }
        blockui.unBlockElement('.m-portlet');
      }, (error) => {
        blockui.unBlockElement('.m-portlet');
      });
    };

    Vue.getDatatables();

    window.$('#add_action_group_btn').on('click', () => {
      const modalContainer = window.$('#action_group_modal');
      modalContainer.modal({
        backdrop: 'static',
        show: true,
      });
      self.selectedActionGroup = {};
      modalContainer.find('form').data('type', '');
      self.isUpdate = false;
    });
  },
};
