import Vue from 'vue';
import * as api from '@/scripts/services/api';
import * as dateFormat from '@/helpers/dateFormat';
import * as blockui from '@/helpers/block-ui';
import * as datatable from '@/scripts/global/datatables';

import { mixinz } from '@/scripts/global/mixinz';

export default {
  mixins: [mixinz],
  props: ['toolId'],
  data() {
    return {
      logTypes: [],
      toolLogs: [],
      isActive: false,
      isVisible: true,
      startDate: moment().add(-1, 'year'),
      endDate: moment(),
      locale: {
        direction: 'ltr', // direction of text
        format: 'DD-MM-YYYY', // fomart of the dates displayed
        separator: ' - ', // separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
        monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
        firstDay: 1, // ISO first day of week - see moment documenations for details
      },
      logTypeId: null,
    };
  },

  mounted() {
    this.getLogTypes();
    this.onFilter();
  },

  computed: {
    toolLogType: {
      get() {
        const self = this;
        return self.selectGet('logTypes', 'logTypeId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('logTypeId', 'Id', newValue);
      },
    },
  },

  methods: {

    getLogTypes() {
      api.getToolLogTypes().then((response) => {
        this.logTypes = response.body.Data.map((logTypeName) => ({
          Name: logTypeName,
          Id: logTypeName,
        }));
      });
    },

    getToolHistory(startDate, endDate, toolId, logType) {
      blockui.blockElement(this, '.m-portlet');
      api.getToolHistoryList({
        startDate,
        endDate,
        toolId,
        logType,
      }).then((response) => {
        this.toolLogs = response.body.Data;
        this.loadDatatable();
      }).finally(() => {
        blockui.unBlockElement('.m-portlet');
      });
    },

    loadDatatable() {
      const self = this; let
        table;
      const columns = [{
        data: 'Id',
        title: 'ID',
        sortable: true,
        width: 40,
        defaultContent: '',
      },
      {
        data: 'CreatedDate',
        title: 'Date',
        sortable: true,
        width: 100,
        defaultContent: '',
        render: (data, type, row) => dateFormat.viewDateTimeFormat(row.CreatedDate),
      },
      {
        data: 'LogType',
        title: this.translate('tool_log_type'),
        sortable: 'asc',
        width: 100,
        defaultContent: '',
      },
      {
        data: 'CreatedUser.NameSurname',
        title: this.translate('user'),
        sortable: 'asc',
        width: 100,
        defaultContent: '',
      },
      {
        data: 'Description',
        title: this.translate('description'),
        sortable: 'asc',
        width: 550,
        defaultContent: '',
      }];

      const datatableContainer = '.tool_history';

      const isDataTable = $.fn.DataTable.isDataTable(datatableContainer);

      if (isDataTable) {
        const dt = $(datatableContainer).DataTable();
        dt.clear();
        dt.rows.add(this.toolLogs);
        dt.draw();
      } else {
        table = datatable.renderTable({
          exportFileName: self.translate('tool_history'),
          container: datatableContainer,
          data: this.toolLogs,
          columns,
          paging: true,
          sortable: true,

        });
      }
    },

    dateRangeUpdated(value) {
      this.endDate = moment(value.endDate);
      this.startDate = moment(value.startDate);
    },

    logChanged(value) {
      this.onFilter();
    },

    onFilter() {
      this.getToolHistory(dateFormat.postDateTimeFormatFromDateObject(this.startDate), dateFormat.postDateTimeFormatFromDateObject(this.endDate), this.toolId, this.logTypeId);
    },

  },
};
