import Vue from 'vue';
import * as api from '../services/api';
import Select2 from '../../components/shared/select2';
import * as blockui from '../../helpers/block-ui';

export default {
  props: ['schedule', 'isUpdate'],
  data() {
    return {
      selectedSchedule: {},
      // scheduleTypeId: 1,
      selectedScheduleTypeId: 1,
      weekDays: [],
      months: [],
      daysOfMonth: [],
      weeks: [],
      scheduleDayOfWeeks: [],
      scheduleMonths: [],
      scheduleDayOfMonths: [],
      scheduleWeeks: [],
    };
  },
  components: {
    select2: Select2,
  },
  async mounted() {
    window.$('form').validate();
    const self = this;

    const promises = [
      api.getDayOfMonths(),
      api.getDayOfWeeks(),
      api.getMonths(),
      api.getWeeks(),
    ];
    const responses = await Promise.all(promises);
    self.daysOfMonth = responses[0].body;
    self.weekDays = responses[1].body;
    self.months = responses[2].body;
    self.weeks = responses[3].body;
    /* for (let i = 1; i <= 6; i++) {
            self.weekDays.push({Text: self.translate('day_name_' + i), Value: i + 1});
        }
        self.weekDays.push({Text: self.translate('day_name_7'), Value: 1}) */

    /* for (let i = 1; i <= 12; i++) {
            self.months.push({Text: self.translate('month_name_' + i), Value: i});
        } */

    /* for (let i = 1; i <= 31; i++) {
            self.daysOfMonth.push({Text: i, Value: i});
        }
        self.daysOfMonth.push({Text: self.translate('last'), Value: 0}); */

    /* for (let i = 1; i <= 4; i++) {
            self.weeks.push({Text: self.translate('ordinal_numbers_' + i), Value: i});
        }
        self.weeks.push({Text: self.translate('last'), Value: 0}); */

    $('#schedule_modal').on('shown.bs.modal', () => {
      // Vue.set(self, 'scheduleTypeId', self.schedule.ScheduleTypeId ? self.schedule.ScheduleTypeId : 1);
      self.scheduleDayOfWeeks = [];
      if (self.selectedSchedule.ScheduleDayOfWeeks) {
        self.selectedSchedule.ScheduleDayOfWeeks.forEach((scheduleDayOfWeek) => {
          self.scheduleDayOfWeeks.push(scheduleDayOfWeek.DayOfWeekId);
        });
      }
      self.scheduleMonths = [];
      if (self.selectedSchedule.ScheduleMonths) {
        self.selectedSchedule.ScheduleMonths.forEach((scheduleMonth) => {
          self.scheduleMonths.push(scheduleMonth.MonthId);
        });
      }
      self.scheduleDayOfMonths = [];
      if (self.selectedSchedule.ScheduleDayOfMonths) {
        self.selectedSchedule.ScheduleDayOfMonths.forEach((scheduleDayOfMonth) => {
          self.scheduleDayOfMonths.push(scheduleDayOfMonth.DayOfMonthId);
        });
      }
      self.scheduleWeeks = [];
      if (self.selectedSchedule.ScheduleWeeks) {
        self.selectedSchedule.ScheduleWeeks.forEach((scheduleWeek) => {
          self.scheduleWeeks.push(scheduleWeek.WeekId);
        });
      }
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        const ScheduleDayOfWeeks = [];
        const ScheduleMonths = [];
        const ScheduleDayOfMonths = [];
        const ScheduleWeeks = [];
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          if (item.name == 'DayOfWeek') {
            ScheduleDayOfWeeks.push({ DayOfWeekId: item.value });
          } else if (item.name == 'ScheduleMonths') {
            ScheduleMonths.push({ MonthId: item.value });
          } else if (item.name == 'ScheduleDayOfMonths') {
            ScheduleDayOfMonths.push({ DayOfMonthId: item.value });
          } else if (item.name == 'ScheduleWeeks') {
            ScheduleWeeks.push({ WeekId: item.value });
          } else if (item.name == 'ScheduleDayOfWeeks') {
            ScheduleDayOfWeeks.push({ DayOfWeekId: item.value });
          } else {
            Object.assign(formData, { [item.name]: item.value });
          }
          formData.ScheduleDayOfWeeks = ScheduleDayOfWeeks;
          formData.ScheduleMonths = ScheduleMonths;
          formData.ScheduleDayOfMonths = ScheduleDayOfMonths;
          formData.ScheduleWeeks = ScheduleWeeks;
        });
        api.saveSchedule(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            $('#schedule_modal').modal('toggle');
            self.reloadGrid(formData);
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    reloadGrid(formData) {
      // Vue.getDatatables();
      this.$emit('updated', formData);
    },
    configureTouchSpin() {
      const self = this;
      const durationTouchSpin = window.$('.touchspin-duration').TouchSpin({
        min: 1,
        max: 999,
      }).on('change', (e) => {
        self.selectedSchedule.Duration = $(e.target).val();
      });

      const intervalTouchSpin = window.$('.touchspin-interval').TouchSpin({
        min: 1,
        max: 999,
      }).on('change', (e) => {
        self.selectedSchedule.Interval = $(e.target).val();
      });
    },
  },
  computed: {
    duration() {
      return this.selectedSchedule.Duration;
    },
    scheduleTypeId: {
      get() {
        return this.schedule && this.schedule.ScheduleTypeId > 0 ? this.schedule.ScheduleTypeId : 1;
      },
      set(newVal) {
        Vue.set(this.schedule, 'ScheduleTypeId', newVal);
      },
    },
  },
  watch: {
    schedule(e) {
      this.selectedSchedule = e;
    },
    scheduleTypeId(e) {
      const self = this;
      self.selectedScheduleTypeId = 0;
      self.$nextTick(() => {
        self.selectedScheduleTypeId = e;
        self.$nextTick(() => {
          self.configureTouchSpin();
          Vue.loadAsteriks();
        });
      });
    },
  },
};
