<template>
  <div>
    <multiselect v-model="valueWrapper" :placeholder="translate('please_select')" label="Name" track-by="Id" :options="options"
                 :option-height="104" :custom-label="customLabel" :show-labels="false">
      <template slot="singleLabel" slot-scope="props">
        <svg width="20" height="20">
          <rect width="20" height="20" :style="{fill:props.option.Code}" style="stroke-width:3;stroke:rgb(0,0,0)"/>
        </svg>
        <span style="padding-left: 10px" class="option__desc">
        <span class="option__title">{{ props.option.Name }}</span>
      </span>
      </template>
      <template slot="option" slot-scope="props">
        <svg width="50" height="50">
          <rect width="50" height="50" :style="{fill:props.option.Code}" style="stroke-width:3;stroke:rgb(0,0,0)"/>
        </svg>
        <div style="padding-left: 10px" class="option__desc">
          <span class="option__title">{{ props.option.Name }}</span>
        </div>
      </template>
    </multiselect>
    <input type="hidden" :value="valueWrapper?valueWrapper.Id:null" :name="name" :id="id">
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    value: {},
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      colors: (state) => state.common.colors,
    }),
    options: {
      get() {
        if (this.colors) {
          return this.colors;
        }
        return [];
      },
      set() {

      },
    },
    valueWrapper: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('update:value', newVal);
      },
    },
  },
  created() {
    this.$store.dispatch('getColors');
  },
  mounted() {

  },
  methods: {
    customLabel({ title, desc }) {
      return `${title} – ${desc}`;
    },
  },

};
</script>
