import Vue from 'vue';
import { getDto } from './dto';
import WarehouseTree from '../../components/partials/modals/Tool/Warehouse/Warehouse.vue';
import Crud from './Crud.vue';
import { getCookieByName } from './util';
import { baseUrl } from '../services/api';

export class BaseModel extends bryntum.scheduler.Model {
    static calculatedFields = [];

    static interActiveFields = [];

    static isDeletable = () => true;

    static isEditable = () => true;
}

export const createColumn = (props) => getGeneralColumn(props) || {
  type: props.type,
  field: props.field,
  text: props.text ? props.text : getTextFormField(props.field),
  groupable: props.groupable,
  locked: props.locked,
  width: props.width,
  renderer: props.renderer,
  editor: props.editor || false,
  widgets: props.widgets,
};

export const getTextFormField = (field) => {
  field = field.endsWith('Id') ? field.slice(0, -2) : field;
  return field.replace(/([A-Z]*)*([A-Z][a-z])/g, '$1 $2');
};

export const removeIdFromField = (field) => (field.endsWith('Id') ? field.slice(0, -2) : field);

export const createStore = (props) => {
  const field = removeIdFromField(props.field);
  return new bryntum.scheduler.AjaxStore({
    readUrl: baseUrl + (props.url || (`/${field}/Query`)),
    autoLoad: false,
    idField: 'Value',
    displayField: 'Text',
    responseDataProperty: '$d',
    onBeforeParse: ({ json, action }) => {
      json.forEach((x) => {
        x.Value = props.idField ? x[props.idField] : x.Value || x.Id;
        x.Text = props.displayField ? x[props.displayField] : x.Text || x.Name;
      });
    },
  });
};

export const getGeneralColumn = (props) => {
  if (props.field === 'RowNumber') {
    return {
      field: 'RowNumber',
      type: 'rownumber',
      hideable: false,
      resizable: false,
      groupable: false,
      draggable: false,
      region: 'left',
      align: props.align || 'right',
      summaries: [
        {
          label: 'U',
          sum: (result, current, index) => {
            index === 0 && (result = {});
            !result.hasOwnProperty('changes') && (result.changes = current.firstStore.changes);
            return result;
          },
          renderer: ({ sum }) => sum.changes && sum.changes.modified && sum.changes.modified.length || '-',
        },
        {
          label: 'D',
          sum: (result, current, index) => {
            index === 0 && (result = {});
            !result.hasOwnProperty('changes') && (result.changes = current.firstStore.changes);
            return result;
          },
          renderer: ({ sum }) => sum.changes && sum.changes.removed && sum.changes.removed.length || '-',
        },
        { sum: 'count', label: 'T' },
      ],
    };
  }

  if (props.field === 'Id') {
    return {
      type: 'number',
      field: 'Id',
      text: 'Id',
      hideable: false,
      resizable: false,
      groupable: false,
      draggable: false,
      editor: false,
      region: 'left',
      align: props.align || 'right',
      width: 75,
      renderer: ({ value }) => (Number.isInteger(value) ? value : null),
      tooltipRenderer: ({ column, record }) => (Number.isInteger(record[column.field]) ? record[column.field] : null),
      summaries: [
        { sum: 'min', label: 'Min:' },
        { sum: 'max', label: 'Max:' },
        {
          label: 'Empty:',
          renderer: ({ sum }) => (sum[undefined] ? `* (${sum[undefined]})` : '-'),
          sum: (result, current, index) => {
            index === 0 && (result = {});
            const val = Number.isInteger(current[props.field]) ? current[props.field] : undefined;
            !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
            return result;
          },
        },
      ],
    };
  }

  if (props.field === 'Delete') {
    return {
      type: 'widget',
      field: 'Delete',
      text: 'Delete',
      hideable: false,
      resizable: false,
      groupable: false,
      draggable: false,
      editor: false,
      region: 'right',
      width: 75,
      widgets: [{
        type: 'button',
        icon: 'b-fa b-fa-trash',
        cls: 'b-red',
        style: 'width:100%',
        onAction: ({ source }) => !source.cellInfo.column.grid.readOnly && source.cellInfo.record.remove(),
      }],
      tooltipRenderer: () => null, // to prevent .Delete field check on model,
    };
  }

  if (props.field === 'Name') {
    return {
      field: 'Name',
      text: props.text || 'Name',
      region: props.region,
      groupable: false,
      editor: {
        clearable: true,
        required: props.required === undefined,
      },
      width: 220,
      renderer: ({ value }) => value || null,
      summaries: [{
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = (current[props.field] || current[props.field] === '') && current[props.field].length;
          !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          result.false && delete result.false;
          return result;
        },
        renderer: ({ sum }) => Object.keys(sum).length && Math.min(...Object.keys(sum)) || '-',
      },
      {
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = (current[props.field] || current[props.field] === '') && current[props.field].length;
          !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          result.false && delete result.false;
          return result;
        },
        renderer: ({ sum }) => Object.keys(sum).length && Math.max(...Object.keys(sum)) || '-',
      },
      {
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = (current[props.field] || current[props.field] === '') && current[props.field].length;
          !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          return result;
        },
        renderer: ({ sum }) => (sum.false ? `* (${sum.false})` : '-'),
      }],
    };
  }

  if (props.type === 'number') {
    return {
      summaries: [{
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = current[props.field];
          !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          result[undefined] && delete result[undefined];
          return result;
        },
        renderer: ({ sum }) => Object.keys(sum).length && Math.min(...Object.keys(sum)) || '-',
      },
      {
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = current[props.field];
          !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          result[undefined] && delete result[undefined];
          return result;
        },
        renderer: ({ sum }) => Object.keys(sum).length && Math.max(...Object.keys(sum)) || '-',
      },
      {
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = current[props.field];
          !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          return result;
        },
        renderer: ({ sum }) => (sum[undefined] ? `* (${sum[undefined]})` : '-'),
      }],
      type: 'number',
      field: props.field,
      text: getTextFormField(props.field),
      region: props.region,
      align: props.align || 'right',
      groupable: props.groupable === true,
      renderer: ({ value }) => value || null,
      editor: {
        clearable: true,
        required: props.required === undefined,
      },
      step: 1,
      min: 1,
    };
  }

  let lastValue;
  if (props.type === 'combo' || props.type === 'comboMulti') {
    const comboStore = createStore(props);
    return {
      summaries: [{
        sum: (result, current, index) => {
          index === 0 && (result = {});
          if (!current) return result;
          const val = current[props.field];
          if (props.type === 'combo') val && (!result.hasOwnProperty(val) ? result[val] = 1 : ++result[val]);
          if (props.type === 'comboMulti') val && val.forEach((item) => (!result.hasOwnProperty(item) ? result[item] = 1 : ++result[item]));
          return result;
        },
        renderer: ({ sum }) => {
          let value = 9999999999;
          let i = 0;
          Object.keys(sum).forEach((key) => {
            if (value >= sum[key]) {
              i = key;
              value = sum[key];
            }
          });
          const data = comboStore.allRecords.find((x) => x.Value == i);
          const text = data && data.Text;
          return text ? `${text} (${value})` : '-';
        },
      },
      {
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = current[props.field];
          if (props.type === 'combo') val && (!result.hasOwnProperty(val) ? result[val] = 1 : ++result[val]);
          if (props.type === 'comboMulti') val && val.forEach((item) => (!result.hasOwnProperty(item) ? result[item] = 1 : ++result[item]));
          return result;
        },
        renderer: ({ sum }) => {
          let value = 0;
          let i = 0;
          Object.keys(sum).forEach((key) => {
            if (value < sum[key]) {
              i = key;
              value = sum[key];
            }
          });
          const data = comboStore.allRecords.find((x) => x.Value == i);
          const text = data && data.Text;
          return text ? `${text} (${value})` : '-';
        },
      },
      {
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = current[props.field];
          if (props.type === 'combo') !val && (!result.hasOwnProperty(undefined) ? result[undefined] = 1 : ++result[undefined]);
          if (props.type === 'comboMulti') (!val || !val.length) && (!result.hasOwnProperty(undefined) ? result[undefined] = 1 : ++result[undefined]);
          return result;
        },
        renderer: ({ sum }) => (sum[undefined] ? `* (${sum[undefined]})` : '-'),
      }],
      dataSource: props.dataSource,
      field: props.field,
      text: props.text || getTextFormField(props.field),
      region: props.region,
      groupable: props.groupable,
      parentField: props.parentField,
      width: 150,
      headerRenderer: ({ column, headerElement }) => {
        const dto = getDto(removeIdFromField(props.field));
        dto && new bryntum.scheduler.Button({
          appendTo: headerElement.firstChild,
          icon: 'b-fa b-fa-plus',
          color: 'b-gray',
          onClick: ({ source }) => {
            const popup = new bryntum.scheduler.Popup({
              title: removeIdFromField(props.field),
              closable: true,
              width: '80%',
              minWidth: '60%',
              minHeight: '92vh',
              autoClose: false,
              autoShow: false,
              trapFocus: false,
              draggable: true,
              floating: true,
              x: 300,
              y: 50,
              style: 'background-color:white;resize:both;overflow:hidden;',
              tools: {
                done: {
                  cls: 'b-fa-minus-square',
                  style: 'margin-right:10px',
                  handler: (e) => {
                    popup.toFront();
                    const contentElement = popup.element.querySelector('.m-portlet--mobile');
                    const condition = contentElement.style.display === 'none';
                    contentElement.style.display = condition ? 'block' : 'none';
                    popup.floating = condition;
                    popup.positioned = condition;
                    popup.draggable = condition;
                    popup.x = condition ? 300 : 0;
                    popup.y = condition ? 50 : 0;
                    popup.minHeight = condition ? '92vh' : 0;
                    popup.height = condition ? 0 : 'auto';
                    popup.minWidth = condition ? '60%' : 0;
                    popup.width = condition ? '80%' : 200;
                    const header = condition ? document.querySelector('.b-float-root') : document.querySelector('.m-grid__item.m-grid__item--fluid.m-wrapper');
                    header.insertBefore(popup.element, header.firstChild);
                    !condition && (popup.element.style.transform = null);
                    popup.element.style.display = condition ? 'flex' : 'inline-block';
                    popup.element.style.margin = condition ? null : '5px';
                    popup.element.style.resize = condition ? 'both' : 'none';
                  },
                  weight: -1000,
                },
              },
            });
            const a = Vue.extend(Crud);
            const b = new a({
              propsData: {
                dto: removeIdFromField(props.field),
              },
            });
            b.$parent = popup;
            popup.show();
            b.$mount(popup.contentElement);
          },
        });
        dto && new bryntum.scheduler.Button({
          appendTo: headerElement.firstChild,
          icon: 'b-fa b-fa-sync',
          color: 'b-gray',
          onClick: async ({ source }) => {
            bryntum.scheduler.DomHelper.highlight(source.element.firstChild);
            await column.editor.store.load();
          },
        });
        return column.text;
      },
      renderer: ({ column, value, cellElement }) => {
        cellElement.style.backgroundColor = '';
        if (column.editor.store.isLoading) setTimeout(() => column.grid && column.grid.renderContents(), 500);
        if (!value || column.editor.store.isLoading) return null;
        try {
          if (props.field === 'ColorId' && props.type === 'combo') cellElement.style.backgroundColor = column.editor.store.allRecords.find((x) => x.Value === value).Code; // todo handle color
          if (props.type === 'combo') return column.editor.store.allRecords.find((x) => x.Value === value).Text;
          if (props.type === 'comboMulti') return value.map((y) => (column.editor.store.allRecords.find((x) => x.Value === y) || y).Text).join(', ');
        } catch {
          cellElement.style.backgroundColor = 'orange';
          return 'Invalid Value!';
        }
      },
      groupRenderer: ({
        groupColumn, record, groupRowFor, cellElement,
      }) => {
        if (groupColumn.editor.store.isLoading) setTimeout(() => groupColumn.grid && groupColumn.grid.renderContents(), 500);
        if (groupColumn.editor.store.isLoading) return null;
        const value = groupRowFor;
        try {
          if (props.field === 'ColorId' && props.type === 'combo') cellElement.style.color = groupColumn.editor.store.allRecords.find((x) => x.Value === value).Code; // todo handle color
          if (!value) return ` (${record.groupChildren.length})`;
          if (props.type === 'combo') return `${groupColumn.editor.store.allRecords.find((x) => x.Value === value).Text} (${record.groupChildren.length})`;
          if (props.type === 'comboMulti') return `${value.map((y) => (groupColumn.editor.store.allRecords.find((x) => x.Value === y) || y).Text).join(', ')} (${record.groupChildren.length})`;
        } catch { return 'Invalid Value!'; }
      },
      tooltipRenderer: ({ column, record }) => {
        if (column.editor.store.isLoading) setTimeout(() => column.grid && column.grid.renderContents(), 500);
        const value = record[column.field];
        if (!value) return null;
        try {
          if (props.type === 'combo') return column.editor.store.allRecords.find((x) => x.Value === value).Text;
          if (props.type === 'comboMulti') return value.map((y) => (column.editor.store.allRecords.find((x) => x.Value === y) || y).Text).join(', ');
        } catch { return 'Invalid Value!'; }
      },
      sortable(a, b) {
        let first = props.type === 'comboMulti' ? (a[props.field] && a[props.field][0]) : a[props.field]; // todo sort changed combo store
        let second = props.type === 'comboMulti' ? (b[props.field] && b[props.field][0]) : b[props.field];
        let step = false;
        try {
          first = (first && comboStore.allRecords.find((x) => x.Value === first).Text) || '';
          step = true;
          second = (second && comboStore.allRecords.find((x) => x.Value === second).Text) || '';
          return first.localeCompare(second);
        } catch { return !step ? -1 : 0; }
      },
      filterable: ({ property, value, record }) => {
        let values = Array.isArray(value) ? value : Number.isInteger(value) ? [value] : value.split(',').map((x) => parseInt(x));
        const textSearch = values.length === 1 && !Number.isInteger(value) && !Array.isArray(value);
        textSearch && (values = comboStore.allRecords.filter((x) => x.Text.toLowerCase().includes(value.toLowerCase())).map((x) => x.Value));
        if (props.type === 'combo') return values.find((x) => record[property] === x);
        if (props.type === 'comboMulti') return values.filter((x) => record[property].find((y) => y === x)).length === values.length;
      },
      editor: {
        type: 'combo',
        valueField: 'Value',
        displayField: 'Text',
        required: props.required === undefined,
        clearable: true,
        editable: false,
        autoExpand: true,
        multiSelect: props.type === 'comboMulti',
        filterSelected: props.type === 'comboMulti',
        hidePickerOnSelect: props.type !== 'comboMulti',
        store: comboStore,
        listeners: {
          paint: ({ source }) => {
            const editor = source.owner;
            props.parentField && source.store.filter((x) => x[props.parentField] === editor.record[props.parentField]);
            (props.type === 'comboMulti') && (source.owner.height = source.chipView.element.clientHeight);
            lastValue = source.value;
          },
          change: ({ oldValue, value, source }) => {
            (props.type === 'comboMulti') && (source.owner.height = source.chipView.element.clientHeight);
          },
          focusout: ({ source }) => {
            const editor = source.owner;
            const grid = editor.owner;
            if (lastValue !== source.value) {
              const reFunc = (grid, record, parentField) => {
                const childColumns = grid.columns.allRecords.filter((x) => x.data.parentField === parentField);
                childColumns && childColumns.forEach((childCol) => {
                  if (editor.record[childCol.field]) editor.record[childCol.field] = null;
                  reFunc(grid, record, childCol.field);
                });
              };
              reFunc(grid, editor.record, props.field);
            }
          },
        },
      },
    };
  }

  if (props.type === 'date') {
    return {
      type: 'date',
      field: props.field,
      text: props.text || getTextFormField(props.field),
      region: props.region,
      groupable: props.groupable === true,
      width: 150,
      format: props.format || 'LL',
      align: props.align || 'right',
      tooltipRenderer: ({ column, record }) => {
        const value = record[column.field];
        if (!value) return null;
        return bryntum.scheduler.DateHelper.format(value, props.format || 'LL');
      },
      editor: {
        type: 'date',
        format: 'LL',
        autoExpand: true,
        clearable: true,
        editable: false,
        required: props.required === undefined,
        onChange: ({ value, source }) => {
          if (!source.owner || !source.owner.cellEditorContext || !source._value || !source.owner.cellEditorContext.value) return;
          source._value.setHours(source.owner.cellEditorContext.value.getHours());
          source._value.setMinutes(source.owner.cellEditorContext.value.getMinutes());
        },
      },
      summaries: [{
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = current[props.field] && bryntum.scheduler.DateHelper.format(current[props.field], 'YYYY-MM-DD');
          val && !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          result.hasOwnProperty(undefined) && delete result[undefined];
          return result;
        },
        renderer: ({ sum }) => Object.keys(sum).sort()[0] || '-',
      },
      {
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = current[props.field] && bryntum.scheduler.DateHelper.format(current[props.field], 'YYYY-MM-DD');
          val && !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          result.hasOwnProperty(undefined) && delete result[undefined];
          return result;
        },
        renderer: ({ sum }) => Object.keys(sum).sort().reverse()[0] || '-',
      },
      {
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = current[props.field] || undefined;
          !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          return result;
        },
        renderer: ({ sum }) => (sum[undefined] ? `* (${sum[undefined]})` : '-'),
      }],
    };
  }

  if (props.type === 'time') {
    return {
      type: 'date',
      field: props.field,
      text: props.text || getTextFormField(props.field),
      region: props.region,
      groupable: props.groupable === true,
      width: 150,
      format: props.format || 'LT',
      align: props.align || 'right',
      tooltipRenderer: ({ column, record }) => {
        const value = record[column.field];
        if (!value) return null;
        return bryntum.scheduler.DateHelper.format(value, props.format || 'LT');
      },
      editor: {
        type: 'time',
        format: 'LT',
        // autoExpand: true, //todo fix bug
        clearable: true,
        editable: false,
        use24hour: bryntum.scheduler.LocaleManager.locale.TimeField.timePicker24Hour,
        required: props.required === undefined,
        onChange: ({ value, source }) => {
          !value && props.required === undefined && (source._value = new Date());
          if (!value) return;
          const date = bryntum.scheduler.DateHelper.clearTime(source.owner.cellEditorContext.value);
          date && date.setHours(value.getHours());
          date && date.setMinutes(value.getMinutes());
          date && (source._value = new Date(date));
        },
      },
    };
  }

  if (props.type === 'check') {
    return {
      sum: true,
      summaryRenderer: ({ sum }) => sum,
      type: 'check',
      field: props.field,
      text: props.text || getTextFormField(props.field),
      region: props.region,
      groupable: true,
      editor: false,
      tooltipRenderer: ({ column, record }) => {
        const value = record[column.field];
        return value ? 'True' : 'False';
      },
      filterable: ({ property, value, record }) => { // todo remove when [Object object] fixed on filter input
        if (typeof value === 'boolean' && !value) {
          record.firstStore.filter(property, 'false');
          return null;
        }
        if (typeof value === 'boolean') return record[property] === value;
        if (!isNaN(value)) return record[property] === (value > 0);
        return record[property] === (value === 'true');
      },
      summaries: [{
        label: 'False',
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = current[props.field];
          !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          return result;
        },
        renderer: ({ sum }) => sum.false || '-',
      },
      {
        label: 'True',
        sum: (result, current, index) => {
          index === 0 && (result = {});
          const val = current[props.field];
          !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
          return result;
        },
        renderer: ({ sum }) => sum.true || '-',
      }],
    };
  }

  let globalPressed = false;
  if (props.type === 'html') {
    return {
      type: 'widget',
      field: props.field,
      text: props.text || getTextFormField(props.field),
      region: props.region,
      groupable: false,
      width: 100,
      required: props.required === undefined,
      tooltipRenderer: ({ column, record }) => decodeURIComponent(record[column.field] || ''),
      widgets: [{
        type: 'button',
        icon: 'b-fa b-fa-pen',
        text: ' ',
        style: 'width:100%',
        toggleable: true,
        defaultBindProperty: null, // need for apply text
        onToggle: ({ pressed, source }) => {
          if (globalPressed) {
            source.pressed = false;
            source.popup && source.popup.hide();
            return;
          }

          !source.popup && (source.popup = new bryntum.scheduler.Popup({
            forElement: source,
            constrainTo: source.cellInfo.column.grid.bodyContainer,
            clippedBy: source.cellInfo.column.grid.bodyContainer,
            anchor: true,
            closable: true,
            scrollAction: 'realign',
            align: 'l-r',
            width: 825,
            autoClose: false,
            autoShow: false,
            trapFocus: false,
            html: '<div id="summernote" class="summernote"></div>',
            listeners: {
              beforealign: (e) => {
                if (e.target && e.target.isDestroyed) {
                  e.target = document.body;
                  e.source.hide();
                }
                if (!e.target) e.target = document.body;
              },
            },
          }));

          source.popup.on({
            paint({ source }) {
              globalPressed = true;
              source.forElement.pressed = true;
            },
            beforehide({ source }) {
              source.forElement.pressed = false;
              globalPressed = false;
              if (!source.forElement.cellInfo) return;
              const { record } = source.forElement.cellInfo;
              const { column } = source.forElement.cellInfo;
              const value = encodeURIComponent($('#summernote').summernote('code'));
              (value || record[column.field]) && record.set(column.field, value);
              $('summernote').summernote('destroy');
            },
          });

          source.popup.show();

          $('#summernote').summernote({
            disableResizeEditor: true,
            maxHeight: 200,
            height: 200,
            width: 825,
            toolbar: [
              ['style', ['codeview', 'bold', 'italic', 'underline', 'clear']],
              ['font', ['strikethrough', 'superscript', 'subscript']],
              ['fontsize', ['fontname', 'fontsize']],
              ['color', ['color']],
              ['para', ['style', 'ul', 'ol', 'paragraph', 'height', 'hr']],
              ['table', ['table']],
            ],
          });
          $('#summernote').summernote('code', decodeURIComponent(source.cellInfo.value || ''));
        },
      }],
    };
  }

  if (props.type === 'file') {
    return {
      type: 'widget',
      field: props.field,
      text: props.text || getTextFormField(props.field),
      region: props.region,
      groupable: false,
      width: 150,
      required: props.required === undefined,
      tooltipRenderer: ({ column, record }) => record.File && record.File.Name,
      widgets: [{
        type: 'button',
        icon: 'b-fa b-fa-upload',
        text: 'Upload',
        toggleable: true,
        defaultBindProperty: null, // need for apply text
        onToggle: ({ pressed, source }) => {
          if (globalPressed) {
            source.pressed = false;
            source.popup && source.popup.hide();
            return;
          }

          !source.popup && (source.popup = new bryntum.scheduler.Popup({
            forElement: source,
            constrainTo: source.cellInfo.column.grid.bodyContainer,
            clippedBy: source.cellInfo.column.grid.bodyContainer,
            anchor: true,
            closable: true,
            scrollAction: 'realign',
            align: 'l-r',
            width: 250,
            autoClose: false,
            autoShow: false,
            trapFocus: false,
            html: '<input id="uploader" type="file" name="File" class="form-control m-input">',
            listeners: {
              beforealign: (e) => {
                if (e.target.isDestroyed) {
                  e.target = document.body;
                  e.source.hide();
                }
              },
            },
          }));

          source.popup.on({
            paint({ source }) {
              globalPressed = true;
              source.forElement.pressed = true;
            },
            beforehide({ source }) {
              source.forElement.pressed = false;
              globalPressed = false;
            },
          });

          source.popup.show();

          $('#uploader').change(function (e) {
            const { files } = this;
            const fileSize = ((files[0].size / 1024) / 1024).toFixed(4);
            const extension = files[0].name.split('.').pop().toLowerCase();

            let acceptedExtensions = [];
            bryntum.scheduler.AjaxHelper.get(`${baseUrl}/FileType/GetList`, {
              parseJson: true,
            }).then(async (response) => {
              const data = response.parsedJson.Data;
              acceptedExtensions = data.map((x) => x.Extension);
              if (typeof files[0].name !== 'undefined' && fileSize <= 20 && acceptedExtensions.includes(extension)) { // static file size
                const formData = new FormData();
                formData.append('File', files[0]);
                bryntum.scheduler.AjaxHelper.post(`${baseUrl}/File/SaveFile`, formData, {
                  parseJson: true,
                  headers: {
                    Accept: 'application/json',
                    Authorization: `${getCookieByName('tokenType')} ${getCookieByName('token')}`,
                    Culture: localStorage.getItem('vue-lang'),
                  },
                }).then((response) => {
                  const data = response.parsedJson;
                  const fileId = parseInt(data.Message);
                  const done = Number.isInteger(fileId);
                  const { record } = source.cellInfo;
                  if (done) {
                    record.File = record.File || {};
                    record.File.Name = record.FileName = files[0].name;
                    record.File.Id = record[props.field] = fileId;
                    source.popup && source.popup.hide();
                  }
                });
              } else {
              // todo error handling && process
              // toastr.error(this.translate("file_is_not_suitable"));
                e.target.value = '';
              }
            });
          });
        },
      },
      {
        type: 'button',
        icon: 'b-fa b-fa-download',
        text: 'Download',
        defaultBindProperty: null, // need for apply text
        onClick: ({ source }) => {
          source.cellInfo.record.FileId && downloadFile(source.cellInfo.record.FileId);
        },
      }],
    };
  }

  // todo make generic tree selector
  if (props.type === 'warehouse') {
    return {
      type: 'widget',
      field: props.field,
      text: props.text || getTextFormField(props.field),
      region: props.region,
      groupable: false,
      width: 100,
      required: props.required === undefined,
      tooltipRenderer: ({ column, record }) => record.Warehouse && record.Warehouse.Name,
      widgets: [{
        type: 'button',
        icon: 'b-fa b-fa-warehouse',
        text: ' ',
        style: 'width:100%',
        toggleable: true,
        defaultBindProperty: null, // need for apply text
        onToggle: async ({ pressed, source }) => {
          if (globalPressed) {
            source.pressed = false;
            source.popup && source.popup.hide();
            return;
          }

          let Warehouse;
          await bryntum.scheduler.AjaxHelper.get(`${baseUrl}/Warehouse/Get`, {
            parseJson: true,
            queryParams: {
              id: source.cellInfo.record.WarehouseId,
            },
          }).then((response) => {
            Warehouse = response.parsedJson;
          });

          const a = Vue.extend(WarehouseTree);
          const b = new a({
            propsData: {
              maxHeight: '300px',
              initNode: Warehouse,
            },
          });

          !source.popup && (source.popup = new bryntum.scheduler.Popup({
            forElement: source,
            constrainTo: source.cellInfo.column.grid.bodyContainer,
            clippedBy: source.cellInfo.column.grid.bodyContainer,
            anchor: true,
            closable: true,
            scrollAction: 'realign',
            align: 'l-r',
            width: 450,
            autoClose: false,
            autoShow: false,
            trapFocus: false,
            style: 'background-color:white',
            listeners: {
              beforealign: (e) => {
                if (e.target.isDestroyed) {
                  e.target = document.body;
                  e.source.hide();
                }
              },
            },
          }));

          source.popup.on({
            paint({ source }) {
              globalPressed = true;
              source.forElement.pressed = true;
            },
            beforehide({ source }) {
              source.forElement.pressed = false;
              globalPressed = false;
              b.$off('treeItemSelected');
              b.$destroy();
            },
          });

          const { record } = source.cellInfo;
          source.popup.show();
          b.$mount(source.popup.contentElement);
          b.$on('treeItemSelected', ({ Id }) => record.WarehouseId = Id);
        },
      }],
    };
  }

  // todo date & time column cleared dirty cell style dont work?

  return {
    type: props.type,
    field: props.field,
    text: props.text || getTextFormField(props.field),
    region: props.region,
    groupable: props.groupable === true,
    editor: props.editor === false ? false : {
      clearable: true,
      required: props.required === undefined,
    },
    widgets: props.widgets,
    width: 220,
    renderer: ({ value }) => value || null,
    summaries: [{
      sum: (result, current, index) => {
        index === 0 && (result = {});
        const val = (current[props.field] || current[props.field] === '') && current[props.field].length;
        !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
        result.false && delete result.false;
        return result;
      },
      renderer: ({ sum }) => Object.keys(sum).length && Math.min(...Object.keys(sum)) || '-',
    },
    {
      sum: (result, current, index) => {
        index === 0 && (result = {});
        const val = (current[props.field] || current[props.field] === '') && current[props.field].length;
        !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
        result.false && delete result.false;
        return result;
      },
      renderer: ({ sum }) => Object.keys(sum).length && Math.max(...Object.keys(sum)) || '-',
    },
    {
      sum: (result, current, index) => {
        index === 0 && (result = {});
        const val = (current[props.field] || current[props.field] === '') && current[props.field].length;
        !result.hasOwnProperty(val) ? result[val] = 1 : ++result[val];
        return result;
      },
      renderer: ({ sum }) => (sum.false ? `* (${sum.false})` : '-'),
    }],
  };
};

export const downloadFile = (id) => {
  bryntum.scheduler.AjaxHelper.get(`${baseUrl}/File/GetFile`, {
    parseJson: true,
    queryParams: {
      id,
      isContainsData: true,
    },
  }).then((response) => {
    const data = response.parsedJson.Data;
    downloadBlob(base64toBlob(data.Base64, data.ContentType), data.Name);
  });
};

export const base64toBlob = (base64Data, type) => {
  type = type || '';
  // Convert the Base64 string back to text.
  const byteString = atob(base64Data);

  // Convert that text into a byte array.
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type });
};

export const downloadBlob = (blob, fileName) => {
  const a = $("<a style='display: none;'/>");
  const url = window.URL.createObjectURL(blob);
  a.attr('href', url);
  a.attr('download', fileName);
  $('body').append(a);
  a[0].click();
  window.URL.revokeObjectURL(url);
  a.remove();
};
