import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';
import { mixinz } from '../global/mixinz';
import upload from '../../components/shared/upload';
import Answers from '../../components/partials/Answers';
import UserCard from '../../components/partials/cards/UserCard';

export default {
  mixins: [mixinz],
  props: {
    formId: {},
    workOrderId: {},
  },
  components: {
    upload,
  },
  data() {
    return {
      files: [],
      isUploadBusy: false,
      selectedForm: {},
      fileStatuses: {
        uploading: 1,
        uploaded: 2,
        canceled: 3,
        error: 4,
        deleted: 5,
      },
      formProcessFiles: [],
    };
  },
  computed: {},
  mounted() {
    const self = this;
    this.onCloseModal('#form_upload_copy_modal');
    // this.selectedForm = this.slcForm;
    // const formProcess = this.selectedForm && this.selectedForm.FormProcesses && this.selectedForm.FormProcesses[0];
    // if (formProcess) {
    blockui.blockElementPromise(self, self.$el);
    api.getFormProcessFiles(self.workOrderId, self.formId).then((res) => {
      if (res.body.IsSuccess) {
        self.formProcessFiles = res.body.Data;
        this.selectFiles();
      }
      blockui.unBlockElement(self.$el);
    });
    // }
  },
  async created() {
  },
  methods: {
    selectFiles() {
      const self = this;
      if (self.formProcessFiles && self.formProcessFiles.length > 0) {
        self.files = self.formProcessFiles.map((x) => ({
          fileId: x.Id,
          title: x.Name,
          type: x.ContentType,
          isActive: true,
          status: self.fileStatuses.uploaded,
          isLocal: false,
        }));
      }
    },
    onSubmit(e) {
      const self = this;
      blockui.blockModal(self);
      const fileIds = self.files.map((x) => x.fileId);
      api.saveFormProcessFile({
        fileIds,
        workOrderId: self.workOrderId,
        formId: self.formId,
      }).then((response) => {
        if (response.body.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
          // self.$parent.getFormTable();
          // Vue.getMyWorkOrder();
          self.updateFormProcess();
          $('#form_upload_copy_modal').modal('toggle');
          blockui.unBlockModal();
        } else {
          toastr.error(response.body.Message);
          blockui.unBlockModal();
        }
      }, (error) => {
        blockui.unBlockModal();
      });
    },
    updateFormProcess() {
      const self = this;
      this.$emit('formProcessUpdated', { workOrderId: self.workOrderId, formId: self.formId });
    },
  },
  watch: {},
};
