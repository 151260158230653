







































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import { CFMDTOInfrastructure } from '@/scripts/services/ClientApiAuto'

@Component<InfrastructureDialog>({
    validations() {
        return {
        infrastructure:{
            Name: {required}
          }
        }
    },
})
export default class InfrastructureDialog extends CFMDialog {
    infrastructure: CFMDTOInfrastructure = {Name: ''};

    async created(){
      console.log()
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.infrastructure.infrastructureGetById({id: <number>this.id});
        this.infrastructure = res.data;
      }
      if(this.isUpdate){
      
      }
      blockui.unBlockModal();
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Id: this.infrastructure.Id,
        Name: this.infrastructure.Name,
        IsActive : this.infrastructure.IsActive
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.infrastructure.infrastructureSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
