import * as api from '../../services/api';
import Select2 from '../../../components/shared/select2';

export default {
  props: ['capacityEquipment', 'isUpdate'],
  components: {
    select2: Select2,
  },
  data() {
    return {
      capacityType: {
        Value: '',
      },
      step: 2,
      allDeviceList: [],
      sources: [],
      outputs: [],
      sourceArray: [],
      outputsArray: [],
    };
  },
  mounted() {
    const self = this;
    window.$('form').validate();

    api.getAllDevices({ roomId: self.capacityEquipment.RoomId }).then((response) => {
      self.allDeviceList = response.body.Data;
    });

    self.$nextTick(() => {
      self.sources = [];
      const count = self.capacityEquipment.Input ? self.capacityEquipment.Input : '0';
      let i;
      for (i = 0; i < count; i++) {
        self.sources.push({
          name: `sources_${i}${1}`,
          index: i,
        });
      }

      self.outputs = [];
      const count2 = self.capacityEquipment.Output ? self.capacityEquipment.Output : '0';
      let j;
      for (j = 0; j < count2; j++) {
        self.outputs.push({
          name: `outputs_${j}${1}`,
          index: j,
        });
      }
      self.configureTouchSpin(self);
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {
          InputDevices: [],
          OutputDevices: [],
        };
        $.map(form.serializeArray(), (item) => {
          if (item.name == 'source') {
            if (item.value) {
              formData.InputDevices.push({ FromDeviceId: item.value });
            }
          } else if (item.name == 'output') {
            if (item.value) {
              formData.OutputDevices.push({ DeviceId: item.value });
            }
          } else {
            Object.assign(formData, { [item.name]: item.value });
          }
        });
        self.$emit('submit', formData);
      }
    },
    back() {
      this.$emit('goback', this.step);
    },
    configureTouchSpin(self) {
      const sourceCount = window.$('#source_count').TouchSpin({
        min: 0,
        max: 500,
        initval: self.capacityEquipment.InputDevices ? self.capacityEquipment.InputDevices.length : 0,
      });

      const outputCount = window.$('#output_count').TouchSpin({
        min: 0,
        max: 500,
        initval: self.capacityEquipment.OutputDevices ? self.capacityEquipment.OutputDevices.length : 0,
      });

      sourceCount.on('touchspin.on.startspin', (e) => {
        self.sources = [];
        const count = $(e.target).val();
        let i;
        for (i = 0; i < count; i++) {
          self.sources.push({
            name: `sources_${i}${1}`,
            index: i,
          });
        }
      });

      outputCount.on('touchspin.on.startspin', (e) => {
        self.outputs = [];
        const count = $(e.target).val();
        let i;
        for (i = 0; i < count; i++) {
          self.outputs.push({
            name: `outputs_${i}${1}`,
            index: i,
          });
        }
      });

      sourceCount.trigger('touchspin.on.startspin');
      outputCount.trigger('touchspin.on.startspin');
    },
    selectedInputDevice(index) {
      if (this.capacityEquipment.InputDevices && this.capacityEquipment.InputDevices.length > index) {
        return this.capacityEquipment.InputDevices[index].FromDeviceId;
      }
      return '';
    },
    selectedOutputDevice(index) {
      if (this.capacityEquipment.OutputDevices && this.capacityEquipment.OutputDevices.length > index) {
        return this.capacityEquipment.OutputDevices[index].DeviceId;
      }
      return '';
    },
  },
  watch: {
    capacityEquipment(e) {
      if (e.InfrastructureId == 1) {

      } else if (e.InfrastructureId == 2) {

      } else if (e.InfrastructureId == 3) {

      }
    },
  },
};
