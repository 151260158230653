import Vue from 'vue';
import grid from '../../global/gridOld';
import * as api from '../../services/api';
import * as blockui from '../../../helpers/block-ui';
import { mixinz } from '../../global/mixinz';
import * as dateFormat from '../../../helpers/dateFormat';

export default {
  mixins: [mixinz],
  props: [
    'capacityEquipment',
    'isUpdate',
  ],
  components: {
    grid,
  },
  data() {
    return {
      contractList: [],
      selectedContractId: null,
      isReady: false,
      rows: [],
      columns: [
        {
          field: 'Id',
          text: 'Id',
          width: 40,

        }, {
          field: 'Name',
          text: 'Contract',
          width: 200,

        }, {
          field: 'ContractNumber',
          text: 'Contract Code',
          width: 200,

        }, {
          field: 'StartDate',
          text: 'Start Date',
          width: 160,

        }, {
          field: 'EndDate',
          text: 'End Date',
          width: 160,

        }, {
          field: 'Vendor',
          text: 'Vendor',
          width: 200,

        }, {
          field: 'Status',
          text: 'Status',
          width: 100,

        }, {
          type: 'widget',
          groupable: false,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-eye',
            cls: 'b-blue',
            style: 'width:100%',
            onAction: ({ source }) => {
              blockui.pageBlock();
              this.goModal(source.cellInfo.record.Id);
              blockui.unBlockPage();
            },
          }],
          field: 'view',
          text: 'View',
          minWidth: 30,

        },
      ],
    };
  },
  async mounted() {
    const self = this;
    await self.getDatatable();
    self.isReady = true;
  },
  methods: {
    goModal(id) {
      const self = this;
      this.$emit('redirect', { name: 'Contract', params: { IsEditable: false }, query: { Id: id } });
    },
    async getDatatable() {
      const self = this;
      blockui.pageBlock(self);
      await api.getContractList({ deviceId: self.capacityEquipment.Id }).then((response) => {
        self.contractList = response.body.Data;
        self.rows = self.contractList.map((item) => ({
          Id: item.Id,
          Name: item.Name,
          ContractNumber: item.ContractNumber ? item.ContractNumber : null,
          StartDate: item.StartDate ? dateFormat.viewDateFormat(item.StartDate) : null,
          EndDate: item.EndDate ? dateFormat.viewDateFormat(item.EndDate) : null,
          Vendor: item.Vendor ? item.Vendor.Name : null,
        }));
        blockui.unBlockPage(self);
      });
    },
  },
};
