import Vue from 'vue';
import AnswerConditionRule from '../AnswerConditionRule/AnswerConditionRule.vue';
import deepClone from '../utilities.js';

export default {
  name: 'answer-condition',
  components: {
    'answer-condition-rule': AnswerConditionRule,
  },
  props: ['query', 'index', 'maxDepth', 'depth', 'styled', 'comparisonOperators', 'operandTypes', 'enumFormQuestionType', 'questionTypeId', 'showErrors'],
  methods: {
    ruleById(ruleId) {
      let rule = null;

      this.rules.forEach((value) => {
        if (value.id === ruleId) {
          rule = value;
          return false;
        }
      });
      return rule;
    },
    addRule() {
      const updated_query = deepClone(this.query);
      const child = {
        ComparisonOperatorId: 0,
        FirstValue: '',
        SecondValue: '',
      };
      updated_query.AnswerConditionRules.push(child);
      this.$emit('update:query', updated_query);
    },
    addGroup() {
      const updated_query = deepClone(this.query);
      if (this.depth < this.maxDepth) {
        updated_query.Children.push({
          OperandTypeId: 1,
          AnswerConditionRules: [],
          Children: [],
        });
        this.$emit('update:query', updated_query);
      }
    },
    remove() {
      this.$emit('child-deletion-requested', this.index);
    },
    removeChild(index) {
      const updated_query = deepClone(this.query);
      updated_query.Children.splice(index, 1);
      this.$emit('update:query', updated_query);
    },
    removeAnswerConditionRule(index) {
      const updated_query = deepClone(this.query);
      updated_query.AnswerConditionRules.splice(index, 1);
      this.$emit('update:query', updated_query);
    },

    updateAnswerConditionRule(index, newVal) {
      Vue.set(this.query.AnswerConditionRules, index, newVal);
      const updated_query = deepClone(this.query);
      this.$emit('update:query', updated_query);
    },
    updateChild(index, newVal) {
      Vue.set(this.query.Children, index, newVal);
      const updated_query = deepClone(this.query);
      this.$emit('update:query', updated_query);
    },
  },
  computed: {
    classObject() {
      const classObject = {
        'panel panel-default': this.styled,
      };
      classObject[`depth-${this.depth.toString()}`] = this.styled;
      return classObject;
    },
    hasError() {
      let error = false;
      for (let i = 0; i < this.query.AnswerConditionRules.length; i++) {
        if (this.$refs[`answerConditionRuleComponent${i}`]) {
          error = this.$refs[`answerConditionRuleComponent${i}`].hasError ? true : error;
        }
      }
      for (let i = 0; i < this.query.Children.length; i++) {
        if (this.$refs[`answerConditionComponent${i}`]) {
          error = this.$refs[`answerConditionComponent${i}`].hasError ? true : error;
        }
      }
      return error;
    },
  },
};
