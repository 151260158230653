<template>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    return {};
  },
  mounted() {
    this.$cookie.delete('token');
    this.$cookie.delete('tokenType');
    localStorage.removeItem('tokenExpireDate');
    localStorage.removeItem('menuItem');
    localStorage.removeItem('urlItem');
    localStorage.removeItem('serverSideItem');
    localStorage.removeItem('clientSideItem');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('eMail');
    localStorage.removeItem('company');
    window.location = '#/Login';
  },
};
</script>
