import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as api from '../services/api';
import Select2 from '../../components/shared/select2';
import * as dateFormat from '../../helpers/dateFormat';
import Select2Group from '../../components/shared/select2Group';
import { mixinz } from '../global/mixinz';
import { formatDataWithParams } from '../global/util';

export default {
  props: ['filter', 'show', 'shown', 'initialValues'],
  components: {
    select2: Select2,
    'select2-group': Select2Group,
  },
  mixins: [mixinz],
  data() {
    return {
      searchText: '',
      sort: 'Name',
      sortDir: 'asc',
      sortType: String,
      filterContinent: {
        Value: '',
      },
      filterCountry: {
        Value: '',
      },
      filterCity: {
        Value: '',
      },
      filterDistrict: {
        Value: '',
      },
      filterBuilding: {
        Value: '',
      },
      filterFloor: {
        Value: '',
      },
      filterRoomType: {
        Value: '',
      },
      filterRoomSubType: {
        Value: '',
      },

      filterProductFamily: {
        Value: '',
      },
      filterVendor: {
        Value: '',
      },

      filterWorkCategory: {
        Value: '',
      },
      filterWorkGroup: {
        Value: '',
      },

      filterWorkOrderStatus: {
        Value: '',
      },
      workOrderStatusList: {},

      filterBrand: {
        Value: '',
      },
      filterModel: {
        Value: '',
      },
      filterServiceProvider: {
        Value: '',
      },
      filterCapacityType: {
        Value: '',
      },

      selectedParams: {},

      /* picker: {
        // https://momentjs.com/docs/#/displaying/
        locale: localStorage.getItem('vue-lang'),
        // format: 'YYYY.MM.DD HH:mm:ss',
        useCurrent: false,
        showClear: true,
        showClose: true,
        // inline: true,
        icons: {
          time: 'far fa-clock',
          date: 'far fa-calendar',
          up: 'fas fa-arrow-up',
          down: 'fas fa-arrow-down',
          previous: 'fas fa-chevron-left',
          next: 'fas fa-chevron-right',
          today: 'fas fa-calendar-check',
          clear: 'far fa-trash-alt',
          close: 'far fa-times-circle'
        },
      }, */
      /* pickerDate: {
        format: 'DD.MM.YYYY',
      }, */
    };
  },
  created() {
    const self = this;
    self.$eventHub.$on('loadFilterData', this.loadFilterData);
  },
  mounted() {
    const self = this;
    /* self.$nextTick(() => {
      self.loadFilterData(self.filter);
    }); */
    mApp.initScrollers();
    if ((this.filter == 'orders') || (this.filter == 'plans')) {
      this.sort = 'ScheduledTime';
      this.sortType = Date;
    }
    window.$('form').validate();
  },
  computed: {
    ...mapState({
      buildingList: (state) => state.building.buildingList,
      floorList: (state) => state.floor.floorList,
      roomSubTypeList: (state) => state.room.roomSubTypeList,
      roomTypeList: (state) => state.room.roomTypeList,

      productFamilyList: (state) => state.product.productFamilyList,
      vendorList: (state) => state.vendor.vendorList,

      workCategoryList: (state) => state.work.workCategoryList,
      workGroupList: (state) => state.work.workGroupList,

      brandList: (state) => state.brand.brandList,
      modelList: (state) => state.model.modelList,
      serviceProviderList: (state) => state.vendor.vendorList,
      capacityTypeList: (state) => state.infrastructure.capacityTypeList,
      infrastructureListWithCapacityTypes: (state) => state.infrastructure.infrastructureListWithCapacityTypes,

      filterBrandList: (state) => state.brand.filterBrandList,
      filterModelList: (state) => state.model.filterModelList,
      formList: (state) => state.form.formList,
    }),
    ...mapGetters({
      procedureTypes: 'getProcedureTypes',
      formStatuses: 'getFormStatuses',
      formProcessStatus: 'getFormStatuses',
      filterCapacityEquipmentBreadCrumbList: 'getFilterCapacityEquipmentBreadCrumbList',
      formProcessStatuses: 'getFormProcessStatuses',
    }),
    getInfrastructuresWithCapacityTypes() {
      return formatDataWithParams(this.infrastructureListWithCapacityTypes, ['InfrastructureGroups', 'InfrastructureTypes', 'CapacityTypes']);
    },

    formStatus: {
      get() {
        const self = this;
        return self.selectGet('formStatuses', 'selectedParams.formStatusId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.formStatusId', 'Id', newValue);
      },
    },
    procedureType: {
      get() {
        const self = this;
        return self.selectGet('procedureTypes', 'selectedParams.procedureTypeId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.procedureTypeId', 'Id', newValue);
      },
    },
    capacityEquipmentBreadCrumb: {
      get() {
        const self = this;
        return self.selectGet('filterCapacityEquipmentBreadCrumbList', 'selectedParams.deviceId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.deviceId', 'Id', newValue);
      },
    },
    model: {
      get() {
        const self = this;
        return self.selectGet('filterModelList', 'selectedParams.modelId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.modelId', 'Value', newValue);
      },
    },
    brand: {
      get() {
        const self = this;
        return self.selectGet('filterBrandList', 'selectedParams.brandId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.brandId', 'Value', newValue);
      },
    },
    formProcessStatus: {
      get() {
        const self = this;
        return self.selectGet('formProcessStatuses', 'selectedParams.formProcessStatusId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.formProcessStatusId', 'Id', newValue);
      },
    },
    parentFormList() {
      return this.formList.filter((form) => !(form.ParentId > 0));
    },
    childFormList() {
      const parentId = this.selectedParams.formId;
      if (parentId > 0) {
        return this.formList.filter((form) => form.ParentId == parentId || form.Id == parentId);
      }
      return [];
    },
    form: {
      get() {
        const self = this;
        return self.selectGet('parentFormList', 'selectedParams.formId', 'Id', 'Name');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.formId', 'Id', newValue);
      },
    },
    version: {
      get() {
        const self = this;
        return self.selectGet('childFormList', 'selectedParams.version', 'Version', 'Version');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedParams.version', 'Version', newValue);
      },
    },
    startDate: {
      get() {
        if (this.selectedParams.startDate != null && this.selectedParams.startDate !== undefined && this.selectedParams.startDate !== '') {
          const momentObj = moment(this.selectedParams.startDate);
          if (momentObj.isValid()) {
            return momentObj.format('DD.MM.YYYY');
          }
        }
        return null;
      },
      set(newVal) {
        const momentObj = moment(newVal, 'DD.MM.YYYY');
        if (momentObj.isValid()) {
          this.selectedParams.startDate = momentObj.toDate();
          return newVal;
        }
        Vue.delete(this.selectedParams, 'startDate');
        return null;
      },
    },
    endDate: {
      get() {
        if (this.selectedParams.endDate != null && this.selectedParams.endDate !== undefined && this.selectedParams.endDate !== '') {
          const momentObj = moment(this.selectedParams.endDate);
          if (momentObj.isValid()) {
            return moment(this.selectedParams.endDate).format('DD.MM.YYYY');
          }
        }
        return null;
      },
      set(newVal) {
        const momentObj = moment(newVal, 'DD.MM.YYYY');
        if (momentObj.isValid()) {
          this.selectedParams.endDate = momentObj.toDate();
          return newVal;
        }
        Vue.delete(this.selectedParams, 'endDate');
        return null;
      },
    },
  },
  methods: {
    formatData(data) {
      const self = this;
      if (!data || !(data.length > 0)) {
        return [];
      }
      const b = [];
      data.forEach((item) => {
        const a = [];
        let containsArray = false;
        Object.keys(item).forEach((key) => {
          if (Array.isArray(item[key])) {
            containsArray = true;
            a.push(self.formatData(item[key]));
          }
        });
        if (containsArray) {
          b.push({ text: item.Name, children: a[0] });
        } else {
          b.push({ text: item.Name, id: item.Id });
        }
      });
      return b;
    },
    clear() {
      const self = this;
      self.formStatus = '';
      self.procedureType = '';
      self.capacityEquipmentBreadCrumb = '';
      self.model = '';
      self.brand = '';
      self.formProcessStatus = '';
      self.selectedParams = {};
      self.$nextTick(() => {
        self.onFilter(self.filter);
      });
    },
    valueChanged(changedName, newVal) {
      const self = this;
      if (newVal) {
        if (changedName === 'buildingId') {
          self.$store.dispatch('getFloorList', { buildingId: newVal });
        }
        if (changedName === 'roomTypeId') {
          self.$store.dispatch('getRoomSubTypeList', { roomTypeId: newVal });
        }
      }
    },
    loadFilterData(type) {
      const self = this;
      if ((this.filter == 'rooms' && type == 'rooms') || (this.filter == 'equipments' && type == 'equipments' && this.$parent.by == 'equipments')) {
        this.$store.dispatch('getBuildingList');
        this.$store.dispatch('getRoomTypeList');
        /* $("form[name='filterForm'] select[name='roomTypeId']").on('change', function (e) {
          if (e.target.value) {
            self.$store.dispatch('getRoomSubTypeList', {roomTypeId: e.target.value});
          }
        });

        $("form[name='filterForm']").on('change', "select[name='buildingId']", function (e) {
          if (e.target.value) {
            self.$store.dispatch('getFloorList', {buildingId: e.target.value});
          }
        }); */
      }
      if (this.filter == 'equipments' && type == 'equipments') {
        this.$store.dispatch('getProductFamilyList');
        this.$store.dispatch('getBrandList');
        // this.$store.dispatch('getInfrastructuresWithCapacityTypeList');
        // this.$store.dispatch('getVendorList');
      }
      if (this.filter == 'works' && type == 'works') {
        this.$store.dispatch('getWorkCategoryList');
        this.$store.dispatch('getWorkGroupList');
      }

      if (this.filter == 'orders' && type == 'orders') {
        api.getWorkOrderStatuses(null).then((res) => {
          self.workOrderStatusList = res.body;
        });
      }
      if ((this.filter == 'orders' && type == 'orders') || (this.filter == 'plans' && type == 'plans')) {
        this.$nextTick(() => {
          moment.locale(localStorage.getItem('vue-lang'));
          const ranges = {};
          ranges[self.translate('today')] = [moment(), moment()];
          ranges[self.translate('tomorrow')] = [moment().add(1, 'days'), moment().add(1, 'days')];
          ranges[self.translate('this_week')] = [moment().startOf('week'), moment().endOf('week')];
          ranges[self.translate('two_week')] = [moment().startOf('week'), moment().add(7, 'days').endOf('week')];
          ranges[self.translate('this_month')] = [moment().startOf('month'), moment().endOf('month')];
          ranges[self.translate('this_year')] = [moment().startOf('year'), moment().endOf('year')];
          const start = moment();
          const end = moment().add(7, 'days').endOf('week');

          function cb(start, end) {
            $('#m_daterangepicker .form-control').val(`${dateFormat.viewDateFormat(start.format('MM/DD/YYYY'))} - ${dateFormat.viewDateFormat(end.format('MM/DD/YYYY'))}`);
          }

          $('#m_daterangepicker').daterangepicker({
            locale: {
              applyLabel: self.translate('apply'),
              cancelLabel: self.translate('cancel'),
              customRangeLabel: self.translate('custom_range'),
            },
            autoApply: true,
            buttonClasses: 'm-btn btn',
            applyClass: 'btn-primary',
            cancelClass: 'btn-secondary',
            startDate: start,
            endDate: end,
            maxDate: moment().add(1, 'year'),
            ranges,
          }, cb);
          cb(start, end);
        });
      }
      if (this.filter == 'massWorkOrderOperationsCapacityEquipments') {
        this.$store.dispatch('getProductFamilyList');
        this.$store.dispatch('getInfrastructuresWithCapacityTypeList');
        this.$store.dispatch('getBuildingList');
        this.$store.dispatch('getBrandList');
        this.$store.dispatch('getModelList');
        this.$store.dispatch('getVendorList');
      }
      if (this.filter == 'forms') {
        this.$store.dispatch('getProcedureTypes');
        this.$store.dispatch('getFormStatuses');
        this.$store.dispatch('getFilterCapacityEquipmentBreadCrumbList');
        this.$store.dispatch('getFilterBrandList');
        this.$store.dispatch('getFilterModelList');
      }
      if (this.filter == 'formAnswers') {
        this.$store.dispatch('getProcedureTypes');
        this.$store.dispatch('getFormProcessStatuses');
        this.$store.dispatch('getFilterCapacityEquipmentBreadCrumbList');
        this.$store.dispatch('getFilterBrandList');
        this.$store.dispatch('getFilterModelList');
        this.$store.dispatch('getFormList');
      }
    },
    selectTimeRange(range) {
    },
    getModelList(brandId) {
      this.$store.dispatch('getModelList', { brandId });
    },
    getVersions() {
      this.version = null;
    },
    getModels() {
      this.model = '';
      if (this.selectedParams.brandId > 0) {
        this.$store.dispatch('getFilterModelList', { brandId: this.selectedParams.brandId });
      } else {
        this.$store.dispatch('getFilterModelList');
      }
      this.getCapacityEquipmentBreadCrumbs();
    },
    getCapacityEquipmentBreadCrumbs() {
      this.capacityEquipmentBreadCrumb = '';
      if (this.selectedParams.brandId > 0 || this.selectedParams.modelId > 0) {
        this.$store.dispatch('getFilterCapacityEquipmentBreadCrumbList', {
          brandId: this.selectedParams.brandId,
          modelId: this.selectedParams.modelId,
        });
      } else {
        this.$store.dispatch('getFilterCapacityEquipmentBreadCrumbList');
      }
    },
    sortBy(s, t) {
      if (s === this.sort) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      }
      this.sort = s;
      this.sortType = t;
    },
    orderBy(items) {
      return items.sort((a, b) => {
        let modifier = 1;
        let aa = a[this.sort];
        let bb = b[this.sort];
        if (this.sortType == String) {
          aa = a[this.sort].toLowerCase();
          bb = b[this.sort].toLowerCase();
        }
        if (this.sortDir === 'desc') modifier = -1;
        if (aa < bb) return -1 * modifier;
        if (aa > bb) return 1 * modifier;
        return 0;
      });
    },
    onFilter(filter) {
      const self = this;
      const filterForm = $(`form.filter-${this.filter}`);
      if (filterForm.valid()) {
        const formData = {};
        $.map(filterForm.serializeArray(), (item) => {
          // Object.assign(formData, {[item.name]: item.value});
          if (item.name == 'DateRange') {
            if (item.value != '') {
              Object.assign(formData, { fromDate: dateFormat.postDateFormat(item.value.split(' - ')[0].trim()) });
              Object.assign(formData, { endDate: dateFormat.postDateFormat(item.value.split(' - ')[1].trim()) });
            }
          } else {
            Object.assign(formData, { [item.name]: item.value });
          }
        });

        if (filter == 'rooms') {
          this.$parent.loadRooms(formData);
        } else if (filter == 'equipments' && this.$parent.by == 'equipments') {
          this.$parent.loadEquipments(formData);
        } else if (filter == 'equipments' && this.$parent.by != 'equipments') {
          this.$parent.loadRoomEquipments(this.$parent.slc.rooms, formData);
        } else if (filter == 'works') {
          this.$parent.loadEquipmentWorks(this.$parent.slc.equipments, formData);
        } else if (filter == 'plans') {
          this.$parent.loadEquipmentWorkPlans(this.$parent.slc.works, formData);
        } else if (filter == 'orders') {
          this.$parent.loadEquipmentWorkOrders(this.$parent.slc.works, formData);
        } else if (filter == 'massWorkOrderOperationsCapacityEquipments') {
          this.$parent.loadCapacityEquipments(formData);
        } else if (filter == 'forms') {
          this.$parent.loadForms(formData);
        } else if (filter == 'formAnswers') {
          const data = {};
          Object.keys(this.selectedParams).forEach((key) => {
            if (key.startsWith('startDate') || key.startsWith('endDate')) {
              const momentObj = moment(self.selectedParams[key], 'DD.MM.YYYY');
              if (momentObj.isValid()) {
                data[key] = dateFormat.postDateTimeFormat(momentObj.toDate());
              }
            } else {
              data[key] = self.selectedParams[key];
            }
          });
          this.$parent.loadFormAnswers(data);
        }
      }
    },
  },
  beforeDestroy() {
    this.$eventHub.$off('loadFilterData');
  },
};
