import Vue from 'vue';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import { mixinz } from '../../../../../scripts/global/mixinz';

export default {
  mixins: [mixinz],
  props: ['isUpdate', 'action'],
  data() {
    return {
      selectedAction: {},
      securityItemTypeList: [],
    };
  },
  mounted() {
    window.$('form').validate();
    const self = this;
    self.selectedAction = self.action;
    api.getSecurityItemTypes().then((res) => {
      // security 3 itemi getiriyor.
      self.securityItemTypeList = res.body;
    });
  },
  methods: {
    onSubmit(e) {
      const self = this; let
        table;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        api.saveSecurityItem(self.selectedAction).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            $('#method-authorization-modal').modal('toggle');

            self.reloadDataTable();
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    reloadDataTable() {
      Vue.getDatatables();
    },
    closeModal() {
      $('#method-authorization-modal').modal('toggle');
      this.selectedAction = {};
    },
  },
  watch: {
    action(e) {
      this.selectedAction = e;
    },
  },
  computed: {
    filteredItems: {
      get() {
        const self = this;
        return self.selectGet('securityItemTypeList', 'selectedAction.ItemTypeId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedAction.ItemTypeId', 'Value', newValue);
      },
    },
  },
};
