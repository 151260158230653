export const blockElement = (self, element, options) => {
  options = $.extend(true, {
    overlayColor: '#000000',
    type: 'loader',
    state: 'primary',
    message: self.translate('loading'),
    fadeIn: 0,
  }, options);
  mApp.block(element, options);
};

export const pageBlock = (self, options) => {
  if (self) {
    options = $.extend(true, {
      overlayColor: '#000000',
      type: 'loader',
      state: 'primary',
      message: self.translate('loading'),
      fadeIn: 0,
    }, options);
  }
  mApp.blockPage(options);
};

export const blockModal = (self) => {
  mApp.block('.modal-content', {
    overlayColor: '#000000',
    type: 'loader',
    state: 'success',
    message: self.translate('loading'),
    fadeIn: 0,
  });
};

export const blockElementPromise = (self, element) => new Promise((resolve, reject) => {
  mApp.block(element, {
    overlayColor: '#000000',
    type: 'loader',
    state: 'primary',
    message: self.translate('loading'),
    fadeIn: 0,
    onBlock: () => {
      resolve();
    },
  });
});

export const blockModalPromise = (self) => new Promise((resolve, reject) => {
  mApp.block('.modal-content', {
    overlayColor: '#000000',
    type: 'loader',
    state: 'success',
    message: self.translate('loading'),
    fadeIn: 0,
    onBlock: () => {
      resolve();
    },
  });
});

export const unBlockElement = (element) => {
  mApp.unblock(element);
};

export const unBlockModal = () => {
  setTimeout(() => {
    mApp.unblock('.modal-content');
  }, 500);
};

export const unBlockPage = () => {
  mApp.unblockPage();
};

export const blockSelect2 = (container = null, element) => {
  element = `${container} [id*=select2-${element}]`;
  mApp.block(element, {
    overlayColor: '#000000',
    type: 'loader',
  });
};

export const unBlockSelect2 = (element) => {
  element = `[id*=select2-${element}]`;
  mApp.unblock(element);
};
