import * as api from '../../services/api';
import scheduler from '../../global/scheduler';
import chart from './chart';
import * as dateFormat from '../../../helpers/dateFormat';
import * as swal from '../../../helpers/swal';

export default {
  mixins: [chart],
  data() {
    return {
      dateHelper: bryntum.scheduler.DateHelper,
      widgetHelper: bryntum.scheduler.WidgetHelper,
      eventBodyTemplate: (data) => `<section><div class="b-sch-event-header">${data.text}</div></section>`,
      eventRenderer({ eventRecord }) {
        return {
          text: eventRecord.eventStyle == 'colored' ? eventRecord.name || '' : '',
        };
      },
      schEngine: undefined,
      IsReady: false,
      shiftTypes: [],
      beginDate: undefined,
      startDate: new Date(),
      endDate: undefined,
      resources: [{ id: 0 }],
      timeRanges: [],
      events: [],
      timeRangesFeature: {
        showHeaderElements: true,
        enableResizing: false,
        showCurrentTimeLine: true,
        showToolTip: true,
        currentDateFormat: 'HH:mm',
      },
      schColumns: [{
        hidden: true,
        text: 'Staff',
        field: 'staff',
        width: 220,
      }],
    };
  },
  components: {
    scheduler,
  },
  async created() {
    const self = this;
    const promises = [
      await self.getShiftTypes(),
      await self.getResources(),
      self.getShifts(),
    ];
    Promise.all(promises);
    self.IsReady = true;
  },
  mounted() {

  },
  computed: {},
  methods: {
    initScheduler() {
      const self = this;
      self.schEngine = self.$refs.scheduler.schedulerEngine;
      self.schEngine.timeAxisColumn.enableHeaderContextMenu = false;

      self.schEngine.on('eventClick', (event) => {
        if (event.eventRecord.data.woId) {
          self.$router.push({
            name: 'MyWorkOrder',
            query: { from: 'Home', workOrderId: event.eventRecord.data.woId },
          });
        }
      });
    },

    getShiftTypes() {
      const self = this;
      api.getShiftTypeList().then((res) => {
        self.shiftTypes = res.body;
      });
    },
    getShifts() {
      const self = this;
      if (self.beginDate && self.endDate) {
        api.GetByDateRange({
          startDate: dateFormat.postDateTimeFormat(self.beginDate),
          endDate: dateFormat.postDateTimeFormat(self.endDate),
          isCurrentUser: true,
        }).then((res) => {
          res.body.Data.forEach((item) => {
            self.events.push({
              id: `Shift:${item.Id}`,
              shiftId: item.Id,
              name: item.ShiftType,
              resourceId: 1,
              eventColor: item.ColorName.toLowerCase(),
              startDate: item.StartDate,
              endDate: item.EndDate,
              eventStyle: 'line',
            });
          });
        });
      }
    },

    async getResources() {
      const self = this;
      self.resources.unshift({
        id: 1,
        staff: localStorage.getItem('userName'),
      });

      await api.getMyWorkOrders().then((response) => {
        if (!response.ok || !response.body.IsSuccess) {
          return;
        }
        self.beginDate = new Date(response.body.Data.OverdueDate);
        self.endDate = new Date(response.body.Data.UpcomingDate);
        self.getTimeRanges();
        response.body.Data.OverdueWorkOrders.forEach((item) => {
          self.events.push({
            id: `Wo:${item.WorkOrderId}`,
            woId: item.WorkOrderId,
            name: item.WorkName,
            resourceId: 1,
            eventColor: item.ColorName.toLowerCase(),
            eventStyle: 'colored',
            startDate: item.StartDate,
            endDate: item.EndDate,
          });
        });
        response.body.Data.TodaysWorkOrders.forEach((item) => {
          self.events.push({
            id: `Wo:${item.WorkOrderId}`,
            woId: item.WorkOrderId,
            name: item.WorkName,
            resourceId: 1,
            eventColor: item.ColorName.toLowerCase(),
            eventStyle: 'colored',
            startDate: item.StartDate,
            endDate: item.EndDate,
          });
        });
        response.body.Data.UpcomingWorkOrders.forEach((item) => {
          self.events.push({
            id: `Wo:${item.WorkOrderId}`,
            woId: item.WorkOrderId,
            name: item.WorkName,
            resourceId: 1,
            eventColor: item.ColorName.toLowerCase(),
            eventStyle: 'colored',
            startDate: item.StartDate,
            endDate: item.EndDate,
          });
        });
      }, (error) => {
      });
    },

    getTimeRanges() {
      const self = this;
      self.timeRanges = [];
      let loop = self.dateHelper.add(self.beginDate, -1, 'd');
      const end = self.dateHelper.add(self.endDate, 3, 'd');
      while (loop <= end) {
        self.shiftTypes.forEach((shift) => {
          const month = loop.getMonth();
          const day = loop.getDate();
          const year = loop.getFullYear();
          const sTimes = shift.StartTime.split(':');
          const eTimes = shift.EndTime.split(':');
          let dayDiff = 0;
          if (shift.StartTime > shift.EndTime) dayDiff++;
          self.timeRanges.push({
            name: shift.Name,
            cls: `hide-range-header shift timerange-${shift.Color.Name.toLowerCase()}`,
            startDate: new Date(year, month, day, sTimes[0], sTimes[1], sTimes[2]),
            endDate: new Date(year, month, day + dayDiff, eTimes[0], eTimes[1], eTimes[2]),
          });
        });
        loop = self.dateHelper.add(loop, 1, 'd');
      }

      const formData = {
        beginDate: dateFormat.postDateTimeFormat(self.beginDate),
        endDate: dateFormat.postDateTimeFormat(self.endDate),
      };
      api.validateWorkSchedule(formData).then((res) => {
        if (res.body.IsSuccess) {
          res.body.Data.map((item) => {
            self.timeRanges.push({
              name: item.Name,
              cls: `shift timerange-${item.IsSuitable ? 'gray' : 'orange'}`,
              startDate: new Date(item.ScheduledBeginDate),
              endDate: new Date(item.ScheduledEndDate),
            });
          });
        } else {
          toastr.error(res.body.Message);
        }
      }, (error) => {
      });
      api.validateDeviceSchedule(formData).then((res) => {
        if (res.body.IsSuccess) {
          res.body.Data.map((item) => {
            self.timeRanges.push({
              name: item.Name,
              cls: `shift timerange-${item.IsSuitable ? 'cyan' : 'violet'}`,
              startDate: new Date(item.ScheduledBeginDate),
              endDate: new Date(item.ScheduledEndDate),
            });
          });
        } else {
          toastr.error(res.body.Message);
        }
      }, (error) => {
      });
      api.validateRoomSchedule(formData).then((res) => {
        if (res.body.IsSuccess) {
          res.body.Data.map((item) => {
            self.timeRanges.push({
              name: item.Name,
              cls: `shift timerange-${item.IsSuitable ? 'lime' : 'red'}`,
              startDate: new Date(item.ScheduledBeginDate),
              endDate: new Date(item.ScheduledEndDate),
            });
          });
        } else {
          toastr.error(res.body.Message);
        }
      }, (error) => {
      });
    },
    redraw() {

    },
    prevDay() {
      const self = this;
      if (self.schEngine.startDate.getTime() > self.beginDate.getTime()) self.schEngine.shiftPrevious();
    },
    nextDay() {
      const self = this;
      if (self.schEngine.endDate.getTime() < self.endDate.getTime()) self.schEngine.shiftNext();
    },
  },
};
