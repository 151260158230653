import Vue from 'vue';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';
import * as dateFormat from '../../helpers/dateFormat';
import grid from '../global/gridOld';

export default {
  data() {
    return {
      IsReady: false,
      gridEngine: undefined,
      data: [],
      columns: [{
        type: 'rownumber',
        field: 'rownumber',
        locked: true,
        tooltipRenderer: false,
      },
      {
        field: 'staff',
        text: 'Staff',
        width: 200,
        locked: true,
        tooltipRenderer: false,
      },
      {
        field: 'role',
        text: 'Role',
        width: 200,
        locked: true,
        hidden: true,
        tooltipRenderer: false,
      },
      {
        type: 'number',
        field: 'requiredTraining',
        text: 'Required Training',
        width: 170,
        align: 'center',
        locked: true,
        tooltipRenderer: false,
        htmlEncode: false,
        renderer: ({ record, value }) => `<b ${!record.isTrained ? 'style="color:red"' : ''}>${value}</b>`,
      }],
      group: 'role',
    };
  },
  components: {
    grid,
  },
  async created() {
    const self = this;
    blockui.pageBlock(self);
    const promises = [
      self.getResources(),
      self.appendTrainings(),
    ];
    await Promise.all(promises);
    await self.appendData();
    blockui.unBlockPage();
    self.IsReady = true;
  },
  methods: {
    initGrid() {
      const self = this;
      self.gridEngine = self.$refs.grid.gridEngine;

      function getTextWidth(text, font) {
        const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
        const context = canvas.getContext('2d');
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
      }

      const groupedCols = [];
      self.gridEngine.columns.forEach((col) => {
        if (col.id.includes('col')) groupedCols.push(col);
      });
      groupedCols.forEach((groupedCol) => {
        let total = 0;
        groupedCol.children.forEach((col) => {
          col.width = getTextWidth(col.text, '500 11px Roboto, sans-serif') + 30;
          total += col.width;
        });

        const headerWidth = getTextWidth(groupedCol.text, '500 11px Roboto, sans-serif') + 15;
        if (headerWidth > total) {
          const increment = (headerWidth - total) / groupedCol.children.length;
          groupedCol.children.forEach((col) => col.width += increment);
        }
      });
    },
    async getResources() {
      const self = this;
      let untbgtltDay = self.$route.query.usersNeedToBeGetTrainingLessThanDays;
      untbgtltDay = untbgtltDay && untbgtltDay > 0 ? untbgtltDay : 5;
      await api.getTrainingDemands({ daysToCheck: untbgtltDay, isUser: true }).then((res) => {
        self.data = res.body.Data.map((item) => ({
          id: item.Id,
          staff: item.Name,
          role: item.ParentName,
          roleId: item.ParentId,
          requiredTraining: item.Total,
          isTrained: !item.IsUntrained,
        }));
      }, (error) => {});
    },
    async appendData() {
      const self = this;
      await api.getLastTrainingsForUsers().then((res) => {
        res.body.Data.forEach((item) => {
          self.data.filter((x) => x.id === item.UserId)[0][`trainingExpireDate${item.TrainingId}`] = item.ExpireDate;
          self.data.filter((x) => x.id === item.UserId)[0][`trainingIssueDate${item.TrainingId}`] = item.IssueDate;
          self.data.filter((x) => x.id === item.UserId)[0][`trainingRemainingDay${item.TrainingId}`] = item.RemainingDay ? item.RemainingDay : Number.NEGATIVE_INFINITY;
          self.data.filter((x) => x.id === item.UserId)[0][`trainingDuration${item.TrainingId}`] = item.Duration;
          self.data.filter((x) => x.id === item.UserId)[0][`trainingIsValid${item.TrainingId}`] = item.IsValid;
        });
      }, (error) => {
      });
    },
    async appendTrainings() {
      const self = this;
      await api.getTrainingList().then(async (res) => {
        let trainingList = res.body.Data;
        const tntbeltDay = self.$route.query.trainingNeedToBeEnrolledLessThanDays;
        if (tntbeltDay && tntbeltDay > 0) {
          const response = (await api.getTrainingDemands({
            daysToCheck: tntbeltDay,
            isUser: false,
          })).body.Data;
          const filterData = response.filter((item) => item.Total > 0).map((item) => ({
            id: item.Id,
            staff: item.Name,
            trainingType: item.ParentName,
            trainingTypeId: item.ParentId,
            total: item.Total,
          }));

          trainingList = trainingList.filter((training) => (!!filterData.find((x) => x.id == training.Id)));
        }

        trainingList.forEach((item) => {
          if (!self.columns.some((x) => x.text === item.TrainingType.Name)) {
            self.columns.push({
              text: item.TrainingType.Name,
              children: [],
              filterable: false,
            });
          }
          self.columns.filter((x) => x.text === item.TrainingType.Name)[0].children.push({
            type: 'number',
            field: `trainingRemainingDay${item.Id}`,
            text: item.Name,
            groupable: false,
            htmlEncode: false,
            draggable: false,
            renderer: ({ record }) => {
              let remainingDay = record.data[`trainingRemainingDay${item.Id}`];
              const isValid = record.data[`trainingIsValid${item.Id}`];
              const duration = record.data[`trainingDuration${item.Id}`];
              remainingDay = remainingDay === Number.NEGATIVE_INFINITY ? '-&infin;' : remainingDay;
              return !duration ? '<b>-</b>' : `<b ${!isValid ? 'style="color:red"' : ''}>${remainingDay}</b>`;
            },
            tooltipRenderer: ({ record }) => {
              if (!Number.isInteger(record.id)) return;
              let remainingDay = record.data[`trainingRemainingDay${item.Id}`];
              let expireDate = record.data[`trainingExpireDate${item.Id}`];
              let issueDate = record.data[`trainingIssueDate${item.Id}`];
              let duration = record.data[`trainingDuration${item.Id}`];
              const color = record.data[`trainingIsValid${item.Id}`] ? 'white' : 'red';
              expireDate = expireDate ? dateFormat.viewDateFormat(expireDate) : '';
              issueDate = issueDate ? dateFormat.viewDateFormat(issueDate) : '';
              remainingDay = remainingDay ? (remainingDay === Number.NEGATIVE_INFINITY ? '-&infin;' : remainingDay) : '';
              duration = duration ? `${duration} ${self.translate('month')}` : '';

              return `<div>${self.translate('training_duration_empty')}:\xa0<b>${duration}</b><div></div>${self.translate('remaining_days')}:\xa0<b style="color:${color}">${remainingDay}</b><div></div>${self.translate('issue_date')}:\xa0<b style="color:${color}">${issueDate}</b><div></div>${self.translate('expire_date')}:\xa0<b style="color:${color}">${expireDate}</b>`;
            },
          });
        });
      }, (error) => {
      });
    },
  },
  mounted() {

  },
};
