










































































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import { CFMDTOInfrastructureGroup, CFMDTOInfrastructureType } from '@/scripts/services/ClientApiAuto'

@Component<InfrastructureTypeDialog>({
  components: {
        'cfm-multiselect': CFMMultiselect,
    },
    validations() {
        return {
        infrastructureType:{
            Name: {required}
        },
        selectedInfrastructure: {
            InfrastructureId: {required}
        },
        selectedInfrastructureGroup: {
            InfrastructureGroupId: {required}
        }
        }
    },
})
export default class InfrastructureTypeDialog extends CFMDialog {
    infrastructureType: CFMDTOInfrastructureType = {Name: ''};
    infrastructureGroupList : {Name:string, Id:number}[] = [];
    infrastructureList : {Name:String, Id:Number}[] = [];

    selectedInfrastructure :CFMDTOInfrastructureGroup = {Name: ''}
    selectedInfrastructureGroup: CFMDTOInfrastructureType = {Name: ''};
    
    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.infrastructureType.infrastructureTypeGetById({id: <number>this.id});
        this.infrastructureType = res.data;
      }
      const promises = [
      this.getInfrastructures()
      ];
      if(this.isUpdate){
        if(this.infrastructureType && this.infrastructureType.InfrastructureGroupId){
          this.selectedInfrastructureGroup  = this.infrastructureType;
          if(this.infrastructureType.InfrastructureGroup && this.infrastructureType.InfrastructureGroup.InfrastructureId){
            this.selectedInfrastructure = this.infrastructureType.InfrastructureGroup;
            promises.push(this.getInfrastructureGroups( <number>this.selectedInfrastructure.InfrastructureId ));
          }
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    async getInfrastructures(){
      const response = await this.apiClient.infrastructure.infrastructureGetInfrastructures();
      this.infrastructureList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getInfrastructureGroups(infrastructureId: number){
      const response = await this.apiClient.infrastructureGroup.infrastructureGroupGetInfrastructureGroups({infrastructureId: infrastructureId});
      this.infrastructureGroupList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    onInfrastructureChanged(args: any){
      this.getInfrastructureGroups(args.InfrastructureId);
      this.selectedInfrastructureGroup = {Name:''};
    }
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Id: this.infrastructureType.Id,
        Name: this.infrastructureType.Name,
        InfrastructureGroupId: this.selectedInfrastructureGroup.InfrastructureGroupId,
        InfrastructureId: this.selectedInfrastructure.InfrastructureId,
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.infrastructureType.infrastructureTypeSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
