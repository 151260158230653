<template>
    <div class='gridContainer'></div>
</template>

<script>
/* eslint-disable */

    // Defines a Vue component that wraps Bryntum Scheduler
    export default {
        props: {
            name: {type: String, default: ''},
            // Configs
            width: {type: String, default: '100%'},
            height: {type: String, default: '75vh'},
            maxHeight: {type: String, default: '75vh'},
            minHeight: {type: String, default: '10vh'},
            autoHeight: {type: Boolean, default: false},
            columns: Array,
            emptyText: String,
            partner: Object,
            hideHeaders: {type: Boolean, default: false},
            id: String,
            selectionMode: {
                type: Object, default: () => {
                    return {row: true, cell: true, multiSelect: true, checkbox: false}
                }
            },
            readOnly: {type: Boolean, default: false},
            responsiveLevels: {type: Object, default: undefined},
            rowHeight: {type: Number, default: 33},
            showRemoveRowInContextMenu: {type: Boolean, default: false},
            // Store
            store: Object,

            crudManager: Object,

            // Data
            data: Array,

            config: Object,

            // Features, only used for initialization
            columnDragToolbarFeature: {type: Boolean, default: true},
            excelExporterFeature: {
                type: [Boolean, Object], default: () => {
                    return {dateFormat: 'LLL'}
                }
            },
            summaryFeature: {type: [Boolean, Object], default: false},
            filterFeature: {type: [Boolean, Object], default: true},
            quickFindFeature: {type: Boolean, default: false},
            searchFeature: {type: Boolean, default: true},
            filterBarFeature: {type: Boolean, default: false},
            cellEditFeature: {type: [Boolean, Object], default: false},
            cellTooltipFeature: {
                type: [Boolean, Function], default: () => {
                    return ({record, column}) => record[column.field]
                }
            },
            columnPickerFeature: {type: [Boolean, Object], default: () => {
                    return {groupByRegion: false, groupByTag: false}
                }
            },
            treeFeature: {type: [Boolean, Object, String], default: false},
            groupFeature: {type: [Boolean, Object, String], default: true},
            groupSummaryFeature: {type: [Boolean, Object], default: false},
            headerContextMenuFeature: {type: [Boolean, Object], default: true},
            regionResizeFeature: {type: Boolean, default: true},
            sortFeature: {type: [Boolean, Object, String, Array], default: 'name'},
            stripeFeature: {type: Boolean, default: true},
            summaryToolbarFeature: [Boolean, Object],

            enableDeleteKey: {type: Boolean, default: false},
            //enableTextSelection: {type: Boolean, default: true}
        },
        mounted: function () {
            var propKeys = Object.keys(this.$props),
                featureConfig = {};

            var config = {
                // Render grid to components element
                appendTo: this.$el,

                // Listeners, will relay events using $emit
                listeners: {
                    catchAll: function (event) {
                        // Uncomment this line to log events being emitted to console
                        this.$emit(event.type, event);
                    },

                    thisObj: this
                },

                features: featureConfig
            };

            // Apply all props to grid config
            propKeys.forEach(function (prop) {
                if (prop.indexOf('Feature') > -1) {
                    var featureName = prop.substr(0, prop.length - 'Feature'.length);
                    // Prop is a feature config
                    featureConfig[featureName] = this[prop];
                } else if (prop === 'config') {
                    // Prop is a config object
                    Object.assign(config, this[prop]);
                } else if(prop === 'emptyText' && !this[prop]) {
                    // emptyText prop
                    config[prop] = this.translate('no_rows_to_display');
                } else {
                    // Prop is a config
                    if (this[prop] !== undefined) config[prop] = this[prop];
                }
            }, this);


            // Create a Bryntum Grid with props as configs
            let language = localStorage.getItem('vue-lang');
            if (!language || language === 'en-US') language = 'En';
            bryntum.scheduler.LocaleManager.applyLocale(language, false, true);

            var engine = this.gridEngine = new bryntum.scheduler.Grid(config);

            this.$emit('grid');

            let scroll = engine.currentElement.getElementsByClassName("b-virtual-scrollers")[0];
            let header = engine.currentElement.getElementsByClassName("b-grid-header-container")[0];
            $(scroll).insertAfter($(header));

            let columnItems = [];

            engine.features.contextMenu && engine.features.contextMenu.headerItems.forEach((item) => columnItems.push(item));

            columnItems.push({
                cls: 'b-separator',
                icon: 'b-fw-icon b-fa-search',
                text: this.translate('search'),
                menu: [{
                    type: 'container',
                    width: '15em',
                    widgets: [
                        {
                            type: 'textfield',
                            clearable: true,
                            icon: 'b-fw-icon b-fa-search',
                            cls: 'menu_item_search',
                            label: this.translate('search'),
                            onInput: (e) => {
                                engine.features.search.search(e.value);
                                engine.features.contextMenu.headerItems.find(x => x.menu && x.menu[0].widgets[0].cls == e.source.cls).menu[0].widgets[0].value = e.value;
                            },
                            onChange: (e) => {
                                engine.features.search.search(e.value);
                                engine.features.contextMenu.headerItems.find(x => x.menu && x.menu[0].widgets[0].cls == e.source.cls).menu[0].widgets[0].value = e.value;
                            }
                        },
                        {
                            type: 'button',
                            icon: 'b-fw-icon b-icon-prev',
                            tooltip: this.translate('find_previous'),
                            cls: 'b-raised b-blue',
                            width: '20%',
                            closeParent: false,
                            flex: 1,
                            onAction: () => engine.features.search.gotoPrevHit()
                        },
                        {
                            type: 'button',
                            icon: 'b-fw-icon b-icon-next',
                            tooltip: this.translate('find_next'),
                            cls: 'b-raised b-blue',
                            width: '20%',
                            style: 'margin-left: 65%',
                            closeParent: false,
                            flex: 1,
                            onAction: () => engine.features.search.gotoNextHit()
                        }
                    ]
                }]
            });

            columnItems.push({
                icon: 'b-fw-icon b-fa-file-excel',
                cls: 'b-separator',
                text: this.translate('export_excel'),
                onItem: () => engine.features.excelExporter.export({
                    filename : this.name || 'grid'
                })
            });

            columnItems.push({
                cls: 'b-separator',
                icon: 'b-fw-icon b-fa-minus-square',
                text: this.translate('collapse'),
                onItem: () => {
                    engine.scrollToTop();
                    engine.collapseAll();
                }
            });

            columnItems.push({
                icon: 'b-fw-icon b-fa-plus-square',
                text: this.translate('expand'),
                onItem: () => engine.expandAll()
            });

            engine.features.contextMenu.headerItems = columnItems;

            if (this.name) {
                // assigning to grid.state applies a state to the grid
                // todo apply state to child columns
                const state = JSON.parse(localStorage.getItem('grid-' + this.name + '-state'));
                if (state) {
                    state.columns.forEach((savedItem) => {
                        engine.state.columns.forEach((item) => {
                            // todo fix id and text fields not override the state
                            if (item.id.replace(/[0-9]/g, '') == savedItem.id.replace(/[0-9]/g, '') && savedItem.id.replace(/[0-9]/g, '') != 'col') {
                                savedItem.id = item.id;
                                savedItem.text = item.text;
                            }
                        });
                    });
                    engine.state = state;
                }
            }

        },
        beforeDestroy: function () {
            if (this.name) {
                let state = this.gridEngine.state;
                //todo preserve selectedCell prevent circular structure to JSON.stringify
                delete state.selectedCell;
                localStorage.setItem('grid-' + this.name + '-state', JSON.stringify(state));
            } else { //todo destroy!
                // Make sure Bryntum Grid is destroyed when vue component is
                //todo destroy with state when coloumn removed from state --> hideColumnFilterField "TypeError: Cannot read property 'classList' of null"
                this.gridEngine.destroy();
            }
        },
        watch: {
            data: function (newValue) {
                this.gridEngine.data = newValue.slice();
            },
            maxHeight: function (newValue) {
                this.gridEngine.maxHeight = newValue;
            },
        },

        methods: {}
    }
</script>
