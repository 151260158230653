import * as api from '@/scripts/services/api';

export default {
  namespaced: true,
  modules: {
  },

  // initial state
  state: {
    tool: {},
  },

  // getters
  getters: {
    tool(state) {
      return state.tool;
    },
  },

  // actions
  actions: {
    getTool({ commit }, id) {
      return new Promise((resolve, reject) => {
        api.getTool(id).then((res) => {
          if (!res.ok && res.status !== 200) {
            return reject(res.status);
          }
          const data = res.body;
          commit('setTool', data);
          resolve(data);
        }, (err) => reject(err.message));
      });
    },
  },

  // mutations
  mutations: {
    setTool(state, data) {
      state.tool = data;
    },
  },
};
