
































import CFMDialog from '@/scripts/CFMDialog'
import {Component, Prop} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";
import { baseUrl } from '../../../scripts/services/api';
import {CFMDTOContract, CFMDTOContractFile, CFMModelContractFileView} from '@/scripts/services/ClientApiAuto'

@Component<ContractDialog>({
    validations() {
        return {
          contract:{
            Name:{required},
            ContractWorkDays:[]
          }
        }
    },
})

export default class ContractDialog extends CFMDialog {
    @Prop()
	  isShowFiles?:boolean;

    @Prop()
    contractName?: string;

    contract: CFMDTOContract = {Name: '', ContractNumber: ''};
    files: CFMModelContractFileView[]= [];

    async created(){
      blockui.blockModal(this);
      if(this.isShowFiles){
          const res = await this.apiClient.contractFile.contractFileGetList({contractId: <number>this.id});
          if (res.data) {
           this.files = res.data;
         }
        }
      blockui.unBlockModal();
    }
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
    }
    downloadFile(id:any) {
      const self = this;
       // @ts-ignore
      bryntum.scheduler.AjaxHelper.get(`${baseUrl}/File/GetFile`, {
        parseJson: true,
        queryParams: {
          id,
          isContainsData: true,
        },
      }).then((response:any) => {
        const data = response.parsedJson.Data;
        self.downloadBlob(self.base64toBlob(data.Base64, data.ContentType), data.Name);
      });
    }
    downloadBlob(blob:any, fileName:any) {
      const a = $("<a style='display: none;'/>");
      const url = window.URL.createObjectURL(blob);
      a.attr('href', url);
      a.attr('download', fileName);
      $('body').append(a);
      a[0].click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
    base64toBlob(base64Data:any, type:any) {
      type = type || '';
      // Convert the Base64 string back to text.
      const byteString = atob(base64Data);

      // Convert that text into a byte array.
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type });
    }
}
