































import Vue from 'vue';
import {DropDownListPlugin} from '@syncfusion/ej2-vue-dropdowns'
import { Component } from 'vue-property-decorator';

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { GridPlugin, DataStateChangeEventArgs  } from '@syncfusion/ej2-vue-grids';
import { CFMModelScheduleDevice } from '@/scripts/services/ClientApiAuto';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import ConstraintDialog from './ConstraintDialog.vue';
import CFMGridPage from '@/scripts/CFMGridPage';


Vue.use(DropDownListPlugin);
Vue.use(GridPlugin);

@Component<Constraint>({
    components: {
        'constraint-dialog': ConstraintDialog,
    }
})

export default class Constraint extends CFMGridPage<CFMModelScheduleDevice> {
    constraintTypes = [
        {
            Text:'Kapasite Ekipmanları', Value:1
        },
        {
            Text:'İş', Value:2
        },
        {
            Text:'Oda', Value:3
        },
    ];
    selectedVal = 0;
    expandProperties = ["Device"];
    fields = {text:'Text',value:'Value'};
    entityType = "ScheduleDevice";

    async onChange(args: any){
        let data:any;
        let g = (<any>this.$refs.grid);
        let gg = g.ej2Instances;
        switch (args.itemData.Value) {
            case 1:
                this.entityType = "ScheduleDevice";
                this.expandProperties = ["Device"];
                data = await this.getData(this.state, false);
                gg.dataSource = data.result;
                gg.columns = [
                    {field:'Device.Name',headerText:'Kapasite Ekipmanı'},
                    {field:'Name',headerText:'Kısıt'},
                    {field:'IsSuitable',headerText:'Uygunluk',type:'boolean', editType:"booleanedit",displayAsCheckBox:true},
                    {field:'BeginDate',headerText:'Başlangıç Tarihi', type:'date',format:"dd/MM/yyyy"},
                    {field:'ExpireDate',headerText:'Geçerlilik Tarihi',type:'date',format:"dd/MM/yyyy"}
                ];
                break;
            case 2:
                this.entityType = "ScheduleWork";
                this.expandProperties = ["Work"];
                data = await this.getData(this.state, false);
                gg.dataSource = data.result;
                gg.columns = [
                    {field:'Work.Name',headerText:'İş'},
                    {field:'Name',headerText:'Kısıt'},
                    {field:'IsSuitable',headerText:'Uygunluk',type:'boolean', editType:"booleanedit",displayAsCheckBox:true},
                    {field:'BeginDate',headerText:'Başlangıç Tarihi', type:'date',format:"dd/MM/yyyy"},
                    {field:'ExpireDate',headerText:'Geçerlilik Tarihi',type:'date',format:"dd/MM/yyyy"}
                ];
                break;
            case 3:
                this.entityType = "ScheduleRoom";
                this.expandProperties = ["Room"];
                data = await this.getData(this.state, false);
                gg.dataSource = data.result;
                gg.columns = [
                    {field:'Room.Name',headerText:'Oda'},
                    {field:'Name',headerText:'Kısıt'},
                    {field:'IsSuitable',headerText:'Uygunluk',type:'boolean', editType:"booleanedit",displayAsCheckBox:true},
                    {field:'BeginDate',headerText:'Başlangıç Tarihi', type:'date',format:"dd/MM/yyyy"},
                    {field:'ExpireDate',headerText:'Geçerlilik Tarihi',type:'date',format:"dd/MM/yyyy"}
                ];
                break;
            default:
                break;
        }
    }
    
    async dataStateChange(state: DataStateChangeEventArgs) {
        this.state = state;
        let dd = (<any>this.$refs.dropdownlist);
        let val = (<any>dd.ej2Instances.itemData).Value;
        switch (val) {
            case 1:
                this.entityType = "ScheduleDevice";
                this.expandProperties = ["Device"];
                break;
            case 2:
                this.entityType = "ScheduleWork";
                this.expandProperties = ["Work"];
                break;
            case 3:
                this.entityType = "ScheduleRoom";
                this.expandProperties = ["Room"];
                break;
            default:
                break;
        }
        const isFilter = !!state && !!state.action && (state.action.requestType === "filterchoicerequest" || <string>state.action.requestType === 'filtersearchbegin')
        let data = await this.getData(state, isFilter);
        if(isFilter)
        {
            (<{dataSource:Function}>state).dataSource(data);
        }
        else{
            this.gridDataSource = data;
            return this.gridDataSource;
        }
    }
    
    refreshGrid(){
        this.dataStateChange(this.state);
    }
    recordDoubleClick(args:any){
        let dd = (<any>this.$refs.dropdownlist);
        let val = (<any>dd.ej2Instances.itemData).Value;
        this.edit(args.rowData.Id,val);
    }
    async delete(data: any,val:any){
        const e = await swal.check(this, data.Name);
        if (e.value) {
            blockui.pageBlock(this);
            let response:any;
            switch (val.Value) {
                case 1:
                    response = await this.apiClient.scheduleDevice.scheduleDeviceDelete({id: data.Id});
                    break;
                case 2:
                    response = await this.apiClient.scheduleWork.scheduleWorkDelete({id: data.Id});
                    break;
                case 3:
                    response = await this.apiClient.scheduleRoom.scheduleRoomDelete({id: data.Id});
                    break;
                default:
                    break;
            }
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
                
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }

    async edit(id: number,val:any){
        this.selectedVal = val;
        this.selectedId = id;
        this.isDialogActive = true;
        await this.$nextTick();
        (<ConstraintDialog>this.$refs.constraintDialog).showDialog();
    }
    async add(){
        this.selectedId = null;
        this.isDialogActive = true;
        await this.$nextTick();
        (<ConstraintDialog>this.$refs.constraintDialog).showDialog();
    }
    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            let dd = (<any>this.$refs.dropdownlist);
            let val = (<any>dd.ej2Instances.itemData).Value;
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id,val);
            }
        }
        else if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            let dd = (<any>this.$refs.dropdownlist);
            let val = (<any>dd.ej2Instances.itemData).Value;
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0],val);
            }
        }
      this.toolbarClickBase(args);
    }
            
}   
