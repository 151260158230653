import * as api from '../../scripts/services/api';

// initial state
const state = {
  // List
  procedureList: [],
  workModalProcedureList: [],
  procedureTypeList: [],
  // Selected
  selectedProcedure: null,
  workModalSelectedProcedure: null,
  // Related Stores
  procedureRelated: {
    Work: ['getWorkModalProcedureList'],
    MassMaintenancePlanOperations: ['getWorkModalProcedureList'],
  },
};

// getters
const getters = {
  getWorkModalProcedureList: (state) => state.workModalProcedureList.map((x) => ({ ProcedureId: x.Value, Name: x.Text })),
};

// actions
const actions = {
  getRelatedProcedureList({ commit, dispatch }, pathName) {
    const relatedList = state.procedureRelated[pathName];
    relatedList.forEach((item) => {
      dispatch(item);
    });
  },
  getProcedureList({ commit }) {
    api.getProcedures().then((res) => {
      commit('setProcedureList', res.body);
    });
  },
  getWorkModalProcedureList({ commit }) {
    api.getProcedures().then((res) => {
      commit('setWorkModalProcedureList', res.body);
    });
  },
  getProcedureTypeList({ commit }) {
    api.getProcedureTypes().then((res) => {
      commit('setProcedureTypeList', res.body);
    });
  },
};

// mutations
const mutations = {
  // List
  setProcedureList(state, procedures) {
    state.procedureList = procedures;
  },
  setWorkModalProcedureList(state, procedures) {
    state.workModalProcedureList = procedures;
  },
  setProcedureTypeList(state, procedureTypes) {
    state.procedureTypeList = procedureTypes;
  },
  // Selected
  setSelectedContinent(state, continent) {
    state.selectedContinent = continent;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
