import * as api from '../../scripts/services/api';

// initial state
const state = {
  positionList: [],
  userTrainings: [],
  roleList: [],
  userTypeList: [],
  experienceList: [],
  userList: [],

  userCardList: [],
};

// getters
const getters = {
  userCardList: (state) => state.userCardList,
};

// actions
const actions = {
  async getUserPositionList({ commit }) {
    await api.getUserPositions().then((res) => {
      commit('setUserPositionList', res.body);
    });
  },
  getUserRoleList({ commit }) {
    api.getUserRoles().then((res) => {
      commit('setUserRoleList', res.body);
    });
  },
  getUserTypeList({ commit }) {
    api.getUserTypes().then((res) => {
      commit('setUserTypeList', res.body);
    });
  },
  getUserExperienceList({ commit }) {
    api.getUserExperiences().then((res) => {
      commit('setUserExperienceList', res.body);
    });
  },
  getUserList({ commit }) {
    api.getUsers().then((res) => {
      commit('setUserList', res.body);
    });
  },
  async addUserToUserCardList({ commit, state }, userId) {
    const existUser = state.userCardList.find((user) => user.Id == userId);
    if (!existUser) {
      return await api.getUserInfo(userId).then((res) => {
        commit('addUserToUserCardList', res.body);
      });
    }
  },
};

// mutations
const mutations = {
  setUserPositionList(state, user_positions) {
    state.positionList = user_positions;
  },
  setUserRoleList(state, roles) {
    state.roleList = roles;
  },
  setUserTypeList(state, user_types) {
    state.userTypeList = user_types;
  },
  setUserExperienceList(state, experiences) {
    state.experienceList = experiences;
  },
  setUserList(state, users) {
    state.userList = users;
  },
  addUserToUserCardList(state, user) {
    if (user && !state.userCardList.find((u) => u.Id === user.Id)) {
      state.userCardList.push(user);
      return true;
    }
    return false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
