





















































































































































































import CFMDialog from "@/scripts/CFMDialog";
import {Component} from "vue-property-decorator";

import * as blockui from "@/helpers/block-ui";
import * as swal from "@/helpers/swal";

import {minValue, required} from "vuelidate/lib/validators";

import {
  CFMModelBuildingView,
  CFMModelFloor,
  CFMDTOFloor,
  CFMDTOBuilding,
} from "@/scripts/services/ClientApiAuto";

// @ts-ignore
const mustBeFilledArrayIf = (param) => (value) => !param() || (value && value.length > 0);

@Component<BuildingDialog>({
  validations() {
    return {
      building: {
        Name: {required},

      },
      selectedCampus: {
        CampusId: {required},
      },
      existFloors: {
        $each: {
          Name: {
            required,
          },
        },
      },
      floorInputs: {
        mustBeFilledArray: mustBeFilledArrayIf(() => !this.existFloors || this.existFloors.length == 0),
        $each: {
          Name: {
            required,
          },
        },
      }
    };
  },
})
export default class BuildingDialog extends CFMDialog {
  building: CFMModelBuildingView = {Name: ""};
  selectedCampus: CFMModelBuildingView = {Name: ""};
  campusList: { Name: string, Id: number }[] = [];
  existFloors: CFMModelFloor[] = [];
  floorInputs: CFMModelFloor[] = [];

  async created() {
    blockui.blockModal(this);
    const promises = [
      this.getCampuses()
    ];
    await Promise.all(promises);
    if (this.isUpdate) {
      const res = await this.apiClient.building.buildingGetBuildingById({
        id: <number>this.id,
      });
      this.building = res.data;
      this.selectedCampus = res.data;
      this.existFloors = this.building.Floors ? this.building.Floors : [];
    }
    blockui.unBlockModal();
  }

  async getCampuses() {
    const response = await this.apiClient.location.locationGetAllCampuses();
    this.campusList = response.data.map(x => ({Name: <string>x.Text, Id: <number>x.Value}));
  }

  async deleteExistFloor(index: number) {
    const floor = this.existFloors[index];
    const e = await swal.check(
        this,
        floor.Name,
        this.translate("will_be_deleted_permanently")
    );
    if (e.value) {
      const res = await this.apiClient.floor.floorDelete({
        id: <number>floor.Id,
      });
      if (res.data.IsSuccess) {
        toastr.success(this.translate("delete_successfully"));
        this.existFloors.splice(index, 1);
        this.saveSucceeded();
      } else {
        toastr.error(res.data.Message ? res.data.Message : res.statusText);
      }
    }
  }

  async deleteFloor(index: number) {
    this.floorInputs.splice(index, 1);
  }

  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    const floors = [];
    if (this.existFloors && this.existFloors.length > 0) {
      floors.push(...this.existFloors);
    }
    if (this.floorInputs && this.floorInputs.length > 0) {
      floors.push(...this.floorInputs);
    }

    let formData: CFMDTOBuilding = {
      Id: this.building.Id,
      Name: <string>this.building.Name,
      Floors: floors,
      CampusId: this.selectedCampus.CampusId
    };

    blockui.blockModal(this);
    try {
      const response = await this.apiClient.building.buildingSave(formData);
      if (response.data.IsSuccess) {
        toastr.success(this.translate("save_successfully"));
        this.saveSucceeded();
        this.close();
      } else {
        const errorMessage = response.data.Message
            ? response.data.Message
            : "Error";
        toastr.error(errorMessage);
      }
    } catch (e) {
      console.log("err", e);
      toastr.error(e);
    } finally {
      blockui.unBlockModal();
    }
  }

  addFloor() {
    this.floorInputs.push(<CFMModelFloor>{Name: ""});
  }
}
