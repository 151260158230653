import HelperBar from '../../../components/partials/dashboard-charts/HelperBar';
import HelperBarTop from '../../../components/partials/dashboard-charts/HelperBarTop';
import * as blockui from '../../../helpers/block-ui';

export default {
  props: ['gridItemId', 'columns', 'height', 'h', 'y', 'w', 'x', 'i', 'chartOptions', 'moved', 'chartName', 'filterType', 'filterParameters'],
  components: {
    'helper-bar': HelperBar,
    'helper-bar-top': HelperBarTop,
  },
  data() {
    moment.locale(localStorage.getItem('vue-lang'));
    return {
      ranges: {
        // 'Yesterday': [moment().add(-1, 'day').startOf('day'), moment().add(-1, 'day').endOf('day'), 1],
        // 'Today': [moment().startOf('day'), moment().endOf('day'), 'today', 2],
        //  'Tomorrow': [moment().add(1, 'day').startOf('day'), moment().add(1, 'day').endOf('day'), 3],
        //
        // 'Last week': [moment().add(-1, 'week').startOf('week'), moment().add(-1, 'week').endOf('week'), 4],
        // 'This week': [moment().startOf('week'), moment().endOf('week'), 5],
        // 'Next week': [moment().add(1, 'week').startOf('week'), moment().add(1, 'week').endOf('week'), 6],
        //
        // 'Last month': [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month'), 7],
        // 'This month': [moment().startOf('month'), moment().endOf('month'), 8],
        // 'Next month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month'), 9],
        //
        // 'Last year': [moment().add(-1, 'year').startOf('year'), moment().add(-1, 'year').endOf('year'), 10],
        // 'This year': [moment().startOf('year'), moment().endOf('year'), 11],
        // 'Next year': [moment().add(1, 'year').startOf('year'), moment().add(1, 'year').endOf('year'), 12],
      },
      cols: 12,
      isFullScreen: false,
      options: {},
      startDate: null,
      endDate: null,
      presetId: null,
      value: null,
    };
  },
  computed: {
    outerId() {
      return `chart-outer-${this.chartName}`;
    },
    chartId() {
      return `chart-${this.chartName}`;
    },
    outerClasses() {
      return 'chart-outer';
    },
    chartClasses() {
      return 'chart-inner';
    },
    colSpan() {
      return `col-sm-12 col-md-${this.cols} width-anim `;
    },
    fullScreen() {
      return 'full-screen';
    },
    heightCalcOuter() {
      return this.isFullScreen ? '50vh' : '';
    },
    widthCalcOuter() {
      return this.isFullScreen ? '90vw' : '100%';
    },
    heightCalcInner() {
      return this.isFullScreen ? '100%' : `${((this.height + 10) * this.h) - (this.filterType ? 80 : 40) - 10}px`;
      // return this.isFullScreen ? "100%" : ((this.height * this.h) - 40) + "px";
    },
    widthCalcInner() {
      return '100%';
    },
    _filterParameters() {
      const self = this;
      let filterParametres = {};
      switch (self.filterType) {
        case 0:
          break;
        case 1:
          // Date Range
          if (self.filterParameters.presetId && self.filterParameters.presetId >= 0) {
            const range = self.getDateFromPreset(self.filterParameters.presetId);
            filterParametres = {
              presetId: self.filterParameters.presetId,
              startDate: range[0].toDate(),
              endDate: range[1].toDate(),
            };
          } else {
            filterParametres = {
              presetId: null,
              startDate: self.filterParameters.startDate,
              endDate: self.filterParameters.endDate,
            };
          }
          break;
        case 2:
          // Number
          filterParametres = {
            value: self.filterParameters.value,
          };
          break;
        case 3:
          filterParametres = {
            startDate: self.filterParameters.startDate,
            endDate: self.filterParameters.endDate,
          };
          break;
      }
      return filterParametres;
    },
  },
  watch: {
    /* chartData: {
          handler: function (val, oldVal) {
            this.chart.dataProvider = val;
            this.chart.validateData();
          },
          deep: true
        }, */
    chartData(val, oldVal) {
      /* this.chart.dataProvider = val;
            this.chart.validateData(); */
    },
    moved(val, oldVal) {
      const self = this;
      if (val) {
        self.$nextTick(() => {
          this.redraw();
          this.$emit('redrawn');
        });
      }
    },
    isFullScreen(val, oldVal) {
      const self = this;
      self.$nextTick(() => {
        this.redraw();
      });
    },
    /* filterParameters(val, oldVal) {
          this.setFilterParameters(val);
        }, */
  },
  methods: {
    setFilterParameters(params) {
      const self = this;
      switch (self.filterType) {
        case 0:
          break;
        case 1:
          // Date
          if (params.presetId && params.presetId >= 0) {
            self.presetId = params.presetId;
            const range = self.getDateFromPreset(params.presetId);
            self.startDate = range[0].toDate();
            self.endDate = range[1].toDate();
          } else {
            self.presetId = null;
            self.startDate = new Date(params.startDate);
            self.endDate = new Date(params.endDate);
          }
          break;
        case 2:
          // Number
          self.value = params.value;
          break;
        case 3:
          // Specific week
          self.startDate = new Date(params.startDate);
          self.endDate = new Date(params.endDate);
          break;
      }
    },
    getDateFromPreset(presetId) {
      return Object.values(this.ranges).find((item) => presetId == item[2]);
    },
    filterParametersUpdated(params) {
      const self = this;
      blockui.blockElement(self, `#${self.chartId}`);
      this.setFilterParameters(params);
      this.updateData();
      blockui.unBlockElement(`#${self.chartId}`);
      this.$emit('filterParametersUpdated', params);
    },
    init() {
      // this.chart = AmCharts.makeChart(this.chartId, this.options)
    },
    col(n) {
      this.cols = n;
    },
    prevChartFullScreen() {
      this.$eventHub.$emit('prev-chart-screen', { index: this.i - 1 });
      this.isFullScreen = false;
    },
    nextChartFullScreen() {
      this.$eventHub.$emit('next-chart-screen', { index: this.i + 1 });
      this.isFullScreen = false;
    },
    checkForFullScreen({ index }) {
      this.isFullScreen = this.i == index;
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
    },
    closeChart() {
      const self = this;
      this.$eventHub.$emit('close-chart', self.gridItemId);
    },
  },
  created() {
    const self = this;
    self.ranges[self.translate('today')] = [moment().startOf('day'), moment().endOf('day'), 'today', 2];
    self.ranges[self.translate('this_week')] = [moment().startOf('week'), moment().endOf('week'), 5];
    self.ranges[self.translate('this_month')] = [moment().startOf('month'), moment().endOf('month'), 8];
    self.ranges[self.translate('this_year')] = [moment().startOf('year'), moment().endOf('year'), 11];
  },
  mounted() {
    const self = this;
    const data = {};
    data.dataProvider = this.chartData;
    this.options = { ...data, ...this.defaultOptions, ...this.chartOptions };
    this.init();
    this.cols = this.columns ? this.columns : this.cols;
    this.$eventHub.$on('next-chart-screen', this.checkForFullScreen);
    this.$eventHub.$on('prev-chart-screen', this.checkForFullScreen);
    this.$eventHub.$on('close-full-screen', () => this.isFullScreen = false);
    self.setFilterParameters(self.filterParameters);
  },
  beforeDestroy() {
    this.$eventHub.$off('next-chart-screen');
    this.$eventHub.$off('prev-chart-screen');
    this.$eventHub.$off('close-full-screen');
  },
};
