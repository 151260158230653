










































import { Component, Prop, Vue } from 'vue-property-decorator';
import CFMDialog from '@/scripts/CFMDialog';
import SparePart from '../NewSparePart/SparePart.vue';
import SparePartGrid from '../NewSparePart/SparePartGrid.vue';
import * as swal from '@/helpers/swal';
import { CFMDTOWorkOrderSparePart } from '@/scripts/services/ClientApiAuto';
import * as blockui from '@/helpers/block-ui'

@Component<SparePartTabDialog>({
    components: {
        'spare-part': SparePart,
        'spare-part-grid': SparePartGrid
    }
})
export default class SparePartTabDialog extends CFMDialog {
    @Prop({ required: true }) workOrderId!: string;
    $eventHub!: Vue;
    sparePartTabData: any[] = [];
    reserveId: any = 0;
    loading: boolean = false;
    reserveDeleteTemplate() {
        const self = this;
        return {
            template: Vue.component("columnTemplate", {
                template: `<div><button @click="reserveDeleted(data.Id)" class="btn btn-danger" v-lang.delete></button></div>`,
                methods: {
                    reserveDeleted(id: number) {
                        const itemIndex = self.sparePartTabData.findIndex((el: any) => el.Id === id);
                        if (itemIndex !== -1) {
                            self.sparePartTabData[itemIndex].isReserved = false;
                        }
                        const removedItem = self.sparePartTabData[itemIndex];
                        self.sparePartTabData = self.sparePartTabData.filter((el: any) => el.Id !== id);

                        self.$eventHub.$emit('reserveDeleted', { Id: id, isReserved: false, item: removedItem });
                    }
                }
            })
        };
    }

    mounted() {
        this.$eventHub.$on('reserveApproved', (data: any) => {
            const { reserveAmount, data: sparePartData } = data;
            const reserveData = {
                ...sparePartData,
                reserveAmount
            };
            this.sparePartTabData = [...this.sparePartTabData, reserveData];
            this.reserveId += 1;
        });
    }

    async confirmClose() {
        if (this.sparePartTabData.length > 0) {
            const e = await swal.qaqccheck(this, this.translate('you_have_unsaved_changes_do_you_want_to_leave'));
            if (e.value) this.close();
        } else {
            this.close();
        }
    }

    async confirmSave() {
        if (this.loading) return;
        this.loading = true;
        if (this.sparePartTabData.length > 0) {
            const formData: CFMDTOWorkOrderSparePart[] = this.sparePartTabData.map(item => ({
                SparePartId: item.SparePartId || item.Id,
                WorkOrderId: Number(this.workOrderId),
                WarehouseId: item.WarehouseId,
                SerialNumber: item.SerialNumber,
                ReservedCount: item.reserveAmount,
                ReturnedCount: item.sparePartReturn || 0,
                Status: 1
            }));

            try {
                blockui.pageBlock(this);
                await this.saveReserve(formData);
                this.$eventHub.$emit('reloadTabCounts', { workOrderId: this.workOrderId });
                this.close();
            } catch (error) {
                console.error("Error saving data:", error);
            } finally {
                blockui.unBlockPage();
                this.loading = false;
            }
        } else {
            this.loading = false;
        }
    }

    async saveReserve(formData: CFMDTOWorkOrderSparePart[]) {
        try {
            const response = await this.apiClient.workOrderSparePart.saveSparePart(formData);
            if (response.data.IsSuccess) {
                response.data.Message && toastr.success(response.data.Message);
                this.saveSucceeded();
                this.$eventHub.$emit('sparePartAdded');
                this.close();
            } 
            else {
                const messages = response.data.Messages || [];

                if (messages.length > 0) {
                    messages.forEach((message) => {
                        toastr.error(`${message.Value}`);
                    });
                } else {
                    const errorMessage = response.data.Message || "Error";
                    toastr.error(errorMessage);
                }

                this.$eventHub.$emit('sparePartAdded');
            }

        } catch (error) {
            console.error("Error sending form data to API:", error);
        }
    }
}
