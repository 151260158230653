import * as api from '../../scripts/services/api';

// initial state
const state = {
  maintenancePeriodList: [],
  maintenancePeriods: [],
  maintenanceTypeList: [],
  maintenanceGroups: [],
};

// getters
const getters = {};

// actions
const actions = {
  async getMaintenancePeriodList({ commit }) {
    await api.getMaintenancePeriods().then((res) => {
      commit('setMaintenancePeriodList', res.body);
    });
  },
  async getMaintenancePeriods({ commit }) {
    await api.getMaintenancePeriodList().then((res) => {
      commit('setMaintenancePeriods', res.body.Data);
    });
  },
  getMaintenanceTypeList({ commit }) {
    api.getWorkOrderTypes().then((res) => {
      commit('setMaintenanceTypeList', res.body);
    });
  },
  getMaintenanceGroups({ commit }) {
    api.getMaintenanceGroups().then((res) => {
      commit('setMaintenanceGroups', res.body);
    });
  },
};

// mutations
const mutations = {
  setMaintenancePeriodList(state, maintenance_periods) {
    state.maintenancePeriodList = maintenance_periods;
  },
  setMaintenanceTypeList(state, maintenance_types) {
    state.maintenanceTypeList = maintenance_types;
  },
  setMaintenanceGroups(state, maintenance_group) {
    state.maintenanceGroups = maintenance_group;
  },
  setMaintenancePeriods(state, maintenance_periods) {
    state.maintenancePeriods = maintenance_periods;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
