import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as api from '../../services/api';
import Select2 from '../../../components/shared/select2';
import * as blockui from '../../../helpers/block-ui';
import * as swal from '../../../helpers/swal';
import * as dateFormat from '../../../helpers/dateFormat';

export default {
  props: {
    capacityEquipment: {
      default() {
        return {};
      },
      type: Object,
    },
    isUpdate: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  components: {
    select2: Select2,
  },
  computed: {
    ...mapState({
      serviceProviderList: (state) => state.capacity_equipment.serviceProviderList,
    }),
  },
  mounted() {
    const self = this;
    self.$store.dispatch('getServiceProviderList', { deviceId: self.capacityEquipment.Id });
    mApp.initScrollers();
  },
  methods: {
    viewDateFormat(date) {
      return dateFormat.viewDateFormat(date);
    },
    deleteItem(index) {
      const self = this;
      const id = self.serviceProviderList[index].Id;
      swal.check(self, null, '').then((e) => {
        if (e.value) {
          api.deleteServiceProviderItem(id).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              self.$store.dispatch('getServiceProviderList', { deviceId: self.capacityEquipment.Id });
            } else {
              toastr.error(response.body.Message);
            }
          });
        }
      });
    },
  },
  watch: {
    serviceProviderList(e) {
      if (e) {
        this.$parent.selectedCapacityEquipment.ServiceProviderCount = e.length;
      }
    },
  },
};
