import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../services/api';
import Select2 from '../../components/shared/select2';
import * as blockui from '../../helpers/block-ui';
import * as dateFormat from '../../helpers/dateFormat';
import { mixinz } from '../global/mixinz';

export default {
  props: ['userAssessment', 'isUpdate'],
  mixins: [mixinz],
  data() {
    return {
      continent: {
        Value: '',
      },
      country: {
        Value: '',
      },
      selectedUserAssessment: {
        AssessmentDate: '',
      },
      instructorList: [],
      userListByRoles: {},
      isSpecificUser: false,
      maxAssessmentScore: 0,
    };
  },
  components: {
    select2: Select2,
  },
  computed: {
    ...mapState({
      userList: (state) => state.user.userList,
      assessmentList: (state) => state.training.assessments,
    }),
    selectedDate: {
      get() {
        const self = this;
        if (self.selectedUserAssessment.AssessmentDate) {
          return moment(self.selectedUserAssessment.AssessmentDate).format('DD.MM.YYYY');
        }
        return '';
      },
      set(newVal) {
        const self = this;
        Vue.set(self.selectedUserAssessment, 'AssessmentDate', moment(newVal, 'DD.MM.YYYY').toDate());
      },
    },
    assessments() {
      return this.assessmentList.map((assessment) => ({ Text: assessment.Name, Value: assessment.Id }));
    },
  },
  async created() {
    const self = this;
    this.$store.dispatch('getUserList');
    await this.$store.dispatch('getAssessments');
    self.isSpecificUser = !!self.userAssessment.UserId;
    if (self.userAssessment.AssessmentId) {
      self.setMaxAssessmentScore(self.userAssessment.AssessmentId);
    }
    self.selectedUserAssessment = self.userAssessment;
  },
  async mounted() {
    const self = this;
    window.$('form').validate();
    self.$nextTick(() => {
      Vue.loadAsteriks();
    });

    const userList = (await api.getUserList()).body.Data;
    userList.forEach((user) => {
      if (!self.userListByRoles[user.RoleId]) {
        self.userListByRoles[user.RoleId] = [];
      }
      self.userListByRoles[user.RoleId].push({ Text: user.NameSurname, Value: user.Id });
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        formData.AssessmentDate = dateFormat.postDateTimeFormatFromDateObject(self.selectedUserAssessment.AssessmentDate);
        api.saveUserAssessment(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            // self.reloadDataTable();
            self.$store.dispatch('getUserAssessmentList', self.userAssessment.UserId);
            self.closeModal();
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    reloadDataTable() {
      Vue.getDatatables();
    },
    closeModal() {
      $('#user_assessment_modal').modal('toggle');
      this.$emit('close');
    },
    setMaxAssessmentScore(selectedAssessmentId) {
      const self = this;
      Vue.set(self, 'instructorList', []);
      self.$nextTick(() => {
        const selectedAssessment = self.assessmentList.find((assessment) => assessment.Id == selectedAssessmentId);
        if (selectedAssessment) {
          Vue.set(self, 'maxAssessmentScore', selectedAssessment.EvaluationScore);
        } else {
          Vue.set(self, 'maxAssessmentScore', 0);
        }
      });
    },
  },
};
