








import { Component, Prop ,Vue } from 'vue-property-decorator';
import SparePartTabDialog from '../NewSparePart/SparePartTabDialog.vue';
import SparePartGrid from '../NewSparePart/SparePartGrid.vue';
@Component<SparePartTab>({
    components: {
        'spare-part-tab-dialog': SparePartTabDialog,
        'spare-part-grid': SparePartGrid                    
    }
})
export default class SparePartTab extends Vue {
    @Prop({ required: true }) workOrderId!: string;
    @Prop({ required: true }) workOrderSpareParts!: number;
    isDialogActive: boolean = false;
    orderStatusId: number | null = null;
    async mounted(){
        this.orderStatusId = this.workOrderSpareParts;
    }
    async reserveApproved() {
        this.isDialogActive = true;
        await this.$nextTick();
        (this.$refs.sparePartTabDialog as SparePartTabDialog).showDialog();
    }
}
