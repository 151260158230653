






















































































import CFMDialog from '@/scripts/CFMDialog'
import { Component, Vue } from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'

import {required, minValue} from "vuelidate/lib/validators";

import { CFMDTOAssessment, CFMDTOAssessmentType } from '@/scripts/services/ClientApiAuto'

@Component<TrainingAssessmentDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect
    },
    validations() {
        return {
          trainingAssessment:{
              Name: {required},
              AssessmentTypeId: {required},
              EvaluationScore: {required, minValue: minValue(1)},
              PassingScore: {required, minValue: minValue(1)}
          },
        }
    },
})
export default class TrainingAssessmentDialog extends CFMDialog {
    trainingAssessment: CFMDTOAssessment = {Name: ''};
    assessmentTypeList: CFMDTOAssessmentType[] = [];
    
    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.assessment.assessmentGetById({id: <number>this.id});
        this.trainingAssessment = res.data;
      }
       const promises = [
        this.getAssessmentTypes()
      ];
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    get isUpdate() {
        return !!this.id;
    }

    async getAssessmentTypes(){
      const response = await this.apiClient.assessmentType.assessmentTypeGetAssessmentTypes();
      this.assessmentTypeList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      
      let formData:CFMDTOAssessment = {
        Id: this.trainingAssessment.Id,
        Name: this.trainingAssessment.Name,
        AssessmentTypeId: this.trainingAssessment.AssessmentTypeId,
        EvaluationScore: this.trainingAssessment.EvaluationScore,
        PassingScore: this.trainingAssessment.PassingScore
      };

      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.assessment.assessmentSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
