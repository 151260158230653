import { mapActions } from 'vuex';
import * as api from '../../../../scripts/services/api';
import * as dateFormat from '../../../../helpers/dateFormat';
import * as blockui from '../../../../helpers/block-ui';
import grid from '../../../../scripts/global/gridOld';
import scheduler from '../../../../scripts/global/scheduler';
import * as swal from '../../../../helpers/swal';
import ToolDetail from '../ToolDetail/ToolDetail';

export default {
  props: ['toolId'],
  data() {
    return {
      dateHelper: bryntum.scheduler.DateHelper,
      widgetHelper: bryntum.scheduler.WidgetHelper,
      currentDate: bryntum.scheduler.DateHelper.getFirstDateOfMonth(new Date()),
      schEngine: undefined,
      gridEngine: undefined,
      IsReady: false,
      IsGridReady: false,
      IsDisplayGrid: false,
      userList: [],
      toolStatusList: [],

      gridColumns: [{
        type: 'rownumber',
        field: 'rownumber',
        locked: true,
      },
      {
        locked: true,
        type: 'number',
        field: 'id',
        text: 'Id',
        width: 90,
      },
      {
        type: 'widget',
        groupable: false,
        widgets: [{
          type: 'button',
          icon: 'b-fa b-fa-edit',
          cls: 'b-blue',
          style: 'width:100%',
          onAction: ({ source }) => {
            const event = this.schEngine.eventStore.getById(source.cellInfo.record.id);
            const resource = this.schEngine.resourceStore.getById(source.cellInfo.record.resourceId);
            this.schEngine.editEvent(event, resource, source.currentElement);
          },
        }],
        field: 'edit',
        text: 'Edit',
        width: 90,
        locked: true,
      },
      {
        type: 'widget',
        groupable: false,
        widgets: [{
          type: 'button',
          icon: 'b-fa b-fa-trash',
          cls: 'b-red',
          style: 'width:100%',
          onAction: ({ source }) => {
            this.deleteReservation(source.cellInfo.record.id);
          },
        }],
        field: 'delete',
        text: 'Delete',
        width: 90,
        locked: true,
      },
      {
        locked: true,
        text: 'Tool Type',
        field: 'toolType',
        width: 150,
      },
      {
        locked: true,
        text: 'Subtype',
        field: 'toolSubType',
        width: 150,
      },
      {
        text: 'Last User',
        field: 'name',
        width: 300,
      },
      {
        type: 'date',
        text: 'Start Date',
        field: 'startDate',
        format: 'LLL',
        width: 200,
      },
      {
        type: 'date',
        text: 'End Date',
        field: 'endDate',
        format: 'LLL',
        width: 200,
      },
      {
        text: 'Reserved User',
        field: 'reservedUser',
        width: 150,
      },
      {
        text: 'Reserved Description',
        field: 'reservedDescription',
        width: 150,
      },

      {
        text: 'Lended User',
        field: 'lendedUser',
        width: 150,
      },
      {
        type: 'date',
        text: 'Lended Date',
        field: 'lendedDate',
        format: 'LLL',
        width: 200,
      },
      {
        type: 'date',
        text: 'Estimated Return Date',
        field: 'estimatedReturnDate',
        format: 'LLL',
        width: 200,
      },
      {
        text: 'Lended Description',
        field: 'lendedDescription',
        width: 150,
      },
      {
        text: 'Returned User',
        field: 'returnedUser',
        width: 150,
      },
      {
        type: 'date',
        text: 'Returned Date',
        field: 'returnedDate',
        format: 'LLL',
        width: 200,
      },
      {
        text: 'Returned Description',
        field: 'returnedDescription',
        width: 150,
      }],
      resources: [],
      events: [{
        resourceId: null, toolType: null, toolSubType: null, reservedDescription: null, reservedUser: null, reservedUserId: null, lendedDescription: null, lendedUser: null, lendedUserId: null, lendedDate: null, estimatedReturnDate: null, returnedDescription: null, returnedUserId: null, returnedUser: null, returnedDate: null, workOrders: null,
      }],
      columns: [
        {
          type: 'number',
          field: 'id',
          text: 'Id',
          width: 75,
          renderer: ({ value }) => (Number.isInteger(value) ? value : ''),
          tooltipRenderer: false,
        },
        {
          type: 'tree',
          field: 'toolTree',
          text: 'Tool',
          leafIconCls: 'b-icon b-fa-wrench',
          collapsedFolderIconCls: 'b-icon b-fa-box',
          expandedFolderIconCls: 'b-icon b-fa-box-open',
          width: 200,
          renderer({ record, value }) {
            function getChildCounts(r) {
              let total = 0;
              if (r.children) r.children.forEach((child) => total += getChildCounts(child));
              else total++;
              return total;
            }
            return record.children ? `${value} (${getChildCounts(record)})` : value;
          },
        },
        {
          type: 'widget',
          groupable: false,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-edit',
            cls: 'b-blue',
            style: 'width:100%',
            onAction: ({ source }) => {
              if (source.cellInfo.record.id.toString().startsWith('_generated')) return;
              this.$router.push({ name: 'ToolDetail', query: { toolId: source.cellInfo.record.id } });
            },
          }],
          field: 'edit',
          text: 'Edit',
          width: 75,
        },
        {
          type: 'widget',
          groupable: false,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-trash',
            cls: 'b-red',
            style: 'width:100%',
            onAction: ({ source }) => {
              if (source.cellInfo.record.id.toString().startsWith('_generated')) return;
              const self = this;
              swal.check(self, source.cellInfo.record.toolTree).then((e) => {
                if (e.value) {
                  blockui.pageBlock(self);
                  api.deleteTool(source.cellInfo.record.id).then((response) => {
                    if (response.body.IsSuccess) {
                      toastr.success(self.translate('delete_successfully'));
                      self.schEngine.eventStore.query((x) => x.resourceId === source.cellInfo.record.id).forEach((e) => e.remove());
                      self.getResources();
                    } else {
                      toastr.error(response.body.Message);
                    }
                    blockui.unBlockPage();
                  }, (error) => {
                    blockui.unBlockPage();
                  });
                }
              });
            },
          }],
          field: 'delete',
          text: 'Delete',
          width: 75,
        },
        {
          field: 'warehouse',
          text: 'Warehouse',
          width: 180,
        },
        {
          hidden: true,
          field: 'isNeedCalibration',
          text: 'Calibration',
          width: 100,
          htmlEncode: false,
          tooltipRenderer: false,
          renderer: ({ value }) => (value == null ? '' : `<div class="b-widget b-checkbox b-green"><input disabled type="checkbox" ${value ? 'checked' : ''}><label/></div>`),
        },
        {
          hidden: true,
          type: 'date',
          field: 'nextCalibrationDate',
          text: 'Next Calibration',
          format: 'LLL',
          width: 120,
        },
        {
          hidden: true,
          field: 'isNeedPeriodicMaintenance',
          text: 'Maintenance',
          width: 100,
          htmlEncode: false,
          tooltipRenderer: false,
          renderer: ({ value }) => (value == null ? '' : `<div class="b-widget b-checkbox blue"><input disabled type="checkbox" ${value ? 'checked' : ''}><label/></div>`),
        },
        {
          hidden: true,
          type: 'date',
          field: 'nextMaintenanceDate',
          text: 'Next Maintenance',
          format: 'LLL',
          width: 120,
        },
        {
          hidden: true,
          field: 'brand',
          text: 'Brand',
          width: 120,
        },
        {
          hidden: true,
          field: 'model',
          text: 'Model',
          width: 120,
        },
        {
          hidden: true,
          field: 'toolStatus',
          text: 'Tool Status',
          width: 120,
        },
        {
          field: 'serialNumber',
          text: 'Serial Number',
          width: 120,
        }],
    };
  },
  components: {
    scheduler,
    grid,
  },
  async created() {
    const self = this;
    if (self.toolId) {
      self.columns = this.columns.filter((value, index, arr) => value.field !== 'edit' && value.field !== 'delete');
    }
    blockui.pageBlock(self);
    const promises = [
      await self.getUserList(),
      await self.getResources(),
    ];
    Promise.all(promises);
    self.IsReady = true;
  },
  mounted() {
    this.$eventHub.$on('RefreshToolResources', this.getResources);
  },
  computed: {

  },
  methods: {
    ...mapActions({
      getWorkOrderList: 'tool/getWorkOrderListForReservation',
    }),
    initGrid() {
      const self = this;
      self.gridEngine = self.$refs.grid.gridEngine;
      self.gridEngine.hide();
    },
    initScheduler() {
      const self = this;
      self.schEngine = self.$refs.scheduler.schedulerEngine;
      self.getEvents();
      self.IsGridReady = true;
      const schButtons = [
        {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-arrow-left',
          tooltip: self.translate('previous_month'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.currentDate = self.dateHelper.add(self.currentDate, -1, 'month');
            self.getEvents();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-arrow-down',
          tooltip: self.translate('today'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.currentDate = self.dateHelper.getFirstDateOfMonth(new Date());
            self.getEvents();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-arrow-right',
          tooltip: self.translate('next_month'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.currentDate = self.dateHelper.add(self.currentDate, 1, 'month');
            self.getEvents();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-search-plus',
          tooltip: self.translate('zoom_in'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.schEngine.zoomIn();
            blockui.unBlockPage();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-search',
          tooltip: self.translate('zoom_reset'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.schEngine.zoomTo({
              preset: 'dayAndWeek',
              startDate: self.currentDate,
              endDate: self.dateHelper.add(self.currentDate, 1, 'month'),
            });
            if (self.currentDate.getTime() === self.dateHelper.getFirstDateOfMonth(new Date()).getTime()) self.$nextTick(() => { self.schEngine.scrollToNow({ block: 'start' }); });
            blockui.unBlockPage();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-search-minus',
          tooltip: self.translate('zoom_out'),
          onAction: function onAction() {
            blockui.pageBlock(self);
            self.schEngine.zoomOut();
            blockui.unBlockPage();
          },
        }, {
          type: 'button',
          cls: 'b-raised b-blue',
          margin: 5,
          icon: 'b-fa b-fa-toggle-on',
          tooltip: self.translate('toggle_view'),
          onAction: function onAction() {
            self.IsDisplayGrid = !self.IsDisplayGrid;
            self.IsDisplayGrid ? self.gridEngine.show() : self.gridEngine.hide();
            !self.IsDisplayGrid ? self.schEngine.show() : self.schEngine.hide();

            toolsContainer[0].items.forEach((item) => {
              if (item.icon.includes('b-fa-search')) {
                self.IsDisplayGrid ? item.hide() : item.show();
              }
            });
          },
        }];

      let toolsContainer = self.widgetHelper.append({ type: 'container', widgets: schButtons, cls: 'pull-left' }, { insertFirst: document.getElementsByClassName('schContainer')[0] });

      const schFilterButtons = [{
        type: 'button',
        icon: 'b-fa b-fa-wrench',
        tooltip: self.translate('reserved'),
        cls: 'b-raised b-blue',
        margin: 5,
        onAction: function onAction() {
          self.schEngine.eventStore.clearFilters();
          if (!this.currentElement.classList.contains('filtered')) {
            filterContainer[0].items.forEach((btn) => {
              btn.currentElement.classList.remove('filtered');
              btn.icon = 'b-fa b-fa-wrench';
            });
            this.currentElement.classList.add('filtered');
            this.icon = 'b-fa b-fa-filter';
            self.schEngine.eventStore.filter((x) => x.eventColor === 'blue');
          } else {
            this.currentElement.classList.remove('filtered');
            this.icon = 'b-fa b-fa-wrench';
          }
        },
      }, {
        type: 'button',
        icon: 'b-fa b-fa-wrench',
        tooltip: self.translate('lended'),
        cls: 'b-raised b-orange',
        margin: 5,
        onAction: function onAction() {
          self.schEngine.eventStore.clearFilters();
          if (!this.currentElement.classList.contains('filtered')) {
            filterContainer[0].items.forEach((btn) => {
              btn.currentElement.classList.remove('filtered');
              btn.icon = 'b-fa b-fa-wrench';
            });
            this.currentElement.classList.add('filtered');
            this.icon = 'b-fa b-fa-filter';
            self.schEngine.eventStore.filter((x) => x.eventColor === 'orange');
          } else {
            this.currentElement.classList.remove('filtered');
            this.icon = 'b-fa b-fa-wrench';
          }
        },
      }, {
        type: 'button',
        icon: 'b-fa b-fa-wrench',
        tooltip: self.translate('returned'),
        cls: 'b-raised b-green',
        margin: 5,
        onAction: function onAction() {
          self.schEngine.eventStore.clearFilters();
          if (!this.currentElement.classList.contains('filtered')) {
            filterContainer[0].items.forEach((btn) => {
              btn.currentElement.classList.remove('filtered');
              btn.icon = 'b-fa b-fa-wrench';
            });
            this.currentElement.classList.add('filtered');
            this.icon = 'b-fa b-fa-filter';
            self.schEngine.eventStore.filter((x) => x.eventColor === 'green');
          } else {
            this.currentElement.classList.remove('filtered');
            this.icon = 'b-fa b-fa-wrench';
          }
        },
      }];

      let filterContainer = self.widgetHelper.append({ type: 'container', widgets: schFilterButtons, cls: 'pull-right' }, { insertFirst: document.getElementsByClassName('schContainer')[0] });

      self.schEngine.eventEdit.editorConfig = {
        title: '',
        bbar: { widgets: [] },
        width: 400,
        triggerEvent: '',
        widgets: [
          {
            type: 'combo',
            label: self.translate('staff'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'userId',
            required: true,
            filterOperator: '*',
            items: self.userList,
          },
          {
            type: 'date',
            label: self.translate('start_date'),
            labelWidth: 400,
            name: 'startDate',
            onChange: (e) => { if (e.oldValue && e.value)self.getWorkOrders(); },
            editable: false,
          },
          {
            type: 'time',
            label: self.translate('start_time'),
            cls: 'startTime',
            labelWidth: 400,
            name: 'startDate',
            onChange: (e) => { if (e.oldValue && e.value)self.getWorkOrders(); },
            editable: false,
          },
          {
            type: 'date',
            label: self.translate('end_date'),
            labelWidth: 400,
            name: 'endDate',
            onChange: (e) => { if (e.oldValue && e.value)self.getWorkOrders(); },
            editable: false,
          },
          {
            type: 'time',
            label: self.translate('end_date'),
            cls: 'endTime',
            labelWidth: 400,
            name: 'endDate',
            onChange: (e) => { if (e.oldValue && e.value)self.getWorkOrders(); },
            editable: false,
          },
          {
            type: 'combo',
            multiSelect: true,
            filterSelected: true,
            label: self.translate('work_orders'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'workOrderIds',
            filterOperator: '*',
            items: [{ text: '', value: 0 }],
          },
          {
            type: 'combo',
            label: self.translate('tool_status'),
            emptyText: self.translate('no_option'),
            labelWidth: 400,
            name: 'toolStatusId',
            required: true,
            filterOperator: '*',
            items: [{ text: '', value: 0 }],
          },
          {
            type: 'text',
            labelWidth: 550,
            label: self.translate('description'),
            name: 'description',
            clearable: true,
          },
          {
            type: 'button',
            cls: 'b-blue b-raised',
            text: self.translate('reserve'),
            name: 'reserve',
            onAction: function onAction(e) {
              blockui.pageBlock(self);
              const { values } = self.schEngine.eventEdit;
              const reservation = {
                Id: values.id,
                ToolId: values.resourceId,
                BeginDate: dateFormat.postDateTimeFormat(values.startDate),
                EndDate: dateFormat.postDateTimeFormat(values.endDate),
                Description: values.description,
                ReservationWorkOrders: values.workOrderIds.map((item) => ({ WorkOrderId: item })),
              };
              self.reserve(reservation);
            },
          },
          {
            type: 'button',
            cls: 'b-orange b-raised',
            text: self.translate('lend'),
            name: 'lend',
            onAction: function onAction(e) {
              blockui.pageBlock(self);
              const { values } = self.schEngine.eventEdit;
              const reservation = {
                Id: values.id,
                EstimatedReturnDate: dateFormat.postDateTimeFormat(values.endDate),
                LendDescription: values.description,
                LendedUserId: values.userId,
              };
              self.lend(reservation);
            },
          },
          {
            type: 'button',
            cls: 'b-green b-raised',
            text: self.translate('return'),
            name: 'return',
            onAction: function onAction(e) {
              blockui.pageBlock(self);
              const { values } = self.schEngine.eventEdit;
              const postData = {
                reservation: {
                  Id: values.id,
                  ReturnedDescription: values.description,
                  ReturnedUserId: values.userId,
                },
                toolStatusId: values.toolStatusId,
              };
              self.return(postData);
            },
          },
          {
            type: 'text',
            cls: 'b-hidden',
            editable: 'false',
            text: 'Id',
            name: 'id',
          },
          {
            type: 'text',
            cls: 'b-hidden',
            editable: 'false',
            text: 'ResourceId',
            name: 'resourceId',
          }],
      };

      self.$nextTick(() => {
        self.schEngine.eventEdit.editor.on('beforehide', () => {
          const { widgets } = self.schEngine.eventEdit.editor;
          widgets.filter((x) => x.name === 'startDate')[0].value = undefined;
          widgets.filter((x) => x.name === 'startDate')[1].value = undefined;
          widgets.filter((x) => x.name === 'endDate')[0].value = undefined;
          widgets.filter((x) => x.name === 'endDate')[1].value = undefined;
          widgets.find((x) => x.name === 'workOrderIds').value = null;
        });
      });

      self.schEngine.on('beforeEventEdit', ({ eventRecord, resourceRecord, eventElement }) => {
        if (resourceRecord.id.toString().startsWith('_generated')) return false;
        const { widgets } = self.schEngine.eventEdit.editor;
        widgets.filter((x) => x.cls !== 'b-hidden').forEach((widget) => widget.show());
        widgets.filter((x) => x.type === 'button').forEach((widget) => widget.hide());
        widgets.find((x) => x.name === 'workOrderIds').value = null;
        widgets.find((x) => x.name === 'toolStatusId').value = null;
        widgets.find((x) => x.name === 'description').value = null;
        widgets.filter((x) => x.name === 'endDate')[0].label = self.translate('end_date');
        widgets.filter((x) => x.name === 'endDate')[1].label = self.translate('end_time');
        // if reservation
        if (!Number.isInteger(eventRecord.id)) {
          widgets.find((x) => x.name === 'userId').hide();
          widgets.find((x) => x.name === 'toolStatusId').hide();
          widgets.find((x) => x.name === 'reserve').show();
          self.$nextTick(async () => {
            await self.getWorkOrders();
            widgets.find((x) => x.name === 'resourceId').value = resourceRecord.id;
            widgets.find((x) => x.name === 'id').value = 0;
          });
        }
        // else if not lended
        else if (!eventRecord.data.lendedUserId) {
          widgets.find((x) => x.name === 'workOrderIds').hide();
          widgets.find((x) => x.name === 'toolStatusId').hide();
          widgets.find((x) => x.name === 'lend').show();
          widgets.filter((x) => x.name === 'endDate')[0].label = self.translate('estimated_return_date');
          widgets.filter((x) => x.name === 'endDate')[1].label = self.translate('estimated_return_time');
          widgets.filter((x) => x.name === 'startDate').forEach((widget) => widget.hide());
          self.$nextTick(() => {
            widgets.find((x) => x.name === 'userId').value = eventRecord.data.reservedUserId;
            widgets.find((x) => x.name === 'resourceId').value = resourceRecord.id;
          });
        }
        // else if not returned
        else if (!eventRecord.data.returnedUserId) {
          widgets.filter((x) => x.name === 'startDate').forEach((widget) => widget.hide());
          widgets.filter((x) => x.name === 'endDate').forEach((widget) => widget.hide());
          widgets.find((x) => x.name === 'workOrderIds').hide();
          widgets.find((x) => x.name === 'return').show();
          widgets.find((x) => x.name === 'toolStatusId').show();
          self.$nextTick(async () => {
            await self.getToolStatusList(resourceRecord.id);
            widgets.find((x) => x.name === 'userId').value = eventRecord.data.lendedUserId;
            widgets.find((x) => x.name === 'resourceId').value = resourceRecord.id;
            widgets.find((x) => x.name === 'toolStatusId').value = 1;
          });
        } else return false; // dont edit
      });

      self.schEngine.on({
        eventcontextmenu(context) {
          if (context.eventRecord.eventColor === 'green') {
            context.event.preventDefault();
            context.event.stopPropagation();
          }
        },
        async eventcontextmenuitem({ source, item, eventRecord }) {
          if (item.icon === 'b-icon b-icon-trash') {
            blockui.pageBlock(self);
            const success = await self.deleteReservation(eventRecord.id);
            if (!success) {
              self.$nextTick(() => {
                self.schEngine.eventStore.add(eventRecord);
              });
            }
          }
        },
      });

      self.schEngine.on('beforeeventdropfinalize', ({ context }) => {
        context.draggedRecords.forEach(async (record) => {
          const newId = context.newResource.id;
          if (newId.toString().startsWith('_generated') || newId === record.resourceId) {
            context.finalize(false);
            return;
          }
          context.async = true;
          blockui.pageBlock(self);
          const success = await self.updateReservation(record.data.id, newId);
          context.finalize(false);
          if (success) self.schEngine.eventStore.getById(record.data.id).resourceId = newId;
          blockui.unBlockPage();
        });
      });

      self.schEngine.scrollToNow({ block: 'start' });
    },
    async getUserList() {
      const self = this;
      await api.getUsers().then((res) => {
        self.userList = res.body.map((user) => ({
          value: user.Value,
          text: user.Text,
        }));
      });
    },
    async getToolStatusList(toolId) {
      const self = this;
      const { widgets } = self.schEngine.eventEdit.editor;
      widgets.find((x) => x.name === 'workOrderIds').value = null;
      await api.getToolStatuses({ toolId }).then((res) => {
        widgets.find((x) => x.name === 'toolStatusId').items = res.body.map((toolStatus) => ({
          value: toolStatus.Value,
          text: toolStatus.Text,
        }));
      });
    },
    async getWorkOrders() {
      const self = this;
      const { widgets } = self.schEngine.eventEdit.editor;
      widgets.find((x) => x.name === 'workOrderIds').value = null;
      const { startDate } = self.schEngine.eventEdit.values;
      const { endDate } = self.schEngine.eventEdit.values;
      await self.getWorkOrderList({
        fromDate: dateFormat.postDateTimeFormat(startDate),
        endDate: dateFormat.postDateTimeFormat(endDate),
      }).then((workOrders) => {
        widgets.find((x) => x.name === 'workOrderIds').items = workOrders.map((workOrder) => ({
          text: `${(workOrder.BreadCrumb ? `${workOrder.BreadCrumb}</br>` : '') + workOrder.WorkName}</br>${dateFormat.viewDateTimeFormat(workOrder.StartDate)} - ${dateFormat.viewDateTimeFormat(workOrder.EndDate)}`, //
          value: workOrder.Id,
        }));
      });
    },
    async getResources() {
      const self = this;
      await api.getToolTypeList({ isIncludeSubData: true, toolId: self.toolId }).then((res) => {
        self.resources = [];
        if (res.body.IsSuccess) {
          res.body.Data.forEach((toolType) => {
            const type = {
              expanded: true,
              toolTree: toolType.Name,
              toolType: null,
              toolSubType: null,
              isNeedCalibration: null,
              nextCalibrationDate: null,
              isNeedPeriodicMaintenance: null,
              nextMaintenanceDate: null,
              brand: null,
              model: null,
              description: null,
              warehouse: null,
              toolStatus: null,
              serialNumber: null,
              children: toolType.Tools ? toolType.Tools.map((tool) => ({
                id: tool.Id,
                isNeedCalibration: tool.IsNeedCalibration,
                nextCalibrationDate: tool.NextCalibrationDate,
                isNeedPeriodicMaintenance: tool.IsNeedPeriodicMaintenance,
                nextMaintenanceDate: tool.NextMaintenanceDate,
                toolTree: toolType.Name,
                toolType: toolType.Name,
                toolSubType: null,
                brand: tool.BrandId ? tool.Brand.Name : null,
                // TODO:tool model waiting from backend
                model: null, // tool.ModelId ? tool.Model.Name : null,
                description: tool.Description,
                warehouse: self.getParentRecursive(tool.Warehouse), // WarehouseName
                toolStatus: tool.ToolStatus.Name,
                serialNumber: tool.SerialNumber,
                iconCls: tool.ToolStatusId !== 1 ? (tool.ToolStatusId === 3 ? 'b-icon b-fa-wrench-orange' : 'b-icon b-fa-wrench-red') : null,
              })) : [],
            };

            toolType.ToolSubTypes.forEach((toolSubType) => {
              const subType = {
                expanded: true,
                toolTree: toolSubType.Name,
                children: toolSubType.Tools ? toolSubType.Tools.map((tool) => ({
                  id: tool.Id,
                  isNeedCalibration: tool.IsNeedCalibration,
                  nextCalibrationDate: tool.NextCalibrationDate,
                  isNeedPeriodicMaintenance: tool.IsNeedPeriodicMaintenance,
                  nextMaintenanceDate: tool.NextMaintenanceDate,
                  toolTree: toolSubType.Name,
                  toolType: toolType.Name,
                  toolSubType: toolSubType.Name,
                  brand: tool.BrandId ? tool.Brand.Name : null,
                  model: tool.ModelId ? tool.Model.Name : null,
                  description: tool.Description,
                  warehouse: self.getParentRecursive(tool.Warehouse), // WarehouseName
                  toolStatus: tool.ToolStatus.Name,
                  serialNumber: tool.SerialNumber,
                  iconCls: tool.ToolStatusId !== 1 ? (tool.ToolStatusId === 3 ? 'b-icon b-fa-wrench-orange' : 'b-icon b-fa-wrench-red') : null,
                })) : [],
              };
              if (subType.children.length > 0) type.children.push(subType);
            });

            if (type.children.length > 0) self.resources.push(type);
          });
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },

    getEvents() {
      const self = this;
      if (self.schEngine) {
        self.schEngine.zoomTo({
          preset: 'dayAndWeek',
          startDate: self.currentDate,
          endDate: self.dateHelper.add(self.currentDate, 1, 'month'),
        });
        if (self.currentDate.getTime() === self.dateHelper.getFirstDateOfMonth(new Date()).getTime()) self.$nextTick(() => { self.schEngine.scrollToNow({ block: 'start' }); });
      }

      const postData = {
        beginDate: dateFormat.postDateTimeFormat(self.currentDate),
        endDate: dateFormat.postDateTimeFormat(self.dateHelper.add(self.currentDate, 1, 'month')),
        toolId: self.toolId,
      };
      api.GetReservationsByDateRange(postData).then((res) => {
        self.events = res.body.Data.map((item) => ({
          id: item.Id,
          resourceId: item.ToolId,
          toolType: self.schEngine.resourceStore.getById(item.ToolId).toolType,
          toolSubType: self.schEngine.resourceStore.getById(item.ToolId).toolSubType,
          name: item.ReturnedUserId ? self.getUserFromList(item.ReturnedUserId) : item.LendedUserId ? self.getUserFromList(item.LendedUserId) : self.getUserFromList(item.CreatedUserId),
          startDate: new Date(item.BeginDate),
          endDate: new Date(item.EndDate),
          reservedDescription: item.Description,
          reservedUser: item.CreatedUserId ? self.getUserFromList(item.CreatedUserId) : '',
          reservedUserId: item.CreatedUserId,
          lendedDescription: item.LendDescription,
          lendedUser: item.LendedUserId ? self.getUserFromList(item.LendedUserId) : '',
          lendedUserId: item.LendedUserId,
          lendedDate: item.LendDate ? new Date(item.LendDate) : null,
          estimatedReturnDate: item.EstimatedReturnDate ? new Date(item.EstimatedReturnDate) : null,
          returnedDescription: item.ReturnedDescription,
          returnedUserId: item.ReturnedUserId,
          returnedUser: item.ReturnedUserId ? self.getUserFromList(item.ReturnedUserId) : '',
          returnedDate: item.ActualReturnDate ? new Date(item.ActualReturnDate) : null,
          workOrders: [],
          eventColor: item.ReturnedUserId ? 'green' : (item.LendedUserId ? 'orange' : 'blue'),
          eventStyle: 'plain',
          durationUnit: 'minute',
          editable: !item.ReturnedUserId,
          draggable: !self.toolId && !item.LendedUserId,
          resizable: false,
        }));
        blockui.unBlockPage();
      });
    },
    getUserFromList(id) {
      const user = this.userList.find((x) => x.value === id);
      return user && user.text || this.translate('deleted_user');
    },
    getParentRecursive(obj) {
      return (obj.Parent ? this.getParentRecursive(obj.Parent) : '').concat((obj.Parent ? ' > ' : '') + obj.Name);
    },

    reserve(reservation) {
      const self = this;
      const { values } = self.schEngine.eventEdit;
      api.saveReservation(reservation).then((res) => {
        if (res.body.IsSuccess) {
          self.schEngine.eventEdit.editor.hide();
          toastr.success(self.translate('save_successfully'));
          self.schEngine.eventStore.add({
            id: parseInt(res.body.Messages.find((x) => x.Key === 'Id').Value),
            resourceId: values.resourceId,
            toolType: self.schEngine.resourceStore.getById(values.resourceId).toolType,
            toolSubType: self.schEngine.resourceStore.getById(values.resourceId).toolSubType,
            name: localStorage.getItem('userName'),
            reservedUser: localStorage.getItem('userName'),
            reservedUserId: localStorage.getItem('userId'),
            startDate: values.startDate,
            endDate: values.endDate,
            reservedDescription: values.description,
            eventColor: 'blue',
            eventStyle: 'plain',
            durationUnit: 'minute',
            lendedDescription: null,
            lendedUser: null,
            lendedUserId: null,
            lendedDate: null,
            estimatedReturnDate: null,
            returnedDescription: null,
            returnedUserId: null,
            returnedUser: null,
            returnedDate: null,
            workOrders: [],
            editable: true,
            draggable: !self.toolId && true,
            resizable: false,
          });
        } else {
          toastr.error(res.body.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    lend(reservation) {
      const self = this;
      const { values } = self.schEngine.eventEdit;
      const users = self.schEngine.eventEdit.editor.widgets.find((x) => x.name === 'userId').items;
      const user = users.find((x) => x.value === values.userId);
      api.lendReservation(reservation).then((res) => {
        if (res.body.IsSuccess) {
          self.schEngine.eventEdit.editor.hide();
          toastr.success(self.translate('save_successfully'));
          const event = self.schEngine.eventStore.getById(values.id);
          event.set({
            name: user.text,
            lendedDate: new Date(),
            lendedDescription: values.description,
            lendedUser: user.text,
            lendedUserId: user.value,
            estimatedReturnDate: values.startDate,
            eventColor: 'orange',
            draggable: false,
          });
          // event.remove();
          // self.schEngine.eventStore.add(event);
          const resource = self.schEngine.resourceStore.getById(event.resourceId);
          resource.iconCls = 'b-icon b-fa-wrench-orange';
          resource.toolStatus = res.body.Messages[0].Key;
          // self.schEngine.refreshWithTransition();
          self.$emit('updated');
        } else {
          toastr.error(res.body.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    return(reservation) {
      const self = this;
      const { values } = self.schEngine.eventEdit;
      const users = self.schEngine.eventEdit.editor.widgets.find((x) => x.name === 'userId').items;
      const user = users.find((x) => x.value === values.userId);
      api.returnReservation(reservation).then((res) => {
        if (res.body.IsSuccess) {
          self.schEngine.eventEdit.editor.hide();
          toastr.success(self.translate('save_successfully'));
          const event = self.schEngine.eventStore.getById(values.id);
          event.set({
            name: user.text,
            returnedDate: new Date(),
            returnedDescription: values.description,
            returnedUser: user.text,
            returnedUserId: user.value,
            eventColor: 'green',
            editable: false,
            draggable: false,
          });
          // event.remove();
          // self.schEngine.eventStore.add(event);
          const resource = self.schEngine.resourceStore.getById(event.resourceId);
          resource.iconCls = reservation.toolStatusId === 2 ? 'b-icon b-fa-wrench-red' : null;
          const statuses = self.schEngine.eventEdit.editor.widgets.find((x) => x.name === 'toolStatusId').items;
          const status = statuses.find((x) => x.value === reservation.toolStatusId).text;
          resource.toolStatus = status;
          // self.schEngine.refreshWithTransition();
          self.$emit('updated');
        } else {
          toastr.error(res.body.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    async updateReservation(reservationId, toolId) {
      const self = this;
      let success = false;
      await api.updateReservation({ id: reservationId, toolId }).then((res) => {
        if (res.body.IsSuccess) {
          success = true;
          toastr.success(self.translate('update_successfully'));
        } else {
          toastr.error(res.body.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
      return success;
    },
    async deleteReservation(reservationId) {
      const self = this;
      let success = false;
      await api.deleteReservation(reservationId).then((res) => {
        if (res.body.IsSuccess) {
          success = true;
          toastr.success(self.translate('delete_successfully'));
          const record = self.schEngine.eventStore.getById(reservationId);
          if (record) record.remove();
        } else {
          toastr.error(res.body.Message);
        }
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
      return success;
    },
  },
  beforeDestroy() {
    this.$eventHub.$off('RefreshToolResources');
  },
};
