





























import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'
import {Vue, Component, Prop} from 'vue-property-decorator';
import { CFMDTOSparePartWarehouse } from '@/scripts/services/ClientApiAuto';
import {required} from "vuelidate/lib/validators";


@Component<SparePartTransfer>({
    components: {
    },
    validations() {
        return {
          selectedWareHouse: {
            StockCount: {required}
          }
        }
    },
})

export default class SparePartTransfer extends Vue{
    @Prop()
    sparePartId?:number;

    selectedWareHouse: CFMDTOSparePartWarehouse = {StockCount:0}
    warehouses: CFMDTOSparePartWarehouse[] = [];
    wareHouseList : {Name:String, Id:Number}[] = [];
    hasError =false

    async created(){
        this.getWareHoueses();
        let response = await this.$globalApiClient.sparePartWarehouse
        .sparePartWarehouseGetWarehouses({sparePartId: <number>this.sparePartId});
        this.warehouses = response.data;
    }

    async getWareHoueses(){
        const response = await this.$globalApiClient.warehouse.warehouseGetWareHouses();
        this.wareHouseList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async onSubmit(){
        let responses = await this.$globalApiClient.sparePartWarehouse.sparePartWarehouseGetWarehouses({sparePartId: <number>this.sparePartId});
        for (let index = 0; index < responses.data.length; index++) {
            const element = responses.data[index];
            if (element.StockCount < this.warehouses[index].StockCount) {
                toastr.error('Transfer edilecek miktar güncel miktardan büyük olamaz');
                this.warehouses[index].StockCount = element.StockCount
                this.hasError = true
            }
            if (element.WarehouseId != this.warehouses[index].WarehouseId && element.StockCount != this.warehouses[index].StockCount) {
                this.warehouses[index].Id = 0
                element.StockCount = element.StockCount - this.warehouses[index].StockCount;
                this.warehouses.push(element)
            }
        }
        if (!this.hasError) {
            const response = await this.$globalApiClient.sparePartWarehouse.sparePartWarehouseTransfer(this.warehouses);
            if (response.data.IsSuccess) {
                toastr.success(this.translate('successfully_transfer_stock'));
                this.$router.push(
                {
                    name:'SparePart', 
                    
                });
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
        }
        this.hasError = false
        
    }
}
