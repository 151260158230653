import Vue from 'vue';
import * as blockui from '../../helpers/block-ui';
import * as swal from '../../helpers/swal';
import * as dateFormat from '../../helpers/dateFormat';
import * as api from '../services/api';

export const renderTable = (tableParams) => {
  let {
    container,
    data,
    columns,
    paging = false,
    sortable = false,
    expandable = false,
    editButton = false,
    deleteButton = false,
    childAddButton = false,
    fixedHeader = false,
    scrollY = false,
    scrollCollapse = false,
    childAddButtonTooltip = '',
    childAddButtonIcon = 'fa-plus',
    rowsGroup = false,
    colReOrder = true,
    order = [],
    searching = true,
    rowGroup = false,
    fixedColumns = false,
    columnDefs = null,
    select = select,
    info = true,
    footerCallback = null,
    buttons = [{
      extend: 'excelHtml5',
      title() {
        return `${tableParams.exportFileName}-${dateFormat.viewDateTimeFormat(new Date())}`;
      },
      className: 'excelButton',
    }, {
      extend: 'pdfHtml5',
      title() {
        return `${tableParams.exportFileName}-${dateFormat.viewDateTimeFormat(new Date())}`;
      },
      className: 'pdfButton',
    }, {
      extend: 'print',
      title() {
        return `${tableParams.exportFileName}-${dateFormat.viewDateTimeFormat(new Date())}`;
      },
      className: 'printButton',
    }],
    extraButtons = [],
  } = tableParams;

  buttons = buttons.map((item) => {
    if (!item.exportOptions) {
      item.exportOptions = {
        orthogonal: 'export',
        columns: 'th:not(.not-export)',
      };
      return item;
    }
    return item;
  });

  const childAddButtonColumn = {
    data: 'addNewChild',
    title: '',
    width: 45,
    class: 'all not-export td-action datatable-action-button',
    orderable: false,
    render(data, type, row) {
      return `<a href="javascript:;" data-id="${row.Id}" data-toggle="m-tooltip" data-placement="left" data-original-title="${childAddButtonTooltip}" class="btn-outline-success add-new-child-button">
                  <i class="fa fa-fw ${childAddButtonIcon}"></i>
                </a>`;
    },
  };

  let btn = { edit: '', delete: '' };
  if (localStorage.getItem('vue-lang') == 'tr-TR') {
    btn = { edit: 'Düzenle', delete: 'Sil' };
  } else if (localStorage.getItem('vue-lang') == 'en-EN') {
    btn = { edit: 'Edit', delete: 'Delete' };
  }

  const editButtonColumn = {
    data: 'edit',
    title: '',
    width: 45,
    class: 'all not-export td-action datatable-action-button',
    orderable: false,
    render(data, type, row) {
      return `<a href="javascript:;" data-id="${row.Id}" data-toggle="m-tooltip" data-placement="left" data-original-title="${btn.edit}" class="btn-outline-primary edit-button">
                    <i class="fa fa-fw fa-edit"></i>
                  </a>`;
    },
  };

  const deleteButtonColumn = {
    data: 'delete',
    title: '',
    width: 45,
    class: 'all not-export td-action datatable-action-button',
    orderable: false,
    render(data, type, row) {
      return `<a href="javascript:;" data-id="${row.Id}" data-toggle="m-tooltip" data-placement="left" data-original-title="${btn.delete}" class="btn-outline-danger delete-button">
                  <i class="fa fa-fw fa-trash-alt"></i>
                </a>`;
    },
  };

  const expandableColumn = {
    data: 'toggle',
    title: '',
    orderable: false,
    width: 45,
    class: 'all not-export td-action datatable-action-button',
    render(data, type, row) {
      if (row.ChildCount > 0 || row.ChildCount === undefined) {
        return `<div class="text-center plus-button details-control"><a href="javascript:;"><i class="fas fa-plus-circle"></i></a></div>
                <div class="text-center minus-button details-control"><a href="javascript:;"><i class="fas fa-minus-circle"></i></a></div>`;
      }
      return `<div class="text-center plus-button details-control m--hide"><a href="javascript:;"><i class="fas fa-plus-circle"></i></a></div>
                <div class="text-center minus-button details-control m--hide"><a href="javascript:;"><i class="fas fa-minus-circle"></i></a></div>`;
    },
  };

  extraButtons.forEach((extraButton) => {
    const extraButtonColumn = {
      title: '',
      width: 45,
      class: 'all not-export td-action datatable-action-button',
      orderable: false,
      render(data, type, row) {
        if (extraButton.display !== undefined && !extraButton.display(row)) {
          return '';
        }
        return `<a href="javascript:;" data-id="${row.Id}" data-toggle="m-tooltip" data-placement="left" 
                    data-original-title="${typeof extraButton.tooltip === 'function' || extraButton.tooltip instanceof Function ? extraButton.tooltip(row) : extraButton.tooltip}" 
                    class="${typeof extraButton.class === 'function' || extraButton.class instanceof Function ? extraButton.class(row) : extraButton.class}">
                  <i class="fa fa-fw ${typeof extraButton.icon === 'function' || extraButton.icon instanceof Function ? extraButton.icon(row) : extraButton.icon} ${typeof extraButton.buttonClass === 'function' || extraButton.buttonClass instanceof Function ? extraButton.buttonClass(row) : extraButton.buttonClass}"></i>
                </a>`;
      },
    };
    columns = [...columns, extraButtonColumn];
  });

  expandable ? columns = [expandableColumn, ...columns] : '';
  childAddButton ? columns = [...columns, childAddButtonColumn] : '';
  editButton ? columns = [...columns, editButtonColumn] : '';
  deleteButton ? columns = [...columns, deleteButtonColumn] : '';

  const options = {
    /* language: {
          url: "@/assets/static/DataTables/i18n/" + localStorage.getItem('vue-lang') + ".json"
        }, */
    dom: 'Bfrtip',
    data,
    sortable,
    retrieve: true,
    paging,
    columns,
    scrollX: true,
    scrollY,
    scrollCollapse,
    rowsGroup,
    buttons,
    fixedHeader,
    order,
    rowGroup,
    colReorder: colReOrder,
    fixedColumns,
    searching,
    search: {
      smart: false,
    },
    columnDefs,
    select,
    info,
    footerCallback,
  };

  moment.locale(localStorage.getItem('vue-lang'));
  $.fn.dataTable.moment('LLL');
  $.fn.dataTable.moment('LL');
  /* $.fn.dataTable.ext.search.push(
      function (settings, searchData, index, rowData, counter) {
      }
    ); */

  const dataTableObject = $(container).DataTable(options);
  mApp.initTooltips();
  dataTableObject.on('draw', () => {
    mApp.initTooltips();
  });

  return dataTableObject;
};

function nestedResolve(path, obj) {
  return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj || this);
}

export const getDatatables = (self, endpoint, res, table, columns, data, extraParams) => {
  blockui.blockElement(self, '.m-portlet');
  const isDataTable = $.fn.DataTable.isDataTable(table);
  api[endpoint](data).then((response) => {
    const resp = nestedResolve(res, response);
    if (isDataTable) {
      const dt = $(table).DataTable();
      dt.clear();
      dt.rows.add(resp);
      dt.draw();
    } else {
      table = renderTable({
        exportFileName: self.translate('form'),
        container: table,
        data: resp,
        columns,
        paging: true,
        sortable: true,
        editButton: true,
        deleteButton: true,
        ...extraParams,
      });
    }
    blockui.unBlockElement('.m-portlet');
  }, (error) => {
    blockui.unBlockElement('.m-portlet');
  });
};

// DELETE MOCK
export const getDatatablez = (self, endpoint, res, table, columns, data, extraParams) => {
  blockui.blockElement(self, '.m-portlet');
  const isDataTable = $.fn.DataTable.isDataTable(table);
  // api[endpoint](data).then((response) => {
  // var resp = this.nestedResolve(res, response);
  const resp = res;
  if (isDataTable) {
    const dt = $(table).DataTable();
    dt.clear();
    dt.rows.add(resp);
    dt.draw();
  } else {
    table = renderTable({
      exportFileName: self.translate('form'),
      container: table,
      data: resp,
      columns,
      paging: true,
      sortable: true,
      editButton: true,
      deleteButton: true,
      ...extraParams,
    });
  }
  blockui.unBlockElement('.m-portlet');
  // }, error => {
  // blockui.unBlockElement(".m-portlet");
  // });
};

export const editButton = (self, endpoint, res, table, selected, modal, show) => {
  window.$(table).on('click', '.edit-button', function () {
    blockui.pageBlock(self);
    self.isUpdate = true;
    const dt = $(table).DataTable();
    self[selected] = {};
    const tr = window.$(this).closest('tr');
    const id = dt.row(tr).data().Id;
    api[endpoint](id).then((response) => {
      self[selected] = nestedResolve(res, response);
      self[show] = true;
      self.$nextTick(() => {
        const modalContainer = window.$(modal);
        blockui.unBlockPage();
        modalContainer.modal({
          backdrop: 'static',
          show: true,
        });
        modalContainer.find('form').data('type', 'update');
      });
    });
  });
};

export const editButtonCallback = (table, callback) => {
  const self = this;
  window.$(table).on('click', '.edit-button', async function () {
    blockui.pageBlock(self);
    const dt = $(table).DataTable();
    const tr = window.$(this).closest('tr');
    const id = dt.row(tr).data().Id;
    await callback(id);
    blockui.unBlockPage();
  });
};

// DELETE MOCK
export const editButtonz = (self, endpoint, res, table, selected, modal, show) => {
  window.$(table).on('click', '.edit-button', function () {
    self.isUpdate = true;
    const dt = $(table).DataTable();
    self[selected] = {};
    const tr = window.$(this).closest('tr');
    const id = dt.row(tr).data().Id;
    // api[endpoint](id).then(response => {
    // self[selected] = nestedResolve(res, response);
    self[selected] = dt.row(tr).data();
    self[show] = true;
    Vue.set(self, show, true);
    self.$nextTick(() => {
      blockui.blockModal(self);
      const modalContainer = window.$(modal);
      blockui.unBlockModal();
      modalContainer.modal({
        backdrop: 'static',
        show: true,
      });
      modalContainer.find('form').data('type', 'update');
    });
    // });
  });
};

/* export const addButton = (self, modal, selected, slc, show) => {
  window["$"]("#add_btn").on('click', () => {
    self.isUpdate = false;
    self[selected] = {};
    self[selected] = slc;
    self[show] = true;
    self.$nextTick(() => {
      const modalContainer = window["$"](modal);
      modalContainer.modal({
        backdrop: 'static',
        show: true
      });
      modalContainer.find("form").data("type", "");
    });
  });
}; */

export const deleteButton = (self, endpoint, table) => {
  window.$(table).on('click', '.delete-button', function () {
    const dt = $(table).DataTable();
    const tr = window.$(this).closest('tr');
    const row = dt.row(tr);
    const data = row.data();
    swal.check(self, data.Name).then((e) => {
      if (e.value) {
        blockui.blockElement(self, '.m-portlet');
        api[endpoint](data.Id).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('delete_successfully'));
            row.remove().draw('full-hold');
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockElement('.m-portlet');
        }, (err) => {
          blockui.unBlockElement('.m-portlet');
        });
      }
    });
  });
};
