import * as api from '../../services/api';

export default {

  data() {
    return {
      sparePartBelowCriticalStockCountList: [],
      currentSort: 'Name',
      currentSortDir: 'asc',
      pageSize: 6,
      currentPage: 1,
    };
  },
  mounted() {
    api.getSparePartBelowCriticalStockCount().then((res) => {
      this.sparePartBelowCriticalStockCountList = res.body.map(item => {
        return {
          ...item,
        };
      });
    });
  },
  methods: {
    redraw() {},
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    nextPage() {
      if (this.currentPage * this.pageSize < this.sparePartBelowCriticalStockCountList.length) this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
  },
  computed: {
    sortedSparePartBelowCriticalStockCountList() {
      return this.sparePartBelowCriticalStockCountList.sort((a, b) => {
        let modifier = 1;
        if (isNaN(a[this.currentSort]) || isNaN(b[this.currentSort])) {
          const compare = a[this.currentSort].localeCompare(b[this.currentSort]);
          if (this.currentSortDir === 'asc') modifier = -1;
          if (compare > 0) return -1 * modifier;
          if (compare < 0) return 1 * modifier;
        } else {
          if (this.currentSortDir === 'asc') modifier = -1;
          if (a[this.currentSort] > b[this.currentSort]) return -1 * modifier;
          if (a[this.currentSort] < b[this.currentSort]) return 1 * modifier;
        }
        return 0;
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize;
        const end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      });
    },
    isLastPage() {
      return this.currentPage * this.pageSize >= this.sparePartBelowCriticalStockCountList.length;
    },
    isFirstPage() {
      return this.currentPage == 1;
    },
  },
};
