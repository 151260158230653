









































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import {Component, Prop} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import {CFMDTOScheduleWork, CFMDTOScheduleDevice, CFMDTOScheduleRoom, CFMDTOSchedule} from '@/scripts/services/ClientApiAuto'
import CFMMultiselect from '../partials/CFMMultiselect.vue';

import { mixinz } from '@/scripts/global/mixinz';
import BootstrapSwitchComponent from '../shared/BootstrapSwitchComponent';


@Component<ConstraintDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
		'bootstrap-switch-component': BootstrapSwitchComponent
    },
    validations() {
        return {
		constraint:{
			Name:{required}
		},
        constraintWork:{
            Name: {required},
            IsSuitable:false
        },
		constraintDevice:{
            Name: {required},
            IsSuitable:false
        },
		constraintRoom:{
            Name: {required},
            IsSuitable:false
        },
        selectedDevice: {
            DeviceId: {required}
        },
		selectedRoom: {
            RoomId: {required}
        },
		selectedWork: {
            WorkId: {required}
        },
		selectedSchedule:{
			ScheduleId: {required}
		}
        }
    },
	mixins: [mixinz]
})

export default class ConstraintDialog extends CFMDialog  {
	@Prop()
	val?:number;
	constraint = 
	{
		Name: '',
		IsActive: true,
		BeginDate: '',
		ExpireDate: '',
		Type: 0
	}
	constraintTypes = [
        {
            Text:'Kapasite Ekipmanları', Value:1
        },
        {
            Text:'İş', Value:2
        },
        {
            Text:'Oda', Value:3
        },
    ];
	selectedConstraintType1 = 1;
  	constraintWork: CFMDTOScheduleWork = {Name: '',};
  	constraintRoom: CFMDTOScheduleRoom = {Name: '',};
	constraintDevice: CFMDTOScheduleDevice = {Name: '',};

    workList : {Name:String, Id:Number }[] = [];
    roomList : {Name:String, Id:Number }[] = [];
    deviceList : {Name:String, Id:Number }[] = [];
    scheduleList : {Name:String, Id:Number }[] = [];

    selectedDevice :CFMDTOScheduleDevice = {Name: ''};
    selectedWork :CFMDTOScheduleWork = {Name: ''};
    selectedRoom :CFMDTOScheduleRoom = {Name: ''};
    selectedSchedule :CFMDTOSchedule = {Name: ''};

	get selectedConstraintType() {
		// @ts-ignore
		return this.selectGet('constraintTypes', 'constraint.Type', 'Value', 'Text');
	}
    set selectedConstraintType(newValue) {
		// @ts-ignore
        return this.selectSet('constraint.Type', 'Value', newValue);
    }

	async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
		this.constraint.Type = <number>this.val;
		switch (this.val) {
			case 1:
				const res = await this.apiClient.scheduleDevice.scheduleDeviceGetById({id: <number>this.id});
        		this.constraintDevice = res.data;
				break;
			case 2:
				const ress = await this.apiClient.scheduleWork.scheduleWorkGetById({id: <number>this.id});
        		this.constraintWork = ress.data;
				break;
			case 3:
				const resss = await this.apiClient.scheduleRoom.scheduleRoomGetById({id: <number>this.id});
        		this.constraintRoom = resss.data;
				break;
			default:
				break;
		}
      }
      blockui.unBlockModal();
      const promises = [
      this.getWorks(),
	  this.getDevices(),
	  this.getRooms(),
	  this.getSchedules()
      ];
      if(this.isUpdate){
        if(this.constraintWork && this.constraintWork.WorkId){
          this.selectedWork = this.constraintWork;
		  if (this.constraintWork.ScheduleId) {
		  	this.getSchedule(this.constraintWork.ScheduleId);
		  }
        }
		if(this.constraintRoom && this.constraintRoom.RoomId){
          this.selectedRoom = this.constraintRoom;
		  if (this.constraintRoom.ScheduleId) {
		  	this.getSchedule(this.constraintRoom.ScheduleId);
		  }
        }
		if(this.constraintDevice && this.constraintDevice.DeviceId){
          this.selectedDevice = this.constraintDevice;
		  if (this.constraintDevice.ScheduleId) {
		  	this.getSchedule(this.constraintDevice.ScheduleId);
		  }
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }

	async getWorks(){
      const response = await this.apiClient.work.workGetListWithBreadCrumb();
      this.workList = response.data.map(x=>({Name:<string>x.BreadCrumb, Id:<number>x.Id,IsActive:true}));
    }

	async getDevices(){
      const response = await this.apiClient.capacityEquipment.capacityEquipmentGetCapacityEquipments();
      this.deviceList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value }));
    }

	async getRooms(){
      const response = await this.apiClient.room.roomGetAllRooms();
      this.roomList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value }));
    }
	async getSchedules(){
      const response = await this.apiClient.schedule.scheduleGetSchedules();
      this.scheduleList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value }));
    }

	async getSchedule(id:number){
		const response = await this.apiClient.schedule.scheduleGetById({id:id});
		this.selectedSchedule = response.data;
		return response.data;
	}

    async onSubmit() {
	  let formData:any;
	  switch (this.constraint.Type) {
		  case 1:
			  formData = {
				Id: this.selectedDevice.Id ? this.selectedDevice.Id : 0,
				Name: this.selectedDevice.Name,
				IsSuitable : this.selectedDevice.IsSuitable,
				ScheduleId: this.selectedSchedule.Id,
				BeginDate : this.selectedDevice.BeginDate,
				ExpireDate : this.selectedDevice.ExpireDate,
				DeviceId: this.selectedDevice.DeviceId,
				IsActive:true
			  }
			  console.log('constraintDevice',formData);
			  try{
				const response  = await this.apiClient.scheduleDevice.scheduleDeviceSave(formData);
					if (response.data.IsSuccess) {
					toastr.success(this.translate('save_successfully'));
					this.saveSucceeded();
					this.close();
					} else {
					const errorMessage = response.data.Message ? response.data.Message : "Error";
					toastr.error(errorMessage);
					}
				}
				catch(e){
					console.log("err",e)
					toastr.error(e);
				}
				finally{
					blockui.unBlockModal();
				}
			break;
		case 2:
			  formData = {
				Id: this.selectedWork.Id,
				Name: this.selectedWork.Name,
				ScheduleId: this.selectedSchedule.Id,
				IsSuitable : this.selectedWork.IsSuitable,
				BeginDate : this.selectedWork.BeginDate,
				ExpireDate : this.selectedWork.ExpireDate,
				IsActive:true,
				WorkId: this.selectedWork.WorkId
			  }
			  console.log('constraintWork',formData);
			  try{
				const response  = await this.apiClient.scheduleWork.scheduleWorkSave(formData);
					if (response.data.IsSuccess) {
					toastr.success(this.translate('save_successfully'));
					this.saveSucceeded();
					this.close();
					} else {
					const errorMessage = response.data.Message ? response.data.Message : "Error";
					toastr.error(errorMessage);
					}
				}
				catch(e){
					console.log("err",e)
					toastr.error(e);
				}
				finally{
					blockui.unBlockModal();
				}
			break;
	  	case 3:
			  formData = {
				Id: this.selectedRoom.Id,
				Name: this.selectedRoom.Name,
				IsSuitable : this.selectedRoom.IsSuitable,
				ScheduleId: this.selectedSchedule.Id,
				BeginDate : this.selectedRoom.BeginDate,
				ExpireDate : this.selectedRoom.ExpireDate,
				RoomId: this.selectedRoom.RoomId,
				IsActive:true
			  }
			  console.log('selectedRoom',formData);
			  try{
				const response  = await this.apiClient.scheduleRoom.scheduleRoomSave(formData);
					if (response.data.IsSuccess) {
					toastr.success(this.translate('save_successfully'));
					this.saveSucceeded();
					this.close();
					} else {
					const errorMessage = response.data.Message ? response.data.Message : "Error";
					toastr.error(errorMessage);
					}
				}
				catch(e){
					console.log("err",e)
					toastr.error(e);
				}
				finally{
					blockui.unBlockModal();
				}
			break;
		  default:
			  break;
	  }
      blockui.blockModal(this);
    }
}
