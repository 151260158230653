import { required } from 'vuelidate/lib/validators';
import Vue from 'vue';
import * as blockui from '../../../helpers/block-ui';
import * as api from '../../../scripts/services/api';
import { getCookieByName } from '../../../scripts/global/util';
import { mixinz } from '../../../scripts/global/mixinz';

export default {
  mixins: [mixinz],
  props: ['contractId', 'attachment', 'isUpdate'],
  data() {
    return {
      description: '',
      fileId: null,
      fileName: null,
      suitableFileTypeList: [],
      // contractId: this.$route.query.Id
      selectedAttachment: {},
      attachmentsType: [],
    };
  },
  validations() {
    return {
      selectedAttachment: {
        FileId: { required },
        TypeId: { required },
      },
    };
  },
  mounted() {
    const self = this;
    self.selectedAttachment = self.attachment;
    $('#add-attachments-modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
    api.getFileTypeList().then((res) => {
      self.suitableFileTypeList = res.body;
    });
  },
  methods: {
    onFileInputChanged(e) {
      const self = this;
      blockui.blockModal(self);
      const { files } = e.target;
      const fileSize = ((files[0].size / 1024) / 1024).toFixed(4);
      const extension = files[0].name.split('.').pop().toLowerCase();
      let acceptedExtensions = [];
      bryntum.scheduler.AjaxHelper.get(`${process.env.VUE_APP_API_BASE_URL}/FileType/GetList`, {
        parseJson: true,
      }).then(async (response) => {
        const data = response.parsedJson;
        acceptedExtensions = data.map((x) => x.Extension);
        if (typeof files[0].name !== 'undefined' && fileSize <= 20 && acceptedExtensions.includes(extension)) { // static file
          const formData = new FormData();
          formData.append('File', files[0]);
          bryntum.scheduler.AjaxHelper.post(`${process.env.VUE_APP_API_BASE_URL}/File/SaveFile`, formData, {
            parseJson: true,
            headers: {
              Accept: 'application/json',
              Authorization: `${getCookieByName('tokenType')} ${getCookieByName('token')}`,
              Culture: localStorage.getItem('vue-lang'),
            },
          }).then((response) => {
            const data = response.parsedJson;
            const fileId = parseInt(data.Message);
            const done = Number.isInteger(fileId);
            // let record = source.cellInfo.record;
            if (done) {
              // record.File = record.File || {};
              // record.File.Name = record.FileName = files[0].name;
              // record.data.FileName = files[0].name;
              // record.FileId = fileId;
              //
              Vue.set(self.selectedAttachment, 'FileId', fileId);
              Vue.set(self.selectedAttachment, 'Name', files[0].name);
              toastr.success(self.selectedAttachment.Name + self.translate('file_saved_successfully'));
            }
            blockui.unBlockModal();
          });
        } else {
          // todo error handling && process
          toastr.error(self.translate('file_is_not_suitable'));
          e.target.value = '';
          blockui.unBlockModal();
        }
      });
    },
    onSubmit(e) {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      blockui.blockModal(self);

      api.saveContractFile(self.selectedAttachment).then((res) => {
        if (res.body.IsSuccess) {
          toastr.success(self.translate('save_successfully'));
          self.$emit('onSubmit');
          self.closeModal();
        } else {
          toastr.error(res.body.Message);
        }
        blockui.unBlockModal();
      });
    },
    closeModal() {
      $('#add-attachments-modal').modal('toggle');
    },
  },
  created() {
    const self = this;

    blockui.blockModal(self);
    api.getContractFileType().then((response) => {
      self.attachmentsType = response.body;
      blockui.unBlockModal();
    });
  },
  computed: {
    selectedAttachmentType: {
      get() {
        const self = this;
        return self.selectGet('attachmentsType', 'selectedAttachment.TypeId', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('selectedAttachment.TypeId', 'Value', newValue);
      },
    },
  },
  watch: {
    attachment(newVal) {
      self.selectedAttachment = newVal;
    },
  },
};
