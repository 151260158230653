<template>
  <div class="row point" :class="{'show-required-warning':showError&&isInvalid}">
    <div class="col-md" style="position: relative">
      <pre>{{point}}</pre>
      <div class="form-group" v-if="point.QuestionTypeId == enumFormQuestionType.multipleChoice">
        <div class="py-2" v-for='(opt, i) in point.Selections' :key="i">
          <label class="m-radio m-radio--bold m-radio--state-brand text-inherit">
            <input type="radio" :name="'radio'" :id="'radio'+'_'+i"
                   :required="point.IsRequired" disabled>
            <input type="text" v-model="opt.Name">
            <span></span>
          </label>
        </div>
      </div>
      <!--<div class="form-group" v-if="point.QuestionTypeId == enumFormQuestionType.number">
        <input type="number" class="form-control" :required="point.IsRequired"
               :disabled="disabled" @input="inputHandler($event,point,currentSelect)"
               @keypress="numberInputKeypressHandler($event,false)">
      </div>
      <div class="form-group" v-if="point.QuestionTypeId == enumFormQuestionType.decimal">
        <input type="number" class="form-control" :required="point.IsRequired" step=".01"
               :disabled="disabled" @input="inputHandler($event,point,currentSelect)"
               @keypress="numberInputKeypressHandler($event,true)">
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as api from '../../../../scripts/services/api';
import { mixinz } from '../../../../scripts/global/mixinz';

export default {
  props: ['point', 'index', 'devices', 'selection', 'answerProcess', 'showError', 'isInvalid'],
  mixins: [mixinz],
  data() {
    return {
    };
  },
  methods: {
    numberInputKeypressHandler(e, allowDot) {
      e = (e) || window.event;
      if (e.key === 'e' || e.key === '+' || ((e.key === '.' || e.key === ',') && !allowDot)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    inputHandler(e, question, currentSelect) {
    },
    validateQuestionType(value, questionTypeId) {
      const regExps = {
        [this.enumFormQuestionType.decimal]: /[+-]?([0-9]*[.])?[0-9]+/,
        [this.enumFormQuestionType.number]: /[+-]?[0-9]+/,
      };
      const currentRegExp = regExps[questionTypeId];
      if (currentRegExp) {
        return this.validateRegExp(currentRegExp, value);
      }
    },
    validateRegExp(regExp, string) {
      return regExp.test(String(string).toLowerCase());
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isInt(value) {
      return !isNaN(value)
          && parseInt(Number(value)) == value
          && !isNaN(parseInt(value, 10));
    },
  },
  computed: {
    ...mapState({
      enumFormQuestionType: (state) => state.enums.formQuestionType,
    }),
    /* isEmpty() {
        const self = this;
        let question = self.question;
        let currentSelection = self.question.Answer;
        if (question.QuestionTypeId == self.enumFormQuestionType.multipleChoice) {
          return !(currentSelection > 0);
        } else if (question.QuestionTypeId == self.enumFormQuestionType.checkBox) {
          return !(currentSelection && currentSelection.length > 0);
        } else if (question.QuestionTypeId == self.enumFormQuestionType.number) {
          return !self.isInt(currentSelection) && !self.validateQuestionType(currentSelection, question.QuestionTypeId);
        } else if (question.QuestionTypeId == self.enumFormQuestionType.decimal) {
          return !self.isNumeric(currentSelection) && !self.validateQuestionType(currentSelection, question.QuestionTypeId);
        }
      }, */
  },
};
</script>
