<template>
  <div class="m-grid m-grid--hor m-grid--root m-page">
    <header-view v-show="!isFullScreen"/>
    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body"
         :class="{'full-screen':isFullScreen}">
      <sidebar-view v-show="!isFullScreen"/>
      <ejs-toast ref='toastRef' id='toast_type' :position='position'></ejs-toast>
      <div class="m-grid__item m-grid__item--fluid m-wrapper" style="position: relative">
        <div class="m-content" :class="{'full-screen':isFullScreen}">
          <div style="position: absolute;top:0;right:30px;">
            <a v-if="isFullScreen" @click="toggle" class="btn btn-xs pull-right">
              <span class="fa" :class="{'fa-expand':!isFullScreen, 'fa fa-compress':isFullScreen}"></span>
            </a>
          </div>
          <router-view :key="$route.fullPath"/>
        </div>
      </div>
    </div>
    <footer-view v-show="!isFullScreen"/>
    <!--<session-timeout-modal></session-timeout-modal>-->
    <change-password-modal/>
  </div>
</template>

<script>
import Vue from 'vue';
import { ToastPlugin, ToastCloseArgs } from '@syncfusion/ej2-vue-notifications';
import Header from './partials/Header';
import Sidebar from './partials/Sidebar';
import Footer from './partials/Footer';
// import SessionTimeoutModal from "./partials/modals/SessionTimeoutModal";
import ChangePasswordModal from './partials/modals/ChangePasswordModal';

Vue.use(ToastPlugin);

export default {
  components: {
    'header-view': Header,
    'sidebar-view': Sidebar,
    'footer-view': Footer,
    // "session-timeout-modal": SessionTimeoutModal,
    'change-password-modal': ChangePasswordModal,
  },
  mounted() {
    const self = this;
    mLayout.init();
    self.$eventHub.$on('toggleFullScreen', self.toggle);
    self.$eventHub.$on('toast', self.toast);
  },
  data() {
    return {
      position: { X: 'Right' },
      isFullScreen: false,
      toasts: [
        {
          name: 'warning', title: 'Warning!', cssClass: 'e-toast-warning', icon: 'e-warning toast-icons',
        },
        {
          name: 'success', title: 'Success!', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
        },
        {
          name: 'error', title: 'Error!', cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
        },
        {
          name: 'information', title: 'Information!', cssClass: 'e-toast-info', icon: 'e-info toast-icons',
        },
      ],
    };
  },
  methods: {
    toggle() {
      this.isFullScreen = !this.isFullScreen;
    },
    toast(toastArgs) {
      if (!toastArgs) {
        return;
      }
      let toastTemplate = {};
      if (toastArgs.type) {
        toastTemplate = this.toasts.find((x) => x.name == toastArgs.type);
      }
      if (toastArgs.title) {
        toastTemplate.title = toastArgs.title;
      }
      if (toastArgs.content) {
        toastTemplate.content = toastArgs.content;
      }

      this.$refs.toastRef.show(toastTemplate);
    },
  },
  beforeDestroy() {
    this.$eventHub.$off('toggleFullScreen');
    this.$eventHub.$off('toast');
  },
};
</script>
