
























































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import {CFMDTOToolSubType } from '@/scripts/services/ClientApiAuto'
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'


@Component<ToolSubTypeDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
    },
    validations() {
        return {
        toolSubType:{
            Name: {required},
        },
        selectedType: {
            TypeId: {required}
        },
        }
    },
})
export default class ToolSubTypeDialog extends CFMDialog {
    toolSubType: CFMDTOToolSubType = {Name: ''};
    typeList : {Name:String, Id:Number}[] = [];

    selectedType :CFMDTOToolSubType = {Name: ''}

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.toolSubType.toolSubTypeGetById({id: <number>this.id});
        this.toolSubType = res.data;
      }
      const promises = [
      this.getTypes()
      ];
      if(this.isUpdate){
        if(this.toolSubType && this.toolSubType.Type){
          this.selectedType = this.toolSubType;
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }
    
    async getTypes(){
      const response = await this.apiClient.toolType.toolTypeGetToolTypes();
      this.typeList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Id: this.toolSubType.Id,
        Name: this.toolSubType.Name,
        TypeId: this.selectedType.TypeId
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.toolSubType.toolSubTypeSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
