

































































































































import CFMDialog from '@/scripts/CFMDialog'
import { Component, Vue } from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import Summernote from '@/scripts/global/summernote'
import BootstrapSwitchComponent from "@/components/shared/BootstrapSwitchComponent";

import {required} from "vuelidate/lib/validators";

import { CFMDTORole } from '@/scripts/services/ClientApiAuto'

// RoleQualifications
// Children
// Predecessors
// Successors


@Component<UserRoleDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
        'summernote' : Summernote,
        'bootstrap-switch-component': BootstrapSwitchComponent,
    },
    validations() {
        return {
          userRole:{
              Name: {required},
          },
        }
    },
})
export default class UserRoleDialog extends CFMDialog {
    userRole: CFMDTORole = {Name: '', IsShowOnCalendar: true, IsShowOnOrganizationChart:true};
    roleList : {Name:string, Id:number}[] = [];
    qualificationList : {Name:string, Id:number}[] = [];

    multiSelectProps= {
        "close-on-select": false,
        "clear-on-select": false,
        "preserve-search": true,
    }

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.role.roleGetById({id: <number>this.id});
        this.userRole = res.data;
      }
      const promises = [
        this.getQualifications(),
        this.getRoles()
      ];
      await Promise.all(promises);
      blockui.unBlockModal();
    }

    get isUpdate() {
        return !!this.id;
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      
      let formData:CFMDTORole = {
        Id: this.userRole.Id,
        Name: this.userRole.Name,
        ParentId: this.userRole.ParentId,
        RoleQualifications: this.userRole.RoleQualifications,
        JobDescription: this.userRole.JobDescription,
        Predecessors: this.userRole.Predecessors,
        Successors: this.userRole.Successors,
        IsShowOnCalendar: this.userRole.IsShowOnCalendar,
        IsShowOnOrganizationChart: this.userRole.IsShowOnOrganizationChart,
      };

      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.role.roleSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }

    async getQualifications(){
      const response = await this.apiClient.qualification.qualificationGetQualifications();
      this.qualificationList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getRoles(){
      const response = await this.apiClient.role.roleGetRoles();
      this.roleList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
}
