import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as api from '../services/api';
import * as swal from '../../helpers/swal';
import * as blockui from '../../helpers/block-ui';
import Filters from '../../components/partials/Filters';
import WorkModal from '../../components/partials/modals/WorkModal';
import ProcedureModal from '../../components/partials/modals/ProcedureModal';
import * as dateFormat from '../../helpers/dateFormat';

export default {
  data() {
    return {
      showFilter: null,
      searchEquipments: '',
      filter: {
        massWorkOrderOperationsCapacityEquipments: false,
      },
      collapse: {
        equipments: false,
      },
      slc: {
        equipments: {},
      },
      showEquipmentModal: false,
      selectedEquipments: [],
      equipmentModalIsUpdate: false,
      pageNumber: 1,
      pageSize: 10,
      delta: 5,
      isShowModal: false,
      currentComponent: null,
      currentProperties: null,

      Id: null,
      Date: null,
      Description: null,

      search: '',
      isShowOnlySelected: false,
    };
  },
  props: ['isUpdate', 'massWorkOrderId', 'from'],
  components: {
    filters: Filters,
    'work-modal': WorkModal,
    'procedure-modal': ProcedureModal,
  },
  computed: {
    ...mapGetters({
      equipments: 'getMassWorkOrderOperationsCapacityEquipmentList',
    }),
    filteredEquipments() {
      const self = this;

      function searchObj(obj, query) {
        for (const key in obj) {
          const value = obj[key];
          if (typeof value === 'object') {
            return searchObj(value, query);
          }
          if (typeof value === 'string' && value.trim().toLocaleLowerCase(localStorage.getItem('vue-lang')).indexOf(query.trim().toLocaleLowerCase(localStorage.getItem('vue-lang'))) > -1) {
            return obj;
          }
        }
      }

      if (self.isShowOnlySelected) {
        if (!self.search && self.search.trim() === '') {
          return self.selectedEquipments.filter((equipment) => equipment.isChecked);
        }
        return self.selectedEquipments.filter((equipment) => equipment.isChecked && searchObj(equipment, self.search));
      } if (!self.search && self.search.trim() === '') {
        return self.equipments;
      }
      return self.equipments.filter((equipment) => searchObj(equipment, self.search));
    },
    isAllChecked() {
      if (this.filteredEquipments && this.filteredEquipments.length > 0) {
        return this.filteredEquipments.every((equipment) => equipment.isChecked === true);
      }
      return false;
    },
    selectedCount() {
      return this.selectedEquipments.length;
    },
    totalDuration() {
      let duration = 0;
      this.selectedEquipments.forEach((equipment) => {
        if (equipment.selectedWorkId > 0) {
          duration += equipment.Works.find((x) => x.Id == equipment.selectedWorkId).Duration;
        }
      });
      return dateFormat.formatMinute(duration, this);
    },
    pageCount() {
      const l = this.filteredEquipments.length;
      const s = this.pageSize;
      return Math.ceil(l / s);
    },
    paginatedData() {
      const start = (this.pageNumber - 1) * this.pageSize;
      const end = start + this.pageSize;

      return this.filteredEquipments.slice(start, end);
    },
    pagination() {
      const left = this.pageNumber - this.delta;
      const right = this.pageNumber + this.delta + 1;
      let result = [];
      result = Array.from({ length: this.pageCount }, (v, k) => k + 1)
        .filter((i) => i && i >= left && i < right);
      return result;
    },
  },
  methods: {
    setPage(page) {
      this.pageNumber = page;
    },
    firstPage(page) {
      this.pageNumber = 1;
    },
    lastPage(page) {
      this.pageNumber = this.pageCount;
    },
    nextPage() {
      this.pageNumber++;
    },
    prevPage() {
      this.pageNumber--;
    },
    filterItems(type, close) {
      if (close) {
        this.showFilter = null;
        Vue.set(this.filter, type, false);
      } else {
        this.showFilter = type;
        Vue.set(this.filter, type, true);
        this.$eventHub.$emit('loadFilterData', type);
        this.$nextTick(() => {
          mApp.initScrollers();
          // mApp.initTooltips();
        });
      }
    },
    showOnlySelected() {
      const self = this;
      self.pageNumber = 1;
      self.isShowOnlySelected = (!self.isShowOnlySelected);
    },
    setVendor(Id, vendorId) {
      const index = this.equipments.findIndex((equipment) => equipment.Id === Id);
      if (index >= 0) {
        const current = this.equipments[index];
        current.selectedVendorId = vendorId;
        Vue.set(this.equipments, index, current);
      }

      const i = this.selectedEquipments.findIndex((equipment) => equipment.Id === Id);
      if (i >= 0) {
        const currentSelectedEquipment = this.selectedEquipments[i];
        currentSelectedEquipment.selectedVendorId = vendorId;
        Vue.set(this.selectedEquipments, i, currentSelectedEquipment);
      }
    },
    setWork(Id, workId) {
      const index = this.equipments.findIndex((equipment) => equipment.Id === Id);
      if (index >= 0) {
        const current = this.equipments[index];
        current.selectedWorkId = workId;
        Vue.set(this.equipments, index, current);
      }

      const i = this.selectedEquipments.findIndex((equipment) => equipment.Id === Id);
      if (i >= 0) {
        const currentSelectedEquipment = this.selectedEquipments[i];
        currentSelectedEquipment.selectedWorkId = workId;
        Vue.set(this.selectedEquipments, i, currentSelectedEquipment);
      }
    },
    deleteMassWorkOrder(Id) {
      const self = this;
      let current;
      if (self.isShowOnlySelected) {
        const index = self.selectedEquipments.findIndex((equipment) => equipment.Id === Id);
        current = self.selectedEquipments[index];
      } else {
        const index = self.equipments.findIndex((equipment) => equipment.Id === Id);
        current = self.equipments[index];
      }

      swal.check(self, self.translate('maintenance_plan_of_device_name').replace('\{deviceName\}', current.DeviceName), self.translate('will_be_deleted_permanently')).then((e) => {
        if (e.value) {
          api.deleteWorkOrder(current.massWorkOrderId).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('delete_successfully'));
              current.selectedWorkId = undefined;
              current.selectedVendorId = undefined;
              current.isChecked = undefined;
              current.disabled = undefined;

              const i = self.equipments.findIndex((equipment) => equipment.Id === current.Id);
              if (i >= 0) {
                Vue.set(self.equipments, i, current);
              }

              const index = self.selectedEquipments.findIndex((equipment) => equipment.Id === current.Id);
              if (index >= 0) {
                self.selectedEquipments.splice(index, 1);
              }

              let tempEquipments = self.equipments;
              tempEquipments = self.sortCheckedOnes(tempEquipments);
              self.$store.commit('setMassWorkOrderOperationsCapacityEquipmentList', tempEquipments);
            } else {
              toastr.error(response.body.Message);
            }
          });
        }
      });
    },
    loadAfter() {
      this.$nextTick(() => {
        mApp.initScrollers();
        // mApp.initTooltips();
      });
    },
    checkClicked(Id) {
      const self = this;
      if (Id >= 0) {
        let current;
        if (self.isShowOnlySelected) {
          const index = self.selectedEquipments.findIndex((equipment) => equipment.Id === Id);
          current = self.selectedEquipments[index];
        } else {
          const index = self.equipments.findIndex((equipment) => equipment.Id === Id);
          current = self.equipments[index];
        }
        if (!current.disabled) {
          // only selected data
          if (current.isChecked) {
            const i = self.selectedEquipments.findIndex((equipment) => equipment.Id === current.Id);
            if (i >= 0) {
              self.selectedEquipments.splice(i, 1);
            }
          } else {
            self.selectedEquipments.push(current);
          }

          // all data
          const _index = self.equipments.findIndex((equipment) => equipment.Id === current.Id);
          current.isChecked = !current.isChecked;
          Vue.set(self.equipments, _index, current);
        } else if (current.isChecked) {
          self.deleteMassWorkOrder(Id);
        }
      } else {
        const { isAllChecked } = self;

        self.filteredEquipments.forEach((current) => {
          if (!current.disabled) {
            // only selected data
            if (isAllChecked) {
              const i = self.selectedEquipments.findIndex((equipment) => equipment.Id === current.Id);
              if (i >= 0) {
                self.selectedEquipments.splice(i, 1);
              }
            } else {
              const i = self.selectedEquipments.findIndex((equipment) => equipment.Id === current.Id);
              if (!(i >= 0)) {
                self.selectedEquipments.push(current);
              }
            }
            // all data
            const _index = self.equipments.findIndex((equipment) => equipment.Id === current.Id);
            if (_index >= 0) {
              current.isChecked = !isAllChecked;
              Vue.set(self.equipments, _index, current);
            }
          }
        });
      }
    },
    async loadCapacityEquipments(formData) {
      const self = this;

      blockui.blockElement(self, '.m-portlet');
      self.pageNumber = 1;
      await self.$store.dispatch('getMassWorkOrderCapacityEquipmentList', formData);

      let tempEquipments = self.equipments;

      if (self.selectedEquipments && self.selectedEquipments.length > 0) {
        self.selectedEquipments.forEach((selectedEquipment, i) => {
          const index = tempEquipments.findIndex((equipment) => equipment.Id === selectedEquipment.Id);

          if (formData.vendorId > 0) {
            if (!selectedEquipment.selectedVendorId && !selectedEquipment.isChecked) {
              self.selectedEquipments[i].selectedVendorId = formData.vendorId;
            }
          }

          if (index >= 0) {
            tempEquipments[index] = selectedEquipment;
          }
          // else de ekleyip flitrelemede olmasa bile görüntülenmesi sağlanabilir
        });
      }

      if (formData.vendorId > 0) {
        tempEquipments.forEach((tempEquipment, index) => {
          if (!tempEquipments[index].selectedVendorId && !tempEquipments[index].isChecked) {
            tempEquipments[index].selectedVendorId = formData.vendorId;
          }
        });
      } else {
        tempEquipments.forEach((tempEquipment, index) => {
          if (!tempEquipments[index].selectedVendorId && !tempEquipments[index].isChecked && tempEquipments[index].Vendors && tempEquipments[index].Vendors.length === 1 && !(self.massWorkOrderId >= 0)) {
            tempEquipments[index].selectedVendorId = tempEquipments[index].Vendors[0].Id;
          }
        });
      }

      tempEquipments = self.sortCheckedOnes(tempEquipments);
      self.$store.commit('setMassWorkOrderOperationsCapacityEquipmentList', tempEquipments);

      blockui.unBlockElement('.m-portlet');
      this.loadAfter();
    },
    openModal(param) {
      const self = this;
      self.currentComponent = param.component;
      self.currentProperties = param.parameter;
      self.isShowModal = true;
      self.$nextTick(() => {
        $(param.modal).modal({
          show: true,
          backdrop: 'static',
        });
      });
    },
    reloadWorks(equipmentId) {
      const self = this;
      const index = self.equipments.findIndex((equipment) => equipment.Id === equipmentId);
      const current = self.equipments[index];
      api.getWorkListWithBreadCrumb({ deviceId: equipmentId }).then((res) => {
        current.Works = res.body;
        Vue.set(self.equipments, index, current);
      });
    },
    save() {
      const self = this;
      const submitForm = $("form[name='submitForm']");
      if (submitForm.valid()) {
        swal.check(self, self.translate('are_you_sure'), self.translate('selected_only_save').formatString(self.selectedEquipments.length)).then((e) => {
          if (e.value) {
            blockui.blockElement(self, '.m-portlet');
            const formData = {};
            if (self.Id) {
              formData.Id = self.Id;
            }
            formData.Description = $(self.$refs.Description).val();
            const date = dateFormat.postDateFormat($(self.$refs.StartDate).val());

            try {
              formData.MassWorkOrders = [];
              self.selectedEquipments.forEach((equipment) => {
                if (!equipment.selectedWorkId) {
                  throw self.translate('you_must_select_work_for_all_checked_equipments');
                }
                formData.MassWorkOrders.push({
                  WorkId: equipment.selectedWorkId,
                  DeviceId: equipment.Id,
                  VendorId: equipment.selectedVendorId,
                  Date: date,
                  Id: equipment.massWorkOrderId > 0 ? equipment.massWorkOrderId : 0,
                });
              });
              if (formData.MassWorkOrders.length === 0) {
                throw self.translate('you_must_select_at_least_one_equipment');
              }
            } catch (e) {
              toastr.error(e);
              blockui.unBlockElement('.m-portlet');
              return;
            }

            api.saveMassMaintenancePlan(formData).then((response) => {
              blockui.unBlockElement('.m-portlet');
              if (response.body.IsSuccess) {
                toastr.success(self.translate('save_successfully'));
                self.$router.push({ name: 'MassMaintenancePlan' });
              } else {
                toastr.error(response.body.Message);
              }
            });
          }
        });
      }
    },
    goFrom() {
      const self = this;
      switch (self.from) {
        case 'MassMaintenancePlan':
          self.$router.push({ name: 'MassMaintenancePlan' });
          break;
        default:
          self.$router.push({ name: 'MassMaintenancePlan' });
          break;
      }
    },
    sortCheckedOnes(eq) {
      return eq.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1;
        } if (!a.isChecked && b.isChecked) {
          return 1;
        } if (a.isChecked && b.isChecked) {
          if (a.Id > b.Id) {
            return 1;
          }
          return -1;
        } if (!a.isChecked && !b.isChecked) {
          if (a.Id > b.Id) {
            return 1;
          }
          return -1;
        }
      });
    },
    setAllVendors(vendorId) {
      const self = this;
      /* if (!(vendorId > 0)) {
                   return
               } */

      if (self.selectedEquipments && self.selectedEquipments.length > 0) {
        self.selectedEquipments.forEach((selectedEquipment, i) => {
          if (!selectedEquipment.disabled) {
            if (selectedEquipment.Vendors.some((x) => x.Id == vendorId)) {
              const tempEquipment = selectedEquipment;
              tempEquipment.selectedVendorId = vendorId;
              Vue.set(self.selectedEquipments, i, tempEquipment);
            }
          }
        });
      }

      self.equipments.forEach((equipment, index) => {
        if (!equipment.disabled) {
          if (equipment.isChecked && (vendorId == '' || equipment.Vendors.some((x) => x.Id == vendorId))) {
            const tempEquipment = equipment;
            tempEquipment.selectedVendorId = vendorId;
            Vue.set(self.equipments, index, tempEquipment);
          }
        }
      });
    },
    /* setAllWorks(workId) {
          const self = this;
          if (!(workId > 0)) {
            workId = 0
          }

          if (self.selectedEquipments && self.selectedEquipments.length > 0) {
            self.selectedEquipments.forEach((selectedEquipment, i) => {
              if (selectedEquipment.Works.some(x => {
                return x.Id == workId;
              })) {
                let tempEquipment = selectedEquipment;
                tempEquipment.selectedWorkId = workId;
                Vue.set(self.selectedEquipments, i, tempEquipment);
              }
            });
          }

          self.equipments.forEach((equipment, index) => {
            if (equipment.Works.some(x => {
              return x.Id == workId;
            })) {
              let tempEquipment = equipment;
              tempEquipment.selectedWorkId = workId;
              Vue.set(self.equipments, index, tempEquipment);
            }
          });
        }, */
  },
  async mounted() {
    const self = this;
    window.$('form').validate();
    self.$eventHub.$on('open-modal', self.openModal);

    window.$('#scheduled_time').datepicker({
      todayHighlight: true,
      autoclose: true,
      language: localStorage.getItem('vue-lang'),
    });

    self.$store.dispatch('getTrainingTypeList');

    blockui.blockElement(self, '.m-portlet');
    self.pageNumber = 1;

    await self.$store.dispatch('getMassWorkOrderCapacityEquipmentList');
    if (self.massWorkOrderId >= 0) {
      const massMaintenancePlan = (await api.getMassMaintenancePlan(self.massWorkOrderId)).body;

      self.Id = massMaintenancePlan.Id;
      self.Date = massMaintenancePlan.Date;
      self.Description = massMaintenancePlan.Description;

      window.$('#scheduled_time').datepicker('update', new Date(self.Date));

      let tempEquipments = self.equipments;

      if (massMaintenancePlan.MassWorkOrders && massMaintenancePlan.MassWorkOrders.length > 0) {
        massMaintenancePlan.MassWorkOrders.forEach((massWorkOrder) => {
          const index = tempEquipments.findIndex((equipment) => equipment.Id === massWorkOrder.DeviceId);
          tempEquipments[index].isChecked = true;
          tempEquipments[index].Id = massWorkOrder.DeviceId;
          tempEquipments[index].selectedWorkId = massWorkOrder.WorkId;
          tempEquipments[index].selectedVendorId = massWorkOrder.VendorId;
          tempEquipments[index].massWorkOrderId = massWorkOrder.Id;
          tempEquipments[index].disabled = true;
          self.selectedEquipments.push(tempEquipments[index]);
        });
      }

      tempEquipments.forEach((tempEquipment, index) => {
        if (!tempEquipments[index].selectedVendorId && !tempEquipments[index].isChecked && tempEquipments[index].Vendors && tempEquipments[index].Vendors.length === 1 && !(self.massWorkOrderId >= 0)) {
          tempEquipments[index].selectedVendorId = tempEquipments[index].Vendors[0].Id;
        }
      });
      tempEquipments = self.sortCheckedOnes(tempEquipments);
      self.$store.commit('setMassWorkOrderOperationsCapacityEquipmentList', tempEquipments);
    }
    blockui.unBlockElement('.m-portlet');
    this.loadAfter();

    // self.loadCapacityEquipments();
  },
  watch: {
    pageSize(e) {
      this.pageNumber = 1;
    },
    search(newVal, oldVal) {
      if (newVal.trim().toLocaleLowerCase(localStorage.getItem('vue-lang')) != oldVal.trim().toLocaleLowerCase(localStorage.getItem('vue-lang'))) {
        this.pageNumber = 1;
      }
    },
  },
  beforeDestroy() {
    this.$eventHub.$off('open-modal');
  },
};
