import Vue from 'vue';

export default {
  props: {
    defaultTabId: {
      type: [Number, String, null],
      required: false,
      default: null,
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    children: [],
    activeTabIndex: -1,
    drag: false,
  }),
  computed: {
    tabCount() {
      return this.tabs.length;
    },
    tabs: {
      get() {
        return this.children.slice(1);
      },
      set(newVal) {
        this.children = [this.children[0], ...newVal];
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  created() {
    this.children = this.$children;
  },
  mounted() {
    if (this.tabs.length) {
      this.selectTab(this.defaultTabId ? this.defaultTabId : this.tabs[0].id);
    }
  },
  methods: {
    findTab(id) {
      return this.tabs.find((tab) => tab.id == id);
    },
    selectTab(id, event) {
      if (event) {
        event.preventDefault();
      }
      const selectedTab = this.findTab(id);
      if (!selectedTab) {
        return;
      }
      if (selectedTab.isDisabled) {
        event.preventDefault();
        return;
      }
      this.tabs.forEach((tab) => {
        this.setTabVisible(tab, (tab.id == selectedTab.id));
      });
      this.$emit('changed', { id: selectedTab.id });
      this.activeTabIndex = this.getTabIndex(id);
    },
    deleteItem(id, name) {
      this.$emit('delete', { id, name });
    },
    editItem(id) {
      this.$emit('edit', { id });
    },
    setTabVisible(tab, visible) {
      if (!tab) {
        return;
      }
      // tab.isVisible = visible;
      tab.isActive = visible;
      tab.isShowed = visible ? true : tab.isShowed;
      /* if (tab.isActive) {
                tab.isActive = visible;
                this.tabs.every((tab, index, array) => {
                    if (tab.isVisible) {
                        tab.isActive = true;
                        return false;
                    }
                    return true;
                });
            } */
    },
    getTabIndex(id) {
      const tab = this.findTab(id);
      return this.tabs.indexOf(tab);
    },
    getTabId(index) {
      const tab = this.tabs.find((tab) => this.tabs.indexOf(tab) === index);
      if (!tab) {
        return;
      }
      return tab.id;
    },
    getActiveTab() {
      return this.findTab(this.getTabId(this.activeTabIndex));
    },
    /* getActiveTabIndex() {
            return this.getTabIndex(this.activeTabHash);
        }, */
    isDeletable(deletable) {
      if (this.isFunction(deletable)) {
        return deletable();
      }
      return deletable;
    },
    isEditable(editable) {
      if (this.isFunction(editable)) {
        return editable();
      }
      return editable;
    },
    isFunction(functionToCheck) {
      return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    },
  },
  watch: {
    tabCount() {
      if (this.tabs.length && this.activeTabIndex < 0) {
        this.selectTab(this.defaultTabId ? this.defaultTabId : this.tabs[0].id);
      }
    },
  },
};
