import { render, staticRenderFns } from "./RadarChart2.vue?vue&type=template&id=239bcfd5&"
import script from "../../../scripts/partials/dashboard-charts/RadarChart2.js?vue&type=script&lang=js&"
export * from "../../../scripts/partials/dashboard-charts/RadarChart2.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports