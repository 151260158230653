import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../../services/api';
import * as blockui from '../../../helpers/block-ui';

export default {
  props: ['serviceProviderItem', 'isUpdate'],
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState({
      vendorList: (state) => state.vendor.vendorList,
      serviceProviderModalServiceProviderList: (state) => state.capacity_equipment.serviceProviderModalServiceProviderList,
    }),
    computedVendorList() {
      const self = this;
      const tempVendorList = self.vendorList;
      self.serviceProviderModalServiceProviderList.forEach((item) => {
        const index = tempVendorList.findIndex((x) => x.Value == item.VendorId);
        if (index >= 0) {
          tempVendorList.splice(index, 1);
        }
      });
      return tempVendorList;
    },
  },
  mounted() {
    const self = this;
    window.$('form').validate();

    self.$store.dispatch('getVendorList');
    self.$store.dispatch('getServiceProviderModalServiceProviderList', { deviceId: self.serviceProviderItem.DeviceId });

    $('#service_provider_item_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;

      self.$nextTick(() => {
        Vue.loadAsteriks();
      });

      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        api.saveServiceProvider(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            self.$store.dispatch('getServiceProviderList', { deviceId: self.serviceProviderItem.DeviceId });
            $('#service_provider_item_modal').modal('toggle');
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    closeModal() {
      $('#service_provider_item_modal').modal('toggle');
    },
  },
};
