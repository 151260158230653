import * as api from '../../scripts/services/api';

// initial state
const state = {
  suitableStatusList: [],
};

// getters
const getters = {};

// actions
const actions = {
  getSuitableStatusList({ commit }, data) {
    api.getWorkOrderSuitableStatuses(data).then((res) => {
      commit('setSuitableStatusList', res.body);
    });
  },
};

// mutations
const mutations = {
  setSuitableStatusList(state, suitable_status) {
    state.suitableStatusList = suitable_status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
