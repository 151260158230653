import * as api from '../../scripts/services/api';

// initial state
const state = {
  modelList: [],
  filterModelList: [],
};

// getters
const getters = {};

// actions
const actions = {
  getModelList({ commit }, data) {
    api.getModels(data).then((res) => {
      commit('setModelList', res.body);
    });
  },
  getFilterModelList({ commit }, data) {
    api.getModels(data).then((res) => {
      commit('setFilterModelList', res.body);
    });
  },
};

// mutations
const mutations = {
  setModelList(state, brands) {
    state.modelList = brands;
  },
  setFilterModelList(state, brands) {
    state.filterModelList = brands;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
