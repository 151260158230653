

































































import CFMDialog from '@/scripts/CFMDialog'
import {Component} from 'vue-property-decorator';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import {CFMDTOUnit } from '@/scripts/services/ClientApiAuto'
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'


@Component<UnitDialog>({
    components: {
        'cfm-multiselect': CFMMultiselect,
    },
    validations() {
        return {
        unit:{
            Name: {required},
            Sign: {required}
        },
        selectedType: {
            UnitTypeId: {required}
        },
        }
    },
})
export default class UnitDialog extends CFMDialog {
    unit: CFMDTOUnit = {Name: '',Sign:''};
    typeList : {Name:String, Id:Number}[] = [];

    selectedType :CFMDTOUnit = {Name: '',Sign:''}

    async created(){
      blockui.blockModal(this);
      if(this.isUpdate){
        const res = await this.apiClient.unit.unitGetById({id: <number>this.id});
        this.unit = res.data;
      }
      const promises = [
      this.getTypes()
      ];
      if(this.isUpdate){
        if(this.unit && this.unit.Name && this.unit.Sign){
          this.selectedType = this.unit;
        }
      }
      await Promise.all(promises);
      blockui.unBlockModal();
    }
//TODO ! unitTypesGetUnitTypes 
    async getTypes(){
      const response = await this.apiClient.unitType.unitTypeGetUnitTypes();
      this.typeList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Id: this.unit.Id,
        Name: this.unit.Name,
        Sign: this.unit.Sign,
        UnitTypeId: this.selectedType.UnitTypeId,
        IsActive: true
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.unit.unitSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
