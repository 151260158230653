import chart from './chart';
import * as swal from '../../../helpers/swal';
import * as api from '../../services/api';

export default {
  mixins: [chart],
  data() {
    return {
      data: [],
      color: [],
      pie: undefined,
    };
  },
  async mounted() {
    const self = this;
    await api.getCapacityEquipmentCountWithInfrastructure().then((res) => {
      self.capacityEquipments = res.body;
    });
    await api.getColors().then((response) => {
      self.color = response.body.Data;
    });
    let i = 9;
    self.capacityEquipments.forEach((capEq) => {
      if (i >= self.color.length - 1) i = 0;
      i++;
      self.data.push({
        label: capEq.Name,
        Id: capEq.Id,
        value: capEq.CapacityEquipmentCount,
        color: self.color[i].Code,
      });
    });
    self.initChart(self.data);
  },

  methods: {
    initChart(data) {
      const self = this;
      if ($(`#${self.chartId}`).length == 0) return;
      const config = {
        type: 'pie',
        data: {
          datasets: [{
            data: [],
            backgroundColor: [],
          }],
          labels: [],
        },
        options: {
          tooltips: {
            callbacks: {},
          },
          // responsive: true,
          legend: {
            position: 'right',
            labels: {
              usePointStyle: true,
            },
          },

          title: {
            display: true,
            text: `${self.translate('number_of_total_capacity_equipment')}: ${data.reduce((a, b) => a + b.value, 0)}`,
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          maintainAspectRatio: false,
        },
      };

      data.forEach((dataItem) => {
        config.data.datasets[0].data.push(dataItem.value);
        config.data.datasets[0].backgroundColor.push(dataItem.color);
        config.data.labels.push(`${dataItem.label} (${dataItem.value})`);
      });

      // let onClick = (isClickedLegend, e, item) => {
      //     let index;
      //     if ((!Array.isArray(item) && item) || (Array.isArray(item) && item.length > 0)) {
      //         if (isClickedLegend) {
      //             index = item.index;
      //         } else {
      //             index = item[0]._index;
      //         }
      //         if (data[index].value > 0) {
      //             swal.check(self, self.translate("are_you_sure"), self.translate("you_will_be_redirected_to_page").formatString(self.translate('work_orders'))).then(function (e) {
      //                 if (e.value) {
      //                     self.$router.push({name: 'WorkOrder', query: {workOrderStatusId: data[index].Id}});
      //                 }
      //             });
      //         } else {
      //             toastr.warning(self.translate('there_is_no_work_order_in_this_status'))
      //         }
      //     }
      // };

      // config.options.onClick = (e, item) => {
      //     onClick(false, e, item)
      // };

      config.options.tooltips.callbacks.label = (tooltipItem, data) => {
        let label = data.labels[tooltipItem.index] || '';
        if (label) label += ': ';
        const total = data.datasets[tooltipItem.datasetIndex].data.reduce((a, b) => a + b, 0);
        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return `${label}${((value / total) * 100).toFixed(2)}%`;
      };

      const ctx = $(`#${self.chartId}`);
      self.pie = new Chart(ctx, config);
    },
    redirect(orderStatusId) {
      this.$router.push({ name: 'WorkOrder', query: { workOrderStatusId: orderStatusId } });
    },
    redraw() {
      $(`#${this.chartId}`).removeAttr('width');
      $(`#${this.chartId}`).removeAttr('height');
      $(`#${this.chartId}`).html('');
      this.pie.destroy();
      this.initChart(this.data);
    },
  },
};
