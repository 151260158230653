import Vue from 'vue';
import grid from '../../../../../scripts/global/gridOld';
import * as api from '../../../../../scripts/services/api';
import * as blockui from '../../../../../helpers/block-ui';
import * as swal from '../../../../../helpers/swal';
import MethodAuthorizationModal from '../MethodAuthorizationModal/MethodAuthorizationModal.vue';
import { mixinz } from '../../../../../scripts/global/mixinz';

export default {
  mixins: [mixinz],
  data() {
    return {
      loading: [
        {
          Text: 'Loading',
          Value: 1,
        },
      ],
      isReady: false,
      isUpdate: false,
      securityItem: [],
      itemTypeName: [],
      filter: {},
      selectedFilter: [],
      securityItemList: [
        {
          Id: 648,
          Name: 'WorkOrderController.Save',
          Description: 'İş Emri kaydetme',
          IsUIItem: false,
          ItemType: [{
            Id: 3,
            Name: 'Server Side Method',
          }],
          ItemTypeId: 3,
        },
      ],
      // MOCK DATA
      securityItemListMock: [
        {
          Id: 648,
          Name: 'WorkOrderController.Save',
          Description: 'İş Emri kaydetme',
          IsUIItem: false,
          ItemType: [{
            Id: 3,
            Name: 'Server Side Method',
          }],
          ItemTypeId: 3,
        },
      ],
      ItemGroupList: [],
      selectedSecurityItem: {},
      selected: [],
      rows: [],
      selectedIndex: {},
      columns: [
        {
          field: 'Name',
          text: 'Name',
          width: 500,
          locked: true,
        }, {
          field: 'Description',
          text: 'Description',
          width: 500,
          locked: true,
        },
        {
          type: 'widget',
          groupable: false,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-edit',
            cls: 'b-blue',
            style: 'width:100%',
            onAction: ({ source }) => {
              this.goModal(source.cellInfo.record.Id);
            },
          }],
          field: 'edit',
          text: 'Edit',
          width: 250,
          locked: false,
        }, {
          type: 'widget',
          groupable: false,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-trash',
            cls: 'b-red',
            style: 'width:100%',
            onAction: ({ source }) => {
              // if(source.cellInfo.record.id.toString().startsWith('_generated')) return;
              const self = this;
              swal.check(self, source.cellInfo.record.Id).then((e) => {
                if (e.value) {
                  api.deleteSecurityItem(source.cellInfo.record.Id).then((response) => {
                    if (response.body.IsSuccess) {
                      toastr.success(self.translate('delete_successfully'));
                      const deleteIndex = self.rows.findIndex((item) => item.Id === source.cellInfo.record.Id);
                      self.rows.splice(deleteIndex, 1);
                    } else {
                      toastr.error(response.body.Message);
                    }
                  });
                }
              });
            },
          }],
          field: 'delete',
          text: 'Delete',
          width: 100,
          locked: false,
        }, {
          field: 'ItemGroupName',
          text: 'ItemGroup',
          width: 250,
          locked: true,
          hidden: true,
          tooltipRenderer: false,
        }],
      group: 'ItemGroupName',
    };
  },
  components: {
    grid,
    'method-authorization-modal': MethodAuthorizationModal,
  },
  methods: {
    selectFilter(e) {
      const self = this;
      self.$refs.grid.gridEngine.store.filters.clear();
      e && e.Text && self.$refs.grid.gridEngine.store.filter((x) => x.ItemGroupName === e.Text);
    },
    removeFilter() {
      const self = this;
      self.$refs.grid.gridEngine.store.clearFilters();
    },
    goModal(id) {
      const self = this;
      blockui.blockModal(self);

      api.getSecurityItem(id).then((res) => {
        const modalContainer = window.$('#method-authorization-modal');
        self.selectedSecurityItem = res.body;
        modalContainer.modal({
          backdrop: 'static',
          show: true,
        });
        modalContainer.find('form').data('type', 'update', 'selectedIndex');
        self.isUpdate = true;
        blockui.unBlockModal();
      });
    },
    newAuthorization() {
      const modalContainer = window.$('#method-authorization-modal');
      modalContainer.modal({
        backdrop: 'static',
        show: true,
      });
      self.selectedIndex = {};
      modalContainer.find('form').data('type', '', 'selectedIndex');
      self.isUpdate = false;
    },
  },
  async created() {
    const self = this;
    await api.getSecurityItemList().then((response) => {
      this.ItemGroupList = response.body;
    });

    this.ItemGroupList.forEach((Item) => {
      const row = {
        Name: Item.Name,
        Description: Item.Description,
        Id: Item.Id,
        ItemGroupName: Item.ItemType.Name,
        ItemGroupId: Item.ItemType.Id,
      };
      self.rows.push(row);
    });
    this.isReady = true;
  },
  mounted() {
    const self = this;
    const datatableContainer = '.method_authorization_list';

    api.getSecurityItemTypes().then((response) => {
      this.itemTypeName = response.body;
    });
  },
};
