module.exports = {

  template: '<input ref="checkbox" data-size="small" type="checkbox"/>',

  props: {
    value: {
      required: false,
      default: false,
      type: [Boolean],
    },
    onText: {
      required: true,
      type: String,
    },
    offText: {
      required: true,
      type: String,
    },
  },

  mounted() {
    const vm = this;
    $(vm.$el).bootstrapSwitch({
      onText: vm.onText,
      offText: vm.offText,
    });
    $(vm.$el).bootstrapSwitch('state', vm.value, vm.value);
    $(vm.$el).on('switchChange.bootstrapSwitch', (e, state) => {
      vm.$emit('input', state);
      vm.$emit('change', { event: e, state });
    });
  },

  watch: {
    value(newValue) {
      $(this.$el).bootstrapSwitch('state', newValue, newValue);
    },
  },

};
