import { mapState, mapActions } from 'vuex';
import * as Sentry from '@sentry/browser';
import {
  maxLength, minLength, minValue, required, requiredIf,
} from 'vuelidate/lib/validators';
import * as api from './services/api';
import Select2 from '../components/shared/select2';
import { mixinz } from './global/mixinz';
import * as blockui from '../helpers/block-ui';

export default {
  name: 'LoginLayout',
  mixins: [mixinz],
  validations() {
    return {
      loginDto: {
        language: {
          required,
        },
        username: {
          required,
        },
        password: {
          required,
        },
      },
      captchaValue: {
        requiredIf: requiredIf(() => this.isCaptchaRequired),
      },
    };
  },
  data() {
    return {
      showAlert: false,
      alertMessage: '',
      loginDto: {
        language: localStorage.getItem('vue-lang') ? localStorage.getItem('vue-lang') : this.defaultLanguageValue,
        username: '',
        password: '',
        rememberMe: false,
        grant_type: 'password',
        captcha: null,
        captchaToken: null,
      },
      captchaToken: null,
      captchaValue: null,
      captchaBase64Image: null,
      defaultLanguageValue: 'tr-TR',
    };
  },
  computed: {
    ...mapState({
      languages: (state) => state.languages.languages,
    }),
    selectedLanguage: {
      get() {
        const self = this;
        return self.selectGet('languages', 'loginDto.language', 'Value', 'Text');
      },
      set(newValue) {
        const self = this;
        return self.selectSet('loginDto.language', 'Value', newValue);
      },
    },
    isCaptchaRequired() {
      return this.captchaToken != null && this.captchaBase64Image != null;
    },
  },
  components: { select2: Select2 },
  async mounted() {
    $('#m_login').css('min-height', $(window).height());

    $(window).resize(() => {
      $('#m_login').css('min-height', $(window).height());
    });

    $('#m_login_forget_password').click((e) => {
      e.preventDefault(), s.removeClass('m-login--signin'), s.removeClass('m-login--signup'), s.addClass('m-login--forget-password'), mUtil.animateClass(s.find('.m-login__forget-password')[0], 'flipInX animated');
    }), $('#m_login_forget_password_cancel').click((e) => {
      e.preventDefault(), o();
    }), $('#m_login_signup').click((e) => {
      e.preventDefault(), s.removeClass('m-login--forget-password'), s.removeClass('m-login--signin'), s.addClass('m-login--signup'), mUtil.animateClass(s.find('.m-login__signup')[0], 'flipInX animated');
    }), $('#m_login_signup_cancel').click((e) => {
      e.preventDefault(), o();
    });

    const s = $('#m_login');
    const n = function (e, i, a) {
      const l = $(`<div class="m-alert m-alert--outline alert alert-${i} alert-dismissible" role="alert">\t\t\t<button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>\t\t\t<span></span>\t\t</div>`);
      e.find('.alert').remove(), l.prependTo(e), mUtil.animateClass(l[0], 'fadeIn animated'), l.find('span').html(a);
    };
    const o = function () {
      const form = $('.forget-password-form');
      form[0].reset();
      const validator = form.validate();
      validator.resetForm();
      s.removeClass('m-login--forget-password'), s.removeClass('m-login--signup'), s.addClass('m-login--signin'), mUtil.animateClass(s.find('.m-login__signin')[0], 'flipInX animated');
    };

    blockui.pageBlock();
    const promises = [
      this.getLanguagesList(),
      this.getCapctcha(),
    ];
    await Promise.all(promises);
    blockui.unBlockPage();
  },
  methods: {
    ...mapActions({
      getLanguagesList: 'getLanguagesList',
    }),
    async getCapctcha() {
      const result = await api.getCapctcha();

      if (result.body == '') {
        this.captchaToken = null;
        this.captchaValue = null;
        this.captchaBase64Image = null;
        return;
      }

      this.captchaToken = result.body.Token;
      this.captchaBase64Image = result.body.Base64Image;
      this.captchaValue = null;
    },
    async onSubmit(e) {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const btn = $(this.$refs.signinSubmitButton);
      btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

      if (self.isCaptchaRequired) {
        self.loginDto.captcha = self.captchaValue;
        self.loginDto.captchaToken = self.captchaToken;
      } else {
        self.loginDto.captcha = null;
        self.loginDto.captchaToken = null;
      }

      api.login(self.loginDto).then(await async function (response) {
        if (response.ok) {
          localStorage.setItem('vue-lang', self.loginDto.language);

          // localStorage.setItem('token', response.body.access_token);
          // localStorage.setItem('tokenExpireDate', (Date.now() + (response.body.expires_in * 1000)).toString());
          // localStorage.setItem('tokenType', response.body.token_type);
          if (self.loginDto.rememberMe) {
            self.$cookie.set('token', response.body.Token, { expires: new Date(response.body.ExpireDate) });
            self.$cookie.set('tokenType', response.body.TokenType, { expires: new Date(response.body.ExpireDate) });
          } else {
            self.$cookie.set('token', response.body.Token);
            self.$cookie.set('tokenType', response.body.TokenType);
          }

          localStorage.setItem('menuItem', response.body.MenuItems);
          localStorage.setItem('urlItem', response.body.UrlItems);
          localStorage.setItem('serverSideItem', response.body.ServerSideItems);
          localStorage.setItem('clientSideItem', response.body.ClientSideItems);
          localStorage.setItem('userId', response.body.UserId);
          localStorage.setItem('userName', response.body.NameSurname);
          localStorage.setItem('eMail', response.body.Email);
          localStorage.setItem('company', JSON.stringify(response.body.Company));

          // Redirecting
          if (self.$route.query.redirect != null) {
            self.$router.replace(`/${self.$route.query.redirect}`);
          } else {
            self.$router.replace('/');
          }
        } else {
          btn.removeClass('m-loader m-loader--right m-loader--light').removeAttr('disabled');
          self.alertMessage = response.body.error_description;
          self.showAlert = true;
          blockui.pageBlock();
          self.getCapctcha();
          blockui.unBlockPage();
        }
      },
      (err) => {
        btn.removeClass('m-loader m-loader--right m-loader--light').removeAttr('disabled');
        blockui.pageBlock();
        self.getCapctcha();
        blockui.unBlockPage();
      });
    },
    sendPasswordEmail(e) {
      const form = $(e.target);
      if (form.valid()) {
        const btn = window.$('#m_login_forget_password_submit');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

        const email = form.find('input').val();
        api.sendForgotPasswordEmail({ Email: email }).then((res) => {
          if (res.body.IsSuccess) {
            toastr.success(this.translate('password_reset_email_sent'));
            this.forgotPasswordCancel();
          } else {
            toastr.warning(res.body.Message);
          }
          btn.removeClass('m-loader m-loader--right m-loader--light').removeAttr('disabled');
        }, (err) => {
          btn.removeClass('m-loader m-loader--right m-loader--light').removeAttr('disabled');
          this.alertMessage = err.body.error_description ? err.body.error_description : 'Error!';
          this.showAlert = true;
        });
      }
    },
    forgotPasswordCancel() {
      const s = $('#m_login');
      const form = $('.forget-password-form');
      form[0].reset();
      const validator = form.validate();
      validator.resetForm();
      s.removeClass('m-login--forget-password'), s.removeClass('m-login--signup'), s.addClass('m-login--signin'), mUtil.animateClass(s.find('.m-login__signin')[0], 'flipInX animated');
    },
    onSelectedLanguageChanged(e) {
      this.language = e.Value;
    },
  },
};
