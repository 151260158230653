import Vue from 'vue';
import { mixinz } from '../../../../../scripts/global/mixinz';

export default {
  mixins: [mixinz],
  props: ['tool'],
  components: {},
  data() {
    return {
      toolEntry: {},
    };
  },
  created() {
  },
  mounted() {
    this.toolEntry = this.tool;
  },
  computed: {
    nextCalibrationDate: {
      get() {
        if (this.toolEntry.NextCalibrationDate) {
          return moment(this.toolEntry.NextCalibrationDate).format('DD.MM.YYYY');
        }
        return null;
      },
      set(val) {
        if (val) {
          Vue.set(this.toolEntry, 'NextCalibrationDate', moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD'));
        } else {
          Vue.set(this.toolEntry, 'NextCalibrationDate', val);
        }
        return val;
      },
    },
    nextMaintenanceDate: {
      get() {
        if (this.toolEntry.NextMaintenanceDate) {
          return moment(this.toolEntry.NextMaintenanceDate).format('DD.MM.YYYY');
        }
        return null;
      },
      set(val) {
        if (val) {
          Vue.set(this.toolEntry, 'NextMaintenanceDate', moment(val, 'DD.MM.YYYY').format('YYYY-MM-DD'));
        } else {
          Vue.set(this.toolEntry, 'NextMaintenanceDate', val);
        }
        return val;
      },
    },
  },
  methods: {
    isNeedCalibrationChanged() {
      if (!this.toolEntry.IsNeedCalibration) {
        this.nextCalibrationDate = null;
      }
    },
    isNeedPeriodicMaintenanceChanged() {
      if (!this.toolEntry.IsNeedPeriodicMaintenance) {
        this.nextMaintenanceDate = null;
      }
    },
    /* async onSubmit() {
            let self = this;
            //TODO validation
            if (true) {
                try {
                    blockui.blockModalPromise(self, "#tool_modal");
                    const result = await api.saveTool({tool: self.toolEntry});
                    if (!result.body.IsSuccess) {
                        throw result.body.Message;
                    }
                    self.$emit('updated');
                    toastr.success(self.translate('save_successfully'));
                } catch (err) {
                    toastr.error(err);
                    throw new Error(err);
                } finally {
                    blockui.unBlockModal();
                }
            }
        }, */
  },
  watch: {
    tool(newVal) {
      this.toolEntry = newVal;
    },
    toolEntry: {
      handler(newVal) {
        this.$emit('updated');
      },
      deep: true,
    },
  },
};
