import * as api from '../../scripts/services/api';

// initial state
const state = {
  netAvailableWorkHours: {},
  turnOvers: [],
  coveragePosition: {},
  averageTurnOver: 0,
  hourlyProcessingCapability: {},
  volumeInfluencedPosition: {},
};

// getters
const getters = {};

// actions
const actions = {
  async getNetAvailableWorkHours({ commit }) {
    await api.getNetAvailableWorkHour().then((res) => {
      commit('setNetAvailableWorkHours', res.body.Data);
    });
  },
  async getTurnOvers({ commit }, data) {
    await api.getTurnOvers(data).then((res) => {
      commit('setTurnOvers', res.body.Data);
    });
  },
  async getAverageTurnOver({ commit }) {
    await api.getAverageTurnOver().then((res) => {
      commit('setAverageTurnOver', res.body.Data);
    });
  },
  async getCoveragePosition({ commit }) {
    await api.getCoveragePosition().then((res) => {
      commit('setCoveragePosition', res.body.Data);
    });
  },
  async getHourlyProcessingCapability({ commit }) {
    await api.getHourlyProcessingCapability().then((res) => {
      commit('setHourlyProcessingCapability', res.body.Data);
    });
  },
  async getVolumeInfluencedPosition({ commit }) {
    await api.getVolumeInfluencedPosition().then((res) => {
      commit('setVolumeInfluencedPosition', res.body.Data);
    });
  },
};

// mutations
const mutations = {
  setNetAvailableWorkHours(state, netAvailableWorkHours) {
    if (netAvailableWorkHours) {
      state.netAvailableWorkHours = netAvailableWorkHours;
    } else {
      state.netAvailableWorkHours = {};
    }
  },
  setTurnOvers(state, turnOvers) {
    state.turnOvers = turnOvers;
  },
  setAverageTurnOver(state, averageTurnOver) {
    state.averageTurnOver = averageTurnOver;
  },
  setCoveragePosition(state, coveragePosition) {
    if (coveragePosition) {
      state.coveragePosition = coveragePosition;
    } else {
      state.coveragePosition = {};
    }
  },
  setHourlyProcessingCapability(state, hourlyProcessingCapability) {
    if (hourlyProcessingCapability) {
      state.hourlyProcessingCapability = hourlyProcessingCapability;
    } else {
      state.hourlyProcessingCapability = {};
    }
  },
  setVolumeInfluencedPosition(state, volumeInfluencedPosition) {
    if (volumeInfluencedPosition) {
      state.volumeInfluencedPosition = volumeInfluencedPosition;
    } else {
      state.volumeInfluencedPosition = {};
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
