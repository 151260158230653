<template>
    <div class="m-portlet m-portlet--mobile">
        <div class="card">
            <div class="card-header" id="collapseAttachmentsHeader">
                <div class="attachments-grid"></div>
                &nbsp<div class="card-title" data-toggle="collapse" data-target="#collapseAttachments" aria-expanded="true"
                          aria-controls="collapseOne1" v-lang.contract_attachments>
            </div>
            </div>
            <div id="collapseAttachments" class="collapse show" aria-labelledby="collapseAttachmentsHeader"
                 style="">

                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <grid
                                    ref="grid"
                                    sort="name"
                                    :data="rows"
                                    @grid="initGrid"
                                    :columns="columns"
                                    :cellEditFeature="false"
                                    :height="null"
                                    :autoHeight="true"
                                    :name="translate('contract_attachments')"
                            >
                            </grid>
                            <add-attachments-modal :isUpdate="isUpdate"
                                                   :contractId="contractId"
                                                   :attachment="selectedAttachment"
                                                   @onSubmit="onSubmit"
                                                   v-if="showAddAttachmentsModal"
                                                   @close="showAddAttachmentsModal=false"></add-attachments-modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--    <div class="m-portlet m-portlet&#45;&#45;mobile ">-->
    <!--        <div class="m-portlet__head">-->
    <!--            <div class="m-portlet__head-caption ">-->
    <!--                <div class="m-portlet__head-title ">-->
    <!--                    <div class="attachments-grid"></div>-->
    <!--                    &nbsp<h3 class="m-portlet__head-text" v-lang.contract_attachments></h3>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <grid-->
    <!--                ref="grid"-->
    <!--                sort="name"-->
    <!--                :data="rows"-->
    <!--                @grid="initGrid"-->
    <!--                :columns="columns"-->
    <!--                :cellEditFeature="false"-->
    <!--        >-->
    <!--        </grid>-->
    <!--        <add-attachments-modal :isUpdate="isUpdate"-->
    <!--                               :contractId="contractId"-->
    <!--                               :attachment="selectedAttachment"-->
    <!--                               @onSubmit="onSubmit"-->
    <!--                               v-if="showAddAttachmentsModal"-->
    <!--                               @close="showAddAttachmentsModal=false"></add-attachments-modal>-->
    <!--    </div>-->
</template>

<script>
import grid from '../../../scripts/global/gridOld.vue';
import * as api from '../../../scripts/services/api';
import * as swal from '../../../helpers/swal';
import AddAttachmentsModal from './AddAttachmentsModal.vue';
import * as blockui from '../../../helpers/block-ui';
import { baseUrl } from '../../../scripts/services/api';

export default {
  // props: {field: String, value: String, store: Object},
  props: ['contractId', 'IsEditable'],
  components: {
    grid, AddAttachmentsModal,
  },
  data() {
    return {
      selectedAttachment: {},
      showAddAttachmentsModal: false,
      currentPopup: null,
      widgetHelper: bryntum.scheduler.WidgetHelper,
      contractList: [],
      rows: [],
      isUpdate: false,
      columns: [
        {
          text: 'Description',
          field: 'Description',
          cellCls: 'decription',
          locked: true,
          width: 444,
          headerRenderer: ({ column }) => `<label>${column.text}</label>`,
          htmlEncode: false,
          renderer: ({ record, value }) => value, // ? value : `<a style="color: grey">Bir Açıklama Giriniz</a>`,
          // widgets: [{
          //     type: 'text',
          //     field:'description',
          //     onAction: ({value,source}) => {
          //
          //         let record = source.cellInfo.record;
          //             record.description =value;
          //             record.data.description = value;
          //
          //     }
          // }]
        },
        {
          text: 'Type',
          field: 'ContractFileType',
          locked: true,
          width: 230,
          // headerRenderer: ({column}) => {
          //     return `<label>${column.text}</label>`;
          // },
          htmlEncode: false,
        },
        // {
        //     text: 'File Upload',
        //     type: 'widget',
        //     groupable: false,
        //     widgets: [{
        //         type: 'button',
        //         icon: 'b-fa b-fa-upload',
        //         cls: 'b-blue',
        //         style: 'width:100%',
        //         onAction: ({source}) => this.openTooltip(source)
        //     }]
        // },
        {
          type: 'widget',
          groupable: false,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-download',
            cls: 'b-blue',
            onAction: ({ source }) => {
              source.cellInfo.record.FileId && this.downloadFile(source.cellInfo.record.FileId);
            },
          }],
          field: 'Name',
          text: 'File Download',
          // width:650,
          flex: 1,
          locked: false,
        },
        {
          text: 'Edit',
          id: 'grid-contract-attachment-edit-button',
          type: 'widget',
          hidden: !this.IsEditable,
          groupable: false,
          width: 100,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-edit',
            cls: 'b-blue',
            style: 'width:100%',
            onAction: async ({ source }) => {
              const self = this;
              self.isUpdate = true;
              blockui.pageBlock(self);
              await api.getContractFile(source.cellInfo.record.Id).then((res) => {
                self.selectedAttachment = res.body;
                self.showAddAttachmentsModal = true;
                self.$nextTick(() => {
                  const modalContainer = window.$('#add-attachments-modal');
                  modalContainer.modal({
                    backdrop: 'static',
                    show: true,
                  });
                });
              });
              blockui.unBlockPage();
            },
          }],
        },
        {
          type: 'widget',
          groupable: false,
          hidden: !this.IsEditable,
          width: 100,
          widgets: [{
            type: 'button',
            icon: 'b-fa b-fa-trash',
            cls: 'b-red',
            style: 'width:100%',
            onAction: ({ source }) => {
              const self = this;
              blockui.pageBlock(self);
              swal.check(self, source.cellInfo.record.data.Id).then((e) => {
                if (e.value) {
                  api.deleteContractFile(source.cellInfo.record.data.Id).then((res) => {
                    if (res.body.IsSuccess) {
                      self.$refs.grid.gridEngine.store.remove(source.cellInfo.record.data.id);
                      toastr.success(self.translate('delete_successfully'));
                    } else {
                      toastr.error(res.body.Message);
                    }
                    blockui.unBlockPage();
                  });
                } else {
                  blockui.unBlockPage();
                }
              });
            },
          }],
          field: 'delete',
          text: 'Delete',
          locked: false,
        },
        // {
        //     type: 'widget',
        //     groupable: false,
        //     widgets: [{
        //         type: 'button',
        //         icon: 'b-fa b-fa-save',
        //         cls: 'b-blue',
        //         style: 'width:100%',
        //         onAction: ({source}) => {
        //            this.saveAction(source.cellInfo.record)
        //         }
        //     }],
        //     field: 'save',
        //     text: 'Save',
        //     locked: false
        // },
      ],
    };
  },
  methods: {
    // saveAction(row){
    //     const self = this;
    //     if(row.Description && row.FileId)
    //     {
    //         self.$refs.grid.gridEngine.scrollRowIntoView(row, {
    //             block: 'center',
    //             highlight: true,
    //             animate: true
    //         });
    //     }
    // },
    // finishCellEdit(event) {
    //     const self = this;
    //     self.$refs.grid.gridEngine.renderContents();
    // },
    initGrid() {
      const self = this;
      // self.$refs.grid.gridEngine.on('finishCellEdit', self.finishCellEdit);
      self.$refs.grid.gridEngine.renderContents();

      const oldAddRowButton = self.widgetHelper.getById('addRow');
      if (oldAddRowButton) {
        oldAddRowButton.destroy();
      }
      const gridButtons = [{
        id: 'addRow',
        type: 'button',
        cls: 'b-raised b-blue',
        // text:self.translate('add_file'),
        icon: 'b-fa b-fa-plus',
        // badge: null,
        tooltip: self.translate('add_attachment'),
        onAction: function onAction(source) {
          // self.$refs.grid.gridEngine.store.add({});
          $('#collapseAttachments').collapse('show');
          self.isUpdate = false;
          self.addItem();
        },
      }, {
        type: 'button',
        margin: 10,
        cls: 'b-raised b-blue',
        icon: 'b-fa b-fa-sync',
        onAction: () => {
          this.fetchData();
        },
      }];
      // self.$refs.grid.gridEngine.store.on('change', ({action}) => {
      //     if (action === "add" || action === "remove") {
      //         self.widgetHelper.getById('addRow').badge = self.$refs.grid.gridEngine.store.added.values.length || null;
      //     }
      // });
      self.widgetHelper.append({
        type: 'container',
        widgets: gridButtons,
        cls: `pull-left${self.IsEditable ? '' : ' d-none'}`,
      }, { insertFirst: document.querySelector('.attachments-grid') });
    },
    downloadFile(id) {
      const self = this;
      bryntum.scheduler.AjaxHelper.get(`${baseUrl}/File/GetFile`, {
        parseJson: true,
        queryParams: {
          id,
          isContainsData: true,
        },
      }).then((response) => {
        const data = response.parsedJson.Data;
        self.downloadBlob(self.base64toBlob(data.Base64, data.ContentType), data.Name);
      });
    },
    base64toBlob(base64Data, type) {
      type = type || '';
      // Convert the Base64 string back to text.
      const byteString = atob(base64Data);

      // Convert that text into a byte array.
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type });
    },
    downloadBlob(blob, fileName) {
      const a = $("<a style='display: none;'/>");
      const url = window.URL.createObjectURL(blob);
      a.attr('href', url);
      a.attr('download', fileName);
      $('body').append(a);
      a[0].click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    // openTooltip(source) {
    //     const self = this;
    //     this.currentPopup = source.popup = Editor({
    //         type: 'toolTip',
    //         onPaint: async (src) => {
    //             src.html = `<div class="input-group">
    //                         <div class="input-group-prepend">
    //                         <span class="input-group-text" id="inputGroupFileAddon01">Upload</span>
    //                         </div>
    //                         <div class="custom-file">
    //                         <input id="uploader" type="file" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
    //                         <label class="custom-file-label" for="inputGroupFile01">${source.cellInfo.record.FileName ? source.cellInfo.record.FileName :'Bir Dosya Seçiniz'}</label></div></div>`;
    //
    //             src.title = source.cellInfo.record.FileName ? source.cellInfo.record.FileName :'Choose File';
    //         },
    //     }, source.popup);
    //
    //     $('#uploader').change(function (e) {
    //         blockui.blockElementPromise(self, '.b-popup');
    //         blockui.pageBlock(self);
    //
    //         const files = this.files;
    //         const fileSize = ((files[0].size / 1024) / 1024).toFixed(4);
    //         const extension = files[0].name.split('.').pop().toLowerCase();
    //
    //         let acceptedExtensions = [];
    //         bryntum.scheduler.AjaxHelper.get(process.env.VUE_APP_API_BASE_URL + '/FileType/GetList', {
    //             parseJson: true,
    //         }).then(async response => {
    //             let data = response.parsedJson.Data;
    //             acceptedExtensions = data.map(x => x.Extension);
    //             if (typeof files[0].name != "undefined" && fileSize <= 20 && acceptedExtensions.includes(extension)) { //static file
    //                 let formData = new FormData();
    //                 formData.append('File', files[0]);
    //                 bryntum.scheduler.AjaxHelper.post(process.env.VUE_APP_API_BASE_URL + '/File/SaveFile', formData, {
    //                     parseJson: true,
    //                     headers: {
    //                         'Accept': 'application/json',
    //                         'Authorization': getCookieByName('tokenType') + " " + getCookieByName('token'),
    //                         'Culture': localStorage.getItem('vue-lang')
    //                     }
    //                 }).then(response => {
    //                     let data = response.parsedJson;
    //                     let fileId = parseInt(data.Message);
    //                     let done = Number.isInteger(fileId);
    //                     let record = source.cellInfo.record;
    //                     toastr.success(self.translate('save_successfully'));
    //                     if (done) {
    //                         record.File = record.File || {};
    //                         record.File.Name = record.FileName = files[0].name;
    //                         record.data.FileName = files[0].name;
    //                         record.FileId = fileId;
    //                         source.popup && source.popup.hide();
    //                     }
    //                     self.$refs.grid.gridEngine.renderContents();
    //                     blockui.unBlockElement('.b-popup');
    //                     blockui.unBlockPage(self)
    //                 });
    //             } else {
    //                 //todo error handling && process
    //                 toastr.error(self.translate('file_is_not_suitable'));
    //                 e.target.value = '';
    //                 blockui.unBlockElement('.b-popup');
    //                 blockui.unBlockPage(self)
    //             }
    //         });
    //     });
    // },
    addItem() {
      this.selectedAttachment = {
        ContractId: this.contractId,
      };
      this.showAddAttachmentsModal = true;
      this.$nextTick(() => {
        const modalContainer = window.$('#add-attachments-modal');
        modalContainer.modal({
          backdrop: 'static',
          show: true,
        });
      });
    },
    onSubmit() {
      const self = this;
      blockui.pageBlock(self);
      this.fetchData();
      self.$refs.grid.gridEngine.renderContents();
      blockui.unBlockPage();
    },
    fetchData() {
      const self = this;
      if (self.contractId) {
        api.getContractFileListByContractId(self.contractId).then((res) => {
          self.rows = res.body;
        });
      }
    },
  },
  async created() {
    const self = this;
    self.fetchData();
  },
  beforeDestroy() {
    // if (this.currentPopup) {
    //     this.currentPopup.hide()
    // }
  },
};
</script>
