import Vue from 'vue';
import * as api from '../services/api';
import * as swal from '../../helpers/swal';
import * as blockui from '../../helpers/block-ui';
import grid from '../global/gridOld';
import ScheduleModal from '../../components/partials/modals/ScheduleModal';

export default {
  data() {
    return {
      gridEngine: undefined,
      data: [],
      columns: [{
        type: 'rownumber',
        field: 'rownumber',
        locked: true,
      },
      {
        type: 'widget',
        groupable: false,
        widgets: [{
          type: 'button',
          icon: 'b-fa b-fa-edit',
          cls: 'b-blue',
          style: 'width:100%',
          onAction: ({ source }) => {
            const self = this;
            blockui.blockModal(self);
            Vue.set(self, 'selectedSchedule', {});
            self.isUpdate = true;
            api.getSchedule(source.cellInfo.record.id).then((res) => {
              Vue.set(self, 'selectedSchedule', res.body);
              const modalContainer = window.$('#schedule_modal');
              modalContainer.modal({
                backdrop: 'static',
                show: true,
              });
              modalContainer.find('form').data('type', 'update');
              blockui.unBlockModal();
            });
          },
        }],
        field: 'edit',
        text: 'Edit',
        width: 90,
        locked: true,
      },
      {
        type: 'widget',
        groupable: false,
        widgets: [{
          type: 'button',
          icon: 'b-fa b-fa-trash',
          cls: 'b-red',
          style: 'width:100%',
          onAction: ({ source }) => {
            const self = this;
            swal.check(self, source.cellInfo.record.schedule).then((e) => {
              if (e.value) {
                api.deleteSchedule(source.cellInfo.record.id).then((response) => {
                  if (response.body.IsSuccess) {
                    toastr.success(self.translate('delete_successfully'));
                    self.data = self.data.filter((x) => x.id != source.cellInfo.record.id);
                  } else {
                    toastr.error(response.body.Message);
                  }
                });
              }
            });
          },
        }],
        field: 'delete',
        text: 'Delete',
        width: 90,
        locked: true,
      },
      {
        type: 'number',
        field: 'id',
        text: 'Id',
        width: 90,
      },
      {
        field: 'schedule',
        text: 'Schedule',
        width: 220,
      }],
      selectedSchedule: {},
      isUpdate: false,
    };
  },
  components: {
    grid,
    'schedule-modal': ScheduleModal,
  },
  async created() {
    const self = this;
    blockui.pageBlock(self);
    const promises = [
      self.getResources(),
    ];
    Promise.all(promises);
  },
  methods: {
    initGrid() {
      const self = this;
      self.gridEngine = self.$refs.grid.gridEngine;
    },
    async getResources() {
      const self = this;
      await api.getScheduleList().then((res) => {
        self.data = res.body.Data.map((item) => ({
          id: item.Id,
          schedule: item.Name,
        }));
        blockui.unBlockPage();
      }, (error) => {
        blockui.unBlockPage();
      });
    },
    async updateGrid(updatedRecord) {
      const self = this;
      if (updatedRecord.Id) await self.getResources();
      else {
        const ids = self.data.map((record) => record.id);
        await self.getResources();
        updatedRecord.Id = self.data.filter((record) => !ids.some((id) => id === record.id))[0].id;
      }
      self.gridEngine.scrollRowIntoView(updatedRecord.Id, { block: 'center', highlight: true, animate: true });
    },
  },
  mounted() {
    const self = this;
    window.$('#add_schedule_btn').on('click', () => {
      const modalContainer = window.$('#schedule_modal');
      modalContainer.modal({
        backdrop: 'static',
        show: true,
      });
      self.selectedSchedule = {};
      modalContainer.find('form').data('type', '');
      self.isUpdate = false;
    });
  },
};
