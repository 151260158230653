import Vue from 'vue';

import moment from 'moment';
import $ from 'jquery';

// @ts-ignore
import Select2 from '@/components/shared/select2.vue';
// @ts-ignore
import datetimepicker from '@/components/shared/datetimepicker.vue';
// @ts-ignore
import datepicker from '@/components/shared/datepicker.vue';
// @ts-ignore
import daterangepicker from 'vue2-daterange-picker';
import Multiselect from 'vue-multiselect';
// @ts-ignore
import draggable from 'vuedraggable';
// @ts-ignore
import datePicker from 'vue-bootstrap-datetimepicker';
// @ts-ignore
import VueNumberInput from '@chenfengyuan/vue-number-input';
// @ts-ignore
import * as blockui from './helpers/block-ui';
// import 'es6-promise/auto'
import 'babel-polyfill';

// region Localization
// @ts-ignore
import Multilanguage from 'vue-multilanguage-legacy';

// @ts-ignore
// endregion Localization

// region validate
// @ts-ignore
import Vuelidate from 'vuelidate';
// endregion validate

// region cookie
// @ts-ignore
import VueCookie from 'vue-cookie';
// endregion cookie

// @ts-ignore
import VTooltip from 'v-tooltip';

// region Error handling
import App from './App.vue';
import language from './languages/index';
import store from './store/index';
import router from './router';
// endregion filters
// @ts-ignore
import { getCookieByName } from './scripts/global/util.js';

import apiConfig from '@/scripts/services/axiosConfig';
import { OtherApi } from '@/scripts/services/OtherApis';

// @ts-ignore
import CFMMultiselect from '@/components/partials/CFMMultiselect';

Vue.use(Multilanguage, language);

// @ts-ignore
Vue.use(Vuelidate);

Vue.use(VueCookie);

Vue.use(VTooltip);

Vue.prototype.$globalApiClient = new OtherApi(apiConfig);

interface Window {
  webkitRTCPeerConnection?: any;
  mozRTCPeerConnection?: any;
  RTCPeerConnection?: any;
}

declare var window: Window;

interface Window {
  errors: any;
  webkitRTCPeerConnection?: any;
  mozRTCPeerConnection?: any;
  RTCPeerConnection?: any;

  addEventListener(error: string, param2: (...a: any) => void): void;
}

declare var window: Window;

function createErrorsArray() {
  if (!window.errors) {
    window.errors = [];
  }
}

window.addEventListener('unhandledrejection', (event) => {
  createErrorsArray();
  window.errors.push(event);
});

window.addEventListener('error', (messageOrEvent, source, lineno, colno, error) => {
  createErrorsArray();
  window.errors.push(messageOrEvent);
});

Vue.config.errorHandler = (msg, vm, info) => {
  createErrorsArray();
  window.errors.push(msg);
  console.error(msg, info);
};

// endregion Error handling

// region RxJS Vue-Rx
/* import VueRx from 'vue-rx';

Vue.use(VueRx); */
// endregion RxJS Vue-Rx

Vue.use(datePicker);
Vue.component(VueNumberInput.name, VueNumberInput);
Vue.component('select2', Select2);
Vue.component('datetimepicker', datetimepicker);
Vue.component('datepicker', datepicker);
Vue.component('daterangepicker', daterangepicker);
Vue.component('multiselect', Multiselect);
Vue.component('draggable', draggable);
Vue.component('cfm-multiselect', CFMMultiselect);

Vue.config.productionTip = false;

// region filters
Vue.filter('formatDateTime', (value: string | Date) => {
  if (value) {
    return moment.utc(String(value)).format('DD.MM.YYYY HH:mm');
  }
});
Vue.filter('formatTime', (value: string | Date) => {
  if (value) {
    return moment(String(value)).format('HH:mm');
  }
});
Vue.filter('formatDateTimeLocal', (value: string | Date) => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm');
  }
});
Vue.filter('formatDate', (value: string | Date) => {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
});
Vue.filter('minsToHrsMins', (value: number): string => {
  if (value) {
    let h: number | string = Math.floor(value / 60);
    let m: number | string = value % 60;
    h = h < 10 ? `0${h}` : h;
    m = m < 10 ? `0${m}` : m;
    return `${h}:${m}`;
  }
  return '00:00';
});

router.beforeEach((to, from, next) => {
  blockui.unBlockPage();
  $('.daterangepicker.dropdown-menu').remove();
  blockui.unBlockPage();
  if (to.matched.some((record) => record.meta.requiresAuth)
    // @ts-ignore
    && (!getCookieByName('token') || !getCookieByName('token') === null)) {
    next({
      path: '/Login',
      query: { redirect: to.fullPath.replace('/', '') },
    });
    return;
  }
  if (to.matched.some((record) => record.meta.authItem)) {
    const isUnauthorizedList = to.matched.map((matchedOne) => {
      if (matchedOne.meta.authItem) {
        const urlItem = localStorage.getItem('urlItem');
        if (urlItem && urlItem != 'undefined') {
          const urlItemList = urlItem.split(',');
          const check = urlItemList.map((item: string) => {
            return `${item}_page` === matchedOne.meta.authItem;
          });
          return !check.includes(true);
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
    if (isUnauthorizedList.includes(true)) {
      if (to.name === 'Home') {
        // @ts-ignore
        if (router.app && router.app.translate) {
          // @ts-ignore
          toastr.warning(router.app.translate('you_dont_have_permission_to_home_page'));
        }
        next({
          path: '/Logout',
        });
        return;
      }
      next({
        path: '/401',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

Vue.prototype.$eventHub = new Vue(); // Global event bus

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
