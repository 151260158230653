




















































































































































































































































import { DropDownTreeComponent } from "@syncfusion/ej2-vue-dropdowns";
import { DatePickerComponent } from '@syncfusion/ej2-vue-calendars';
import Component from "vue-class-component";
import Vue from "vue";
import ExcelJS from 'exceljs';
import {required} from "vuelidate/lib/validators";
import { mixinz } from '@/scripts/global/mixinz';
import moment from 'moment';
import * as blockui from '../../helpers/block-ui';
import * as dateFormat from '../../helpers/dateFormat';

interface Point {
  Id: number;
  Name: string;
  TypeId: number;
  ChildItems: Point[];
  Parents?: string[];
}

@Component<PointAnalysisReport>({
  mixins: [mixinz],
  components: {
      'ejs-dropdowntree': DropDownTreeComponent,
      'ejs-datepicker': DatePickerComponent
  },
  data() {
    return {
      startDate: moment().format('DD.MM.YYYY'),
      endDate: moment().format('DD.MM.YYYY'),
    };
  },  
  validations() {
      return {
        startDate:{required},
        endDate:{required},
      }
  },     
})

export default class PointAnalysisReport extends Vue {
    pointList: Point[] = [];
    openIds: string[] = [];
    selectedPoints: Point[] = [];
    searchText = "";
    isDataLoading = false;
  async created() {
      await this.getPoints();
    }   
    async getPoints() {
      this.isDataLoading = true;
      this.pointList = (await this.$globalApiClient.pointAnalysis.GetAllPointsHierarchical()).data;
      this.addParents(this.pointList);
      this.isDataLoading = false;
    }

    addParents(points: Point[], parents: string[] = []) {
      points.forEach(point => {
        point.Parents = [...parents];
        if (point.ChildItems) {
          this.addParents(point.ChildItems, [...parents, point.Name]);
        }
      });
    }

    handleRowClick(Id: string) {
      if (this.openIds.includes(Id)) {
        this.openIds.splice(this.openIds.indexOf(Id), 1);
      } else {
        this.openIds.push(Id);
      }
    }
    handleCheckBoxClick(item: Point) {
      if (this.selectedPoints.find(point => point.Id === item.Id)) {
        this.selectedPoints = this.selectedPoints.filter(point => point.Id !== item.Id);
      } else {
        this.selectedPoints.push(item);
      }
    }
    async selectDate(startDate: any, endDate: any) {
      const self = this;
      if (!startDate || !endDate) {
        toastr.error(self.translate('start_and_end_dates_are_required'));
        blockui.unBlockPage();
        return false;
      }
      //@ts-ignore
      if (moment(startDate, self.pickerDateFormat).toDate() > moment(endDate, self.pickerDateFormat).toDate()) {
        toastr.error(self.translate('end_date_cannot_be_before_start_date'));
        blockui.unBlockPage();
        return false;
      }
      return true;
    }
  async handleSubmit() {
    //@ts-ignore
    const startDate = moment(this.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
    //@ts-ignore
    const endDate = moment(this.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
    //@ts-ignore
    if (!await this.selectDate(this.startDate, this.endDate)) {
      return;
    }

    this.isDataLoading = true;
    let data = {pointIds:encodeURI(this.selectedPoints.map(point => point.Id).toString()),startDate:encodeURI(startDate) ,endDate:encodeURI(endDate) }
    let response = (await this.$globalApiClient.pointAnalysis.GetPointAnalysisReport(data)).data;

    const dateMoment = new Date();
    let excelWorkbook = new ExcelJS.Workbook();
    excelWorkbook.creator = 'CFM';
    excelWorkbook.created = dateMoment;
    excelWorkbook.calcProperties.fullCalcOnLoad = true;
    excelWorkbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible',
      },
    ];

    const reportColumns = [
      { key: "WorkGroupName", header: "İş Gurubu", width: 20 },
      { key: "WorkName", header: "İş Adı", width: 20 },
      { key: "Room", header: "Oda", width: 20 },
      { key: "Inventory", header: "Envanter", width: 20 },
      { key: "Device", header: "Cihaz", width: 20 },
      { key: "PointName", header: "Nokta İsmi", width: 20 },
      { key: "ScheduledTime", header: "Tarih Saat", width: 20, style: { numFmt: 'dd/mm/yyyy HH:mm' } },
      { key: "WorkOrderId", header: "WO NO", width: 20 },
      { key: "AnswerValue", header: "Değer", width: 20 },
      { key: "ExpectableValue", header: "Beklenen Değer (Y/N)", width: 20 },
    ]

    const reportRows = response.map(row => {
      return {
        WorkGroupName: row.WorkGroupName,
        WorkName: row.WorkName,
        Room: row.Room,
        Inventory: row.Inventory,
        Device: row.Device,
        PointName: row.PointName,
        ScheduledTime: row.ScheduledTime ? dateFormat.viewDateTimeFormatTZ(row.ScheduledTime) : null,
        WorkOrderId: row.WorkOrderId,
        AnswerValue: row.AnswerValue,
        ExpectableValue: row.ExpectableValue
      };
    });

    let context = [
      {
        sheetName: "PointAnalysis",
        sheetData: reportRows,
        columnName: reportColumns
      }
    ];

    context.forEach((contextItem) => {
      // Add Work Sheet
      let sheet = excelWorkbook.addWorksheet(contextItem.sheetName, {});
      sheet.columns = contextItem.columnName;

      // Add Data 
      contextItem.sheetData.forEach((row) => {
        sheet.addRow(row);
      });

      // Add Header Style
      sheet.eachRow((sheetRow, sheetRowNumber) => {
        if (sheetRowNumber == 1) {
          sheet.autoFilter = `${sheet.getColumn(1).letter + "1:" + sheet.getColumn(sheet.columns.length).letter + "1"}`;
          sheetRow.font = { bold: true, size: 12 };
          sheetRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '0042a5f5' } };
        }
        else if (sheetRow.getCell(10).value === "No") {
          sheetRow.font = { color: { argb: '00ff0000'}, bold: true, size: 10 };
        }
        else {
          sheetRow.font = { bold: false, size: 10 };
        }

        sheetRow.commit();
      });
    });

    excelWorkbook.xlsx.writeBuffer().then((buffer) => {
      const uint8Array = new Uint8Array(buffer);
      this.saveByteArray(`PointAnalistReport-${startDate}-${endDate}.xlsx`, uint8Array);
    });

    this.isDataLoading = false;
  }

  saveByteArray(reportName: string, byte: Uint8Array) {
    const blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    if (navigator.appVersion.toString().indexOf('.NET') > 0) window.navigator.msSaveBlob(blob, reportName);
    else {
      const link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a') as HTMLAnchorElement;
      link.href = URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    }
  }

  async fetchData() {
    this.isDataLoading = true;
    let response = await this.$globalApiClient.pointAnalysis.GetAllPointsHierarchical({ searchText: this.searchText });
    this.pointList = response.data;
    this.addParents(this.pointList);
    this.isDataLoading = false;
  }

}
