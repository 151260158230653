<template>
    <div id="comment_step">
        <div class="column-item" v-for="(sparePartComment,index) in sparePartComments" :id="sparePartId" :key="index">
            <div class="xxx">
                <div class="row">
                    <p class="col-12 col-md-4">
                        <label v-lang.comment></label>
                        <strong>{{sparePartComment.Text}}</strong>
                    </p>
                    <p class="col-12 col-md-4">
                        <label v-lang.created_date></label>
                        <strong>{{sparePartComment.CreatedDate}}</strong>
                    </p>
                    <p class="col-12 col-md-4">
                        <label v-lang.user></label>
                        <strong><i class="fas fa-user"></i>{{sparePartComment.UserName}}</strong>
                    </p>
                </div>
            </div>
        </div>
        <input v-model="sparePartComment" type="text" name="Text" class="form-control m-input col-md-3" autocomplete="off" :placeholder="this.translate('add_comment')" maxlength="200"/>
        <label><span class="inputWarning" v-lang.input_text_validation_comment></span><span class="inputWarning">*</span></label>
        <br>
        <span class="inputWarning"> Kalan Karakter sınırı : {{this.remainingChar - this.sparePartComment.length}}</span>
        <br>
        <br>
        <button type="submit" class="btn btn-primary" @click="addComment" v-lang.save></button>
    </div>
</template>

<script>
export default {
    name:'CommentStep',
    data() {
        return {
            sparePartComments:[],
            sparePartComment:'',
            remainingChar:200
        };
    },
    props: [
        'sparePartId'
    ],
    async created(){
        //@ts-ignore
        let response = await this.$globalApiClient.sparePartComment.sparePartGetComments({sparePartId: this.sparePartId});
        //@ts-ignore
        this.sparePartComments = response.data;
    },

    methods:{

        async addComment(){
            let formData = {
                SparePartId: this.sparePartId,
                Text: this.sparePartComment
            };
            const response = await this.$globalApiClient.sparePartComment.sparePartCommentSave(formData);
            if (response.data.IsSuccess) {
                toastr.success(this.translate('successfully_add_comment'));
                window.location.reload();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
        },
    },
}
</script>

<style>
    .inputWarning{
        color:red; 
        font-size:smaller;
    }
</style>