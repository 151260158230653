import * as api from '../../scripts/services/api';

// initial state
const state = {
  jobList: [],
};

// getters
const getters = {};

// actions
const actions = {
  getJobList({ commit }) {
    api.getJobs().then((res) => {
      commit('setJobList', res.body);
    });
  },
};

// mutations
const mutations = {
  setJobList(state, jobs) {
    state.jobList = jobs;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
