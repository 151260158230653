<template>
    <div class="row question" :class="{'show-required-warning':showError&&isInvalid}">
        <div class="col-md" style="position: relative">
            <!--answerProcess {{answerProcess}}-->
            <h6 :class="{'m--font-brand': !question.Answer || !question.Answer.length}">
                {{index+1}} {{question.Name}}
                <span class="text-danger" v-if="question.IsRequired">*</span>
                <i v-if="questionFeedBacks" v-for="(feedback, index) in questionFeedBacks"
                   style="color:#006c95; margin-left:10px;"
                   class="b-fa b-fa-flag i-tooltip custom-feedback-button"
                   :id="'feedback_'+feedback.Id"
                ></i>
                <i v-if="!disabled && formProcessId"
                   class="b-fa b-fa-comment-plus custom-comment-button"
                   style="color:#ffb822; margin-left:10px;"
                   @click.prevent="openEditor"
                ></i>
                <i v-if="questionProcess" style="margin-left:10px;" class="fa"
                   :class="{'fa-check':questionProcess.IsValid===true,'fa-times':questionProcess.IsValid===false,'btn-outline-success':questionProcess.IsValid===true,'btn-outline-danger':questionProcess.IsValid===false}"></i>
            </h6>
            <p>
                {{question.Description}}
            </p>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.multipleChoice">
                <div class="py-2" v-for='(opt, i) in question.Selections' :key="i">
                    <label class="m-radio m-radio--bold m-radio--state-brand text-inherit">
                        <!--{{opt.AnswerProcesses.find(x=>x.IsSelected=='true').SelectionId}}-->
                        <!--{{questionProcess.AnswerProcesses.find(x=>x.IsSelected==true)}}-->
                        <input type="radio"
                               @change="onMultipleSelectionChanged"
                               :name="'radio_'+question.Id+'_'+getIndex"
                               :id="'radio_'+question.Id+'_'+getIndex+'_'+i"
                               :value="opt.Id"
                               v-model="currentSelect"
                               @click.stop="onRadioButtonClick(opt.Id)"
                               :required="question.IsRequired"
                               :disabled="disabled">
                        <!--stop bubbling for prevent click event fire multiple time at question click-->
                        <div :style="{color:opt.IsExpectedValue?'blue':''}">{{opt.Name}}</div>
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.checkBox">
                <div class="py-2" v-for='(opt, i) in question.Selections'>
                    <label class="m-checkbox m-checkbox--bold m-checkbox--state-brand text-inherit" :key="i">
                        <input type="checkbox"
                               @change="onMultipleSelectionChanged"
                               :name="'checkbox_'+question.Id+'_'+getIndex"
                               :id="'checkbox_'+question.Id+'_'+getIndex+'_'+i"
                               v-model="currentSelect"
                               :value="opt.Id"
                               @click.stop=""
                               :required="question.IsRequired"
                               :disabled="disabled">
                        <!--stop bubbling for prevent click event fire multiple time at question click-->
                        <div :style="{color:opt.IsExpectedValue?'blue':''}">{{opt.Name}}</div>
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.shortText">
                <input v-model="currentSelect" type="text" class="form-control" :required="question.IsRequired"
                       :disabled="disabled">
            </div>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.longText">
            <textarea v-model="currentSelect" class="form-control" :required="question.IsRequired"
                      :disabled="disabled"></textarea>
            </div>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.number">
                <input v-model="currentSelect" type="number" class="form-control" :required="question.IsRequired"
                       :disabled="disabled" @input="inputHandler($event,question,currentSelect)"
                       @keypress="numberInputKeypressHandler($event,false)">
            </div>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.decimal">
                <input v-model="currentSelect" type="number" class="form-control" :required="question.IsRequired"
                       step=".01"
                       :disabled="disabled" @input="inputHandler($event,question,currentSelect)"
                       @keypress="numberInputKeypressHandler($event,true)">
            </div>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.shortDate">
                <date-picker name="date" v-model="currentSelect" :config="pickerDateOptions" :required="question.IsRequired"
                             :disabled="disabled" :key="index"></date-picker>
                <input v-model="currentSelect" type="text" class="form-control d-none" :required="question.IsRequired"
                       :disabled="disabled">
            </div>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.longDate">
                <date-picker name="datetime" v-model="currentSelect" :config="pickerDateTimeOptions"
                             :required="question.IsRequired"
                             :disabled="disabled" :key="index"></date-picker>
                <input v-model="currentSelect" type="text" class="form-control d-none" :required="question.IsRequired"
                       :disabled="disabled">
            </div>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.hour">
                <date-picker name="time" v-model="currentSelect" :config="pickerTimeOptions" :required="question.IsRequired"
                             :disabled="disabled" :key="index"></date-picker>
                <input v-model="currentSelect" type="text" class="form-control d-none" :required="question.IsRequired"
                       :disabled="disabled">
            </div>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.imageUpload">
                <upload file-type="Image" :value.sync="currentSelect" :multiple="false" :required="question.IsRequired"
                        :disabled="disabled" :isUploadBusy.sync="isTransactionWorking" :key="index"/>
            </div>
            <div class="form-group" v-if="question.QuestionTypeId == formQuestionType.fileUpload">
                <upload file-type="File" :value.sync="currentSelect" :multiple="false" :required="question.IsRequired"
                        :disabled="disabled" :isUploadBusy.sync="isTransactionWorking" :key="index"/>
            </div>
            <div v-if="question.PointView">
                <i class="la la-link"></i>
                {{question.PointView.BreadCrumb}}
            </div>
            <div v-if="question.TempPointView" style="vertical-align: middle;">
                <span class="line-through-outer">
                    <span class="line-through-inner">
                        <i class="la la-link"></i>
                        {{question.TempPointView.BreadCrumb}}
                        <i style="color:black; cursor: pointer" class="la la-times" @click="removeTempPoint"></i>
                    </span>
                </span>
            </div>
            <div>
                <pre>{{answerConditionString}}</pre>
            </div>
        </div>
        <div>
            <div class="col-md mt-5">
                <div v-if="answerProcess&&answerProcess.CreatedUser&&!isEmpty">
                    {{translate('answered_at')}}: {{answerProcess.CreatedDate|formatDateTimeLocal}}
                    <user-card position="right" :userId="answerProcess.CreatedUser.Id">
                        {{translate('answered_by')}}: <strong>{{answerProcess.CreatedUser.Name}}
                        {{answerProcess.CreatedUser.Surname}}</strong>
                    </user-card>
                </div>
            </div>
            <img :src="image" v-if="image" class="col--sm ml-auto mt-6 m--margin-right-25 m--margin-top-35"
                 :class="{'thumb': toggleImage}" @click="toggleImage = !toggleImage">
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import * as api from '../../scripts/services/api';
import upload from '../shared/upload';
import UserCard from './cards/UserCard';
import { mixinz } from '../../scripts/global/mixinz';
import { Editor } from '../../scripts/global/editors';

export default {
  props: ['workOrderId', 'questionFeedBacks', 'formProcessId', 'question', 'index', 'disabled', 'devices', 'selection', 'isUploadBusy', 'answerProcess', 'showError', 'isInvalid', 'current', 'comparisonOperators', 'operandTypes'],
  mixins: [mixinz],
  components: {
    upload,
    'user-card': UserCard,
  },
  data() {
    return {
      feedBackEditor: null,
      isTransactionWorking: false,
      toggleImage: true,
      image: null,
      oldVal: '',
    };
  },
  async created() {
    const self = this;
    if (self.question && self.question.Selections) {
      self.question.Selections.sort((x, y) => x.Order - y.Order);
    }
    self.initImage(self.imageId);
  },
  beforeDestroy() {
    Editor(null, this.feedback);
  },
  methods: {
    onRadioButtonClick(selectedOptionId) {
      if (this.currentSelect == selectedOptionId) {
        this.currentSelect = null;
        this.onMultipleSelectionChanged();
      }
    },
    onMultipleSelectionChanged(e) {
      if (!this.disabled) {
        this.$emit('multipleSelectionChanged', e);
      }
    },
    removeTempPoint() {
      this.$emit('removeTempPoint');
    },
    openEditor({ target }) {
      const self = this;
      self.feedBackEditor = Editor({
        type: 'popupHtml',
        forElement: target,
        value: '',
        callback: async (value) => {
          if (!value.length) return false;
          await self.$globalApiClient.workOrderHtmlContent.workOrderHtmlContentSave({
            workOrderId: self.workOrderId,
            questionId: self.question.Id,
            formProcessId: self.formProcessId,
          }, {
            content: value,
          }).then((res) => {
            if (res.data.Data) {
              const questionFeedBack = {
                Id: res.data.Data.Id,
                HtmlContentId: res.data.Data.HtmlContentId,
                CreatedDate: res.data.Data.CreatedDate,
                WorkOrderId: self.workOrderId,
                QuestionId: self.question.Id,
                FormProcessId: self.formProcessId,
                CreatedUser: { NameSurName: localStorage.getItem('userName') },
              };
              self.questionFeedBacks.push(questionFeedBack);
              self.$emit('questionFeedBackSaved', questionFeedBack);
              toastr.success(self.translate('feedback_saved_successfully'));
              self.feedBackEditor.close();
            } else {
              toastr.error(res.data.Message);
            }
          });
        },
      }, self.feedBackEditor);
    },
    async downloadFile(fileId) {
      const self = this;
      const response = await api.getFile({ id: fileId, isContainsData: true });
      return response.body.Data;
    },
    async initImage(e) {
      const self = this;
      if (e > 0) {
        const response = await self.downloadFile(e);
        if (response.ContentType.match('image.*')) {
          self.image = `data:${response.ContentType};base64,${response.Base64}`;
        }
      } else {
        self.image = null;
      }
    },
    numberInputKeypressHandler(e, allowDot) {
      e = (e) || window.event;
      if (e.key === 'e' || e.key === '+' || ((e.key === '.' || e.key === ',') && !allowDot)) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    inputHandler(e, question, currentSelect) {
      /*
                 let newVal = e.target.value;
                 let oldVal = currentSelect;
                 if (!newVal || !this.validateQuestionType(newVal, question.QuestionTypeId)) {
                   e.target.value = oldVal;
                 } */
    },
    validateQuestionType(value, questionTypeId) {
      const regExps = {
        [this.formQuestionType.decimal]: /[+-]?([0-9]*[.])?[0-9]+/,
        [this.formQuestionType.number]: /[+-]?[0-9]+/,
      };
      const currentRegExp = regExps[questionTypeId];
      if (currentRegExp) {
        return this.validateRegExp(currentRegExp, value);
      }
    },
    validateRegExp(regExp, string) {
      return regExp.test(String(string).toLowerCase());
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isInt(value) {
      return !isNaN(value)
                    && parseInt(Number(value)) == value
                    && !isNaN(parseInt(value, 10));
    },
    answerConditionRecursive(answerCondition, index) {
      const operandType = this.operandTypes.find((operandType) => operandType.Id === answerCondition.OperandTypeId);
      const array = [];
      if (answerCondition && answerCondition.AnswerConditionRules && answerCondition.AnswerConditionRules.length > 0) {
        for (const answerConditionRule of answerCondition.AnswerConditionRules) {
          if (answerConditionRule.ComparisonOperator.IsSecondValueNeeded) {
            array.push(`${answerConditionRule.ComparisonOperator.Name} ${answerConditionRule.FirstValue}, ${answerConditionRule.SecondValue}`);
          } else {
            array.push(`${answerConditionRule.ComparisonOperator.Name} ${answerConditionRule.FirstValue}`);
          }
        }
      }
      if (answerCondition && answerCondition.Children && answerCondition.Children.length > 0) {
        for (const child of answerCondition.Children) {
          array.push(this.answerConditionRecursive(child, index + 1));
        }
      }

      let str = `\n${' '.repeat(index)}(`;
      str += array.join(` ${operandType.Name} `);
      return index - 1 > -1 ? `${str})` + `\n${' '.repeat(index - 1)}` : `${str})`;
    },
  },
  computed: {
    pickerDateOptions() {
      return { widgetPositioning: { vertical: 'top' }, ...this.pickerDate };
    },
    pickerDateTimeOptions() {
      return { widgetPositioning: { vertical: 'top' }, ...this.pickerDateTime };
    },
    pickerTimeOptions() {
      return { widgetPositioning: { vertical: 'top' }, ...this.pickerTime };
    },
    getIndex() {
      if (this.current) {
        return 'current';
      }
      return this.index;
    },
    imageId() {
      return this.question.ImageId;
    },
    ...mapState({
      formQuestionType: (state) => state.enums.formQuestionType,
    }),
    questionProcess() {
      const self = this;
      if (self.question && self.question.QuestionProcess && self.question.QuestionProcess && self.question.QuestionProcess.length > 0) {
        return self.question.QuestionProcess[0];
      }
      return null;
    },
    isEmpty() {
      const self = this;
      const { question } = self;
      const currentSelection = self.question.Answer;
      if (question.QuestionTypeId == self.formQuestionType.multipleChoice) {
        return !(currentSelection > 0);
      } if (question.QuestionTypeId == self.formQuestionType.checkBox) {
        return !(currentSelection && currentSelection.length > 0);
      } if (question.QuestionTypeId == self.formQuestionType.shortText) {
        return !currentSelection || currentSelection.trim() === '';
      } if (question.QuestionTypeId == self.formQuestionType.longText) {
        return !currentSelection || currentSelection.trim() === '';
      } if (question.QuestionTypeId == self.formQuestionType.number) {
        return !self.isInt(currentSelection) && !self.validateQuestionType(currentSelection, question.QuestionTypeId);
      } if (question.QuestionTypeId == self.formQuestionType.decimal) {
        return !self.isNumeric(currentSelection) && !self.validateQuestionType(currentSelection, question.QuestionTypeId);
      } if (question.QuestionTypeId == self.formQuestionType.shortDate) {
        const momentObj = moment(currentSelection, 'DD.MM.YYYY');
        return !momentObj.isValid();
      } if (question.QuestionTypeId == self.formQuestionType.longDate) {
        const momentObj = moment(currentSelection, 'DD.MM.YYYY HH:mm');
        return !momentObj.isValid();
      } if (question.QuestionTypeId == self.formQuestionType.hour) {
        const momentObj = moment(currentSelection, 'HH:mm');
        return !momentObj.isValid();
      } if (question.QuestionTypeId == self.formQuestionType.imageUpload) {
        return !(currentSelection && currentSelection.fileId > 0);
      } if (question.QuestionTypeId == self.formQuestionType.fileUpload) {
        return !(currentSelection && currentSelection.fileId > 0);
      }
    },
    currentSelect: {
      get() {
        const self = this;
        return self.selection;
      },
      set(newVal) {
        const self = this;
        if (self.currentSelect !== newVal) {
          self.$emit('updateSelection', newVal);
        }
      },
    },
    answerConditionString() {
      if (!this.question.AnswerCondition) {
        return '';
      }
      return this.answerConditionRecursive(this.question.AnswerCondition, 0);
    },
  },
  watch: {
    async imageId(e) {
      this.initImage(e);
    },
    isTransactionWorking(e) {
      this.$emit('update:isUploadBusy', e);
    },
    question() {
      const self = this;
      if (self.question && self.question.Selections) {
        self.question.Selections.sort((x, y) => x.Order - y.Order);
      }
    },
  },
};
</script>
