<template>
    <div>
        <div class="input-group" v-bind:class="addButton && !isChild ? 'select-add-button' : ''">
            <select ref="select" :class="classNames" v-model="selected" :required="required" :name="name" :id="id"
                    :multiple="multiple" :disabled="disabled">
                <option v-if="!this.multiple" value="" v-lang.please_select></option>
                <option v-for="item in items" :data-icon="item.Icon?item.Icon:false"
                        :data-breadcrumb="item.BreadCrumb?item.BreadCrumb:false" :value="item.Value">{{item.Text}}
                </option>
            </select>
            <div v-if="addButton && !isChild" class="input-group-append">
                <button class="btn btn-metal" v-bind:class="addButton" @click="openModal()" type="button"
                        style="border: 1px;"
                        data-toggle="m-tooltip" data-placement="left" :data-original-title="translate('add_new')"
                        data-select2-open="single-append-text">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
            <div v-if="applyAllButton" class="input-group-append">
                <button class="btn btn-metal" v-bind:class="applyAllButton" @click="applyAll()" type="button"
                        style="border-left: 1px solid #8da2ac;"
                        data-toggle="m-tooltip" data-placement="left" :data-original-title="translate('apply_all')"
                        data-select2-open="single-append-text">
                    <span class="fas fa-align-justify"></span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: ['items', 'selected', 'required', 'name', 'id', 'classNames', 'multiple', 'allowClear', 'addButton', 'component', 'modal', 'modalParameters', 'isChild', 'selectAll', 'dropdownParent', 'disabled', 'applyAllButton'],
  data() {
    return {
      isUpdate: false,
      isClicked: false,
      value: null,
    };
  },
  mounted() {
    const vm = this;
    $(this.$refs.select)
    // init select2
      .select2({
        allowClear: vm.allowClear !== false,
        placeholder: vm.allowClear === false ? undefined : this.translate('please_select'),
        dropdownParent: vm.dropdownParent ? $(vm.dropdownParent) : $(this.$refs.select).closest('div'),
        templateResult: vm.formatResult,
        templateSelection: vm.formatSelection,
        matcher: vm.matchCustom,
        language: {
          noResults: () => vm.translate('no_option'),
        },
      })
      .val(vm.selected)
      .on('change', (e) => {
        const newVal = e.target.value;
        if (vm.value !== newVal) {
          vm.$emit('update:value', $(vm.$refs.select).val() !== null ? $(vm.$refs.select).val() : vm.selected);
          vm.$emit('valueChanged', $(vm.$refs.select).val() !== null ? $(vm.$refs.select).val() : vm.selected);
        }
        vm.value = newVal;
      })
      .on('select2:close', function () {
        if (this.required && $(this).closest('form').length > 0) {
          $(this).valid();
        }
        vm.$emit('selectClosed', $(vm.$refs.select).val());
      })
      .on('select2:open', () => {
        if (vm.selectAll && vm.multiple) {
          $('.select2-results:not(:has(a))').append(`<a href="javascript:;">${vm.translate('select_all')}</a>`).on('click', () => {
            $(vm.$refs.select)
              .val(vm.items.map((item) => item.Value))
              .trigger('change');
          });
        }
      });
    if (vm.selected > 0) {
      $(this.$refs.select).trigger('change');
    }
    vm.$nextTick(() => {
      mApp.initTooltips();
    });
  },
  watch: {
    selected(value) {
      $(this.$refs.select)
        .val(value)
        .trigger('change');
    },
    items(items) {
      const self = this;
      // update options
      const { selected } = self;
      $(this.$refs.select).val(null).trigger('change');
      if (selected && items && items.length > 0 && items.find((x) => x.Value == selected)) {
        $(this.$refs.select).val(selected).trigger('change');
      }

      $(this.$refs.select).select2({
        allowClear: self.allowClear !== false,
        placeholder: self.translate('please_select'),
        dropdownParent: self.dropdownParent ? $(self.dropdownParent) : $(self.$refs.select).closest('div'),
        templateResult: self.formatResult,
        templateSelection: self.formatSelection,
        matcher: self.matchCustom,
        language: {
          noResults: () => self.translate('no_option'),
        },
      });
    },
  },
  methods: {
    matchCustom(params, data) {
      if ($.trim(params.term) === '') {
        return data;
      }

      if (typeof data.text === 'undefined') {
        return null;
      }

      if (data.text.toLocaleLowerCase(localStorage.getItem('vue-lang')).indexOf(params.term.toLocaleLowerCase(localStorage.getItem('vue-lang'))) > -1) {
        return data;
      }

      const breadCrumb = $(data.element).data('breadcrumb');
      if (breadCrumb) {
        if (breadCrumb.toLocaleLowerCase(localStorage.getItem('vue-lang')).indexOf(params.term.toLocaleLowerCase(localStorage.getItem('vue-lang'))) > -1) {
          return data;
        }
      }

      return null;
    },
    formatResult(state) {
      const breadCrumb = $(state.element).data('breadcrumb');
      const icon = $(state.element).data('icon');

      let $breadCrumb = '';
      if (breadCrumb) {
        $breadCrumb = `<div>${breadCrumb}</div>`;
      }
      let $icon = '';
      if (icon) {
        $icon = `<i class="fa ${icon} fa-fw"></i>`;
      }

      const $state = $(`<div>${$icon}<span>${state.text}</span></div><div>${$breadCrumb}</div>`);

      return $state;
    },
    formatSelection(state) {
      const breadCrumb = $(state.element).data('breadcrumb');

      let $state = `${state.text}`;

      if (breadCrumb) {
        $state += ` --> ${breadCrumb}`;
      }

      return $state;
    },
    reloadSelect2() {
      this.$emit('reloadSelect2');
    },
    openModal() {
      if (this.modal) {
        this.$eventHub.$emit('open-modal', {
          modal: this.modal,
          component: this.component,
          parameter: this.modalParameters,
        });
      }
    },
    applyAll() {
      this.$emit('applyAll', this.value);
    },
  },
  destroyed() {
    $(this.$refs.select).off().select2('destroy');
  },
};
</script>
