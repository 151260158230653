







































import CFMDialog from '@/scripts/CFMDialog';
import { CFMDTOQAQC } from '@/scripts/services/ClientApiAuto'
import {Component} from 'vue-property-decorator';
import {required} from "vuelidate/lib/validators";
import * as blockui from '@/helpers/block-ui'

@Component<QAQCDialog>({
    components: {},
	validations() {
        return {
        qaqc:{
            Name: {required},
			Description: {}
        }
        }
    },
})

export default class QAQCDialog extends CFMDialog {
	qaqc: CFMDTOQAQC = {Name: '', Description: ''};

	async created(){}
	
	async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      let formData = {
        Name: this.qaqc.Name,
		Description: this.qaqc.Description
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.QAQC.qaqcSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}

