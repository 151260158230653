module.exports = {

  template: '<textarea :name="name"></textarea>',

  props: {
    value: {
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    height: {
      type: String,
      default: '150',
    },
  },

  mounted() {
    const config = {
      height: this.height,
      dialogsInBody: true,
    };

    const vm = this;

    config.callbacks = {

      onInit() {
        $(vm.$el).summernote('code', vm.computedValue);
      },

      onChange() {
        vm.$emit('input', encodeURIComponent($(vm.$el).summernote('code')));
      },

      onBlur() {
        vm.$emit('input', encodeURIComponent($(vm.$el).summernote('code')));
      },
    };

    $(this.$el).summernote(config);
  },

  computed: {
    computedValue: {
      get() {
        return this.value ? decodeURIComponent(this.value) : decodeURIComponent('');
      },
    },
  },

  watch: {
    value() {
      const isSame = $(this.$el).summernote('code') == this.computedValue;
      if (!isSame) {
        $(this.$el).summernote('code', this.computedValue);
      }
    },
  },

};
