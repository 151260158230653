<template>
    <div>
        <div class="card-header">
            <h6 style="display: inline-flex;margin: 0;">
                {{workOrder.capacityEquipment?workOrder.capacityEquipment:''}}&nbsp;</h6>
            <code>{{workOrder.workOrderId?workOrder.workOrderId:''}}</code>
            <span @click="closeFastAssign" style="cursor:pointer;position: absolute;right: 10%;">X</span>
            <span class="mt-2" style="display: block;">{{workOrder.workOrderName?workOrder.workOrderName:''}}</span>
            <span style="display: block;"><pre style="margin: 0">{{workOrderTime(workOrder.workOrderStartDate)}}-{{workOrderTime(workOrder.workOrderEndDate)}}</pre></span>
            <div class="input-group md-form form-sm form-1 pl-0 mt-2">
                <div class="input-group-prepend">
                    <span class="input-group-text purple lighten-3" id="basic-text1"><i class="fas fa-search"
                                                                                        aria-hidden="true"></i></span>
                </div>
                <input v-model="searchText" @keyup="searchUser" class="form-control" type="text"
                       :placeholder="translate('search_user')" aria-label="Search">
            </div>
        </div>
        <div class="card-content" style="overflow-y: scroll;overflow-x: auto;height: 70vh">
            <div class="card" style="width: 100%;min-width: 18rem" v-for="user in filteredUsers">
                <div class="card-body">
                    <h5 class="card-title">{{user.Name}}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{user.Role}}</h6>
                    <p class="card-content">
                        <span data-toggle="m-tooltip"
                              data-placement="bottom"
                              :data-original-title="user.IsHaveWorkOrder ? translate('busy') : translate('available')"
                              :class="user.IsHaveWorkOrder?'':'text-primary'"
                              class="card-link"><i
                                :class="user.IsHaveWorkOrder?'fas fa-times-circle':'fas fa-check-circle'"></i></span>
                        <span data-toggle="m-tooltip"
                              data-placement="bottom"
                              :data-original-title="translate('shift')"
                              :class="user.IsHaveShift?'text-primary':''"
                              class="card-link"><i class="fas fa-user-clock"></i></span>
                        <span data-toggle="m-tooltip"
                              data-placement="bottom"
                              :data-original-title="translate('master_training')"
                              style="font-weight: bold"
                              :class="user.IsTrainedForMaster?'text-primary':''"
                              v-if="user.IsAssignableToMaster"
                              class="card-link">M&nbsp;<i class="fas fa-book"></i></span>
                        <span data-toggle="m-tooltip"
                              data-placement="bottom"
                              :data-original-title="translate('observer_training')"
                              style="font-weight: bold"
                              :class="user.IsTrainedForObserver?'text-primary':''"
                              v-if="user.IsAssignableToObserver"
                              class="card-link">O&nbsp;<i class="fas fa-book"></i></span>
                    </p>
                    <div class="custom-control custom-checkbox custom-control-inline" v-if="user.IsAssignableToMaster">
                        <input class="custom-control-input" style="cursor: pointer;" :id="'materialInline1'+user.Id"
                               @change="assignMasterUser(user,$event)" type="checkbox"
                               :checked="user.IsMaster">
                        <label class="custom-control-label" style="cursor: pointer;" :for="'materialInline1'+user.Id">{{translate('master')}}</label>
                    </div>
                    <div class="custom-control custom-checkbox custom-control-inline"
                         v-if="user.IsAssignableToObserver">
                        <input class="custom-control-input" style="cursor: pointer;" :id="'materialInline2'+user.Id"
                               @change="assignObserverUser(user,$event)" type="checkbox"
                               :checked="user.IsObserver">
                        <label class="custom-control-label" style="cursor: pointer;" :for="'materialInline2'+user.Id">{{translate('observer')}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import moment from 'moment';
import { mapState } from 'vuex';
import * as api from '../../scripts/services/api';
import * as blockui from '../../helpers/block-ui';
import * as swal from '../../helpers/swal';

export default {
  data() {
    return {
      users: [],
      searchText: '',
    };
  },
  props: ['workOrder'],
  async mounted() {
    const self = this;
    await self.fetchData();
    mApp.initTooltips();
  },
  methods: {
    async fetchData() {
      const self = this;
      const workOrder = {
        workOrderId: self.workOrder.workOrderId,
        isFastAssign: true,
      };
      blockui.pageBlock(self);
      await api.getSuitableWorkUsers(workOrder).then((res) => {
        self.users = res.body;
        blockui.unBlockPage();
      }, (err) => {
        blockui.unBlockPage();
      });
    },
    async assignMasterUser(user, event) {
      if (event.target.checked) {
        const success = await this.saveWorkOrderUser(user, this.enumUserPositions.master);
        event.target.checked = success;
        Vue.set(user, 'IsMaster', success);
      } else {
        const success = await this.deleteWorkOrderUser(user);
        event.target.checked = !success;
        Vue.set(user, 'IsMaster', !success);
      }
    },
    async assignObserverUser(user, event) {
      if (event.target.checked) {
        const success = await this.saveWorkOrderUser(user, this.enumUserPositions.observer);
        event.target.checked = success;
        Vue.set(user, 'IsObserver', success);
      } else {
        const success = await this.deleteWorkOrderUser(user);
        event.target.checked = !success;
        Vue.set(user, 'IsObserver', !success);
      }
    },
    closeFastAssign() {
      this.$emit('closeFastAssign');
    },
    async saveWorkOrderUser(user, userPositionId) {
      const self = this;
      const workOrderUser = {
        WorkOrderId: self.workOrder.workOrderId,
        UserId: user.Id,
        UserPositionId: userPositionId,
      };
      try {
        blockui.pageBlock(self);
        const saveWorkOrderResponse = await api.saveWorkOrderUser({
          workOrderUser,
          forceSave: false,
        });
        blockui.unBlockPage();
        if (saveWorkOrderResponse.body.IsSuccess) {
          toastr.success(user.Name, self.translate('save_successfully'));
          return true;
        }
        const swalResponse = await swal.check(self, self.translate('work_order_time_conflict_title'), saveWorkOrderResponse.body.Message);
        if (!swalResponse.value) {
          return false;
        }
        blockui.pageBlock(self);
        const forceSaveWorkOrderResponse = await api.saveWorkOrderUser({
          workOrderUser,
          forceSave: true,
        });
        blockui.unBlockPage();
        if (forceSaveWorkOrderResponse.body.IsSuccess) {
          toastr.success(user.Name, self.translate('save_successfully'));
          return true;
        }
        toastr.error(forceSaveWorkOrderResponse.body.Message);
        return false;
      } catch (e) {
        blockui.unBlockPage();
        return false;
      }
    },
    async deleteWorkOrderUser(user) {
      const self = this;
      blockui.pageBlock(self);
      try {
        const deleteWorkOrderUsersResponse = await api.deleteWorkOrderUsers({
          WorkOrderId: self.workOrder.workOrderId,
          UserId: user.Id,
        });
        blockui.unBlockPage();
        if (deleteWorkOrderUsersResponse.body.IsSuccess) {
          toastr.success(user.Name, self.translate('delete_successfully'));
          return true;
        }
        toastr.error(deleteWorkOrderUsersResponse.body.Message);
        return false;
      } catch (e) {
        blockui.unBlockPage();
        return false;
      }
    },
    searchUser() {
      mApp.initTooltips();
    },
    workOrderTime(date) {
      return moment(date).locale(localStorage.getItem('vue-lang')).format('HH:mm');
    },
  },
  computed: {
    ...mapState({
      enumUserPositions: (state) => state.enums.userPositions,
    }),
    filteredUsers() {
      return this.users.filter((user) => user.Name.trim().toLocaleLowerCase(localStorage.getItem('vue-lang')).match(this.searchText.trim().toLocaleLowerCase(localStorage.getItem('vue-lang')).normalize('NFC')) || user.Name.toLocaleLowerCase('tr-TR').match(this.searchText.toLocaleLowerCase(localStorage.getItem('vue-lang')).normalize('NFC')),
        // if (typeof user.Name === 'string' && user.Name.trim().toLocaleLowerCase(localStorage.getItem("vue-lang")).indexOf(this.searchText.trim().toLocaleLowerCase(localStorage.getItem("vue-lang"))) > -1) {
        //     return user;
        // }
      );
    },
  },
};
</script>
<style scoped>
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: #0ae;
        background-image: -webkit-gradient(linear, 0 0, 0 100%,
        color-stop(.5, rgba(255, 255, 255, .2)),
        color-stop(.5, transparent), to(transparent));
    }

</style>
