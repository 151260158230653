import Vue from 'vue';
import * as api from '../services/api';
import * as datatable from '../global/datatables';
import * as blockui from '../../helpers/block-ui';
import Select2 from '../../components/shared/select2';
import * as dateFormat from '../../helpers/dateFormat';

export default {
  data() {
    return {
      roleList: [],
      workGroupList: [],
      workGroupRaciRoles: {},
      fullRoleList: {},
    };
  },
  mounted() {
    $('form').validate();
    const self = this; let
      table;
    const columns = [
      {
        data: 'Id',
        title: 'ID',
        sortable: true,
        width: 40,
        defaultContent: '',
      }, {
        data: 'Name',
        title: this.translate('work_group'),
        sortable: 'asc',
        defaultContent: '',
      }, {
        data: 'RM',
        title: `${this.translate('responsible')} (${this.translate('master')}) <span class="asteriks">*</span>`,
        sortable: false,
        defaultContent: '',
        render: (data, type, row) => `<div class="form-group m-form__group"><div id="RM_${row.Id}"></div></div>`,
      }, {
        data: 'RO',
        title: `${this.translate('responsible')} (${this.translate('observer')})`,
        sortable: false,
        defaultContent: '',
        render: (data, type, row) => `<div class="form-group m-form__group"><div id="RO_${row.Id}"></div></div>`,
      }, {
        data: 'A',
        title: `${this.translate('accountable')} <span class="asteriks">*</span>`,
        sortable: false,
        defaultContent: '',
        render: (data, type, row) => `<div class="form-group m-form__group"><div id="A_${row.Id}"></div></div>`,
      }, {
        data: 'C',
        title: this.translate('consulted'),
        sortable: false,
        defaultContent: '',
        render: (data, type, row) => `<div class="form-group m-form__group"><div id="C_${row.Id}"></div></div>`,
      }, {
        data: 'I',
        title: this.translate('informed'),
        sortable: false,
        defaultContent: '',
        render: (data, type, row) => `<div class="form-group m-form__group"><div id="I_${row.Id}"></div></div>`,
      }, {
        data: 'Save',
        title: this.translate('save'),
        sortable: false,
        class: 'not-export',
        defaultContent: '',
        render: (data, type, row) => `<button id="save_${row.Id}" class="btn btn-outline-primary save-button not-export">${this.translate('save')}</button>`,
      }];

    const datatableContainer = '#raci_matrix_list';
    api.getUserRoleList().then((res) => {
      res.body.forEach((x) => {
        self.fullRoleList[x.Id] = x;
      });
    });

    api.getUserRoles().then((res) => {
      self.roleList = res.body;

      Vue.getDatatables = () => {
        $(datatableContainer).on('init.dt', handlers);
        blockui.blockElement(self, '.m-portlet');
        const isDataTable = $.fn.DataTable.isDataTable(datatableContainer);
        api.getWorkGroupList().then((response) => {
          self.workGroupList = response.body;
          self.workGroupList.forEach((workGroup) => {
            self.workGroupRaciRoles[workGroup.Id] = {};
            self.workGroupRaciRoles[workGroup.Id].isRequired = false;
            self.workGroupRaciRoles[workGroup.Id]['1'] = [];
            self.workGroupRaciRoles[workGroup.Id]['2'] = '';
            self.workGroupRaciRoles[workGroup.Id]['3'] = [];
            self.workGroupRaciRoles[workGroup.Id]['4'] = [];
            self.workGroupRaciRoles[workGroup.Id]['5'] = [];
            workGroup.WorkGroupRoles.forEach((workGroupRole, i) => {
              if (workGroupRole.IsResponsible) {
                self.workGroupRaciRoles[workGroup.Id]['1'].push(workGroupRole.RoleId);
              }
              if (workGroupRole.IsAccountable) {
                self.workGroupRaciRoles[workGroup.Id]['2'] = workGroupRole.RoleId;
              }
              if (workGroupRole.IsConsulted) {
                self.workGroupRaciRoles[workGroup.Id]['3'].push(workGroupRole.RoleId);
              }
              if (workGroupRole.IsInformed) {
                self.workGroupRaciRoles[workGroup.Id]['4'].push(workGroupRole.RoleId);
              }
              if (workGroupRole.IsObserver) {
                self.workGroupRaciRoles[workGroup.Id]['5'].push(workGroupRole.RoleId);
              }
            });
          });

          if (isDataTable) {
            const dt = $(datatableContainer).DataTable();
            dt.clear();
            dt.rows.add(response.body);
            dt.draw();
          } else {
            table = datatable.renderTable({
              exportFileName: self.translate('work_group'),
              container: '#raci_matrix_list',
              data: response.body,
              columns,
              paging: true,
              pageSizeSelect: [10],
              sortable: true,
              colReOrder: false,
              buttons: [
                {
                  extend: 'excelHtml5',
                  title() {
                    return `${self.translate('raci_matrix')}-${dateFormat.viewDateTimeFormat(new Date())}`;
                  },
                  className: 'excelButton',
                  exportOptions: {
                    orthogonal: 'export',
                    columns: 'th:not(.not-export)',
                    format: {
                      body: self.customExport,
                    },
                  },
                }, {
                  extend: 'pdfHtml5',
                  title() {
                    return `${self.translate('raci_matrix')}-${dateFormat.viewDateTimeFormat(new Date())}`;
                  },
                  className: 'pdfButton',
                  exportOptions: {
                    orthogonal: 'export',
                    columns: 'th:not(.not-export)',
                    format: {
                      body: self.customExport,
                    },
                  },
                }, {
                  extend: 'print',
                  title() {
                    return `${self.translate('raci_matrix')}-${dateFormat.viewDateTimeFormat(new Date())}`;
                  },
                  className: 'printButton',
                  exportOptions: {
                    orthogonal: 'export',
                    columns: 'th:not(.not-export)',
                    format: {
                      body: self.customExport,
                    },
                  },
                }],
            });
            table.on('draw', handlers);
          }
          blockui.unBlockElement('.m-portlet');
        }, (error) => {
          blockui.unBlockElement('.m-portlet');
        });
      };

      Vue.getDatatables();
    });

    function handlers() {
      self.$nextTick(() => {
        const dt = $('#raci_matrix_list').DataTable();
        $('#raci_matrix_list tr').each(function (j) {
          const workGroup = dt.row($(this)).data();
          if (workGroup) {
            if ($(`#RM_${workGroup.Id}`).length > 0) {
              const Profile = Vue.extend(Select2);
              self.workGroupRaciRoles[workGroup.Id].RM = new Profile({
                propsData: {
                  items: self.roleList,
                  name: `RM_${workGroup.Id}_select`,
                  multiple: true,
                  selected: self.workGroupRaciRoles[workGroup.Id]['1'],
                  // required: self.workGroupRaciRoles[workGroup.Id]['isRequired'],
                  dropdownParent: 'body',
                },
              }).$mount(`#RM_${workGroup.Id}`);
            }
            if ($(`#RO_${workGroup.Id}`).length > 0) {
              const Profile = Vue.extend(Select2);
              self.workGroupRaciRoles[workGroup.Id].RO = new Profile({
                propsData: {
                  items: self.roleList,
                  name: `RO_${workGroup.Id}_select`,
                  multiple: true,
                  selected: self.workGroupRaciRoles[workGroup.Id]['5'],
                  dropdownParent: 'body',
                },
              }).$mount(`#RO_${workGroup.Id}`);
            }
            if ($(`#A_${workGroup.Id}`).length > 0) {
              const Profile = Vue.extend(Select2);
              self.workGroupRaciRoles[workGroup.Id].A = new Profile({
                propsData: {
                  items: self.roleList,
                  name: `A_${workGroup.Id}_select`,
                  multiple: false,
                  selected: self.workGroupRaciRoles[workGroup.Id]['2'],
                  // required: self.workGroupRaciRoles[workGroup.Id]['isRequired'],
                  dropdownParent: 'body',
                },
              }).$mount(`#A_${workGroup.Id}`);
            }
            if ($(`#C_${workGroup.Id}`).length > 0) {
              const Profile = Vue.extend(Select2);
              self.workGroupRaciRoles[workGroup.Id].C = new Profile({
                propsData: {
                  items: self.roleList,
                  name: `C_${workGroup.Id}_select`,
                  multiple: true,
                  selected: self.workGroupRaciRoles[workGroup.Id]['3'],
                  dropdownParent: 'body',
                },
              }).$mount(`#C_${workGroup.Id}`);
            }
            if ($(`#I_${workGroup.Id}`).length > 0) {
              const Profile = Vue.extend(Select2);
              self.workGroupRaciRoles[workGroup.Id].I = new Profile({
                propsData: {
                  items: self.roleList,
                  name: `I_${workGroup.Id}_select`,
                  multiple: true,
                  selected: self.workGroupRaciRoles[workGroup.Id]['4'],
                  dropdownParent: 'body',
                },
              }).$mount(`#I_${workGroup.Id}`);
            }
          }
        });
        self.$nextTick(() => {
          $('#raci_matrix_list select').on('change', (e) => {
            $(e.target).closest('tr').addClass('selected');
            $($.fn.dataTable.tables(true)).DataTable()
              .columns.adjust()
              .responsive.recalc();
          });
          $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust()
            .responsive.recalc();
        });
      });
    }

    window.$('#raci_matrix_list').on('click', '.save-button', function (e) {
      e.preventDefault();
      const dt = $('#raci_matrix_list').DataTable();
      const row = window.$(this).closest('tr');
      const workGroup = dt.row(row).data();
      const formData = {};
      formData.workGroupId = workGroup.Id;
      self.workGroupRaciRoles[workGroup.Id]['1'] = $(`[name="RM_${workGroup.Id}_select"]`).val();
      self.workGroupRaciRoles[workGroup.Id]['2'] = $(`[name="A_${workGroup.Id}_select"]`).val();
      self.workGroupRaciRoles[workGroup.Id].RM.required = true;
      self.workGroupRaciRoles[workGroup.Id].A.required = true;
      self.workGroupRaciRoles[workGroup.Id].RM.selected = self.workGroupRaciRoles[workGroup.Id]['1'];
      self.workGroupRaciRoles[workGroup.Id].A.selected = self.workGroupRaciRoles[workGroup.Id]['2'];

      self.$nextTick(() => {
        const form = window.$(this).closest('form');
        if (form.valid()) {
          formData.racies = [];
          const selects = row.find('select');
          selects.each(function (index) {
            if ($(this).val()) {
              let raciId = 0;
              const name = $(this).attr('name');
              if (name.startsWith('RM')) {
                raciId = 1;
              } else if (name.startsWith('A')) {
                raciId = 2;
              } else if (name.startsWith('C')) {
                raciId = 3;
              } else if (name.startsWith('I')) {
                raciId = 4;
              } else if (name.startsWith('RO')) {
                raciId = 5;
              }
              if (Array.isArray($(this).val())) {
                $(this).val().forEach((value) => {
                  formData.racies.push({ key: value, value: raciId });
                });
              } else {
                formData.racies.push({ key: $(this).val(), value: raciId });
              }
            }
          });
          blockui.pageBlock();
          api.saveRaci(formData).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('save_successfully'));
              row.removeClass('selected');
              // self.workGroupRaciRoles[formData.workGroupId] = {};
              /*              formData.racies.forEach((keyValuePair) => {
                              if (keyValuePair.value == 2) {
                                self.workGroupRaciRoles[formData.workGroupId][keyValuePair.value] = keyValuePair.key;
                              } else {
                                if (!self.workGroupRaciRoles[formData.workGroupId][keyValuePair.value]) {
                                  self.workGroupRaciRoles[formData.workGroupId][keyValuePair.value] = [];
                                }
                                self.workGroupRaciRoles[formData.workGroupId][keyValuePair.value].push(keyValuePair.key);
                              }
                            }); */
            } else {
              toastr.error(response.body.Message);
            }
            blockui.unBlockPage();
          }, (error) => {
            blockui.unBlockPage();
          });
          self.workGroupRaciRoles[workGroup.Id]['1'] = $(`[name="RM_${workGroup.Id}_select"]`).val();
          self.workGroupRaciRoles[workGroup.Id]['2'] = $(`[name="A_${workGroup.Id}_select"]`).val();
          self.workGroupRaciRoles[workGroup.Id].RM.required = false;
          self.workGroupRaciRoles[workGroup.Id].A.required = false;
          self.workGroupRaciRoles[workGroup.Id].RM.selected = self.workGroupRaciRoles[workGroup.Id]['1'];
          self.workGroupRaciRoles[workGroup.Id].A.selected = self.workGroupRaciRoles[workGroup.Id]['2'];
        } else {
          self.workGroupRaciRoles[workGroup.Id]['1'] = $(`[name="RM_${workGroup.Id}_select"]`).val();
          self.workGroupRaciRoles[workGroup.Id]['2'] = $(`[name="A_${workGroup.Id}_select"]`).val();
          self.workGroupRaciRoles[workGroup.Id].RM.required = false;
          self.workGroupRaciRoles[workGroup.Id].A.required = false;
          self.workGroupRaciRoles[workGroup.Id].RM.selected = self.workGroupRaciRoles[workGroup.Id]['1'];
          self.workGroupRaciRoles[workGroup.Id].A.selected = self.workGroupRaciRoles[workGroup.Id]['2'];
        }
      });
    });
  },
  methods: {
    customExport(data, row, column, node) {
      const self = this;
      let a = [];
      let input = $(node).find('select');
      if (input.length > 0) {
        a = input.attr('name').split('_');
      } else {
        input = $(node).find('div');
        if (input.length > 0) {
          a = input.attr('Id').split('_');
        }
      }
      if (a.length > 0) {
        const workGroupId = a[1];
        let result = '';
        if (self.workGroupRaciRoles[workGroupId]) {
          if (a[0] == 'RM') {
            self.workGroupRaciRoles[workGroupId][1].forEach((role) => {
              result += self.fullRoleList[role].Name;
            });
          } else if (a[0] == 'RO') {
            self.workGroupRaciRoles[workGroupId][5].forEach((role) => {
              result += self.fullRoleList[role].Name;
            });
          } else if (a[0] == 'A' && self.workGroupRaciRoles[workGroupId][2]) {
            result += self.fullRoleList[self.workGroupRaciRoles[workGroupId][2]].Name;
          } else if (a[0] == 'C') {
            self.workGroupRaciRoles[workGroupId][3].forEach((role) => {
              result += self.fullRoleList[role].Name;
            });
          } else if (a[0] == 'I') {
            self.workGroupRaciRoles[workGroupId][4].forEach((role) => {
              result += self.fullRoleList[role].Name;
            });
          }
        }
        return result;
      }
      return data;
    },
  },
};
