






































import { Component, Vue } from 'vue-property-decorator'
import SparePartTaskIdGrid from '../NewSparePart/SparePartTaskIdGrid.vue'
import SparePartTabDialog from '../NewSparePart/SparePartTabDialog.vue'

@Component<SparePartTaskId>({
  components: {
    'spare-part-tab-dialog': SparePartTabDialog,
    'spare-part-task-id-grid': SparePartTaskIdGrid,
  },
})
export default class SparePartTaskId extends Vue {
  isDialogActive: boolean = false
  newWorkOrderId: string = ''
  isFetching: boolean = false
  debounceTimer: number | null = null

  async reserveApproved() {
    if (!this.newWorkOrderId.trim()) {
      toastr.error(this.translate('please_enter_valid_task_id'))
      return
    }
    this.isDialogActive = true
    await this.$nextTick()
    ;(this.$refs.sparePartTabDialog as SparePartTabDialog).showDialog()
  }

  backSparePart() {
    this.$router.push({ name: 'SparePart' })
  }

  debouncedFetchData() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }

    this.debounceTimer = window.setTimeout(() => {
      this.fetchData()
    }, 500)
  }

  async fetchData() {
    if (this.isFetching) {
      return
    }
    if (!this.newWorkOrderId.trim()) {
      toastr.error(this.translate('please_enter_valid_task_id'))
      return
    }

    this.isFetching = true

    try {
      await (this.$refs.sparePartGrid as SparePartTaskIdGrid).updateFilterSettings(Number(this.newWorkOrderId))
    } finally {
      this.isFetching = false
    }
  }
}
