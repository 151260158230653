import Vue from 'vue';
import FirstStep from '../../components/partials/add-capacity-equipment-steps/FirstStep';
import SecondStep from '../../components/partials/add-capacity-equipment-steps/SecondStep';
import * as api from '../services/api';
import * as dateFormat from '../../helpers/dateFormat';
import * as blockui from '../../helpers/block-ui';

export default {
  data() {
    return {
      stepCount: 2,
      wizardOptions: {
        manualStepForward: false,
      },
      capacityEquipment: {},
      step: 0,
      formData: {},
    };
  },
  props: [
    'selectedCapacityEquipment',
    'isUpdate',
    'selectedTable',
  ],
  components: {
    'first-step': FirstStep,
    'second-step': SecondStep,
  },
  mounted() {
    const self = this;
    new mWizard('capacity_equipment_wizard', {}).on('change', (e) => {
      Vue.justNumber();
      self.step = e.currentStep;
      if (self.step !== self.stepCount) {
        $(e.element).find('.wizard-submit-button').addClass('m--hide');
        $(e.element).find('.wizard-next-button').removeClass('m--hide');
      }
    });
    self.capacityEquipment = self.selectedCapacityEquipment;
    self.step = 1;
    self.$nextTick(() => {
      Vue.justNumber();
    });

    $('#capacity_equipment_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });
  },
  methods: {
    openRoomModal() {
      this.$emit('open-room-modal');
    },
    addCapacityEquipmentWizard(e, status) {
      const self = this;
      e.preventDefault();
      const wizard = new mWizard('capacity_equipment_wizard', this.wizardOptions);
      const form = $("form[name='stepForm']");
      const wizardForm = $("div[name='wizardForm']");

      if (status === 'next') {
        self.$refs['first-step-child'].$v.$touch();
        const isValid = !self.$refs['first-step-child'].$v.$invalid;
        if (isValid && form.valid()) {
          $.map(form.serializeArray(), (item) => {
            if (item.name === 'DateOfInstallation' || item.name === 'DateOfWarrantyStart' || item.name === 'ExpectedLifeEndDate' && item.value) {
              Object.assign(this.formData, { [item.name]: item.value ? dateFormat.postDateFormatToDate(dateFormat.postDateFormat(item.value)) : null });
            } else {
              Object.assign(this.formData, { [item.name]: item.value });
            }
          });

          if (this.capacityEquipment === undefined) {
            this.capacityEquipment = {};
          }
          Object.assign(this.capacityEquipment, this.formData);
          this.step++;
          setTimeout(() => {
            wizard.goNext();
          });
          if (this.step === this.stepCount) {
            wizardForm.find('.wizard-submit-button').removeClass('m--hide');
            wizardForm.find('.wizard-next-button').addClass('m--hide');
          }
        }
      } else if (status === 'prev') {
        this.capacityEquipment = this.formData;
        setTimeout(() => {
          wizard.goPrev();
        });
        wizardForm.find('.wizard-submit-button').addClass('m--hide');
        wizardForm.find('.wizard-next-button').removeClass('m--hide');
      } else if (status === 'submit') {
        if (form.valid()) {
          const stepData = {
            InputDevices: [],
            OutputDevices: [],
          };
          blockui.blockModal(self);
          $.map(form.serializeArray(), (item) => {
            if (item.name === 'source') {
              if (item.value) {
                stepData.InputDevices.push({ FromDeviceId: item.value });
              }
            } else if (item.name === 'output') {
              if (item.value) {
                stepData.OutputDevices.push({ DeviceId: item.value });
              }
            } else {
              Object.assign(stepData, { [item.name]: item.value });
            }
          });

          Object.assign(this.formData, stepData);
          const formDataWithFormattedDates = this.formData;
          if (formDataWithFormattedDates.DateOfInstallation) {
            formDataWithFormattedDates.DateOfInstallation = dateFormat.postDateFormat(dateFormat.viewDateFormat(formDataWithFormattedDates.DateOfInstallation));
          }
          if (formDataWithFormattedDates.DateOfWarrantyStart) {
            formDataWithFormattedDates.DateOfWarrantyStart = dateFormat.postDateFormat(dateFormat.viewDateFormat(formDataWithFormattedDates.DateOfWarrantyStart));
          }
          if (formDataWithFormattedDates.ExpectedLifeEndDate) {
            formDataWithFormattedDates.ExpectedLifeEndDate = dateFormat.postDateFormat(dateFormat.viewDateFormat(formDataWithFormattedDates.ExpectedLifeEndDate));
          }

          api.saveCapacityEquipment(formDataWithFormattedDates).then((response) => {
            if (response.body.IsSuccess) {
              toastr.success(self.translate('save_successfully'));
              if (self.$route.meta.xyz) {
                Vue.getDatatables('.capacity_equipment_list');
                if (!self.isUpdate) {
                  self.$parent.slc.rooms.ChildCount = self.$parent.slc.rooms.ChildCount ? self.$parent.slc.rooms.ChildCount + 1 : 1;
                }
              } else if (!self.isUpdate) {
                if ($('[name=capacity_equipment_list]').length > 0) {
                  if (!$(`table [data-id='${self.formData.RoomId}']`).eq(0).closest('tr').hasClass('shown')) {
                    $(`table [data-id='${self.formData.RoomId}']`).eq(0).closest('tr').find('.details-control')
                      .removeClass('m--hide');
                    $(`table [data-id='${self.formData.RoomId}']`).eq(0).closest('tr').find('.details-control')
                      .eq(0)
                      .click();
                  } else {
                    Vue.getDatatables($(`table [data-id='${self.formData.RoomId}']`).closest('tr').next().find('table'));
                  }
                } else {
                  self.$store.dispatch('getRelatedCapacityEquipmentList', self.$router.history.current.name);
                }
              } else {
                Vue.getDatatables($(`table [data-id='${self.formData.RoomId}']`).closest('tr').next().find('table'));
              }
              $('#capacity_equipment_modal').modal('toggle');
            } else {
              toastr.error(response.body.Message);
            }
            blockui.unBlockModal();
          }, (error) => {
            blockui.unBlockModal();
          });
        }
      }
    },
  },
  watch: {
    step(e) {
      this.$nextTick(() => {
        const form = $("form[name='stepForm']");
        form.validate();
      });
    },
  },
};
