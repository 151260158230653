import * as api from '../../scripts/services/api';

// initial state
const state = {
  vendorList: [],
};

// getters
const getters = {};

// actions
const actions = {
  getVendorList({ commit }) {
    api.getVendors().then((res) => {
      commit('setVendorList', res.body);
    });
  },
};

// mutations
const mutations = {
  setVendorList(state, vendors) {
    state.vendorList = vendors;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
