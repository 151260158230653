



























import { Component } from 'vue-property-decorator';
import { DataStateChangeEventArgs } from "@syncfusion/ej2-vue-grids";
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import {  CFMDTOShiftType } from '@/scripts/services/ClientApiAuto'
import ShiftTypeDialog from './ShiftTypeDialog.vue';

@Component<ShiftType>({
    
    components: {
        'shift-type-dialog': ShiftTypeDialog,
    }
})
export default class ShiftType extends CFMGridPage<CFMDTOShiftType> {
    expandProperties = ["Color/Name"];
    entityType = "ShiftType";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];

    async mounted(){
        this.dataStateChange(this.state);
    }
    timeFormatter(field: string, data: any, column: Object): string{
        // 12:15:00.0000000 -> 12:15
        const timeSplited = data[field].split(":");
        return `${timeSplited[0]}:${timeSplited[1]}`;
    }

    refreshGrid(){
        this.dataStateChange(this.state);
    }
    recordDoubleClick(args:any){
        this.edit(args.rowData.Id);
    }
    async delete(data: CFMDTOShiftType){
        const e = await swal.check(this, data.Name);
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.shiftType.shiftTypeDelete({id: data.Id});
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }
    async edit(id: number){
        this.selectedId = id;
        this.isDialogActive = true;
        await this.$nextTick();
        (<ShiftTypeDialog>this.$refs.shiftTypeDialog).showDialog();
    }
    async add(){
        this.selectedId = null;
        this.isDialogActive = true;
        await this.$nextTick();
        (<ShiftTypeDialog>this.$refs.shiftTypeDialog).showDialog();
    }
    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id);
            }
        }
        else if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
      this.toolbarClickBase(args);
    }

    // getDateFromTimeSpan(input){

    //     var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
    //     var hours = 0, minutes = 0, seconds = 0, totalseconds;

    //     var matches = reptms.exec(input);
    //     if (matches[1]) hours = Number(matches[1]);
    //     if (matches[2]) minutes = Number(matches[2]);
    //     if (matches[3]) seconds = Number(matches[3]);
    //     totalseconds = hours * 3600  + minutes * 60 + seconds;
    //     var str = new Date(totalseconds * 1000).toISOString().substr(11, 8);
    //     return str;
    // }

}
