<template>
  <div
    class="m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default">
    <div class="m-grid m-grid--hor m-grid--root m-page">
      <div class="m-grid__item m-grid__item--fluid m-grid  m-error-1"
           :style="{'background-image': 'url('+require('@/assets/images/error_bg.jpg')+')'}">
        <div class="m-error_container">
					<span class="m-error_number">
						<h1>
							404
						</h1>
					</span>
          <p class="m-error_desc" v-lang.display_error_404></p>
          <a href="#/Home" class="m-error_desc">Home</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundView',
  data() {
    return {};
  },
  mounted() {
    $('.m-page').css('height', $(document).height());
  },
};
</script>
