export default {
  props: {
    currentList: {
      default: () => [],
      type: Array,
    },
    defaultChartList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      isShown: false,
    };
  },
  mounted() {
    const self = this;
  },
  computed: {
    charts() {
      const self = this;
      return self.defaultChartList.map((defaultChart) => {
        const current = self.currentList.find((current) => current.id == defaultChart.id);
        return {
          chartName: defaultChart.chartName,
          id: defaultChart.id,
          isSelected: (current && current.isActive),
        };
      });
    },
  },
  methods: {
    redraw() {},
    toggleChart(id) {
      const self = this;
      const current = self.currentList.find((current) => current.id == id);
      if (current.isActive) {
        this.$eventHub.$emit('close-chart', id);
      } else {
        this.$eventHub.$emit('open-chart', id);
      }
    },
  },
};
