










































































import CFMDialog from "@/scripts/CFMDialog";
import { Component, Prop } from "vue-property-decorator";

import * as blockui from "@/helpers/block-ui";
import * as swal from "@/helpers/swal";

import { CFMDTOMaintenancePlan, CFMModelSuitableWorkUserSP } from "@/scripts/services/ClientApiAuto";
import { mixinz } from "@/scripts/global/mixinz";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns"; //import { MultiSelectComponent, CheckBoxSelection } from "@syncfusion/ej2-vue-dropdowns";
import Multiselect from 'vue-multiselect'

@Component<MaintenancePlanTransformToWorkOrderDialog>({
  data() {
    return {};
  },

  methods: {},

  components: {
    "ejs-multiselect": MultiSelectComponent,
    'vue-multiselect': Multiselect
  },

  /*
  provide: {
    'multiselect': [CheckBoxSelection]
  },
  */

  validations() {
    return {};
  },

  mixins: [mixinz],
})
export default class MaintenancePlanTransformToWorkOrderDialog extends CFMDialog {
  @Prop()
  selectedMaintenancePlans?: Array<CFMDTOMaintenancePlan>;

  userData: CFMModelSuitableWorkUserSP[] = [];
  observerUserData: CFMModelSuitableWorkUserSP[] = [];

  userFields = { groupBy: "Role", text: "UserDisplayText", value: "Id" };
  observerUserFields = { groupBy: "Role", text: "ObserverUserDisplayText", value: "Id" };

  userDataPlaceHolder = "İşi Yapacak Personel Seçiniz";
  observerUserDataPlaceHolder = "Refakatçi Personel Seçiniz";
  /*
  multiMode = 'CheckBox';
  filterPlaceholder = 'Search vegetables';
  showSelectAll = true;
  showDropDownIcon = true;
  enableSelectionOrder = false;
  enableGroupCheck = true;
  popupHeight = "250px";
  */

  userDataForSelect = [];
  observerUserDataForSelect = [];

  selectedForMasterUser = [];
  selectedForObserverUser = [];

  groupBy(list:any, keyGetter:any) {
    const map = new Map();
    list.forEach((item:any) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
  }


  async created() {
    blockui.blockModal(this);
    const self = this;
    const promises = [this.getWorkOrderUserGetSuitableWorkUsers()];
    await Promise.all(promises);
    blockui.unBlockModal();
  }

  async getWorkOrderUserGetSuitableWorkUsers() {
    this.userData = [];
    this.observerUserData = [];

    if (this.selectedMaintenancePlans !== undefined && (this.selectedMaintenancePlans?.length ?? 0) > 0) {
      const workOrderIds: string = this.selectedMaintenancePlans.map((a) => {
        return a.Id;
      }).join(",");

      this.apiClient.workOrderUser.workOrderUserGetSuitableMultipleWorkUsers({workOrderIds: workOrderIds,}).then((response) => {
        response.data.forEach((user) => {
          user.ShiftDescription = user.IsHaveShift ? "Shift: Yes" : "Shift: No";
          user.OverlappedDescription = user.IsHaveWorkOrder ? "Workload Availability: No" : "Workload Availability: Yes";

          if (user.IsAssignableToMaster) {
            user.UserTrainedDescription = user.IsTrainedForMaster ? "Trained: Yes" : "Trained: No";
            user.UserDisplayText = `${user.Name} - ${user.UserTrainedDescription} - ${user.ShiftDescription} - ${user.OverlappedDescription}`;
            this.userData.push(user);
          }
          
          if (user.IsAssignableToObserver) {
            user.ObserverUserTrainedDescription = user.IsTrainedForObserver ? "Trained: Yes" : "Trained: No";
            user.ObserverUserDisplayText = `${user.Name} - ${user.ObserverUserTrainedDescription} - ${user.ShiftDescription} - ${user.OverlappedDescription}`;
            this.observerUserData.push(user);
          }
        });
        //@ts-ignore
        this.userDataForSelect = Array.from(this.groupBy(this.userData, user => user.Role), ([role, value]) => ({ role, value }));
        //@ts-ignore
        this.observerUserDataForSelect = Array.from(this.groupBy(this.observerUserData, user => user.Role), ([role, value]) => ({ role, value }));
      });
    }
  }

  async onSubmit() {
    blockui.blockModal(this);
    blockui.unBlockModal();
  }

  async handleSave() {
    let hasUserShiftWarning: boolean = false;
    let hasUserOverlapWarning: boolean = false;

    let hasObserverUserShiftWarning: boolean = false;
    let hasObserverOverlapWarning: boolean = false;

    if (this.selectedForMasterUser.length === 0 && this.selectedForObserverUser.length === 0) {
      return;
    }

    this.selectedForMasterUser?.forEach(async (selectedUser) => {
      if (selectedUser !== undefined && selectedUser !== null) {
        //@ts-ignore
        hasUserShiftWarning = (selectedUser.IsHaveShift ?? false) == false;
        //@ts-ignore
        hasUserOverlapWarning = (selectedUser.IsHaveWorkOrder ?? false) == true;
      }
    });

    this.selectedForObserverUser?.forEach(async (selectedObserverUser) => {
      if (selectedObserverUser !== undefined && selectedObserverUser !== null) {
        //@ts-ignore
        hasObserverUserShiftWarning = (selectedObserverUser.IsHaveShift ?? false) == false;
        //@ts-ignore
        hasObserverOverlapWarning = (selectedObserverUser.IsHaveWorkOrder ?? false) == true;
      }
    })

    if(hasUserShiftWarning || hasUserOverlapWarning || hasObserverUserShiftWarning || hasObserverOverlapWarning) {
      const alertTitle: string = "Atanmak istenen iş emirleri !!!";
      const alertMessage: string = 
        (hasUserShiftWarning || hasUserOverlapWarning ? "İşi Yapacak Personelin(Personellerin) " : "") +
        (hasUserShiftWarning ? "vardiyası ile eşleşmemektedir, " : "") +
        (hasUserOverlapWarning ? "diğer bir iş emriyle çakışan işleri bulunmaktadır. " : "") + "<br>" +
        (hasObserverUserShiftWarning || hasObserverOverlapWarning ? "Refakatçi Personelin(Personellerin) " : "") +
        (hasObserverUserShiftWarning ? "vardiyası ile eşleşmemektedir, " : "") + 
        (hasObserverOverlapWarning ? "diğer bir iş emriyle çakışan işleri bulunmaktadır." : "");

        // @ts-ignore
        let swalResponse = await swal.checkHtml(this, alertTitle, alertMessage)
        swalResponse.dismiss
        if (swalResponse !== undefined && swalResponse.dismiss !== undefined && swalResponse.dismiss === "cancel") { // if (!swalResponse.value) {
          return;
        }
    }
 
    let promisesForMaster = [];
    blockui.blockModal(this);
    //@ts-ignore
    for (let i = 0; i < this.selectedMaintenancePlans?.length; i++) {
      for (let j = 0; j < this.selectedForMasterUser.length; j++) {
        promisesForMaster.push(this.apiClient.workOrderUser.workOrderUserSave({
          // @ts-ignore
          WorkOrderId: this.selectedMaintenancePlans[i].Id,
          //@ts-ignore
          UserId: this.selectedForMasterUser[j].Id,
          UserPositionId: 1
        },
        { forceSave: true }));
      }
    }

    try {
      await Promise.all(promisesForMaster);
    } 
    catch (error) {

    }

    let promisesForObserver = [];
    // @ts-ignore
    for (let i = 0; i < this.selectedMaintenancePlans?.length; i++) {
      for (let j = 0; j < this.selectedForObserverUser.length; j++) {
        promisesForObserver.push(this.apiClient.workOrderUser.workOrderUserSave({
          // @ts-ignore
          WorkOrderId: this.selectedMaintenancePlans[i].Id,
          //@ts-ignore
          UserId: this.selectedForObserverUser[j].Id,
          UserPositionId: 2,
        },
        { forceSave: true }));
      }
    }
    try {
      await Promise.all(promisesForObserver);
      blockui.unBlockModal();
      this.saveSucceeded();
      this.close();
    } 
    catch (error) {
      blockui.unBlockModal();
      this.saveSucceeded();
      this.close();
    }
  }
} 
