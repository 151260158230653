import * as api from '../../scripts/services/api';

// initial state
const state = {
  productFamilyList: [],
};

// getters
const getters = {};

// actions
const actions = {
  getProductFamilyList({ commit }) {
    api.getProductFamilies().then((res) => {
      commit('setProductFamilyList', res.body);
    });
  },
};

// mutations
const mutations = {
  setProductFamilyList(state, product_families) {
    state.productFamilyList = product_families;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
