import * as api from '../../scripts/services/api';

// initial state
const state = {
  qualificationList: [],
  qualificationTypeList: [],
};

// getters
const getters = {};

// actions
const actions = {
  async getQualificationList({ commit }) {
    await api.getQualifications().then((res) => {
      commit('setQualificationList', res.body);
    });
  },
  getQualificationTypeList({ commit }) {
    api.getQualificationTypes().then((res) => {
      commit('setQualificationTypeList', res.body);
    });
  },
  getRoleQualificationTypeList({ commit }) {
    api.getQualificationTypes().then((res) => {
      commit('setQualificationTypeList', res.body);
    });
  },
};

// mutations
const mutations = {
  setQualificationList(state, qualifications) {
    state.qualificationList = qualifications;
  },
  setQualificationTypeList(state, qualificationTypes) {
    state.qualificationTypeList = qualificationTypes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
