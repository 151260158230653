import * as api from '../../scripts/services/api';
import * as blockui from '../../helpers/block-ui';
import moment from 'moment';

export default {
  name: 'WorkOrderQaQcQuestion',
  confirmApproveWorkOrder: false,
  props: {
    workOrderId: {
      type: [Number, String],
      required: true,
    },
    workOrderStatusId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      WorkOrderQaQcQuestion: [],
      isEditableQaqc: false
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      const self = this;
      blockui.blockElementPromise(this, this.$el);
      
      try {
        self.isEditableQaqc = (self.workOrderStatusId == 5 || self.workOrderStatusId == 6 || self.workOrderStatusId == 8); 
        const response = await api.getQAQCListByWorkOrderId(this.workOrderId);
        if (!response) {
          throw new Error('undefined_error');
        }
        this.WorkOrderQaQcQuestion = response.body;
        self.confirmApproveWorkOrder = self.WorkOrderQaQcQuestion.some(x => !x.IsChecked);
        this.formatQAQCList();
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(this.$el);
      }
    },
    formatQAQCList() {
      this.WorkOrderQaQcQuestion.forEach(qaqc => {
        if (qaqc.IsChecked) {
          qaqc.ModifiedDate = this.formatDate(qaqc.ModifiedDate);
          qaqc.ModifiedUser = this.formatUser(qaqc.ModifiedUser);
        } else {
          qaqc.ModifiedDate = '';
          qaqc.ModifiedUser = '';
        }
      });
    },

    formatDate(date) {
      return this.translate('answered_at')+": "+ moment(date).format('DD.MM.YYYY HH:mm');
    },
    formatUser(user) {
      return this.translate('answered_by')+": "+ user;
    },
    async qaqcIsChecked(id) {
      const success = await api.saveQAQCChecked(id);
      if (success) {
        toastr.success(this.translate('save_successfully'));
        await this.refreshQAQCList();
      }
    },
    async refreshQAQCList() {
      try {
        await this.loadData();
      } catch (e) {
        toastr.error(e.message);
      }
    }
  },
};
