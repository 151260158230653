import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  props: [
    'capacityEquipment',
    'isUpdate',
  ],
  computed: {
    ...mapState({
      formProcessList: (state) => state.form.formProcessList,
    }),
  },
  async mounted() {
    const self = this;
    const promises = [
      this.$store.dispatch('getFormProcessList', { deviceId: self.capacityEquipment.Id }),
    ];
    await Promise.all(promises);
  },
  methods: {
    showItem(index) {
      const selectedItem = this.formProcessList[index];
      this.$parent.$parent.editItem('pmpItems', { workOrderId: selectedItem.WorkOrderId, formId: selectedItem.FormId, procedureTypeId: selectedItem.ProcedureTypeId });
    },
  },
  watch: {
    formProcessList(e) {
      if (e) {
        this.$parent.selectedCapacityEquipment.PMPCount = e.length;
      }
    },
  },
};
