import * as api from '../../scripts/services/api';

// initial state
const state = {
  financialItemList: [],
  selectedServiceProviderItemId: null,
  financialModalServiceProviderList: [],

  // List
  capacityEquipmentList: [],
  capacityEquipmentBreadCrumbList: [],
  filterCapacityEquipmentBreadCrumbList: [],
  serviceProviderList: [],
  serviceProviderModalServiceProviderList: [],
  // Selected
  selectedCapacityEquipment: null,
  // Related Stores
  capacityEquipmentRelated: {
    WorkOrder: ['getCapacityEquipmentList'],
    RoomCapacityEquipment: ['getCapacityEquipmentList'],
    Inventory: ['getCapacityEquipmentList'],
  },

  massWorkOrderOperationsCapacityEquipmentList: [],

};

// getters
const getters = {
  getFinancialModalServiceProviderComboBox: (state) => state.financialModalServiceProviderList.map((x) => ({ Value: x.Id, Text: x.Vendor.Name })),
  getMassWorkOrderOperationsCapacityEquipmentList: (state) => state.massWorkOrderOperationsCapacityEquipmentList,
  getCapacityEquipmentBreadCrumbList: (state) => state.capacityEquipmentBreadCrumbList.map((x) => ({ Id: x.Value, Name: x.Text })),
  getFilterCapacityEquipmentBreadCrumbList: (state) => state.filterCapacityEquipmentBreadCrumbList.map((x) => ({ Id: x.Value, Name: x.Text })),
};

const wait = (delay, ...args) => new Promise((resolve) => setTimeout(resolve, delay, ...args));

// actions
const actions = {
  getRelatedCapacityEquipmentList({ commit, dispatch }, pathName) {
    const relatedList = state.capacityEquipmentRelated[pathName];
    relatedList.forEach((item) => {
      dispatch(item);
    });
  },
  getCapacityEquipmentList({ commit }) {
    api.getCapacityEquipments({ roomId: this.state.room.selectedRoom }).then((res) => {
      commit('setCapacityEquipmentList', res.body);
    });
  },
  async getCapacityEquipmentBreadCrumbList({ commit }, data) {
    await api.getCapacityEquipmentsBreadCrumb(data).then((res) => {
      commit('setCapacityEquipmentBreadCrumbList', res.body);
    });
  },
  async getFilterCapacityEquipmentBreadCrumbList({ commit }, data) {
    await api.getCapacityEquipmentsBreadCrumb(data).then((res) => {
      commit('setFilterCapacityEquipmentBreadCrumbList', res.body);
    });
  },
  getServiceProviderList({ commit }, data) {
    api.getServiceProviderList(data).then((res) => {
      commit('setServiceProviderList', res.body.Data);
    });
  },
  getServiceProviderModalServiceProviderList({ commit }, data) {
    api.getServiceProviderList(data).then((res) => {
      commit('setServiceProviderModalServiceProviderList', res.body.Data);
    });
  },
  getFinancialModalServiceProviderList({ commit }, data) {
    api.getServiceProviderList(data).then((res) => {
      commit('setFinancialModalServiceProviderList', res.body.Data);
    });
  },
  getFinancialItemList({ commit }, deviceId) {
    api.getFinancialItemList({ deviceId, serviceProviderId: state.selectedServiceProviderItemId }).then((res) => {
      commit('setFinancialItemList', res.body.Data);
    });
  },
  async getMassWorkOrderCapacityEquipmentList({ commit }, data) {
    await api.getDeviceFilter(data).then((res) => {
      commit('setMassWorkOrderOperationsCapacityEquipmentList', res.body.Data);
    });
  },
};

// mutations
const mutations = {
  setCapacityEquipmentList(state, rooms) {
    state.capacityEquipmentList = rooms;
  },
  setCapacityEquipmentBreadCrumbList(state, rooms) {
    state.capacityEquipmentBreadCrumbList = rooms;
  },
  setFilterCapacityEquipmentBreadCrumbList(state, rooms) {
    state.filterCapacityEquipmentBreadCrumbList = rooms;
  },
  setServiceProviderList(state, serviceProviders) {
    state.serviceProviderList = serviceProviders;
  },
  setServiceProviderModalServiceProviderList(state, serviceProviders) {
    state.serviceProviderModalServiceProviderList = serviceProviders;
  },
  setFinancialModalServiceProviderList(state, serviceProviders) {
    state.financialModalServiceProviderList = serviceProviders;
  },
  setFinancialItemList(state, financialItems) {
    state.financialItemList = financialItems;
  },
  setSelectedServiceProviderItemId(state, selectedServiceProviderItemId) {
    state.selectedServiceProviderItemId = selectedServiceProviderItemId;
  },
  setMassWorkOrderOperationsCapacityEquipmentList(state, massWorkOrderOperationsCapacityEquipmentList) {
    state.massWorkOrderOperationsCapacityEquipmentList = massWorkOrderOperationsCapacityEquipmentList;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
