import Vue from 'vue';
import { mapState } from 'vuex';
import * as api from '../../services/api';
import * as blockui from '../../../helpers/block-ui';
import * as dateFormat from '../../../helpers/dateFormat';

export default {
  props: ['referenceLibraryItem', 'isUpdate'],
  data() {
    return {
      file: {},
      maxFileSize: 20,
      fileTypeList: [],
      acceptedExtensions: [],
    };
  },
  components: {},
  computed: {
    ...mapState({
      documentStatuses: (state) => state.status.documentStatuses,
      documentValidityStatuses: (state) => state.status.documentValidityStatuses,
      timeUnitList: (state) => state.unit.timeUnitList,
    }),
    acceptedExtensionsString() {
      let string = '';
      this.acceptedExtensions.forEach((acceptedExtension, index) => {
        if (index == 0) {
          string += acceptedExtension;
        } else {
          string += `, ${acceptedExtension}`;
        }
      });
      return string;
    },
  },
  mounted() {
    const self = this;
    window.$('form').validate();

    api.getFileTypeList().then((response) => {
      self.fileTypeList = response.body.Data;
      self.fileTypeList.forEach((fileType) => {
        self.acceptedExtensions.push(fileType.Extension);
      });
    });

    self.$store.dispatch('getTimeUnitList');
    self.$store.dispatch('getDocumentStatuses');
    self.$store.dispatch('getDocumentValidityStatuses');

    $('#reference_library_item_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });

    const datetimePicker = $('#reference_library_item_modal .date-picker').datepicker({
      autoclose: true,
      language: localStorage.getItem('vue-lang'),
    });

    if (self.referenceLibraryItem.LastCheckDate) {
      datetimePicker.datepicker('update', new Date(self.referenceLibraryItem.LastCheckDate));
    }
    Vue.loadAsteriks();
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = new FormData();
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          if (item.name.startsWith('LastCheckDate')) {
            formData.append(item.name, dateFormat.postDateFormat(item.value));
          } else {
            formData.append(item.name, item.value);
          }
        });
        if (self.file[0]) formData.append('File', self.file[0]);
        api.saveReferenceLibraryItem(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            self.$store.dispatch('getReferenceLibraryList', { deviceId: self.referenceLibraryItem.DeviceId });
            $('#reference_library_item_modal').modal('toggle');
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    closeModal() {
      $('#reference_library_item_modal').modal('toggle');
    },
    onFileChange(e) {
      const { files } = e.currentTarget;
      const fileSize = ((files[0].size / 1024) / 1024).toFixed(4);
      const extension = files[0].name.split('.').pop().toLowerCase();
      if (typeof files[0].name !== 'undefined' && fileSize <= this.maxFileSize && this.acceptedExtensions.includes(extension)) {
        this.file = files;
      } else {
        toastr.error(this.translate('file_is_not_suitable'));
        this.file = {};
        e.target.value = '';
      }
    },
    downloadFile() {
      const self = this; let
        table;
      blockui.blockModal(self);
      api.getFile({ id: this.referenceLibraryItem.FileId, isContainsData: true }).then((response) => {
        self.downloadBlob(self.base64toBlob(response.body.Data.Base64, response.body.Data.ContentType), response.body.Data.Name);
        blockui.unBlockModal();
      });
    },
    base64toBlob(base64Data, type) {
      type = type || '';
      // Convert the Base64 string back to text.
      const byteString = atob(base64Data);

      // Convert that text into a byte array.
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type });
    },
    downloadBlob(blob, fileName) {
      const a = $("<a style='display: none;'/>");
      const url = window.URL.createObjectURL(blob);
      a.attr('href', url);
      a.attr('download', fileName);
      $('body').append(a);
      a[0].click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
  },
};
