<template>
    <div id="tree_select" class="tree-select">
        <div class="list"
             :style="{'max-height':maxHeight,'height':height, borderColor:invalid?'red':'black'}"
             style="display: flex;flex-direction: column;">
            <!--<div>
                <input type="text"
                       class="clear"
                       style="width: 100%;"
                       :placeholder="translate('search')"
                       v-model="tempSearchText"
                       @keyup="searchTextChanged"/>
            </div>-->
            <div style="overflow-y: auto; width: 100%;">
                <div class="list" :style="{borderColor:invalid?'red':'black'}">
                    <v-jstree :data="nodes" :async="loadData" @item-click="itemClick"
                              multiple
                              show-checkbox
                              ref="warehouseTree">
                    </v-jstree>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VJstree from 'vue-jstree-promise';
import { minValue, required } from 'vuelidate/lib/validators';
import * as api from '../../../../scripts/services/api';
import * as blockui from '../../../../helpers/block-ui';

export default {
  props: {
    value: {
      required: false,
      type: Array,
    },
    multiple: {
      required: false,
      type: Boolean,
    },
    maxHeight: {
      type: String,
    },
    height: {
      default: '100%',
      type: String,
    },
    invalid: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      nodes: [],

      tempSearchText: '',
      searchText: '',
      searchTimeout: null,
      selectedValue: [],
      enumTreeViewDeviceSourceType: {
        Room: 1,
        CapacityEquipment: 2,
        Device: 3,
      },
    };
  },
  components: {
    'v-jstree': VJstree,
  },
  created() {
    this.selectedValue = this.value;
  },
  computed: {
    searchTextExist() {
      return !this.stringIsEmptyOrWhiteSpace(this.searchText);
    },
    selectedText() {
      return this.value ? this.value.text : 'Please select...';
    },
  },
  methods: {
    itemClick(e) {
      let tempValue = this.selectedValue ? [...this.selectedValue] : [];
      const index = tempValue.findIndex((item) => item.Value == e.model.value && item.Type == e.model.type);

      if (e.model.selected) {
        const data = {
          Text: e.model.text,
          Value: e.model.value,
          Type: e.model.type,
          Icon: e.model.icon,
          Target: {},
        };
        if (e.model.type == this.enumTreeViewDeviceSourceType.Room) {
          Vue.set(data.Target, 'RoomId', e.model.value);
        } else if (e.model.type == this.enumTreeViewDeviceSourceType.CapacityEquipment) {
          Vue.set(data.Target, 'DeviceId', e.model.value);
        } else if (e.model.type == this.enumTreeViewDeviceSourceType.Device) {
          Vue.set(data.Target, 'DeviceId', e.model.value);
        }
        if (!this.multiple) {
          tempValue = [data];
        } else if (index >= 0) {
          Vue.set(tempValue, index, data);
        } else {
          tempValue.push(data);
        }
      } else if (index >= 0) {
        if (!this.multiple) {
          tempValue = [];
        } else {
          tempValue.splice(index, 1);
        }
      }
      this.selectedValue = tempValue;
      this.$emit('input', tempValue);
      if (!this.multiple) {
        this.$emit('hide');
      }
    },
    searchTextChanged(e) {
      const self = this;
      clearTimeout(self.searchTimeout);
      const timeout = self.tempSearchText.length > 3 ? 300 : 600;
      self.searchTimeout = setTimeout(async () => {
        await blockui.blockElementPromise(self, self.$el);
        self.searchText = self.tempSearchText;
        blockui.unBlockElement(self.$el);
      }, timeout);
    },
    stringIsEmptyOrWhiteSpace(str) {
      return (!str || str.trim().length === 0);
    },
    sliceFirst(array) {
      if (!array || (array && array.length == 0)) {
        return [];
      }
      return [...array.slice(1, array.length)];
    },
    // loads async data when expand tree items and init
    loadData(oriNode, resolve) {
      const self = this;
      let nodes = [];

      if (oriNode.data.value === undefined) {
        api.getRoomListForTreeView().then((response) => {
          nodes = response.body.map((item) => ({
            text: item.Name,
            value: item.Id,
            type: item.TypeId,
            isLeaf: item.IsLeaf,
            icon: this.getIconFromTypeId(item.TypeId),
            opened: false,
          }));
          resolve(nodes);
        });
      } else {
        api.getCapacityEquipmentListForTreeView(oriNode.data.value, oriNode.data.type).then((response) => {
          nodes = response.body.map((item) => ({
            text: item.Name,
            value: item.Id,
            type: item.TypeId,
            isLeaf: item.IsLeaf,
            icon: this.getIconFromTypeId(item.TypeId),
            opened: false,
          }));

          /* if (oriNode.data.value === null && oriNode.data.text === self.rootNodeText && self.initNode) {
                            let tempObj = self.initNode;
                            while (tempObj.ParentId) tempObj = tempObj.Parent;
                            let parentNode = nodes.find(x => x.value === tempObj.Id);
                            parentNode.opened = self.initNode.Id !== parentNode.value;
                            self.traverseTree(parentNode, tempObj);
                        } */
          resolve(nodes);
        });
      }
    },
    getIconFromTypeId(typeId) {
      if (typeId == this.enumTreeViewDeviceSourceType.Room) {
        return 'fa fa-warehouse';
      } if (typeId == this.enumTreeViewDeviceSourceType.CapacityEquipment) {
        return 'fa fa-bolt';
      } if (typeId == this.enumTreeViewDeviceSourceType.Device) {
        return 'fa fa-server';
      }
    },
    selectCurrent(nodes, selectedValues) {
      for (const node of nodes) {
        if (!node.loading) {
          node.selected = !!selectedValues.find((value) => value.Value == node.value && value.Type == node.type);
          if (node.children && node.children.length > 0) {
            this.selectCurrent(node.children, selectedValues);
          }
        }
      }
    },
  },
  watch: {

    value(e) {
      this.selectedValue = e;
      this.selectCurrent(this.nodes, e || []);
    },
  },
};
</script>

<style scoped>
    .input-wrap {
        margin-bottom: 14px;
        position: relative;
    }

    .clear {
        clear: both;
    }
</style>
