import Vue from 'vue';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';

export default {
  props: {
    room: {
      default() {
        return { AreaSize: 50 };
      },
      type: Object,
    },
    isUpdate: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      building: {
        Value: '',
      },
      floor: {
        Value: '',
      },
      roomType: {
        Value: '',
      },
      buildingList: [],
      floorList: [],
      roomTypeList: [],
      roomSubTypeList: [],
      selectedRoom: {},
    };
  },
  components: {},
  mounted() {
    const self = this;
    api.getAllBuildings().then((response) => {
      this.buildingList = response.body;
    });
    api.getRoomTypes().then((response) => {
      this.roomTypeList = response.body;
    });
    const touchSpin = window.$('.touch-spin').TouchSpin({
      min: 1,
      max: 10000,
    });

    self.$nextTick(() => {
      touchSpin.on('change', (e) => {
        self.selectedRoom.AreaSize = $(e.target).val();
      });

      $("#room_modal form [name='BuildingId']").on('change', (e) => {
        self.floorList = [];
        if (e.target.value) {
          self.fetchFloors(e.target.value);
          /* api.getFloors({buildingId: e.target.value}).then(response => {
                        self.floorList = response.body;
                    }); */
        }
      });

      $("#room_modal form [name='RoomTypeId']").on('change', (e) => {
        if (e.target.value) {
          self.fetchRoomSubTypes(e.target.value);
          /* api.getRoomSubTypes({roomTypeId: e.target.value}).then(response => {
                        self.roomSubTypeList = response.body;
                    }); */
        }
      });
    });

    Vue.loadAsteriks();

    $('#room_modal').on('hidden.bs.modal', (e) => {
      self.$emit('close');
    });

    $('#room_modal').on('shown.bs.modal', (e) => {
      self.selectedRoom = self.room;
      if (self.selectedRoom && self.selectedRoom.Floor && self.selectedRoom.Floor.BuildingId) {
        self.fetchFloors(self.selectedRoom.Floor.BuildingId);
      }
      if (self.selectedRoom && self.selectedRoom.RoomSubType && self.selectedRoom.RoomSubType.RoomTypeId) {
        self.fetchRoomSubTypes(self.selectedRoom.RoomSubType.RoomTypeId);
      }
    });
  },
  methods: {
    onSubmit(e) {
      const self = this;
      const form = window.$(e.target);
      if (form.valid()) {
        const formData = {};
        blockui.blockModal(self);
        $.map(form.serializeArray(), (item) => {
          Object.assign(formData, { [item.name]: item.value });
        });
        api.saveRoom(formData).then((response) => {
          if (response.body.IsSuccess) {
            toastr.success(self.translate('save_successfully'));
            $('#room_modal').modal('toggle');
            if (self.$route.meta.xyz) {
              Vue.getDatatables('.room_list');
            } else if ($('.m-portlet').hasClass('room_list')) {
              self.reloadDataTable();
            } else {
              self.$store.dispatch('getRelatedRoomList', self.$router.history.current.name);
            }
          } else {
            toastr.error(response.body.Message);
          }
          blockui.unBlockModal();
        }, (error) => {
          blockui.unBlockModal();
        });
      }
    },
    fetchFloors(buildingId) {
      const self = this;
      api.getFloors({ buildingId }).then((response) => {
        self.floorList = response.body;
      });
    },
    fetchRoomSubTypes(roomTypeId) {
      const self = this;
      api.getRoomSubTypes({ roomTypeId }).then((response) => {
        self.roomSubTypeList = response.body;
      });
    },
    reloadDataTable() {
      Vue.getDatatables('.room_list');
    },
  },
  watch: {
    room(e) {
      const self = this;
      this.selectedRoom = e;
      if (this.isUpdate) {
        /* if (this.room.RoomSubType && this.room.RoomSubType.RoomTypeId) {
                    api.getRoomSubTypes({roomTypeId: this.room.RoomSubType.RoomTypeId}).then(response => {
                        this.roomSubTypeList = response.body;
                    });
                } */
        if (self.selectedRoom && self.selectedRoom.Floor && self.selectedRoom.Floor.BuildingId) {
          self.fetchFloors(self.selectedRoom.Floor.BuildingId);
        }
        if (self.selectedRoom && self.selectedRoom.RoomSubType && self.selectedRoom.RoomSubType.RoomTypeId) {
          self.fetchRoomSubTypes(self.selectedRoom.RoomSubType.RoomTypeId);
        }
      }
    },
  },
};
