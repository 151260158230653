import Vue from 'vue';
import { baseUrl } from '../services/api';
import WarehouseTree from '../../components/partials/modals/Tool/Warehouse/Warehouse.vue';
import { getCookieByName, isMobileBrowser } from './util';

export const Editor = (props, ref) => {
  if (ref && !ref.isDestroyed) ref.close();
  if (props === null) return null;
  if (props.type === 'popupHtml') {
    ref = new bryntum.scheduler.Popup({
      refElement: props.refElement,
      maximize: false,
      maxHeight: '100vh',
      draggable: { handleSelector: ':not(.b-popup-content)' },
      scrollable: true,
      centered: true,
      showOnclick: true,
      closable: true,
      width: 946,
      autoClose: false,
      closeAction: 'destroy',
      autoShow: false,
      trapFocus: true,
      html: '<textarea class="redactor" placeholder="..."></textarea>',
      tools: {
        info: {
          html: bryntum.scheduler.LocaleManager.locale.Popup.counter(),
          style: 'font-size:9px!important;margin-right:9px',
          weight: -1000,
        },
        done: {
          cls: 'b-fa-check-square b-hidden',
          style: 'margin-right:10px',
          handler: async (e) => {
            await onPopupHtmlDone(props, ref);
          },
          weight: -1000,
        },
      },
      onPaint: ({ source }) => {
        const lang = localStorage.getItem('vue-lang');
        source.tools.done.element.classList.remove('b-hidden');
        $(source.bodyElement.querySelector('.redactor')).redactor({
          forceFullscreen: isMobileBrowser(),
          save: async () => {
            if (isMobileBrowser()) {
              await onPopupHtmlDone(props, ref);
              !ref.isDestroyed && ref.destroy();
            }
          },
          cancel: async () => {
            if (isMobileBrowser()) {
              !ref.isDestroyed && ref.destroy();
            }
          },
          imageUpload: `${baseUrl}/File/SaveContent`,
          fileUpload: `${baseUrl}/File/SaveContent`,
          requestHeaders: [{
            key: 'Authorization',
            value: `${getCookieByName('tokenType')} ${getCookieByName('token')}`,
          }, {
            key: 'Culture',
            value: localStorage.getItem('vue-lang'),
          }],
          imageUploadFields: {
            baseUrl,
          },
          fileUploadFields: {
            baseUrl,
          },
          buttons: ['format', 'bold', 'italic', 'deleted', 'underline', 'horizontalrule', 'lists', 'link', 'file', 'image'],
          plugins: ['undoredo', 'inlinestyle', 'fontfamily', 'fontsize', 'fontcolor', 'alignment', 'table', 'fullscreen', 'imageUpload', 'fileUpload', 'counter'], // 'video', //todo static id on video plugin
          imagePosition: true,
          imageResizable: true,
          tabAsSpaces: 4,
          minHeight: 350,
          maxHeight: 350,
          dragUpload: true,
          multipleUpload: true,
          clipboardUpload: true,
          lang: lang === 'tr-Tr' ? lang : 'en',
          callbacks: {
            uploadError: (message) => toastr.error(message),
            counter: (data) => {
              source.tools.info.element.innerText = bryntum.scheduler.LocaleManager.locale.Popup.counter(data);
            },
          },
        });
      },
      onBeforeClose: ({ source }) => {
        $(source.bodyElement.querySelector('.redactor')).redactor('core.destroy'); // todo check if work
      },
    });
    ref.show();
    ref.contentElement.style.overflow = 'inherit';
    props.value && $(ref.bodyElement.querySelector('.redactor')).redactor('set', props.value); // todo check if work
    return ref;
  } if (props.type === 'popupVue') {
    let instance = null;
    const a = Vue.extend(props.vueComponent);
    instance = new a({
      propsData: {
        ...props.vueProps,
      },
    });

    if (props.vueEvents) {
      for (const event of props.vueEvents) {
        instance.$on(event.name, event.callback);
      }
    }

    ref = new bryntum.scheduler.Popup({
      forElement: '', // props.refElement,
      maximize: false,
      maxHeight: '100vh',
      draggable: { handleSelector: ':not(.b-popup-content)' },
      scrollable: true,
      anchor: true,
      showOnclick: true,
      closable: true,
      width: 946,
      autoClose: false,
      closeAction: 'hide',
      autoShow: false,
      trapFocus: false,
      html: '',
      style: 'background-color:white',
      /* listeners: {
                catchAll: function (event) {
                    console.log(event.type, event);
                },
                thisObj: this
            }, */
      changeProp: (newProps) => {
        if (newProps) {
          for (const propKey in newProps) {
            Vue.set(instance, propKey, newProps[propKey]);
          }
        }
      },
      /* tools: {
                done: {
                    cls: 'b-fa-check-square',
                    style: 'margin-right:10px',
                    handler: async () => {
                        ref.mask(bryntum.scheduler.LocaleManager.locale.Grid.loadMask);
                        props.value = value;
                        await props.callback(props.value);
                        !ref.isDestroyed && ref.unmask();
                    },
                    weight: -1000,
                },
            }, */
      onPaint: ({ source }) => {
        instance.$mount(source.contentElement);
      },
      onBeforeClose: ({ source }) => {
        // instance.$destroy();
        // instance.$el.remove();
      },
      onBeforeDestroy: ({ source }) => {
        instance.$el.remove();
        instance.$destroy();
      },
    });
    // ref.show();
    ref.contentElement.style.overflow = 'inherit';
    return ref;
  }
  if (props.type === 'toolTip') {
    ref = new bryntum.scheduler.Popup({
      refElement: props.refElement,
      maximize: false,
      draggable: { handleSelector: ':not(.b-popup-content)' },
      scrollable: true,
      centered: true,
      showOnclick: true,
      closable: true,
      width: 500,
      minHeight: 100,
      maxHeight: '100vh',
      autoClose: false,
      closeAction: 'destroy',
      autoShow: false,
      trapFocus: true,
      onPaint: async ({ source }) => {
        source.title = '';
        source.html = '';
        source.mask(bryntum.scheduler.LocaleManager.locale.Grid.loadMask);
        await props.onPaint(source);
        source.unmask();
      },
      tools: {
        delete: {
          cls: 'b-fa-comment-times',
          style: 'display:none; margin-right:10px; color:red',
          handler: async () => {
            ref.mask(bryntum.scheduler.LocaleManager.locale.Grid.loadMask);
            await props.callback(ref);
            !ref.isDestroyed && ref.unmask();
          },
          weight: -1000,
        },
        maximize: {
          cls: 'b-fa-minus-square',
          handler: () => {
            ref.maximize = !ref.maximize;
            ref.tempHeight = ref.maximize && ref.height || ref.tempHeight;
            ref.tempWidth = ref.maximize && ref.width || ref.tempWidth;
            ref.tempX = ref.maximize && ref.x || ref.tempX;
            ref.tempY = ref.maximize && ref.y || ref.tempY;
            ref.x = ref.maximize ? 0 : ref.tempX;
            ref.y = ref.maximize ? 0 : ref.tempY;
            ref.width = ref.maximize ? '100%' : ref.tempWidth;
            ref.height = ref.maximize ? '100%' : ref.tempHeight;
            ref.draggable = !ref.maximize ? { handleSelector: ':not(.b-popup-content)' } : false;
          },
          weight: -1000,
        },

      },
    });
    ref.show();
    ref.contentElement.style.display = 'block';
    return ref;
  }
};

async function onPopupHtmlDone(props, ref) {
  ref.mask(bryntum.scheduler.LocaleManager.locale.Grid.loadMask);
  let value = $(ref.bodyElement.querySelector('.redactor')).redactor('code.get');
  if (!value) {
    // TODO - Localization
    toastr.error(bryntum.scheduler.LocaleManager.locale.Popup.enterValue);
    !ref.isDestroyed && ref.unmask();
    return;
  }
  // TODO find better way to detect image and file url to change
  // Change base url with placeholder
  value = value.replace(baseUrl, '#ApiBaseUrlSignature#');
  props.value = value;
  await props.callback(props.value);
  !ref.isDestroyed && ref.unmask();
}
