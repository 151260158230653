import Vue from 'vue';

import WarehouseTree from '../../../partials/modals/Tool/Warehouse/Warehouse.vue';

export default {
  data() {
    return {
    };
  },
  components: {
    'warehouse-tree': WarehouseTree,
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  },
};
