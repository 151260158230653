import * as api from '../../scripts/services/api';

// initial state
const state = {
  pendingApprovalList: [],
  workOrderStatuses: [],
  workOrderStatusList: [],
  workOrderStatusDescriptions: [],
};

// getters
const getters = {
  getWorkOrderStatusesFromIds: (state) => {
    const newObj = {};
    state.workOrderStatuses.forEach((x) => {
      newObj[x.Id] = x;
    });
    return newObj;
  },
};

// actions
const actions = {
  async getPendingApprovalList({ commit }, data) {
    await api.getPendingApprovalList(data).then((res) => {
      commit('setPendingApprovalList', res.body.Data);
    });
  },
  async getWorkOrderStatuses({ commit }, workOrderSourceId) {
    await api.getWorkOrderStatusList(workOrderSourceId).then((res) => {
      commit('setWorkOrderStatuses', res.body.Data);
    });
  },
  async getWorkOrderStatusDescriptions({ commit }, data) {
    await api.getWorkOrderStatusDescriptions(data).then((res) => {
      commit('setWorkOrderStatusDescriptions', res.body);
    });
  },
  async getWorkOrderStatusDescriptionList({ commit }, data) {
    await api.getWorkOrderStatusDescriptionList(data).then((res) => {
      commit('setWorkOrderStatusDescriptionList', res.body.Data);
    });
  },
  async getWorkOrderStatusList({ commit }, workOrderSourceId) {
    await api.getWorkOrderStatuses(workOrderSourceId).then((res) => {
      commit('setWorkOrderStatusList', res.body);
    });
  },
};

// mutations
const mutations = {
  setPendingApprovalList(state, pendingApprovals) {
    state.pendingApprovalList = pendingApprovals;
  },
  setWorkOrderStatuses(state, workOrderStatuses) {
    state.workOrderStatuses = workOrderStatuses;
  },
  setWorkOrderStatusDescriptions(state, workOrderStatusDescriptions) {
    state.workOrderStatusDescriptions = workOrderStatusDescriptions;
  },
  setWorkOrderStatusDescriptionList(state, workOrderStatusDescriptions) {
    state.workOrderStatusDescriptions = workOrderStatusDescriptions;
  },
  setWorkOrderStatusList(state, workOrderStatuses) {
    state.workOrderStatusList = workOrderStatuses;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
