<template>
    <div style="margin-top:10px"></div>
</template>
<script>
export default {
  components: { },
  props: {
    type: String,
    config: Object,
  },
  data() {
    return {
      el: undefined,
    };
  },
  methods: {},
  computed: { },
  watch: { },
  created() {},
  mounted() {
    this.config.width = this.config.width ? this.config.width : 200;
    this.config.insertFirst = this.$el;
    this.config.listeners = {
      catchAll(event) {
        this.$emit(event.type, event);
      },
      thisObj: this,
    };
    this.el = new bryntum.scheduler[this.type](this.config);
  },
};
</script>
