






























import { Component } from 'vue-property-decorator';
import { DataStateChangeEventArgs } from "@syncfusion/ej2-vue-grids";
import CFMGridPage from '@/scripts/CFMGridPage'

import { CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelTrainingDemand, 
CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelTrainingSp, CFMDTOTraining } from '@/scripts/services/ClientApiAuto';

@Component<TrainingTracking>({
})
export default class TrainingTracking extends CFMGridPage<CFMDTOTraining> {
    entityType = "Training";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];
    expandProperties = ["Role","TrainingType"];
    groupOptions= { columns: ['RoleName','TrainingTypeName'] };

    async mounted(){
        this.dataStateChange(this.state);
    }
    async dataStateChange(state: DataStateChangeEventArgs) {
        this.state = state;
        let dataForUser = await this.getLastTrainingsForUsers(5);
        let demandData = await this.getTrainingDemandData(5,true);
        let dd = await this.mergeData(demandData,dataForUser);
        if(state && state.action && (state.action.requestType === "filterchoicerequest" || <string>state.action.requestType === 'filtersearchbegin'))
        {
            (<{dataSource:Function}>state).dataSource(dd);
        }
        else{
            this.gridDataSource = dd;
            this.groupOptions.columns.push('RoleId');
            this.groupOptions.columns.push('TrainingTypeName');
            return this.gridDataSource;
        }
    }

    async mergeData(demandData:any,dataForUser:any){
        for (let i = 0; i < dataForUser.length; i++) {
            const ele = dataForUser[i];
            for (let index = 0; index < demandData.length; index++) {
                const el = demandData[index];
                if(el['Id'] == ele['UserId']){
                    ele['Total'] = el['Total'];
                    break;
                }
            }
            if(ele['RemainingDay']){
                ele['RemainingDay'] = 327 + ele['RemainingDay'];
            }
            else{
                ele['RemainingDay'] = '-';
            }
        }
        return dataForUser;
    }
    async getTrainingDemandData(daysToCheck?: number, isUser?: boolean): Promise<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelTrainingDemand[]> {
        const response = await this.apiClient.training.trainingGetTrainingDemands({daysToCheck:daysToCheck,isUser:isUser});
        // @ts-ignore
        return response.data.Data;
    }
    async getLastTrainingsForUsers(daysToCheck?: number): Promise<CFMCoreEntityResultMessageSystemCollectionsGenericIEnumerableCFMModelTrainingSp[]> {
        const response = await this.apiClient.training.trainingGetLastTrainingsForUsers({daysToCheck:daysToCheck});
        // @ts-ignore
        return response.data.Data;
    }
    refreshGrid(){
        this.dataStateChange(this.state);
    }
}
