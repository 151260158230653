import deepClone from '../utilities.js';
import ConditionInput from '../ConditionInput/ConditionInput.vue';

export default {
  name: 'query-builder-rule',
  props: ['query', 'index', 'styled', 'comparisonOperators', 'enumFormQuestionType', 'questionTypeId', 'showErrors'],
  components: {
    'condition-input': ConditionInput,
  },
  data() {
    return {};
  },
  methods: {
    remove() {
      this.$emit('child-deletion-requested', this.index);
    },
    updateQuery(value) {
      const updated_query = deepClone(this.query);
      updated_query.value = value;
      this.$emit('update:query', updated_query);
    },
  },
  computed: {
    isSecondValueNeeded() {
      const selectedComparisonOperator = this.comparisonOperators.find((comparisonOperator) => comparisonOperator.Id == this.query.ComparisonOperatorId);
      if (selectedComparisonOperator) {
        return selectedComparisonOperator.IsSecondValueNeeded;
      }
      return false;
    },
    isValid() {
      return this.query.ComparisonOperatorId > 0 && !!this.query.FirstValue && (!this.isSecondValueNeeded || !!this.query.SecondValue);
    },
  },
  mounted() {
  },
};
